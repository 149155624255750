import { Badge, Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person, Relationship } from '../../../types';
import React, { FC } from 'react';

import CardHeaderTitle from '../../Widgets/Cards/CardHeaderTitle';
import { CommentGroup } from './CommentGroup';
import { PrivateDataBadge } from '../components/PrivateDataBadge';
import { getUnattributedPerson } from '../../../utils/models/Person';

export type HeadsUpDisplayMode = 'hidden' | 'count' | 'full';

export interface HeadsUpCardProps {
  organization: Organization;
  firstName?: string;
  person?: Person;
  headsUp?: Relationship[];
  headsUpsDisplay: HeadsUpDisplayMode;
}

export const HeadsUpCard: FC<HeadsUpCardProps> = (props) => {
  const { formatMessage } = useIntl();
  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <i className="fe fe-flag text-danger me-2" />
          <FormattedMessage
            id="app.views.person.person_performance.heads_up_card.heads_up"
            defaultMessage="Heads up"
          />
          {(props.headsUp?.length ?? 0) > 0 && (
            <Badge className="bg-danger-soft fw-bold fs-5 ms-3">
              {props.headsUp?.length}
            </Badge>
          )}
          {props.headsUpsDisplay !== 'full' && <PrivateDataBadge />}
        </CardHeaderTitle>
      </CardHeader>

      {props.headsUpsDisplay === 'full' && (
        <CardBody>
          {props.headsUp?.length ? (
            props.headsUp?.map((hu) => (
              <CommentGroup
                key={hu.id}
                author={
                  hu.from_person && !hu.is_anonymous
                    ? hu.from_person
                    : getUnattributedPerson(
                        formatMessage,
                        hu.id,
                        hu.type,
                        hu.is_anonymous
                      )
                }
              >
                {hu.negative_comments || (
                  <div className="fst-italic">
                    <FormattedMessage
                      id="app.views.person.person_performance.heads_up_card.no_comments_provided"
                      defaultMessage="No comments provided"
                    />
                  </div>
                )}
                <div className="gap-3 mt-3">
                  {hu.negative_skills?.map((skill) => (
                    <Badge key={skill.name} pill className="bg-primary-soft">
                      {skill.name}
                    </Badge>
                  ))}
                </div>
              </CommentGroup>
            ))
          ) : (
            <div>
              <FormattedMessage
                id="app.views.person.person_performance.heads_up_card.empty_state"
                defaultMessage="{firstName} did not receive any Heads Ups this cycle."
                values={{
                  firstName: props.firstName,
                }}
              />
            </div>
          )}
        </CardBody>
      )}
    </Card>
  );
};
