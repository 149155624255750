import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import IncludeExcludeFilter, {
  IncludeExcludeFilterType,
} from '../../Widgets/Inputs/IncludeExcludeFilter';
import { OnFilterChange, ViewScope } from '../../../utils/models/Objective';
import React, { FC, useCallback, useMemo, useState } from 'react';

import ObjectivesOverviewCSVDownloader from './ObjectivesOverviewCSVDowloader';
import ObjectivesOverviewStatusFilter from './ObjectivesOverviewStatusFilter';
import ObjectivesOverviewTextFilter from './ObjectivesOverviewTextFilter';

type ConfigurationType = {
  value: ViewScope;
  text: React.ReactNode;
};

const configuration: ConfigurationType[] = [
  {
    value: 'everyone',
    text: (
      <FormattedMessage
        id="app.views.objectives.objectives_overview_page.scope.everyone"
        defaultMessage="Everyone's objectives"
      />
    ),
  },
  {
    value: 'my_full_team',
    text: (
      <FormattedMessage
        id="app.views.objectives.objectives_overview_page.scope.my_full_team"
        defaultMessage="My full team"
      />
    ),
  },
  {
    value: 'my_direct_reports',
    text: (
      <FormattedMessage
        id="app.views.objectives.objectives_overview_page.scope.my_direct_reports"
        defaultMessage="My direct reports"
      />
    ),
  },
  {
    value: 'my_objectives',
    text: (
      <FormattedMessage
        id="app.views.objectives.objectives_overview_page.scope.my_objectives"
        defaultMessage="My objectives"
      />
    ),
  },
];

type ObjectivesOverviewScopeFilterItem = {
  value: ViewScope;
  text: React.ReactNode;
  outline: boolean;
  onChange: (delta: OnFilterChange) => void;
  disabled: boolean;
};

const ObjectivesOverviewScopeFilterItem = ({
  value,
  text,
  outline,
  onChange,
  disabled,
}: ObjectivesOverviewScopeFilterItem) => {
  return (
    <Button
      size="sm"
      color="primary"
      active={!outline}
      disabled={disabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        onChange({ scope: (e.target as HTMLInputElement).value as ViewScope })
      }
      value={value}
      outline={outline}
    >
      {text}
    </Button>
  );
};

type Props = {
  disabled: boolean;
  scope?: ViewScope;
  peopleFilters: IncludeExcludeFilterType;
  status: string[];
  availableScopes: ViewScope[];
  textFilter: string;
  firstDay: string;
  lastDay: string;
  onChange: (delta: OnFilterChange) => void;
  onGenerateCSV: () => void;
  onGenerateCSVCompleted: () => void;
  hasData: boolean;
};

const HIDDEN_FILTER_CATEGORIES: string[] = ['level'];

const ObjectivesOverviewScopeFilter: FC<Props> = ({
  scope,
  status,
  peopleFilters,
  textFilter,
  onChange,
  onGenerateCSV,
  onGenerateCSVCompleted,
  disabled,
  availableScopes,
  firstDay,
  lastDay,
  hasData,
}: Props) => {
  const { formatMessage } = useIntl();
  const filteredConfiguratons = useMemo(
    () => configuration.filter((item) => availableScopes.includes(item.value)),
    [availableScopes]
  );
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const adaptIncludeExcludeOnChange = useCallback(
    (filters: IncludeExcludeFilterType) => {
      onChange({ peopleFilters: filters });
    },
    [onChange]
  );
  return (
    <Card>
      <CardHeader style={{ height: 'auto' }}>
        <Row className="justify-content-between">
          <Col className="col-12 col-lg-auto">
            <Row>
              <Col className="px-0 col-lg-auto">
                <ButtonGroup>
                  {filteredConfiguratons.map((item) => (
                    <ObjectivesOverviewScopeFilterItem
                      disabled={disabled}
                      key={'scope-filter-item-' + item.value}
                      onChange={onChange}
                      value={item.value}
                      text={item.text}
                      outline={item.value !== scope}
                    />
                  ))}
                </ButtonGroup>
              </Col>
              <Col className="col-auto">
                <Button
                  disabled={disabled}
                  color="light"
                  className="btn-sm"
                  onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                >
                  <i
                    className="fe fe-filter"
                    style={{ position: 'relative', top: '1px' }}
                  />{' '}
                  {showAdvancedFilters ? (
                    <FormattedMessage
                      id="app.widgets.task.hide_filters_button_text"
                      defaultMessage="Hide filters"
                    />
                  ) : (
                    <FormattedMessage
                      id="app.widgets.task.toggle_filters_button_text"
                      defaultMessage="Filter this list"
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
          {!!scope && (
            <Col className="col-12 col-lg-auto pe-0">
              <ObjectivesOverviewCSVDownloader
                disabled={disabled || !hasData}
                scope={scope}
                status={status}
                peopleFilters={peopleFilters}
                textFilter={textFilter}
                firstDay={firstDay}
                lastDay={lastDay}
                onGenerateCSV={onGenerateCSV}
                onGenerateCSVCompleted={onGenerateCSVCompleted}
              />
            </Col>
          )}
        </Row>
      </CardHeader>
      {showAdvancedFilters && (
        <CardBody className="p-3 mb-n3">
          <Row>
            <Col className="col-12 col-xl-6">
              <Row>
                <Col className="col-auto mb-3">
                  <ObjectivesOverviewStatusFilter
                    disabled={disabled}
                    value={status}
                    onChange={onChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <ObjectivesOverviewTextFilter
                    disabled={disabled}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-xl-6">
              <IncludeExcludeFilter
                initialValue={peopleFilters}
                onChange={adaptIncludeExcludeOnChange}
                hidePersonFilters={false}
                hideFilterCagories={HIDDEN_FILTER_CATEGORIES}
                includeFilterPlaceholder={formatMessage({
                  id: 'app.views.objectives.objectives_overview_page.filter.include_placeholder',
                  defaultMessage: 'Filter by owners',
                })}
                excludeFilterPlaceholder={formatMessage({
                  id: 'app.views.objectives.objectives_overview_page.filter.exclude_placeholder',
                  defaultMessage: 'Exclude owners',
                })}
              />
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
};

export default ObjectivesOverviewScopeFilter;
