import React, { FC, ReactNode } from 'react';
import { Alert, Button } from 'reactstrap';

interface Action {
  label: ReactNode | string;
  onClick: () => void;
  color?: string;
}

interface AnnouncementBarProps {
  message: ReactNode | string;
  alertType?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  className?: string;
  actions?: Action[];
}

const AnnouncementBar: FC<AnnouncementBarProps> = ({
  message,
  alertType = 'warning',
  className = '',
  actions = [],
}) => {
  return (
    <Alert
      className={`mb-0 rounded-0 d-flex justify-content-between align-items-center ${className}`}
      color={alertType}
    >
      <div className="flex-grow-1 text-left">{message}</div>
      {actions.length > 0 && (
        <div className="mt-2">
          {actions.map((action, index) => (
            <Button
              key={index}
              color={action.color || 'primary'}
              onClick={action.onClick}
              className="ml-2"
            >
              {action.label}
            </Button>
          ))}
        </div>
      )}
    </Alert>
  );
};

export default AnnouncementBar;
