import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback } from 'react';

import { CampaignWithConfigs } from '../../../types';
import { INPUT_TYPES } from '../../Widgets/Inputs/ValidatedInputTypes';
import ModalEditor from '../../Widgets/Modals/ModalEditor';
import { TEAM_PERFORMANCE_DASHBOARD } from '../../../consts/consts';

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  selectedValues: any[];
  inputFields: any[];
  campaign: CampaignWithConfigs;
  onSuccess: (status: ApiStatus) => void;
};

export type ApiStatus = 'modified' | 'unchanged';

type ApiResponse = {
  status: ApiStatus;
  dataset_id: number;
};

type CheckboxValueType = 'Y' | 'N' | '-';

const CampaignParticipantsBulkEditorModal: FC<Props> = ({
  isOpen,
  onToggle,
  selectedValues,
  campaign,
  inputFields,
  onSuccess,
}) => {
  const { formatMessage } = useIntl();

  const handleCallback = useCallback(
    (data?: ApiResponse, error?: unknown, hardErrorMessage?: unknown) => {
      if (!error && !hardErrorMessage && data) {
        onSuccess(data.status);
      }
    },
    [onSuccess]
  );

  const fields = inputFields.map((item) => {
    const { name, label, helperText } = item;
    return {
      name,
      label,
      helperText,
      type: INPUT_TYPES.CHECKBOX,
      withIndeterminate: true,
      checkedValue: 'Y',
      uncheckedValue: 'N',
      indeterminateValue: '-',
    };
  });

  const form: { [name: string]: CheckboxValueType } = fields.reduce(
    (acc, item) => {
      const values: boolean[] = selectedValues.map(
        (person) => person[item.name]
      );
      const hasTrue = values.includes(true);
      const hasFalse = values.includes(false);
      return {
        ...acc,
        [item.name]: hasTrue && hasFalse ? '-' : hasTrue ? 'Y' : 'N',
      };
    },
    {}
  );

  return (
    <>
      <ModalEditor
        title={formatMessage(
          {
            id: 'app.views.administration.campaign_participants_bulk_editor_modal.title',
            defaultMessage:
              'Edit {count, plural, one {# participant} other {# participants}} for {campaignName}',
          },
          { count: selectedValues.length, campaignName: campaign.name }
        )}
        callback={handleCallback}
        isOpen={isOpen}
        toggle={onToggle}
        method="POST"
        url={`/datasets/${campaign.dataset_id}/bulk-update`}
        submitText={formatMessage({
          id: 'app.views.administration.campaign_participants_bulk_editor_modal.button.save',
          defaultMessage: 'Save',
        })}
        object={form}
        inputs={fields}
        transformObjectBeforeSubmit={(payload) => {
          return {
            ...payload,
            selected_values: selectedValues
              .filter((item) => !!item.email)
              .map((item) => item.email),
          };
        }}
        footer={
          <div className="col pb-4 text-center">
            <FormattedMessage
              id="app.views.administration.campaign_participants_bulk_editor_modal.footer"
              defaultMessage={
                "Want to edit a person's attributes for this cycle dataset snapshot? <br></br> Go to the <link>Team Dashboard</link>"
              }
              values={{
                link: (chunk) => (
                  <a
                    href={TEAM_PERFORMANCE_DASHBOARD().path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunk}
                  </a>
                ),
                br: () => <br />,
              }}
            />
          </div>
        }
      />
    </>
  );
};

export default CampaignParticipantsBulkEditorModal;
