import * as Sentry from '@sentry/browser';

import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';

// use display names in case we want to print it
const ENV_PROD = 'Production';
const ENV_DEMO = 'Demo';
const ENV_SANDBOX = 'Sandbox';
const ENV_TOUR = 'Tour';
const ENV_UAT = 'UAT';
const ENV_LOCAL = 'Local';

const getEnvironmentName = () => {
  const url = process.env.REACT_APP_CONFIRM_APP_URL;

  const envs = {
    'https://app.confirm.com': ENV_PROD,
    'https://app.confirmhr.com': ENV_PROD,
    'https://app-uat.confirm.com': ENV_UAT,
    'https://app-uat.confirmhr.com': ENV_UAT,
    'https://app-demo.confirm.com': ENV_DEMO,
    'https://app-demo.confirmhr.com': ENV_DEMO,
    'https://sandbox.confirm.com': ENV_SANDBOX,
    'https://sandbox.confirmhr.com': ENV_SANDBOX,
    'https://tour.confirm.com': ENV_TOUR,
    'https://tour.confirmhr.com': ENV_TOUR,
    'http://localhost:3000': ENV_LOCAL,
    'http://host.docker.internal:3000': ENV_LOCAL,
  };

  // @ts-expect-error
  if (!envs[url]) {
    console.error(
      'config.js: unrecognized REACT_APP_CONFIRM_APP_URL: ' +
        url +
        ', defaulting to local'
    );
  }

  // @ts-expect-error
  return envs[url] ?? ENV_LOCAL;
};

const config = {
  init: () => {
    // throw error for any unrecognized hostnames
    if (
      !(
        window.location.hostname.endsWith('.ngrok.io') ||
        window.location.hostname.endsWith('.ngrok-free.app') ||
        window.location.hostname.endsWith('.confirm.com') ||
        window.location.hostname.endsWith('.confirmhr.com')
      ) &&
      window.location.hostname !== 'localhost'
    ) {
      throw new Error('Invalid hostname: ' + window.location.hostname);
    }

    const environmentName = getEnvironmentName();
    if (environmentName !== ENV_LOCAL) {
      Sentry.init({
        dsn: 'https://b836013d23c84f71a1e1a83aa3ca135a@o336739.ingest.sentry.io/1891157',
        environment: environmentName,
        release: 'confirm-app@' + process.env.REACT_APP_SENTRY_RELEASE,
        integrations: [
          new Integrations.BrowserTracing(),
          new CaptureConsole({
            levels: ['error'],
          }),
        ],
        tracesSampleRate: environmentName === 'prod' ? 0.1 : 1.0,
        ignoreErrors: [
          // ignore these per https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
          'ResizeObserver loop limit exceeded',
          // ignore these per https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174
          'Non-Error promise rejection captured with value',
          // ignore these that are triggered when we are in maintenance window
          '"maintenance"',
        ],
        beforeSend: (event) => {
          // see https://docs.logrocket.com/docs/sentry
          if (LogRocket.sessionURL) {
            if (!event.extra) {
              event.extra = {};
            }
            event.extra.sessionURL = LogRocket.sessionURL;
          }
          return event;
        },
      });
    }

    // for "Last Updated" widgets to work on iPhone and test environments
    if (!Intl.RelativeTimeFormat) {
      require('@formatjs/intl-relativetimeformat/polyfill');
      require('@formatjs/intl-relativetimeformat/locale-data/en');
    }

    if (environmentName === ENV_LOCAL) {
      // UNCOMMENT HERE TO ENABLE MSW
      // ON LOCAL DEVELOPMENT
      // (add new handlers to mocks/browser.ts)
      // const { worker } = require('mocks/browser');
      // worker.start();
    }

    // ensure environment variables that are needed exist
    if (!process.env.REACT_APP_CONFIRM_APP_URL) {
      throw new Error(
        'Missing required environment variable: REACT_APP_CONFIRM_APP_URL'
      );
    }
    if (!process.env.REACT_APP_CONFIRM_ADMIN_URL) {
      throw new Error(
        'Missing required environment variable: REACT_APP_CONFIRM_ADMIN_URL'
      );
    }
    if (!process.env.REACT_APP_CONFIRM_API_URL) {
      throw new Error(
        'Missing required environment variable: REACT_APP_CONFIRM_API_URL'
      );
    }
    if (!process.env.REACT_APP_AUTH_DOMAIN) {
      throw new Error(
        'Missing required environment variable: REACT_APP_AUTH_DOMAIN'
      );
    }
    if (!process.env.REACT_APP_AUTH_AUDIENCE) {
      throw new Error(
        'Missing required environment variable: REACT_APP_AUTH_AUDIENCE'
      );
    }
    if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
      throw new Error(
        'Missing required environment variable: REACT_APP_AUTH0_CLIENT_ID'
      );
    }
  },
};

// @ts-expect-error
config.getEnvironmentName = getEnvironmentName;

// @ts-expect-error
config.isProduction = () => {
  return getEnvironmentName() === ENV_PROD;
};

// @ts-expect-error
config.isUat = () => {
  return getEnvironmentName() === ENV_UAT;
};

// @ts-expect-error
config.isLocal = () => {
  return getEnvironmentName() === ENV_LOCAL;
};

// @ts-expect-error
config.isTour = () => {
  return getEnvironmentName() === ENV_TOUR;
};

// @ts-expect-error
config.isDemo = () => {
  return getEnvironmentName() === ENV_DEMO;
};

// @ts-expect-error
config.isSandbox = () => {
  return getEnvironmentName() === ENV_SANDBOX;
};

// @ts-expect-error
config.getOauthConnection = () => {
  // Commented out when we added Microsoft.
  // This would force google auth independently of how many
  // Social Logins are active on Auth0.
  // if (config.isProduction()) {
  //   return 'google-oauth2';
  // }

  // Returning undefined will show the login selector for the user to
  // pick the identity service they want. To disable the form email/password
  // delete the Database integration on Auth0.
  // (https://community.auth0.com/t/social-only-login/46277)
  return undefined;
};

// for event tracking (note: we use staging to ensure the calls don't break anything before pushing to production)
// @ts-expect-error
config.getTracking = () => {
  // @ts-expect-error
  const isProduction = config.isProduction();
  // @ts-expect-error
  const isLocal = config.isLocal();

  return {
    useGoogleAnalyticsTracking: !isLocal,
    useLogRocketEventTracking: !isLocal,
    useIntercomEventTracking: isProduction,
    useConsoleEventTracking: !isProduction,
    useFullStoryEventTracking: false,
    usePendoTracking: false,
  };
};

export default config;
