import * as consts from '../../consts/consts';

import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getFriendlyUserFacingErrorObjectAndMessage,
  sortByFieldFunc,
} from '../../utils/util/util';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import Loading from '../Widgets/Loading';
import PeopleEditor from '../Widgets/Forms/PeopleEditor';
import PersonCard from '../Widgets/Cards/PersonCard';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { peopleIdsAreEqual } from '../../utils/models/Person';
import { toast } from 'react-toastify';
import { updateMyConfigs } from '../../actions';
import { useLocation } from 'react-router-dom';
import usePersonOrgChartRelatives from '../Person/PersonOrgChartRelatives';

const PROFILE_TABS_PATH_LIST = (formatMessage) => [
  consts.PROFILE_TAB_ONE_ON_ONES(formatMessage).path,
  consts.PROFILE_TAB_OBJECTIVES(formatMessage).path,
  consts.PROFILE_TAB_GOALS_AND_ASPIRATIONS(formatMessage).path,
  consts.PROFILE_TAB_OPERATING_MANUAL(formatMessage).path,
  consts.PROFILE_TAB_FEEDBACK(formatMessage).path,
  consts.PROFILE_TAB_PERFORMANCE(formatMessage).path,
];

const PeopleNav: FC<Props> = (props) => {
  const location = useLocation();
  const { formatMessage } = useIntl();

  const [showAddPersonInput, setShowAddPersonInput] = useState(false);
  const [peopleShortCuts, setPeopleShortCuts] = useState(
    // @ts-expect-error
    props.settings?.people_nav_shortcuts?.length > 0
      ? // @ts-expect-error
        props.settings?.people_nav_shortcuts
      : []
  );

  // update UI if updated elsewhere (e.g. if 1:1s tab open and left nav
  // open at the same time; adding one should add to the other in real time)
  useEffect(() => {
    // @ts-expect-error
    if (props.settings?.people_nav_shortcuts?.length > 0) {
      // @ts-expect-error
      setPeopleShortCuts(props.settings?.people_nav_shortcuts);
    }
    // @ts-expect-error
  }, [props.settings?.people_nav_shortcuts]);

  // @ts-expect-error
  const propsUpdateMyConfigs = props.updateMyConfigs;
  const updatePeopleNavOnServer = useCallback(
    (newShortcuts) => {
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        '/settings',
        {
          people_nav_shortcuts: newShortcuts.map((p) => p.id),
        },
        (data, error, hardErrorMessage) => {
          if (data) {
            // update all settings (including people nav shortcuts)
            propsUpdateMyConfigs(data);
          } else if (error || hardErrorMessage) {
            const [errorObject, friendlyErrorMessage] =
              getFriendlyUserFacingErrorObjectAndMessage(
                error,
                hardErrorMessage
              );
            console.error(
              'Error adding person: ' + JSON.stringify(errorObject)
            );
            toast.error(friendlyErrorMessage);
          }
        }
      );
    },
    [propsUpdateMyConfigs]
  );

  const addPeople = useCallback(
    (people) => {
      setShowAddPersonInput(false);

      // if you are proxying, don't allow adding
      // @ts-expect-error
      if (props.currentProxyPerson) {
        toast.error(
          formatMessage({
            id: 'app.views.layout.people_nav.toast.cannot_add_people_proxying',
            defaultMessage:
              'You cannot add people to this list when viewing as someone else.',
          })
        );
        return;
      }

      // this shouldn't happen
      if (people === undefined || people === null) {
        console.error(
          formatMessage({
            id: 'app.views.layout.people_nav.error.add_people_invalid_list',
            defaultMessage: 'addPeople on PeopleNav returned invalid list',
          })
        );
        return;
      }

      // if we press backspace in an empty input, it returns an empty array
      if (people.length === 0) {
        return;
      }

      // ensure person is valid, else don't add and throw toast validation error instead
      const invalidPerson = people.find((p) => !p?.id);
      if (invalidPerson) {
        toast.error(
          (invalidPerson?.email ?? 'That person') + ' was not found.'
        );
      } else {
        const newShortcuts = [...peopleShortCuts, ...people];
        setPeopleShortCuts(newShortcuts);
        updatePeopleNavOnServer(newShortcuts);
      }
    },
    [
      peopleShortCuts,
      // @ts-expect-error
      props.currentProxyPerson,
      updatePeopleNavOnServer,
      formatMessage,
    ]
  );

  const removePerson = useCallback(
    (person) => {
      const newShortcuts = peopleShortCuts.filter(
        (p) => !peopleIdsAreEqual(p?.id, person?.id)
      );
      setPeopleShortCuts(newShortcuts);
      updatePeopleNavOnServer(newShortcuts);
    },
    [peopleShortCuts, updatePeopleNavOnServer]
  );

  const toggleAddPersonInput = useCallback(() => {
    setShowAddPersonInput(!showAddPersonInput);
  }, [showAddPersonInput]);

  const orgChartRelatives = usePersonOrgChartRelatives(
    props.currentOrganization,
    // @ts-expect-error
    props.currentProxyPerson,
    props.me
  );

  const fullPeopleList = useMemo(() => {
    if (typeof orgChartRelatives === 'undefined') {
      return undefined;
    }

    return [
      ...(props.showSelf ? [props.me] : []),
      // don't show manager if they are already in the list of direct
      // reports due to a reciprocal relationship (two people reporting to each other)
      ...(orgChartRelatives?.manager &&
      !orgChartRelatives?.direct_reports?.find((p) =>
        peopleIdsAreEqual(p?.id, orgChartRelatives?.manager?.id)
      )
        ? [orgChartRelatives?.manager]
        : []),
      ...(orgChartRelatives?.direct_reports ?? []),
      ...(peopleShortCuts?.length > 0
        ? peopleShortCuts
            .map((p) => ({ ...p, allowRemoval: true }))
            .sort((a, b) => sortByFieldFunc(a, b, ['full_name']))
        : []),
    ];
  }, [orgChartRelatives, props.showSelf, props.me, peopleShortCuts]);

  const currentPathSuffix = useMemo(() => {
    // if url ends with any item on PROFILE_TABS_PATH_LIST,
    // then set that to postFix
    return (
      PROFILE_TABS_PATH_LIST(formatMessage).find((path) =>
        location.pathname.endsWith(path)
      ) ?? ''
    );
  }, [location, formatMessage]);

  const personItemClass = props.inNav ? 'mb-3 pb-2' : '';

  return (
    <>
      {typeof fullPeopleList === 'undefined' && <Loading />}
      {fullPeopleList?.length === 0 && (
        <EmptyState
          title={formatMessage({
            id: 'app.views.layout.people_nav.title.no_people_on_1_1_list',
            defaultMessage: 'No people on 1:1 list',
          })}
          subtitle={
            <span>
              <FormattedMessage
                id="app.views.layout.people_nav.click_add_person"
                defaultMessage="Click <span>+ Add person</span> below to add a person to your 1:1 list."
                values={{
                  span: (msg) => <span className="fw-bold">{msg}</span>,
                }}
              />
            </span>
          }
        />
      )}
      {/* @ts-expect-error */}
      {fullPeopleList?.length > 0 && (
        <ul className={props.inNav ? 'navbar-nav' : 'list-group'}>
          {fullPeopleList?.map((person, i) => (
            <li
              className={props.inNav ? 'nav-item' : 'list-group-item'}
              key={i}
            >
              <div className={props.inNav && i === 0 ? 'pt-3 pb-0' : 'py-0'}>
                <div
                  className="hover-parent position-relative nav-link py-0"
                  style={{ display: 'inherit' }}
                >
                  <PersonCard
                    className={personItemClass}
                    headerClassName="navbar--person"
                    bodyOnly={true}
                    person={person}
                    showDescription={!props.inNav}
                    size={props.inNav ? 'xxs' : 'sm'}
                    linked={true}
                    urlSuffix={currentPathSuffix}
                  />
                  {person.allowRemoval && (
                    <div
                      className="hover-child position-absolute"
                      style={{
                        top: '0',
                        right: '1.5rem',
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => removePerson(person)}
                        title={formatMessage({
                          id: 'app.views.layout.people_nav.title.remove_person',
                          defaultMessage: 'remove person',
                        })}
                        className="text-muted d-block btn m-0 p-0"
                      >
                        <i className={'fe fe-x font-sm'} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <hr className="navbar-divider my-3"></hr>
      <ul className={props.inNav ? 'nav nav-sm flex-column' : 'list-group'}>
        <li className={props.inNav ? 'nav-item' : 'list-group-item'}>
          <div
            className="nav-link text-muted navbar--addperson"
            role="button"
            onClick={toggleAddPersonInput}
          >
            {showAddPersonInput && (
              <PeopleEditor
                placeholder={formatMessage({
                  id: 'app.views.layout.people_nav.placeholder.enter_name',
                  defaultMessage: 'Enter name',
                })}
                autoFocus={true}
                callback={addPeople}
                excludeList={fullPeopleList}
                excludeListErrorMessage={formatMessage({
                  id: 'app.views.layout.people_nav.error.person_already_on_list',
                  defaultMessage: ' is already on the list.',
                })}
              />
            )}
            {!showAddPersonInput && (
              <span>
                <FormattedMessage
                  id="app.views.layout.people_nav.add_person_button"
                  defaultMessage="+ Add person"
                />
              </span>
            )}
          </div>
        </li>
      </ul>
    </>
  );
};

const PeopleNav_propTypes = {
  inNav: PropTypes.bool,
  showSelf: PropTypes.bool.isRequired,
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<typeof PeopleNav_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, myConfigs } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    settings: myConfigs?.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMyConfigs: (me) => dispatch(updateMyConfigs(me)),
  };
};

// all tracking in app will be passed through here
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PeopleNav));
