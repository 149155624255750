import React, { FC } from 'react';

import PromotionPacket from './PromotionPacket';
import { useParams } from 'react-router-dom';

const PromotionPacketPage: FC = () => {
  const params = useParams<{ id: string }>();
  const promotionPacketId = params.id;

  return (
    <PromotionPacket promotionPacketId={promotionPacketId} inModal={false} />
  );
};

export default React.memo(PromotionPacketPage);
