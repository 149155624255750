import React, { FC, useCallback, useEffect, useRef } from 'react';

interface Props {
  name: string;
  label?: string | JSX.Element;
  className?: string;
  value?: boolean;
  required?: boolean;
  // with this flag, on undefined we display indeterminate:
  // https://getbootstrap.com/docs/5.0/forms/checks-radios/#indeterminate
  withIndeterminate?: boolean;
  checkedValue?: boolean | string;
  uncheckedValue?: boolean | string;
  indeterminateValue?: boolean | string;
  disabled?: boolean;
  onChange?: (value: boolean | string) => void;
}

const Checkbox: FC<Props> = ({
  name,
  label,
  value,
  className = '',
  required = false,
  checkedValue = true,
  uncheckedValue = false,
  indeterminateValue,
  withIndeterminate = false,
  disabled = false,
  onChange = (_) => {},
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked ? checkedValue : uncheckedValue);
    },
    [onChange, checkedValue, uncheckedValue]
  );

  // Intetermined is not implemented natively by checkbox
  // For this reason, we need to set it manually via js pseudo class
  // https://stackoverflow.com/a/52859693
  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.indeterminate = withIndeterminate && value === indeterminateValue;
    }
  }, [value, withIndeterminate, indeterminateValue, inputRef]);

  return (
    <>
      <input
        name={name}
        type="checkbox"
        className={'form-check-input ' + (className ?? '')}
        required={required}
        ref={inputRef}
        checked={value === checkedValue}
        disabled={disabled}
        onChange={handleChange}
      />
      {!!label && <span className="ms-3">{label}</span>}
    </>
  );
};

export default Checkbox;
