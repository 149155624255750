import LikertHeatmap, { FORMAT_VALUE } from '../Widgets/Charts/LikertHeatmap';
import {
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  getCampaignFeature,
} from '../../utils/models/Performance';
import { createCSVFileName, sumObjectValues } from '../../utils/util/util';

import AccordionTable from '../Widgets/AccordionTable';
import { CARD_TYPES } from '../Widgets/Dashboards/CardRowDashboard';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { ICONS } from '../../consts/consts';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import MultipleChoiceHeatmap from '../Widgets/Charts/MultipleChoiceHeatmap';
import React from 'react';
import TextQuestionTableCard from '../Widgets/Cards/TextQuestionTableCard';
import { getFilterDisplayName } from '../../utils/models/Filters';

export const getEngagementSurveyComponents = (
  formatMessage,
  campaign,
  data,
  title
) => {
  // if we have no data, return an empty array
  if (!data?.length) {
    return [];
  }

  const questions = getCampaignFeature(
    campaign,
    PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS
  );
  if (!questions || !questions?.length) {
    return [];
  }

  // TODO: Add in additional question types as support is built out
  const TEXT_QUESTION_TYPES = [
    INPUT_TYPES.TEXTAREA,
    INPUT_TYPES.RICH_TEXT_EDITOR,
  ];
  const LIKERT_QUESTION_TYPES = [INPUT_TYPES.LIKERT];
  const MULTIPLE_CHOICE_QUESTION_TYPES = [
    INPUT_TYPES.MULTIPLE_CHOICE,
    INPUT_TYPES.DROPDOWN,
  ];

  const textQuestions = [];
  const likertQuestions = [];
  const multipleChoiceQuestions = [];

  data.forEach((q) => {
    const qType = q?.configs?.type?.toUpperCase();

    if (TEXT_QUESTION_TYPES.includes(qType)) {
      // @ts-expect-error
      textQuestions.push(q);
    }
    if (LIKERT_QUESTION_TYPES.includes(qType)) {
      // @ts-expect-error
      likertQuestions.push(q);
    }
    if (MULTIPLE_CHOICE_QUESTION_TYPES.includes(qType)) {
      // @ts-expect-error
      multipleChoiceQuestions.push(q);
    }
  });

  const textQuestionRows = textQuestions.map((q) => ({
    // @ts-expect-error
    name: q?.configs?.name,
    // @ts-expect-error
    displayName: q?.configs?.display_name,
    // @ts-expect-error
    count: q?.all?.responses?.length,
    // @ts-expect-error
    data: q?.responses,
  }));

  const multipleChoiceQuestionRows = multipleChoiceQuestions.map((q) => {
    // @ts-expect-error
    const question = questions.find((_q) => _q.name === q?.configs?.name);

    return {
      // @ts-expect-error
      name: q?.configs?.name,
      // @ts-expect-error
      displayName: q?.configs?.display_name,
      // @ts-expect-error
      all: q?.all?.responses,
      // @ts-expect-error
      total: sumObjectValues(q?.all?.responses),
      // @ts-expect-error
      responses: q?.responses,
      options: question.objects || question.options || [],
    };
  });

  const csvFormatTextQuestion = (q) => {
    const headerRow = [
      `Question: ${q?.displayName}`,
      'Field',
      'Value',
      '',
      '',
      '',
      '',
      '',
      '',
      'Response',
    ];

    const groupings = Object.keys(q?.data || {});

    const dataRows = [];

    for (const group of groupings) {
      const groupNames = Object.keys(q?.data?.[group] || {});
      for (const groupName of groupNames) {
        for (const response of q?.data?.[group]?.[groupName]?.responses || []) {
          // @ts-expect-error
          dataRows.push([
            '',
            getFilterDisplayName(group, formatMessage),
            groupName,
            '',
            '',
            '',
            '',
            '',
            '',
            response.replace(/"/g, '""'),
          ]);
        }
      }
    }

    const result = [headerRow, ...dataRows];

    result.push(['']);
    return result;
  };

  const csvFormatNonTextQuestion = (q, options, includeAverage) => {
    const headerRow = [
      `Likert: ${q?.configs?.display_name}`,
      'Field',
      'Value',
      ...(includeAverage ? ['Average'] : []),
      ...options.map((o) => o.toString()),
    ];
    const groupings = Object.keys(q?.responses || {});
    const dataRows = [];
    for (const group of groupings) {
      const groupNames = Object.keys(q?.responses?.[group] || {});
      for (const groupName of groupNames) {
        const average = FORMAT_VALUE(
          q?.responses?.[group]?.[groupName]?.responses
        );
        // @ts-expect-error
        dataRows.push([
          '',
          getFilterDisplayName(group, formatMessage),
          groupName,
          ...(includeAverage ? [average] : []),
          ...options.map(
            (o) => q?.responses?.[group]?.[groupName]?.responses?.[o] || 0
          ),
        ]);
      }
    }

    const result = [headerRow, ...dataRows];

    result.push(['']);
    return result;
  };

  const createCsvData = () => {
    let result = [];

    // Assumption that likert only spans from 1-5
    const LIKERT_SCALE = [5, 4, 3, 2, 1];

    likertQuestions.forEach((e) => {
      // @ts-expect-error
      result = [...result, ...csvFormatNonTextQuestion(e, LIKERT_SCALE, true)];
    });

    multipleChoiceQuestionRows.forEach((e) => {
      // @ts-expect-error
      result = [
        ...result,
        ...csvFormatNonTextQuestion(
          e,
          e.options.map((o) => o.id),
          false
        ),
      ];
    });

    textQuestionRows.forEach((e) => {
      // @ts-expect-error
      result = [...result, ...csvFormatTextQuestion(e)];
    });

    return result;
  };

  const exportButton = (
    <CSVLink
      className="btn btn-light btn-sm"
      filename={createCSVFileName()}
      data={createCsvData()}
    >
      <i className={ICONS.EXPORT + ' me-2'} />
      <FormattedMessage
        id="app.views.engagement_survey.engagement_survey.csv_link.export"
        defaultMessage="
      Export
    "
      />
    </CSVLink>
  );

  const renderItem = (o) => {
    return getFilterDisplayName(o.id, formatMessage);
  };

  return [
    {
      type: CARD_TYPES.MULTICHART,
      title: (
        <div className="d-flex justify-content-between">
          <span className="align-self-center">
            {title ??
              formatMessage({
                id: 'app.views.engagement_survey.engagement_survey.responses',
                defaultMessage: 'Responses',
              })}
          </span>
          {exportButton}
        </div>
      ),
      emptyStateText: formatMessage({
        id: 'app.views.engagement_survey.engagement_survey.no_responses',
        defaultMessage: 'No responses are currently available',
      }),
      content: [
        ...(likertQuestions.length
          ? [
              <AccordionTable
                key={0}
                columns={[
                  // @ts-expect-error
                  {
                    name: formatMessage({
                      id: 'app.views.engagement_survey.engagement_survey.likert_questions',
                      defaultMessage: 'Likert questions',
                    }),
                  },
                ]}
                customContent={
                  <LikertHeatmap
                    data={likertQuestions}
                    // @ts-expect-error
                    renderItem={renderItem}
                  />
                }
              />,
            ]
          : []),
        ...(multipleChoiceQuestions.length
          ? [
              <AccordionTable
                key={1}
                columns={[
                  // @ts-expect-error
                  {
                    name: formatMessage({
                      id: 'app.views.engagement_survey.engagement_survey.multiple_choice_questions',
                      defaultMessage: 'Multiple choice questions',
                    }),
                  },
                ]}
                customContent={multipleChoiceQuestionRows.map((q, i) => (
                  <MultipleChoiceHeatmap key={i} data={[q]} />
                ))}
              />,
            ]
          : []),
        ...(textQuestions.length
          ? [
              <TextQuestionTableCard
                key={2}
                rows={textQuestionRows}
                exportButton={exportButton}
                renderItem={renderItem}
              />,
            ]
          : []),
      ],
    },
  ];
};
