import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useMemo } from 'react';

import EnpsSentimentTable from './EnpsSentimentTable';
import { EnpsSummaryResponse } from './types';
import ExportButton from '../../../../components/ExportButton/ExportButton';
import SimpleCard from '../../../Widgets/Cards/SimpleCard';
import { createCSVFileName } from '../../../../utils/util/util';
import { getIntlStrings } from './intl';

interface Props {
  data: EnpsSummaryResponse;
}

const EnpsSummaryCard: React.FC<Props> = ({ data }) => {
  const { formatMessage } = useIntl();
  const intl = getIntlStrings(formatMessage);
  const csvFilename = createCSVFileName('enps_summary');

  const cardBody = (
    <>
      <ListGroup className="mb-4">
        {data.summary && (
          <ListGroupItem>
            <h4 className="card-header-title mb-3">
              <FormattedMessage
                id="app.views.widgets.dashboards.takeaways_dashboard.enps_summary_card."
                defaultMessage="Executive Summary"
              />
            </h4>
            {data.summary?.map((para: string, index: number) => (
              <p key={index}>{para}</p>
            ))}
          </ListGroupItem>
        )}
      </ListGroup>
      {data.themes_by_segment?.promoter && (
        <Row>
          <Col>
            <EnpsSentimentTable
              segment="Promoter"
              themes={data.themes_by_segment.promoter.themes}
              comments={data.answers}
            />
          </Col>
        </Row>
      )}
      {data.themes_by_segment?.detractor && (
        <Row>
          <Col>
            <EnpsSentimentTable
              segment="Detractor"
              themes={data.themes_by_segment.detractor.themes}
              comments={data.answers}
            />
          </Col>
        </Row>
      )}
      {data.themes_by_segment?.passive && (
        <Row>
          <Col>
            <EnpsSentimentTable
              segment="Passive"
              themes={data.themes_by_segment.passive.themes}
              comments={data.answers}
            />
          </Col>
        </Row>
      )}
    </>
  );

  const csvData = useMemo(() => {
    const headers = [
      intl.Segment,
      intl.Theme,
      intl.Summary,
      intl.Description,
      intl.TopQuote,
      intl.TopQuote,
      intl.TopQuote,
      intl.RelatedComments,
    ];
    const summaryRow = [intl.Summary, intl.Summary, data.summary.join('\n\n')];
    const bodyRows: string[][] = [];

    Object.keys(data.themes_by_segment).forEach((segment) => {
      const themes = data.themes_by_segment[segment].themes;
      themes.forEach((theme) => {
        const commentList = theme.relatedComments
          ?.map((idx) => data.answers[idx].comment)
          .join('\n\n');
        const row = [
          intl[segment],
          theme.title,
          theme.summary,
          theme.observations,
          theme.examples?.[0]?.quote ?? '',
          theme.examples?.[1]?.quote ?? '',
          theme.examples?.[2]?.quote ?? '',
          commentList ?? '',
        ];
        bodyRows.push(row);
      });
    });

    return [headers, summaryRow, ...bodyRows];
  }, [intl, data.answers, data.summary, data.themes_by_segment]);

  return (
    <SimpleCard
      title={
        <div className="d-flex justify-content-between align-items-baseline">
          <FormattedMessage
            id="app.takeaways_dashboard.enps_summary_card.title"
            defaultMessage="eNPS Summary"
          />
          <ExportButton filename={csvFilename} data={csvData ?? []} />
        </div>
      }
    >
      {cardBody}
    </SimpleCard>
  );
};

export default React.memo(EnpsSummaryCard);
