import { Card, CardBody } from 'reactstrap';
import React, { FC, useMemo } from 'react';
import {
  TalentMatrixViewer,
  TalentMatrixViewerConfig,
} from 'views/TalentMatrix';

import { getPersonField } from 'utils/models/Takeaways';

interface Props {
  people: Record<string, any>;
  talentMatrices: TalentMatrixViewerConfig[];
}

const TalentMatricesCards: FC<Props> = ({ people, talentMatrices = [] }) => {
  const peopleTransformed = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(people).map(([email, values]) => [
          getPersonField(values, 'id'),
          {
            email: email,
            full_name: getPersonField(values, 'full_name'),
            id: getPersonField(values, 'id'),
            avatar: getPersonField(values, 'avatar'),
            title: getPersonField(values, 'title'),
          },
        ])
      ),
    [people]
  );

  return (
    <>
      {talentMatrices.map((talent_matrix, idx) => (
        <Card key={`talent_matrix_${idx}`}>
          <CardBody>
            <TalentMatrixViewer
              config={talent_matrix}
              idToPerson={peopleTransformed}
            />
          </CardBody>
        </Card>
      ))}
    </>
  );
};

export default React.memo(TalentMatricesCards);
