import { Col, Container, Row } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import {
  setHasSeenSelectAccountPrompt,
  setShouldShowForcedAuthModal,
} from '../../utils/models/User';

import { AUTH0_PARAMS } from '../../utils/api/AuthProvider';
import { FormattedMessage } from 'react-intl';
import { SESSION_POST_FORCED_LOGIN } from '../../consts/consts';
import { redirectLoginOptionsGenerator } from '../../utils/util/utiltsx';
import { useAuth0 } from '@auth0/auth0-react';

const SessionForceRelogin: FC = () => {
  // This component is loaded as the first step in a re-login flow.
  // Will call Auth0Provider.logout() to clear local storage and cookies,
  // then redirect to SessionPostForcedLogin for the next steps.
  const history = useHistory();

  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [logoutAlreadyCalled, setLogoutAlreadyCalled] = useState(false);

  useEffect(() => {
    if (logoutAlreadyCalled) {
      return;
    }

    if (!isAuthenticated) {
      console.debug('[customsessionmanagement][call] getAccessTokenSilently');
      getAccessTokenSilently(AUTH0_PARAMS);
      console.debug('[customsessionmanagement][call] getAccessTokenSilently');
    } else {
      setLogoutAlreadyCalled(true);
      setHasSeenSelectAccountPrompt(false);

      logout({
        // This needs to be added to Auth0 allowed logout URLs.
        returnTo:
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '') +
          SESSION_POST_FORCED_LOGIN.path,
      });
    }
  }, [getAccessTokenSilently, logout, isAuthenticated, logoutAlreadyCalled]);

  // If a user closes the Auth page before logging in, at the next attempt their
  // session will be in a bad state and Auth0Provider will throw an error. The link
  // provided in the html will help them recover from this situation.
  return (
    <Container>
      <Row>
        <Col className="bg-white justify-content-center text-center">
          <h2 className="py-4 my-4">
            <FormattedMessage
              id="app.views.session.custom_session_management.header.text"
              defaultMessage="
            Keep this window open while we log you in again.
          "
            />
          </h2>
          <p>
            <FormattedMessage
              id="app.views.session.custom_session_management.if_noting_happens"
              defaultMessage="
            If nothing happens within 5 seconds <link>click here</link>."
              values={{
                link: (text) => (
                  <Link
                    // @ts-expect-error
                    to={SESSION_POST_FORCED_LOGIN}
                    onClick={() => {
                      history.push(SESSION_POST_FORCED_LOGIN.path);
                    }}
                  >
                    {text}
                  </Link>
                ),
              }}
            />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export const SessionPostForcedLogin: FC = () => {
  // This component has 2 steps that complete the re-login flow.
  // First step is to generate the login URL and redirect the user to it.
  // The second step is executed after Auth0 login redirects here and simply
  // updates localstorage and closes the window.

  const postReloginQuerystringKey = 'c';
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  const qs = new URLSearchParams(location.search);

  if (qs.get(postReloginQuerystringKey) === null) {
    const params = redirectLoginOptionsGenerator(
      SESSION_POST_FORCED_LOGIN.path + '?' + postReloginQuerystringKey,
      false
    );
    params['prompt'] = 'login';

    console.debug('[customsessionmanagement][call] loginWithRedirect');
    loginWithRedirect(params);
    console.debug('[customsessionmanagement][response] loginWithRedirect');
  } else {
    // We clear the modal, this localstorage key has an
    // envent listener in App to monitor changes. App will then
    // clear the modal.
    setShouldShowForcedAuthModal(false);
    window.close();
  }
  return null;
};

export default SessionForceRelogin;
