import { Comment } from '../../types';
import { cloneDeep } from 'lodash';
import { statusDescriptorFor } from './Objective';

export const convertTo2Decimals = (value) =>
  value && value != 'None'
    ? (Math.round(value * 10000) / 100).toString()
    : value;

export const transformItems = (items: Comment[], intl) => {
  return items.map((item) => {
    const decoratedItem = cloneDeep(item);

    let parsedChange = item.body;
    try {
      parsedChange = JSON.parse(item.body);
    } catch (e) {
      /* do nothing */
    }

    decoratedItem.body = Object.entries(parsedChange)
      .map((item) => generateItemText(item, intl))
      .join(', ');

    return decoratedItem;
  });
};

const generateItemText = (item, intl) => {
  const [field, newValue] = generatePairsText(item[0], item[1], intl);

  // case of removal, as sent by the backend
  if (newValue === 'None') {
    return intl.formatMessage(
      {
        id: 'app.objectives.item.history.item_removed',
        defaultMessage: '{field} removed',
        description:
          'The objective has been changed, this is the message that is displayed in the history. {field} is the field that has been removed.',
      },
      { field }
    );
  }

  return intl.formatMessage(
    {
      id: 'app.objectives.item.history.item_changed',
      defaultMessage: '{field} changed to {newValue}',
      description:
        'The objective has been changed, this is the message that is displayed in the history. {field} is the field that has been modified, {newValue} is the new value of the field',
    },
    {
      field,
      newValue,
    }
  );
};

const generatePairsText = (type: string, value, intl) => {
  switch (type) {
    case 'name':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.name',
          defaultMessage: 'Title',
        }),
        value,
      ];
    case 'status':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.status',
          defaultMessage: 'Status',
        }),
        statusDescriptorFor(value, intl)?.i18n ?? '',
      ];
    case 'due_date':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.due_date',
          defaultMessage: 'Due Date',
        }),
        value,
      ];
    case 'score':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.score',
          defaultMessage: 'Amount achieved so far',
        }),
        convertTo2Decimals(value),
      ];
    case 'score_denominator':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.score_denominator',
          defaultMessage: 'Goal Amount',
        }),
        convertTo2Decimals(value),
      ];
    case 'weight':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.weight',
          defaultMessage: 'Weight',
        }),
        convertTo2Decimals(value),
      ];
    case 'score_comments':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.score_comments',
          defaultMessage: 'Notes',
        }),
        value,
      ];
    case 'collaborators':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.collaborators',
          defaultMessage: 'Collaborators',
        }),
        value,
      ];
    case 'parent':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.parent',
          defaultMessage: 'Parent objective',
        }),
        value,
      ];
    case 'coverage_start_date':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.coverage_start_date',
          defaultMessage: 'Start Date',
        }),
        value,
      ];
    case 'coverage_end_date':
      return [
        intl.formatMessage({
          id: 'app.objectives.item.history.change_type.coverage_end_date',
          defaultMessage: 'End Date',
        }),
        value,
      ];
    default:
      return [null, null];
  }
};
