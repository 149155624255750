import * as consts from '../../consts/consts';

import { Col, Container, Row } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import GeneralAdministration from './GeneralAdministration';
import { Organization } from 'types';
import Page from '../Layout/Pages/Page';
import UserAdministration from './UserAdministration';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

interface Props {
  currentOrganization: Organization;
}

const OrganizationSettings: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const tabs = useMemo(() => {
    const tabs = [];

    // @ts-expect-error
    tabs.push({
      path: consts.ORGANIZATION_SETTINGS(formatMessage).path,
      name: consts.ORGANIZATION_SETTINGS_GENERAL(formatMessage).name,
      content: <GeneralAdministration />,
    });
    // @ts-expect-error
    tabs.push({
      path: consts.ORGANIZATION_SETTINGS_USERS(formatMessage).path,
      name: consts.ORGANIZATION_SETTINGS_USERS(formatMessage).name,
      content: <UserAdministration />,
    });

    return tabs;
  }, [formatMessage]);

  return (
    <Container className="container-lg container-fluid">
      <Row className="justify-content-center">
        <Col className="col-12 col-lg-10 col-xl-8">
          <Page
            pretitle={props.currentOrganization?.name}
            title={consts.ADMINISTRATION(formatMessage).name}
            shouldRenderContainer={false}
            tabs={tabs}
          ></Page>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization } = state;

  return {
    currentOrganization,
  };
};

export default connect(mapStateToProps)(React.memo(OrganizationSettings));
