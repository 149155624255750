import { Col, Row } from 'reactstrap';
import React, { FC, useCallback } from 'react';

import ObjectsDropdown from '../Widgets/Dropdowns/ObjectsDropdown';
import { useIntl } from 'react-intl';

interface Props {
  campaigns: any;
  campaign: any;
  campaignIdToFetch: any;
  setCampaignIdToFetch: any;
  setReceivedUserPerformanceData: any;
}

export const CampaignSelectorRow: FC<Props> = ({
  campaigns,
  campaign,
  campaignIdToFetch,
  setCampaignIdToFetch,
  setReceivedUserPerformanceData,
}) => {
  const { formatMessage } = useIntl();
  const onSelectCampaign = useCallback(
    (event) => {
      const id = parseInt(event.target.value, 10);

      // if it's already loaded in the UI, do nothing
      if (campaign?.id === id) {
        return;
      }

      // clear UI so loading indicator shows
      setCampaignIdToFetch(id);
      setReceivedUserPerformanceData(false);
    },
    [campaign?.id, setCampaignIdToFetch, setReceivedUserPerformanceData]
  );
  return (
    <Row>
      <Col className="mb-4">
        <ObjectsDropdown
          objects={campaigns?.map((c) => ({
            id: c.id,
            name: formatMessage(
              {
                id: 'app.views.selector.campaign_selector_row.campaign_name_cycle',
                defaultMessage: '{campaignName} cycle',
              },
              { campaignName: c.name }
            ),
          }))}
          value={
            campaignIdToFetch
              ? campaigns.find((c) => c.id === campaignIdToFetch)?.id
              : campaigns?.length > 0 && campaigns[0]?.id
          }
          onChange={onSelectCampaign}
        />
      </Col>
    </Row>
  );
};
