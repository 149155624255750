import { useCallback, useMemo } from 'react';
import useWebSocketOriginal, { ReadyState } from 'react-use-websocket';

import config from './config';

const readyStateToString = (readyState) =>
  ({
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]);

// this is a re-usable hook for creating a websocket that connects to our
// Cloudflare Durable Objects. this should not require any modification to
// support other kinds of Durable Objects beyond 1:1 agendas, since protocol
// details are left to the caller.
export const useWebSocket = (token, onMessage) => {
  // make sure we use different endpoints for different environments so we
  // don't cross-contaminate data. this must agree with the environments
  // defined in `core/collab/wrangler.toml`

  // set hostname via if/else and isProduction
  let hostname = '';
  // @ts-expect-error
  if (config.isProduction()) {
    hostname = 'wss://edge.confirm.com';
    // @ts-expect-error
  } else if (config.isDemo()) {
    hostname = 'wss://edge-demo.confirm.com';
    // @ts-expect-error
  } else if (config.isSandbox()) {
    hostname = 'wss://edge-sandbox.confirm.com';
    // @ts-expect-error
  } else if (config.isUat()) {
    hostname = 'wss://edge-uat.confirm.com';
    // @ts-expect-error
  } else if (config.isTour()) {
    hostname = 'wss://edge-tour.confirm.com';
    // @ts-expect-error
  } else if (config.isLocal()) {
    hostname = 'wss://edge-dev.confirm.com';
    // use this instead if you are running the wrangler dev server (i.e. localhost:8787)
    // hostname = 'ws://localhost:8787';
  } else {
    console.error('websocket.ts: unknown environment, cannot create websocket');
  }

  // sometimes token will be undefined when this hook is used before the
  // component is fully set up. passing 'null' to react-use-websocket
  // results in a NOP, so we'll do that.
  const url = token ? `${hostname}/?token=${token}` : null;

  // note: because this is a hook we cannot short-circuit logic to
  // avoid calling this if we know the url is invalid
  const { sendMessage, readyState } = useWebSocketOriginal(url, {
    onMessage,
    shouldReconnect: () => true,
  });

  const send = useCallback(
    (payload) => {
      sendMessage(JSON.stringify(payload));
    },
    [sendMessage]
  );

  const status = useMemo(() => readyStateToString(readyState), [readyState]);

  // if token is undefined, we explicitly return something that is safe but
  // does nothing
  if (!token) {
    return [
      () => {
        /* NOP */
      },
      'Uninstantiated',
    ];
  }

  return [send, status];
};
