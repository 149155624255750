import { Button, Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import React, { FC, PropsWithChildren, useState } from 'react';

type Props = {
  className?: string;
  label: string | JSX.Element;
};

const AccordionItem: FC<PropsWithChildren<Props>> = ({
  className,
  label,
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={className}>
      <Button
        color="light"
        className="btn btn-block text-start w-100"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Row>
          <Col>{label}</Col>
          <Col className="col-auto mt-3" style={{ fontSize: '1.5rem' }}>
            {isOpen && <i className="fe fe-chevron-up" />}
            {!isOpen && <i className="fe fe-chevron-down" />}
          </Col>
        </Row>
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className="mt-4">
          <CardBody>{children}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default AccordionItem;
