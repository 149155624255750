import { Button, Card, Table } from 'reactstrap';
import React, { useCallback, useState } from 'react';

import SafeModal from '../../components/SafeModal';
import { decompress } from 'lz-string';
import { reverseLocalStorageFullKey } from '../../utils/models/User';

// This page is not linked anyware,
// it is just for debugging localstorage content
// open this page on http://localhost:3000/local-storage-content-test
const LocalStorageContentTest = () => {
  const [currentValue, setCurrentValue] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOnToggle = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleOnClickButton = useCallback((element) => {
    setIsModalOpen(true);
    setCurrentValue(element);
  }, []);

  return (
    <>
      <Table className="pt-4" size="sm">
        <thead>
          <tr>
            <th>{'Decrypted Key'}</th>
            <th>{'Encrypted Key'}</th>
            <th>{'Actions'}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localStorage).map(([key, value]) => {
            return (
              <tr key={key}>
                <td>{reverseLocalStorageFullKey(key)}</td>
                <td>{key}</td>
                <td>
                  <Button
                    color="primary"
                    className="btn-sm text-nowrap"
                    onClick={() => handleOnClickButton({ key, value })}
                  >
                    {'Details'}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {currentValue && (
        <LocalStorageContentTestModal
          onToggle={handleOnToggle}
          isModalOpen={isModalOpen}
          value={currentValue}
        />
      )}
    </>
  );
};

const LocalStorageContentTestModal = ({ isModalOpen, onToggle, value }) => {
  return (
    <SafeModal isOpen={isModalOpen} toggle={onToggle}>
      <Card>
        <Table className="pt-4">
          <tbody>
            <tr>
              <td>
                <b>{'Decrypted key'}</b>
              </td>
            </tr>
            <tr>
              <td>{reverseLocalStorageFullKey(value.key)}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">
                <b>{'Decrypted value'}</b>
              </td>
            </tr>
            <tr>
              <td>{decompress(value.value)}</td>
            </tr>
            <tr className="font-weight-bold">
              <td>
                <b>{'Encrypted key'}</b>
              </td>
            </tr>
            <tr>
              <td>{value.key}</td>
            </tr>
            <tr>
              <td className="font-weight-bold">
                <b>{'Encrypted value'}</b>
              </td>
            </tr>
            <tr>
              <td>{value.value}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </SafeModal>
  );
};

export default LocalStorageContentTest;
