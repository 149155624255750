import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ConfirmationDialogModalMultiAction from '../Widgets/Modals/ConfirmationDialogModalMultiAction';
import { Objective } from '../../utils/models/Objective';
import { Person } from 'types';
import { formatErrorMessageIfPresent } from 'utils/util/error';
import { toast } from 'react-toastify';
import { useObjectiveCopyToNextPeriod } from 'views/Objectives/ObjectiveOperations';

interface Props {
  objective: Objective;
  isOpen: boolean;
  onClosed?: () => void;
  toggle: () => void;
  zIndex?: number;
  person: Person;
}

const PersonalObjectiveItemCopyToNextPeriodModal: FC<Props> = ({
  objective,
  isOpen,
  onClosed: onClosedProps = () => {
    //do nothing
  },
  toggle,
  zIndex,
  person,
}) => {
  const { formatMessage } = useIntl();

  const hasChildren = (objective?.children?.length ?? 0) > 0;
  const [copyAllChildren, setCopyAllChildren] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const { action, reset, status, error } = useObjectiveCopyToNextPeriod({
    key: objective.key,
    personId: person?.id,
  });

  useEffect(() => {
    if (status === 'SUCCESS') {
      toast.success(
        formatMessage({
          id: 'app.views.person.personal_objective_item_copy_modal.toast.success',
          defaultMessage: 'Objective(s) copied successfully',
        })
      );
      reset();
      setIsSubmitted(false);
      setCopyAllChildren(false);
      onClosedProps();
    } else if (status === 'ERROR') {
      reset();
      setIsSubmitted(false);
    }
  }, [status, formatMessage, onClosedProps, reset]);

  const handleOnClickCopyButton = useCallback(() => {
    return action({ copyAllChildren: copyAllChildren });
  }, [action, copyAllChildren]);

  const handleOnChangeSubObjectivesCheckbox = useCallback(
    () => setCopyAllChildren(!copyAllChildren),
    [copyAllChildren]
  );

  const handleOnSubmitted = useCallback(() => {
    setIsSubmitted(true);
  }, []);

  const actions = useMemo(() => {
    return [
      {
        text: formatMessage({
          id: 'app.views.person.personal_objective_item_copy_modal.cancel',
          defaultMessage: 'Cancel',
        }),
        color: 'light',
        onClick: toggle,
        className: 'col-md-2',
        outline: false,
      },
      {
        text: formatMessage({
          id: 'app.views.person.personal_objective_item_copy_modal.copy',
          defaultMessage: 'Copy',
        }),
        color: 'primary',
        onClick: handleOnClickCopyButton,
        className: 'col-md-2',
        outline: false,
        skipClose: true,
      },
    ];
  }, [formatMessage, toggle, handleOnClickCopyButton]);

  return (
    <ConfirmationDialogModalMultiAction
      isOpen={isOpen}
      onClosed={onClosedProps}
      onSubmitted={handleOnSubmitted}
      toggle={toggle}
      validationErrors={formatErrorMessageIfPresent(error)}
      actions={actions}
      className="col-md-6"
      zIndex={zIndex}
      title={formatMessage({
        id: 'app.views.person.personal_objective_item_copy_to_next_period_modal.title.copy_objective_to_next_period',
        defaultMessage: 'Copy objective to the next period',
      })}
      description={formatMessage(
        {
          id: 'app.views.person.personal_objective_item_copy_modal.dialog.question.copy.simple.description',
          defaultMessage:
            'Are you sure you want to copy the following objective to the next period? <br></br> This objective will be assigned only to you. <p>{objective}</p> ',
        },
        {
          objective: objective.name,
          br: () => <br />,
          p: (chunks: ReactNode) => (
            <p className="fw-bold text-secondary mt-2 objective-to-delete-name">
              <i
                className="fe fe-arrow-right text-secondary me-2 position-relative"
                style={{ top: '1px' }}
              />
              {chunks}
            </p>
          ),
        }
      )}
    >
      {hasChildren && (
        <div className="mt-2">
          <input
            disabled={isSubmitted}
            className="form-check-input me-2"
            type="checkbox"
            title={formatMessage({
              id: 'app.views.person.personal_objective_item_copy_to_next_period_modal.title.copy_all_subobjectives',
              defaultMessage: 'Copy all subobjectives',
            })}
            checked={copyAllChildren}
            onChange={handleOnChangeSubObjectivesCheckbox}
          />
          <FormattedMessage
            id="app.views.person.personal_objective_item_copy_to_next_period_modal.checkbox.also_copy_all_of_this_objectives_subobjectives"
            defaultMessage="Also copy all of this objective's subobjectives"
          />
        </div>
      )}
    </ConfirmationDialogModalMultiAction>
  );
};

export default PersonalObjectiveItemCopyToNextPeriodModal;
