import { configForLocale } from 'locale/messages';

export const intercomArticleLink = (
  locale: string,
  articleId: number
): string => {
  return `https://support.confirm.com/${
    configForLocale(locale).intercomLocale
  }/articles/${articleId}`;
};
