import {
  Campaign,
  Organization,
  Person,
  RelationshipType,
} from '../../../types';
import { PerformanceHistoryData } from './Trajectory/HistoryChartUtils';

export const buildDemoData = (
  org: Organization,
  campaign: Campaign,
  person: Person,
  demoPeople: Person[]
): PerformanceHistoryData[] => {
  const people = demoPeople.filter((p) => p.id !== person.id);
  const data = [
    {
      person: person,
      campaign: campaign,
      benchmarks: {
        organization: {
          all: {
            gold_stars: 2.0,
            influence: 6.0,
            heads_ups: 0.0,
            positive_ona_avg: 8.0,
          },
          tenure: {
            gold_stars: 1.0,
            influence: 5.0,
            heads_ups: 0.0,
            positive_ona_avg: 7.0,
            positive_ona_percentile: 45,
          },
          cross_tenure: {
            buckets: {
              '0-2': { next_point: 3, num_people: 68, average_ona: 1.5 },
              '3-5': { next_point: 6, num_people: 107, average_ona: 5.1 },
              '6-8': { next_point: 9, num_people: 70, average_ona: 7.4 },
              '9-11': {
                next_point: 12,
                num_people: 27,
                average_ona: 9.4,
              },
              '12-14': {
                next_point: 15,
                num_people: 82,
                average_ona: 10.0,
              },
              '15-17': {
                next_point: 18,
                num_people: 72,
                average_ona: 11.7,
              },
              '18-20': {
                next_point: 21,
                num_people: 101,
                average_ona: 10.9,
              },
              '21-23': {
                next_point: 24,
                num_people: 82,
                average_ona: 11.5,
              },
              '24-26': {
                next_point: 27,
                num_people: 59,
                average_ona: 12.0,
              },
              '27-29': {
                next_point: 30,
                num_people: 28,
                average_ona: 12.7,
              },
              '30-32': {
                next_point: 33,
                num_people: 30,
                average_ona: 14.4,
              },
              '33-35': {
                next_point: 36,
                num_people: 24,
                average_ona: 16.2,
              },
              '36-38': {
                next_point: 39,
                num_people: 35,
                average_ona: 15.3,
              },
              '39-41': {
                next_point: 42,
                num_people: 34,
                average_ona: 12.7,
              },
              '42-44': {
                next_point: 45,
                num_people: 25,
                average_ona: 15.0,
              },
              '45-47': {
                next_point: 48,
                num_people: 23,
                average_ona: 15.3,
              },
              '48-50': {
                next_point: 51,
                num_people: 13,
                average_ona: 12.0,
              },
              '51-53': {
                next_point: 54,
                num_people: 17,
                average_ona: 10.9,
              },
              '54-56': {
                next_point: 57,
                num_people: 8,
                average_ona: 15.6,
              },
              '57-59': {
                next_point: 60,
                num_people: 6,
                average_ona: 14.5,
              },
              '60-62': {
                next_point: 63,
                num_people: 5,
                average_ona: 13.0,
              },
              '63-65': {
                next_point: 66,
                num_people: 4,
                average_ona: 14.2,
              },
              '66-68': {
                next_point: 69,
                num_people: 3,
                average_ona: 12.3,
              },
              '69-71': {
                next_point: 72,
                num_people: 2,
                average_ona: 10.0,
              },
              '72-74': {
                next_point: 75,
                num_people: 4,
                average_ona: 12.0,
              },
              '75-77': {
                next_point: 78,
                num_people: 2,
                average_ona: 5.0,
              },
              '78-80': {
                next_point: 81,
                num_people: 2,
                average_ona: 15.5,
              },
              '81-83': {
                next_point: 84,
                num_people: 1,
                average_ona: 14.0,
              },
              '84-86': {
                next_point: 87,
                num_people: 1,
                average_ona: 28.0,
              },
              '87-89': {
                next_point: 90,
                num_people: 0,
                average_ona: 0.0,
              },
              '90-92': {
                next_point: 93,
                num_people: 0,
                average_ona: 0.0,
              },
              '93-95': {
                next_point: 96,
                num_people: 1,
                average_ona: 34.0,
              },
            },
            overall: { slope: 0.19, intercept: 6.21 },
          },
          ratings: {
            1: { max: 4, min: 2 },
            2: { max: 24, min: 4 },
            3: { max: 78, min: 24 },
            4: { max: 100, min: 78 },
          },
          ona: {
            heads_ups: [
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0,
              1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 3.0,
            ],
            influence: [
              0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 2.0,
              2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0,
              3.0, 3.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 5.0, 5.0, 5.0,
              5.0, 5.0, 5.0, 5.0, 5.0, 6.0, 6.0, 6.0, 6.0, 6.0, 6.0, 6.0, 7.0,
              7.0, 7.0, 7.0, 7.0, 7.0, 7.88, 8.0, 8.0, 8.0, 8.0, 8.68, 9.0, 9.0,
              9.0, 9.0, 10.0, 10.0, 10.0, 10.0, 10.0, 11.0, 11.0, 11.0, 11.0,
              12.0, 12.0, 12.0, 13.0, 13.0, 13.0, 14.0, 14.0, 15.0, 15.0, 16.0,
              16.0, 17.0, 17.0, 18.0, 19.0, 20.0, 21.0, 23.0, 24.56, 27.0, 29.0,
              36.28,
            ],
            gold_stars: [
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
              0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0,
              1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 2.0, 2.0,
              2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0, 2.0,
              2.76, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0,
              4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 5.0, 5.0, 5.0, 5.0, 5.0,
              6.0, 6.0, 6.0, 7.0, 7.0, 8.0, 9.0, 11.0,
            ],
            positive_ona: [
              0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 2.0, 2.0, 2.0, 2.0,
              2.0, 2.0, 2.0, 3.0, 3.0, 3.0, 3.0, 3.0, 3.0, 4.0, 4.0, 4.0, 4.0,
              4.0, 4.0, 5.0, 5.0, 5.0, 5.0, 5.0, 5.0, 5.0, 6.0, 6.0, 6.0, 6.0,
              6.0, 7.0, 7.0, 7.0, 7.0, 7.0, 7.56, 8.0, 8.0, 8.0, 8.0, 8.0, 9.0,
              9.0, 9.0, 9.0, 9.0, 10.0, 10.0, 10.0, 10.0, 10.0, 11.0, 11.0,
              11.0, 11.0, 12.0, 12.0, 12.0, 12.0, 13.0, 13.0, 13.0, 14.0, 14.0,
              14.0, 15.0, 15.0, 15.0, 16.0, 16.0, 16.0, 17.0, 17.0, 18.0, 19.0,
              19.0, 19.0, 20.0, 21.0, 22.0, 22.0, 23.0, 25.0, 26.0, 28.0, 30.56,
              34.0, 37.0, 43.64,
            ],
          },
        },
      },
      level: 'Skilled',
      title: 'Senior InfoSec Administrator I',
      department: undefined,
      position_tenure: 1,
      tenure_months: 8,
      gold_stars: [
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:06:30.149848+00:00',
          type: RelationshipType.GIVES_GOLD_STAR_TO,
          positive_skills: [],
          positive_comments:
            'She enabled us to onboard hundreds of needed vendors & helped redesign our onboarding process.',
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 106496,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[0],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:44:35.856866+00:00',
          type: RelationshipType.GIVES_GOLD_STAR_TO,
          positive_skills: [],
          positive_comments:
            'Beryl has been relentless and was a huge help to the team with the Polaris development and rollout',
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 93986,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[1],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:53:24.225803+00:00',
          type: RelationshipType.GIVES_GOLD_STAR_TO,
          positive_skills: [],
          positive_comments:
            'Patient, diligent and really embodies the spirit of "always be helpful" throughout ENT sales cycles.',
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 98933,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[2],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
      ],
      influence: [
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:58:12.290669+00:00',
          type: RelationshipType.ADVISED_BY,
          positive_skills: [],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 101794,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[0],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:01:02.312755+00:00',
          type: RelationshipType.ENERGIZED_BY,
          positive_skills: [],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 103379,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[1],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:04:18.937620+00:00',
          type: RelationshipType.ADVISED_BY,
          positive_skills: [],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 105304,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[3],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T19:06:56.929823+00:00',
          type: RelationshipType.ENERGIZED_BY,
          positive_skills: [],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 106732,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: undefined,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[4],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:44:31.194168+00:00',
          type: RelationshipType.ENERGIZED_BY,
          positive_skills: [],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 93941,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[5],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
        {
          rating: undefined,
          negative_comments: undefined,
          created_at: '2023-07-10T18:46:43.212917+00:00',
          type: RelationshipType.ADVISED_BY,
          positive_skills: [],
          positive_comments: undefined,
          recommend_for_promotion: undefined,
          final_rating: undefined,
          salary_increase_comments: undefined,
          id: 95224,
          rating_comments: undefined,
          to_person: person,
          final_rating_provided_by_person: undefined,
          priority: undefined,
          promotion_comments: undefined,
          final_rating_comments: undefined,
          recommend_for_salary_increase: undefined,
          is_anonymous: false,
          organization: org,
          negative_skills: [],
          campaign: campaign.id,
          from_person: people[6],
          responses: undefined,
          decline_reason: undefined,
          dataset: undefined,
        },
      ],
      heads_ups: [],
      heads_ups_display: 'full',
      rating: {
        rating: 4.0,
        negative_comments: 'nothing specific',
        created_at: '2023-07-10T19:28:05.496385+00:00',
        type: 'D',
        positive_skills: [
          { name: 'We bias to action (OPIT)', id: 711, type: 'E' },
          { name: 'collaboration', id: 1019, type: 'E' },
          { name: 'due diligence', id: 2079, type: 'E' },
          { name: 'effective communication', id: 828, type: 'E' },
          { name: 'information security', id: 1450, type: 'E' },
          { name: 'organization', id: 746, type: 'E' },
          { name: 'relentlessness', id: 704, type: 'E' },
        ],
        positive_comments:
          'What Beryl brought into the risk and compliance program was order and consistency.  She drove how audit evidence is collected and how to collaborate with the different teams in a more efficient manner.  She was able to pick up the product and services quickly to drive the audits in a much smoother way\n\nAs part of the risk and compliance program, Beryl took on the vendor due diligence program and helped reshape it with the different teams from legal to procurement.  She collaborated with the different teams to build an easier VDD process\n\nOne unique aspect that Beryl took on was the infosec program management.  It was somewhat an open/empty role.  The program is high impact specifically for  the security team to organize and execute the projects.  This was not even part of her day to day responsibilities',
        recommend_for_promotion: true,
        final_rating: undefined,
        salary_increase_comments: undefined,
        id: 116056,
        rating_comments: 'She has gone above and beyond her responsibilities',
        to_person: person,
        final_rating_provided_by_person: undefined,
        priority: undefined,
        promotion_comments:
          'Beryl not only solved the problems that we needed her to solve, she worked on other problems to help the team and company scale and grow quickly',
        final_rating_comments: undefined,
        recommend_for_salary_increase: undefined,
        is_anonymous: false,
        organization: org,
        negative_skills: [
          { name: 'n/a (nothing or not applicable)', id: 1567, type: 'E' },
        ],
        campaign: campaign.id,
        from_person: people[0],
        responses: undefined,
        decline_reason: undefined,
        dataset: undefined,
        value: 4.0,
        benchmark: undefined,
      },
      ona_percentiles: {
        positive_ona: 69,
        gold_stars: 78,
        influence: 67,
      },
      is_eligible_for_reporting: true,
    },
  ];
  return data;
};
