import * as consts from '../../../consts/consts';

import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person, Relationship } from '../../../types';
import React, { FC, useMemo } from 'react';

import CardHeaderTitle from '../../Widgets/Cards/CardHeaderTitle';
import WordCloud from '../../Widgets/WordCloud';
import { getUniqueWordCloudsFromRelationships } from '../PersonPerformance/utils';

export interface SkillCardProps {
  title: string;
  wordCloud: any[];
}

const SkillCardWithTitle: FC<SkillCardProps> = ({ title, wordCloud }) => {
  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <i className="fe fe-zap me-2 text-success" />
          {title}
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {wordCloud.length > 0 ? (
          <WordCloud
            isExternalUrl={true}
            className="text-center mt-1"
            emptyText="No skills"
            objects={wordCloud}
            pathPrefix={consts.SKILLS().path}
          />
        ) : (
          // TO-DO FOR RENATO: Design empty state for this
          <div>
            <FormattedMessage
              id="app.views.person.person_performance.skills_card.no_one_ever"
              defaultMessage="No one ever"
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export interface SkillsCardProps {
  organization: Organization;
  person?: Person;
  positiveONA?: Relationship[];
}

export const SkillsCard: FC<SkillsCardProps> = (props) => {
  const { formatMessage } = useIntl();

  const allSkillWordClouds = useMemo(
    () =>
      getUniqueWordCloudsFromRelationships(
        formatMessage,
        props.positiveONA,
        'positive_skills'
      ),
    [props.positiveONA, formatMessage]
  );

  const experienceSkillsWordCloud = useMemo(
    () => allSkillWordClouds.filter((t) => t.wordObject.type !== 'B'),
    [allSkillWordClouds]
  );

  const behaviorSkillsWordCloud = useMemo(
    () => allSkillWordClouds.filter((t) => t.wordObject.type === 'B'),
    [allSkillWordClouds]
  );

  return (
    <>
      {experienceSkillsWordCloud.length > 0 && (
        <SkillCardWithTitle
          title={formatMessage({
            id: 'app.views.person.person_performance.skills_card.title.recognized_skills',
            defaultMessage: 'Recognized skills',
          })}
          wordCloud={experienceSkillsWordCloud}
        />
      )}
      {behaviorSkillsWordCloud.length > 0 && (
        <SkillCardWithTitle
          title={formatMessage({
            id: 'app.views.person.person_performance.skills_card.title.recognized_behaviors',
            defaultMessage: 'Recognized behaviors',
          })}
          wordCloud={behaviorSkillsWordCloud}
        />
      )}
    </>
  );
};
