import ModalScheduleNotificationButton, {
  getCampaignNotificationTypes,
} from '../Widgets/Modals/ModalScheduleNotificationButton';
import { NOTIFICATION_MEDIUM, NOTIFICATION_METHODS } from '../../consts/consts';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getFriendlyUserFacingErrorObjectAndMessage,
  getPrettyDate,
} from '../../utils/util/util';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import FilterablePeopleTable from '../Widgets/People/FilterablePeopleTable';
import Loading from '../Widgets/Loading';
import PropTypes from 'prop-types';
import SwitchInput from '../Widgets/Inputs/SwitchInput';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { standardConfirmAPIsendRequestToConfirmPromiseCallback } from '../../utils/util/utiltsx';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const TYPE_MAPPING = (formatMessage) => ({
  'perf-new-campaign-phase-start': formatMessage({
    id: 'app.views.administration.campaign_notifications.phase_has_started',
    defaultMessage: 'Phase has started',
  }),
  'perf-campaign-phase-ongoing': formatMessage({
    id: 'app.views.administration.campaign_notifications.phase_ongoing',
    defaultMessage: 'Phase ongoing',
  }),
  'perf-campaign-phase-ends-soon': formatMessage({
    id: 'app.views.administration.campaign_notifications.phase_ends_soon',
    defaultMessage: 'Phase ends soon',
  }),
});

const STATUS_MAPPING = (formatMessage) => ({
  W: formatMessage({
    id: 'app.views.administration.campaign_notifications.waiting_to_be_sent',
    defaultMessage: 'Waiting to be sent',
  }),
  S: formatMessage({
    id: 'app.views.administration.campaign_notifications.sending',
    defaultMessage: 'Sending',
  }),
  C: formatMessage({
    id: 'app.views.administration.campaign_notifications.completed',
    defaultMessage: 'Completed',
  }),
  F: formatMessage({
    id: 'app.views.administration.campaign_notifications.failed',
    defaultMessage: 'Failed',
  }),
  P: formatMessage({
    id: 'app.views.administration.campaign_notifications.paused',
    defaultMessage: 'Paused',
  }),
});
const EDITABLE_STATUSES = new Set(['W', 'P', 'F']);

const METHOD_MAPPING = (formatMessage) => ({
  E: formatMessage({
    id: 'app.views.administration.campaign_notifications.email',
    defaultMessage: 'Email',
  }),
  S: formatMessage({
    id: 'app.views.administration.campaign_notifications.slack',
    defaultMessage: 'Slack',
  }),
  M: formatMessage({
    id: 'app.views.administration.campaign_notifications.msteams',
    defaultMessage: 'MS Teams',
  }),
});

const SCHEDULE_TYPE_MAPPING = (formatMessage) => ({
  I: formatMessage({
    id: 'app.views.administration.campaign_notifications.immediate',
    defaultMessage: 'Immediate',
  }),
  S: formatMessage({
    id: 'app.views.administration.campaign_notifications.scheduled',
    defaultMessage: 'Scheduled',
  }),
});

const CampaignNotifications: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const [notifications, setNotifications] = useState([]);

  const defaultNotificationObject = useMemo(() => {
    const imMethods = {
      [NOTIFICATION_MEDIUM.SLACK]: NOTIFICATION_METHODS.SLACK,
      [NOTIFICATION_MEDIUM.MSTEAMS]: NOTIFICATION_METHODS.MSTEAMS,
    };

    return {
      methods: [
        NOTIFICATION_METHODS.EMAIL,
        // @ts-expect-error
        ...(imMethods[props.currentOrganization?.instant_messaging] ?? []),
      ].sort(),
    };
  }, [props.currentOrganization]);

  const [emptyNotification, setEmptyNotification] = useState(
    cloneDeep(defaultNotificationObject)
  );
  const [
    sendPerformanceNotificationsToManagers,
    setSendPerformanceNotificationsToManagers,
  ] = useState(true);
  const [status, setStatus] = useState('LOADING');

  const updatePerformanceNotificationToManagers = useCallback(
    (value) => {
      setSendPerformanceNotificationsToManagers(value);
      ConfirmAPI.sendRequestToConfirm(
        'PUT',
        // @ts-expect-error
        `campaigns/${props?.campaign?.id}/features`,
        {
          disable_send_performance_notifications_to_managers: !value,
          // @ts-expect-error
          organization: props.currentOrganization?.id,
        },
        (_data, error, hardErrorMessage) => {
          if (error || hardErrorMessage) {
            const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
              error,
              hardErrorMessage
            );
            console.error(
              'Error saving notification campaign features:',
              errorObject
            );
            toast.error(
              formatMessage({
                id: 'app.views.administration.campaign_notifications.error_changing_configuration',
                defaultMessage: 'Error changing configuration',
              })
            );
            return;
          }
          toast.success(
            formatMessage({
              id: 'app.views.administration.campaign_notifications.changed_successfully',
              defaultMessage:
                'Notification configuration changed successfully!',
            })
          );
        }
      );
    },
    // @ts-expect-error
    [formatMessage, props?.campaign?.id, props.currentOrganization?.id]
  );

  useEffect(() => {
    // @ts-expect-error
    if (props.currentOrganization?.id && props?.campaign?.id) {
      const fetchNotifications = new Promise((resolve, reject) => {
        ConfirmAPI.sendRequestToConfirm(
          'GET',
          // @ts-expect-error
          'get-notifications-by-campaign/' + props?.campaign?.id,
          // @ts-expect-error
          { organization: props.currentOrganization?.id },
          standardConfirmAPIsendRequestToConfirmPromiseCallback(resolve, reject)
        );
      });

      const fetchCampaginFeatures = new Promise((resolve, reject) => {
        ConfirmAPI.sendRequestToConfirm(
          'GET',
          // @ts-expect-error
          `campaigns/${props?.campaign?.id}/features`,
          // @ts-expect-error
          { organization: props.currentOrganization?.id },
          standardConfirmAPIsendRequestToConfirmPromiseCallback(resolve, reject)
        );
      });

      Promise.all([fetchNotifications, fetchCampaginFeatures]).then(
        ([notifications, campaignFeatures]) => {
          // @ts-expect-error
          setNotifications(notifications?.notifications);
          const disable_send_performance_notifications_to_managers =
            // @ts-expect-error
            campaignFeatures?.disable_send_performance_notifications_to_managers ??
            false;
          setSendPerformanceNotificationsToManagers(
            !disable_send_performance_notifications_to_managers
          );
          setStatus('FETCHED');
        }
      );
    }
    // @ts-expect-error
  }, [props.currentOrganization?.id, props?.campaign?.id]);

  const columns = useMemo(
    () => [
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_notifications.notification',
          defaultMessage: 'Notification',
        }),
        field: 'notification',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_notifications.status',
          defaultMessage: 'Status',
        }),
        field: 'status',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_notifications.medium',
          defaultMessage: 'Medium',
        }),
        field: 'medium',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_notifications.recipients',
          defaultMessage: 'Recipients',
        }),
        field: 'recipients',
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_notifications.actions',
          defaultMessage: 'Actions',
        }),
        field: 'action',
        sortable: false,
      },
    ],
    [formatMessage]
  );

  const actionButtons = useMemo(
    () => (
      <ModalScheduleNotificationButton
        callback={(created) => {
          setEmptyNotification(cloneDeep(defaultNotificationObject));
          // @ts-expect-error
          setNotifications([...notifications, created]);
          toast.success(
            formatMessage({
              id: 'app.views.administration.campaign_notifications.notification_scheduled_successfully',
              defaultMessage: 'Notification scheduled successfully!',
            })
          );
        }}
        object={emptyNotification}
        // @ts-expect-error
        campaign={props.campaign}
        onClosed={() =>
          setEmptyNotification(cloneDeep(defaultNotificationObject))
        }
      />
    ),
    [
      defaultNotificationObject,
      emptyNotification,
      formatMessage,
      notifications,
      props.campaign,
    ]
  );

  const editActionButton = useCallback(
    (notification) => {
      return (
        <ModalScheduleNotificationButton
          callback={(updated) => {
            // replaces the edited notification with the new one
            const newNotifications = notifications.map((x) =>
              // @ts-expect-error
              x.id === updated.id ? updated : x
            );
            // @ts-expect-error
            setNotifications(newNotifications);
            toast.success(
              formatMessage({
                id: 'app.views.administration.campaign_notifications.notification_updated_successfully',
                defaultMessage: 'Notification updated successfully!',
              })
            );
          }}
          // @ts-expect-error
          campaign={props.campaign}
          object={notification}
          readOnly={
            // Only scheduled notifications can be edited
            !EDITABLE_STATUSES.has(notification.status) ||
            notification.schedule_type !== 'S'
          }
        />
      );
    },
    [props.campaign, notifications, formatMessage]
  );

  const rows = useMemo(() => {
    const notificationTypes = Object.values(
      // @ts-expect-error
      getCampaignNotificationTypes(props.campaign, formatMessage)
    );

    const completionNofication = {
      notification: formatMessage({
        id: 'app.views.administration.campaign_notifications.completion_notification.notification',
        defaultMessage: 'Completion notifications',
      }),
      status: formatMessage({
        id: 'app.views.administration.campaign_notifications.completion_notification.status',
        defaultMessage:
          '(Ongoing) Sends to managers each time a direct report completes any phase',
      }),
      medium: formatMessage({
        id: 'app.views.administration.campaign_notifications.completion_notification.medium',
        defaultMessage: 'All',
      }),
      recipients: formatMessage({
        id: 'app.views.administration.campaign_notifications.completion_notification.recipients',
        defaultMessage: 'All',
      }),
      action: (
        <SwitchInput
          switchLabel={formatMessage({
            id: 'app.views.administration.campaign_notifications.completion_notification.switch.enabled',
            defaultMessage: 'Enabled',
          })}
          name="sendPerformanceNotificationsToManagers"
          value={sendPerformanceNotificationsToManagers}
          checkedValue={true}
          uncheckedValue={false}
          onChange={updatePerformanceNotificationToManagers}
        />
      ),
    };
    return [
      completionNofication,
      ...(notifications?.map((n) => {
        return {
          // @ts-expect-error
          ...n,
          notification: notificationTypes.find((x) => {
            // @ts-expect-error
            return x.phaseType === n.phase && x.action === n.notification_type;
          })?.name,
          // @ts-expect-error
          medium: n.methods
            .map((x) => METHOD_MAPPING(formatMessage)[x])
            .join('/'),
          // @ts-expect-error
          schedule_type: SCHEDULE_TYPE_MAPPING(formatMessage)[n.schedule_type],
          // @ts-expect-error
          type: TYPE_MAPPING(formatMessage)[n.type],
          status:
            // @ts-expect-error
            n.status === 'W'
              ? // @ts-expect-error
                n.schedule_type === 'I'
                ? formatMessage({
                    id: 'app.views.administration.campaign_notifications.queued',
                    defaultMessage: 'Queued for immediate send',
                  })
                : formatMessage(
                    {
                      id: 'app.views.administration.campaign_notifications.scheduled_for_date_time',
                      defaultMessage: 'Scheduled for {dateTime}',
                    },
                    {
                      dateTime: getPrettyDate({
                        // @ts-expect-error
                        dateString: n.scheduled_send_time,
                        hideYear: true,
                        includeTime: true,
                        locale,
                      }),
                    }
                  )
              : // @ts-expect-error
              n.status === 'C'
              ? formatMessage(
                  {
                    id: 'app.views.administration.campaign_notifications.sent_at',
                    defaultMessage: 'Sent at {dateTime}',
                  },
                  {
                    dateTime: getPrettyDate({
                      // @ts-expect-error
                      dateString: n.scheduled_send_time,
                      hideYear: true,
                      includeTime: true,
                      locale,
                    }),
                  }
                )
              : // @ts-expect-error
                STATUS_MAPPING(formatMessage)[n.status],
          action: editActionButton(n),
        };
      }) ?? []),
    ];
  }, [
    editActionButton,
    formatMessage,
    locale,
    notifications,
    props.campaign,
    sendPerformanceNotificationsToManagers,
    updatePerformanceNotificationToManagers,
  ]);

  if (status === 'LOADING') {
    return (
      <Loading
        message={formatMessage({
          id: 'app.views.administration.campaign_notifications.message.loading_notification_data',
          defaultMessage: 'Loading notification data',
        })}
      />
    );
  }

  return (
    <>
      <FilterablePeopleTable
        // TODO: determine if this flag is truly needed here
        arrayValuesUsedForFormatting={true}
        title={formatMessage({
          id: 'app.views.administration.campaign_notifications.title.notifications',
          defaultMessage: 'Notifications',
        })}
        actions={actionButtons}
        rows={rows}
        columns={columns}
        hideFilters={true}
        hideExportButton={true}
      />
    </>
  );
};

const CampaignNotifications_propTypes = {
  campaign: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof CampaignNotifications_propTypes>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(CampaignNotifications));
