import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useState } from 'react';

import AspirationInput from '../Widgets/Inputs/AspirationInput';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import GoalForm from '../Goals/GoalForm';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import Loading from '../Widgets/Loading';
import ModalPersonProfileEditButton from '../Widgets/Modals/ModalPersonProfileEditButton';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';
import { loadOrRender } from '../../utils/util/formatter';

const PersonGoals: FC<Props> = ({ setPerson = () => {}, ...props }) => {
  const { formatMessage, locale } = useIntl();
  const propsSetAspirations = props.setAspirations;
  // @ts-expect-error
  const isMe = props.person?.id === props.meId;

  const [isMounted, setIsMounted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [aspirations, setAspirations] = useState(props.aspirations);
  const [showTagSkillWarningIndices, setShowTagSkillWarningIndices] = useState(
    []
  );
  const [autosaveStatus, setAutosaveStatus] = useState(null);
  const [mostRecentAspirationIndex, setMostRecentAspirationIndex] = useState(0);

  // update UI whenever aspirations/goals are updated externally (or via save)
  useEffect(() => {
    if (props.aspirations) {
      // clear warnings when aspirations updated
      setShowTagSkillWarningIndices([]);

      // always have one goal populated in the UI if it's me
      setAspirations(
        isMe
          ? props.aspirations.map((a) => ({
              ...a,
              // @ts-expect-error
              goals: !(a.goals?.length > 0)
                ? [
                    {
                      // @ts-expect-error
                      aspirations: [a.id],
                    },
                  ]
                : // @ts-expect-error
                  a.goals,
            }))
          : props.aspirations
      );
    }
  }, [isMe, props.aspirations]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted || !props.person) {
      return;
    }

    const params = {
      // @ts-expect-error
      person: props.person.id,
      // @ts-expect-error
      organization: props.currentOrganization?.id,
    };

    ConfirmAPI.getUrlWithCache(
      '/aspirations',
      // @ts-expect-error
      null,
      null, // this is too much data, don't cache it
      // @ts-expect-error
      props.currentProxyPerson,
      params,
      (data) => {
        if (isMounted) {
          if (data?.results) {
            setAspirations(data.results);

            // set aspirations externally so adding to list updates here properly
            // @ts-expect-error
            propsSetAspirations(data.results);
          }
        }
      },
      (message) => {
        setErrorMessage(message);
      }
    );
  }, [
    isMounted,
    propsSetAspirations,
    // @ts-expect-error
    props.currentOrganization?.id,
    // @ts-expect-error
    props.currentProxyPerson,
    props.person,
  ]);

  // when autosave status updates, clear out any warnings
  useEffect(() => {
    setShowTagSkillWarningIndices([]);
  }, [autosaveStatus]);

  const setAspirationByIndex = useCallback(
    (aspiration, aspirationIndex) => {
      setMostRecentAspirationIndex(aspirationIndex);
      // @ts-expect-error
      const newAspirations = aspirations.map((a, i) =>
        i === aspirationIndex ? aspiration : a
      );
      // @ts-expect-error
      propsSetAspirations(newAspirations);
    },
    [aspirations, propsSetAspirations]
  );

  const deleteAspirationByIndex = useCallback(
    (aspirationIndex) => {
      // @ts-expect-error

      const newAspirations = aspirations.filter(
        (a, i) => i !== aspirationIndex
      );
      // @ts-expect-error
      propsSetAspirations(newAspirations);
    },
    [aspirations, propsSetAspirations]
  );

  const updateGoalOnAspiration = useCallback(
    (goal, aspirationIndex) => {
      if (goal) {
        // clear warnings when goal updated
        setShowTagSkillWarningIndices([]);

        // @ts-expect-error
        const newAspirations = props.aspirations.map((a, i) => {
          if (aspirationIndex === i) {
            // @ts-expect-error
            const goalIndex = a.goals
              ? // @ts-expect-error
                a.goals.findIndex(
                  (g) => goal.id && goal.id?.toString() === g.id?.toString()
                )
              : -1;

            return {
              ...a,
              goals:
                goalIndex === -1
                  ? // @ts-expect-error
                    [...a.goals, goal]
                  : // @ts-expect-error
                    a.goals.map((g) =>
                      g.id && g.id?.toString() === goal.id?.toString()
                        ? goal
                        : g
                    ),
            };
          } else {
            return a;
          }
        });
        // @ts-expect-error
        propsSetAspirations(newAspirations);
      }
    },
    [props.aspirations, propsSetAspirations]
  );

  const removeGoalFromAspiration = useCallback(
    (goalIndex, aspirationIndex) => {
      // clear warnings when goal removed
      setShowTagSkillWarningIndices([]);

      // @ts-expect-error
      const newAspirations = props.aspirations.map((a, i) => {
        if (aspirationIndex === i) {
          return {
            ...a,
            // @ts-expect-error
            goals: a.goals ? a.goals.filter((g, i) => i !== goalIndex) : [],
          };
        } else {
          return a;
        }
      });

      // note: we manually call set aspirations here remove it from the UI
      setAspirations(newAspirations);
      // @ts-expect-error
      propsSetAspirations(newAspirations);
    },
    [props.aspirations, propsSetAspirations]
  );

  const goalIsIncomplete = useCallback((goal) => {
    return !(goal.skills?.length > 0) && !(goal.credentials?.length > 0);
  }, []);

  // returns true if all but the last goal are complete, else false
  const otherGoalsAreComplete = useCallback(
    (aspiration) => {
      return (
        aspiration?.goals && aspiration.goals.findIndex(goalIsIncomplete) === -1
      );
    },
    [goalIsIncomplete]
  );

  const insertNewGoal = useCallback(
    (aspirationIndex) => {
      // @ts-expect-error
      if (otherGoalsAreComplete(aspirations[aspirationIndex])) {
        setAspirations(
          // @ts-expect-error
          aspirations.map((a, index) =>
            aspirationIndex === index
              ? {
                  ...a,
                  goals: [
                    // @ts-expect-error
                    ...a.goals,
                    {
                      // @ts-expect-error
                      aspirations: [a.id],
                    },
                  ],
                }
              : a
          )
        );
      } else {
        setShowTagSkillWarningIndices([
          // @ts-expect-error
          ...showTagSkillWarningIndices.filter((i) => i !== aspirationIndex),
          // @ts-expect-error
          aspirationIndex,
        ]);
      }
    },
    [otherGoalsAreComplete, aspirations, showTagSkillWarningIndices]
  );

  const isReadOnly = !isMe || props.readOnly;

  const loadOrRenderOutput = loadOrRender(aspirations, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (typeof aspirations === 'undefined') {
    return <Loading />;
  }

  const lastDevelopmentConversationAt =
    // @ts-expect-error
    props.person.profile?.last_development_conversation_at;

  return (
    <Row className="justify-content-md-center">
      <Col
        md={isMe && !props.inSidebar ? 8 : 12}
        className="order-2 order-md-1"
      >
        {/* @ts-expect-error */}
        {!isReadOnly && isMe && !(aspirations?.length > 0) && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.person.person_goals.title.add_aspirations_then_goals',
              defaultMessage: 'Add aspirations, then goals',
            })}
            subtitle={formatMessage({
              id: 'app.views.person.person_goals.subtitle.plan_the_vision_of_your_career',
              defaultMessage: 'Plan the vision of your career journey.',
            })}
            // @ts-expect-error
            inSidebar={props.inSidebar}
          >
            {/* @ts-expect-error */}
            <Button color="primary" onClick={props.toggleAspirationCreateModal}>
              <FormattedMessage
                id="app.views.person.person_goals.button.add_aspiration"
                defaultMessage="
              Add aspiration
            "
              />
            </Button>
          </EmptyState>
        )}
        {/* @ts-expect-error */}
        {!isMe && !(aspirations?.length > 0) && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.person.person_goals.title.no_aspirations_or_goals_shared',
              defaultMessage: 'No aspirations or goals shared',
            })}
            subtitle={formatMessage({
              id: 'app.views.person.person_goals.subtitle.either_none_were_created_or_they',
              defaultMessage:
                'Either none were created or they are all private.',
            })}
            // @ts-expect-error
            inSidebar={props.inSidebar}
          />
        )}
        {/* @ts-expect-error */}
        {aspirations.map((aspiration, aspirationIndex) => (
          <AspirationInput
            isReadOnly={isReadOnly}
            key={aspirationIndex}
            aspiration={aspiration}
            callback={(aspiration) =>
              setAspirationByIndex(aspiration, aspirationIndex)
            }
            onDelete={() => deleteAspirationByIndex(aspirationIndex)}
          >
            {/* @ts-expect-error */}
            {!isMe && !(aspiration.goals?.length > 0) && (
              <span className="text-muted">
                <FormattedMessage
                  id="app.views.person.person_goals.no_goals_created"
                  defaultMessage="No goals have been created yet."
                />
              </span>
            )}
            {/* @ts-expect-error */}
            {aspiration.goals?.length > 0 && (
              <h4 className="text-muted">
                <FormattedMessage
                  id="app.views.person.person_goals.goals"
                  defaultMessage="Goals"
                />
              </h4>
            )}
            {/* @ts-expect-error */}
            {aspiration.goals?.map((goal, goalIndex) => (
              <GoalForm
                inSidebar={props.inSidebar}
                key={goalIndex}
                meId={props.meId}
                isReadOnly={isReadOnly}
                goal={goal}
                callback={(g) => updateGoalOnAspiration(g, aspirationIndex)}
                onDelete={() =>
                  removeGoalFromAspiration(goalIndex, aspirationIndex)
                }
                setAutosaveStatus={setAutosaveStatus}
                autoFocus={
                  aspirationIndex === mostRecentAspirationIndex &&
                  // @ts-expect-error
                  goalIndex === aspiration.goals.length - 1
                }
              />
            ))}
            {!isReadOnly && (
              <Row className="pt-2 ps-2 ms-0 mt-2 justify-content-between">
                <Col className="col-auto">
                  <div
                    className="text-muted"
                    role="button"
                    onClick={() => insertNewGoal(aspirationIndex)}
                  >
                    <FormattedMessage
                      id="app.views.person.person_goals.add_goal"
                      defaultMessage="
                    Add goal...
                  "
                    />
                  </div>
                </Col>
                {showTagSkillWarningIndices &&
                  showTagSkillWarningIndices.findIndex(
                    (i) => i === aspirationIndex
                  ) === -1 && (
                    <Col className="col-auto text-muted">{autosaveStatus}</Col>
                  )}
                {showTagSkillWarningIndices &&
                  showTagSkillWarningIndices.findIndex(
                    (i) => i === aspirationIndex
                  ) !== -1 && (
                    <Col className="col-auto text-danger">
                      <FormattedMessage
                        id="app.views.person.person_goals.tag_a_skill"
                        defaultMessage="
                      Tag a skill or credential before adding more goals.
                    "
                      />
                    </Col>
                  )}
              </Row>
            )}
          </AspirationInput>
        ))}
      </Col>
      {!props.inSidebar && isMe && (
        <Col md={4} className="order-1 order-md-2">
          <div className="mb-5">
            <div className="fw-bold">
              <FormattedMessage
                id="app.views.person.person_goals.last_conversation_manager"
                defaultMessage="
              Last development conversation with manager:
            "
              />
            </div>
            <span className="me-2">
              {lastDevelopmentConversationAt
                ? getPrettyDate({
                    dateString: lastDevelopmentConversationAt,
                    locale,
                  })
                : formatMessage({
                    id: 'app.views.person.person_goals.title.last_development_conversation.never',
                    defaultMessage: 'Never',
                  })}
            </span>
            <ModalPersonProfileEditButton
              buttonTitle={formatMessage({
                id: 'app.views.person.person_goals.button_title.update',
                defaultMessage: 'Update',
              })}
              title={formatMessage({
                id: 'app.views.person.person_goals.title.track_your_development_conversations',
                defaultMessage: 'Track your development conversations',
              })}
              anchorTrigger="edit-intro"
              object={{
                last_development_conversation_at: lastDevelopmentConversationAt,
              }}
              transformObjectBeforeSubmit={(object) => {
                return {
                  profile: {
                    last_development_conversation_at:
                      object.last_development_conversation_at,
                  },
                };
              }}
              inputs={[
                {
                  autoFocus: false,
                  name: 'last_development_conversation_at',
                  label: formatMessage({
                    id: 'app.views.person.person_goals.last_development_conversation_with_manager',
                    defaultMessage:
                      'When was the last career development conversation you had with your manager?',
                  }),
                  type: INPUT_TYPES.DATE_PICKER,
                },
              ]}
              // @ts-expect-error
              successCallback={setPerson}
            />
          </div>
          <div className="mb-4">
            <p className="text-muted">
              <FormattedMessage
                id="app.views.person.person_goals.aspirations.description"
                defaultMessage="<bold>Aspirations</bold> are high level aims
              or desires for your life & career that can take many months,
              years, or even decades.
            "
                values={{
                  bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                }}
              />
            </p>
            <p className="text-muted">
              <FormattedMessage
                id="app.views.person.person_goals.goals.description"
                defaultMessage="<bold>Goals</bold> are part of aspirations,
              and ideally they are:
            "
                values={{
                  bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                }}
              />
            </p>
            <ul className="text-muted">
              <li>
                <FormattedMessage
                  id="app.views.person.person_goals.specific"
                  defaultMessage="<bold>S</bold>pecific"
                  values={{
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="app.views.person.person_goals.measurable"
                  defaultMessage="<bold>M</bold>easurable
              "
                  values={{
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="app.views.person.person_goals.actionable_achievable"
                  defaultMessage="<bold>A</bold>ctionable & achievable
              "
                  values={{
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="app.views.person.person_goals.relevant_realistic"
                  defaultMessage="<bold>R</bold>elevant & realistic
              "
                  values={{
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="app.views.person.person_goals.timely"
                  defaultMessage="<bold>T</bold>imely
              "
                  values={{
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </li>
            </ul>
          </div>
        </Col>
      )}
    </Row>
  );
};

const PersonGoals_propTypes = {
  readOnly: PropTypes.bool,
  inSidebar: PropTypes.bool,
  hideDates: PropTypes.bool,
  meId: PropTypes.number.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  setPerson: PropTypes.func,
  aspirations: PropTypes.arrayOf(PropTypes.object),
  setAspirations: PropTypes.func,
  aspirationCreateModalIsOpen: PropTypes.bool,
  toggleAspirationCreateModal: PropTypes.func,
  updateGoals: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof PersonGoals_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, features } = state;

  return {
    meId: me.id,
    currentOrganization: currentOrganization,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PersonGoals));
