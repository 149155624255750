import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedList, FormattedMessage } from 'react-intl';
import React, { FC, useMemo } from 'react';

import Avatar from '../Widgets/People/Avatar';
import AvatarGroup from '../Widgets/People/AvatarGroup';
import LinkedPerson from '../Widgets/People/LinkedPerson';
import PropTypes from 'prop-types';
import RelativeTime from '../Widgets/RelativeTime';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import TagsList from '../Widgets/TagsList';

const FeedbackDeclineCard: FC<Props> = (props) => {
  const feedbackDecline = props.feedbackDecline;
  const feedbackRequest = props.feedbackRequest;
  // @ts-expect-error
  const author = feedbackDecline.author_person;
  // @ts-expect-error
  const subjectPeople = feedbackRequest?.subject_people;

  const FeedbackDeclineCardBody = useMemo(
    () => (
      <Row>
        <Col>
          <Row>
            <Col>
              <FormattedMessage
                id="app.views.feedbackDecline.feedback_card.action"
                defaultMessage="<headline>{author} declined to give feedback about {subjects}</headline><secondary>{date}{activitiesOrSkills, select, null {} other { for } }{activitiesOrSkills}</secondary>"
                values={{
                  headline: (...chunks) => (
                    <span className="mb-1 mt-1 fw-normal">{chunks}</span>
                  ),
                  secondary: (...chunks) => (
                    <span className="d-block text-muted small mb-2">
                      {chunks}
                    </span>
                  ),
                  author: <LinkedPerson person={author} />,
                  subjects: (
                    <FormattedList
                      value={subjectPeople.map((person, index) => {
                        return (
                          <span key={index}>
                            <LinkedPerson
                              person={person}
                              isExternalUrl={false}
                            />
                          </span>
                        );
                      })}
                    />
                  ),
                  date: (
                    <RelativeTime
                      unit="day"
                      // @ts-expect-error
                      datetime={feedbackDecline.created_at}
                    />
                  ),
                  activitiesOrSkills:
                    // @ts-expect-error
                    feedbackRequest?.activities?.length > 0 ||
                    // @ts-expect-error
                    feedbackRequest?.skills?.length > 0 ? (
                      <TagsList
                        className="d-inline"
                        style={{ position: 'relative', top: '-1px' }}
                        // @ts-expect-error
                        activities={feedbackRequest?.activities}
                        // @ts-expect-error
                        skills={feedbackRequest?.skills}
                        isExternalUrl={props.isExternalUrl}
                        truncated={true}
                      />
                    ) : null,
                }}
              />
            </Col>
          </Row>
          <div
            className={'comment-body d-block mt-2 px-3'}
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            {/* @ts-expect-error */}
            {feedbackDecline.comments?.length > 0 && (
              <div className="mb-0 pt-2 pb-1">
                <RichTextViewer
                  // @ts-expect-error
                  model={feedbackDecline.comments}
                  expanded={true}
                />
              </div>
            )}
            {/* @ts-expect-error */}
            {!feedbackDecline.comments?.length > 0 && (
              <div className="mb-0 pt-2 pb-1 fst-italic">
                <FormattedMessage
                  id="app.views.feedbackDecline.feedback_card.no_comments"
                  defaultMessage="No comments provided"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    ),
    [
      author,
      // @ts-expect-error
      feedbackDecline.comments,
      // @ts-expect-error
      feedbackDecline.created_at,
      // @ts-expect-error
      feedbackRequest?.activities,
      // @ts-expect-error
      feedbackRequest?.skills,
      props.isExternalUrl,
      subjectPeople,
    ]
  );

  const wrappedBody = useMemo(
    () => (
      <Row
        className={
          (props.className ? props.className + ' ' : '') +
          'flex-nowrap feedback-card'
        }
      >
        {props.showAvatars && (
          <>
            <Col className="col-auto pe-2">
              <Avatar
                className="avatar-sm mt-2"
                person={author}
                linked
                // @ts-expect-error
                isExternalUrl={props.isExternalUrl}
              />
            </Col>
            <Col className="col-auto mt-2 pt-2 px-0">
              <i className="fe fe-slash" />
            </Col>
            <Col className="col-auto ps-2 me-n3">
              <AvatarGroup
                format="circle"
                className="mt-2"
                size="sm"
                people={subjectPeople}
              />
            </Col>
          </>
        )}
        <Col>{FeedbackDeclineCardBody}</Col>
      </Row>
    ),
    [
      author,
      FeedbackDeclineCardBody,
      props.className,
      props.isExternalUrl,
      props.showAvatars,
      subjectPeople,
    ]
  );

  const output = useMemo(() => {
    // @ts-expect-error
    if (props.bodyOnly) {
      return wrappedBody;
    } else {
      return (
        <Card>
          <CardBody>{wrappedBody}</CardBody>
        </Card>
      );
    }
    // @ts-expect-error
  }, [props.bodyOnly, wrappedBody]);

  return output;
};

const FeedbackDeclineCard_propTypes = {
  className: PropTypes.string,
  isExternalUrl: PropTypes.bool,
  feedbackDecline: PropTypes.object.isRequired,
  feedbackRequest: PropTypes.object.isRequired,
  showAvatars: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof FeedbackDeclineCard_propTypes>;

export default React.memo(FeedbackDeclineCard);
