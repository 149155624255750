import * as consts from '../../consts/consts';

import React, { FC } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import {
  orgIsActiveOrDemo,
  orgIsChurned,
} from '../../utils/models/Organization';

import ErrorBoundary from '../Error/ErrorBoundary';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { redirectLoginOptionsGenerator } from '../../utils/util/utiltsx';
import { useIntl } from 'react-intl';
import { userHasCompletedWelcome } from '../../utils/models/User';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { withFallbackErrorWithSpecialInstructions } from '../../hacks/hacks';
import { processRipplingData } from 'utils/util/MergeRipplingUtils';

const ProtectedRoute: FC<Props> = ({
  routes,
  match,
  component,
  meId,
  currentOrganization,
  hasCompletedWelcome,
  // @ts-expect-error
  isHRBP,
  ...args
}) => {
  const location = useLocation();
  const params = useParams();
  const { formatMessage } = useIntl();

  const isSignup = location.pathname === '/signup';

  //const authParams = {route.authParams ? route.authParams : {}}
  const breadcrumbs = routes
    // Get all routes that contain the current one (except the top level "Dashboard" "/" path)
    // @ts-expect-error
    .filter(({ path }) => path !== '/' && match.path.startsWith(path))
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    // @ts-expect-error
    .map(({ path, ...rest }) => ({
      path: Object.keys(params).length
        ? Object.keys(params).reduce(
            (path, param) => path.replace(`:${param}`, params[param]),
            path
          )
        : path,
      ...rest,
    }));

  // page to return to if we need to redirect elsewhere for some reason
  const continueUrl = location.pathname + location.search + location.hash;

  // ensure that user is attached to an organization before anything else;
  // if not, show error message (NOTE: we check for null vs undefined because
  // undefined could be due to a network outage meaning an error will show,
  // but null means the server returned no org)
  if (meId && currentOrganization === null) {
    return <Redirect to={consts.NO_ORGANIZATION(formatMessage).path} />;
  }

  // ensure that user is attached to an active (or demo) organization; if not active, show error accordingly
  // TODO: show more customized org depending on specific status
  if (
    meId &&
    currentOrganization &&
    !orgIsActiveOrDemo(currentOrganization) &&
    !orgIsChurned(currentOrganization) // handle churned orgs below
  ) {
    return <Redirect to={consts.NO_ORGANIZATION(formatMessage).path} />;
  }

  if (
    meId &&
    currentOrganization &&
    orgIsChurned(currentOrganization) &&
    !isHRBP // deny access to churned orgs unless user is an HRBP
  ) {
    return <Redirect to={consts.CHURNED_ORGANIZATION(formatMessage).path} />;
  }

  const ripplingData = processRipplingData(location.search);

  // ensure that when logged in, welcome has been completed before proceeding to given component
  if (
    meId &&
    !location.pathname?.startsWith(consts.LOGOUT(formatMessage).path) &&
    !location.pathname?.startsWith(consts.WELCOME(formatMessage).path) &&
    !ripplingData
  ) {
    if (!hasCompletedWelcome) {
      const RequireCompletedWelcomeComponent = () => {
        return (
          <Redirect
            to={
              consts.WELCOME(formatMessage).path +
              '?' +
              consts.URL_QUERY_PARAMETERS.CONTINUE_URL +
              '=' +
              encodeURIComponent(continueUrl)
            }
          />
        );
      };

      return <RequireCompletedWelcomeComponent />;
    }
  }

  // if not logged in, go back to this page after logging in
  const ComponentToRender = withAuthenticationRequired(
    // @ts-expect-error
    component,
    redirectLoginOptionsGenerator(continueUrl, isSignup)
  );

  return (
    <ErrorBoundary fallback={withFallbackErrorWithSpecialInstructions}>
      {/* @ts-expect-error */}
      <ComponentToRender match={match} breadcrumbs={breadcrumbs} {...args} />
    </ErrorBoundary>
  );
};

const ProtectedRoute_propTypes = {
  me: PropTypes.object,
  meId: PropTypes.number,
  currentOrganization: PropTypes.object,
  currentProxyPerson: PropTypes.object,
  hasCompletedWelcome: PropTypes.bool.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

type Props = PropTypes.InferProps<typeof ProtectedRoute_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { me, myConfigs, currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    meId: me?.id,
    hasCompletedWelcome: userHasCompletedWelcome(myConfigs),
  };
};

export default connect(mapStateToProps)(React.memo(ProtectedRoute));
