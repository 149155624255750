import React, { FC } from 'react';

import Page from '../Layout/Pages/Page';
import SkillsDirectory from './SkillsDirectory';
import { useIntl } from 'react-intl';

const SkillsDirectoryPage: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Page
      title={formatMessage({
        id: 'app.views.skills.skills_directory_page.title.all_skills_and_behaviors',
        defaultMessage: 'All skills and behaviors',
      })}
    >
      <SkillsDirectory />
    </Page>
  );
};

export default React.memo(SkillsDirectoryPage);
