import { useCallback, useState } from 'react';

import ConfirmAPI from './ConfirmAPI';
import { ReduxState } from '../../types';
import { getFriendlyUserFacingErrorObjectAndMessage } from '../util/util';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector } from 'react-redux';

export type ApiState = 'LOADING' | 'ERROR' | 'SUCCESS' | 'DISABLED';
export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type ApiHookParams<T> = {
  method: HttpMethod; // the http method to use
  url: string; // the url of the api call
  params?: object; // the parameters passet to the api call
  postProcess?: (data: any) => T; // this is to trasnsform the data from the api to the data we want to use
  callback?: (
    data: T | null,
    error: unknown,
    hardErrorMessage: unknown
  ) => void; // this is an alternative way to handle the data from the api if not using the hook elements
  disabled?: boolean; // this is to disable the api call
};

export type ApiHookReturn<T> = {
  status: ApiState;
  error: unknown | undefined;
  data: T | undefined;
};

export type ApiHookReturnWithDefault<T> = Omit<ApiHookReturn<T>, 'data'> & {
  data: T;
};

export const useConfirmApi = <T,>({
  method,
  url,
  params = {},
  postProcess,
  callback,
  disabled = false,
}: ApiHookParams<T>): ApiHookReturn<T> => {
  const currentProxyPersonEmail = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );

  const currentOrganizationId = useSelector<ReduxState, number | undefined>(
    (state) => state.currentOrganization?.id
  );

  const [status, setStatus] = useState<ApiState>(() => {
    return 'LOADING';
  });
  const [error, setError] = useState<unknown | undefined>(undefined);
  const [data, setData] = useState<T | undefined>(undefined);

  const apiCallback = useCallback(
    (data: T, error: unknown, hardErrorMessage: unknown) => {
      if (error || hardErrorMessage) {
        const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
          error,
          hardErrorMessage
        );
        if (callback) callback(null, error, hardErrorMessage);
        setError(errorObject);
        setStatus('ERROR');
        return;
      }

      const postProcessedData = postProcess ? postProcess(data) : data;
      if (callback) callback(postProcessedData, error, hardErrorMessage);
      setData(postProcessedData);
      setStatus('SUCCESS');
    },
    [postProcess, callback]
  );

  useDeepCompareEffect(() => {
    if (!disabled) {
      setStatus('LOADING');
      ConfirmAPI.sendRequestToConfirm(
        method,
        url,
        {
          ...params,
          proxy: currentProxyPersonEmail,
          organization_id: currentOrganizationId,
        },
        apiCallback
      );
    }
  }, [method, url, params, currentProxyPersonEmail, apiCallback, disabled]);

  return {
    status: disabled ? 'DISABLED' : status,
    error,
    data,
  };
};

export const useConfirmApiWithDefault = <T,>({
  defaultValue,
  ...params
}: ApiHookParams<T> & { defaultValue: T }): ApiHookReturnWithDefault<T> => {
  const { data, status, error }: ApiHookReturn<T> = useConfirmApi<T>(params);

  return { data: data ? data : defaultValue, status, error };
};
