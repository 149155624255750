import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import GridEditorWrapper from '../Editor/GridEditorWrapper';
import { TalentMatrixCampaignModel } from '../TalentMatrix';

interface Props {
  isEditorOpen?: boolean;
  onChange: (value: { target: { value: TalentMatrixCampaignModel } }) => void;
  value: TalentMatrixCampaignModel;
}

const EditModalButton: FC<Props> = ({
  isEditorOpen: propsIsEditorOpen = false,
  onChange,
  value,
}) => {
  const [isEditorOpen, setIsEditorOpen] = useState(propsIsEditorOpen);

  const handleOnClick = useCallback(() => {
    setIsEditorOpen(true);
  }, []);

  const handleToggle = useCallback(() => {
    setIsEditorOpen((prev) => !prev);
  }, []);

  const handleOnCallback = useCallback(
    (configuration) => {
      setIsEditorOpen(false);
      onChange({ target: { value: configuration } });
    },
    [onChange]
  );

  useEffect(() => {
    setIsEditorOpen(propsIsEditorOpen);
  }, [propsIsEditorOpen]);

  return (
    <>
      <Modal
        keyboard={false}
        backdrop="static"
        autoFocus={false}
        isOpen={isEditorOpen}
        toggle={handleToggle}
        size={'xl'}
      >
        <ModalHeader toggle={handleToggle}>
          <FormattedMessage
            id="app.views.talent_matrix.talent_matrix_campaign_editor_table_item.edit_talent_matrix"
            defaultMessage="Edit Talent Matrix"
          />
        </ModalHeader>
        <ModalBody>
          <GridEditorWrapper
            disabled={!isEditorOpen}
            onCallback={handleOnCallback}
            value={value}
          />
        </ModalBody>
      </Modal>
      <Button className="btn btn-white" onClick={handleOnClick}>
        <i
          className="fe fe-edit"
          style={{ position: 'relative', top: '2px' }}
        />{' '}
        <FormattedMessage
          id="app.views.talent_matrix.talent_matrix_campaign_editor_table_item.edit"
          defaultMessage="Edit"
        />
      </Button>
    </>
  );
};

export default EditModalButton;
