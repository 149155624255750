import React, { FC } from 'react';

import EmptyState from 'views/Widgets/EmptyState';
import { useIntl } from 'react-intl';

const DevelopmentPlansFeatureDisabled: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <EmptyState
      title={formatMessage({
        id: 'app.views.development_plans.development_plans_dashboard.title.development_plans_disabled',
        defaultMessage: 'Development plans are disabled',
      })}
      subtitle={formatMessage({
        id: 'app.views.development_plans.development_plans_dashboard.subtitle.contact_your_administrator',
        defaultMessage:
          'Contact your administrator to enable development plans.',
      })}
    />
  );
};

export default DevelopmentPlansFeatureDisabled;
