import { Card, CardBody } from 'reactstrap';
import React, { ReactNode } from 'react';

type FancyTitleCardProps = {
  icon: string;
  title: string;
  children: ReactNode;
};

const FancyTitleCard: React.FC<FancyTitleCardProps> = ({
  icon,
  title,
  children,
}) => (
  <Card>
    <CardBody>
      <h3 className="border-bottom pb-3 mb-4">
        <i className={icon + ' me-2'} />
        {title}
      </h3>
      <div className="mb-3">{children}</div>
    </CardBody>
  </Card>
);

export default FancyTitleCard;
