import React, { FC, useMemo } from 'react';
import {
  getFormattedLabel,
  sortChartLabels,
} from '../../../utils/models/Takeaways';

import { Bar } from 'react-chartjs-2';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const TakeawaysBreakdownChart: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const options = useMemo(() => {
    return {
      tooltips: {
        itemSort: (a, b) => a.datasetIndex - b.datasetIndex,
        callbacks: {
          label: (item, data) => {
            let label = data.datasets[item.datasetIndex].label || '';
            label = label.replaceAll('_', ' ');
            const value = item.yLabel.toFixed(2);
            return (
              '&nbsp;' +
              formatMessage(
                {
                  id: 'app.views.widgets.charts.takeaways_breakdown_chart.tooltips.label',
                  defaultMessage: 'Average {label}: {value}',
                },
                { label, value }
              )
            );
          },
          labelColor: (item, chart) => {
            const dataset = chart.config.data.datasets[item.datasetIndex];
            return dataset.label === 'tenure'
              ? {
                  borderColor: dataset.borderColor,
                  backgroundColor: dataset.borderColor,
                }
              : {
                  borderColor: dataset.borderColor,
                  backgroundColor: dataset.backgroundColor,
                };
          },
        },
      },
      scales: {
        yAxes: [
          {
            display: true,
            position: 'left',
            id: 'bar-chart-axis',
            scaleLabel: {
              display: true,
              labelString: props.barChartValueFieldLabel,
            },
            ticks: {
              maxTicksLimit: 5,
            },
          },
          {
            display: true,
            drawTicks: false,
            position: 'right',
            id: 'line-chart-axis',
            pointRadius: 1,
            scaleLabel: {
              display: true,
              labelString: formatMessage({
                id: 'app.views.widgets.charts.takeaways_breakdown_chart.y_axes.scale_label.avg_tenure_months',
                defaultMessage: 'Avg Tenure (months)',
              }),
            },
          },
        ],
      },
    };
  }, [props.barChartValueFieldLabel, formatMessage]);

  const chartData = useMemo(() => {
    if (!props.data) {
      return undefined;
    }
    const sortedData = props.data.slice().sort(sortChartLabels);
    const barChartDataset = {
      type: 'bar',
      label: props.barChartValueFieldName,
      yAxisID: 'bar-chart-axis',
      data: sortedData.map(
        // @ts-expect-error
        (x) => x[`${props.barChartValueFieldName}__stats`].average
      ),
      order: 2,
      backgroundColor: props.color,
    };
    const lineChartDataset = {
      type: 'line',
      label: props.lineChartValueFieldName,
      yAxisID: 'line-chart-axis',
      // @ts-expect-error
      data: sortedData.map((x) => x[props.lineChartValueFieldName]),
      order: 1,
      tension: 0,
      borderColor: props.lineColor,
      borderDash: [8, 2],
      borderWidth: 1.5,
    };

    return {
      labels: sortedData.map((x) =>
        // @ts-expect-error
        getFormattedLabel(props.xAxisLabel, x.name)
      ),
      datasets: [barChartDataset, lineChartDataset],
    };
  }, [
    props.barChartValueFieldName,
    props.color,
    props.data,
    props.lineChartValueFieldName,
    props.lineColor,
    props.xAxisLabel,
  ]);
  return chartData ? (
    <Bar data={chartData} options={options} />
  ) : (
    <EmptyState
      title={formatMessage({
        id: 'app.views.widgets.charts.bar_with_line_chart.title.no_data_available',
        defaultMessage: 'No data available',
      })}
    />
  );
};

const TakeawaysBreakdownChart_propTypes = {
  color: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  barChartValueFieldName: PropTypes.string.isRequired,
  barChartValueFieldLabel: PropTypes.string.isRequired,
  lineChartValueFieldName: PropTypes.string.isRequired,
  lineColor: PropTypes.string,
  xAxisLabel: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof TakeawaysBreakdownChart_propTypes>;

export default React.memo(TakeawaysBreakdownChart);
