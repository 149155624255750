import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReduxState } from 'types';
import { connect } from 'react-redux';

interface Props {
  meUrl?: string;
}

const MyProfile: FC<Props> = (props) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // do nothing if the required fields aren't present
    if (!history && !props.meUrl) {
      return;
    }

    // if subroute was passed in, e.g. /profile/performance, redirect to the appropriate tab
    // @ts-expect-error
    const subroute = props.match.params.subroute
      ? // @ts-expect-error
        '/' + props.match.params.subroute
      : '';

    // redirect to appropriate place (including any query params if any were passed in)
    return history.replace(props.meUrl + subroute + location.search);
    // @ts-expect-error
  }, [history, location.search, props.match.params.subroute, props.meUrl]);

  return <></>;
};

const mapStateToProps = (state: ReduxState) => {
  const { me } = state;

  return { meUrl: me?.url };
};

export default connect(mapStateToProps)(React.memo(MyProfile));
