import { Card, CardBody, CardHeader } from 'reactstrap';
import { Organization, Person, ReduxState } from 'types';
import React, { FC, useCallback, useEffect, useState } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

interface Props {
  currentOrganization: Organization;
  currentProxyPerson: Person;
}

const UserAdministration: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [organizationDetails, setOrganizationDetails] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    if (props.currentOrganization?.id) {
      ConfirmAPI.getUrlWithCache(
        '/organizations/' + props.currentOrganization.id,
        '/organizations/' + props.currentOrganization.id,
        userSub,
        props.currentProxyPerson,
        {},
        (data) => {
          setOrganizationDetails(data);
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [props.currentOrganization?.id, userSub, props.currentProxyPerson]);

  const getUserCard = useCallback(
    (label, name, placeholder, isDict = false) => {
      return (
        <Card>
          <CardHeader>
            <CardHeaderTitle>{label}</CardHeaderTitle>
          </CardHeader>
          <CardBody>
            <p className="text-muted small">{placeholder}</p>
            <div className="alert alert-light small">
              <div>
                {/* @ts-expect-error */}
                {organizationDetails?.settings[name] ? (
                  isDict ? (
                    <pre>
                      {JSON.stringify(
                        // @ts-expect-error
                        organizationDetails?.settings[name],
                        null,
                        2
                      )}
                    </pre>
                  ) : (
                    // @ts-expect-error
                    organizationDetails?.settings[name].join(', ')
                  )
                ) : (
                  formatMessage({
                    id: 'app.user.organization.user_administration.cards.none',
                    defaultMessage: 'None',
                  })
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      );
    },
    // @ts-expect-error
    [organizationDetails?.settings, formatMessage]
  );

  const loadOrRenderOutput = loadOrRender(organizationDetails, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return (
    <>
      {getUserCard(
        formatMessage({
          id: 'app.user.organization.user_administration.cards.title.system_administrators',
          defaultMessage: 'System administrators',
        }),
        'system_admins',
        formatMessage({
          id: 'app.user.organization.user_administration.cards.description.system_administrators',
          defaultMessage:
            'Typically technical admins. They have full system access to configure all aspects of Confirm for your organization.',
        })
      )}
      {getUserCard(
        formatMessage({
          id: 'app.user.organization.user_administration.cards.title.hr_administrators',
          defaultMessage: 'HR administrators',
        }),
        'admins',
        formatMessage({
          id: 'app.user.organization.user_administration.cards.description.hr_administrators',
          defaultMessage:
            'Typically HRBPs i.e. HR/People admins. They have HR admin access to specific groups of people across all campaigns.',
        }),
        true
      )}
      {getUserCard(
        formatMessage({
          id: 'app.user.organization.user_administration.cards.title.rating_dashboard_administrators',
          defaultMessage: 'Rating dashboard administrators',
        }),
        'rating_dashboard_admins',
        formatMessage({
          id: 'app.user.organization.user_administration.cards.description.rating_dashboard_administrators',
          defaultMessage:
            'Typically executives (and some HRBPs i.e. HR/People admins). They can see the rating dashboard of all Performance ratings.',
        })
      )}
      {getUserCard(
        formatMessage({
          id: 'app.user.organization.user_administration.cards.title.engagement_dashboard_administrators',
          defaultMessage: 'Engagement dashboard administrators',
        }),
        'engagement_dashboard_admins',
        formatMessage({
          id: 'app.user.organization.user_administration.cards.description.engagement_dashboard_administrators',
          defaultMessage:
            'Typically executives (and some HRBPs i.e. HR/People admins). They can see the results of engagement surveys, along with HR administrators.',
        })
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(UserAdministration));
