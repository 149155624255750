import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';

// This is a Async version of the CSVLink component
// Since this issue https://github.com/react-csv/react-csv/issues/350
// will probably never be fixed, we need to create our own version of the CSVLink component

interface Props {
  data: object[];
  headers: HeaderDescriptor[];
  filename: string;
  className?: string;
  onClick: (done: (proceed: boolean) => void) => void;
}

type State = 'READY' | 'IN_PROGRESS';

export type HeaderDescriptor = {
  key: string;
  label: string;
};

const CSVLinkAsync: FC<PropsWithChildren<Props>> = ({
  data,
  headers,
  filename,
  children,
  onClick,
  className = 'btn-sm',
}) => {
  const [state, setState] = useState<State>('READY');
  const ref = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(
    undefined
  );
  const handleDone = useCallback((proceed: boolean) => {
    if (proceed == null || proceed === true) {
      ref?.current?.link?.click();
    }
    setState('READY');
  }, []);

  useEffect(() => {
    if ('IN_PROGRESS' === state) {
      onClick(handleDone);
    }
  }, [handleDone, onClick, state]);

  return (
    <>
      <Button
        color="light"
        className={className}
        onClick={() => {
          if ('READY' === state) {
            setState('IN_PROGRESS');
          }
        }}
      >
        {children}
      </Button>
      <CSVLink
        data={data}
        ref={ref}
        headers={headers}
        filename={filename}
        target="_blank"
      />
    </>
  );
};

export default CSVLinkAsync;
