import ActivitiesDirectory from './ActivitiesDirectory';
import Page from '../Layout/Pages/Page';
import React from 'react';
import { useIntl } from 'react-intl';

const ActivitiesDirectoryPage = () => {
  const { formatMessage } = useIntl();

  return (
    <Page
      title={formatMessage({
        id: 'app.views.activities.activities_directory_page.title.all_activities',
        defaultMessage: 'All activities',
      })}
    >
      <ActivitiesDirectory />
    </Page>
  );
};

ActivitiesDirectoryPage.propTypes = {};

// all tracking in app will be passed through here
export default React.memo(ActivitiesDirectoryPage);
