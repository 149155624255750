import { Person, Relationship } from '../../../../types';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { ReactNode, useContext, useMemo } from 'react';
import { compareRatingScales } from './TrajectoryUtils';

import { Bar } from 'react-chartjs-2';
import CardHeaderTitle from '../../../Widgets/Cards/CardHeaderTitle';
import { getCampaignRatings } from '../../../../utils/models/Performance';
import PersonPerformanceContext from '../PersonPerformanceContext';

interface ManagerRatingDistributionChartProps {
  person?: {
    id: number;
    given_name: string;
  };
  isInReviewFlow: boolean;
  hideRatingFromDirectReport: boolean;
  managerRelationshipsWithFeedback?: Relationship[];
  previousRatingDifference?: number;
  manager: Person;
}

const ManagerRatingDistributionChart: React.FC<
  ManagerRatingDistributionChartProps
> = ({ person, isInReviewFlow }) => {
  const { formatMessage } = useIntl();

  const firstName = person?.given_name ?? '';

  const personPerformanceContext = useContext(PersonPerformanceContext);

  const selectedCampaign = personPerformanceContext.selectedCampaignData;

  const previousCampaignWithRating =
    personPerformanceContext.previousCampaignDataWithRating;

  const latestRating = selectedCampaign?.rating;

  const latestRatingValue = latestRating?.value;

  const selectedCampaignRatingScale = useMemo(
    () =>
      getCampaignRatings(selectedCampaign?.campaign).sort(
        (a, b) => b.value - a.value
      ),
    [selectedCampaign]
  );

  const latestRatingDefinition = useMemo(
    () =>
      selectedCampaignRatingScale?.find((r) => r.value === latestRatingValue),
    [selectedCampaignRatingScale, latestRatingValue]
  );

  const latestRatingDescription = useMemo(() => {
    const ratingDescription =
      latestRatingDefinition?.description ||
      formatMessage({
        id: 'app.views.person.person_performance.trajectory.summary.no_rating_description',
        defaultMessage:
          'Your manager has not provided you with a rating for this cycle.',
      });

    return ratingDescription;
  }, [formatMessage, latestRatingDefinition?.description]);

  const previousRatingDifference = useMemo(() => {
    // if the person is not eligible in one of the two campaigns,
    // there is no point in calculating a difference
    if (
      !previousCampaignWithRating?.is_eligible_for_reporting ||
      !selectedCampaign?.is_eligible_for_reporting
    ) {
      return null;
    }

    // if the person didn't receive a rating, don't compute the difference
    // == on purpose here, I want to check null or undefined
    if (
      selectedCampaign?.rating?.value == null ||
      previousCampaignWithRating?.rating?.value == null
    ) {
      return null;
    }

    const previousCampaignRatingScale = getCampaignRatings(
      previousCampaignWithRating?.campaign
    ).sort((a, b) => b.value - a.value);

    if (
      !previousCampaignRatingScale ||
      !compareRatingScales(
        previousCampaignRatingScale,
        selectedCampaignRatingScale
      )
    )
      return null;

    const prevRating = previousCampaignWithRating?.rating?.value;
    return (latestRatingValue ?? 0) - prevRating;
  }, [
    latestRatingValue,
    previousCampaignWithRating,
    selectedCampaign,
    selectedCampaignRatingScale,
  ]);

  const latestRatingsDetails = useMemo(() => {
    const benchmarks = selectedCampaign?.benchmarks?.organization?.ratings;
    let bucketText = '';
    let ratingDetails: JSX.Element | null = null;
    if (
      benchmarks &&
      typeof benchmarks === 'object' &&
      Object.keys(benchmarks).length > 0
    ) {
      const dataValues: number[] = [];

      const sortedRatings = Object.keys(benchmarks)
        // convert strings to int once
        .map((x) => ({ key: x, value: parseInt(x) }))
        // sort the list of objects
        .sort((a, b) => a.value - b.value)
        // discard the parsed int, we just need the string key
        .map((x) => x.key);
      sortedRatings.forEach((r) => {
        dataValues.push(benchmarks[r].max - benchmarks[r].min);
      });

      const highlightIndex = sortedRatings.findIndex(
        (e) => e === String(latestRatingValue)
      );

      const borderArray = Array(sortedRatings.length).fill('#B1C2D9');
      const backgroundArray = Array(sortedRatings.length).fill('#B1C2D9');

      if (highlightIndex >= 0) {
        borderArray[highlightIndex] = '#2c7be5';
        backgroundArray[highlightIndex] = '#2c7be5';

        const ratingBenchmark = benchmarks[sortedRatings[highlightIndex]];
        bucketText =
          ' ' +
          formatMessage(
            {
              id: 'app.views.person.person_performance.trajectory.summary.rating_bucket',
              defaultMessage:
                'Their latest rating places them between the {minPercentile}-{maxPercentile, selectordinal, one {#st} two {#nd} few {#rd} other {#th} } percentile.',
            },
            {
              minPercentile: ratingBenchmark.min,
              maxPercentile: ratingBenchmark.max,
            }
          );
      }

      const ratingScaleDict = selectedCampaignRatingScale.reduce(
        (acc, curr) => ({ ...acc, [curr.value.toString()]: curr.name }),
        {}
      );

      const xAxisLabels = sortedRatings.map((r) => ratingScaleDict[r]);

      const chartData = {
        labels: xAxisLabels,
        datasets: [
          {
            data: dataValues,
            borderColor: borderArray,
            backgroundColor: backgroundArray,
          },
        ],
      };

      const chartOptions = {
        tooltips: {
          enabled: false,
          // this looks like a bug in the chart-js library we are using
          // just disabling the tooltip doesn't make any effect,
          // using an empty custom callback will disable it
          custom: function () {}, // eslint-disable-line
        },
        maintainAspectRatio: true,
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: formatMessage({
                  id: 'app.views.person.person_performance.trajectory.summary.rating_bucket_chart.x_scale_label',
                  defaultMessage: 'Rating',
                }),
              },
              ticks: {
                display: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: formatMessage({
                  id: 'app.views.person.person_performance.trajectory.summary.rating_bucket_chart.y_scale_label',
                  defaultMessage: '% participants',
                }),
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
              gridLines: {
                display: true,
                color: '#edf2f9',
                lineWidth: 2,
              },
            },
          ],
        },
      };

      ratingDetails = (
        <div className="mt-4 me-4">
          <Bar data={chartData} options={chartOptions} />
        </div>
      );
    }

    let diffStatement: ReactNode;
    if (previousRatingDifference === null) {
      diffStatement = null;
    } else if (previousRatingDifference > 0) {
      diffStatement = formatMessage(
        {
          id: 'app.views.person.person_performance.trajectory.summary.diff_rating_improved',
          defaultMessage:
            "{firstName}'s manager rating has <bold>increased by {previousRatingDifference, plural, =1 {# point} other {# points}}</bold> from the previous cycle. {bucketSentence}",
        },
        {
          firstName: firstName,
          bucketSentence: bucketText,
          previousRatingDifference: new String(previousRatingDifference),
          bold: (chunks) => <span className="fw-bold">{chunks}</span>,
        }
      );
    } else if (previousRatingDifference < 0) {
      diffStatement = formatMessage(
        {
          id: 'app.views.person.person_performance.trajectory.summary.diff_rating_worsened',
          defaultMessage:
            "{firstName}'s manager rating has <bold>decreased by {previousRatingDifference, plural, =1 {# point} other {# points}}</bold> from the previous cycle. {bucketSentence}",
        },
        {
          firstName: firstName,
          bucketSentence: bucketText,
          previousRatingDifference: new String(previousRatingDifference * -1),
          bold: (chunks) => <span className="fw-bold">{chunks}</span>,
        }
      );
    } else {
      diffStatement = formatMessage(
        {
          id: 'app.views.person.person_performance.trajectory.summary.diff_rating_unchanged',
          defaultMessage:
            "{firstName}'s manager rating remains unchanged from the previous cycle. {bucketSentence}",
        },
        { firstName: firstName, bucketSentence: bucketText }
      );
    }

    return (
      <>
        {ratingDetails}
        {diffStatement !== null ? (
          <>
            <hr />
            {diffStatement}
          </>
        ) : null}
      </>
    );
  }, [
    formatMessage,
    selectedCampaign?.benchmarks?.organization?.ratings,
    selectedCampaignRatingScale,
    latestRatingValue,
    previousRatingDifference,
    firstName,
  ]);

  return (
    <Card className="card-fill">
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.person.person_performance.trajectory.manager_history_chart.header"
            defaultMessage="Manager ratings distribution"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {/* latest-rating */}
        {!isInReviewFlow && (
          <>
            <Row>
              <Col>{latestRatingsDetails}</Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <h6 className="text-muted text-uppercase">
                  <FormattedMessage
                    id="app.views.person.person_performance.trajectory.trajectory_summary.manager_rating_distribution.how_to_read"
                    defaultMessage="Rating description"
                  />
                </h6>
                <p>{latestRatingDescription}</p>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ManagerRatingDistributionChart;
