import React, { FC } from 'react';

import PropTypes from 'prop-types';
import UnlinkedAvatar from './UnlinkedAvatar';
import { useHistory } from 'react-router-dom';

const LinkedAvatar: FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { linked, linkToSubPath, ...remainingProps } = props;
  const person = props.person;
  const history = useHistory();

  // @ts-expect-error
  if (!person?.id || !person.url) {
    // @ts-expect-error
    return <UnlinkedAvatar {...remainingProps} />;
  }
  // @ts-expect-error
  const link = person.url + (linkToSubPath ?? '');
  const onClick = () => {
    if (props.isExternalUrl) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };
  // @ts-expect-error

  return <UnlinkedAvatar {...remainingProps} onClick={onClick} />;
};

const LinkedAvatar_propTypes = {
  person: PropTypes.object.isRequired,
  size: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  imageStyle: PropTypes.object,
  hidePopover: PropTypes.bool,
  linked: PropTypes.bool,
  isExternalUrl: PropTypes.bool,
  linkToSubPath: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof LinkedAvatar_propTypes>;

export default React.memo(LinkedAvatar);
