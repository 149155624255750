import { toast as reactToast } from 'react-toastify';
import { throttle } from 'lodash';

const THROTTLE_TIME_MS = 10 * 1000;

const THROTTLE_OPTIONS = {
  trailing: false,
  leading: true,
};

const TOAST_FUNCTIONS = ['success', 'info', 'error', 'warning', 'warn', 'dark'];

const toast = {
  ...reactToast,
  ...TOAST_FUNCTIONS.reduce(
    (acc, key) => ({
      ...acc,
      [key]: throttle(reactToast[key], THROTTLE_TIME_MS, THROTTLE_OPTIONS),
    }),
    {}
  ),
};

export { toast };
