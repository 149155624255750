import {
  EventSerializerContextType,
  useEventsSerializer as useEventSerializerImlp,
} from './hooks';

import React from 'react';

export const EventSerializerContext =
  React.createContext<EventSerializerContextType>([
    () => {
      throw new Error('EventSerializerContext is not implemented');
    },
    false,
  ]);

export const EventSerializerProvider = ({
  onSubmit,
  onSuccessCallback,
  onErrorCallback,
  initialRetryDelayInMillis = 500,
  children,
}) => {
  const useEventSerializer: EventSerializerContextType = useEventSerializerImlp(
    onSubmit,
    onSuccessCallback,
    onErrorCallback,
    {
      initialRetryDelayInMillis,
    }
  );
  return (
    <EventSerializerContext.Provider value={useEventSerializer}>
      {children}
    </EventSerializerContext.Provider>
  );
};

export const useEventSerializer = () => {
  const context = React.useContext(EventSerializerContext);
  if (context === undefined) {
    throw new Error('useIntl must be used within a EventSerializerProvider');
  }
  return context;
};
