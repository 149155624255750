import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useMemo, useState } from 'react';

import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import FilterablePeopleTable from '../People/FilterablePeopleTable';
import { INPUT_TYPES } from './ValidatedInputTypes';
import PropTypes from 'prop-types';
import ValidatedInput from './ValidatedInput';

const ActionsComponent = (props) => {
  const { formatMessage } = useIntl();

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = useCallback(
    () => setConfirmDeleteModal(!confirmDeleteModal),
    [confirmDeleteModal]
  );

  const confirmDeleteCallback = useCallback(() => {
    props.onDelete();
    toggleConfirmDeleteModal();
  }, [props, toggleConfirmDeleteModal]);

  return (
    <>
      <ConfirmationDialogModal
        isOpen={confirmDeleteModal}
        toggle={toggleConfirmDeleteModal}
        confirmCallback={confirmDeleteCallback}
        title={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.title',
          defaultMessage: 'Delete this item?',
        })}
        description={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.description',
          defaultMessage: 'Are you sure that you want to delete this item?',
        })}
        confirmText={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.confirm_text',
          defaultMessage: 'Delete item',
        })}
      />
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn btn-sm btn-rounded-circle btn-white"
          role="button"
        >
          <i
            className="fe fe-more-horizontal"
            style={{ position: 'relative', top: '2px' }}
          />
        </DropdownToggle>
        <DropdownMenu end>
          <div>
            <DropdownItem onClick={toggleConfirmDeleteModal}>
              <FormattedMessage
                id="app.views.widgets.inputs.table_editor.dropdown.delete"
                defaultMessage="Delete"
              />
            </DropdownItem>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

const TableEditor = (props) => {
  const { formatMessage } = useIntl();
  const [cellBeingEdited, setCellBeingEdited] = useState(null);
  const propsOnChange = props.onChange;
  const propsAddText =
    props.addText ??
    formatMessage({
      id: 'app.views.widgets.inputs.table_editor.add_text',
      defaultMessage: 'Add item',
    });
  const changeCellValue = useCallback(
    (rowIndex, field, value, uniqueIndexIdentifier) => {
      // to handle the undefined/blank case, set cell being edited to the unique index identifier
      setCellBeingEdited(uniqueIndexIdentifier);

      // replace the specific cell value and return a new list
      const newList = props.value.map((item, index) => {
        if (rowIndex === index) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      });

      propsOnChange(newList);
    },
    [props.value, propsOnChange]
  );

  const onAddRow = useCallback(() => {
    const newListValue = [
      ...props.value,
      props.columns.reduce((acc, col) => {
        acc[col.field] = col.generateDefaultValue
          ? col.generateDefaultValue(props.value?.length, props.value)
          : col.defaultValue;
        return acc;
      }, {}),
    ];

    propsOnChange(newListValue);
  }, [propsOnChange, props.value, props.columns]);

  const onDeleteRow = useCallback(
    (row) => {
      const newListValue = props.value.filter((r) => r !== row);
      propsOnChange(newListValue);
    },
    [props.value, propsOnChange]
  );

  const onDeleteRowIndex = useCallback(
    (rowIndex) => () => {
      const newListValue = props.value.filter((r, index) => index !== rowIndex);
      propsOnChange(newListValue);
    },
    [props.value, propsOnChange]
  );

  const onRowsReordered = useCallback(
    (rows) => {
      propsOnChange(rows);
    },
    [propsOnChange]
  );

  const rows = useMemo(() => {
    return props.value?.map((item, index) => ({
      ...item,
      ...(props.deletingDisabled || props.isReadOnly || !props.showActions
        ? {}
        : {
            actions: [
              null,
              <ActionsComponent
                key={index}
                onDelete={() => onDeleteRow(item)}
              />,
            ],
          }),
    }));
  }, [
    onDeleteRow,
    props.deletingDisabled,
    props.value,
    props.isReadOnly,
    props.showActions,
  ]);

  // add a column for actions (i.e. edit and delete)
  const columns = useMemo(
    () => [
      ...props.columns.map((col) => ({
        ...col,
        editable: props.isReadOnly || col.editable,
        renderIfEmpty: true,
        // note: col.editable === false && typeof v === 'undefined case
        // is not needed because the cell will be empty and the user can
        // click on it to edit it
        format: (v, uniqueIndexIdentifier, rowIndex, personFields) => (
          <>
            {col.editable === false && typeof v !== 'undefined' && <>{v}</>}
            {col.editable !== false &&
              typeof v !== 'undefined' &&
              uniqueIndexIdentifier !== cellBeingEdited &&
              col.type !== INPUT_TYPES.CUSTOM_COMPONENT && (
                <div className="m-n3">
                  <div
                    className="list-editor-editable-cell rounded p-3"
                    role="button"
                    onClick={() => setCellBeingEdited(uniqueIndexIdentifier)}
                  >
                    {v}
                  </div>
                </div>
              )}
            {col.editable !== false &&
              (typeof v === 'undefined' ||
                col.type === INPUT_TYPES.CUSTOM_COMPONENT ||
                uniqueIndexIdentifier === cellBeingEdited) && (
                <ValidatedInput
                  {...col}
                  name={props.name + '_' + col.field}
                  type={col.type}
                  value={v}
                  placeholder={col.placeholder}
                  formGroupClassName="mb-0"
                  blur={
                    // if no input value, then keep the cell active and editable
                    v
                      ? (event) => {
                          if (
                            // if the related target is not a translation button,
                            // then set the cell being edited to null
                            !event?.relatedTarget?.className?.includes(
                              'translation-button'
                            )
                          ) {
                            setCellBeingEdited(null);
                          }
                        }
                      : undefined
                  }
                  autoFocus={uniqueIndexIdentifier === cellBeingEdited}
                  onChange={(e) => {
                    changeCellValue(
                      rowIndex,
                      col.field,
                      col.formatValueOnSubmit &&
                        (!col.suspendFormatValueOnSubmit ||
                          !col.suspendFormatValueOnSubmit(e.target.value))
                        ? col.formatValueOnSubmit(e.target.value)
                        : e.target.value,
                      uniqueIndexIdentifier
                    );
                  }}
                  onDelete={onDeleteRowIndex(rowIndex)}
                  translationNamespace={col.translationNamespace}
                  jsonPath={
                    col && col.jsonPathItemGenerator && col.jsonPath
                      ? col.jsonPathItemGenerator(col.jsonPath, personFields)
                      : undefined
                  }
                />
              )}
          </>
        ),
      })),
      ...(props.deletingDisabled || props.isReadOnly || !props.showActions
        ? []
        : [
            {
              name: 'Action',
              field: 'actions',
              sortable: false,
              hideFromCSV: true,
              hideFromFilters: true,
            },
          ]),
    ],
    [
      cellBeingEdited,
      changeCellValue,
      onDeleteRowIndex,
      props.columns,
      props.deletingDisabled,
      props.name,
      props.isReadOnly,
      props.showActions,
    ]
  );

  const showAddButton = useMemo(
    () => !props.addingDisabled,
    [props.addingDisabled]
  );

  const output = useMemo(
    () => (
      <>
        {showAddButton && !(rows?.length > 0) && (
          <Button color="white" onClick={onAddRow} disabled={props.isReadOnly}>
            <i
              className="fe fe-plus"
              style={{ position: 'relative', top: '2px' }}
            />{' '}
            {propsAddText}
          </Button>
        )}
        {rows?.length > 0 && (
          <>
            <FilterablePeopleTable
              // TODO: determine if this flag is truly needed here
              arrayValuesUsedForFormatting={true}
              hideFilters={true}
              hideExportButton={true}
              rowsAreReorderable={
                !props.reorderingDisabled && !props.isReadOnly
              }
              rawReorderableRows={props.value}
              onChange={onRowsReordered}
              rows={rows}
              columns={columns}
            />
            {showAddButton && (
              <Button
                className="btn btn-white"
                onClick={onAddRow}
                disabled={props.isReadOnly}
              >
                <i
                  className="fe fe-plus"
                  style={{ position: 'relative', top: '2px' }}
                />{' '}
                {propsAddText}
              </Button>
            )}
          </>
        )}
      </>
    ),
    [
      propsAddText,
      props.reorderingDisabled,
      props.value,
      rows,
      onAddRow,
      onRowsReordered,
      columns,
      showAddButton,
      props.isReadOnly,
    ]
  );

  return output;
};

TableEditor.defaultProps = {
  reorderingDisabled: false,
  addingDisabled: false,
  deletingDisabled: false,
  showActions: true,
  isReadOnly: false,
};

TableEditor.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  addText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isReadOnly: PropTypes.bool,
  showActions: PropTypes.bool,
};

export default TableEditor;
