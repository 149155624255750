import React, { Component, ErrorInfo, ReactNode, forwardRef } from 'react';
interface Props {
  children: ReactNode;
  fallback: ReactNode | ((error?: Error, info?: ErrorInfo) => ReactNode);
}

interface State {
  hasError: boolean;
  error?: Error;
  info?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error, info: ErrorInfo) {
    return { hasError: true, error, info };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      if (typeof this.props.fallback === 'function') {
        return this.props.fallback(this.state.error, this.state.info);
      }
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

export const withSelfRecoveryErrorBoundary = (Component) => {
  const ComponentWithBoundary = (props: object, ref) => (
    <ErrorBoundary fallback={() => <Component {...props} ref={ref} />}>
      <Component {...props} ref={ref} />
    </ErrorBoundary>
  );
  return forwardRef(ComponentWithBoundary);
};
