import 'flatpickr/dist/themes/airbnb.css';

import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  datesAreEqual,
  getMMDDYYYYStringFromDate,
  parseDate,
} from '../../../utils/util/util';

import Flatpickr from 'react-flatpickr';
import { InputGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { configForLocale } from '../../../locale/messages';
import { getDatePart } from '../../../utils/util/time';
import { useIntl } from 'react-intl';

export const YYYY_MM_DD_WITH_DASHES_FORMAT = 'YYYY-MM-DD';
export const MM_DD_YYYY_WITH_SLASHES_FORMAT = 'MM/DD/YYYY';

const convertValueToDate = (value) =>
  // ensure we don't treat empty strings as anything
  typeof value === 'string' && value?.length > 0
    ? new Date(
        // if this isn't an iso string, parse the value so it
        // shows up in the current user's timezone, e.g. if it's
        // a YYYY-MM-DD string without a time
        // @ts-expect-error
        value.indexOf('T') === -1 ? parseDate(value) : value
      )
    : value;

/*
Checks if the specified langCode requires 24 hours time or 12 hours time.
If no langCode is specified, the default language of the browser is used instead
*/
const localeUses24HourTime = (langCode) => {
  return (
    // @ts-expect-error
    new Intl.DateTimeFormat(langCode, {
      hour: 'numeric',
    })
      .formatToParts(new Date(2020, 0, 1, 13))
      .find((part) => part.type === 'hour').value.length === 2
  );
};

const CustomInput: FC<CustomInputProps> = ({
  placeholder = 'MM/DD/YYYY',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  className,
  inputClassName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  defaultValue,
  inputRef,
  onClearDate,
  hideClearButton,
  autoFocus,
  onBlur,
  label,
  ...props
}) => {
  return (
    // @ts-expect-error
    <InputGroup className={className}>
      <input
        // @ts-expect-error
        autoFocus={autoFocus}
        // @ts-expect-error
        onBlur={onBlur}
        className={
          inputClassName ? 'form-control ' + inputClassName : 'form-control'
        }
        {...props}
        // @ts-expect-error
        placeholder={placeholder}
        // @ts-expect-error
        defaultValue={defaultValue}
        ref={inputRef}
        data-toggle="flatpickr"
        aria-label={label}
      />
      {!hideClearButton && (
        // @ts-expect-error
        <div role="button" onClick={onClearDate}>
          <span
            className="input-group-text"
            style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
          >
            {'×'}
          </span>
        </div>
      )}
    </InputGroup>
  );
};

const CustomInput_propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  inputRef: PropTypes.func,
  onClearDate: PropTypes.func,
  hideClearButton: PropTypes.bool,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  inputClassName: PropTypes.string,
};

type CustomInputProps = PropTypes.InferProps<typeof CustomInput_propTypes>;

const DatePicker: FC<Props> = ({
  altInput = false,
  altFormat = undefined,
  dateFormat = 'm/d/Y',
  placeholder = 'MM/DD/YYYY',
  onOpen = () => {
    /* do nothing */
  },
  ...props
}) => {
  const { locale, formatMessage } = useIntl();
  const fp = useRef();
  const [validationMessage, setValidationMessage] = useState(null);

  const propsOnChange = props.onChange;
  const propsFormat = props.format;
  const propsEnableTime = props.enableTime;
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;

  const use24HourTime = useMemo(() => {
    // @ts-expect-error
    localeUses24HourTime();
  }, []);

  const onClearDate = useCallback(() => {
    if (fp?.current) {
      // @ts-expect-error
      fp.current.flatpickr.clear();
    }
  }, [fp]);

  const inputtedDateValue = useMemo(
    () => convertValueToDate(props.value),
    [props.value]
  );

  const onChange = useCallback(
    (selectedDates) => {
      if (propsOnChange) {
        if (!(selectedDates?.length > 0)) {
          // date cleared out/empty, so set to null
          propsOnChange(null);
        } else {
          // select only one date (as date object)
          const newDate = selectedDates[0];

          // if this is same as inputted date, don't do anything
          // @ts-expect-error
          if (datesAreEqual(newDate, inputtedDateValue, propsEnableTime)) {
            return;
          }

          propsOnChange(
            // TODO: support other format as needed
            propsFormat === YYYY_MM_DD_WITH_DASHES_FORMAT
              ? getDatePart(newDate)
              : propsFormat === MM_DD_YYYY_WITH_SLASHES_FORMAT
              ? getMMDDYYYYStringFromDate(newDate)
              : newDate
          );
        }
      }
    },
    [inputtedDateValue, propsFormat, propsOnChange, propsEnableTime]
  );

  useEffect(() => {
    if (propsRequired) {
      const isValid =
        typeof inputtedDateValue !== 'undefined' &&
        inputtedDateValue !== null &&
        inputtedDateValue !== '';
      setValidationMessage(
        // @ts-expect-error
        isValid
          ? null
          : formatMessage({
              id: 'app.utils.models.date_picker.please_select_a_date',
              defaultMessage: 'Please select a date.',
            })
      );
    } else {
      setValidationMessage(null);
    }
  }, [inputtedDateValue, propsRequired, formatMessage]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  const onReady = useCallback(
    (e, funcs, flatpickr) => {
      if (props.autoFocus) {
        // wait 750ms second to open (to allow for rendering of modal);
        // without this, screens like the add activity screen when
        // closed and reopened while datepicker is open will have
        // date picker show up in the wrong place
        setTimeout(() => {
          flatpickr?.open();
        }, 750);
      }
    },
    [props.autoFocus]
  );

  return (
    <Flatpickr
      ref={fp}
      name={props.name}
      label={props.label}
      // per documentation, date strings must match dateFormat provided below
      value={inputtedDateValue}
      className={props.className}
      onChange={onChange}
      // @ts-expect-error
      role={props.role}
      style={props.style}
      options={{
        locale: configForLocale(locale).datePicker,
        enableTime: props.enableTime,
        time_24hr: use24HourTime,
        onReady: onReady,
        // if someone manually types, we need this to catch that change
        onClose: onChange,
        onOpen: onOpen,
        size: props.size,
        disabled: props.disabled,
        allowInput: true,
        autoComplete: 'off', // disable autocomplete for all dates (not a good UX)
        dateFormat: props.enableTime
          ? // @ts-expect-error
            dateFormat + (use24HourTime ? ' H:i' : ' h:i K')
          : dateFormat,
        altInput: altInput,
        altFormat: altFormat,
        minDate: props.minDate,
        maxDate: props.maxDate,
        defaultDate: props.defaultDate,
      }}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ defaultValue, value, className, ...otherProps }, ref) => {
        return (
          <CustomInput
            className={className}
            inputClassName={props.inputClassName}
            onClearDate={onClearDate}
            defaultValue={defaultValue}
            value={value}
            inputRef={ref}
            hideClearButton={props.hideClearButton}
            placeholder={placeholder}
            autoFocus={props.autoFocus}
            // @ts-expect-error
            disabled={props.disabled}
            label={props.label}
          />
        );
      }}
    ></Flatpickr>
  );
};

const DatePicker_propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.number,
  bsSize: PropTypes.string,
  disabled: PropTypes.bool,
  hideClearButton: PropTypes.bool,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  enableTime: PropTypes.bool,
  dateFormat: PropTypes.string,
  altFormat: PropTypes.string,
  altInput: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onValidChange: PropTypes.func,
  defaultDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onOpen: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof DatePicker_propTypes>;

export default React.memo(DatePicker);
