import { Col, List, Progress, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ObjectiveStatusCount,
  STATUS_DESCRIPTOR_NOT_SET,
  statusDescriptorFor,
} from '../../utils/models/Objective';
import React, { FC } from 'react';

import SafeUncontrolledPopover from '../../components/SafeUncontrolledPopover';
import { toPercent } from '../../utils/util/util';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  width?: number;
  breakdown: ObjectiveStatusCount;
};

const ObjectivesBreakdownBar: FC<Props> = ({ breakdown, width = 100 }) => {
  const intl = useIntl();
  const progressRefId = `progress-${uuidv4()}`;

  const total = Object.values(breakdown).reduce((acc, value) => acc + value, 0);

  if (total === 0) {
    return <div style={{ width, opacity: 0.65 }} />;
  }

  return (
    <Row className="align-items-center ">
      <Col>
        <div style={{ width, opacity: 0.65 }}>
          <Progress multi id={progressRefId}>
            {Object.entries(breakdown).map(([key, value], index) => (
              <Progress
                key={index}
                bar
                style={{
                  backgroundColor:
                    statusDescriptorFor(key, intl)?.colorHex ??
                    STATUS_DESCRIPTOR_NOT_SET(intl).colorHex,
                }}
                value={(value / total) * 100}
              />
            ))}
          </Progress>
          {total > 0 && (
            <SafeUncontrolledPopover
              placement="top"
              trigger="hover"
              target={progressRefId}
            >
              <List
                className="mx-2  my-1 d-flex gap-2 flex-column "
                type="unstyled"
              >
                {Object.entries(breakdown).map(([key, value]) =>
                  value ? (
                    <div key={key}>
                      <FormattedMessage
                        id="app.views.objectives.objectives_breakdown_bar.hover.text"
                        defaultMessage="<b>{percent}%</b> ({value} of {total}) {status}"
                        values={{
                          percent: toPercent(value, total),
                          value,
                          total,
                          b: (chunks) => <b>{chunks}</b>,
                          status: (
                            <span
                              style={{
                                color:
                                  statusDescriptorFor(key, intl)?.colorHex ??
                                  STATUS_DESCRIPTOR_NOT_SET(intl).colorHex,
                              }}
                            >
                              {statusDescriptorFor(key, intl)?.i18n ??
                                STATUS_DESCRIPTOR_NOT_SET(intl).i18n}
                            </span>
                          ),
                        }}
                      />
                    </div>
                  ) : null
                )}
              </List>
            </SafeUncontrolledPopover>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ObjectivesBreakdownBar;
