import { Features, ReduxState } from 'types';
import React, { FC } from 'react';

import DevelopmentPlan from './DevelopmentPlan';
import DevelopmentPlansFeatureDisabled from './DevelopmentPlansFeatureDisabled';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

interface Props {
  features: Features;
}
const DevelopmentPlanPage: FC<Props> = ({ features }) => {
  const params = useParams<{ id: string }>();
  const promotionPacketId = params.id;

  if (!features?.development_plans?.enabled) {
    return <DevelopmentPlansFeatureDisabled />;
  }

  return (
    <>
      <DevelopmentPlan
        developmentPlanId={Number(promotionPacketId)}
        inModal={false}
      />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(DevelopmentPlanPage));
