import { FormattedMessage, type IntlShape } from 'react-intl';
import { Person, Relationship, Skill } from '../../../../types';

import React from 'react';
import { getUnattributedPerson } from '../../../../utils/models/Person';
import { groupBy } from 'lodash';
import { isRawValueFollowedByOutputValueArrayOfTwo } from '../../../../utils/util/util';

export const ONA_BREAKDOWN = ['below_chain_of_command', 'same_group', 'other'];

export type ONAInfo = {
  giver: Person;
  comments: string;
  skills: Skill[];
  is_anonymous: boolean;
};

export interface TeamDashboardPerson {
  influence_count: number;
  influence_advised?: Relationship[];
  influence_energized?: Relationship[];
  influence_wordcloud_skills?: {
    name: string;
    count: number;
  }[];
  goldstars_count: number;
  goldstars?: number | Relationship[];
  headsups_count: number;
  headsups?: number | Relationship[];
}

const filterAbbreviationMap = {
  t: 'title',
  p: 'people',
  m: 'manager',
  a: 'manager_or_above',
  l: 'level_id',
  e: 'level',
  f: 'function',
  x: 'function_id',
  c: 'cost_center',
  z: 'cost_center_id',
  u: 'country',
  d: 'department',
  q: 'department_id',
  b: 'business_unit',
  g: 'business_unit_id',
  r: 'final_rating_text',
  v: 'final_rating_provided_by_person',
  o: 'location',
  h: 'hrbp',
  i: 'leader',
  n: 'calibration_text',
  s: 'calibration_status',
};

export const getFilterAbbreviationForType = (fieldName) => {
  for (const key in filterAbbreviationMap) {
    if (fieldName === filterAbbreviationMap[key]) {
      return key;
    }
  }

  return undefined;
};

export const getFilterTypeForAbbreviation = (abbr) => {
  return filterAbbreviationMap[abbr];
};

const makeONAInfo = (relationship, hash, formatMessage) => {
  const giver = relationship.is_anonymous
    ? getUnattributedPerson(formatMessage, hash, relationship.type, true)
    : relationship.from_person;
  const comments =
    relationship.type === 'G'
      ? relationship.positive_comments
      : relationship.negative_comments;
  const skills =
    relationship.type === 'G'
      ? relationship.positive_skills
      : relationship.negative_skills;
  return {
    giver,
    comments,
    skills,
    is_anonymous: relationship.is_anonymous,
  };
};

export const getONACellListHeader = (
  ona_source_type: string,
  intl: IntlShape
): string => {
  const { formatMessage } = intl;
  switch (ona_source_type) {
    case 'below_chain_of_command':
      return formatMessage({
        id: 'app.views.widgets.dashboards.team_dashboard.ona_cell_list_header.below_chain_of_command',
        defaultMessage: 'from below chain of command',
      });
    case 'same_group':
      return formatMessage({
        id: 'app.views.widgets.dashboards.team_dashboard.ona_cell_list_header.same_group',
        defaultMessage: 'from same group',
      });
    case 'other':
      return formatMessage({
        id: 'app.views.widgets.dashboards.team_dashboard.ona_cell_list_header.other',
        defaultMessage: 'from outside group',
      });
    default:
      return '';
  }
};

export const getONACellListHelperText = (
  ona_source_type: string
): React.ReactNode => {
  switch (ona_source_type) {
    case 'below_chain_of_command':
      return null;
    case 'same_group':
      return (
        <FormattedMessage
          id="app.views.widgets.dashboards.team_dashboard.ona_cell_list_helper_text.same_group"
          defaultMessage="Contains feedback from people who share a group identifier (e.g. Department, Cost Center, etc.) and are not in the reporting chain of the person they are leaving feedback about."
        />
      );
    case 'other':
      return (
        <FormattedMessage
          id="app.views.widgets.dashboards.team_dashboard.ona_cell_list_helper_text.other"
          defaultMessage="Contains feedback from people who do not share any group identifier (e.g. Department, Cost Center, etc.) and are not in the reporting chain of the person they are leaving feedback about."
        />
      );
    default:
      return <></>;
  }
};

export const groupONAByType = (relationships, formatMessage) => {
  // group list of relationships by ona_source_type using lodash
  const groupedONA = groupBy(relationships, 'ona_source_type');
  const listOfLists = ONA_BREAKDOWN.map((ona_source_type) => {
    const onaList = groupedONA[ona_source_type] ?? [];
    const onaInfoList = onaList.map((item, index) =>
      makeONAInfo(item, index, formatMessage)
    );
    return [ona_source_type, onaInfoList] as [string, ONAInfo[]];
  });

  const nonemptyLists = listOfLists.filter(
    ([_, onaInfoList]) => onaInfoList.length > 0
  );

  return nonemptyLists;
};

const countGroupONAByType = (relationships, formatMessage) => {
  const groupedONA = groupONAByType(relationships, formatMessage);
  return groupedONA.reduce(
    (acc, [onaSourceType, onaList]) => ({
      ...acc,
      [onaSourceType]: onaList.length,
    }),
    {}
  );
};

export const formatCSVONAKeyLabelByType = (
  labelPrefix: string,
  keyPrefix: string,
  intl: IntlShape
) => {
  return ONA_BREAKDOWN.map((onaItem) => ({
    label: `${labelPrefix} (${getONACellListHeader(onaItem, intl)})`,
    key: `${keyPrefix}_${onaItem}`,
  }));
};

export const formatCSVONACountsByType = (
  prefix: string,
  relationship,
  formatMessage
) => {
  return ONA_BREAKDOWN.reduce(
    (acc, onaItem) => ({
      ...acc,
      [`${prefix}_${onaItem}`]:
        countGroupONAByType(relationship?.[1] ?? [], formatMessage)?.[
          onaItem
        ] ?? 0,
    }),
    {}
  );
};

export const formatCSVONAIdsByType = (prefix: string, ids: string[]) => {
  return ONA_BREAKDOWN.reduce(
    (acc, onaItem) => ({
      ...acc,
      [`${prefix}_${onaItem}`]: ids.join(', '),
    }),
    {}
  );
};

export const defaultFormatRelationshipItem = (
  formatRelationship,
  formatRelationshipIfEmtpy = ''
): ((rel) => string) => {
  return (relationship) =>
    relationship.giver?.full_name +
    ': ' +
    (formatRelationship(relationship) || formatRelationshipIfEmtpy);
};

export const formatCSVONARelationshipByType = (
  prefix,
  relationship,
  formatMessage,
  formatItem
) => {
  return ONA_BREAKDOWN.reduce(
    (acc, onaItem) => ({
      ...acc,
      [`${prefix}_${onaItem}`]: (isRawValueFollowedByOutputValueArrayOfTwo(
        relationship
      )
        ? groupONAByType(relationship[1], formatMessage).find(
            ([onaItemType, _grouped]) => onaItemType === onaItem
          )?.[1] ?? []
        : []
      )?.reduce((str, rel) => {
        return (str?.length > 0 ? str + '\n' : '') + formatItem(rel);
      }, ''),
    }),
    {}
  );
};

export const formatGiverEmail = (rel) => {
  return rel?.giver?.email ?? '';
};

export const formatSkills = (rel) => {
  return rel.skills?.map((s) => s.name).join(', ') ?? '';
};
