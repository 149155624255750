import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import React, { FC, useMemo } from 'react';

import PropTypes from 'prop-types';
import { labellize } from '../../../utils/util/util';

const DashboardDropdown: FC<Props> = (props) => {
  const currentOption = useMemo(
    // @ts-expect-error
    () => props?.options?.find((opt) => opt[0] === props?.selected),
    [props.options, props.selected]
  );

  // @ts-expect-error
  return currentOption === undefined || currentOption[0] === undefined ? (
    <></>
  ) : // @ts-expect-error
  props.options.length === 1 ? (
    // @ts-expect-error
    labellize(currentOption[0])
  ) : (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle tag="span" className="text-primary" role="button">
        {/* @ts-expect-error */}
        {labellize(currentOption[0])}
      </DropdownToggle>
      <DropdownMenu end>
        {/* @ts-expect-error */}
        {props.options
          // @ts-expect-error
          .filter((opt) => opt[0] !== currentOption[0])
          .map((opt, index) => (
            // @ts-expect-error
            <DropdownItem key={index} onClick={() => props.onClick(opt[1])}>
              {/* @ts-expect-error */}
              {labellize(opt[0])}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const DashboardDropdown_propTypes = {
  /** when option is chosen, callback taking the option value */
  onClick: PropTypes.func,
  /** one element for each option, each element is a pair of string name and object value */
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  /** string identifying which option is currently selected */
  selected: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof DashboardDropdown_propTypes>;

export default React.memo(DashboardDropdown);
