import * as consts from '../../consts/consts';

import React, { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TagsList: FC<Props> = (props) => {
  const MAX_TAGS_SHOWN_BY_DEFAULT = 4;

  const [isTruncated, setIsTruncated] = useState(props.truncated);
  const toggleTruncation = () => {
    setIsTruncated(!isTruncated);
  };

  const activities =
    // @ts-expect-error
    props.activities?.length > 0
      ? // @ts-expect-error
        props.activities.map((a) => ({
          path: consts.ACTIVITIES().path,
          ...a,
        }))
      : [];
  const credentials =
    // @ts-expect-error
    props.credentials?.length > 0
      ? // @ts-expect-error
        props.credentials.map((c) => ({
          path: consts.CREDENTIALS().path,
          ...c,
        }))
      : [];
  const skills =
    // @ts-expect-error
    props.skills?.length > 0
      ? // @ts-expect-error
        props.skills.map((s) => ({
          path: consts.SKILLS().path,
          ...s,
        }))
      : [];
  const people =
    // @ts-expect-error
    props.people?.length > 0
      ? // @ts-expect-error
        props.people.map((p) => ({
          path: consts.PEOPLE_SUBROUTE().path,
          // @ts-expect-error
          name: p.full_name,
          ...p,
        }))
      : [];
  const tags = [...people, ...activities, ...credentials, ...skills];

  if (tags.length === 0) {
    return <></>;
  }

  return (
    // @ts-expect-error
    <div className={props.className} style={props.style}>
      <span className="me-n2">
        {props.children}
        {tags
          .slice(
            0,
            isTruncated && tags.length !== MAX_TAGS_SHOWN_BY_DEFAULT + 1
              ? MAX_TAGS_SHOWN_BY_DEFAULT
              : tags.length
          )
          .map((tag, tagIndex) => (
            <span key={tagIndex}>
              {/* @ts-expect-error */}
              {tag.id && (
                <Link
                  // @ts-expect-error
                  to={tag.path + '/' + tag.id}
                  target={props.isExternalUrl ? '_blank' : undefined}
                  rel="noopener noreferrer"
                >
                  <span className="badge rounded-pill me-2 bg-primary-soft">
                    {/* @ts-expect-error */}
                    {tag.name}
                  </span>
                </Link>
              )}
              {/* @ts-expect-error */}
              {!tag.id && (
                <span className="badge rounded-pill me-2 bg-primary-soft">
                  {/* @ts-expect-error */}
                  {tag.name}
                </span>
              )}
            </span>
          ))}
        {isTruncated &&
          tags.length !== MAX_TAGS_SHOWN_BY_DEFAULT + 1 &&
          tags.length > MAX_TAGS_SHOWN_BY_DEFAULT && (
            <span
              className="small text-muted me-2"
              role="button"
              onClick={toggleTruncation}
            >
              <FormattedMessage
                id="app.widgets.tags_list.more_tags"
                defaultMessage="and {count, number} more"
                values={{ count: tags.length - MAX_TAGS_SHOWN_BY_DEFAULT }}
                description="comes after a set of tags which are usually a list of skills/behaviors"
              />
            </span>
          )}
      </span>
    </div>
  );
};

const TagsList_propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  path: PropTypes.string,
  activities: PropTypes.arrayOf(PropTypes.object),
  credentials: PropTypes.arrayOf(PropTypes.object),
  skills: PropTypes.arrayOf(PropTypes.object),
  people: PropTypes.arrayOf(PropTypes.object),
  isExternalUrl: PropTypes.bool,
  truncated: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof TagsList_propTypes>;

export default React.memo(TagsList);
