import * as consts from '../../../consts/consts';

import {
  CUSTOM_QUESTION_ANONYMOUS_PREFIX,
  CUSTOM_QUESTION_NONANONYMOUS_PREFIX,
  HARDCODED_QUESTION_NAMES_LIST,
} from '../../../utils/models/Performance';
import { Card, CardBody, Col, Row, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, useIntl, type IntlShape } from 'react-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce, isEqual } from 'lodash';

import { INPUT_TYPES } from './ValidatedInputTypes';
import ModalEditor from '../Modals/ModalEditor';
import Question from '../Questions/Question';
import QuestionEditorModalTitle from './QuestionEditorModalTitle';
import RevealLink from '../RevealLink';
import { USER_GENERATED_INPUT_TYPE_OPTIONS } from './ValidatedInput';
import { joinJsonPath } from '../../../utils/util/jsonpath';
import { stripInvalidHtmlSelectorCharacters } from '../../../utils/util/util';
import {
  Campaign,
  QuestionDefaults,
  QuestionType,
  UserGeneratedInputType,
  UserGeneratedInputTypeAttribute,
} from 'types';
import { isPhaseLocked, isQuestionLocked } from 'utils/models/Campaign';
import { useCampaignEditorPhase } from 'views/Administration/CampaignEditorPhaseContext';

// Add here fields that should be removed from the question object before saving
const SANITIZE_FIELDS = ['organization', 'canBePrivate', 'canBeAnonymous'];

// for generating unique ids (e.g. for custom questions)
const getUniqueTimestampedIdAsString = () => {
  return new Date().getTime().toString();
};

export const generateUniqueQuestionId = () => {
  return 'question_' + getUniqueTimestampedIdAsString();
};

const getDefaultCustomAttributesForType = (
  type: string,
  formatMessage: IntlShape['formatMessage']
): Partial<UserGeneratedInputTypeAttribute> => {
  const typeObj = USER_GENERATED_INPUT_TYPE_OPTIONS(formatMessage).find(
    (typeOption) => typeOption.id === type
  );

  if (typeObj?.attributes) {
    return typeObj.attributes.reduce((acc, attribute) => {
      return {
        ...acc,
        [attribute.name]: attribute.defaultValue,
      };
    }, {});
  } else {
    return {};
  }
};

const computePreviewKey = (question) => {
  if (!question) {
    return 'preview';
  }
  return question.type === INPUT_TYPES.RICH_TEXT_EDITOR
    ? `${question.name}_${question.type}_${question.placeholder}`
    : `${question.name}_${question.type}`;
};

type QuestionEditorType = {
  isOpen: boolean;
  toggle: () => void;
  question?: UserGeneratedInputType;
  callback: (newQuestion: any) => void;
  title: string;
  submitText?: string;
  campaign?: Campaign | null;
  // disallow any changes which could affect the ability to find or use
  // existing answers
  preserveExistingAnswers?: boolean | null;
  excludeSpecialQuestions?: string[] | null;
  // Questiond default for specific question types (optional)
  questionDefaults?: QuestionDefaults;
  submitButtonType?: string | null;
  hideAdvancedFeatures?: boolean;
  autoFocus?: boolean | null;
  translationNamespace?: string | null;
  jsonPath?: string | null;
  canBePrivate?: boolean | null;
  canBeAnonymous?: boolean | null;

  // These props where discovered at typechecking time.
  extraProperties?: {
    canBePrivate?: boolean | null;
    canBeAnonymous?: boolean | null;
  };
  existingSpecialQuestions?: (string | undefined)[];
};

const QuestionEditor: React.FC<QuestionEditorType> = ({
  isOpen,
  toggle,
  question,
  callback,
  title,
  submitText,
  campaign,
  preserveExistingAnswers = false,
  excludeSpecialQuestions,
  questionDefaults = {},
  submitButtonType,
  hideAdvancedFeatures,
  autoFocus,
  translationNamespace,
  jsonPath,
  canBePrivate,
  canBeAnonymous,
  extraProperties,
  existingSpecialQuestions,
}) => {
  const { formatMessage } = useIntl();

  const campaignEditorPhaseContext = useCampaignEditorPhase();

  const readOnlyBecauseAnsweredAlready =
    isQuestionLocked(
      campaign?.campaign_question_locks ?? {},
      campaignEditorPhaseContext?.phaseType ?? '',
      question?.name ?? ''
    ) ||
    // SECTION types have inconsistent behaviour,
    // sometimes they have `name` and sometimes
    // they don't.
    (question?.type == INPUT_TYPES.SECTION &&
      isPhaseLocked(
        campaign?.campaign_question_locks ?? {},
        campaignEditorPhaseContext?.phaseType ?? ''
      ));

  const QUESTION_TYPES = useMemo((): QuestionType[] => {
    const QUESTION_TYPE_POSITIVE_SKILLS: QuestionType = {
      name: 'positive_skills',
      heading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_skills.heading',
        defaultMessage: 'Strength competencies',
      }),
      subheading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_skills.subheading',
        defaultMessage: 'skills and behaviors going well',
      }),
      icon: consts.ICONS.SKILL,
      description: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_skills.description',
        defaultMessage:
          'Shows in key takeaways and performance reports as tag clouds',
      }),
      generateQuestionId: false,
      allowedQuestionTypes: [],
      questionOptions: {
        type: INPUT_TYPES.SKILLS,
        label: formatMessage({
          id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_skills.label',
          defaultMessage:
            "In the past '{{duration}}', what are the strongest skills and behaviors '{{name}}' demonstrated?",
        }),
        required: true,
        minLength: 2,
        ...questionDefaults['positive_skills'],
      },
    };

    const QUESTION_TYPE_POSITIVE_COMMENTS: QuestionType = {
      name: 'positive_comments',
      heading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_comments.heading',
        defaultMessage: 'Strength comments',
      }),
      subheading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_comments.subheading',
        defaultMessage: 'comments on what is going well',
      }),
      icon: consts.ICONS.FEEDBACK,
      description: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_comments.description',
        defaultMessage:
          'Shows near strength competencies tag cloud in performance reports as context',
      }),
      generateQuestionId: false,
      allowedQuestionTypes: [INPUT_TYPES.RICH_TEXT_EDITOR],
      questionOptions: {
        type: INPUT_TYPES.RICH_TEXT_EDITOR,
        label: formatMessage({
          id: 'app.views.activities.widgets.inputs.question_editor.question_type.positive_comments.label',
          defaultMessage:
            'Give an example or two of how you saw these demonstrated.',
        }),
        required: true,
        maxLength: 1000,
        minRows: 3,
        maxRows: 15,
        ...questionDefaults['positive_comments'],
      },
    };

    const QUESTION_TYPE_NEGATIVE_SKILLS: QuestionType = {
      name: 'negative_skills',
      heading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_skills.heading',
        defaultMessage: 'Growth competencies',
      }),
      subheading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_skills.subheading',
        defaultMessage: 'skills and behaviors to grow',
      }),
      icon: consts.ICONS.SKILL,
      description: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_skills.description',
        defaultMessage:
          'Shows in key takeaways and performance reports as tag clouds',
      }),
      generateQuestionId: false,
      allowedQuestionTypes: [],
      questionOptions: {
        type: INPUT_TYPES.SKILLS,
        label: formatMessage({
          id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_skills.label',
          defaultMessage:
            "What skills or behaviors does '{{name}}' need guidance or support with moving forward?",
        }),
        required: true,
        minLength: 1,
        ...questionDefaults['negative_skills'],
      },
    };

    const QUESTION_TYPE_NEGATIVE_COMMENTS: QuestionType = {
      name: 'negative_comments',
      heading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_comments.heading',
        defaultMessage: 'Growth comments',
      }),
      subheading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_comments.subheading',
        defaultMessage: 'comments on growth areas',
      }),
      icon: consts.ICONS.FEEDBACK,
      description: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_comments.description',
        defaultMessage:
          'Shows near growth competencies tag cloud in performance reports as context',
      }),
      generateQuestionId: false,
      allowedQuestionTypes: [INPUT_TYPES.RICH_TEXT_EDITOR],
      questionOptions: {
        type: INPUT_TYPES.RICH_TEXT_EDITOR,
        label: formatMessage({
          id: 'app.views.activities.widgets.inputs.question_editor.question_type.negative_comments.label',
          defaultMessage:
            "Share an idea or two of how '{{name}}' could further develop in these areas.",
        }),
        required: true,
        maxLength: 1000,
        minRows: 3,
        maxRows: 15,
        ...questionDefaults['negative_comments'],
      },
    };

    const QUESTION_TYPE_RATING: QuestionType = {
      name: 'rating',
      heading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.rating.heading',
        defaultMessage: 'Quantitative rating',
      }),
      subheading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.rating.subheading',
        defaultMessage: 'employee NPS',
      }),
      icon: consts.ICONS.FEEDBACK,
      description: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.rating.description',
        defaultMessage:
          'Shows in reports to enable quantitative comparison of ratings between people',
      }),
      generateQuestionId: false,
      allowedQuestionTypes: [INPUT_TYPES.DROPDOWN, INPUT_TYPES.LIKERT],
      questionOptions: {
        type: INPUT_TYPES.DROPDOWN,
        objects: [],
        ...questionDefaults['rating'],
      },
    };

    const QUESTION_TYPE_CUSTOM: QuestionType = {
      name: 'custom',
      heading: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.question_type.custom.heading',
        defaultMessage: 'Custom question',
      }),
      subheading: '',
      icon: consts.ICONS.FEEDBACK,
      description: '',
      generateQuestionId: true,
    };

    const questionTypes = [
      QUESTION_TYPE_POSITIVE_SKILLS,
      QUESTION_TYPE_POSITIVE_COMMENTS,
      QUESTION_TYPE_NEGATIVE_SKILLS,
      QUESTION_TYPE_NEGATIVE_COMMENTS,
      QUESTION_TYPE_RATING,
      QUESTION_TYPE_CUSTOM,
    ];

    const excludeSpecialQuestionsSet = new Set(excludeSpecialQuestions);
    return excludeSpecialQuestions
      ? questionTypes.filter((q) => !excludeSpecialQuestionsSet.has(q.name))
      : questionTypes;
  }, [excludeSpecialQuestions, questionDefaults, formatMessage]);

  const CUSTOM_QUESTION_TYPE = useMemo<QuestionType | undefined>(
    () => QUESTION_TYPES.find((qt) => qt.name === 'custom'),
    [QUESTION_TYPES]
  );

  const generateDefaultQuestion = useCallback(
    (name?: string, questionOptions?: object): UserGeneratedInputType => ({
      // note: must start with CUSTOM_QUESTION_NONANONYMOUS_PREFIX
      // for it to be save properly in the perf flow
      name:
        name ??
        CUSTOM_QUESTION_NONANONYMOUS_PREFIX + generateUniqueQuestionId(),
      label: formatMessage({
        id: 'app.views.activities.widgets.inputs.question_editor.default_question_title',
        defaultMessage: 'Title goes here',
      }),
      type: INPUT_TYPES.TEXTAREA,
      ...questionOptions,
    }),
    [formatMessage]
  );

  const existingSpecialQuestionsSet = useMemo(
    () => new Set(existingSpecialQuestions ?? []),
    [existingSpecialQuestions]
  );

  const [questionType, setQuestionType] = useState<
    QuestionType | null | undefined
  >(null);

  const isInQuestionEditingMode = useMemo(
    () => questionType || question,
    [questionType, question]
  );

  const computedQuestionType = useMemo(() => {
    return questionType
      ? questionType
      : question
      ? QUESTION_TYPES.find((qt) => qt.name === question.name) ??
        CUSTOM_QUESTION_TYPE
      : null;
  }, [QUESTION_TYPES, CUSTOM_QUESTION_TYPE, questionType, question]);

  const [questionWithDefault, setQuestionWithDefault] =
    // Are these 2 types somewhat the same thing?
    useState<UserGeneratedInputType>(question ?? generateDefaultQuestion());

  useEffect(() => {
    if (!question) {
      if (
        questionType &&
        !questionType.generateQuestionId &&
        questionWithDefault.name !== questionType.name
      ) {
        setQuestionWithDefault(
          generateDefaultQuestion(
            questionType.name,
            questionType.questionOptions
          )
        );
      }
    }
  }, [questionType, question, questionWithDefault, generateDefaultQuestion]);

  // Add the extra properties to the question object here since they are plugged in a later stage
  useEffect(() => {
    setQuestionWithDefault((questionWithDefault) => {
      const questionWithExtraProps = {
        ...questionWithDefault,
        ...extraProperties,
      };
      return isEqual(questionWithDefault, questionWithExtraProps)
        ? questionWithDefault
        : questionWithExtraProps;
    });
  }, [questionWithDefault, extraProperties]);

  const uppercaseQuestionType = useMemo(
    () => questionWithDefault?.type?.toUpperCase(),
    [questionWithDefault?.type]
  );

  const onValidate = useCallback(() => {
    const hasOptions =
      (questionWithDefault?.options?.length ?? 0) > 0 ||
      (questionWithDefault?.objects?.length ?? 0) > 0;

    const checkOptionIsEmpty = (option) =>
      //MC and dropdowns use "name" and likert uses "value"
      option?.value?.length === 0 || option?.name?.length === 0;

    const optionType =
      uppercaseQuestionType === INPUT_TYPES.LIKERT ? 'question' : 'option';

    // At least one option must be present
    if (
      (uppercaseQuestionType === INPUT_TYPES.MULTIPLE_CHOICE ||
        uppercaseQuestionType === INPUT_TYPES.DROPDOWN ||
        uppercaseQuestionType === INPUT_TYPES.LIKERT) &&
      !hasOptions
    ) {
      return {
        error: formatMessage(
          {
            id: 'app.views.activities.widgets.inputs.question_editor.options_required',
            defaultMessage: 'There must be at least one {optionType}.',
          },
          { optionType: optionType }
        ),
      };
    }

    // At least one option must be present
    if (
      uppercaseQuestionType === INPUT_TYPES.LIKERT &&
      computedQuestionType?.name === 'rating'
    ) {
      const optionsCount = questionWithDefault?.objects?.length ?? 0;
      if (optionsCount !== 1) {
        return {
          error: formatMessage(
            {
              id: 'app.views.activities.widgets.inputs.question_editor.one_options_enforced',
              defaultMessage: 'There must be at exactly one {optionType}.',
            },
            { optionType: optionType }
          ),
        };
      }
    }

    // if option is present, it must have a value
    if (uppercaseQuestionType === INPUT_TYPES.MULTIPLE_CHOICE) {
      if (questionWithDefault.options?.some(checkOptionIsEmpty)) {
        return {
          error: formatMessage(
            {
              id: 'app.views.activities.widgets.inputs.question_editor.empty_option',
              defaultMessage: 'An {optionType} cannot be empty.',
            },
            { optionType: optionType }
          ),
        };
      }
    } else if (uppercaseQuestionType === INPUT_TYPES.DROPDOWN) {
      if (questionWithDefault.objects?.some(checkOptionIsEmpty)) {
        return {
          error: formatMessage(
            {
              id: 'app.views.activities.widgets.inputs.question_editor.empty_option',
              defaultMessage: 'An {optionType} cannot be empty.',
            },
            { optionType: optionType }
          ),
        };
      }
    } else if (uppercaseQuestionType === INPUT_TYPES.LIKERT) {
      if (questionWithDefault.objects?.some(checkOptionIsEmpty)) {
        return {
          error: formatMessage(
            {
              id: 'app.views.activities.widgets.inputs.question_editor.empty_option',
              defaultMessage: 'An {optionType} cannot be empty.',
            },
            { optionType: optionType }
          ),
        };
      }
    }

    // If min/max are present, they must be sane
    if (
      questionWithDefault.minSelections &&
      questionWithDefault.maxSelections
    ) {
      if (
        parseInt(questionWithDefault.minSelections) >
        parseInt(questionWithDefault.maxSelections)
      ) {
        return {
          error: formatMessage({
            id: 'app.views.activities.widgets.inputs.question_editor.min_must_be_less_than_max',
            defaultMessage:
              'Min selections must be less than or equal to Max selections.',
          }),
        };
      }
    }
  }, [
    questionWithDefault,
    uppercaseQuestionType,
    formatMessage,
    computedQuestionType,
  ]);

  // update question when edited externally
  useEffect(() => {
    setQuestionWithDefault(question ?? generateDefaultQuestion());
  }, [question, generateDefaultQuestion]);

  // when this modal is closed, reset the question back to what was originally
  // passed in so reopening the modal will show the original question
  useEffect(() => {
    if (!isOpen) {
      setQuestionWithDefault(question ?? generateDefaultQuestion());
      setQuestionType(null);
    }
  }, [question, generateDefaultQuestion, isOpen]);

  const onChangeObject = useCallback(
    (o) => {
      // we uppercase type id for saving to the database
      const newTypeUppercase = o.type?.toUpperCase();

      const updatedQuestion = {
        ...getDefaultCustomAttributesForType(newTypeUppercase, formatMessage),
        ...o,
        type: newTypeUppercase,
      };

      // always require a unique id
      if (!o?.name) {
        updatedQuestion.name = generateUniqueQuestionId();
      }

      // if changed to yes/no toggle, required cannot be set
      // (because leaving a toggle off means the person is
      // answering "no")
      if (questionWithDefault.type === INPUT_TYPES.SWITCH) {
        updatedQuestion.required = false;
      }

      if (questionWithDefault.type !== newTypeUppercase) {
        // if converting between dropdown list, multiple choice, or likert
        // scale, change values to match new type as follows:
        // Dropdown: "objects" with id and name fields
        // Multiple choice: "options" with id and name fields
        // Likert scale: "objects" with name (instead of id), value (for question), and helperText fields
        const oldType = questionWithDefault?.type?.toUpperCase();
        const newType = newTypeUppercase;

        // dropdown -> multiple choice
        if (
          oldType === INPUT_TYPES.DROPDOWN &&
          newType === INPUT_TYPES.MULTIPLE_CHOICE
        ) {
          updatedQuestion.options = questionWithDefault.objects?.map((obj) => ({
            id: obj.id,
            name: obj.name,
            helperText: obj.helperText,
          }));
          updatedQuestion.objects = undefined;
        }

        // dropdown -> likert scale
        if (
          oldType === INPUT_TYPES.DROPDOWN &&
          newType === INPUT_TYPES.LIKERT
        ) {
          updatedQuestion.objects = questionWithDefault.objects?.map((obj) => ({
            name: obj.id,
            value: obj.name,
            helperText: obj.helperText,
          }));
        }

        // multiple choice -> dropdown
        if (
          oldType === INPUT_TYPES.MULTIPLE_CHOICE &&
          newType === INPUT_TYPES.DROPDOWN
        ) {
          updatedQuestion.objects = questionWithDefault.options?.map(
            (option) => ({
              id: option.id,
              name: option.name,
              helperText: option.helperText,
            })
          );
          updatedQuestion.options = undefined;
        }

        // multiple choice -> likert scale
        if (
          oldType === INPUT_TYPES.MULTIPLE_CHOICE &&
          newType === INPUT_TYPES.LIKERT
        ) {
          updatedQuestion.objects = questionWithDefault.options?.map(
            (option) => ({
              name: option.id,
              value: option.name,
              helperText: option.helperText,
            })
          );
          updatedQuestion.options = undefined;
        }

        // likert scale -> dropdown
        if (
          oldType === INPUT_TYPES.LIKERT &&
          newType === INPUT_TYPES.DROPDOWN
        ) {
          updatedQuestion.objects = questionWithDefault.objects?.map((obj) => ({
            id: obj.name,
            name: obj.value,
            helperText: obj.helperText,
          }));
        }

        // likert scale -> multiple choice
        if (
          oldType === INPUT_TYPES.LIKERT &&
          newType === INPUT_TYPES.MULTIPLE_CHOICE
        ) {
          updatedQuestion.options = questionWithDefault.objects?.map((obj) => ({
            id: obj.name,
            name: obj.value,
            helperText: obj.helperText,
          }));
          updatedQuestion.objects = undefined;
        }
      }

      setQuestionWithDefault(updatedQuestion);
    },
    [questionWithDefault, formatMessage]
  );

  const isSpecialHardcodedQuestion = useMemo(() => {
    return (
      HARDCODED_QUESTION_NAMES_LIST.indexOf(questionWithDefault?.name ?? '') !==
      -1
    );
  }, [questionWithDefault?.name]);

  // switches can't be required since required means the person has to always
  // set to yes (which is unintuitive, so we don't show to prevent users from
  // unintentionally creating required switches)
  const canBeRequired = useMemo(
    () =>
      uppercaseQuestionType !== INPUT_TYPES.SWITCH &&
      uppercaseQuestionType !== INPUT_TYPES.SECTION,
    [uppercaseQuestionType]
  );

  const canBeAnonymousLocal = useMemo(
    () =>
      uppercaseQuestionType !== INPUT_TYPES.SECTION &&
      (canBeAnonymous || questionWithDefault.canBeAnonymous),
    [uppercaseQuestionType, canBeAnonymous, questionWithDefault.canBeAnonymous]
  );

  const canBePrivateLocal = questionWithDefault.canBePrivate || canBePrivate;

  const [previewKey, setPreviewKey] = useState(() =>
    computePreviewKey(questionWithDefault)
  );

  const setPreviewKeyDebounced = useMemo(
    () => debounce(setPreviewKey, 250),
    []
  );

  useEffect(() => {
    setPreviewKeyDebounced(computePreviewKey(questionWithDefault));
  }, [setPreviewKeyDebounced, questionWithDefault]);

  const questionLivePreview = useMemo(
    // we can't have required = true in the preview
    // as it will prevent saving of the editing form
    () => (
      <Question
        key={previewKey}
        value={{ ...questionWithDefault, required: false }}
        campaign={campaign}
        autoFocus={autoFocus}
      />
    ),
    [autoFocus, campaign, questionWithDefault, previewKey]
  );

  const editableAttributesOptionsForQuestion = useMemo(() => {
    const type = questionWithDefault?.type;
    if (
      type === INPUT_TYPES.DROPDOWN &&
      questionWithDefault.name === 'rating'
    ) {
      return {
        allowEditingId: true,
        disabled: readOnlyBecauseAnsweredAlready,
        generateDefaultValue: (index, values) => {
          const maxVal = values.reduce((acc, value) => {
            const parsed = parseInt(value.id, 10);
            return Number.isNaN(parsed) ? acc : Math.max(acc, parsed);
          }, 0);
          return maxVal + 1;
        },
      };
    } else if (
      type === INPUT_TYPES.LIKERT &&
      questionWithDefault.name === 'rating'
    ) {
      return {
        disableAddingQuestions: (questionWithDefault.objects?.length ?? 0) > 0,
        disableRemovingQuestions:
          (questionWithDefault.objects?.length ?? 0) < 2,
        disabled: readOnlyBecauseAnsweredAlready,
      };
    }

    return {};
  }, [readOnlyBecauseAnsweredAlready, questionWithDefault]);

  const editableAttributesListForType = useMemo(() => {
    let attributes: UserGeneratedInputTypeAttribute[] = [];
    if (questionWithDefault?.type) {
      const options = {
        ...editableAttributesOptionsForQuestion,
        translationNamespace: translationNamespace,
        jsonPath: jsonPath,
      };
      // get attributes list from USER_GENERATED_INPUT_TYPE_OPTIONS
      attributes =
        USER_GENERATED_INPUT_TYPE_OPTIONS(
          formatMessage,
          hideAdvancedFeatures,
          options
        ).find((type) => type.id === questionWithDefault?.type)?.attributes ??
        [];
    }

    attributes = attributes.filter((a) => {
      if (a.displayIf) {
        return a.displayIf(questionWithDefault);
      }
      return true;
    });

    // still allow editing labels for dynamic attributes since we store the ids in
    // the sr and relationships instead of the labels
    attributes = attributes.map((attribute) => ({
      ...attribute,
      ...(readOnlyBecauseAnsweredAlready
        ? { addingDisabled: true, deletingDisabled: true }
        : {}),
    }));

    return attributes;
  }, [
    editableAttributesOptionsForQuestion,
    formatMessage,
    hideAdvancedFeatures,
    jsonPath,
    questionWithDefault,
    readOnlyBecauseAnsweredAlready,
    translationNamespace,
  ]);

  const dynamicAttributesListForType = useMemo(
    () =>
      editableAttributesListForType?.map((attribute) => ({
        ...attribute,
        value:
          attribute.name in questionWithDefault &&
          questionWithDefault[attribute.name]
            ? questionWithDefault[attribute.name]
            : attribute.defaultValue,
      })) ?? [],
    [editableAttributesListForType, questionWithDefault]
  );

  const filterInputs = (inputs, questionType) => {
    let allowedByQuestionType;

    // filter by the allowed question types, if any
    if (questionType && questionType.allowedQuestionTypes) {
      const allowedSet = new Set(questionType.allowedQuestionTypes);
      allowedByQuestionType = inputs.filter((i) => allowedSet.has(i.id));
    } else {
      allowedByQuestionType = inputs;
    }
    // we never allow the user to select a skills questions directly
    return allowedByQuestionType.filter((i) => i.id != INPUT_TYPES.SKILLS);
  };

  const inputs = useMemo(
    () => [
      ...(hideAdvancedFeatures
        ? []
        : [
            {
              disabled:
                isSpecialHardcodedQuestion ||
                preserveExistingAnswers ||
                readOnlyBecauseAnsweredAlready,
              type: INPUT_TYPES.TEXT,
              name: 'name',
              label: 'ID',
              helperHover:
                'This must be unique for each question in this question set. It is used for CSV export headers, etc. Cannot be modified after the phase has opened.',
            },
          ]),
      {
        type: INPUT_TYPES.DROPDOWN,
        name: 'type',
        label: 'Type',
        disabled: readOnlyBecauseAnsweredAlready,
        objects: filterInputs(
          USER_GENERATED_INPUT_TYPE_OPTIONS(
            formatMessage,
            hideAdvancedFeatures
          ),
          computedQuestionType
        ),
      },
      {
        type: INPUT_TYPES.SWITCH,
        name: 'required',
        disabled: readOnlyBecauseAnsweredAlready,
        label: formatMessage({
          id: 'app.views.activities.widgets.inputs.question_editor.inputs.required.label',
          defaultMessage: 'Required',
        }),
        helperHover: formatMessage({
          id: 'app.views.activities.widgets.inputs.question_editor.inputs.required.help',
          defaultMessage:
            'If enabled, participants will not be able to submit their responses without answering this question.',
        }),
      },
      ...(hideAdvancedFeatures
        ? []
        : [
            {
              type: INPUT_TYPES.SWITCH,
              name: 'anonymous',
              label: formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.anonymous.label',
                defaultMessage: 'Confidential',
              }),
              disabled:
                preserveExistingAnswers || readOnlyBecauseAnsweredAlready,
              helperHover: formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.anonymous.help',
                defaultMessage:
                  "If enabled, responses to this question will not be shown to anyone but the person who answers the question. Reporting will show aggregated results, and CSV exports of each person's responses will not include this question. Cannot be modified after the phase has opened.",
              }),
            },
          ]),
      ...(hideAdvancedFeatures
        ? []
        : [
            {
              type: INPUT_TYPES.SWITCH,
              name: 'hide_from_recipient',
              label: formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.hide_from_recipient.label',
                defaultMessage: 'Private',
              }),
              disabled:
                preserveExistingAnswers || readOnlyBecauseAnsweredAlready,
              helperHover: formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.hide_from_recipient.help',
                defaultMessage:
                  "If enabled, the person being evaluated will not see the response to this question, but the person's manager and above and HR administrators will.",
              }),
            },
          ]),
      ...(hideAdvancedFeatures
        ? []
        : [
            {
              type: INPUT_TYPES.INCLUDE_EXCLUDE_FILTER,
              name: 'filters',
              disabled: readOnlyBecauseAnsweredAlready,
              label: formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.filters.label',
                defaultMessage: 'Only show to specific people',
              }),
            },
          ]),
      {
        type: INPUT_TYPES.RICH_TEXT_EDITOR,
        name: 'label',
        // if type is likert scale, label should be "Likert section header"
        // otherwise it should be "Label"
        label:
          uppercaseQuestionType === INPUT_TYPES.LIKERT
            ? formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.label.label.likert',
                defaultMessage: 'Likert section header',
              })
            : formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.label.label.default',
                defaultMessage: 'Label',
              }),
        translationNamespace: translationNamespace,
        jsonPath: joinJsonPath(jsonPath, '.label'),
      },
      {
        type: INPUT_TYPES.RICH_TEXT_EDITOR,
        name: 'helperText',
        label:
          uppercaseQuestionType === INPUT_TYPES.LIKERT
            ? formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.helper_text.label.likert',
                defaultMessage: 'Likert section helper text',
              })
            : formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.inputs.helper_text.label.default',
                defaultMessage: 'Helper text',
              }),
        translationNamespace: translationNamespace,
        jsonPath: joinJsonPath(jsonPath, '.helperText'),
      },
      ...dynamicAttributesListForType,
    ],
    [
      uppercaseQuestionType,
      dynamicAttributesListForType,
      preserveExistingAnswers,
      computedQuestionType,
      isSpecialHardcodedQuestion,
      hideAdvancedFeatures,
      translationNamespace,
      formatMessage,
      jsonPath,
      readOnlyBecauseAnsweredAlready,
    ]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const dynamicAttributeSubmitValues = dynamicAttributesListForType.reduce(
        (acc, attribute) => ({
          ...acc,
          [attribute.name]: attribute.formatValueOnSubmit
            ? attribute.formatValueOnSubmit(object[attribute.name])
            : object[attribute.name],
        }),
        {}
      );

      return {
        ...object,
        ...dynamicAttributeSubmitValues,
        // note: we must start with either anonymous_ or custom_ because
        name:
          // we only save responses to fields that start with that in their
          // name on the frontend (see extractCustomResponses)
          object.name
            ? (isSpecialHardcodedQuestion
                ? ''
                : object.anonymous
                ? CUSTOM_QUESTION_ANONYMOUS_PREFIX
                : CUSTOM_QUESTION_NONANONYMOUS_PREFIX) +
              stripInvalidHtmlSelectorCharacters(object.name)
            : null,
        ...SANITIZE_FIELDS.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
      };
    },
    [dynamicAttributesListForType, isSpecialHardcodedQuestion]
  );
  const filtersHaveValues = (
    { include, exclude } = { include: [], exclude: [] }
  ) => {
    return (include && include.length) || (exclude && exclude.length);
  };

  const renderModalHeader = useCallback(
    (modalTitle) => {
      return (
        <QuestionEditorModalTitle
          modalTitle={modalTitle}
          questionType={questionType}
          hasQuestion={!!question}
          onBackClick={() => {
            setQuestionType(null);
            setQuestionWithDefault(question ?? generateDefaultQuestion());
          }}
        />
      );
    },
    [questionType, setQuestionType, question, generateDefaultQuestion]
  );

  const renderQuestionInputs = useCallback(
    (inputs) => {
      const type = hideAdvancedFeatures ? inputs[0] : inputs[1];
      const required = hideAdvancedFeatures ? inputs[1] : inputs[2];
      const anonymous = hideAdvancedFeatures ? null : inputs[3];
      const hideFromRecipient = hideAdvancedFeatures ? null : inputs[4];
      const includeExclude = hideAdvancedFeatures ? null : inputs[5];
      const label = hideAdvancedFeatures ? inputs[2] : inputs[6];
      const rest = hideAdvancedFeatures ? inputs.slice(3) : inputs.slice(7);

      const shouldForceRevealCustomFiltersLink = () =>
        !!filtersHaveValues(includeExclude.props.value);

      return (
        <>
          <Row>
            {type.props.objects.length > 1 ? (
              <Col className="col-auto">{type}</Col>
            ) : null}
            {canBeRequired && <Col className="col-auto">{required}</Col>}
            {anonymous &&
              !canBePrivateLocal &&
              canBeAnonymousLocal &&
              !isSpecialHardcodedQuestion && (
                <Col className="col-auto">{anonymous}</Col>
              )}
            {hideFromRecipient && canBePrivateLocal && (
              <Col className="col-auto">{hideFromRecipient}</Col>
            )}
          </Row>
          <Row>
            <Col>
              {label}
              {uppercaseQuestionType !== INPUT_TYPES.SECTION && (
                <>{[...rest]}</>
              )}
            </Col>
          </Row>
          {!hideAdvancedFeatures && (
            <RevealLink
              text={formatMessage({
                id: 'app.views.activities.widgets.inputs.question_editor.filter_link',
                defaultMessage: 'Only show this question to specific people',
              })}
              forceReveal={shouldForceRevealCustomFiltersLink}
              className="mb-3"
            >
              <Row>
                <Col>{includeExclude}</Col>
              </Row>
            </RevealLink>
          )}
          <div className="border-top pt-4">
            <Row className="align-items-center">
              <Col>
                <Card className="mb-0">
                  <CardBody>
                    <h6 className="text-uppercase text-muted mb-2">
                      <FormattedMessage
                        id="app.views.widgets.inputs.question_editor.preview"
                        defaultMessage="Preview"
                      />
                    </h6>
                    {questionLivePreview}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    },
    [
      canBeRequired,
      canBePrivateLocal,
      canBeAnonymousLocal,
      isSpecialHardcodedQuestion,
      uppercaseQuestionType,
      hideAdvancedFeatures,
      questionLivePreview,
      formatMessage,
    ]
  );

  // render inputs into separate steps
  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {!isInQuestionEditingMode && <>{inputs}</>}
          {isInQuestionEditingMode && (
            <>
              {inputs}
              {submitButton}
            </>
          )}
        </>
      );
    },
    [isInQuestionEditingMode]
  );

  const renderQuestionTypesInputs = useCallback(() => {
    return (
      <Row className="mb-n4">
        {QUESTION_TYPES.map((type, index) => {
          const disabled = existingSpecialQuestionsSet.has(type.name);
          return (
            <Col
              key={index}
              className="col-12 col-md-6 py-2 py-md-0 mb-4"
              onClick={() => setQuestionType(disabled ? null : type)}
            >
              <Card
                id={'add-question-type-' + type.name}
                className={
                  'py-4 mb-0 align-items-center' +
                  (disabled ? ' text-bg-light' : ' lift')
                }
                role="button"
                color={disabled ? 'light' : undefined}
              >
                <div className="avatar">
                  <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i className={type.icon}></i>
                  </div>
                </div>
                <h3
                  className={'mb-0 mt-3' + (disabled ? ' text-secondary' : '')}
                >
                  {type.heading}
                </h3>
                <p
                  className={
                    'small text-muted text-center mb-0' +
                    (disabled ? ' text-black-50' : '')
                  }
                >
                  {disabled
                    ? formatMessage({
                        id: 'app.views.activities.widgets.inputs.question_editor.question_type.card.duplicate_question.subheading',
                        defaultMessage: 'Already added',
                      })
                    : type.subheading || <span>&nbsp;</span>}
                </p>
              </Card>
              {(type.description || '').length > 0 ? (
                <UncontrolledPopover
                  placement="top"
                  trigger="hover"
                  target={'add-question-type-' + type.name}
                >
                  {disabled ? (
                    <FormattedMessage
                      id="app.views.activities.widgets.inputs.question_editor.question_type.card.duplicate_question.description"
                      defaultMessage="You have already added a question for {questionType} in this section"
                      values={{ questionType: type.heading }}
                    />
                  ) : (
                    type.description
                  )}
                </UncontrolledPopover>
              ) : null}
            </Col>
          );
        })}
      </Row>
    );
  }, [QUESTION_TYPES, existingSpecialQuestionsSet, formatMessage]);

  const renderInputs = useCallback(
    (args) => {
      if (isInQuestionEditingMode) {
        return renderQuestionInputs(args);
      } else {
        return renderQuestionTypesInputs();
      }
    },
    [renderQuestionInputs, renderQuestionTypesInputs, isInQuestionEditingMode]
  );

  const questionNameWithoutStartingAnonymousOrCustom = useMemo(() => {
    if (!questionWithDefault?.name) {
      if (questionWithDefault?.type === INPUT_TYPES.SECTION)
        return generateUniqueQuestionId();
      return null;
    }

    if (isSpecialHardcodedQuestion) {
      // return question as is
      return questionWithDefault.name;
    }

    if (questionWithDefault.name.startsWith(CUSTOM_QUESTION_ANONYMOUS_PREFIX)) {
      return questionWithDefault.name.substring(
        CUSTOM_QUESTION_ANONYMOUS_PREFIX.length
      );
    } else if (
      questionWithDefault.name.startsWith(CUSTOM_QUESTION_NONANONYMOUS_PREFIX)
    ) {
      return questionWithDefault.name.substring(
        CUSTOM_QUESTION_NONANONYMOUS_PREFIX.length
      );
    } else {
      return questionWithDefault.name;
    }
  }, [
    isSpecialHardcodedQuestion,
    questionWithDefault?.name,
    questionWithDefault?.type,
  ]);

  const object = useMemo(
    () => ({
      ...questionWithDefault,
      name: questionNameWithoutStartingAnonymousOrCustom,
      ...dynamicAttributesListForType.reduce(
        (acc, attribute) => ({
          ...acc,
          [attribute.name]: attribute.value,
        }),
        {}
      ),
    }),
    [
      dynamicAttributesListForType,
      questionWithDefault,
      questionNameWithoutStartingAnonymousOrCustom,
    ]
  );

  const output = useMemo(
    () => (
      <ModalEditor
        isOpen={isOpen}
        toggle={toggle}
        title={title}
        object={object}
        inputs={inputs}
        onChange={onChangeObject}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        callback={callback}
        renderInputs={renderInputs}
        submitText={submitText}
        hasUnsavedChanges={questionWithDefault !== object}
        onValidate={onValidate}
        renderModalHeader={renderModalHeader}
        renderForm={renderForm}
        buttonType={submitButtonType}
      />
    ),
    [
      isOpen,
      toggle,
      title,
      callback,
      submitText,
      submitButtonType,
      object,
      inputs,
      onChangeObject,
      transformObjectBeforeSubmit,
      renderInputs,
      questionWithDefault,
      onValidate,
      renderModalHeader,
      renderForm,
    ]
  );

  return output;
};

export default React.memo(QuestionEditor);
