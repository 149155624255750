import React, { FC, PropsWithChildren, useCallback } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

// NOTE: offline access necessary if useRefreshTokens is true below in the AuthProvider
export const AUTH0_PARAMS = {
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  scope: 'openid profile email offline_access',
};

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = useCallback(
    (appState) => {
      history.replace(appState?.returnTo || window.location.pathname);
    },
    [history]
  );

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
      sessionCheckExpiryDays={30}
      // NOTE: if we wish to disable useRefreshTokens/cacheLocation below, we
      // need to remove 'offline_access' from the scope above
      useRefreshTokens={true}
      cacheLocation="localstorage"
      // We need to set this for ALL auth sessions otherwise we
      // won't get `auth_time` in the claims within App.js.
      // https://auth0.com/docs/authenticate/login/max-age-reauthentication
      // More info in utils/util/CustomSessionTTL.js.
      // This needs to be longer than the refresh token, which is 30 days.
      maxAge={2678400}
      prompt="login"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
