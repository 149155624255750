import {
  AttachedContentTypes,
  OrganizationSettings,
  Person,
  ReduxState,
} from 'types';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  FEEDBACK_REQUEST_VISIBILITY_MANAGER_ONLY,
  FEEDBACK_TYPE_REQUEST,
} from 'utils/models/Feedback';
import { FormattedMessage, useIntl } from 'react-intl';
import PersonFeedbackList, {
  PEOPLE_GROUPINGS,
} from 'views/Person/PersonFeedbackList';
import React, { FC, useCallback, useMemo, useState } from 'react';

import FeedbackRequestTable from 'views/Feedback/FeedbackRequestTable';
import ModalFeedbackEditorButton from 'views/Feedback/ModalFeedbackEditorButton';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';

interface Packet {
  id: number;
  name: string;
  candidate_person: Person;
  feedback_list: Feedback[];
  feedback_requests: any[];
  feedback_decline_list: any[];
}

interface Props {
  className?: string;
  promotionPacket: Packet;
  attachedContentTypes: AttachedContentTypes;
  settings: OrganizationSettings;
}

interface Feedback {
  id: number;
  created_at: string;
  updated_at: string;
  type: string;
  status: string;
  submitted_at: string;
  approved_at: string;
  denied_at: string;
  withdrawn_at: string;
  candidate_person: Person;
  author_person: Person;
  previous_reviewer_person: Person;
  next_reviewer_person: Person;
  configs: any;
  description: string;
  comments: any[];
  is_adminable: boolean;
}

const PromotionPacketFeedback: FC<Props> = ({
  promotionPacket,
  attachedContentTypes,
  settings,
  className = '',
}) => {
  const { formatMessage } = useIntl();
  const [activeTab, setActiveTab] = React.useState<
    'feedback_received' | 'feedback_sent'
  >('feedback_received');

  const renderCustomEmptyMessage = useCallback(() => {
    return formatMessage(
      {
        id: 'app.views.promotion_packets.promotion_packet_feedback.no_feedback_yet',
        defaultMessage:
          'No feedback about {personName} received for this promotion packet.',
      },
      {
        personName: promotionPacket.candidate_person.given_name,
      }
    );
  }, [formatMessage, promotionPacket.candidate_person.given_name]);

  const forceRequestFeedback = useMemo(
    () => ({
      feedback_visibility:
        FEEDBACK_REQUEST_VISIBILITY_MANAGER_ONLY(formatMessage).id,
    }),
    [formatMessage]
  );

  const feedbackDefaults = useMemo(
    () => ({
      type: FEEDBACK_TYPE_REQUEST(formatMessage),
      subject_people: [promotionPacket.candidate_person],
      object_id: promotionPacket.id,
      content_type: attachedContentTypes['packet'],
      custom_questions:
        settings?.promotion_packet_custom_questions ?? undefined,
    }),
    [
      attachedContentTypes,
      formatMessage,
      promotionPacket.candidate_person,
      promotionPacket.id,
      settings?.promotion_packet_custom_questions,
    ]
  );

  const [feedbackRequests, setFeedbackRequests] = useState(() =>
    sortBy(promotionPacket.feedback_requests ?? [], ['created_at']).reverse()
  );

  const feedbackList = useMemo(
    () => sortBy(promotionPacket.feedback_list ?? [], ['created_at']).reverse(),
    [promotionPacket.feedback_list]
  );

  const feedbackDeclineList = useMemo(
    () => promotionPacket.feedback_decline_list ?? [],
    [promotionPacket.feedback_decline_list]
  );

  const adaptedFeedbackRequestsForSentTable = useMemo(() => {
    return feedbackRequests.map((feedbackRequest) => ({
      ...feedbackRequest,
      feedback: feedbackList.filter(
        // @ts-expect-error
        (f) => f?.feedback_request?.id === feedbackRequest.id
      ),
      feedback_declines: feedbackDeclineList.filter(
        (f) => f?.feedback_request?.id === feedbackRequest.id
      ),
    }));
  }, [feedbackRequests, feedbackList, feedbackDeclineList]);

  const hasSentRequests = feedbackRequests.length > 0;

  const handleFeedbackRequestAdded = useCallback((feedbackRequest) => {
    setFeedbackRequests((feedbackRequests) => [
      feedbackRequest,
      ...feedbackRequests,
    ]);
  }, []);

  return (
    <>
      <Row className={`align-items-center ${className}`}>
        <Col>
          <h3>
            <FormattedMessage
              id="app.views.promotion_packets.promotion_packet_feedback.feedback_from_others"
              defaultMessage="Feedback from others"
            />
          </h3>
        </Col>
        <Col className="col-auto">
          <ModalFeedbackEditorButton
            modalTitle={formatMessage({
              id: 'app.views.promotion_packets.promotion_packet_feedback.request_feedback_modal_title',
              defaultMessage: 'Request feedback for Promotion Packet',
            })}
            buttonText={formatMessage(
              {
                id: 'app.views.promotion_packets.promotion_packet_feedback.request_feedback_about_person_from_others',
                defaultMessage:
                  'Request feedback about {personName} from others',
              },
              {
                personName: promotionPacket.candidate_person.given_name,
              }
            )}
            forceRequestFeedback={forceRequestFeedback}
            feedback={feedbackDefaults}
            callback={handleFeedbackRequestAdded}
            enableCustomQuestionsCreation={false}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <Nav tabs={true}>
            <NavItem>
              <NavLink
                href="#"
                active={activeTab === 'feedback_received'}
                onClick={() => setActiveTab('feedback_received')}
              >
                <FormattedMessage
                  id="app.views.promotion_packets.promotion_packet_feedback.received"
                  defaultMessage="Received"
                />
              </NavLink>
            </NavItem>
            {hasSentRequests && (
              <NavItem>
                <NavLink
                  href="#"
                  active={activeTab === 'feedback_sent'}
                  onClick={() => setActiveTab('feedback_sent')}
                >
                  <FormattedMessage
                    id="app.views.promotion_packets.promotion_packet_feedback.sent_requests"
                    defaultMessage="Sent requests"
                  />
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab} className="pt-4">
            <TabPane tabId="feedback_received">
              <PersonFeedbackList
                hideFilters={true}
                showGlobalFeedback={PEOPLE_GROUPINGS.EVERYONE}
                feedbackList={feedbackList}
                person={promotionPacket.candidate_person}
                showManagerOnlyPerformanceDetails={true}
                renderCustomEmptyMessage={renderCustomEmptyMessage}
              />
            </TabPane>
            {hasSentRequests && (
              <TabPane tabId="feedback_sent">
                <FeedbackRequestTable
                  newFeedbackRequests={adaptedFeedbackRequestsForSentTable}
                  disableFetchAllRequests={true}
                  hideHeaderContent={true}
                  hideExportButton={true}
                />
              </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { attachedContentTypes, settings } = state;

  return {
    attachedContentTypes,
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(PromotionPacketFeedback));
