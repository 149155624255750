import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, PropsWithChildren } from 'react';

import { Person } from '../../../../types';
import PersonResponseRow from '../PersonResponseRow';
import { PrivateDataBadge } from '../../components/PrivateDataBadge';

export type ManagerCommentsProps = PropsWithChildren<{
  author: Person;
  comments?: string;
}>;

export const ManagerComments: FC<ManagerCommentsProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <h6 className="text-uppercase text-secondary mb-3">
        <FormattedMessage
          id="app.views.person.person_performance.trajectory.manager_comments.manager_comments"
          defaultMessage="Manager comments"
        />{' '}
        <PrivateDataBadge />
      </h6>
      <PersonResponseRow
        author={props.author}
        text={
          props.comments ??
          formatMessage({
            id: 'app.views.person.person_performance.trajectory.manager_comments.no_comments_provided',
            defaultMessage: 'There are no comments you are eligible to view',
          })
        }
        last={true}
      />
    </div>
  );
};
