import { Card, CardBody, Input } from 'reactstrap';
import React, { ChangeEvent, FC, useCallback } from 'react';

import BucketDescriptor from '../Viewer/BucketDescriptor';
import { TalentMatrixAxisSettings } from '../TalentMatrix';

interface Props {
  title: string;
  x: number;
  y: number;
  onTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
  axisSettings: TalentMatrixAxisSettings;
  editingCell?: { x: number; y: number };
  setEditingCell: (cell: { x: number; y: number }) => void;
}

const CellEditor: FC<Props> = ({
  title,
  x,
  y,
  onTextChange,
  axisSettings,
  editingCell,
  setEditingCell,
}) => {
  const isEditing = editingCell?.x === x && editingCell?.y === y;

  const onTextClick = useCallback(
    (x, y) => () => {
      setEditingCell({ x, y });
    },
    [setEditingCell]
  );

  return (
    <Card
      className="h-100 text-center"
      style={{
        borderRadius: '0rem',
      }}
    >
      <CardBody>
        <div className="row align-items-center ps-2 pe-2">
          {!isEditing && (
            <div
              className="list-editor-editable-cell rounded p-3"
              role="button"
              onClick={onTextClick(x, y)}
            >
              {title}
            </div>
          )}
          {isEditing && (
            <Input
              type="text"
              className="text-center"
              value={title}
              onChange={onTextChange}
            />
          )}
        </div>
        <BucketDescriptor
          className="text-muted"
          boundaries={axisSettings.x_axis_buckets[x]}
          metric_description={axisSettings.x_axis_metric_description}
          is_reversed={axisSettings.x_axis_order_reversed}
        />
        <BucketDescriptor
          className="text-muted"
          boundaries={axisSettings.y_axis_buckets[y]}
          metric_description={axisSettings.y_axis_metric_description}
          is_reversed={axisSettings.y_axis_order_reversed}
        />
      </CardBody>
    </Card>
  );
};

export default CellEditor;
