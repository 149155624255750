import { Col, Row } from 'reactstrap';
import React, { FC, useContext, useMemo } from 'react';
import { type RouteComponentProps, withRouter } from 'react-router-dom';

import ManagerRatingHistoryChart from './Trajectory/ManagerRatingHistoryChart';
import NetworkRecognitionHistoryChart from './Trajectory/NetworkRecognitionHistoryChart';
import { NetworkRecognitionRow } from '../NetworkRecognitionRow';
import { PageSection } from '../components/PageSection';
import PerformanceProfileContext from './PersonPerformanceContext';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import TrajectorySummary from './Trajectory/TrajectorySummary';
import { connect } from 'react-redux';
import { getUniqueWordCloudsFromRelationships } from './utils';
import { getWordCloudWordDictionaries } from '../../Widgets/WordCloud';
import { useIntl } from 'react-intl';

const PerformanceHistory: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const performanceProfileContext = useContext(PerformanceProfileContext);

  const data = props.data;
  const selectedCampaignIndex =
    // @ts-expect-error
    data && props.campaign
      ? // @ts-expect-error
        data.findIndex((cycle) => cycle.campaign.id === props.campaign.id)
      : -1;

  const currentPerson = useMemo(() => {
    // @ts-expect-error
    return data ? data[0]?.person : null;
  }, [data]);

  const firstName = currentPerson?.given_name ?? '';

  const selectedCampaignSkills =
    data &&
    data[selectedCampaignIndex] && // selectedCampaignIndex often -1 in testing
    [data[selectedCampaignIndex]].reduce(
      // @ts-expect-error
      (acc, e) => [...acc, ...e.influence, ...e.gold_stars],
      []
    );

  const allSkillsTags = useMemo(
    () =>
      getUniqueWordCloudsFromRelationships(
        formatMessage,
        selectedCampaignSkills,
        'positive_skills'
      ),
    [selectedCampaignSkills, formatMessage]
  );

  const topSkills = useMemo(() => {
    const skills = getWordCloudWordDictionaries(allSkillsTags, '');
    // @ts-expect-error
    skills.sort((a, b) => b.count - a.count);
    return skills.slice(0, 3);
  }, [allSkillsTags]);

  const topSkillsText = formatMessage(
    {
      id: 'app.components.campaign_insight.top_skills_text',
      defaultMessage:
        "The network believes {firstName}'s {topSkillsCount, plural, one {top skill is:} other {top skills are:}}",
    },
    { firstName, topSkillsCount: topSkills?.length ?? 0 }
  );
  const topSkillsList = useMemo(
    () =>
      topSkills?.map((s, index) => (
        <span
          key={index}
          className="fs-5 badge rounded-pill me-2 bg-primary-soft"
        >
          {/* @ts-expect-error */}
          {s.word}
        </span>
      )),
    [topSkills]
  );

  const scrollToONATable = () => {
    const el = document.getElementById('ona-summary-table');
    if (el) {
      el.scrollIntoView(true);
      window.scrollBy(0, -20);
    }
  };

  // If data Array is empty, then we fallback to PersonPerformance to indicate
  // lack of results, and this component renders nothing
  return (
    <>
      {/* @ts-expect-error */}
      {data?.length > 0 &&
        (props.isEligibleForReporting || props.hasONAFeedback) && (
          <Col>
            <Row className="justify-content-center">
              <Col className="col-12">
                {/* @ts-expect-error */}
                {props.shouldShowHistoricalData && (
                  <>
                    <Row>
                      <TrajectorySummary
                        firstName={firstName}
                        person={props.person}
                        organization={props.currentOrganization}
                        data={data}
                        scrollTo={scrollToONATable}
                        topSkills={{
                          text: topSkillsText,
                          list: topSkillsList,
                        }}
                        managerView={props.showManagerOnlyPerformanceDetails}
                        // @ts-expect-error
                        managerRelationshipsWithFeedback={
                          // @ts-expect-error
                          props.managerRelationshipsWithFeedback
                        }
                        showManagerOnlyPerformanceDetails={
                          props.showManagerOnlyPerformanceDetails
                        }
                        // @ts-expect-error
                        manager={props.manager}
                        // @ts-expect-error
                        showTimeAtOrganization={props.showTimeAtOrganization}
                        // @ts-expect-error
                        campaign={props.campaign}
                        // @ts-expect-error
                        monthsAtOrganization={props.monthsAtOrganization}
                        // @ts-expect-error
                        monthsInRole={props.monthsInRole}
                        // @ts-expect-error
                        role={props.role}
                        // @ts-expect-error
                        showTimeInRole={props.showTimeInRole}
                        isInReviewFlow={props.isInReviewFlow}
                      />
                    </Row>

                    {!props.displayDistributions &&
                    performanceProfileContext.displayCharts ? (
                      <PageSection
                        title={formatMessage({
                          id: 'app.views.person.person_performance.history.ratings_over_time',
                          defaultMessage: 'Ratings over time',
                        })}
                        titleIcon="bar-chart-2"
                        titleIconColor="text-info"
                        widths={[
                          // @ts-expect-error
                          { sm: 12, lg: 6 },
                          // @ts-expect-error
                          { sm: 12, lg: 6 },
                        ]}
                      >
                        <ManagerRatingHistoryChart
                          person={currentPerson}
                          // @ts-expect-error
                          showManagerOnlyPerformanceDetails={
                            props.showManagerOnlyPerformanceDetails
                          }
                          // @ts-expect-error
                          performanceData={data}
                          // @ts-expect-error
                          organization={props.currentOrganization}
                        />
                        <NetworkRecognitionHistoryChart
                          key="network-recognition-history"
                          person={currentPerson}
                          // @ts-expect-error
                          showManagerOnlyPerformanceDetails={
                            props.showManagerOnlyPerformanceDetails
                          }
                          // @ts-expect-error
                          performanceData={data}
                        />
                      </PageSection>
                    ) : null}
                  </>
                )}

                {(performanceProfileContext.showGoldStarsAndInfluenceToRecipient ||
                  props.showManagerOnlyPerformanceDetails) &&
                performanceProfileContext.canDisplayOna ? (
                  <NetworkRecognitionRow
                    data={data}
                    currentPerson={currentPerson}
                    firstName={firstName}
                    // @ts-expect-error
                    campaign={props.campaign}
                    // @ts-expect-error
                    currentOrganization={props.currentOrganization}
                    // @ts-expect-error
                    showManagerOnlyPerformanceDetails={
                      props.showManagerOnlyPerformanceDetails
                    }
                    performanceData={data}
                    displayDistributions={props.displayDistributions}
                  />
                ) : null}
              </Col>
            </Row>
          </Col>
        )}
    </>
  );
};

const PerformanceHistory_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  person: PropTypes.object.isRequired,
  showManagerOnlyPerformanceDetails: PropTypes.bool,
  isInReviewFlow: PropTypes.bool,
  isEligibleForReporting: PropTypes.bool,
  hasONAFeedback: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  displayDistributions: PropTypes.bool.isRequired,
};

type Props = PropTypes.InferProps<typeof PerformanceHistory_propTypes> &
  RouteComponentProps;

const mapStateToProps = (state: ReduxState) => {
  const { currentProxyPerson, currentOrganization } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(PerformanceHistory))
);
