import React, { useMemo } from 'react';
import {
  getPersonDisplayTitleHtml,
  isCurrentEmployee,
} from '../../../utils/models/Person';

import ObjectCard from './ObjectCard';
import { Person } from '../../../types';
import { useIntl } from 'react-intl';

interface Props {
  className?: string;
  cardBodyClassName?: string;
  headerClassName?: string;
  person: Person;
  closeButtonText?: string;
  onClose?: () => void;
  bodyOnly?: boolean;
  inline?: boolean;
  inDropdown?: boolean;
  size?: string;
  showDescription?: boolean;
  query?: string;
  linked?: boolean;
  urlSuffix?: string;
  showEnrichedProfile?: boolean;
  style?: object;
  isExternalUrl?: boolean;
  aside?: React.ReactNode;
  children?: React.ReactNode;
  isValid?: boolean;
  nameTransformerFunction?: (name: string | undefined) => string;
  hidePopover?: boolean;
  inPopover?: boolean;
  leaderboardNumber?: number;
  hideAvatar?: boolean;
  useTagFormat?: boolean;
  headerStyle?: object;
  role?: string;
  disabled?: boolean;
}

const PersonCard = ({
  cardBodyClassName = '',
  size = 'sm',
  showDescription = true,
  useTagFormat = false,
  disabled = false,
  ...props
}: Props) => {
  const { formatMessage } = useIntl();
  const name = props.person.full_name
    ? props.person.full_name
    : props.person.email;

  const isFormerEmployee = useMemo(
    () => props.person?.status && !isCurrentEmployee(props.person.status),
    [props.person?.status]
  );

  const propsNameTransformerFunction = props.nameTransformerFunction;
  const personName = useMemo(
    () =>
      propsNameTransformerFunction ? propsNameTransformerFunction(name) : name,
    [name, propsNameTransformerFunction]
  );

  // If useTagFormat, override a few props
  const className =
    props.className ?? '' + (useTagFormat ? ' mx-1 my-1 text-nowrap' : '');
  const linked = useTagFormat || props.linked;
  const propsShowDescription = useTagFormat ? false : showDescription;
  const propsCardBodyClassName =
    cardBodyClassName +
    (useTagFormat
      ? ' align-items-center d-flex h-100 me-3 ms-1 px-2 py-2 w-100'
      : '');
  const isExternalUrl = useTagFormat || props.isExternalUrl;

  return (
    <ObjectCard
      className={className}
      cardBodyClassName={propsCardBodyClassName}
      headerClassName={
        !props.headerClassName && isFormerEmployee
          ? 'd-inline text-muted fw-normal'
          : props.headerClassName
      }
      headerStyle={props.headerStyle}
      name={personName}
      description={
        propsShowDescription
          ? getPersonDisplayTitleHtml(formatMessage, props.person)
          : undefined
      }
      url={
        linked
          ? props.person.url + (props.urlSuffix ? props.urlSuffix : '')
          : null
      }
      query={props.query}
      size={size}
      role={props.role}
      style={props.style}
      bodyOnly={props.bodyOnly}
      inline={props.inline}
      inDropdown={props.inDropdown}
      closeButtonText={props.closeButtonText}
      onClose={props.onClose}
      person={props.person}
      showEnrichedProfile={props.showEnrichedProfile}
      isExternalUrl={isExternalUrl}
      aside={props.aside}
      isValid={props.isValid}
      hidePopover={props.hidePopover}
      inPopover={props.inPopover}
      leaderboardNumber={props.leaderboardNumber}
      hideAvatar={props.hideAvatar}
      disabled={disabled}
    >
      {props.children}
    </ObjectCard>
  );
};

PersonCard.defaultProps = {
  cardBodyClassName: '',
  size: 'sm',
  showDescription: true,
  useTagFormat: false,
};

export default React.memo(PersonCard);
