// https://github.com/facebook/react/issues/11538#issuecomment-417504600
// https://app.asana.com/0/home/1203665124224790/1205862660633723
// The translation plugin throws an error when it tries to remove a node that has already been removed.

import React, { FC } from 'react';

import SimpleErrorPage from '../views/Error/SimpleErrorPage';
import { useIntl } from 'react-intl';

const rethrowDomRemovedError = (e: any) => {
  return { ...e, domRemovedError: true };
};

// This is a hack to catch that error and add extra information so the error boundary can show a better message.
export const mokeyPatchForTranslationPluginErrorMessage = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // @ts-expect-error
    Node.prototype.removeChild = function (child, ...args) {
      try {
        return originalRemoveChild.apply(this, [child, ...args]);
      } catch (e: any) {
        if (child.parentNode !== this) {
          throw rethrowDomRemovedError(e);
        }
        throw e;
      }
    };

    const originalInsertBefore = Node.prototype.insertBefore;

    // @ts-expect-error
    Node.prototype.insertBefore = function (newNode, referenceNode, ...args) {
      try {
        return originalInsertBefore.apply(this, [
          newNode,
          referenceNode,
          ...args,
        ]);
      } catch (e: any) {
        if (referenceNode && referenceNode.parentNode !== this) {
          throw rethrowDomRemovedError(e);
        }
        throw e;
      }
    };
  }
};

export const withFallbackErrorWithSpecialInstructions = (
  error: any,
  _info: any
) => <ErrorWithSpecialInstructions error={error} />;

const ErrorWithSpecialInstructions: FC<{ error: any }> = ({ error }) => {
  const { formatMessage } = useIntl();
  if (error?.domRemovedError) {
    return (
      <SimpleErrorPage
        errorMessage={formatMessage(
          {
            id: 'app.hacks.error.with.special.instructions.text',
            defaultMessage:
              'Error due to page changes from a browser extension (e.g., Google Translate). Disable the extension and refresh.<br></br><br></br>' +
              'You can set your preferred language from your account page if your organization has the feature enabled.',
          },
          { br: () => <br /> }
        )}
      />
    );
  }
  return <SimpleErrorPage />;
};
