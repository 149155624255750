import React, { FC, useCallback, useMemo } from 'react';

import Heatmap from './Heatmap/Heatmap';
import PropTypes from 'prop-types';
import { sumObjectValues } from '../../../utils/util/util';

const COLOR_SCALE = (value, data) => {
  if (typeof value !== 'number') {
    return '';
  }
  const baseline = Math.round(100 / data.numOptions);
  if (value > 2 * baseline) {
    return {
      background: '#5588e8',
      text: '#12263f',
    };
  }
  if (value > baseline) {
    return {
      background: '#9fb3f2',
      text: '#12263f',
    };
  }
  return {
    background: '#dae0fa',
    text: '#12263f',
  };
};

const FORMAT_VALUE = (value) => {
  // No responses
  if (typeof value !== 'number') {
    return '-';
  }

  return Math.round(100 * value);
};

const MultipleChoiceHeatmap: FC<Props> = (props) => {
  const question = useMemo(() => props.data[0], [props.data]);

  const getColor = useMemo(() => {
    if (props.colorScale) {
      return props.colorScale;
    }
    return COLOR_SCALE;
  }, [props.colorScale]);

  const getValue = useMemo(() => {
    if (props.formatValue) {
      return props.formatValue;
    }
    return FORMAT_VALUE;
  }, [props.formatValue]);

  const frozenRowData = useMemo(() => {
    // @ts-expect-error
    return question.options?.map((o) => {
      // @ts-expect-error
      const count = question.all?.[o.id] || 0;
      return {
        // @ts-expect-error
        all: { responses: { value: count / question.total, count: count } },
        count: count,
        name: o?.name,
        // @ts-expect-error
        numOptions: question.options.length,
        customPrefix: 'frozen',
        // @ts-expect-error
        _name: question?.displayName,
        _choice: o?.name,
      };
    });
  }, [question]);

  const unFrozenRowData = useCallback(
    (segment) => {
      // @ts-expect-error
      return question.options?.map((o) => {
        // @ts-expect-error
        const formattedResponses = { _name: question.displayName };
        // @ts-expect-error
        const segmentedResponses = question.responses?.[segment];

        for (const _segment in segmentedResponses) {
          const _responses = segmentedResponses[_segment];
          const _count = _responses?.responses?.[o.id] || 0;
          const total = sumObjectValues(_responses?.responses);
          formattedResponses[_segment] = {
            responses: {
              count: _count,
              value: total === 0 ? total : _count / total,
            },
          };
        }

        return {
          // @ts-expect-error
          numOptions: question.options.length,
          _segment: segment,
          _choice: o?.name,
          ...formattedResponses,
        };
      });
    },
    [question]
  );

  return (
    <Heatmap
      data={props.data}
      colorScale={getColor}
      formatValue={getValue}
      frozenRowData={frozenRowData}
      unFrozenRowData={unFrozenRowData}
      isMultipleChoice={true}
      showTitle={true}
    />
  );
};

const MultipleChoiceHeatmap_propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorScale: PropTypes.func,
  formatValue: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof MultipleChoiceHeatmap_propTypes>;

export default React.memo(MultipleChoiceHeatmap);
