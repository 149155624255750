import * as consts from '../../consts/consts';

import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import ActivityCard from './ActivityCard';
import ActivityList from './ActivityList';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import EmptyState from '../Widgets/EmptyState';
import Loading from '../Widgets/Loading';
import ModalActivityEditorButton from './ModalActivityEditorButton';
import { useIntl } from 'react-intl';

const COMPRESSED_CARD_MAX_FACES = 2;

const ActivityListCard: React.FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [showCreateActivityDialog, setShowCreateActivityDialog] =
    useState(false);
  const [activities, setActivities] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const loaderFunction = props.loaderFunction;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch activities from ElasticSearch
    if (typeof activities === 'undefined' && loaderFunction) {
      loaderFunction(
        (newActivities) => {
          if (isMounted) {
            setActivities(newActivities);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [isMounted, activities, loaderFunction]);

  const openCreateActivityDialog = useCallback(() => {
    setShowCreateActivityDialog(true);
  }, []);

  const onCreateActivityDialogClosed = useCallback(() => {
    setShowCreateActivityDialog(false);
  }, []);

  if (errorMessage) {
    console.error('Could not load ' + props.title + ': ' + errorMessage);
    return <></>;
  }

  if (!activities) {
    return <Loading />;
  }

  // @ts-expect-error
  if (activities.length === 0 && props.hideIfEmpty) {
    return <></>;
  }

  if (props.expanded) {
    return (
      <>
        <ModalActivityEditorButton
          isOpen={showCreateActivityDialog}
          onClosed={onCreateActivityDialogClosed}
          hideButton={true}
        />
        {
          // @ts-expect-error
          !(activities?.length > 0) && (
            <Card>
              <CardBody>
                <EmptyState
                  title={formatMessage({
                    id: 'app.views.activities.activity_list_card.title.no_activities',
                    defaultMessage: 'No activities',
                  })}
                  subtitle={props.emptyState}
                />
              </CardBody>
            </Card>
          )
        }
        {
          // @ts-expect-error
          activities?.map((ac, acIndex) => (
            <ActivityCard
              maxFaces={COMPRESSED_CARD_MAX_FACES}
              key={acIndex}
              activity={ac}
              focalPerson={props.focalPerson}
              focalSkill={props.focalSkill}
              focalCredential={props.focalCredential}
              setActivity={(a) =>
                // @ts-expect-error
                setActivities(activities.map((a2) => (a2.id === a.id ? a : a2)))
              }
              openCreateActivityDialog={openCreateActivityDialog}
            />
          ))
        }
      </>
    );
  } else {
    return (
      <>
        <ModalActivityEditorButton
          isOpen={showCreateActivityDialog}
          onClosed={onCreateActivityDialogClosed}
          hideButton={true}
        />
        <Card>
          <CardHeader>
            <CardHeaderTitle
              // @ts-expect-error
              icon={props.icon}
            >
              {props.title}
            </CardHeaderTitle>
          </CardHeader>
          <CardBody>
            {
              // @ts-expect-error
              !(activities?.length > 0) && (
                // @ts-expect-error
                <EmptyState title={props.emptyState} />
              )
            }
            {
              // @ts-expect-error
              activities?.length > 0 && (
                <ActivityList
                  hideImages={true}
                  hideContributions={true}
                  hideDescriptions={true}
                  maxFaces={COMPRESSED_CARD_MAX_FACES}
                  activities={activities}
                  focalPerson={props.focalPerson}
                  focalSkill={props.focalSkill}
                  focalCredential={props.focalCredential}
                  setActivities={setActivities}
                  openCreateActivityDialog={openCreateActivityDialog}
                />
              )
            }
          </CardBody>
        </Card>
      </>
    );
  }
};

ActivityListCard.defaultProps = {
  icon: consts.ICONS.ACTIVITY,
  expanded: false,
};

type Props = InferProps<typeof ActivityListCard_propTypes>;

const ActivityListCard_propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  loaderFunction: PropTypes.func.isRequired,
  emptyState: PropTypes.string,
  hideIfEmpty: PropTypes.bool,
  expanded: PropTypes.bool,
  focalPerson: PropTypes.object,
  focalSkill: PropTypes.object,
  focalCredential: PropTypes.object,
};

export default React.memo(ActivityListCard);
