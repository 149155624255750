import React, { Fragment, useRef } from 'react';
import {
  TeamDashboardPerson,
  getONACellListHeader,
  getONACellListHelperText,
  groupONAByType,
} from './common';

import { ICONS } from '../../../../consts/consts';
import ONAList from './ONAList';
import ScrollableUncontrolledPopover from '../../ScrollableUncontrolledPopover';
import { useIntl } from 'react-intl';

type Props = {
  person: TeamDashboardPerson;
  uniqueId: string;
};

const HeadsUpCell: React.FC<Props> = ({ person, uniqueId }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const targetRef = useRef<HTMLSpanElement>(null);

  const groupedONA = groupONAByType(person.headsups?.[1] ?? [], formatMessage);

  return (
    <>
      <span ref={targetRef} id={'row-heads-ups-' + uniqueId}>
        <i className={ICONS.HEADS_UP} />
        &nbsp;
        {person.headsups_count}
      </span>
      {person.headsups_count > 0 && (
        <ScrollableUncontrolledPopover
          placement="left"
          trigger="hover"
          target={targetRef}
        >
          {groupedONA.map(([onaSourceType, onaList], index) => {
            return (
              <Fragment key={`heads-up-ona-list-item-${onaSourceType}`}>
                {index > 0 && <hr></hr>}
                <ONAList
                  iconClassName={ICONS.HEADS_UP}
                  header={getONACellListHeader(onaSourceType, intl)}
                  onaList={onaList}
                  helperText={getONACellListHelperText(onaSourceType)}
                />
              </Fragment>
            );
          })}
        </ScrollableUncontrolledPopover>
      )}
    </>
  );
};

export default HeadsUpCell;
