import { Col, ListGroup, Row } from 'reactstrap';
import { Me, ReduxState } from '../../../types';
import { useAvailableScopes, useObjectivesOverview } from './hooks';

import EmptyState from '../../Widgets/EmptyState';
import Loading from '../../Widgets/Loading';
import ObjectivesOverviewItemHeader from './ObjectivesOverviewItemHeader';
import ObjectivesOverviewScopeFilter from './ObjectivesOverviewScopeFilter';
import ObjectivesTimeFrameSelector from '../ObjectivesTimeFrameSelector';
import OperationInProgressPrompt from '../../../components/OperationInProgressPrompt';
import PersonalObjectiveItemRoot from '../../Person/PersonalObjectiveItemRoot';
import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

interface Props {
  me: Me;
}

const ObjectivesOverviewPage: React.FC<Props> = ({ me }) => {
  const { formatMessage } = useIntl();

  const availableScopes = useAvailableScopes();

  const {
    handleViewNextTimeframe,
    currentTimeframeText,
    handleViewPreviousTimeframe,
    isLoadingMoreItems,
    handleFilterChange,
    viewStatus,
    viewScope,
    viewPeopleFilters,
    allObjectives,
    loadMoreRef,
    handleGenerateCSV,
    handleGenerateCSVCompleted,
    status,
    firstDay,
    lastDay,
    viewTextFilter,
    isTaskInProgress,
  } = useObjectivesOverview({ availableScopes });

  if (status === 'ERROR' && !isLoadingMoreItems) {
    return <EmptyState title={'Error.... OOPS'} />;
  }

  return (
    <>
      <OperationInProgressPrompt
        hasPendingChanges={isTaskInProgress}
        message={formatMessage({
          id: 'app.views.objectives.objectives_overview_page.download_in_progress',
          defaultMessage:
            'A download is in progress. Are you sure you want to leave this page?',
        })}
      />
      <Row>
        <Col>
          <ObjectivesTimeFrameSelector
            viewNextTimeframe={handleViewNextTimeframe}
            currentTimeframeText={currentTimeframeText}
            viewPreviousTimeframe={handleViewPreviousTimeframe}
            disabled={
              (status === 'LOADING' || status == 'DISABLED') &&
              !isLoadingMoreItems
            }
          />
          <ObjectivesOverviewScopeFilter
            onChange={handleFilterChange}
            onGenerateCSV={handleGenerateCSV}
            onGenerateCSVCompleted={handleGenerateCSVCompleted}
            status={viewStatus}
            scope={viewScope}
            peopleFilters={viewPeopleFilters}
            availableScopes={availableScopes}
            textFilter={viewTextFilter}
            firstDay={firstDay}
            lastDay={lastDay}
            disabled={
              (status === 'LOADING' || status == 'DISABLED') &&
              !isLoadingMoreItems
            }
            hasData={allObjectives.length > 0}
          />
          {status === 'LOADING' && !isLoadingMoreItems && <Loading />}
          {status === 'SUCCESS' && allObjectives.length === 0 && (
            <EmptyState title={'No Data'} />
          )}
          {(status === 'SUCCESS' ||
            status === 'DISABLED' ||
            isLoadingMoreItems) &&
            allObjectives.length > 0 && (
              <ListGroup>
                <ObjectivesOverviewItemHeader />
                {allObjectives.map((objective, index) => (
                  <PersonalObjectiveItemRoot
                    isEditable={false}
                    isInPopover={false}
                    hideScoresAndWeight={false}
                    hideRelatedObjectives={false}
                    person={me}
                    key={objective.key}
                    // @ts-expect-error
                    objective={objective}
                    showLabelHeadings={index === 0}
                    forceShowChildren={false}
                    enableParentEdit={false}
                    accessiblePeopleIds={[]}
                    showDescendantsCount={true}
                    showDescendantsBreakdown={true}
                    textToHighlight={viewTextFilter}
                    applyFiltering={true}
                  />
                ))}
                <div ref={loadMoreRef} />
                {isLoadingMoreItems && <Loading />}
              </ListGroup>
            )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { me } = state;
  return {
    me,
  };
};

const connector = connect(mapStateToProps);

export default connector(React.memo(ObjectivesOverviewPage));
