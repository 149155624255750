import React, { FC, useState } from 'react';

type Props = {
  text: string;
  forceReveal?: boolean | (() => boolean);
  children: React.ReactNode;
  [key: string]: unknown;
};
const RevealLink: FC<Props> = ({
  text,
  forceReveal = false,
  children,
  ...otherProps
}) => {
  const [userRevealContent, setUserRevealContent] = useState(forceReveal);
  const shouldForceReveal =
    typeof forceReveal === 'function' ? forceReveal() : forceReveal;

  const handleClick = () => setUserRevealContent(true);

  return userRevealContent || shouldForceReveal ? (
    <>{children}</>
  ) : (
    <div {...otherProps}>
      <button className="btn btn-link p-0" onClick={handleClick}>
        {text}
      </button>
    </div>
  );
};

export default RevealLink;
