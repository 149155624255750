import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useState } from 'react';

import { Objective } from '../../utils/models/Objective';

interface Props {
  isEditable?: boolean;
  setShowChildren?: (boolean) => void;
  setShowDeleteModal: (boolean) => void;
  setShowCopyToNextPeriodModal: (boolean) => void;
  addChild: (Objective) => void;
  value: Objective;
  setValue: (any) => void;
}

const ObjectiveItemMoreControls: FC<Props> = ({
  isEditable,
  setShowChildren,
  setShowDeleteModal,
  setShowCopyToNextPeriodModal,
  addChild,
  value,
  setValue,
}) => {
  const { formatMessage } = useIntl();
  const [showExtraControls, setShowExtraControls] = useState(false);
  const toggle = useCallback(
    () => setShowExtraControls((prevState) => !prevState),
    []
  );

  const handleOnClickAddSubObjective = useCallback(
    () => addChild({ value, setValue, setShowChildren }),
    [addChild, setShowChildren, setValue, value]
  );
  const handleOnClickDeleteObjective = useCallback(
    () => setShowDeleteModal(true),
    [setShowDeleteModal]
  );

  const handleOnClickCopyToNextPeriod = useCallback(
    () => setShowCopyToNextPeriodModal(true),
    [setShowCopyToNextPeriodModal]
  );
  const output = (
    <>
      {isEditable && (
        <>
          <Dropdown isOpen={showExtraControls} toggle={toggle} direction="end">
            <DropdownToggle tag="span">
              <Button
                size="sm"
                color="secondary"
                id={`add-child-button-${value.key}`}
                aria-label={formatMessage({
                  id: 'app.views.person.personal_objective_item.more_actions.aria_label',
                  defaultMessage: 'More actions button',
                })}
                className="p-1"
                style={{
                  opacity: 0.45,
                }}
                outline
              >
                <i className="fe fe-more-vertical" />
              </Button>
            </DropdownToggle>
            <DropdownMenu className="pt-2 pb-2" end>
              <DropdownItem
                id={`add-child-button-${value.key}`}
                onClick={handleOnClickAddSubObjective}
              >
                <i className="pe-2 fe fe-plus" />
                <FormattedMessage
                  id="app.views.person.objective_item_add_delete_controls.add_a_subobjective"
                  defaultMessage="Add a subobjective"
                />
              </DropdownItem>
              <DropdownItem
                id={`copy-to-next-period-button-${value.key}`}
                onClick={handleOnClickCopyToNextPeriod}
              >
                <i className="pe-2 fe fe-copy" />
                <FormattedMessage
                  id="app.views.person.objective_item_add_delete_controls.copy_to_next_period"
                  defaultMessage="Copy this objective to the next period"
                />
              </DropdownItem>
              <DropdownItem
                id={`delete-button-${value.key}`}
                onClick={handleOnClickDeleteObjective}
              >
                <i className="pe-2 fe fe-trash" />
                <FormattedMessage
                  id="app.views.person.objective_item_add_delete_controls.delete"
                  defaultMessage="Delete this objective"
                />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </>
      )}
    </>
  );
  return output;
};

export default ObjectiveItemMoreControls;
