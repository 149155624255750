import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

type Props = {
  position: string;
  stuckClasses?: string;
  unstuckClasses?: string;
  stuckStyles?: CSSProperties;
  unstuckStyles?: CSSProperties;
};
// Per https://mtm.dev/sticky-stuck-styles this allows CSS position "sticky" that
// change visually when they are "stuck"
const Sticky: FC<PropsWithChildren<Props>> = ({
  position,
  stuckClasses = '',
  unstuckClasses = '',
  stuckStyles = {},
  unstuckStyles = {},
  children,
}) => {
  const [stuck, setStuck] = useState(false);
  const ref = React.createRef<HTMLDivElement>();

  const classes = stuck ? stuckClasses : unstuckClasses;
  const styles = stuck ? stuckStyles : unstuckStyles;

  const inlineStyles: CSSProperties = {
    position: 'sticky',
    [position]: -1,
    ...styles,
  };

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] }
    );
    if (cachedRef) {
      observer.observe(cachedRef);
    }
    return () => {
      if (cachedRef) {
        observer.unobserve(cachedRef);
      }
    };
  }, [ref]);

  return (
    <div style={inlineStyles} className={classes} ref={ref}>
      {children}
    </div>
  );
};

export default React.memo(Sticky);
