import { FormattedMessage, useIntl } from 'react-intl';
import { Me, Person } from '../../types';
import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import ConfirmationDialogModalMultiAction from '../Widgets/Modals/ConfirmationDialogModalMultiAction';
import { Objective } from '../../utils/models/Objective';
import { RTF_FUNCTIONS } from '../../locale/RTFUtils';
import SafeUncontrolledPopover from '../../components/SafeUncontrolledPopover';

type OnDeleteParams = {
  deleteAllChildren: boolean;
  removeForAllCollaborators: boolean;
};
interface Props {
  objective: Objective;
  isOpen: boolean;
  onClosed?: () => void;
  onDelete: ({
    deleteAllChildren,
    removeForAllCollaborators,
  }: OnDeleteParams) => void;
  toggle: () => void;
  validationErrors?: string | Array<string>;
  zIndex?: number;
  me: Me;
  person: Person;
}

const PersonalObjectiveItemDeleteModal: FC<Props> = ({
  objective,
  isOpen,
  onClosed: onClosedProps = () => {
    //do nothing
  },
  toggle,
  onDelete,
  validationErrors = '',
  zIndex,
  me,
  person,
}) => {
  const { formatMessage } = useIntl();

  const hasMultipleCollaborators = (objective?.collaborators?.length ?? 0) > 1;
  const hasChildren = (objective?.children?.length ?? 0) > 0;
  const isStandaloneOKR = !hasMultipleCollaborators && !hasChildren;
  const [deleteAllChildren, setDeleteForAllChildren] = useState<boolean>(false);
  const [removeForAllCollaborators, setremoveForAllCollaborators] =
    useState<boolean>(false);

  const handleDelete = useCallback(() => {
    if (isStandaloneOKR) {
      return onDelete({ deleteAllChildren, removeForAllCollaborators: true });
    }
    return onDelete({ deleteAllChildren, removeForAllCollaborators });
  }, [onDelete, deleteAllChildren, removeForAllCollaborators, isStandaloneOKR]);

  const [description, className, actions] = useMemo(() => {
    return [
      formatMessage(
        {
          id: 'app.views.person.personal_objective_item_delete_modal.dialog.question.delete_objective.simple.description',
          defaultMessage:
            'Are you sure you want to delete the following objective? <p>{objective}</p>',
        },
        {
          objective: objective.name,
          p: (chunks: ReactNode) => (
            <p className="fw-bold text-secondary mt-2 objective-to-delete-name">
              <i
                className="fe fe-arrow-right text-secondary me-2 position-relative"
                style={{ top: '1px' }}
              />
              {chunks}
            </p>
          ),
        }
      ),
      'col-md-6',
      [
        {
          text: formatMessage({
            id: 'app.views.person.personal_objective_item_delete_modal.cancel',
            defaultMessage: 'Cancel',
          }),
          color: 'light',
          onClick: toggle,
          className: 'col-md-2',
          outline: false,
        },
        {
          text: formatMessage({
            id: 'app.views.person.personal_objective_item_delete_modal.delete',
            defaultMessage: 'Delete',
          }),
          color: 'danger',
          onClick: handleDelete,
          className: 'col-md-2',
          outline: false,
        },
      ],
    ];
  }, [objective.name, formatMessage, toggle, handleDelete]);

  return (
    <ConfirmationDialogModalMultiAction
      isOpen={isOpen}
      onClosed={onClosedProps}
      toggle={toggle}
      validationErrors={validationErrors}
      actions={actions}
      className={className}
      zIndex={zIndex}
      title={formatMessage({
        id: 'app.views.person.personal_objective_item_delete_modal.dialog.question.delete_objective.title',
        defaultMessage: 'Delete objective',
      })}
      description={description}
    >
      {!isStandaloneOKR && (
        <div className="mt-4">
          <input
            className="form-check-input me-2"
            type="checkbox"
            title={formatMessage({
              id: 'app.views.person.personal_objective_item_delete_modal.title.delete_for_all_collaborators',
              defaultMessage: 'Delete for all collaborators',
            })}
            checked={removeForAllCollaborators}
            onChange={() =>
              setremoveForAllCollaborators(!removeForAllCollaborators)
            }
          />
          <FormattedMessage
            id="app.views.person.personal_objective_item_delete_modal.checkbox.delete_for_collaborators"
            defaultMessage="For other collaborators, delete for those collaborators as well"
          />
          <>
            <i
              id="helper-hover-collaborators"
              className="ms-2 fe fe-help-circle text-primary small"
            />
            <SafeUncontrolledPopover
              placement="top"
              trigger="hover"
              target="helper-hover-collaborators"
            >
              <FormattedMessage
                id="app.views.person.personal_objective_item_delete_modal.checkbox.delete_for_collaborators.helper"
                defaultMessage="Use caution before checking this box.
                <br></br><br></br>If you check this box, we will delete this objective for {isMe, select, true {you} other {{collaborator}}} AND for the other collaborator(s) on this objective.
                This includes deleting all history and comments on the objective for both {isMe, select, true {you} other {{collaborator}}} AND them.
                Be sure that they want it to be deleted on their end as well before checking this box.
                <br></br><br></br>If you do not check this box, we will just remove {isMe, select, true {you} other {{collaborator}}} as a collaborator from this objective instead of deleting it."
                values={{
                  isMe: me.id === person.id,
                  collaborator: person.full_name,
                  ...RTF_FUNCTIONS,
                }}
              />
            </SafeUncontrolledPopover>
          </>
        </div>
      )}
      {hasChildren && (
        <div className="mt-2">
          <input
            className="form-check-input me-2"
            type="checkbox"
            title={formatMessage({
              id: 'app.views.person.personal_objective_item_delete_modal.title.delete_all_children',
              defaultMessage: 'Delete all subobjectives',
            })}
            checked={deleteAllChildren}
            onChange={() => setDeleteForAllChildren(!deleteAllChildren)}
          />
          <FormattedMessage
            id="app.views.person.personal_objective_item_delete_modal.checkbox.delete_all_children"
            defaultMessage="Also delete all of this objective's subobjectives"
          />
        </div>
      )}
    </ConfirmationDialogModalMultiAction>
  );
};

export default PersonalObjectiveItemDeleteModal;
