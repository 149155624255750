import { Col, Row } from 'reactstrap';
import { Features, Organization, Person } from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useEffect, useMemo, useState } from 'react';

import Avatar from '../Widgets/People/Avatar';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getContributionPerson } from '../../utils/models/Activity';
import { getPersonDisplayTitleHtml } from '../../utils/models/Person';
import { isSkillsTalentInventoryEnabled } from 'utils/util/features';
import { loadOrRender } from '../../utils/util/formatter';
import { standardConfirmAPIsendRequestToConfirmPromiseCallback } from '../../utils/util/utiltsx';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  skillId: string;
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  features: Features;
  setPeopleCount: (number) => void;
};

const SkillDashboard: FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [contributions, setContributions] = useState(undefined);
  const [peopleWithDeclaredSkill, setPeopleWithDeclaredSkill] =
    useState(undefined);
  const [relationships, setRelationships] = useState(undefined);
  const [feedbacks, setFeedbacks] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth0();
  const userSub = user?.sub;

  const currentOrgId = props.currentOrganization?.id;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const promises: Promise<any>[] = [];
    if (userSub && currentOrgId && props.skillId) {
      // fetch contributions from RDS
      const fetchContributions = new Promise((resolve, reject) => {
        ConfirmAPI.sendRequestToConfirm(
          'GET',
          'get-contributions-for-skill/' + encodeURIComponent(props.skillId),
          {
            proxy: props.currentProxyPerson?.email,
            organization_id: currentOrgId,
          },
          standardConfirmAPIsendRequestToConfirmPromiseCallback(resolve, reject)
        );
      });
      promises.push(fetchContributions);

      // fetch people with declared skills
      const fetchPeopleDeclared = new Promise((resolve, reject) => {
        ConfirmAPI.sendRequestToConfirm(
          'GET',
          'get-people-with-declared-skill-rds/' +
            encodeURIComponent(props.skillId),
          {
            proxy: props.currentProxyPerson?.email,
            organization_id: currentOrgId,
          },
          standardConfirmAPIsendRequestToConfirmPromiseCallback(resolve, reject)
        );
      });
      promises.push(fetchPeopleDeclared);

      if (isSkillsTalentInventoryEnabled(props.features)) {
        const fetchContinuousRecognition = new Promise((resolve, reject) => {
          ConfirmAPI.sendRequestToConfirm(
            'GET',
            'get-continuous-recognition-for-skill/' +
              encodeURIComponent(props.skillId),
            {
              proxy: props.currentProxyPerson?.email,
              organization_id: currentOrgId,
            },
            standardConfirmAPIsendRequestToConfirmPromiseCallback(
              resolve,
              reject
            )
          );
        });
        promises.push(fetchContinuousRecognition);

        const fetchPositiveRelationship = new Promise((resolve, reject) => {
          ConfirmAPI.sendRequestToConfirm(
            'GET',
            'get-positive-relationship-for-skill/' +
              encodeURIComponent(props.skillId),
            {
              proxy: props.currentProxyPerson?.email,
              organization_id: currentOrgId,
            },
            standardConfirmAPIsendRequestToConfirmPromiseCallback(
              resolve,
              reject
            )
          );
        });
        promises.push(fetchPositiveRelationship);
      }

      Promise.all(promises)
        .then((resolvedPromises) => {
          setContributions(resolvedPromises[0]);
          setPeopleWithDeclaredSkill(resolvedPromises[1]);
          if (isSkillsTalentInventoryEnabled(props.features)) {
            setFeedbacks(resolvedPromises[2]);
            setRelationships(resolvedPromises[3]);
          }
        })
        .catch((error) => {
          setErrorMessage(error);
        });
    }
  }, [
    currentOrgId,
    isMounted,
    props.currentProxyPerson,
    props.features,
    props.skillId,
    userSub,
  ]);

  // get contributions of that user with that skill
  const mentions = useMemo(() => {
    if (
      typeof contributions === 'undefined' ||
      typeof peopleWithDeclaredSkill === 'undefined'
    ) {
      return undefined;
    }

    // @ts-expect-error
    let mentions = contributions?.reduce((acc, contribution) => {
      const person = getContributionPerson(contribution);

      // go by email so we handle invitations properly
      if (typeof acc[person.email] !== 'undefined') {
        acc[person.email].mentions_count++;
        acc[person.email].people_ids.add(person.person_id);
      } else {
        acc[person.email] = {
          person: person,
          mentions_count: 1,
          people_ids: new Set([person.person_id]),
        };
      }

      return acc;
    }, {});

    // add people who self-declared
    if (peopleWithDeclaredSkill) {
      // @ts-expect-error
      mentions = peopleWithDeclaredSkill?.reduce(
        (acc, person) => {
          // go by email so we handle invitations properly
          if (person?.email && typeof acc[person.email] !== 'undefined') {
            acc[person.email].mentions_count++;
            acc[person.email].people_ids.add(person.id);
          } else if (person?.email) {
            acc[person.email] = {
              person: person,
              mentions_count: 1,
              people_ids: new Set([person.id]),
            };
          }

          return acc;
        },
        mentions ? mentions : {}
      );
    }

    if (isSkillsTalentInventoryEnabled(props.features)) {
      if (
        typeof feedbacks === 'undefined' ||
        typeof relationships === 'undefined'
      ) {
        return undefined;
      }

      const reduceStandardObjects = (inputList, mentions) => {
        return inputList?.reduce(
          (acc, object) => {
            const person = object.recipient_person;
            // go by email so we handle invitations properly
            if (person?.email && typeof acc[person.email] !== 'undefined') {
              acc[person.email].mentions_count++;
              acc[person.email].people_ids.add(object.author_person_id);
            } else if (person?.email) {
              acc[person.email] = {
                person: person,
                mentions_count: 1,
                people_ids: new Set([object.author_person_id]),
              };
            }

            return acc;
          },
          mentions ? mentions : {}
        );
      };

      if (feedbacks) {
        mentions = reduceStandardObjects(feedbacks, mentions);
      }

      if (relationships) {
        mentions = reduceStandardObjects(relationships, mentions);
      }
    }

    return mentions
      ? Object.values(mentions).sort((a, b) => {
          // @ts-expect-error
          if (b.mentions_count === a.mentions_count) {
            // @ts-expect-error
            return b.people_ids.size - a.people_ids.size;
          }
          // @ts-expect-error
          return b.mentions_count - a.mentions_count;
        })
      : undefined;
  }, [
    contributions,
    feedbacks,
    peopleWithDeclaredSkill,
    props.features,
    relationships,
  ]);

  const propsSetPeopleCount = props.setPeopleCount;
  useEffect(() => {
    if (typeof mentions !== 'undefined') {
      propsSetPeopleCount(mentions.length);
    }
  }, [mentions, propsSetPeopleCount]);

  const loadOrRenderOutput = loadOrRender(mentions, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return (
    <>
      {mentions?.length === 0 && (
        <EmptyState
          title={formatMessage({
            id: 'app.views.skills.skill_dashboard.title.no_people_found',
            defaultMessage: 'No people found',
          })}
          subtitle={formatMessage({
            id: 'app.views.skills.skill_dashboard.subtitle.nobody_has_declared_that_they_have',
            defaultMessage:
              'Nobody has declared that they have experience with this skill.',
          })}
        />
      )}
      {
        // @ts-expect-error
        mentions?.length > 0 && (
          <Row>
            <Col className="col-12 col-xl-8">
              {mentions?.map((mention, mentionIndex) => (
                <div key={mentionIndex} className="card mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <Avatar
                          className="avatar-md"
                          person={
                            // @ts-expect-error
                            mention.person
                          }
                        />
                      </div>
                      <div className="col ms-n2">
                        <h4 className="mb-1 mt-1">
                          <Link
                            to={
                              // @ts-expect-error
                              mention.person.url
                            }
                          >
                            {
                              // @ts-expect-error
                              mention.person.full_name
                            }
                          </Link>
                        </h4>
                        <p className="text-muted mb-0">
                          {getPersonDisplayTitleHtml(
                            formatMessage,
                            // @ts-expect-error
                            mention.person
                          )}
                        </p>
                      </div>
                      <div className="col-auto text-center">
                        <h1 className="mt-1 mb-n1">
                          {
                            // @ts-expect-error
                            mention.mentions_count
                          }
                        </h1>
                        <p className="text-muted mb-0">
                          <FormattedMessage
                            id="app.views.skills.skill_dashboard.mentions.text"
                            defaultMessage="Mentions"
                          />
                        </p>
                      </div>
                      {isSkillsTalentInventoryEnabled(props.features) && (
                        <FormattedMessage
                          id="app.views.skills.skill_dashboard.mentions.talent_inventory"
                          defaultMessage="<by>by</by><div><count>{mentionPeopleIdsSize}</count><people>{mentionPeopleIdsSize, plural, one {person} other {people}}</people></div>"
                          values={{
                            by: (chunks) => (
                              <div className="col-auto text-center">
                                <p className="text-muted mb-0">{chunks}</p>
                              </div>
                            ),
                            div: (...chunks) => (
                              <div className="col-auto text-center">
                                {chunks}
                              </div>
                            ),
                            count: (chunks) => (
                              <h1 className="mt-1 mb-n1">{chunks}</h1>
                            ),
                            people: (chunks) => (
                              <p className="text-muted mb-0">{chunks}</p>
                            ),
                            // @ts-expect-error
                            mentionPeopleIdsSize: mention.people_ids.size,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(SkillDashboard));
