import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import SimplePage from '../SimplePage';

// This page can be enabled putting the site in Maintenance mode
// https://www.notion.so/confirm/Maintenance-Mode-304449577be54a869ccc8da144c5db58#42feafc536b0437cbe2b5e2fe533905f

const PageMaintenance: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <SimplePage
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page_maintenance.title',
        defaultMessage: 'Confirm is undergoing maintenance.',
      })}
      titleClassName="text-center"
    >
      <div className="text-center">
        <p>
          <FormattedMessage
            id="app.views.layout.pages.errors.page_maintenance.main_text"
            defaultMessage="Thank you for your patience as we work on making improvements to Confirm. Please come back shortly."
          />
        </p>
      </div>
    </SimplePage>
  );
};

export default React.memo(PageMaintenance);
