import { Button, Col, Container, Input, Row } from 'reactstrap';
import React, { FC, useState } from 'react';
import {
  TalentMatrixAxisSettings,
  TalentMatrixGridCellProps,
  renderNumber,
} from '../TalentMatrix';

import CellEditor from './CellEditor';
import { useIntl } from 'react-intl';

interface Props {
  columns: number;
  rows: number;
  maxColumns: number;
  maxRows: number;
  handleRemoveColumn: () => void;
  handleAddCloumn: () => void;
  axisSettings: TalentMatrixAxisSettings;
  handleChangeAxisBuckets: (
    axis: string,
    reverseOrderField: string,
    index: number
  ) => (e: any) => void;
  quadrants: TalentMatrixGridCellProps[];
  handleAddRow: () => void;
  handleRemoveRow: () => void;
  handleOnCellChange: (x: number, y: number) => (e: any) => void;
}

const MIN_ROWS = 2;
const MIN_COLUMNS = 2;

const MatrixEditor: FC<Props> = ({
  columns,
  rows,
  maxColumns,
  maxRows,
  handleRemoveColumn,
  handleAddCloumn,
  axisSettings,
  handleChangeAxisBuckets,
  quadrants,
  handleAddRow,
  handleRemoveRow,
  handleOnCellChange,
}) => {
  const { formatMessage } = useIntl();
  const addRemoveStyle = { width: '45px' };

  const [editingCell, setEditingCell] = useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >(undefined);

  return (
    <Container>
      <Row className="col-12 text-center pb-4 pt-4">
        <Col className="col-2">{''}</Col>
        <Col>
          {columns > MIN_COLUMNS && (
            <Button
              style={addRemoveStyle}
              onClick={handleRemoveColumn}
              className="btn btn-light ms-2"
            >
              {'-'}
            </Button>
          )}
          {columns < maxColumns && (
            <Button
              style={addRemoveStyle}
              onClick={handleAddCloumn}
              className="btn btn-light ms-2"
            >
              {'+'}
            </Button>
          )}
        </Col>
        <Col className="col-1">{''}</Col>
      </Row>
      <Row className="col-12 pb-3">
        <Col className="p-0 m-0 b-0 col-1 align-self-center">
          <AxisLabel
            description={axisSettings.y_axis_metric_description}
            label={axisSettings.y_axis_label}
            rotate={true}
          />
        </Col>
        <Col>
          {Array.from(Array(rows).keys())
            .reverse()
            .map((y) => {
              const isReversed = axisSettings.y_axis_order_reversed;
              const buckets = axisSettings.y_axis_buckets;
              return (
                <Row key={y} className="p-0">
                  <Col className="col-1 p-0">
                    <div className="d-flex text-center p-0 h-100">
                      <div className="align-self-center">
                        <Row>
                          <Col>
                            <div className="mt-2">
                              {y === 0 ? (
                                <div
                                  className="mt-3 mb-3 "
                                  style={{ width: '60px' }}
                                >
                                  {renderNumber(buckets[y][0])}
                                </div>
                              ) : (
                                <Input
                                  type="text"
                                  className="ps-0 pe-0 text-center"
                                  style={{ width: '60px' }}
                                  value={buckets[y][0]}
                                  onChange={handleChangeAxisBuckets(
                                    'y_axis_buckets',
                                    'y_axis_order_reversed',
                                    y
                                  )}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <small className="text-nowrap text-muted">
                              {isReversed
                                ? formatMessage({
                                    id: 'app.views.talent_matrix.talent_matrix_grid_editor.and_below',
                                    defaultMessage: 'and below',
                                  })
                                : formatMessage({
                                    id: 'app.views.talent_matrix.talent_matrix_grid_editor.and_above',
                                    defaultMessage: 'and above',
                                  })}
                            </small>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  {Array.from(Array(columns).keys())
                    .map((x) => {
                      const quadrant = quadrants.find(
                        (it) => it.x === x && it.y === y
                      )!;
                      return (
                        <Col key={x} className="p-0">
                          <CellEditor
                            editingCell={editingCell}
                            setEditingCell={setEditingCell}
                            x={quadrant.x}
                            y={quadrant.y}
                            title={quadrant.title}
                            onTextChange={handleOnCellChange(
                              quadrant.x,
                              quadrant.y
                            )}
                            axisSettings={axisSettings}
                          />
                        </Col>
                      );
                    })
                    .filter((it) => it !== null)}
                </Row>
              );
            })}
          <Row className="p-0 pt-3">
            <Col className="col-1 p-0">{''}</Col>
            <Col className="p-0">
              <Row>
                {Array.from(Array(columns).keys()).map((x) => {
                  const isReversed = axisSettings.x_axis_order_reversed;
                  const buckets = axisSettings.x_axis_buckets;
                  return (
                    <Col key={x} className="">
                      <Row>
                        <Col className="col-12 d-flex justify-content-center ">
                          <div className="d-flex text-center p-0 h-100">
                            <div className="align-self-center">
                              <Row>
                                <Col>
                                  <div className="mt-2">
                                    {x === 0 ? (
                                      <div
                                        className="mt-3 mb-3 "
                                        style={{ width: '60px' }}
                                      >
                                        {renderNumber(buckets[x][0])}
                                      </div>
                                    ) : (
                                      <Input
                                        type="text"
                                        className="ps-0 pe-0 text-center"
                                        style={{ width: '60px' }}
                                        value={buckets[x][0]}
                                        onChange={handleChangeAxisBuckets(
                                          'x_axis_buckets',
                                          'x_axis_order_reversed',
                                          x
                                        )}
                                      />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <small className="text-nowrap text-muted">
                                    {isReversed
                                      ? formatMessage({
                                          id: 'app.views.talent_matrix.talent_matrix_grid_editor.and_below',
                                          defaultMessage: 'and below',
                                        })
                                      : formatMessage({
                                          id: 'app.views.talent_matrix.talent_matrix_grid_editor.and_above',
                                          defaultMessage: 'and above',
                                        })}
                                  </small>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="p-0 m-0 b-0 col-1 align-self-center">
          <h1 className="p-0 ps-4 m-0 b-0">
            {rows < maxRows && (
              <Button
                style={addRemoveStyle}
                onClick={handleAddRow}
                className="btn btn-light"
              >
                {'+'}
              </Button>
            )}
            <br />
            {rows > MIN_ROWS && (
              <Button
                style={addRemoveStyle}
                onClick={handleRemoveRow}
                className="btn btn-light mt-2"
              >
                {'-'}
              </Button>
            )}
          </h1>
        </Col>
      </Row>

      <Row className="col-12 text-center pb-3">
        <Col className="col-2">{''}</Col>
        <Col>
          <AxisLabel
            description={axisSettings.x_axis_metric_description}
            label={axisSettings.x_axis_label}
          />
        </Col>
        <Col className="col-1">{''}</Col>
      </Row>
    </Container>
  );
};

const AxisLabel = ({
  label,
  description,
  rotate = false,
}: {
  label: string;
  description: string;
  rotate?: boolean;
}) => {
  return (
    <div
      className="d-flex justify-content-center text-nowrap"
      style={{ transform: `rotate(${rotate ? '-90' : '0'}deg)` }}
    >
      <span className="fs-1">
        <span className="text-uppercase">{`${label}`}</span>
        {' - '}
        {`${description}`}
      </span>
    </div>
  );
};

export default MatrixEditor;
