import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { getExternalSiteProfileButton } from '../../../utils/models/Person';

interface Props {
  person: any;
}

const ExternalPersonEnrichedProfileCard: FC<Props> = (props) => {
  const profile = props.person.enriched_profile;
  if (!profile) {
    return <></>;
  }

  const getFollowersAbbreviation = (followerCount) => {
    if (followerCount < 10) {
      return '< 10';
    }
    if (followerCount < 100) {
      return '< 100';
    }
    if (followerCount < 500) {
      return '< 500';
    }
    if (followerCount < 1000) {
      return '< 1000';
    }
    if (followerCount < 1000000) {
      return Math.floor(followerCount / 1000) + 'k';
    } else {
      return (followerCount / 1000000).toFixed(1) + 'M';
    }
  };

  return (
    <>
      <Row>
        <Col className="mt-1">
          {getExternalSiteProfileButton(profile, 'linkedin')}
          {getExternalSiteProfileButton(profile, 'angellist')}
          {getExternalSiteProfileButton(profile, 'twitter')}
          {getExternalSiteProfileButton(profile, 'github')}
          {getExternalSiteProfileButton(profile, 'stackoverflow')}
          {getExternalSiteProfileButton(profile, 'quora')}
          {getExternalSiteProfileButton(profile, 'youtube')}
          {getExternalSiteProfileButton(profile, 'facebook')}
        </Col>
      </Row>
      {profile.twitter && profile.twitter.followers && (
        <Row className="mt-1 small text-muted">
          <Col>
            <FormattedMessage
              id="app.views.widgets.cards.external_person_enriched_profile_card.followers"
              defaultMessage="{followersCount} followers"
              values={{
                followersCount: getFollowersAbbreviation(
                  profile.twitter.followers
                ),
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(ExternalPersonEnrichedProfileCard);
