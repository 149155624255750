import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useMemo, useState } from 'react';

import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import PropTypes from 'prop-types';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';

const PulseCheckInput = (props) => {
  const { formatMessage } = useIntl();
  // note: we ONLY update this on initial load, NOT when updated
  // from the server to enable autosave to not overwrite a current
  // work in progress
  const [object, setObject] = useState(props.value);

  const onChange = useCallback((o) => {
    // set locally immediately so we can show the relevant inputs
    // even if the server hasn't updated
    setObject(o);
  }, []);

  const renderInputs = useCallback(
    (inputs) => {
      // only show comments if rating has been set
      if (object?.rating > 0) {
        return (
          <>
            {inputs.map((input, i) => {
              return <div key={i}>{input}</div>;
            })}
          </>
        );
      } else {
        return <>{inputs[0]}</>;
      }
    },
    [object?.rating]
  );

  const propsCancelCallback = props.cancelCallback;
  const onCancel = useCallback(() => {
    if (propsCancelCallback) {
      propsCancelCallback();
      setObject(props.value);
    }
  }, [props.value, propsCancelCallback]);

  const cancelButton = useMemo(
    () => (
      <div className="btn-link d-flex" role="button" onClick={onCancel}>
        <FormattedMessage
          id="app.views.pulse_checks.pulse_check_input.cancel"
          defaultMessage="Cancel"
        />
      </div>
    ),
    [onCancel]
  );

  // hide submit button if star rating has not
  // yet been selected
  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {inputs}
          {object?.rating > 0 && (
            <Row className="align-items-center">
              <Col className="col-auto mt-3">{cancelButton}</Col>
              <Col>{submitButton}</Col>
            </Row>
          )}
        </>
      );
    },
    [cancelButton, object?.rating]
  );

  const transformObjectBeforeSubmit = useCallback((o) => {
    // only send rating, rating comments, and organization
    return {
      organization: o.organization,
      rating: o.rating,
      rating_comments: o.rating_comments,
    };
  }, []);

  return (
    <>
      <ValidatedForm
        buttonClassName="mt-3"
        method={object?.id ? 'PATCH' : 'POST'}
        url={'pulse-checks' + (object?.id ? '/' + object.id : '')}
        object={object}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        inputs={[
          {
            name: 'rating',
            label: formatMessage({
              id: 'app.views.pulse_checks.pulse_check_input.label.how_was_week',
              defaultMessage: 'How has your week been?',
            }),
            helperHover: formatMessage({
              id: 'app.views.pulse_checks.pulse_check_input.helper_hover.how_was_week',
              defaultMessage:
                'Your responses are only viewable to your manager and above and HR.',
            }),
            type: INPUT_TYPES.STAR_RATING,
            formGroupClassName: object?.rating > 0 ? undefined : 'mb-0',
          },
          {
            name: 'rating_comments',
            label: formatMessage({
              id: 'app.views.pulse_checks.pulse_check_input.label.what_challenges',
              defaultMessage: 'What challenges are you encountering?',
            }),
            type: INPUT_TYPES.TEXTAREA,
            minRows: 2,
            required: true,
            autoFocus: true,
          },
        ]}
        onChange={onChange}
        callback={props.callback}
        renderInputs={renderInputs}
        renderForm={renderForm}
      />
    </>
  );
};

PulseCheckInput.propTypes = {
  value: PropTypes.object,
  callback: PropTypes.func,
  cancelCallback: PropTypes.func,
};

export default PulseCheckInput;
