import * as consts from '../../consts/consts';

import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
// @ts-expect-error
import Intercom, { IntercomAPI } from '../../vendor/react-intercom';
import { Link, NavLink } from 'react-router-dom';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import {
  getUserIsSuperAdmin,
  getUserLocalStorage,
  logOutAndCleanUp,
  setUserLocalStorage,
} from '../../utils/models/User';

import AccountDropdown from './AccountDropdown';
import Avatar from '../Widgets/People/Avatar';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { LOCALES_DISPLAY_LIST } from '../../locale/messages';
import ModalActivityEditorButton from '../Activities/ModalActivityEditorButton';
import ModalFeedbackEditorButton from '../Feedback/ModalFeedbackEditorButton';
import ObjectCard from '../Widgets/Cards/ObjectCard';
import {
  AuthUser,
  Features,
  Me,
  Organization,
  ReduxState,
  RouteConfig,
} from 'types';
import TasksNotificationsIndicator from './TasksNotificationsIndicator';
import UncontrolledHoverDropdown from '../Widgets/Dropdowns/UncontrolledHoverDropdown';
import ValidatedAutosuggest from '../Widgets/Inputs/ValidatedAutosuggest';
import { atLeastOneContinuousFeedbackFeatureIsEnabled } from '../../utils/util/features';
import config from '../../utils/util/config';
import { connect } from 'react-redux';
import { getQueryForHeader } from '../Widgets/Inputs/ValidatedInput';
import logoWithName from '../../assets/img/brand/logo.png';
import { orgIsActiveOrDemo } from '../../utils/models/Organization';
import { truncateString } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';

const INTERCOM_APP_ID = 'sojex2od';

type HeaderProps = {
  authUser?: AuthUser;
  className?: string;
  currentOrganization?: Organization;
  errorMessage?: string | ReactNode;
  features: Features;
  isInPerformanceCycleFlow: boolean;
  isOnWelcomePageOrUnderMaintenance: boolean;
  isOrgSystemAdmin: boolean;
  isSuperAdmin: boolean;
  me?: Me;
  onLogout?: () => void;
  organizations?: Organization[];
  setIsSuperAdmin: (a: boolean) => void;
  showIfEnabled: (a: string, b: RouteConfig) => RouteConfig[];
  suggestions?: { [key: string]: any }[];
  underlyingAuthMe?: Me;
};

const Header: FC<HeaderProps> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const showIfEnabled = props.showIfEnabled;
  const isInPerformanceCycleFlow = props.isInPerformanceCycleFlow;
  const isOnWelcomePageOrUnderMaintenance =
    props.isOnWelcomePageOrUnderMaintenance;
  const isSuperAdmin = props.isSuperAdmin;
  const setIsSuperAdmin = props.setIsSuperAdmin;
  const isOrgSystemAdmin = props.isOrgSystemAdmin;

  const [showSupport, setShowSupport] = useState(false);
  const [showCreateActivityDialog, setShowCreateActivityDialog] =
    useState(false);

  const [intercomHMAC, setIntercomHMAC] = useState(undefined);

  const toggleCreateActivityDialog = useCallback(() => {
    setShowCreateActivityDialog(!showCreateActivityDialog);
  }, [showCreateActivityDialog]);
  const [showCreateFeedbackDialog, setShowCreateFeedbackDialog] =
    useState(false);
  const toggleCreateFeedbackDialog = useCallback(() => {
    setShowCreateFeedbackDialog(!showCreateFeedbackDialog);
  }, [showCreateFeedbackDialog]);

  // NOTE: this is a hack due to Dropdown active state not working properly
  // when ObjectCards are used instead of text as children of dropdown items
  const [addActivityHovered, setAddActivityHovered] = useState(false);
  const [addFeedbackHovered, setAddFeedbackHovered] = useState(false);

  const { logout } = useAuth0();

  const toggleSupport = useCallback(() => {
    if (!showSupport) {
      IntercomAPI('show');
    }

    setShowSupport(!showSupport);
  }, [showSupport]);

  useEffect(() => {
    if (props.authUser) {
      setIsSuperAdmin(getUserIsSuperAdmin(props.authUser));

      // hide intercom widget when closed
      IntercomAPI('onHide', () => {
        setShowSupport(false);
      });
    }
  }, [setIsSuperAdmin, props.authUser]);

  const me = props.me;
  const underlyingAuthMe = props.underlyingAuthMe;

  // if user is admin or they are part of more than one account with either
  // demo or active status, show the org dropdown
  const hasMultipleActiveOrDemoCompanies = useMemo(
    () =>
      isSuperAdmin ||
      // @ts-expect-error
      props.organizations?.filter(orgIsActiveOrDemo)?.length > 1,
    [isSuperAdmin, props.organizations]
  );

  // @ts-expect-error
  const isProduction = config.isProduction();

  const preferredIntercomLocale = useMemo(() => {
    if (!props.me?.locale) {
      return undefined;
    }

    const localeDict = LOCALES_DISPLAY_LIST.find(
      (l) => l.id === props.me?.locale
    );

    return localeDict?.intercomLocale;
  }, [props.me?.locale]);

  const intercom = useMemo(() => {
    // This useMemo "seals" the logic to instantiate Intercom in a single
    // place with all the needed configuration setup (i.e. user hmac hash).

    if (!isProduction) {
      // Avoid instantiating Intercom in non-production environments, local
      // and UAT have collisions on UserIDs with prod thus it's safer to only
      // use Intercom in production.
      return null;
    }

    // At page load we initially look for an HMAC in localstorage or fetch it from the API.
    if (typeof intercomHMAC === 'undefined') {
      if (!props.authUser) {
        // We should have a guarantee here that the user is authenticated
        // unless hearder is included from an "off auth" page like
        // RipplingAppIntegration.
        // @ts-expect-error
        setIntercomHMAC(null);
        return null;
      }

      const cached_intercom_hmac = getUserLocalStorage(
        props.authUser.sub,
        null,
        props.currentOrganization?.id,
        consts.INTERCOM_HMAC_CACHE_KEY
      );

      if (cached_intercom_hmac) {
        setIntercomHMAC(cached_intercom_hmac);
      } else {
        ConfirmAPI.sendRequestToConfirm('GET', 'intercom-hmac', {}, (data) => {
          if (data) {
            setUserLocalStorage(
              // @ts-expect-error
              props.authUser.sub,
              null,
              props.currentOrganization?.id,
              consts.INTERCOM_HMAC_CACHE_KEY,
              data.intercom_hmac
            );
            setIntercomHMAC(data.intercom_hmac);
          }
        });
      }
    }

    if (!underlyingAuthMe?.email) {
      // Handle the error case where someone hasn't authenticated yet with us
      // successfully but got a 500, e.g. on init-app.
      return (
        <Intercom
          hide_default_launcher={!showSupport}
          appID={INTERCOM_APP_ID}
        />
      );
    }

    // We need to avoid instantiating Intercom unless we either have an HMAC
    // or we are sure we won't get one, it seems Intercom is instantiated only once
    // even if a re-render would trigger different props to the component.
    if (typeof intercomHMAC !== 'undefined') {
      // hmac has been loaded but could be null.

      if (!intercomHMAC) {
        // User is authenticated but we don't have the HMAC.
        console.error(
          'Intercom HMAC is missing for user ' +
            underlyingAuthMe?.email +
            ' (ID: ' +
            underlyingAuthMe?.user_id +
            ')'
        );
        return (
          <Intercom
            hide_default_launcher={!showSupport}
            appID={INTERCOM_APP_ID}
            user_id={underlyingAuthMe?.user_id}
            email={underlyingAuthMe?.email}
            name={underlyingAuthMe?.full_name}
          />
        );
      }

      // User is authenticated and we have the HMAC.
      return (
        <Intercom
          hide_default_launcher={!showSupport}
          appID={INTERCOM_APP_ID}
          user_id={underlyingAuthMe?.user_id}
          email={underlyingAuthMe?.email}
          name={underlyingAuthMe?.full_name}
          user_hash={intercomHMAC}
          language_override={preferredIntercomLocale}
        />
      );
    }

    return null;
  }, [
    intercomHMAC,
    isProduction,
    props.authUser,
    props.currentOrganization?.id,
    showSupport,
    underlyingAuthMe?.email,
    underlyingAuthMe?.full_name,
    underlyingAuthMe?.user_id,
    preferredIntercomLocale,
  ]);

  const anyContinuousFeedbackisEnabled = useMemo(
    () => atLeastOneContinuousFeedbackFeatureIsEnabled(props.features),
    [props.features]
  );

  const profileDropdownItems = useMemo(
    () => [
      ...showIfEnabled('resumes', consts.MY_PROFILE(formatMessage)),
      ...showIfEnabled('objectives', consts.MY_OBJECTIVES(formatMessage)),
      ...showIfEnabled('goals', consts.MY_ASPIRATIONS(formatMessage)),
      ...(anyContinuousFeedbackisEnabled
        ? [consts.MY_FEEDBACK(formatMessage)]
        : []),
      ...showIfEnabled('performance', consts.MY_PERFORMANCE(formatMessage)),
    ],
    [anyContinuousFeedbackisEnabled, showIfEnabled, formatMessage]
  );

  const getProfileDropdownItemLabel = (item) => {
    if (item.path === consts.MY_PROFILE(formatMessage).path) {
      return (
        <FormattedMessage
          id="app.views.layout.header.me.dropdown.my_profile"
          defaultMessage="My profile"
          description="Dropdown menu item under user's Avatar leading to the user's own profile page"
        />
      );
    } else if (item.path === consts.MY_OBJECTIVES(formatMessage).path) {
      return (
        <FormattedMessage
          id="app.views.layout.header.me.dropdown.my_objectives"
          defaultMessage="My objectives"
          description="Dropdown menu item under user's Avatar leading to the user's objectives page"
        />
      );
    } else if (item.path === consts.MY_ASPIRATIONS(formatMessage).path) {
      return (
        <FormattedMessage
          id="app.views.layout.header.me.dropdown.my_aspirations"
          defaultMessage="My aspirations"
          description="Dropdown menu item under user's Avatar leading to the user's aspirations page"
        />
      );
    } else if (item.path === consts.MY_FEEDBACK(formatMessage).path) {
      return (
        <FormattedMessage
          id="app.views.layout.header.me.dropdown.my_feedback"
          defaultMessage="My feedback"
          description="Dropdown menu item under user's Avatar leading to the user's feedback page"
        />
      );
    } else if (item.path === consts.MY_PERFORMANCE(formatMessage).path) {
      return (
        <FormattedMessage
          id="app.views.layout.header.me.dropdown.my_performance"
          defaultMessage="My performance"
          description="Dropdown menu item under user's Avatar leading to the user's performance page"
        />
      );
    }
  };

  return (
    <Navbar container={false} expand="lg" className="navbar-light">
      <div
        className={
          props.errorMessage ||
          isInPerformanceCycleFlow ||
          isOnWelcomePageOrUnderMaintenance
            ? 'container'
            : 'container-fluid'
        }
      >
        {(props.errorMessage ||
          isInPerformanceCycleFlow ||
          isOnWelcomePageOrUnderMaintenance) && (
          <div className="d-none d-lg-block order-0 pe-sm-3 nav-item">
            <NavbarBrand
              tag={props.errorMessage ? undefined : Link}
              to={props.errorMessage ? undefined : '/'}
              role={props.errorMessage ? 'button' : undefined}
              onClick={
                props.errorMessage ? () => window.location.reload() : undefined
              }
              className="nav-link py-0 px-0"
            >
              <img
                style={{ maxHeight: '1.6rem' }}
                src={logoWithName}
                alt="Confirm Logo"
              />
            </NavbarBrand>
          </div>
        )}
        {!isInPerformanceCycleFlow &&
          !isOnWelcomePageOrUnderMaintenance &&
          !props.errorMessage &&
          props.currentOrganization?.id && (
            <>
              <div className="navbar-search">
                <ValidatedAutosuggest
                  linked
                  size="sm"
                  className="w-100"
                  iconClassName="fe fe-search"
                  placeholder={intl.formatMessage({
                    id: 'app.views.layout.header.q.placeholder',
                    defaultMessage: 'Search',
                    description:
                      'Placeholder text for the search box at the top of every page',
                  })}
                  name="q"
                  value={new URLSearchParams(location.search).get('q')}
                  suggestions={props.suggestions}
                  clearInputOnSelect={true}
                  dropdownMenuClass={'dropdown-menu-card'}
                  elasticsearchOptions={{
                    url: 'get-all-by-name',
                    getQuery: (q) => getQueryForHeader(q, props.features),
                  }}
                />
              </div>
              <ModalActivityEditorButton
                anchorTrigger="create-activity"
                isOpen={showCreateActivityDialog}
                toggle={toggleCreateActivityDialog}
                onClosed={() => setShowCreateActivityDialog(false)}
                hideButton={true}
              />
              {anyContinuousFeedbackisEnabled && (
                <ModalFeedbackEditorButton
                  anchorTrigger="create-feedback"
                  isOpen={showCreateFeedbackDialog}
                  toggle={toggleCreateFeedbackDialog}
                  onClosed={() => setShowCreateFeedbackDialog(false)}
                  hideButton={true}
                />
              )}
              {(props.features.resumes?.enabled ||
                anyContinuousFeedbackisEnabled) && (
                <div className="me-auto">
                  {/* @ts-expect-error */}
                  <UncontrolledHoverDropdown className="ms-3">
                    <DropdownToggle
                      role="button"
                      style={{
                        borderRadius: 100,
                      }}
                      className="btn bg-primary-soft text-primary border-0 px-3"
                    >
                      <i className="fe fe-plus me-0 me-md-1 small ps-md-1"></i>
                      <span className="d-none d-md-inline pe-2">
                        <FormattedMessage
                          id="app.views.layout.header.add"
                          defaultMessage="Add"
                          description="Button in global page header to add a new activity or feedback"
                        />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-card">
                      {anyContinuousFeedbackisEnabled && (
                        <div>
                          <DropdownItem
                            // NOTE: this is a hack due to Dropdown active state not working properly
                            // when ObjectCards are used instead of text as children of dropdown items
                            onMouseEnter={() => {
                              setAddActivityHovered(false);
                              setAddFeedbackHovered(true);
                            }}
                            onMouseLeave={() => setAddFeedbackHovered(false)}
                            active={addFeedbackHovered}
                            onClick={() => {
                              setShowCreateFeedbackDialog(true);
                              setAddFeedbackHovered(false);
                            }}
                          >
                            <ObjectCard
                              icon="fe fe-message-square"
                              name={
                                <FormattedMessage
                                  id="app.views.layout.header.add.feedback"
                                  defaultMessage="Add feedback or recognition"
                                  description="Name of dropdown option to add feedback or recognition"
                                />
                              }
                              description={
                                <FormattedMessage
                                  id="app.views.layout.header.add.feedback.description"
                                  defaultMessage="Feedback, recognition, or private notes"
                                  description="Description of dropdown option to add feedback or recognition"
                                />
                              }
                              inline={true}
                              bodyOnly={true}
                              inDropdown={true}
                            />
                          </DropdownItem>
                        </div>
                      )}
                      {props.features.resumes?.enabled && (
                        <>
                          <DropdownItem
                            // NOTE: this is a hack due to Dropdown active state not working properly
                            // when ObjectCards are used instead of text as children of dropdown items
                            onMouseEnter={() => {
                              setAddFeedbackHovered(false);
                              setAddActivityHovered(true);
                            }}
                            onMouseLeave={() => setAddActivityHovered(false)}
                            active={addActivityHovered}
                            className="border-top"
                            onClick={() => {
                              setShowCreateActivityDialog(true);
                              setAddActivityHovered(false);
                            }}
                          >
                            <ObjectCard
                              icon={consts.ICONS.ACTIVITY}
                              name={
                                <FormattedMessage
                                  id="app.views.layout.header.add.resume"
                                  defaultMessage="Add to resume"
                                  description="Name of dropdown option to add an activity to your resume"
                                />
                              }
                              description={
                                <FormattedMessage
                                  id="app.views.layout.header.add.resume.description"
                                  defaultMessage="Project, accomplishment, learning, or metrics"
                                  description="Description of dropdown option to add an activity to your resume"
                                />
                              }
                              inline={true}
                              bodyOnly={true}
                              inDropdown={true}
                            />
                          </DropdownItem>
                        </>
                      )}
                    </DropdownMenu>
                  </UncontrolledHoverDropdown>
                </div>
              )}
            </>
          )}
        {isInPerformanceCycleFlow && (
          <Nav navbar className="ms-auto px-4">
            <Link to="/" role="button" className="text-muted">
              <i className="fe fe-log-out" />
              &nbsp;
              <FormattedMessage
                id="app.views.layout.header.exit_cycle"
                defaultMessage="Exit cycle"
                description="Button label, user clicks to leave performance cycle UX"
              />
            </Link>
          </Nav>
        )}
        {me && underlyingAuthMe && (
          <div className="navbar-user">
            {intercom}
            {!props.errorMessage &&
              !isInPerformanceCycleFlow &&
              !isOnWelcomePageOrUnderMaintenance && (
                <Nav navbar className="px-3 d-none d-lg-block">
                  <NavItem>
                    {profileDropdownItems?.length > 1 && (
                      // @ts-expect-error
                      <UncontrolledHoverDropdown>
                        <DropdownToggle tag="div" className="nav-link p-0">
                          <Avatar person={me} hidePopover size="sm" />
                          <span
                            className="d-inline-block nav-link"
                            style={{ position: 'relative', top: '1px' }}
                          >
                            {truncateString(me.given_name, 20)}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="bg-white">
                          {profileDropdownItems.map((x, index) => (
                            <DropdownItem className="p-0" key={index}>
                              <NavLink
                                className="d-block dropdown-item"
                                exact={true}
                                to={x.path}
                              >
                                {getProfileDropdownItemLabel(x)}
                              </NavLink>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledHoverDropdown>
                    )}
                    {profileDropdownItems?.length === 1 && (
                      <NavLink exact={true} to={profileDropdownItems[0].path}>
                        <Avatar person={me} hidePopover size="sm" />
                        <span
                          className="d-inline-block nav-link"
                          style={{ position: 'relative', top: '1px' }}
                        >
                          {truncateString(me.given_name, 20)}
                        </span>
                      </NavLink>
                    )}
                  </NavItem>
                </Nav>
              )}
            {!props.errorMessage &&
              !isInPerformanceCycleFlow &&
              !isOnWelcomePageOrUnderMaintenance && (
                <TasksNotificationsIndicator />
              )}
            <AccountDropdown
              isInFocusedMode={
                isInPerformanceCycleFlow || isOnWelcomePageOrUnderMaintenance
              }
              hasMultipleActiveOrDemoCompanies={
                hasMultipleActiveOrDemoCompanies
              }
              isSuperAdmin={isSuperAdmin}
              isOrgSystemAdmin={isOrgSystemAdmin}
              onLogout={props.onLogout}
              onSupport={toggleSupport}
              errorMessage={props.errorMessage ? 'error' : ''}
              className="pe-0"
            />
          </div>
        )}
        {props.errorMessage && !props.me && (
          <>
            {intercom}
            <div>
              <Button color="link" onClick={toggleSupport}>
                <FormattedMessage
                  id="app.views.layout.header.no_user_data.get_help"
                  defaultMessage="Get help"
                  description="User not found in system, button label to get help"
                />
              </Button>
              <Button color="link" onClick={() => logOutAndCleanUp(logout)}>
                <FormattedMessage
                  id="app.views.layout.header.no_user_data.log_out"
                  defaultMessage="Log out"
                  description="User not found in system, button label to log out"
                />
              </Button>
            </div>
          </>
        )}
      </div>
    </Navbar>
  );
};

// These props come from the application's
// state when it is started
const mapStateToProps = (state: ReduxState) => {
  const {
    features,
    authUser,
    me,
    // @ts-expect-error
    underlyingAuthMe,
    currentOrganization,
    // @ts-expect-error
    organizations,
  } = state;

  return {
    features,
    authUser,
    me,
    underlyingAuthMe,
    currentOrganization,
    organizations,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(Header));
