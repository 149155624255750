import * as consts from 'consts/consts';

import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import { Button } from 'reactstrap';
import SimplePage from '../SimplePage';
import { logOutAndCleanUp } from 'utils/models/User';
import { useAuth0 } from '@auth0/auth0-react';

const PageChurnedOrganization: FC = () => {
  const { formatMessage } = useIntl();

  const { logout } = useAuth0();

  return (
    <SimplePage
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page_churned_organization.title.your_organization_no_longer_has',
        defaultMessage: 'Your organization no longer has access',
      })}
    >
      <p>
        <FormattedMessage
          id="app.views.layout.pages.errors.page_churned_organization.no_longer_access"
          defaultMessage="

        Your organization no longer has access to Confirm. Please contact your
        internal team to find out more information about this change. If you
        meant to log in with a different email address, <link>log out</link> and log back in with the correct email address."
          values={{
            link: (chunks) => (
              <span
                className="btn-link"
                role="button"
                onClick={() => logOutAndCleanUp(logout)}
              >
                {chunks}
              </span>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="app.views.layout.pages.errors.page_churned_organization.request_demo_description"
          defaultMessage="
        If you are not a current customer but would like a demo of our platform:
      "
        />
      </p>
      <div className="mb-4">
        <a href={consts.CONFIRM_DEMO_FULL_PATH}>
          <Button color="primary">
            <FormattedMessage
              id="app.views.layout.pages.errors.page_churned_organization.request_demo_button"
              defaultMessage="Request a demo"
            />
          </Button>
        </a>
      </div>
    </SimplePage>
  );
};

// all tracking in app will be passed through here
export default React.memo(PageChurnedOrganization);
