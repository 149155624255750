import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import { Person } from 'types';
import { getExternalSiteProfileButton } from '../../../utils/models/Person';

interface Props {
  person: Person;
}

const PersonEnrichedProfileCard: FC<Props> = (props) => {
  // @ts-expect-error
  const profile = props.person.enriched_profile;
  if (!profile) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col className="mt-1">
          {getExternalSiteProfileButton(profile, 'linkedin')}
          {getExternalSiteProfileButton(profile, 'angellist')}
          {getExternalSiteProfileButton(profile, 'twitter')}
          {getExternalSiteProfileButton(profile, 'github')}
          {getExternalSiteProfileButton(profile, 'stackoverflow')}
          {getExternalSiteProfileButton(profile, 'quora')}
        </Col>
      </Row>
    </>
  );
};

export default React.memo(PersonEnrichedProfileCard);
