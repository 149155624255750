import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import React, { FC, useState } from 'react';

import { CONTACT_METHOD_OPTIONS } from '../../../utils/models/ContactMethods';
import { FormattedMessage } from 'react-intl';
import ObjectsDropdown from '../Dropdowns/ObjectsDropdown';
import PropTypes from 'prop-types';

const ContactMethodEditor: FC<Props> = (props) => {
  const [contactMethods, setContactMethods] = useState(
    props.value ? props.value : []
  );

  const updateContactMethods = (newMethods) => {
    setContactMethods(newMethods);
    props.onChange(newMethods);
  };

  const onChangeMethodMedium = (index, mediumId) => {
    updateContactMethods(
      contactMethods.map((c, i) =>
        index === i
          ? {
              ...c,
              id: mediumId,
            }
          : c
      )
    );
  };

  const onChangeMethodValue = (index, value) => {
    updateContactMethods(
      contactMethods.map((c, i) =>
        index === i
          ? {
              ...c,
              value: value,
            }
          : c
      )
    );
  };

  const addContactMethod = () => {
    updateContactMethods([...contactMethods, {}]);
  };

  const removeMethod = (index) => {
    updateContactMethods(contactMethods.filter((item, i) => i !== index));
  };

  return (
    <div>
      {contactMethods.map((c, index) => {
        const onChangeMedium = (event) => {
          onChangeMethodMedium(index, event.target.value);
        };

        const mediumOptionIndex = CONTACT_METHOD_OPTIONS.findIndex(
          // @ts-expect-error
          (o) => o?.id === c?.id
        );

        const mediumOption =
          mediumOptionIndex === -1
            ? null
            : CONTACT_METHOD_OPTIONS[mediumOptionIndex];

        const onInputChange = (event) => {
          onChangeMethodValue(index, event.target.value);
        };

        return (
          <InputGroup key={index} className="mb-3">
            <ObjectsDropdown
              className="input-group-prepend"
              objects={CONTACT_METHOD_OPTIONS}
              onChange={onChangeMedium}
              value={mediumOption?.id ? mediumOption.id : undefined}
            />
            <Input
              // @ts-expect-error
              value={c?.value ? c.value : ''}
              style={{ minHeight: '41.5px' }}
              onChange={onInputChange}
            />
            <div role="button" onClick={() => removeMethod(index)}>
              <span
                style={{
                  borderLeft: 'none',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  height: '2.65rem',
                }}
                className="input-group-text"
              >
                {'×'}
              </span>
            </div>
          </InputGroup>
        );
      })}
      <Row>
        <Col>
          <Button color="light" onClick={addContactMethod}>
            <FormattedMessage
              id="app.views.widgets.inputs.contact_method_editor.add_method"
              defaultMessage="Add Method"
            />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const ContactMethodEditor_propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof ContactMethodEditor_propTypes>;

export default React.memo(ContactMethodEditor);
