import React, { FC } from 'react';

import { Doughnut } from 'react-chartjs-2';

interface Props {
  denominator: number;
  numerator: number;
  height: number;
  width: number;
}

const options = {
  tooltips: false,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  events: [],
  cutoutPercentage: 70,
  animation: {
    duration: 400,
  },
};

const CompletionDoughnut: FC<Props> = ({
  denominator,
  numerator,
  height,
  width,
}) => {
  let percentage;
  let data;
  if (denominator === 0 && numerator === 0) {
    percentage = 0;
    data = [numerator, denominator - numerator];
  } else if (denominator === 0 && numerator !== 0) {
    percentage = 100;
    data = [100, 0];
  } else {
    percentage = Number((numerator / denominator) * 100).toFixed(0);
    data = [numerator, denominator - numerator];
  }

  return (
    <div>
      <Doughnut
        key={`doughnut-${percentage}`}
        options={options}
        height={height}
        width={width}
        plugins={[
          {
            beforeDraw: function (chart) {
              const width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;
              ctx.restore();
              const fontSize = Number((height / 60).toFixed(2));
              ctx.font = fontSize + 'em sans-serif';
              ctx.textBaseline = 'top';
              const text = percentage + '%';
              const textX = Math.round(
                (width - ctx.measureText(text).width) / 2
              );
              const textY = height / 2 - fontSize * 5;
              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ]}
        data={{
          datasets: [
            {
              data: data,
              backgroundColor: ['#00d97e', '#E3EBF6'],
            },
          ],
        }}
      />
    </div>
  );
};

export default CompletionDoughnut;
