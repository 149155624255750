import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import React, { FC } from 'react';
import {
  getPerformancePhasesDisplayObjects,
  getPhaseDisplayDescription,
  getPhaseDisplayName,
  getPhaseIsManagersOnly,
  getPhasePrefix,
} from '../../utils/models/Performance';

import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { getPrettyDateRange } from '../../utils/util/util';
import { withRouter } from 'react-router';
import { type RouteComponentProps } from 'react-router-dom';

const PerformancePhases: FC<Props & RouteComponentProps> = (props) => {
  const { locale, formatMessage } = useIntl();
  const campaign = props.campaign;

  // @ts-expect-error
  const phases = getPerformancePhasesDisplayObjects(campaign.phases);

  return (
    <ListGroup className="list-group-flush list-group-activity my-4">
      {phases.map((p, index) => (
        <ListGroupItem key={index}>
          <Row>
            <Col className="col-auto">
              <div className="avatar avatar-sm">
                {props.stepsComplete > index && (
                  <div className="avatar-title fs-lg bg-success rounded-circle text-white">
                    <span className="fe fe-check"></span>
                  </div>
                )}
                {props.stepsComplete <= index && (
                  <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    {phases.length > 1 && index + 1}
                    {phases.length === 1 && <i className="fe fe-user" />}
                  </div>
                )}
              </div>
            </Col>
            <Col className="ms-n2">
              <h4 className="mb-0 mt-1">
                {phases.length > 1
                  ? getPhasePrefix(index, formatMessage) + ': '
                  : formatMessage({
                      id: 'app.views.performance.performance_phases.quick_survey',
                      defaultMessage: 'Quick survey: ',
                    })}{' '}
                {getPhaseDisplayName(
                  // @ts-expect-error
                  campaign,
                  p,
                  // @ts-expect-error
                  props.currentOrganization?.name
                )}
                {getPhaseIsManagersOnly(p) && (
                  <>
                    {' '}
                    <span className="badge bg-primary-soft rounded-pill ms-2 my-n2">
                      <FormattedMessage
                        id="app.views.performance.performance_phases.people_managers_only"
                        defaultMessage="People managers only"
                      />
                    </span>
                  </>
                )}
              </h4>
              <div className="small mb-1">
                {/* @ts-expect-error */}
                {campaign.phases?.length > 0 &&
                  getPrettyDateRange(
                    // @ts-expect-error
                    campaign.phases[index]?.start_date,
                    // @ts-expect-error
                    campaign.phases[index]?.end_date,
                    true,
                    locale
                  )}
              </div>
              <p className="small text-muted mb-0">
                {getPhaseDisplayDescription(
                  // @ts-expect-error
                  campaign,
                  p,
                  // @ts-expect-error
                  props.currentOrganization?.name
                )}
              </p>
            </Col>
          </Row>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

const PerformancePhases_propTypes = {
  campaign: PropTypes.object.isRequired,
  stepsComplete: PropTypes.number.isRequired,
  currentOrganization: PropTypes.object.isRequired,
};

type Props = InferProps<typeof PerformancePhases_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization } = state;

  return {
    currentOrganization,
  };
};

export default connect(mapStateToProps)(
  withRouter(React.memo(PerformancePhases))
);
