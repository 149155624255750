// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins/file.min.css';
import 'froala-editor/css/plugins/image.min.css';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/css/plugins/video.min.css';
// Import Froala plugins
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';

import * as consts from '../../../consts/consts';

import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// max height before showing indicator to show more and collapsing the content
const MAX_DESCRIPTION_HEIGHT = 150;

interface RichTextViewerProps {
  model?: string;
  expanded?: boolean;
  beforeContent?: ReactNode;
  afterContent?: ReactNode;
}

const RichTextViewer: React.FC<RichTextViewerProps> = (props) => {
  const [shouldShowFullContent, setShouldShowFullContent] = useState<boolean>(
    props.expanded || false
  );
  const descriptionRef = useRef<HTMLDivElement>(null);

  const showFullContent = () => {
    setShouldShowFullContent(true);
  };

  useEffect(() => {
    setShouldShowFullContent(
      props.expanded || false
        ? true
        : descriptionRef?.current
        ? descriptionRef.current.clientHeight <= MAX_DESCRIPTION_HEIGHT
        : false
    );
  }, [descriptionRef, props.expanded]);

  return (
    <div
      ref={descriptionRef}
      style={
        shouldShowFullContent
          ? {}
          : {
              overflowY: 'hidden',
              maxHeight: `${MAX_DESCRIPTION_HEIGHT + 50}px`,
            }
      }
    >
      {!shouldShowFullContent && (
        <div className="collapse-content-overlay">
          <Button color="link" className="btn-link" onClick={showFullContent}>
            <FormattedMessage
              id="app.views.widgets.inputs.rich_text_viewer.view_more"
              defaultMessage="View more"
            />
          </Button>
        </div>
      )}
      {props.beforeContent}
      <FroalaEditorView
        tag="span" // ensure any "after content" shows on the same line
        config={{
          ...consts.FROALA_CONFIG_DEFAULTS,
          key: process.env.REACT_APP_FROALA_ACTIVATION_KEY,
        }}
        model={props.model}
      />
      {props.afterContent}
    </div>
  );
};

export default React.memo(RichTextViewer);
