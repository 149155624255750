import {
  AvailableMetricConfig,
  TalentMatrixAxisSettings,
  TalentMatrixCampaignModel,
  TalentMatrixGridCellProps,
  calculateBuckets,
  calculateMaxBucketCount,
  nearestNextSegmentStart,
} from '../TalentMatrix';
import { Button, Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';

import AxisEditor from './AxisEditor';
import Legends from './Legends';
import MatrixEditor from './MatrixEditor';

interface Props {
  availableMetrics: AvailableMetricConfig[];
  onCallback: (value: TalentMatrixCampaignModel) => void;
  value: TalentMatrixCampaignModel;
}

const Editor: FC<Props> = ({ availableMetrics, onCallback, value }) => {
  const { formatMessage } = useIntl();
  const [columns, setColumns] = useState<number>(value.columns);
  const [rows, setRows] = useState<number>(value.rows);
  const [maxColumns, setMaxColumns] = useState<number>(() =>
    calculateMaxBucketCount(value.x_axis_metric, availableMetrics)
  );
  const [maxRows, setMaxRows] = useState<number>(() =>
    calculateMaxBucketCount(value.y_axis_metric, availableMetrics)
  );
  const [quadrants, setQuadrants] = useState<TalentMatrixGridCellProps[]>(
    () => value.quadrants
  );

  const [axisSettings, setAxisSettings] = useState<TalentMatrixAxisSettings>(
    () => ({
      ...value,
    })
  );

  const forceRowValueAndMax = useCallback((rows: number, maxRows: number) => {
    setRows(rows);
    setMaxRows(maxRows);
  }, []);

  const forceColumnValueAndMax = useCallback(
    (columns: number, maxColumns: number) => {
      setColumns(columns);
      setMaxColumns(maxColumns);
    },
    []
  );

  const handleAddRow = useCallback(() => {
    setRows(rows + 1);
    setQuadrants((prev) => [
      ...prev,
      ...Array.from(Array(columns).keys()).map((x) => ({
        title: formatMessage({
          id: 'app.views.talent_matrix.talent_matrix_grid_editor.metrics.new_quadrant',
          defaultMessage: 'New quadrant',
        }),
        x: x,
        y: rows,
      })),
    ]);
    setAxisSettings((prev) => ({
      ...prev,
      y_axis_buckets: calculateBuckets(
        availableMetrics.find((it) => it.id === prev.y_axis_metric)!
          .possible_values,
        rows + 1,
        prev.y_axis_order_reversed
      ),
    }));
  }, [columns, rows, availableMetrics, formatMessage]);

  const handleRemoveRow = useCallback(() => {
    setRows(rows - 1);
    setQuadrants((prev) => prev.filter((it) => it.y !== rows - 1));
    setAxisSettings((prev) => ({
      ...prev,
      y_axis_buckets: calculateBuckets(
        availableMetrics.find((it) => it.id === prev.y_axis_metric)!
          .possible_values,
        rows - 1,
        prev.y_axis_order_reversed
      ),
    }));
  }, [rows, availableMetrics]);

  const handleAddCloumn = useCallback(() => {
    setColumns(columns + 1);
    setQuadrants((prev) => [
      ...prev,
      ...Array.from(Array(rows).keys()).map((y) => ({
        title: formatMessage({
          id: 'app.views.talent_matrix.talent_matrix_grid_editor.metrics.new_quadrant',
          defaultMessage: 'New quadrant',
        }),
        x: columns,
        y: y,
      })),
    ]);
    setAxisSettings((prev) => ({
      ...prev,
      x_axis_buckets: calculateBuckets(
        availableMetrics.find((it) => it.id === prev.x_axis_metric)!
          .possible_values,
        columns + 1,
        prev.x_axis_order_reversed
      ),
    }));
  }, [columns, rows, formatMessage, availableMetrics]);

  const handleRemoveColumn = useCallback(() => {
    setColumns(columns - 1);
    setQuadrants((prev) => prev.filter((it) => it.x !== columns - 1));
    setAxisSettings((prev) => ({
      ...prev,
      x_axis_buckets: calculateBuckets(
        availableMetrics.find((it) => it.id === prev.x_axis_metric)!
          .possible_values,
        columns - 1,
        prev.x_axis_order_reversed
      ),
    }));
  }, [columns, availableMetrics]);

  const onControlsChange = useCallback((value: TalentMatrixAxisSettings) => {
    setAxisSettings(value);
  }, []);

  const handleOnCellChange = useCallback(
    (x, y) => (event: ChangeEvent<HTMLInputElement>) => {
      setQuadrants((prev) => {
        return prev.map((it) =>
          it.x === x && it.y === y ? { ...it, title: event.target.value } : it
        );
      });
    },
    []
  );

  const handleChangeAxisBuckets = useCallback(
    (fieldName, reversedOrderField, indexToChange) => (event) => {
      setAxisSettings((controlsObject) => {
        const value = event.target.value;
        const isAcceptedNumber = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (!isAcceptedNumber) return controlsObject;
        const isReversed = controlsObject[reversedOrderField];
        return {
          ...controlsObject,
          [fieldName]: controlsObject?.[fieldName]?.map(
            ([left, right], idx: number) => {
              if (idx === indexToChange) return [value, right];
              // shift the right boundary on the left side of the changed bucket
              if (idx === indexToChange - 1)
                return [left, nearestNextSegmentStart(value, isReversed)];
              return [left, right];
            }
          ),
        };
      });
    },
    []
  );

  const handleOnSaveClick = useCallback(() => {
    onCallback({
      ...axisSettings,
      rows,
      columns,
      quadrants,
    });
  }, [columns, axisSettings, onCallback, quadrants, rows]);

  return (
    <>
      <h2 className="ps-2 pt-3">
        <FormattedMessage
          id="app.views.talent_matrix.talent_matrix_grid_editor.metrics"
          defaultMessage="Metrics"
        />
      </h2>
      <small className="ps-2 text-muted">
        <FormattedMessage
          id="app.views.talent_matrix.talent_matrix_grid_editor.metrics.sub_text"
          defaultMessage="Define the horizontal (x) and vertical (y) axes of your talent matrix, and how many buckets for each axis you would like displayed."
        />
      </small>
      <Card className="p-3">
        <CardBody>
          <AxisEditor
            availableMetrics={availableMetrics}
            axisSettings={axisSettings}
            columns={columns}
            onControlsChange={onControlsChange}
            rows={rows}
            onResizeColumns={forceColumnValueAndMax}
            onResizeRows={forceRowValueAndMax}
          />
        </CardBody>
      </Card>
      <h2 className="ps-2 pt-2">
        <FormattedMessage
          id="app.views.talent_matrix.editor.editor.matrix"
          defaultMessage="Matrix"
        />
      </h2>
      <small className="ps-2 text-muted">
        <FormattedMessage
          id="app.views.talent_matrix.talent_matrix_grid_editor.matrix.sub_text"
          defaultMessage=" Set descriptions for each box and define what values each box corresponds to. Use the below legend to identify which number corresponds to which response."
        />
      </small>
      <Legends
        xMetric={axisSettings.x_axis_metric}
        yMetric={axisSettings.y_axis_metric}
        availableMetrics={availableMetrics}
      />
      <MatrixEditor
        columns={columns}
        rows={rows}
        maxColumns={maxColumns}
        maxRows={maxRows}
        handleRemoveColumn={handleRemoveColumn}
        handleAddCloumn={handleAddCloumn}
        axisSettings={axisSettings}
        handleChangeAxisBuckets={handleChangeAxisBuckets}
        quadrants={quadrants}
        handleAddRow={handleAddRow}
        handleRemoveRow={handleRemoveRow}
        handleOnCellChange={handleOnCellChange}
      />

      <Button
        color="primary"
        className="w-100 mt-3"
        onClick={handleOnSaveClick}
      >
        <FormattedMessage
          id="app.views.talent_matrix.talent_matrix_grid_editor.save"
          defaultMessage="Save"
        />
      </Button>
    </>
  );
};

export default Editor;
