import * as consts from '../../consts/consts';

import OneOnOnesDirectory from './OneOnOnesDirectory';
import Page from '../Layout/Pages/Page';
import React from 'react';
import { useIntl } from 'react-intl';

const OneOnOnesDirectoryPage: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Page title={consts.TEAM_ONE_ON_ONES(formatMessage).name}>
      <OneOnOnesDirectory />
    </Page>
  );
};

export default React.memo(OneOnOnesDirectoryPage);
