import { Col, Container, Row } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import { Doughnut } from 'react-chartjs-2';
import EmptyState from '../EmptyState';
import { FormattedMessage } from 'react-intl';

// @ts-expect-error
const toPct = (a: number, b: number) => parseInt((100.0 * a) / b + 0.5);

const chartOptions = {
  tooltips: {
    enabled: false,
  },
  cutoutPercentage: 70,
  borderColor: '#FFFFFF',
  borderWidth: 0.1,
  animation: {
    duration: 400,
  },
};

interface Props {
  className?: string;
  count?: number;
  emptyStateText?: string;
  total?: number;
  unit?: string;
}

const CompletionDoughnutChartCard: FC<Props> = ({
  count = 0,
  total = 0,
  ...props
}) => {
  const percent = useMemo(
    () => (!total ? 0 : toPct(count, total)),
    [count, total]
  );

  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          data: [count, total - count],
          backgroundColor: ['#00D97E', '#EDF2F9'], // match "success" and "light" from dashkit theme
        },
      ],
    };
  }, [count, total]);

  return (
    <div className={props.className}>
      {total ? (
        <div className="position-relative" style={{ height: '150px' }}>
          {/* DO NOT REMOVE ENVELOPE DIV OR DOUGHNUT CHART WILL BREAK */}
          <div className="position-absolute w-100 h-100">
            <Doughnut data={chartData} options={chartOptions} />
            <div className="text-center small">
              <FormattedMessage
                id="app.views.widgets.cards.doughnut_chart_card.count_of_unit"
                defaultMessage="{count} of {total} {unit}"
                values={{
                  count: count,
                  total: total,
                  unit: props.unit,
                }}
              />
            </div>
          </div>
          <Container
            fluid={true}
            className="d-flex align-items-center justify-content-center h-100"
          >
            <Row>
              <Col>
                <span className="display-3">
                  {percent}
                  {'%'}
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <EmptyState title={props.emptyStateText} />
      )}
    </div>
  );
};

export default React.memo(CompletionDoughnutChartCard);
