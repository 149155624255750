import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Person } from '../../../types';

interface Props {
  person: Person;
  isExternalUrl?: boolean;
  includeTitle?: boolean;
}

const LinkedPerson: FC<Props> = (props) => {
  return (
    <span className="fw-bold">
      {props.person.url && (
        <Link
          className="text-dark"
          to={props.person.url}
          target={props.isExternalUrl ? '_blank' : undefined}
          rel="noopener noreferrer"
        >
          {props.person.full_name}
        </Link>
      )}
      {!props.person.url && <span>{props.person.full_name}</span>}
      {props.includeTitle && props.person.title && (
        <span className="fw-normal">
          <FormattedMessage
            id="util.list_comma"
            defaultMessage=","
            description="comma between item in lists"
          />{' '}
          {props.person.title}
        </span>
      )}
    </span>
  );
};

export default React.memo(LinkedPerson);
