import React, { FC } from 'react';

import SimplePage from '../Layout/Pages/SimplePage';
import { useIntl } from 'react-intl';

interface Props {
  errorHeading?: string;
  errorMessage?: string | React.ReactNode;
}

const SimpleErrorPage: FC<Props> = ({ errorHeading, errorMessage }) => {
  const { formatMessage } = useIntl();
  return (
    <SimplePage
      title={
        errorHeading ||
        formatMessage({
          id: 'app.views.layout.error.error.default',
          defaultMessage: 'Oops! Something went wrong.',
        })
      }
      titleClassName="text-center"
    >
      <div className="text-center">
        <p>{errorMessage}</p>
      </div>
    </SimplePage>
  );
};

export default SimpleErrorPage;
