// Overall App actions
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_ME = 'SET_ME';
export const UPDATE_ME = 'UPDATE_ME';
export const UPDATE_MY_CONFIGS = 'UPDATE_MY_CONFIGS';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_CURRENT_PROXY_PERSON = 'SET_CURRENT_PROXY_PERSON';
export const LOAD_APP = 'LOAD_APP';
export const RENDER_APP = 'RENDER_APP';

// Tasks
export const LOAD_TASKS = 'LOAD_TASKS';
export const SET_TASKS = 'SET_TASKS';

// Perf campaigns
export const LOAD_CAMPAIGNS_AND_SURVEY_RESPONSES =
  'LOAD_CAMPAIGNS_AND_SURVEY_RESPONSES';
export const SET_CAMPAIGNS_AND_SURVEY_RESPONSES =
  'SET_CAMPAIGNS_AND_SURVEY_RESPONSES';
export const SET_CURRENT_PERF_CAMPAIGN = 'SET_CURRENT_PERF_CAMPAIGN';
export const SET_CURRENT_PERF_SURVEY_RESPONSE =
  'SET_CURRENT_PERF_SURVEY_RESPONSE';
export const SET_CURRENT_DEMO_OR_PREVIEW_PERF_CAMPAIGN =
  'SET_CURRENT_DEMO_OR_PREVIEW_PERF_CAMPAIGN';

// App Integrations
export const LOAD_APP_INTEGRATIONS = 'LOAD_APP_INTEGRATIONS';
export const RENDER_APP_INTEGRATIONS = 'RENDER_APP_INTEGRATIONS';
export const ADD_APP_INTEGRATION = 'ADD_APP_INTEGRATION';
export const UPDATE_APP_INTEGRATION = 'UPDATE_APP_INTEGRATION';

// Invitations
export const LOAD_INVITATION = 'LOAD_INVITATION';
export const CLAIM_INVITATION = 'CLAIM_INVITATION';

// Tasks
export const ACCEPT_TASK = 'ACCEPT_TASK';
export const DECLINE_TASK = 'DECLINE_TASK';

// Contributions
export const IGNORE_CONTRIBUTION = 'IGNORE_CONTRIBUTION';
export const UNIGNORE_CONTRIBUTION = 'UNIGNORE_CONTRIBUTION';
