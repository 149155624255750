import * as consts from '../../consts/consts';

import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, Fragment, useState } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RichTextViewer from './Inputs/RichTextViewer';

const shouldShowColumn = (focusLevel, columnIndex) => {
  // show first column and either all columns, or, if a focus level exists, that
  // column and the columns before and after (for use in level assessments)
  return (
    !focusLevel ||
    columnIndex === focusLevel ||
    columnIndex === focusLevel + 1 ||
    columnIndex === focusLevel - 1
  );
};

const cellHasContent = (cell) => {
  return cell.title || cell.description_html || cell.description;
};

const LevelingSkillsHeaderRow = (props) => {
  const { formatMessage } = useIntl();
  // TODO: this could be management or support
  const careerType = 'exempt';
  const row = props.row;

  return (
    <tr className="skills-matrix-header">
      <th className="skills-matrix-header-cell skills-matrix-header-cell-corner">
        <div className="skills-matrix-header-item">
          {!props.focusLevel && (
            <Link to={consts.LEVELING_FRAMEWORK(formatMessage).path}>
              <span className="fe fe-arrow-left"></span>{' '}
              <FormattedMessage
                id="app.views.widgets.job_family_expectations_matrix.link.back.text"
                defaultMessage="Back"
              />
            </Link>
          )}
        </div>
      </th>
      {row.map((item, itemIndex) => {
        if (!shouldShowColumn(props.focusLevel, itemIndex)) {
          return <Fragment key={itemIndex}></Fragment>;
        }

        return (
          <th key={itemIndex} className="skills-matrix-header-cell">
            <div className="skills-matrix-header-item">
              <span className="badge bg-primary">
                {'L'}
                {itemIndex}
              </span>
              {item.title && <div className="mt-1 mb-n1">{item.title}</div>}
              {item[careerType] && (
                <div className="mt-1 mb-n1">{item[careerType].title}</div>
              )}
              {item.description_html && (
                <span className="small text-muted">
                  <RichTextViewer
                    model={item.description_html}
                    expanded={true}
                  />
                </span>
              )}
              {item.description && (
                <span className="small text-muted">{item.description}</span>
              )}
            </div>
          </th>
        );
      })}
    </tr>
  );
};

const LevelingSkillRow = (props) => {
  const [selectedColumn, setSelectedColumn] = useState(null);

  const row = props.row;
  const rowIndex = props.rowIndex;

  // if all items in row have no title or description, don't show it at all
  if (
    !row.find(
      (item, itemIndex) =>
        itemIndex > 0 &&
        shouldShowColumn(props.focusLevel, itemIndex) &&
        cellHasContent(item)
    )
  ) {
    return <></>;
  }

  return (
    <tr className="skills-matrix-row">
      {row.map((item, itemIndex) => {
        // hide rows that aren't interactable if focus level is set, and hide
        // cells that aren't part of focus if it's set
        if (
          (props.focusLevel && (rowIndex === 1 || rowIndex === 2)) ||
          (itemIndex !== 0 && !shouldShowColumn(props.focusLevel, itemIndex))
        ) {
          return <Fragment key={itemIndex}></Fragment>;
        }

        if (itemIndex === 0) {
          return (
            <th
              key={itemIndex}
              className="skills-matrix-row-cell skills-matrix-row-category"
            >
              <div className="skills-matrix-row-item">
                {item.title && <span>{item.title}</span>}
                {item.description_html && (
                  <span className="mb-0">
                    <RichTextViewer
                      model={item.description_html}
                      expanded={true}
                    />
                  </span>
                )}
                {item.description && (
                  <span className="mb-0">{item.description}</span>
                )}
              </div>
            </th>
          );
        } else {
          return (
            <td
              key={itemIndex}
              onClick={
                props.isInput ? () => setSelectedColumn(itemIndex) : undefined
              }
              className={
                'skills-matrix-row-cell' +
                (selectedColumn === itemIndex ? ' selected-level' : '') +
                (props.isInput && rowIndex > 2 && itemIndex > 0
                  ? ' level-input'
                  : '')
              }
            >
              <div className="skills-matrix-row-item">
                {item.title && <h4>{item.title}</h4>}
                {item.description_html && (
                  <span className="mb-0">
                    <RichTextViewer
                      model={item.description_html}
                      expanded={true}
                    />
                  </span>
                )}
                {item.description && (
                  <span className="mb-0">{item.description}</span>
                )}
              </div>
            </td>
          );
        }
      })}
    </tr>
  );
};

const JobFamilyExpectationsMatrix: FC<Props> = (props) => {
  const levels = props.levels;
  const expectations = props.expectations;

  if (!levels || !expectations) {
    return <></>;
  }

  return (
    <>
      {!props.focusLevel && (
        <style
          // NOTE: we allow using this here only because it's static html,
          // not provided by the user
          dangerouslySetInnerHTML={{
            __html: `
        .app nav.navbar { display: none; }
      `,
          }}
        />
      )}
      <table className="skills-matrix">
        <thead>
          <LevelingSkillsHeaderRow
            isInput={props.isInput}
            row={levels}
            focusLevel={props.focusLevel}
          />
        </thead>
        <tbody>
          {expectations.map((row, rowIndex) => {
            return (
              <LevelingSkillRow
                key={rowIndex}
                isInput={props.isInput}
                row={row}
                rowIndex={rowIndex}
                focusLevel={props.focusLevel}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const JobFamilyExpectationsMatrix_propTypes = {
  levels: PropTypes.arrayOf(PropTypes.object),
  expectations: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  focusLevel: PropTypes.number,
  callback: PropTypes.func,
  isInput: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof JobFamilyExpectationsMatrix_propTypes>;

export default JobFamilyExpectationsMatrix;
