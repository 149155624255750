import { type RedirectLoginOptions } from '@auth0/auth0-react';
import config from '../../utils/util/config';
import { getHasSeenSelectAccountPrompt } from '../models/User';
import { getFriendlyUserFacingErrorObjectAndMessage } from '../../utils/util/util';

export const redirectLoginOptionsGenerator = (
  continueUrl: string, // Assuming continueUrl is a string
  isSignup: boolean = false
): RedirectLoginOptions => {
  const options: RedirectLoginOptions = {
    // TODO: re-enable the below when using the authParams feature (see rest of codebase for its use)
    //...(props.authParams ? props.authParams : {}),
    // if logging in, always ask for account (but if this is a session refresh, don't get in the way)
    prompt: getHasSeenSelectAccountPrompt() ? undefined : 'select_account',
    appState: {
      // go back to this page after logging in
      returnTo: continueUrl,
    },
    // @ts-expect-error
    connection: config.getOauthConnection(),
  };

  if (isSignup && !getHasSeenSelectAccountPrompt()) {
    options['screen_hint'] = 'signup';
  }

  return options;
};

export const scrollTo = (id: string): void => {
  if (id === 'TOP') {
    window.scrollTo(0, 0);
    return;
  }
  // @ts-expect-error FIXME
  document.getElementById(id).scrollIntoView(true);
  window.scrollBy(0, -60);
};

export const standardConfirmAPIsendRequestToConfirmPromiseCallback =
  (
    resolve: (value: any) => Promise<any> | void,
    reject: (error: any) => Promise<any> | void
  ) =>
  (data: any, error: string | object, hardErrorMessage: string | object) => {
    if (error || hardErrorMessage) {
      const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
        error,
        hardErrorMessage
      );
      console.error('Fetching error :', errorObject);
      reject(errorObject);
      return;
    }
    resolve(data);
  };
