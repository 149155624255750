import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';

import AvatarGroup from '../People/AvatarGroup';
import CardHeaderTitle from '../Cards/CardHeaderTitle';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import { GOAL_VISIBILITY_EVERYONE } from '../../../utils/models/Goal';
import ModalAspirationEditor from '../Modals/ModalAspirationEditor';
import PropTypes from 'prop-types';
import RichTextViewer from './RichTextViewer';
import TagsList from '../TagsList';
import { toast } from 'react-toastify';

const AspirationInput: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const aspiration = props.aspiration;

  const [modalEditorIsOpen, setModalEditorIsOpen] = useState(false);
  const toggleModalEditor = () => setModalEditorIsOpen(!modalEditorIsOpen);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setConfirmDeleteModal(!confirmDeleteModal);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);

  const propsOnDelete = props.onDelete;

  const confirmDelete = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      // @ts-expect-error
      '/aspirations/' + aspiration.id,
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            setDeleteValidationErrors(hardErrorMessage);
          } else {
            setDeleteValidationErrors(error);
          }
        } else {
          setConfirmDeleteModal(false);

          // go to dashboard and show toast
          toast.success(
            formatMessage({
              id: 'app.views.widgets.inputs.aspiration_input.aspiration_deleted',
              defaultMessage: 'Aspiration deleted!',
            })
          );
          if (propsOnDelete) {
            propsOnDelete();
          }
        }
      },
      null
    );
  }, [aspiration, propsOnDelete, formatMessage]);

  const hasTags = useMemo(
    // @ts-expect-error
    () => aspiration.skills?.length > 0 || aspiration.credentials?.length > 0,
    // @ts-expect-error
    [aspiration.credentials, aspiration.skills]
  );

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          {/* @ts-expect-error */}
          {aspiration.visibility !==
          GOAL_VISIBILITY_EVERYONE(formatMessage).id ? (
            <i
              className="fe fe-lock me-2"
              style={{ position: 'relative', top: 1 }}
            />
          ) : (
            ''
          )}
          {/* @ts-expect-error */}
          {aspiration.name}
        </CardHeaderTitle>
        {!props.isReadOnly && (
          <>
            <ModalAspirationEditor
              isOpen={modalEditorIsOpen}
              toggle={toggleModalEditor}
              title={formatMessage({
                id: 'app.views.widgets.inputs.aspiration_input.editor.title',
                defaultMessage: 'Edit aspiration',
              })}
              aspiration={aspiration}
              callback={props.callback}
            />
            <ConfirmationDialogModal
              isOpen={confirmDeleteModal}
              onClosed={() => setDeleteValidationErrors(null)}
              toggle={toggleConfirmDeleteModal}
              confirmCallback={confirmDelete}
              title={formatMessage({
                id: 'app.views.widgets.inputs.aspiration_input.modal.title',
                defaultMessage: 'Delete aspiration?',
              })}
              description={formatMessage({
                id: 'app.views.widgets.inputs.aspiration_input.modal.description',
                defaultMessage:
                  'Are you sure that you want to delete this aspiration?',
              })}
              confirmText={formatMessage({
                id: 'app.views.widgets.inputs.aspiration_input.modal.confirm_text',
                defaultMessage: 'Delete aspiration',
              })}
              validationErrors={deleteValidationErrors}
            />
            <UncontrolledDropdown>
              <DropdownToggle
                tag="div"
                role="button"
                className="dropdown-ellipses text-primary text-decoration-none"
              >
                <Button color="link" className="p-0">
                  <span className={'me-2 fe fe-edit'}></span>
                  <small>
                    <FormattedMessage
                      id="app.views.widgets.inputs.aspiration_input.edit"
                      defaultMessage="Edit"
                    />
                  </small>
                </Button>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={toggleModalEditor}>
                  <FormattedMessage
                    id="app.views.widgets.inputs.aspiration_input.edit_aspiration"
                    defaultMessage="Edit aspiration"
                  />
                </DropdownItem>
                {/* @ts-expect-error */}
                {aspiration.id && (
                  <DropdownItem onClick={toggleConfirmDeleteModal}>
                    <FormattedMessage
                      id="app.views.widgets.inputs.aspiration_input.delete_aspiration"
                      defaultMessage="Delete aspiration"
                    />
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        )}
      </CardHeader>
      <CardBody>
        {/* @ts-expect-error */}
        {aspiration.description && (
          <Row className={hasTags ? '' : 'mb-4'}>
            <Col>
              {/* @ts-expect-error */}

              {aspiration.description && (
                <RichTextViewer
                  // @ts-expect-error
                  model={aspiration.description}
                  expanded={true}
                />
              )}
            </Col>
          </Row>
        )}
        {hasTags && (
          <Row className="mb-4">
            <Col>
              <TagsList
                // @ts-expect-error
                skills={aspiration.skills}
                // @ts-expect-error
                credentials={aspiration.credentials}
              >
                <span className="text-muted small me-2">
                  <FormattedMessage
                    id="app.views.widgets.inputs.aspiration_input.achieved_using"
                    defaultMessage="Achieved using"
                  />
                </span>
              </TagsList>
            </Col>
            {/* @ts-expect-error */}
            {aspiration.supporter_people?.length > 0 && (
              <Col className="col-auto">
                <FormattedMessage
                  id="app.views.widgets.inputs.aspiration_input.supportable_by"
                  defaultMessage="<span>Supportable by</span> {peopleList}"
                  values={{
                    span: (...chunks) => (
                      <span
                        className="text-muted small me-1 mt-2"
                        style={{ position: 'relative', top: 2 }}
                      >
                        {chunks}
                      </span>
                    ),
                    peopleList: (
                      <AvatarGroup
                        size="xs"
                        // @ts-expect-error
                        people={aspiration.supporter_people}
                      />
                    ),
                  }}
                />
              </Col>
            )}
          </Row>
        )}
        {props.children}
      </CardBody>
    </Card>
  );
};

const AspirationInput_propTypes = {
  isReadOnly: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
  aspiration: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

type Props = PropTypes.InferProps<typeof AspirationInput_propTypes>;

export default React.memo(AspirationInput);
