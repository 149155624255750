import { getUnattributedPerson } from '../../../utils/models/Person';

export const getUniqueWordCloudsFromRelationships = (
  formatMessage,
  relationships,
  tagType
) => {
  if (!(relationships?.length > 0)) {
    return [];
  }

  return relationships.reduce(
    (acc, r) =>
      acc.concat(
        r[tagType]
          ? // ensure skills only mentioned once for a given
            // contributor
            r[tagType].map((tag, index) => ({
              wordObject: tag,
              contributedPerson: r.person?.id
                ? r.person
                : getUnattributedPerson(formatMessage, index, r.type),
            }))
          : []
      ),
    []
  );
};
