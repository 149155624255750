import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

interface Props {
  data: Array<{ value: number; color: string }>;
}

const HorizontalBar: FC<Props> = (props) => {
  return (
    <Row className="gx-0" style={{ height: '1.5rem' }}>
      {props.data?.map((d, i) => (
        <Col
          key={i}
          className={'col-auto'}
          style={{ width: `${100 * d.value}%`, backgroundColor: d.color }}
        />
      ))}
    </Row>
  );
};

export default React.memo(HorizontalBar);
