import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import EmptyState from '../Widgets/EmptyState';

const MentorshipsSummary: FC = () => {
  const { formatMessage } = useIntl();
  const mentorshipOpportunities = [];

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.opportunities.opportunities_dashboard.mentorship_summary.mentorship"
            defaultMessage="Mentorships"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {mentorshipOpportunities?.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.opportunities.opportunities_dashboard.title.no_menorship_opportunities',
              defaultMessage: 'No menorship opportunities',
            })}
            subtitle={formatMessage({
              id: 'app.views.opportunities.opportunities_dashboard.subtitle.there_are_no_mentorship_opportunities',
              defaultMessage:
                'There are no mentorship opportunities currently available.',
            })}
          />
        )}
      </CardBody>
    </Card>
  );
};

const ProblemsSummary: FC = () => {
  const { formatMessage } = useIntl();

  const problemOpportunities = [];

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.opportunities.opportunities_dashboard.problem_summary.header.title"
            defaultMessage="Problem Opportunities"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {problemOpportunities?.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.opportunities.opportunities_dashboard.title.no_problems',
              defaultMessage: 'No problems',
            })}
            subtitle={formatMessage({
              id: 'app.views.opportunities.opportunities_dashboard.subtitle.there_are_no_problem_opportunities',
              defaultMessage:
                'There are no problem opportunities currently available.',
            })}
          />
        )}
      </CardBody>
    </Card>
  );
};

const IdeasSummary: FC = () => {
  const { formatMessage } = useIntl();

  const ideaOpportunities = [];

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.opportunities.opportunities_dashboard.ideas_summary.header.title"
            defaultMessage="Ideas"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {ideaOpportunities?.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.opportunities.opportunities_dashboard.title.no_ideas',
              defaultMessage: 'No ideas',
            })}
            subtitle={formatMessage({
              id: 'app.views.opportunities.opportunities_dashboard.subtitle.there_are_no_idea_opportunities',
              defaultMessage:
                'There are no idea opportunities currently available.',
            })}
          />
        )}
      </CardBody>
    </Card>
  );
};

const OpportunitiesDashboard: FC = () => {
  return (
    <Row>
      <Col className="col-md-9">
        <MentorshipsSummary />
        <ProblemsSummary />
      </Col>
      <Col className="col-md-3">
        <IdeasSummary />
      </Col>
    </Row>
  );
};

export default React.memo(OpportunitiesDashboard);
