import {
  Col,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import {
  ENGAGEMENT_SURVEY_OTHERS_EXPLANATION,
  ICONS,
} from '../../../consts/consts';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import AccordionTable from '../AccordionTable';
import Modal from '../../../components/SafeModal';
import ObjectsDropdown from '../Dropdowns/ObjectsDropdown';
import PropTypes from 'prop-types';
import RichTextViewer from '../Inputs/RichTextViewer';
import { capitalize } from '../../../utils/util/formatter';
import { createName } from '../../EngagementSurvey/utils';

const TextQuestionTableCard: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal, setShowModal]);

  const setModal = useCallback(
    (data) => {
      setModalData(data);
      toggleModal();
    },
    [toggleModal, setModalData]
  );

  const COLUMNS = [
    {
      name: formatMessage({
        id: 'app.views.widgets.cards.text_question_table_card.text_question',
        defaultMessage: 'Text Question',
      }),
      field: 'name',
      className: 'col-9',
    },
    {
      name: '',
      field: 'action',
      className: 'col-3 text-end',
    },
  ];

  const rows = useMemo(
    () =>
      props.rows?.map((q, i) => ({
        name: createName(
          formatMessage,
          // @ts-expect-error
          q.displayName,
          // @ts-expect-error
          q.count,
          'text-' + i,
          'fs-4',
          100
        ),
        action: (
          <>
            <div
              role="button"
              onClick={() => {
                setModal({
                  // @ts-expect-error
                  name: q.displayName,
                  // @ts-expect-error
                  data: q.data,
                });
              }}
              className="btn btn-primary fs-4"
            >
              <FormattedMessage
                id="app.views.widgets.cards.text_question_table_card.view_responses"
                defaultMessage="View responses"
              />
            </div>
          </>
        ),
      })),
    [props.rows, setModal, formatMessage]
  );

  return (
    <>
      {showModal && (
        <TextQuestionModal
          // @ts-expect-error
          name={modalData.name}
          // @ts-expect-error
          data={modalData.data}
          isOpen={showModal}
          toggle={toggleModal}
          exportButton={props.exportButton}
          hideDropdown={props.hideDropdown ?? false}
          renderItem={props.renderItem}
        />
      )}
      <AccordionTable columns={COLUMNS} rows={rows} />
    </>
  );
};

const TextQuestionTableCard_propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  exportButton: PropTypes.node,
  hideDropdown: PropTypes.bool,
  renderItem: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof TextQuestionTableCard_propTypes>;

export default React.memo(TextQuestionTableCard);

const TextQuestionModal: FC<PropsQuestionModal> = (props) => {
  const { formatMessage } = useIntl();
  const [segment, setSegment] = useState(null);

  const segments = useMemo(
    () => Object.keys(props.data)?.map((s) => ({ id: s, name: s })),
    [props.data]
  );

  useEffect(() => {
    if (segment === null) {
      // @ts-expect-error
      setSegment(segments[0]);
    }
  }, [segment, segments]);

  const columns = useMemo(() => {
    return [
      {
        // @ts-expect-error
        name: segment?.name,
        field: 'name',
        className: 'col-3',
      },
      {
        name: formatMessage({
          id: 'app.views.widgets.cards.text_question_table_card.response',
          defaultMessage: 'Response',
        }),
        field: 'value',
        className: 'col-9',
      },
    ];
  }, [segment, formatMessage]);

  const rows = useMemo(() => {
    // @ts-expect-error
    const selectedData = props.data[segment?.name];
    const dataRows = [];

    for (const field in selectedData) {
      const responses = selectedData[field]?.responses;

      responses.forEach((r, i) => {
        const id = 'text-modal-others-tootip-' + i;
        const fieldName =
          field === '_others' ? (
            <>
              <span id={id}>
                <FormattedMessage
                  id="app.views.widgets.cards.text_question_table_card.rows.others"
                  defaultMessage="Others"
                />
                <i
                  className={
                    ICONS.HELP + ' small ms-1 text-primary position-relative'
                  }
                />
              </span>
              <UncontrolledPopover
                placement="top"
                trigger="hover click"
                target={id}
              >
                {ENGAGEMENT_SURVEY_OTHERS_EXPLANATION(formatMessage)}
              </UncontrolledPopover>
            </>
          ) : (
            capitalize(field)
          );

        // @ts-expect-error
        dataRows.push({
          name: fieldName,
          value: r,
        });
      });
    }

    // Sorting the responses alphabetically by segment value
    // (easier for manually scroll search)
    // @ts-expect-error
    dataRows.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    return dataRows;
  }, [props.data, segment, formatMessage]);

  return (
    <Modal
      autoFocus={false}
      // @ts-expect-error
      isOpen={props.isOpen}
      // @ts-expect-error
      toggle={props.toggle}
      size={'xl'}
    >
      {/* @ts-expect-error */}
      <ModalHeader toggle={props.toggle}>
        <Row>
          <Col className="col-7 align-self-center">
            <Row>
              <Col className="text-muted col-auto">
                <FormattedMessage
                  id="app.views.widgets.cards.text_question_table_card.question"
                  defaultMessage="Question:"
                />
              </Col>
              <Col>
                {/* @ts-expect-error */}
                <RichTextViewer model={props.name} expanded={false} />
              </Col>
            </Row>
          </Col>
          <Col className="col-5">
            <Row className="justify-content-end">
              {!props.hideDropdown && (
                <Col className="col-auto">
                  <ObjectsDropdown
                    objects={segments}
                    // @ts-expect-error
                    value={segment?.id}
                    onChange={(e) => {
                      const match = segments.find(
                        (s) => s.id === e.target.value
                      );
                      // @ts-expect-error
                      setSegment(match);
                    }}
                    renderItem={props.renderItem}
                    size={'sm'}
                  />
                </Col>
              )}
              <Col className="col-auto me-2">{props.exportButton}</Col>
            </Row>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <AccordionTable columns={columns} rows={rows} />
      </ModalBody>
    </Modal>
  );
};

const TextQuestionModal_propTypes = {
  name: PropTypes.string,
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  exportButton: PropTypes.node,
  hideDropdown: PropTypes.bool,
  renderItem: PropTypes.func,
};

type PropsQuestionModal = PropTypes.InferProps<
  typeof TextQuestionModal_propTypes
>;
