import { Button, ButtonGroup } from 'reactstrap';
import { type IntlShape, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo } from 'react';

import {
  OnFilterChange,
  STATUS_DESCRIPTOR,
  StatusDescriptorType,
} from '../../../utils/models/Objective';

type ObjectivesOverviewStatusFilterItem = {
  value: string | undefined;
  text: React.ReactNode;
  outline: boolean;
  onChange: (status: string) => void;
  disabled: boolean;
  color: string;
};

const ObjectivesOverviewStatusFilterItem = ({
  value,
  text,
  outline,
  color,
  onChange,
  disabled,
}: ObjectivesOverviewStatusFilterItem) => {
  return (
    <Button
      color={color}
      disabled={disabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        onChange((e.target as HTMLInputElement).value as string)
      }
      value={value}
      outline={outline}
    >
      {text}
    </Button>
  );
};

type Props = {
  disabled: boolean;
  value: string[];
  onChange: (delta: OnFilterChange) => void;
};

const ObjectivesOverviewStatusFilter: FC<Props> = ({
  value,
  onChange,
  disabled,
}: Props) => {
  const intl: IntlShape = useIntl();
  const filteredConfiguratons: StatusDescriptorType[] = useMemo(
    () => [
      ...STATUS_DESCRIPTOR(intl),
      {
        color: 'secondary',
        // TODO (on follow-up stories, once we are happy with the copy): i18n
        label: 'Not yet started',
        i18n: 'Not yet started',
        status: 'N',
        colorHex: '#6c757d',
      },
    ],
    [intl]
  );

  const handleOnChange = useCallback(
    (status: string) => {
      if (value.includes(status)) {
        onChange({ status: value.filter((item) => item !== status) });
      } else {
        onChange({ status: [...value, status] });
      }
    },
    [value, onChange]
  );

  return (
    <ButtonGroup>
      {filteredConfiguratons.map((item) => (
        <ObjectivesOverviewStatusFilterItem
          disabled={disabled}
          key={'status-filter-item-' + item.status}
          onChange={handleOnChange}
          value={item.status}
          text={item.label}
          outline={!value.includes(item.status || 'N')}
          color={item.color}
        />
      ))}
    </ButtonGroup>
  );
};

export default ObjectivesOverviewStatusFilter;
