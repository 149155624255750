import {
  EventSerializerBufferFunctionType,
  ORG_SETTING_OBJECTIVES_RESTRICT_VISIBILITY_TO_MATCH_EDITABILITY,
} from '../../utils/util/hooks';
import {
  GetObjectivesTreeResponse,
  ObjectiveWithRef as Objective,
  formatNumber4Decimals,
  parseObjectivesForDisplay,
} from '../../utils/models/Objective';
import { Me, OrganizationSettings } from '../../types';
import { createRef, useCallback, useRef, useState } from 'react';
import { debounce, intersection } from 'lodash';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { ONE_HOUR_IN_MILLISECONDS } from '../../consts/consts';
import { renderErrorOrCallback } from '../../utils/util/util';
import { useConfirmApi } from 'utils/api/ApiHooks';
import { useEventSerializer } from '../../utils/util/EventSerializer';
import { v4 as uuidv4 } from 'uuid';

export type ObjectiveOperation =
  | 'CASCADE_DELETE'
  | 'DELETE'
  | 'DELETE_BREAK_TREE'
  | 'REMOVE_FROM_COLLABORATORS'
  | 'CASCADE_REMOVE_FROM_COLLABORATORS'
  | 'IMPORT'
  | 'UPDATE';

export const useObjectiveActions = ({
  me,
  person,
  firstDay,
  lastDay,
  currentOrganization,
}) => {
  const [addToBufferQueue] = useEventSerializer();

  // this is to allow the user not to save to the buffer every time there's a keystroke
  const addToBufferQueueDebounced = useRef(
    debounce<EventSerializerBufferFunctionType>(addToBufferQueue, 500)
  ).current;

  // This event only creates an element in the ui, it doesn't save it to the buffer
  // consequent calls to edit the elements will save it to the buffer
  const createNewTopItem = ({
    setObjectives,
  }: {
    setObjectives: (callback: (objectives: Objective[]) => Objective[]) => void;
  }) => {
    // @ts-expect-error
    setObjectives((objectives: Objective[]) => [
      ...objectives,
      {
        name: '',
        key: uuidv4(),
        coverage_start_date: firstDay,
        coverage_end_date: lastDay,
        organization: currentOrganization,
        owner_person: person,
        collaborators: [person],
        children: [],
        ref: createRef<HTMLTextAreaElement>(),
        isNew: true,
        auto_calculation: false,
      },
    ]);
  };

  const calculateDeleteOperation = (
    value: Objective,
    { deleteAllChildren, removeForAllCollaborators }
  ): ObjectiveOperation => {
    if (removeForAllCollaborators) {
      if (deleteAllChildren) {
        return 'CASCADE_DELETE';
      }

      if (value.children && value.children.length > 0) {
        return 'DELETE_BREAK_TREE';
      }

      return 'DELETE';
    }

    // if not delete for all collaborators
    if (deleteAllChildren) {
      return 'CASCADE_REMOVE_FROM_COLLABORATORS';
    }
    return 'REMOVE_FROM_COLLABORATORS';
  };

  // This event is triggered when the user delete an objective
  const deleteObjective = ({
    value,
    parentObjective = null,
    updateParentObjective,
    deleteAllChildren = false,
    removeForAllCollaborators = false,
  }: {
    value: Objective;
    parentObjective?: Objective | null;
    updateParentObjective: (value: Objective) => void;
    deleteAllChildren?: boolean;
    removeForAllCollaborators?: boolean;
  }) => {
    addToBufferQueueDebounced({
      ...toApiObjective(value, parentObjective),
      collaborators: value.collaborators,
      operation: calculateDeleteOperation(value, {
        deleteAllChildren,
        removeForAllCollaborators,
      }),
    });

    // remove the objective from the parent on the UI
    if (parentObjective) {
      updateParentObjective({
        ...parentObjective,
        children:
          parentObjective.children?.filter((child) => child.key != value.key) ??
          [],
      });
    } else {
      // remove the objective from the top level on the UI
      updateParentObjective(value);
    }
  };

  // This event is triggered when the user add a child to an objective
  const addChild = ({
    value,
    setValue,
    setShowChildren,
  }: {
    value: Objective;
    setValue: (value: Objective) => void;
    setShowChildren: (value: boolean) => void;
  }) => {
    const newChild = {
      key: uuidv4(),
      name: '',
      parent: value.id,
      parent_key: value.key,
      coverage_start_date: value.coverage_start_date,
      coverage_end_date: value.coverage_end_date,
      organization: currentOrganization,
      owner_person: person,
      collaborators: [person],
      auto_calculation: false,
      ref: createRef(),
      isNew: true,
    };
    setValue({
      ...value,
      // @ts-expect-error
      children: [...(value?.children ?? []), newChild],
    });

    addToBufferQueue(
      toApiObjective(
        // @ts-expect-error
        newChild,
        value
      )
    );
    setShowChildren(true);
  };

  // This event is triggered when the user add a child to the parent of the current objective
  const addSibling = ({
    value,
    parentObjective,
    person,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    me,
    updateParentObjective,
  }) => {
    const newChild = {
      key: uuidv4(),
      name: '',
      coverage_start_date: value.coverage_start_date,
      coverage_end_date: value.coverage_end_date,
      organization: currentOrganization,
      owner_person: person,
      collaborators: [person],
      auto_calculation: false,
      ref: createRef(),
      isNew: true,
    };
    addToBufferQueue(
      toApiObjective(
        // @ts-expect-error
        newChild,
        parentObjective
      )
    );

    updateParentObjective({
      ...parentObjective,
      children: [...(parentObjective?.children || []), newChild],
    });
  };

  const updateRelatedObjectives = ({
    value,
    setValue,
    relatedObjectives,
    parentObjective,
  }) => {
    const modified = {
      ...value,
      related_to: relatedObjectives,
    };
    addToBufferQueueDebounced(toApiObjective(modified, parentObjective));
    setValue(modified);
  };

  const updateRelatedObjectivesRoot = ({
    value,
    setValue,
    relatedObjectives,
  }) => {
    const modified = {
      ...value,
      related_to: relatedObjectives,
    };
    addToBufferQueueDebounced(toApiObjective(modified, value.parent_objective));
    setValue(modified);
  };

  // This event is triggered when the user change link to the parent objective on a top level
  const setRootParentObjective = ({
    rootParentObjectives,
    value,
    setValue,
  }: {
    rootParentObjectives: Objective[];
    value: Objective;
    setValue: (value: Objective) => void;
  }) => {
    const rootParentObjective =
      rootParentObjectives.length > 0 ? rootParentObjectives[0] : null;

    addToBufferQueueDebounced(toApiObjective(value, rootParentObjective));
    setValue({
      ...value,
      parent_objective: rootParentObjective,
    });
  };

  // this event is triggered when the user change some indicator of the objectives
  const updateIndicators = ({
    updatedObj,
    value,
    setValue,
    parentObjective,
    updateParentObjective,
  }) => {
    const updated = fromIndicators(value, updatedObj);
    addToBufferQueue(toApiObjective(updated, parentObjective));
    setValue(updated);
    updateParentObjective({
      ...parentObjective,
      children: parentObjective.children.map((child) =>
        child.key !== value.key ? child : updated
      ),
    });
  };

  // this event is triggered when the user change some indicator of the objectives on top
  const updateIndicatorRoot = ({ updatedObj, value, setValue }) => {
    const updated = fromIndicators(value, updatedObj);
    addToBufferQueueDebounced(toApiObjective(updated, value.parent_objective));
    setValue(updated);
  };

  // This event is triggered when the user change the text of the objective
  const updateText = ({
    e,
    value,
    parentObjective,
    setValue,
  }: {
    e: React.ChangeEvent<HTMLTextAreaElement>;
    value: Objective;
    parentObjective: Objective;
    setValue: (value: Objective) => void;
  }) => {
    addToBufferQueueDebounced(
      toApiObjective({ ...value, name: e.target.value }, parentObjective)
    );
    setValue({ ...value, name: e.target.value });
  };

  // This event is triggered when the user change the text of the objective on top
  const updateTextRoot = ({
    e,
    value,
    setValue,
  }: {
    e: React.ChangeEvent<HTMLTextAreaElement>;
    value: Objective;
    setValue: (value: Objective) => void;
  }) => {
    addToBufferQueueDebounced(
      toApiObjective({ ...value, name: e.target.value }, null)
    );
    setValue({ ...value, name: e.target.value });
  };

  const updateTextKeyDownRoot = ({
    e,
    value,
    setShowDeleteModal,
    updateParentObjective,
    setValue,
    setShowChildren,
  }) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if ((value?.children?.length ?? 0) === 0) {
        addChild({ value, setValue, setShowChildren });
      } else {
        setShowChildren(true);
        const firstChildRef = value.children[0].ref.current;
        firstChildRef?.focus();
        firstChildRef?.setSelectionRange(
          firstChildRef.value.length,
          firstChildRef.value.length
        );
      }
    }
    if (e.keyCode === 8 && (e.target?.value === '' || !e.target?.value)) {
      e.preventDefault();
      if ((value?.children?.length ?? 0) > 0) {
        setShowDeleteModal(true);
      } else {
        deleteObjective({
          value,
          updateParentObjective,
        });

        // updateParentObjective(value);
      }
      return;
    }
  };

  const isLastSibling = (objective: Objective, parentObjective: Objective) => {
    const siblings = parentObjective?.children || [];
    const siblingsCount = siblings.length;
    return (
      siblingsCount === 0 || siblings[siblingsCount - 1].key == objective.key
    );
  };

  const updateTextKeyDown = ({
    e,
    addSibling,
    value,
    parentObjective,
    setShowDeleteModal,
    deleteObjective,
    updateParentObjective,
  }) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isLastSibling(value, parentObjective)) {
        addSibling({
          value,
          parentObjective,
          person,
          me,
          updateParentObjective,
        });
      } else {
        const siblings = parentObjective?.children || [];
        const currentIndex = siblings.findIndex(
          (sibling) => sibling.key === value.key
        );
        const nextSiblingRef = siblings[currentIndex + 1].ref.current;
        nextSiblingRef.focus();
        nextSiblingRef.setSelectionRange(
          nextSiblingRef.value.length,
          nextSiblingRef.value.length
        );
      }
    }
    if (e.keyCode === 8 && (e.target?.value === '' || !e.target?.value)) {
      e.preventDefault();
      if (value.children && value.children.length > 0) {
        setShowDeleteModal(true);
        return;
      }
      deleteObjective({
        value,
        parentObjective,
        updateParentObjective,
      });
      return;
    }
  };

  return {
    createNewTopItem,
    deleteObjective,
    addChild,
    addSibling,
    updateRelatedObjectives,
    updateRelatedObjectivesRoot,
    setRootParentObjective,
    updateIndicatorRoot,
    updateIndicators,
    updateText,
    updateTextRoot,
    updateTextKeyDownRoot,
    updateTextKeyDown,
  };
};

export const useObjectiveOperations = ({
  isDemoOrPreviewMode,
  currentOrganization,
  currentProxyPerson,
  person,
  firstDay,
  lastDay,
  setObjectives,
  setState,
}) => {
  const onSubmit = (objective, onSuccess, onError) => {
    if (isDemoOrPreviewMode) {
      // just return the objective without saving it
      onSuccess(objective);
      return;
    }

    const transformedObjective = transformObjectiveForSubmit(objective);
    switch (objective.operation as ObjectiveOperation) {
      case 'DELETE': {
        ConfirmAPI.sendRequestToConfirm(
          'DELETE',
          `/v2/objectives/${transformedObjective.key}`,
          null,
          renderErrorOrCallback(onSuccess, onError)
        );
        break;
      }
      case 'DELETE_BREAK_TREE': {
        ConfirmAPI.sendRequestToConfirm(
          'DELETE',
          `/v2/objectives/${transformedObjective.key}`,
          null,
          renderErrorOrCallback(onSuccess, onError)
        );
        break;
      }
      case 'CASCADE_DELETE': {
        ConfirmAPI.sendRequestToConfirm(
          'DELETE',
          `/v2/objectives/${transformedObjective.key}?cascade=true`,
          null,
          renderErrorOrCallback(onSuccess, onError)
        );
        break;
      }

      case 'REMOVE_FROM_COLLABORATORS': {
        console.log(
          'Removing self from collaborators',
          transformedObjective.key
        );
        ConfirmAPI.sendRequestToConfirm(
          'POST',
          `/objectives/${objective.key}/collaborators`,
          {
            collaborators: objective.collaborators
              .map((it) => it.id)
              .filter((it) => it !== person.id),
          },
          renderErrorOrCallback(onSuccess, onError),
          null,
          null
        );
        break;
      }

      case 'CASCADE_REMOVE_FROM_COLLABORATORS': {
        console.log(
          'Removing self from collaborators on all the sub-objectives',
          transformedObjective.key
        );
        ConfirmAPI.sendRequestToConfirm(
          'POST',
          `/objectives/${objective.key}/collaborators`,
          {
            cascade: true,
            collaborators: [person.id],
          },
          renderErrorOrCallback(onSuccess, onError),
          null,
          null
        );
        break;
      }

      case 'IMPORT': {
        console.log(
          'Import objective amending collaborators',
          transformedObjective.key
        );
        ConfirmAPI.sendRequestToConfirm(
          'POST',
          `/objectives/${objective.key}/collaborators`,
          { collaborators: objective.collaborators.map((it) => it.id) },
          renderErrorOrCallback(onSuccess, onError),
          null,
          null
        );
        break;
      }
      default: {
        ConfirmAPI.sendRequestToConfirm(
          'POST',
          '/v2/objectives',
          transformedObjective,
          renderErrorOrCallback(onSuccess, onError)
        );
      }
    }
  };

  const onSuccess = (successItem) => {
    switch (successItem.operation as ObjectiveOperation) {
      case 'IMPORT':
      case 'REMOVE_FROM_COLLABORATORS':
      case 'CASCADE_REMOVE_FROM_COLLABORATORS':
      case 'DELETE_BREAK_TREE': {
        console.log('Refresh :', successItem);
        setState('LOADING');
        getAllTreeObjectives({
          person,
          firstDay,
          lastDay,
          shouldCacheObjectivesOnFrontend: false,
          user: null,
          currentOrganization,
          currentProxyPerson,
          onFetchedObjectives: (data) => {
            setObjectives(data);
            setState('FETCHED');
          },
        });
        break;
      }
    }
    console.info('Successfully saved objective:', successItem);
  };

  const onError = (message, errorItem) => {
    console.error(message);
    console.warn('Could not save agenda item, retrying...:', errorItem.key);
  };

  return { onSubmit, onSuccess, onError };
};

export const getAllTreeObjectives = ({
  person,
  currentOrganization,
  currentProxyPerson,
  firstDay,
  lastDay,
  shouldCacheObjectivesOnFrontend,
  user,
  onFetchedObjectives,
}) => {
  const params = {
    person: person.id,
    organization: currentOrganization?.id,
    proxy: currentProxyPerson ? currentProxyPerson.email : undefined,
    coverage_start_date: firstDay,
    coverage_end_date: lastDay,
  };

  const fetchOrgSettings = new Promise((resolve, reject) => {
    ConfirmAPI.getUrlWithCache(
      '/organization-settings',
      'organization-settings',
      user?.sub,
      currentProxyPerson,
      {
        names: [
          ORG_SETTING_OBJECTIVES_RESTRICT_VISIBILITY_TO_MATCH_EDITABILITY,
        ],
        organization: currentOrganization.id,
      },
      resolve,
      reject,
      ONE_HOUR_IN_MILLISECONDS
    );
  });

  const onError = (message) => {
    if (message.status === 403) {
      // zero objectives matched with visibility criteria will return the correct empty page
      onFetchedObjectives([], false, []);
    } else {
      console.log(
        'Error Fetching objective tree for person with id ',
        person.id,
        message
      );
    }
  };

  fetchOrgSettings
    .then((orgSettings: unknown) => {
      const companyOKRsFullVisibility = !(
        (orgSettings as OrganizationSettings)
          ?.objectives_restrict_visibility_to_match_editability ?? false
      );
      const onTreeFetchSuccess = (data: GetObjectivesTreeResponse) => {
        if (!data?.results) {
          onFetchedObjectives([], companyOKRsFullVisibility, []);
          return;
        }

        const accessiblePeopleIds = data.accessible_people_ids;
        const objectivesWithRefs: Objective[] = data.results.map(
          (objective) => parseObjectivesForDisplay(objective) // can do here the related to game:
        );

        onFetchedObjectives(
          objectivesWithRefs,
          companyOKRsFullVisibility,
          accessiblePeopleIds
        );
      };

      ConfirmAPI.getUrlWithCache(
        '/objectives/tree',
        shouldCacheObjectivesOnFrontend && '/objectives/tree',
        shouldCacheObjectivesOnFrontend && user?.sub,
        currentProxyPerson,
        params,
        onTreeFetchSuccess,
        onError
      );
    })
    .catch(onError);
};

interface RelatedToObjectivePost {
  id: number;
  key?: string;
  coverage_start_date: string;
  coverage_end_date: string;
}

export interface ApiObjectivePost {
  id: number;
  key?: string;
  coverage_start_date: string;
  coverage_end_date: string;
  due_date: string;
  name: string;
  score: number | null;
  score_denominator: number | null;
  weight: number | null;
  auto_calculation: boolean;
  score_comments?: string;
  score_comments_author_person?: Me;
  status: string;
  parent?: number | null;
  parent_key?: string | null;
  related_to?: RelatedToObjectivePost[];
  organization?: number;
  owner_person: number;
}

const toApiObjective = (
  {
    id,
    key,
    coverage_start_date,
    coverage_end_date,
    due_date,
    name,
    score,
    score_denominator,
    weight,
    auto_calculation,
    score_comments,
    score_comments_author_person,
    status,
    related_to,
    organization,
    owner_person,
  }: Objective,
  parentObjective: Objective | null
): ApiObjectivePost => {
  return {
    id,
    key,
    coverage_start_date,
    coverage_end_date,
    due_date,
    name,
    score,
    score_denominator,
    weight,
    auto_calculation,
    score_comments,
    score_comments_author_person,
    status,
    organization: organization?.id,
    owner_person: owner_person?.id,
    parent: parentObjective?.id ?? null,
    parent_key: parentObjective?.key ?? null,
    related_to: related_to?.map((it) => ({
      id: it.id,
      key: it.key,
      coverage_start_date: it.coverage_start_date,
      coverage_end_date: it.coverage_end_date,
    })),
  };
};

// do not need the children since we are not pushing them
const transformObjectiveForSubmit = (o: Objective) => {
  return {
    ...o,
    score: o.score ? formatNumber4Decimals(o.score / 100) : null,
    score_denominator: o.score_denominator
      ? formatNumber4Decimals(o.score_denominator / 100)
      : null,
    weight: o.weight ? formatNumber4Decimals(o.weight / 100) : null,
    score_comments_author_person: o.score_comments_author_person?.id,
  };
};

function fromIndicators(value, updatedObj) {
  return {
    ...value,
    score: updatedObj.score,
    score_denominator: updatedObj.score_denominator,
    weight: updatedObj.weight,
    auto_calculation: updatedObj.auto_calculation,
    status: updatedObj.status,
    coverage_start_date: updatedObj.coverage_start_date,
    coverage_end_date: updatedObj.coverage_end_date,
    score_comments: updatedObj.score_comments,
    score_comments_author_person: updatedObj.score_comments_author_person,
  };
}

export const hasPersonalEditability = (
  objective: Objective,
  accessiblePeopleIds: number[]
): boolean => {
  return (
    intersection(
      accessiblePeopleIds,
      objective.collaborators.map((it) => it.id)
    ).length > 0
  );
};

export const hasNoUsefulInformation = (value: Objective): boolean => {
  const hasNoChildren = (value.children?.length ?? 0) === 0;
  const isEmpty = !value.name;
  const allChildrenHaveNoUsefulInformation =
    value.children?.every((it) => hasNoUsefulInformation(it)) ?? true;
  return isEmpty && (hasNoChildren || allChildrenHaveNoUsefulInformation);
};

type ObjectiveCopyToNextPeriodParams = {
  key: string;
  personId?: number;
};

export const useObjectiveCopyToNextPeriod = ({
  key,
  personId,
}: ObjectiveCopyToNextPeriodParams) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [copyAllChildren, setCopyAllChildren] = useState<boolean>(false);

  const action = useCallback(({ copyAllChildren }) => {
    setCopyAllChildren(copyAllChildren);
    setDisabled(false);
  }, []);

  const reset = useCallback(() => {
    setDisabled(true);
    setCopyAllChildren(false);
  }, []);

  const callback = useCallback(() => {}, []);

  const { status, error } = useConfirmApi<void>({
    method: 'POST',
    url: `/objectives/${key}/copy-to-next-period`,
    params: { key, must_copy_children: copyAllChildren, person: personId },
    callback,
    disabled,
  });

  return {
    action,
    reset,
    status,
    error,
  };
};
