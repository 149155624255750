import { type AxiosInstance } from 'axios';

interface BackoffRetryStrategy {
  maxRetries: number;
  intervalMs: number;
  exponentialFactor: number;
} // T = intevalMs * exponentialFactor ^ (retry# -1)

export const defaultBackoffRetryConfig = (): BackoffRetryStrategy => ({
  exponentialFactor: 2,
  maxRetries: 4,
  intervalMs: 1000,
  /*
   T = 0
   RT1 = 1000 * 2 ^ (0) = 1s
   RT2 = 1000 * 2 ^ (1) = 2s
   RT3 = 1000 * 2 ^ (2) = 4s
   RT4 = 1000 * 2 ^ (3) = 8s
  */
});

const calculateBackoffDelay = (
  retryConfig: BackoffRetryStrategy,
  retryNumber: number
): number => {
  const { intervalMs, exponentialFactor } = retryConfig;
  return intervalMs * Math.pow(exponentialFactor, retryNumber - 1);
};

export const retryOnRecoverableErrors = (
  api: AxiosInstance,
  error
): Promise<unknown> => {
  const { config, message } = error;

  const retryConfig: BackoffRetryStrategy | undefined = config?.retryConfig;
  const currentRetryAttempt: number = (config?.currentRetryAttempt ?? 0) + 1;

  if (!config || !retryConfig || currentRetryAttempt > retryConfig.maxRetries) {
    return Promise.reject(error);
  }

  if (!isNetworkError(message)) {
    return Promise.reject(error);
  }

  // update for the next retry
  config.currentRetryAttempt = currentRetryAttempt;
  const delay = calculateBackoffDelay(retryConfig, currentRetryAttempt);

  const delayRetryRequest = new Promise<void>((resolve) => {
    setTimeout(() => {
      console.warn(
        `Interceptor: Attempt Retry ${currentRetryAttempt} with delay ${delay} for the request ${config.url} with retry configuration: ${retryConfig}`
      );
      resolve();
    }, delay);
  });
  return delayRetryRequest.then(() => api(config));
};

const isNetworkError = (message: string = '') => {
  return message.includes('timeout') || message.includes('Network Error');
};
