import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { FC } from 'react';

import { AppIntegration as AppIntegrationT } from '../../types';
import { FormattedMessage } from 'react-intl';
import RelativeTime from '../Widgets/RelativeTime';

interface Props {
  appIntegration: AppIntegrationT;
}

const AppIntegration: FC<Props> = ({ appIntegration }: Props) => {
  return (
    <Card>
      <CardHeader>
        <h4 className="card-header-title">
          <img height={50} src={appIntegration.app.square_image ?? undefined} />
          {appIntegration.app.display_name}
        </h4>
      </CardHeader>
      <CardBody>
        <div>
          <p>
            <FormattedMessage
              id="app.views.app_integrations.app_integration.created"
              defaultMessage="Created:"
            />{' '}
            <RelativeTime datetime={appIntegration.created_at} />
            <FormattedMessage
              id="app.views.app_integrations.app_integration.period"
              defaultMessage="."
            />
          </p>
          <p className="mb-0">
            <FormattedMessage
              id="app.views.app_integrations.app_integration.last_synced"
              defaultMessage="Last synced:"
            />{' '}
            <RelativeTime datetime={appIntegration.last_synced_on} />
            <FormattedMessage
              id="app.views.app_integrations.app_integration.period"
              defaultMessage="."
            />
          </p>
        </div>
      </CardBody>
    </Card>
  );
};

export default AppIntegration;
