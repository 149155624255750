import {
  Campaign,
  OrganizationSettings,
  Person,
  ReduxState,
} from '../../types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CAMPAIGN_PHASE_TOKEN_VALIDITY_HOURS } from 'utils/models/Campaign';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { ONE_HOUR_IN_MILLISECONDS } from '../../consts/consts';
import { isEqual } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfirmApi } from '../api/ApiHooks';
import { useSelector } from 'react-redux';

// we define all org settings here for use throughout the product
// so there is one place to look to get the list of settings
// NOTE: when adding something here, you'll need to add it to get_all_settings_keys
// in the backend
// @DEPRECATED: use the settings in ReduxContext instead
export const ORG_SETTING_TRAJECTORY_ADMINS = 'trajectory_admins';
export const ORG_SETTING_OBJECTIVES_RESTRICT_VISIBILITY_TO_MATCH_EDITABILITY =
  'objectives_restrict_visibility_to_match_editability';
export const OBJECTIVES_TIMEFRAME = 'objectives_timeframe';
export const OBJECTIVES_TIMEFRAME_DEFAULT_START_DATE =
  'objectives_timeframe_default_start_date';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

export function useComponentId(): number {
  const idRef = useRef(getUniqueId());
  return idRef.current;
}

export type PhaseCompletions = { [key: string]: boolean };
export type PhasePermissions = { [key: string]: boolean };

export const useCurrentActiveCampaign = (
  organization_id: number | null,
  proxyPerson: Person | null,
  userSub: string | undefined
): [Campaign | undefined, PhasePermissions, PhaseCompletions, boolean] => {
  const [currentActiveCampaign, setCurrentActiveCampaign] = useState<
    Campaign | undefined
  >(undefined);
  const [phasePermissions, setPhasePermissions] = useState<PhasePermissions>(
    {}
  );
  const [phaseCompletions, setPhaseCompletions] = useState<PhaseCompletions>(
    {}
  );
  const [isParticipating, setIsParticipating] = useState<boolean>(false);

  useEffect(() => {
    if (organization_id && !currentActiveCampaign) {
      ConfirmAPI.getUrlWithCache(
        '/campaigns/current-active',
        'campaigns/current-active',
        userSub,
        proxyPerson,
        { organization: organization_id },
        (data) => {
          setCurrentActiveCampaign(data?.campaign);
          setPhasePermissions(data?.phase_permissions || {});
          setPhaseCompletions(data?.completions || {});
          setIsParticipating(data?.is_participating);
        },
        (message) => {
          // error to console but don't show to user
          console.error('Header alert error: ' + JSON.stringify(message));
        }
      );
    }
  }, [currentActiveCampaign, organization_id, proxyPerson, userSub]);

  return [
    currentActiveCampaign,
    phasePermissions,
    phaseCompletions,
    isParticipating,
  ];
};

export interface SerializableEvent {
  key: string;
  modified_at: Date;
  retryAttempt: number | undefined;
}

export type EventsSerializerSuccessCallback = (item: SerializableEvent) => void;

export type EventsSerializerErrorCallback = (
  message: any,
  item: SerializableEvent
) => void;

export type EventsSerializerSubmitCallback = (
  item: SerializableEvent,
  onSuccess: EventsSerializerSuccessCallback,
  onError: EventsSerializerErrorCallback
) => void;

export type EventSerializerBufferFunctionType = (
  newItemOrItems: unknown | unknown[],
  preserveModifidedDate?: boolean
) => void;

export type EventSerializerContextType = [
  EventSerializerBufferFunctionType,
  boolean
];

// Hook that allow to submit versioned events that have a property 'key'
export const useEventsSerializer = (
  onSubmit: EventsSerializerSubmitCallback,
  onSuccessCallback: EventsSerializerSuccessCallback,
  onErrorCallback: EventsSerializerErrorCallback,
  { initialRetryDelayInMillis } = { initialRetryDelayInMillis: 500 }
): EventSerializerContextType => {
  // Queue of events to be submitted
  const [bufferQueue, setBufferQueue] = useState<SerializableEvent[]>([]);
  // Queue of inflight events
  const [submitQueue, setSubmitQueue] = useState<SerializableEvent[]>([]);

  const hasPendingChanges = bufferQueue.length > 0 || submitQueue.length > 0;

  // Dispatcher method:
  // It's only possible to pile up events in the buffer queue
  // This will take care of popping them into the submit queue and execute the submission onSubmit
  // In case of error, the event will be re-added to the buffer queue so it can be retried
  useEffect(() => {
    // If there's already an item with the same key in the submit queue, we don't want to move it
    const eligibleToSubmit = bufferQueue.filter(
      (bufferedItem) =>
        !submitQueue.find(
          (submittedItem) => submittedItem.key === bufferedItem.key
        )
    );

    // if nothing is eligible, we don't need to do anything
    if (!eligibleToSubmit.length) {
      return;
    }

    // remove the eligible items from the buffer queue
    setBufferQueue((bufferQueue) =>
      bufferQueue.filter(
        (item) =>
          !eligibleToSubmit.find(
            (itemEligible) =>
              itemEligible.key === item.key &&
              itemEligible.modified_at === item.modified_at
          )
      )
    );

    // add the eligible items to the submit queue
    setSubmitQueue((submitQueue) => [...submitQueue, ...eligibleToSubmit]);

    // submit the eligible items
    eligibleToSubmit.forEach((eligibleItem) => {
      const onSuccess = () => {
        // on success, remove the item from the submit queue...
        setSubmitQueue((submitQueue) =>
          submitQueue.filter(
            (submittedItem) => submittedItem.key !== eligibleItem.key
          )
        );
        onSuccessCallback(eligibleItem);
      };

      const onError = (message) => {
        // increase the retry attempt counter...
        const retryAttempt =
          eligibleItem?.retryAttempt != null
            ? eligibleItem.retryAttempt + 1
            : 0;
        const eligibleItemWithRetry = {
          ...eligibleItem,
          retryAttempt,
        };
        //...and add it back to the buffer queue after a delay
        setTimeout(() => {
          // on error, remove the item from the submit queue...
          setSubmitQueue((submitQueue) =>
            submitQueue.filter(
              (submittedItem) => submittedItem.key !== eligibleItem.key
            )
          );
          addToBufferQueue(eligibleItemWithRetry, true);
        }, initialRetryDelayInMillis * retryAttempt * 2);

        onErrorCallback(message, eligibleItemWithRetry);
      };

      onSubmit(eligibleItem, onSuccess, onError);
    });
  }, [
    bufferQueue,
    initialRetryDelayInMillis,
    onErrorCallback,
    onSubmit,
    onSuccessCallback,
    setBufferQueue,
    setSubmitQueue,
    submitQueue,
  ]);

  const addToBufferQueue = (newItemOrItems, preserveModifidedDate = false) => {
    const newItems = Array.isArray(newItemOrItems)
      ? newItemOrItems
      : [newItemOrItems];

    const newItemsWithModifiedDate = preserveModifidedDate
      ? newItems
      : newItems.map((item) => ({
          ...item,
          modified_at: new Date(),
        }));

    newItemsWithModifiedDate.forEach((newItem) => {
      // add the item to the buffer queue only if the version we are adding is newer than the one already in the queue
      setBufferQueue((bufferQueue) =>
        !bufferQueue.find(
          (bufferItem) =>
            bufferItem.key === newItem.key &&
            bufferItem.modified_at > newItem.modified_at
        )
          ? [...bufferQueue.filter((item) => item.key !== newItem.key), newItem] // substitute the old item with the same key in the queue with the new one
          : bufferQueue
      );
    });
  };

  return [addToBufferQueue, hasPendingChanges];
};

/*
  Register an escape key event on the current page and call the callback when the escape key is pressed
  Automatically get uregistered when the component unmounts
*/
export const useEscape = (onEscape: () => void) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      // TODO: replace deprecated property
      if (event.keyCode === 27) onEscape();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onEscape]);
};

/*
  Register and call the callback when the click is visually outside the ref
  Automatically get uregistered when the component unmounts
*/
export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  onClickOutside: () => void
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, ref]);
};

// call a function when user wakes up a tab which has been inactive for > X seconds
export const useStaleTabDetector = (onStaleTabDetected, delay = 30) => {
  const [wentInactive, setWentInactive] = useState<Date | null>(null);

  const onVisibilityStateChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      const delta = new Date().getTime() - (wentInactive?.getTime() ?? 0);
      if (delta > 1000 * delay) {
        // tab which just became visible is stale
        onStaleTabDetected();
      }
    } else if (document.visibilityState === 'hidden') {
      setWentInactive(new Date());
    }
  }, [delay, wentInactive, onStaleTabDetected]);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityStateChange);
    return () =>
      window.removeEventListener('visibilitychange', onVisibilityStateChange);
  }, [onVisibilityStateChange]);
};

// Method to memo an object that has nested depth objects (arrays, objects, etc)
export function useDeepEqualMemo(value: any) {
  const ref = useRef<any>(undefined);

  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}

interface LocalStorageStateOptions<T> {
  serialize?: (value: T) => string;
  deserialize?: (value: string) => T;
  ttlInMs?: number;
}

const LOCAL_STORAGE_CUSTOM_TYPE = 'CUSTOM';

const getFromLocalStorage = ({
  key,
  userSub,
  currentProxyPerson,
  hasTTL,
}: {
  key: string;
  userSub: string | undefined;
  currentProxyPerson: Person | null;
  hasTTL: boolean;
}): any | undefined => {
  return ConfirmAPI.getObjectFromCache(
    userSub,
    currentProxyPerson,
    LOCAL_STORAGE_CUSTOM_TYPE,
    key,
    hasTTL
  );
};

const setToLocalStorage = ({
  key,
  userSub,
  currentProxyPerson,
  ttlInMs,
  value,
}: {
  key: string;
  userSub: string | undefined;
  currentProxyPerson: Person | null;
  ttlInMs?: number;
  value: any;
}) => {
  ConfirmAPI.setObjectInCache(
    userSub,
    currentProxyPerson,
    LOCAL_STORAGE_CUSTOM_TYPE,
    key,
    value,
    ttlInMs
  );
};

// A hook to save the state and initialize it from localStorage
export const useLocalStorageState = <T>(
  key: string,
  defaultValue: T | undefined,
  {
    serialize = JSON.stringify,
    deserialize = JSON.parse,
    ttlInMs = undefined,
  }: LocalStorageStateOptions<T> = {}
): [T | undefined, React.Dispatch<React.SetStateAction<T>>] => {
  const { user } = useAuth0();
  const userSub = user?.sub;
  const currentProxyPerson = useSelector<ReduxState, Person | null>(
    (state) => state?.currentProxyPerson
  );
  const cacheKeyParams = useMemo(
    () => ({
      key,
      userSub,
      currentProxyPerson,
      hasTTL: !!ttlInMs,
      ttlInMs,
    }),
    [key, userSub, currentProxyPerson, ttlInMs]
  );

  const [state, setState] = useState<T>(() => {
    const valueInLocalStorage = getFromLocalStorage(cacheKeyParams);

    if (valueInLocalStorage) {
      try {
        return deserialize(valueInLocalStorage);
      } catch (error) {
        ConfirmAPI.removeObjectFromCache(
          userSub,
          currentProxyPerson,
          LOCAL_STORAGE_CUSTOM_TYPE,
          key
        );
      }
    }
    return defaultValue instanceof Function ? defaultValue() : defaultValue;
  });

  const prevKeyRef = useRef(key);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;

    const valueInLocalStorage = getFromLocalStorage(cacheKeyParams);
    if (isEqual(valueInLocalStorage, serialize(state))) {
      return;
    }
    setToLocalStorage({ ...cacheKeyParams, value: serialize(state) });
  }, [key, state, serialize, ttlInMs, cacheKeyParams]);

  return [state, setState];
};

export type ConvertQueryParamFunc = (<T>(param: string) => T) | undefined;

// A hook to save the state and initialize it from localStorage
// or a query param with the same name
export const useLocalStorageOrQueryParamState = <T>(
  key: string,
  defaultValue: T | undefined,
  convertQueryParamValue: (v: string | null) => T,
  {
    serialize = JSON.stringify,
    deserialize = JSON.parse,
    ttlInMs = undefined,
  }: LocalStorageStateOptions<T> = {}
) => {
  const [storageValue, setStorageValue] = useLocalStorageState<T>(
    key,
    defaultValue,
    { serialize, deserialize, ttlInMs }
  );

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get(key);
    if (queryParam) {
      setStorageValue(convertQueryParamValue(queryParam));
    }
  }, [key, setStorageValue, convertQueryParamValue]);

  return storageValue;
};

// A hook to get the window width and track changes
export const useWindowResize = (): number => {
  const [width, setWidth] = useState<number>(() => window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return width;
};

export const useRefDimensions = (
  myRef: React.RefObject<HTMLElement>,
  enabled: boolean | undefined = true
) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [refreshId, setRefreshId] = useState<number>(0);

  // Hack for elements that are hidden of lazily loaded
  useEffect(() => {
    // 5 Refreshes before giving up so we don't get stuck in a loop
    if (enabled && !myRef?.current && refreshId < 5) {
      setRefreshId(refreshId + 1);
    }
  }, [myRef, refreshId, enabled]);

  useEffect(() => {
    const getDimensions = () => ({
      width: (myRef && myRef?.current?.offsetWidth) || 0,
      height: (myRef && myRef?.current?.offsetHeight) || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, refreshId]);

  return dimensions;
};

export const useOrganizationSettings = (): OrganizationSettings => {
  const settings = useSelector<ReduxState, OrganizationSettings>(
    (state) => state.settings
  );
  return settings;
};

// This hook is used to get the phase special access token
// from the query params or local storage
// and check if it's valid for the current campaign
export const useCampaignSpecialAccessToken = ({
  campaignId,
  phaseType,
}: {
  campaignId?: number;
  phaseType?: string;
}): { hasValidToken: boolean; isVerifyingToken: boolean } => {
  const campaignSpecialAccessToken = useLocalStorageOrQueryParamState(
    'access_id',
    undefined,
    (value) => value,
    { ttlInMs: CAMPAIGN_PHASE_TOKEN_VALIDITY_HOURS * ONE_HOUR_IN_MILLISECONDS }
  );

  const { data, status } = useConfirmApi<{ status: string }>({
    method: 'POST',
    url: '/check-campaign-token',
    params: {
      campaign_token: campaignSpecialAccessToken,
      campaign_id: campaignId,
      phase_type: phaseType,
    },
    disabled: campaignSpecialAccessToken == null || !campaignId || !phaseType,
  });

  const hasValidToken = data?.status === 'OK';
  const isVerifyingToken = status === 'LOADING';

  return { hasValidToken, isVerifyingToken };
};
