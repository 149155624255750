import { FormattedMessage } from 'react-intl';
import { ListGroupItem } from 'reactstrap';
import React from 'react';

const PersonalObjectiveAddItem = ({ indent, onClick, value, hidden }) => (
  <ListGroupItem
    hidden={hidden}
    color="white"
    className={`objective-list--btn-item objective-list--btn-item__${
      indent + 1
    } text-muted text-truncate pe-8`}
    onClick={onClick}
  >
    {value.name ? (
      <>
        <i className="fe fe-plus me-2" />
        <span
          className="text-truncate"
          style={{
            display: 'inline-block',
            maxWidth: '400px',
            marginBottom: '-7px',
          }}
        >
          <FormattedMessage
            id="app.views.person.personal_objective_item.add_key_result_for"
            defaultMessage="Add key result for {name}"
            values={{
              name: <span className="fw-bold">{value.name}</span>,
            }}
          />
        </span>
      </>
    ) : (
      <>
        <i className="fe fe-plus me-2" />
        <FormattedMessage
          id="app.views.person.personal_objective_item.add_key_result"
          defaultMessage="Add key result..."
        />
      </>
    )}
  </ListGroupItem>
);

export default PersonalObjectiveAddItem;
