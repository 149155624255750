import {
  BREAKOUT_INFLUENCER_TENURE_THRESHOLD,
  LOW_INFLUENCER_TENURE_THRESHOLD,
} from '../../../utils/models/Takeaways';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PeopleTable from '../PeopleTable';
import PropTypes from 'prop-types';

const INITIAL_TABLE_SIZE = 10;
const MAX_TABLE_SIZE = 10;

const TripleLeaderboardCard: FC<Props> = ({
  shouldShowInfluence = true,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {props.data ? (
          <>
            <Row>
              <Col>
                {/* @ts-expect-error */}
                {props?.data?.content ? (
                  // @ts-expect-error
                  props?.data?.content
                ) : (
                  // @ts-expect-error
                  <EmptyState title={props.emptystate} />
                )}
              </Col>
            </Row>
            <Row>
              <Col className="col-md-4 ps-2 pe-0">
                <PeopleTable
                  isTripleLeaderboard={true}
                  contentId={props.contentId + '-table-breakout'}
                  nameHeader={formatMessage({
                    id: 'app.views.widgets.cards.triple_leaderboard_card.people_table.breakout_influencers.name_header',
                    defaultMessage: 'Breakout Influencers',
                  })}
                  helpPopoverContent={
                    <>
                      <h4 className="mb-1">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.breakout_influencers"
                          defaultMessage="Breakout influencers"
                        />
                      </h4>
                      <span className="text-muted">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.breakout_influencers_tenure"
                          defaultMessage="< {value} months tenure"
                          values={{
                            value: BREAKOUT_INFLUENCER_TENURE_THRESHOLD,
                          }}
                        />
                      </span>
                      <p className="pt-3">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.breakout_influencers_description"
                          defaultMessage="
                        These newcomers know how to build influence.
                      "
                        />
                      </p>
                      <div className="pb-0 mb-0">
                        <h5 className="mb-1">
                          <FormattedMessage
                            id="app.views.widgets.cards.triple_leaderboard_card.people_table.breakout_influencers_recommended_action"
                            defaultMessage="Recommended action"
                          />
                        </h5>
                        <span>
                          <FormattedMessage
                            id="app.views.widgets.cards.triple_leaderboard_card.people_table.breakout_influencers_recommended_action_description"
                            defaultMessage="
                          Investigate how these people onboarded. There are
                          likely opportunities to replicate their experience for
                          future new team members.
                        "
                          />
                        </span>
                      </div>
                    </>
                  }
                  helpPopoverContentId="breakout-influencers-help"
                  initialSize={INITIAL_TABLE_SIZE}
                  maxSize={MAX_TABLE_SIZE}
                  fields={[
                    'full_name',
                    'tenure',
                    'influence',
                    'gold_stars',
                    'heads_ups',
                  ]}
                  // @ts-expect-error
                  people={props?.data?.breakout_influencers}
                  shouldShowInfluence={shouldShowInfluence}
                />
              </Col>
              <Col className="col-md-4 ps-4 pe-4">
                <PeopleTable
                  isTripleLeaderboard={true}
                  contentId={props.contentId + '-table-super'}
                  nameHeader={formatMessage({
                    id: 'app.views.widgets.cards.triple_leaderboard_card.people_table.super_influencers.name_header',
                    defaultMessage: 'Super influencers',
                  })}
                  helpPopoverContent={
                    <>
                      <h4 className="mb-1">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.super_influencers"
                          defaultMessage="Super-influencers"
                        />
                      </h4>
                      <span className="text-muted">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.super_influencers_tenure"
                          defaultMessage="All tenures"
                        />
                      </span>
                      <p className="pt-3">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.super_influencers_description"
                          defaultMessage="
                        These key influencers directly influence most of your
                        organization.
                      "
                        />
                      </p>
                      <div className="pb-0 mb-0">
                        <h5 className="mb-1">
                          <FormattedMessage
                            id="app.views.widgets.cards.triple_leaderboard_card.people_table.super_influencers_recommended_action"
                            defaultMessage="Recommended action"
                          />
                        </h5>
                        <span>
                          <FormattedMessage
                            id="app.views.widgets.cards.triple_leaderboard_card.people_table.super_influencers_recommended_action_description"
                            defaultMessage="
                          Give these people visibility into level initiatives to
                          ensure their influence is constructively channeled to
                          drive business impact.
                        "
                          />
                        </span>
                      </div>
                    </>
                  }
                  helpPopoverContentId="super-influencers-help"
                  initialSize={INITIAL_TABLE_SIZE}
                  maxSize={MAX_TABLE_SIZE}
                  fields={[
                    'full_name',
                    'tenure',
                    'influence',
                    'gold_stars',
                    'heads_ups',
                  ]}
                  // @ts-expect-error
                  people={props?.data?.super_influencers}
                  shouldShowInfluence={shouldShowInfluence}
                />
              </Col>
              <Col className="col-md-4 ps-0 pe-2">
                <PeopleTable
                  isTripleLeaderboard={true}
                  contentId={props.contentId + '-table-below'}
                  nameHeader={formatMessage({
                    id: 'app.views.widgets.cards.triple_leaderboard_card.people_table.below_potential.name_header',
                    defaultMessage: 'Below potential',
                  })}
                  helpPopoverContent={
                    <>
                      <h4 className="mb-1">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.below_potential"
                          defaultMessage="Below potential influence"
                        />
                      </h4>
                      <span className="text-muted">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.below_potential_tenure"
                          defaultMessage="{value}+ years tenure"
                          values={{
                            value: Math.round(
                              LOW_INFLUENCER_TENURE_THRESHOLD / 12
                            ),
                          }}
                        />
                      </span>
                      <p className="pt-3">
                        <FormattedMessage
                          id="app.views.widgets.cards.triple_leaderboard_card.people_table.below_potential_description"
                          defaultMessage="
                        These tenured folks are rarely sought for help, advice,
                        or energy.
                      "
                        />
                      </p>
                      <div className="pb-0 mb-0">
                        <h5 className="mb-1">
                          <FormattedMessage
                            id="app.views.widgets.cards.triple_leaderboard_card.people_table.below_potential_recommended_action"
                            defaultMessage="Recommended action"
                          />
                        </h5>
                        <span>
                          <FormattedMessage
                            id="app.views.widgets.cards.triple_leaderboard_card.people_table.below_potential_recommended_action_description"
                            defaultMessage="
                          Low influence may be a sign of lack of visibility,
                          participation, or an indicator of bias. It is worth
                          probing deeper to understand which.
                        "
                          />
                        </span>
                      </div>
                    </>
                  }
                  helpPopoverContentId="below-potential-help"
                  initialSize={INITIAL_TABLE_SIZE}
                  maxSize={MAX_TABLE_SIZE}
                  fields={[
                    'full_name',
                    'tenure',
                    'influence',
                    'gold_stars',
                    'heads_ups',
                  ]}
                  // @ts-expect-error
                  people={props?.data?.low_influencers}
                  shouldShowInfluence={shouldShowInfluence}
                />
              </Col>
            </Row>
          </>
        ) : (
          // @ts-expect-error
          <EmptyState title={props.emptyStateText} />
        )}
      </CardBody>
    </Card>
  );
};

const TripleLeaderboardCard_propTypes = {
  contentId: PropTypes.string.isRequired,
  data: PropTypes.object,
  className: PropTypes.object,
  emptyStateText: PropTypes.string,
  shouldShowInfluence: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
};

type Props = PropTypes.InferProps<typeof TripleLeaderboardCard_propTypes>;

export default React.memo(TripleLeaderboardCard);
