import { Col, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  Fragment,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getEmojiDataFromNative, init } from 'emoji-mart';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import Picker from '@emoji-mart/react';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { configForLocale } from '../../locale/messages';
import { connect } from 'react-redux';
// Fix to versin 13 since the newer versions are not well supported
// by all browsers and the backend is not ready to support the encoding
import data from '@emoji-mart/data/sets/13/apple.json';
import { peopleIdsAreEqual } from '../../utils/models/Person';
import { toast } from 'react-toastify';

const EMOJI_STYLE = 'apple';

init({ data });

// @ts-expect-error
const ReactionPopover: FC = ({ index, r, meId }) => {
  const { formatMessage } = useIntl();
  const [emojiData, setEmojiData] = useState(undefined);

  useEffect(() => {
    getEmojiDataFromNative(r.emoji).then((data) => {
      setEmojiData(data);
    });
  }, [r]);
  if (emojiData) {
    return (
      <Fragment key={index}>
        <UncontrolledPopover trigger="hover" placement="top" target={r.ref}>
          <PopoverBody>
            <Row className="justify-content-center">
              <Col className="col-auto">
                {/* @ts-expect-error */}
                <em-emoji
                  set={EMOJI_STYLE}
                  // @ts-expect-error
                  id={emojiData.id}
                  // @ts-expect-error
                  skin={emojiData.skin || 1}
                  size={32}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <span
                  className="text-normal fw-bold"
                  style={{ color: '#12263F' }}
                >
                  <FormattedList
                    value={r.people
                      .filter((x) => !!x) // remove nulls
                      .map((p, i) =>
                        peopleIdsAreEqual(p.id, meId)
                          ? i === 0
                            ? formatMessage({
                                id: 'app.views.widgets.emoji_bar.reacted.you_capital',
                                defaultMessage: 'You',
                              })
                            : formatMessage({
                                id: 'app.views.widgets.emoji_bar.reacted.you',
                                defaultMessage: 'you',
                              })
                          : p.full_name
                      )}
                  />
                </span>
                <FormattedMessage
                  id="app.views.widgets.emoji_bar.reacted"
                  defaultMessage="<span> reacted with {emoji}</span>"
                  values={{
                    span: (chunks) => (
                      <span className="text-muted">{chunks}</span>
                    ),
                    // @ts-expect-error
                    emoji: emojiData.colons,
                  }}
                />
              </Col>
            </Row>
          </PopoverBody>
        </UncontrolledPopover>
      </Fragment>
    );
  }
};

const Reaction = ({
  objectId,
  contentType,
  r,
  index,
  toggleEmoji,
  isSmall,
}) => {
  const uniqueId = 'emoji-' + contentType + '-' + objectId + '-' + r.emoji;

  const [emojiData, setEmojiData] = useState(undefined);

  useEffect(() => {
    getEmojiDataFromNative(r.emoji).then((data) => {
      setEmojiData(data);
    });
  }, [r]);

  if (emojiData) {
    return (
      <Fragment key={index}>
        <li
          id={uniqueId}
          ref={r.ref}
          className={r.clicked ? 'reaction-bar-added' : ''}
          onClick={() => toggleEmoji(r.emoji)}
        >
          {/* @ts-expect-error */}
          <em-emoji
            set={EMOJI_STYLE}
            // @ts-expect-error
            id={emojiData.id}
            // @ts-expect-error
            skin={emojiData.skin || 1}
            size={isSmall ? 14 : 20}
          />
          <span className="ms-1 reaction-count">{r.count}</span>
        </li>
      </Fragment>
    );
  }
};

const EmojiBar: FC<Props> = ({
  isSmall = false,
  reactions: propsReactions = [],
  ...props
}) => {
  const { formatMessage, locale } = useIntl();

  const [reactions, setReactions] = useState(propsReactions);
  const propsContentType = props.contentType;
  const propsObjectId = props.objectId;
  const propsUpdateReactions = props.updateReactions;

  useEffect(() => {
    setReactions(propsReactions);
  }, [propsReactions]);

  const sendReactionToServer = useCallback(
    (emoji, isDelete = false) => {
      ConfirmAPI.sendRequestToConfirm(
        isDelete ? 'DELETE' : 'POST',
        '/reactions',
        {
          content_type: propsContentType,
          object_id: propsObjectId,
          emoji: emoji,
        },
        (response, error, hardErrorMessage = null) => {
          if (error) {
            // surface error to the user
            console.error('Reaction failed: ' + JSON.stringify(error));
            if (hardErrorMessage) {
              toast.error(
                formatMessage(
                  {
                    id: 'app.views.widgets.emoji_bar.reaction_failed',
                    defaultMessage: 'Reaction failed: {message}',
                  },
                  { message: hardErrorMessage }
                )
              );
            } else {
              toast.error(
                formatMessage({
                  id: 'app.views.widgets.emoji_bar.reaction_failed_default',
                  defaultMessage:
                    'Reaction failed. If this keeps happening, please contact customer support.',
                })
              );
            }
          } else {
            if (propsUpdateReactions) {
              propsUpdateReactions(reactions);
            }
          }
        },
        null
      );
    },
    [
      propsContentType,
      propsObjectId,
      propsUpdateReactions,
      reactions,
      formatMessage,
    ]
  );

  const toggleEmoji = useCallback(
    (nativeEmoji) => {
      // @ts-expect-error
      const matchingReaction = reactions.find(
        (r) =>
          // @ts-expect-error
          r.emoji === nativeEmoji &&
          // @ts-expect-error
          peopleIdsAreEqual(r.author_person?.id, props.meId)
      );

      if (matchingReaction) {
        // remove from list
        // @ts-expect-error
        setReactions(reactions.filter((r) => r !== matchingReaction));

        // send reaction deletion to server
        sendReactionToServer(nativeEmoji, true);
      } else {
        // add to list
        setReactions([
          // @ts-expect-error
          ...reactions,
          {
            emoji: nativeEmoji,
            author_person: {
              id: props.meId,
              given_name: props.meGivenName,
              family_name: props.meFamilyName,
            },
          },
        ]);

        // send reaction creation to server
        sendReactionToServer(nativeEmoji, false);
      }
    },
    [
      props.meFamilyName,
      props.meGivenName,
      props.meId,
      reactions,
      sendReactionToServer,
    ]
  );

  const aggregatedReactions = useMemo(() => {
    // @ts-expect-error
    return reactions.reduce((arr, r) => {
      // @ts-expect-error
      const i = arr && arr.findIndex((r2) => r2.emoji === r.emoji);
      // @ts-expect-error
      const clicked = peopleIdsAreEqual(r.author_person?.id, props.meId);

      if (i === -1) {
        // first instance of this reaction
        // @ts-expect-error
        arr.push({
          // @ts-expect-error
          emoji: r.emoji,
          count: 1,
          // @ts-expect-error
          people: [r.author_person],
          clicked: clicked,
          ref: createRef(),
        });
      } else {
        // ensure you are the last on the list
        // @ts-expect-error
        const lastPerson = arr[i].people[arr[i].count - 1];
        const iAmLast = peopleIdsAreEqual(lastPerson.id, props.meId);
        const newPeople = iAmLast
          ? // @ts-expect-error
            [...arr[i].people.slice(0, -1), r.author_person, lastPerson]
          : // @ts-expect-error
            [...arr[i].people, r.author_person];

        // existing reaction so increment count and updated people and clicked
        // @ts-expect-error
        arr[i] = {
          // @ts-expect-error
          emoji: r.emoji,
          // @ts-expect-error
          count: arr[i].count + 1,
          people: newPeople,
          // @ts-expect-error
          clicked: arr[i].clicked || clicked,
          // @ts-expect-error
          ref: arr[i].ref,
        };
      }

      return arr;
    }, []);
  }, [reactions, props.meId]);

  const userHasReacted = useMemo(() => {
    // @ts-expect-error
    if (!(reactions?.length > 0)) {
      return false;
    }

    return (
      reactions &&
      reactions?.findIndex((r) =>
        // @ts-expect-error
        peopleIdsAreEqual(r.author_person?.id, props.meId)
      ) !== -1
    );
  }, [props.meId, reactions]);

  const uniqueLikeId =
    'emoji-1-' + props.contentType + '-' + props.objectId + '-like-link';

  return (
    <div
      className={
        'action-container small text-muted' +
        (props.className ? ' ' + props.className : '')
      }
    >
      {!props.showIconsOnly && (
        <span
          onClick={() => toggleEmoji('👍')}
          id={uniqueLikeId}
          role="button"
          className={'me-2' + (userHasReacted ? ' text-primary' : '')}
        >
          <FormattedMessage
            id="app.views.widgets.emoji_bar.like"
            defaultMessage="
          Like
        "
          />
        </span>
      )}
      {/* @ts-expect-error */}
      {(reactions?.length > 0 || props.showIconsOnly) && (
        <ul
          className={
            'd-inline-block reaction-bar' +
            (isSmall ? ' reaction-bar-small' : '')
          }
        >
          {props.showIconsOnly && (
            <li
              className={
                'like-button' + (userHasReacted ? ' like-button-active' : '')
              }
              onClick={() => toggleEmoji('👍')}
              id={uniqueLikeId}
            >
              <span className="fe fe-thumbs-up me-2"></span>
              <FormattedMessage
                id="app.views.widgets.emoji_bar.like"
                defaultMessage="Like
            "
              />
            </li>
          )}
          {/* @ts-expect-error */}
          {aggregatedReactions.map((r, index) => (
            // @ts-expect-error
            <Reaction
              key={index}
              {...props}
              index={index}
              r={r}
              toggleEmoji={toggleEmoji}
            />
          ))}
        </ul>
      )}
      <UncontrolledPopover
        popperClassName="emoji-popover"
        hideArrow={true}
        delay={50}
        trigger={'hover'}
        placement="top"
        target={uniqueLikeId}
      >
        <Picker
          i18n={configForLocale(locale).emojiBar}
          emoji=""
          title={formatMessage({
            id: 'app.views.widgets.emoji_bar.title.change_skin_tone',
            defaultMessage: 'Change skin tone',
          })}
          data={data}
          set={EMOJI_STYLE}
          onEmojiSelect={(emojiObject) => toggleEmoji(emojiObject.native)}
        />
      </UncontrolledPopover>
      {props.showCommentModal && (
        <>
          <span className="me-2">{'∙'}</span>
          <span role="button" onClick={props.showCommentModal}>
            <FormattedMessage
              id="app.views.widgets.emoji_bar.comment"
              defaultMessage="
            Comment
          "
            />
          </span>
        </>
      )}
      {props.showFeedbackModal && (
        <>
          <span className="mx-2">{'∙'}</span>
          <span role="button" onClick={props.showFeedbackModal}>
            <FormattedMessage
              id="app.views.widgets.emoji_bar.add_feeedback_or_recognition"
              defaultMessage="
            Add feedback or recognition
          "
            />
          </span>
        </>
      )}
      {/* @ts-expect-error */}
      {props.emptyStatePromptText && !(reactions?.length > 0) && (
        <span
          className="small text-muted align-middle"
          style={{ position: 'relative', top: '6px' }}
        >
          {!props.showIconsOnly && <span className="mx-2">{'∙'}</span>}
          {props.emptyStatePromptText}
        </span>
      )}
      {/* @ts-expect-error */}
      {aggregatedReactions.map((r, index) => (
        // @ts-expect-error
        <ReactionPopover key={index} index={index} r={r} meId={props.meId} />
      ))}
    </div>
  );
};

const EmojiBar_propTypes = {
  showIconsOnly: PropTypes.bool,
  contentType: PropTypes.string.isRequired,
  objectId: PropTypes.string.isRequired,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  updateReactions: PropTypes.func,
  // each reaction is an object with emoji (unicode emoji), count (int),
  // and clicked (bool for user has clicked)
  reactions: PropTypes.arrayOf(PropTypes.object),
  meId: PropTypes.number.isRequired,
  meGivenName: PropTypes.string.isRequired,
  meFamilyName: PropTypes.string.isRequired,
  emptyStatePromptText: PropTypes.string,
  showCommentModal: PropTypes.func,
  showFeedbackModal: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof EmojiBar_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { me, features } = state;

  return {
    meId: me?.id,
    meGivenName: me?.given_name,
    meFamilyName: me?.family_name,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(EmojiBar));
