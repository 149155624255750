import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useState } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import EmptyState from '../Widgets/EmptyState';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import ModalPersonProfileEditButton from '../Widgets/Modals/ModalPersonProfileEditButton';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';

export const operatingManualSections = (formatMessage) => [
  {
    section: formatMessage({
      id: 'app.views.person.person_operating_manual.section.scheduling',
      defaultMessage: 'Scheduling',
    }),
    content: [
      {
        name: 'ideal_schedule',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_schedule.label',
          defaultMessage: 'How best to schedule me',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_schedule.helper_text',
          defaultMessage: 'How best should people schedule time with you?',
        }),
        options: [
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_schedule.options_1',
            defaultMessage:
              "Send me a meeting invitation for whenever I'm available.",
          }),
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_schedule.options_2',
            defaultMessage: "Ask me first and we'll find a time to meet.",
          }),
        ],
      },
      {
        name: 'ideal_booking',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_booking.label',
          defaultMessage: 'When booking meetings',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_booking.helper_text',
          defaultMessage:
            'What would you like people to do when booking meetings with you?',
        }),
        options: [
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_schedule.option_1',
            defaultMessage:
              'Share an agenda with me 24 hours in advance so I can review it.',
          }),
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_schedule.option_2',
            defaultMessage:
              "I don't need an agenda, but have a sense of how you'd like to use our time.",
          }),
        ],
      },
      {
        name: 'calendar_obedience',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.calendar_obedience.label',
          defaultMessage: 'Calendar obedience and tardiness',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.calendar_obedience.helper_text',
          defaultMessage:
            'How rigorously do you follow your own calendar and how on-time are you usually?',
        }),
        options: [
          formatMessage({
            id: 'app.views.person.person_operating_manual.calendar_obedience.option_1',
            defaultMessage: 'I follow my calendar to the minute.',
          }),
          formatMessage({
            id: 'app.views.person.person_operating_manual.calendar_obedience.option_2',
            defaultMessage:
              "I'm sometimes a few minutes late to meetings. Sorry about that!",
          }),
        ],
      },
    ],
  },
  {
    section: formatMessage({
      id: 'app.views.person.person_operating_manual.section.communication_and_influence',
      defaultMessage: 'Communication & Influence',
    }),
    content: [
      {
        name: 'ideal_communication',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_communication.label',
          defaultMessage: 'How best to communicate with me',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_communication.helper_text',
          defaultMessage: 'How would you like people to communicate with you?',
        }),
        options: [
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_communication.option_1',
            defaultMessage:
              'Message me when I am free, and I will reply when I can.',
          }),
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_communication.option_2',
            defaultMessage:
              'I prefer verbal conversations and will answer calls if I am available.',
          }),
        ],
      },
      {
        name: 'ideal_feedback',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_feedback.label',
          defaultMessage: 'Giving & receiving feedback',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_feedback.helper_text',
          defaultMessage: 'How do you prefer to give and receive feedback?',
        }),
        options: [
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_feedback.option_1',
            defaultMessage:
              'I prefer when feedback is straight and to the point.',
          }),
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_feedback.option_2',
            defaultMessage:
              'I prefer to exchange feedback as part of a longer conversation.',
          }),
        ],
      },
      {
        name: 'ideal_influence',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_influence.label',
          defaultMessage: 'How to influence me',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_influence.helper_text',
          defaultMessage: 'What is the best way for people to influence you?',
        }),
        options: [
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_influence.option_1',
            defaultMessage: 'Tell me what you need and why it matters.',
          }),
          formatMessage({
            id: 'app.views.person.person_operating_manual.ideal_influence.option_2',
            defaultMessage: 'Keep my priorities in context of what you need.',
          }),
        ],
      },
    ],
  },
  {
    section: formatMessage({
      id: 'app.views.person.person_operating_manual.section.environments',
      defaultMessage: 'Environments',
    }),
    content: [
      {
        name: 'preferred_environments',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.preferred_environments.label',
          defaultMessage: 'Preferred environments',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.preferred_environments.helper_text',
          defaultMessage:
            'What are the preferred environments in which you prefer to work?',
        }),
        options: [
          formatMessage({
            id: 'app.views.person.person_operating_manual.preferred_environments.option_1',
            defaultMessage: 'I like organized, quiet atmospheres.',
          }),
          formatMessage({
            id: 'app.views.person.person_operating_manual.preferred_environments.option_2',
            defaultMessage: 'I welcome a dynamic, interactive environment.',
          }),
        ],
      },
      {
        name: 'energizers',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.energizers.label',
          defaultMessage: 'What energizes me',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.energizers.helper_text',
          defaultMessage:
            'What are the things you love/enjoy/are passionate about/get energy from?',
        }),
      },
      {
        name: 'ideal_environment',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_environment.label',
          defaultMessage: 'When/how I work best',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.ideal_environment.helper_text',
          defaultMessage: 'When and/or how do you work best?',
        }),
      },
    ],
  },
  {
    section: formatMessage({
      id: 'app.views.person.person_operating_manual.section.values_and_motivations',
      defaultMessage: 'Values & Motivations',
    }),
    content: [
      {
        name: 'core_values',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.core_values.label',
          defaultMessage: 'Core values',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.core_values.helper_text',
          defaultMessage: 'What are the core values most important to you?',
        }),
      },
      {
        name: 'valued_qualities',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.valued_qualities.label',
          defaultMessage: 'Valued qualities in others',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.valued_qualities.helper_text',
          defaultMessage:
            'What qualities do you particularly value in people who work with you?',
        }),
      },
      {
        name: 'values_outside_work',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.values_outside_work.label',
          defaultMessage: 'Values outside work',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.values_outside_work.helper_text',
          defaultMessage: 'Outside of work, what is most important to you?',
        }),
      },
    ],
  },
  {
    section: formatMessage({
      id: 'app.views.person.person_operating_manual.section.personality',
      defaultMessage: 'Personality',
    }),
    content: [
      {
        name: 'working_style',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.label.personality_assessment',
          defaultMessage: 'Personality assessment or working style',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.helper_text.personality_assessment',
          defaultMessage:
            'If you have done DiSC, Myers-Briggs, or other personality assessments, what were their results? Or, how would you describe your personality or working style when things are going well? Do you like to work independently? Thrive in social settings? Prefer mornings or evenings?',
        }),
      },
      {
        name: 'triggers_and_stress',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.label.triggers_and_stress',
          defaultMessage: 'Triggers & stress',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.helper_text.triggers_and_stress',
          defaultMessage:
            'What are your pet peeves or triggers that others should avoid, and how do you handle stress?',
        }),
      },
      {
        name: 'life_outside_work',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.label.life_outside_work',
          defaultMessage: 'Life outside work',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.helper_text.life_outside_work',
          defaultMessage:
            'What are your unique non-work-related skills/expertise/interests?',
        }),
      },
    ],
  },
  {
    section: formatMessage({
      id: 'app.views.person.person_operating_manual.section.area_of_growth_and_fyis',
      defaultMessage: "Areas of growth & FYI's",
    }),
    content: [
      {
        name: 'quirks_and_misunderstandings',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.label.quirks_and_misunderstandings',
          defaultMessage: 'Quirks & misunderstandings',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.helper_text.quirks_and_misunderstandings',
          defaultMessage:
            'What are your quirks and/or what do people misunderstand about you?',
        }),
      },
      {
        name: 'supporting_me',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.label.supporting_me',
          defaultMessage: 'Supporting me',
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.helper_text.supporting_me',
          defaultMessage: 'How can people help and/or support you at work?',
        }),
      },
      {
        name: 'fyis',
        label: formatMessage({
          id: 'app.views.person.person_operating_manual.label.fyis',
          defaultMessage: "FYI's",
        }),
        helperText: formatMessage({
          id: 'app.views.person.person_operating_manual.helper_text.fyis',
          defaultMessage:
            "What FYI's do you want people to know about you, e.g. allergies, unusual habits, things to be aware of so people are sensitive to them, etc.?",
        }),
      },
    ],
  },
];

const PersonOperatingManual: FC<Props> = ({
  operatingManual = {},
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [clickedSection, setClickedSection] = useState(undefined);
  const isMe = props.isMe;

  const operatingManualSectionsWithContent = operatingManualSections(
    formatMessage
  ).filter(
    (item) =>
      isMe ||
      (item?.content &&
        item.content.findIndex((c) => operatingManual[c.name]) !== -1)
  );

  const emptyHouseContentElement = (
    <span className="fst-italic">
      <FormattedMessage
        id="app.views.person.person_operating_manual.not_provided"
        defaultMessage="Not provided"
      />
    </span>
  );

  const onClosed = () => {
    setClickedSection(undefined);
  };

  return (
    <>
      {operatingManualSectionsWithContent.length === 0 && (
        <EmptyState
          title={formatMessage({
            id: 'app.views.person.person_operating_manual.title.no_operating_manual',
            defaultMessage: 'No Operating Manual',
          })}
          subtitle={formatMessage(
            {
              id: 'app.views.person.person_operating_manual.subtitle.no_operating_manual',
              defaultMessage:
                '{fullName} has not answered any Operating Manual questions yet.',
            },
            { fullName: props.fullName }
          )}
        />
      )}
      {operatingManualSectionsWithContent.map((item, itemIndex) => (
        <div key={itemIndex} className={itemIndex === 0 ? '' : 'mt-5'}>
          {itemIndex !== 0 && <hr className="mb-5" />}
          <Row>
            <Col className="col-auto">
              <h2>{item.section}</h2>
            </Col>
            <Col>
              {isMe && (
                <ModalPersonProfileEditButton
                  className="p-0"
                  isOpen={clickedSection === itemIndex}
                  onClosed={onClosed}
                  title={item.section}
                  transformObjectBeforeSubmit={(object) => {
                    return {
                      profile: {
                        operating_manual: object,
                      },
                    };
                  }}
                  inputs={item.content.map((c) => ({
                    name: c.name,
                    title: c.options ? c.helperText : undefined,
                    label: c.options ? undefined : c.label,
                    helperText: c.options ? undefined : c.helperText,
                    value: operatingManual[c.name],
                    options: c.options,
                    allowCustom: true,
                    type: c.options
                      ? INPUT_TYPES.MULTIPLE_CHOICE
                      : INPUT_TYPES.TEXTAREA,
                  }))}
                  successCallback={props.setPerson}
                />
              )}
            </Col>
          </Row>
          <Row>
            {item.content.map(
              (contentItem, contentItemIndex) =>
                (isMe || operatingManual[contentItem.name]) && (
                  <Col key={contentItemIndex} md={4}>
                    <Card
                      role={isMe ? 'button' : undefined}
                      onClick={
                        // @ts-expect-error
                        isMe ? () => setClickedSection(itemIndex) : undefined
                      }
                    >
                      <CardHeader>
                        <CardHeaderTitle>
                          {contentItem.label
                            ? contentItem.label
                            : // @ts-expect-error
                              contentItem.question}
                        </CardHeaderTitle>
                      </CardHeader>
                      <CardBody>
                        {!operatingManual[contentItem.name] &&
                          emptyHouseContentElement}
                        {operatingManual[contentItem.name] && (
                          <p style={{ whiteSpace: 'pre-wrap' }}>
                            {operatingManual[contentItem.name]}
                          </p>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                )
            )}
          </Row>
        </div>
      ))}
    </>
  );
};

const PersonOperatingManual_propTypes = {
  isMe: PropTypes.bool.isRequired,
  setPerson: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  operatingManual: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<typeof PersonOperatingManual_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { features } = state;

  return {
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PersonOperatingManual));
