import React, { useMemo, FC, ReactNode } from 'react';

import RichTextViewer from './Inputs/RichTextViewer';

interface Props {
  description?: ReactNode | string;
  className?: string;
}

const TaskDescription: FC<Props> = ({ description, className }: Props) => {
  const output = useMemo(() => {
    if (typeof description === 'object') {
      return <span className={className}>{description}</span>;
    } else {
      return (
        <span className={className}>
          <RichTextViewer model={description as string} expanded={true} />
        </span>
      );
    }
  }, [description, className]);

  return output;
};

export default React.memo(TaskDescription);
