import { Col, Row } from 'reactstrap';
import React, { useCallback } from 'react';

import BreakdownChartCard from '../../Cards/BreakdownChartCard';
import CandlestickChartCard from '../../Cards/CandlestickChartCard';
import ChordGraphCard from '../../Cards/ChordGraphCard';
import Container from '../../Cards/Container';
import DistributionInfoCard from '../../Cards/DistributionInfoCard';
import EmployeeNPSResultsCard from '../../Cards/EmployeeNPSResultsCard';
import EmptyState from '../../EmptyState';
import EngagementSurveySummaryCard from '../../../EngagementSurvey/EngagementSurveySummaryCard';
import GenericDoughnutCard from '../../Cards/GenericDoughnutCard';
import GenericTableCard from '../../Cards/GenericTableCard';
import LeaderboardCard from '../../Cards/LeaderboardCard';
import MultichartCard from '../../Cards/MultichartCard';
import NetworkGraphCard from '../../Cards/NetworkGraphCard';
import PowerLawCard from '../../Cards/PowerLawCard';
import ProgressChartCard from '../../Cards/ProgressChartCard';
import ProgressTableCard from '../../Cards/ProgressTableCard';
import PropTypes from 'prop-types';
import SankeyGraphCard from '../../Cards/SankeyGraphCard';
import ScatterPlotCard from '../../Cards/ScatterPlotCard';
import SimpleCard from '../../Cards/SimpleCard';
import SkillCloudsCard from '../../Cards/SkillCloudsCard';
import TripleLeaderboardCard from '../../Cards/TripleLeaderboardCard';
import { useIntl } from 'react-intl';

export const CARD_TYPES = {
  PROGRESS_CHART: 0,
  PROGRESS_TABLE: 1,
  DISTRIBUTION_INFO: 2,
  SIMPLE_CARD: 3,
  BREAKDOWN_CHART: 4,
  LEADERBOARD_CARD: 5,
  SKILL_CLOUDS_CARD: 6,
  SCATTER_PLOT: 7,
  HEADER: 8,
  POWER_LAW_CARD: 9,
  CANDLESTICK_CHART: 10,
  TRIPLE_LEADERBOARD_CARD: 11,
  GENERIC_TABLE: 12,
  GENERIC_DOUGHNUT: 13,
  CHORD: 14,
  SANKEY: 15,
  NETWORK: 16,
  HORIZONTAL_BAR: 17,
  MULTICHART: 18,
  EMPLOYEE_NPS: 19,
  ENGAGEMENT_SURVEY_SUMMARY: 20,
  CONTAINER: 21,
};

const getCounters = (data, key) => {
  if (!data || !key) {
    return undefined;
  }
  key.split('.').forEach((k) => {
    if (k in data) {
      data = data[k];
    } else {
      return undefined;
    }
  });
  return data;
};

const CardRowDashboard = (props) => {
  const { formatMessage } = useIntl();
  const outputToCard = useCallback(
    (output) =>
      output.type === CARD_TYPES.PROGRESS_TABLE ? (
        <ProgressTableCard {...output} data={props.data[output.dataKey]} />
      ) : output.type === CARD_TYPES.PROGRESS_CHART ? (
        <ProgressChartCard {...output} data={props.data[output.dataKey]} />
      ) : output.type === CARD_TYPES.DISTRIBUTION_INFO ? (
        <DistributionInfoCard
          {...output}
          counters={getCounters(props.data, output.dataKey)}
          baseline={getCounters(props.data, output.baselineKey)}
        />
      ) : output.type === CARD_TYPES.SIMPLE_CARD ? (
        <SimpleCard {...output}>{props.data[output.datakey]}</SimpleCard>
      ) : output.type === CARD_TYPES.BREAKDOWN_CHART ? (
        <BreakdownChartCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.LEADERBOARD_CARD ? (
        <LeaderboardCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.SKILL_CLOUDS_CARD ? (
        <SkillCloudsCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.SCATTER_PLOT ? (
        <ScatterPlotCard
          {...output}
          data={props.data[output.datakey]}
          showMetric={false}
          showField={false}
        />
      ) : output.type === CARD_TYPES.HEADER ? (
        <div {...output}>{props.data[output.datakey]}</div>
      ) : output.type === CARD_TYPES.POWER_LAW_CARD ? (
        <PowerLawCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.CHORD ? (
        <ChordGraphCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.SANKEY ? (
        <SankeyGraphCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.NETWORK ? (
        <NetworkGraphCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.CANDLESTICK_CHART ? (
        <CandlestickChartCard
          {...output}
          data={props.data[output.datakey]}
          showMetric={false}
        />
      ) : output.type === CARD_TYPES.TRIPLE_LEADERBOARD_CARD ? (
        <TripleLeaderboardCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.GENERIC_TABLE ? (
        <GenericTableCard {...output} rows={props.data[output.dataKey]} />
      ) : output.type === CARD_TYPES.GENERIC_DOUGHNUT ? (
        <GenericDoughnutCard {...output} data={props.data[output.dataKey]} />
      ) : output.type === CARD_TYPES.MULTICHART ? (
        <MultichartCard {...output} data={output?.content} />
      ) : output.type === CARD_TYPES.EMPLOYEE_NPS ? (
        <EmployeeNPSResultsCard {...output} data={props.data[output.datakey]} />
      ) : output.type === CARD_TYPES.ENGAGEMENT_SURVEY_SUMMARY ? (
        <EngagementSurveySummaryCard {...output} summary={output?.summary} />
      ) : output.type === CARD_TYPES.CONTAINER ? (
        <Container>{props.data[output.datakey]}</Container>
      ) : (
        <EmptyState
          title={formatMessage({
            id: 'app.views.widgets.dashboards.card_row_dashboard.title.unsupported_card_type',
            defaultMessage: 'Unsupported card type.',
          })}
        />
      ),
    [props.data, formatMessage]
  );

  return props.data !== undefined ? (
    <>
      {props.beforeContent}
      {props.rows.map((row, index) => (
        <Row xs="1" md="12" key={index}>
          {row.map(
            (output, output_index) =>
              output?.skip !== 'true' && (
                <Col
                  className={`col-12 col-md-${12 / row.length}`}
                  key={output_index}
                >
                  {outputToCard(output)}
                </Col>
              )
          )}
        </Row>
      ))}
    </>
  ) : (
    <EmptyState title={props.emptyStateText} />
  );
};

CardRowDashboard.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  data: PropTypes.object,
  title: PropTypes.string,
  pretitle: PropTypes.string,
  underTitle: PropTypes.object,
  mainActionElement: PropTypes.node,
  beforeContent: PropTypes.node,
  emptyStateText: PropTypes.string,
};

CardRowDashboard.defaultProps = {
  pretitle: 'Dashboard',
  title: '',
};

export default React.memo(CardRowDashboard);
