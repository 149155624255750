import * as React from 'react';

import { CampaignEditorPhaseContextType } from 'types';

const CampaignEditorPhaseContext =
  React.createContext<CampaignEditorPhaseContextType | null>(null);

const CampaignEditorPhaseProvider = ({ phaseType, campaignId, children }) => {
  const value: CampaignEditorPhaseContextType = React.useMemo(
    () => ({
      phaseType,
      campaignId,
    }),
    [phaseType, campaignId]
  );

  return (
    <CampaignEditorPhaseContext.Provider value={value}>
      {children}
    </CampaignEditorPhaseContext.Provider>
  );
};

const useCampaignEditorPhase = (): CampaignEditorPhaseContextType | null => {
  return React.useContext(CampaignEditorPhaseContext);
};

export { CampaignEditorPhaseProvider, useCampaignEditorPhase };
