import {
  CATCHALL_GROUP_NAME,
  calculateEnpsScore,
} from '../Dashboards/TakeawaysDashboard/util';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { EnpsComment, EnpsResponse } from '../../../apiTypes';
import EnpsCommentsCard, {
  FormattedComments,
} from '../Dashboards/TakeawaysDashboard/EnpsCommentsCard';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ObjectDropdownOption,
  OrganizationSettings,
  ReduxState,
} from '../../../types';
import React, { useRef, useState } from 'react';
import {
  createCSVFileName,
  formatSimpleDecimal,
  sortCsvRows,
} from '../../../utils/util/util';

import { CSVLink } from 'react-csv';
import CardHeaderTitle from './CardHeaderTitle';
import EnpsBar from '../Dashboards/TakeawaysDashboard/EnpsBar';
import EnpsResultTable from '../Dashboards/TakeawaysDashboard/EnpsResultTable';
import EnpsSummaryCard from '../Dashboards/TakeawaysDashboard/EnpsSummaryCard';
import { EnpsSummaryResponse } from '../Dashboards/TakeawaysDashboard/types';
import { ICONS } from '../../../consts/consts';
import ObjectsDropdown from '../Dropdowns/ObjectsDropdown';
import { connect } from 'react-redux';
import { getFilterDisplayName } from '../../../utils/models/Filters';

interface EmployeeNPSResultsCardProps {
  data: EnpsResponse;
  settings: OrganizationSettings;
}

// convert a list of enps comments to a structure of
// the form { [fieldId]: { [fieldValue]: { eligible: number, responses: string[] }}}}
function formatComments(comments: EnpsComment[]): FormattedComments {
  const output: FormattedComments = {};

  for (const comment of comments) {
    if (!comment) {
      continue;
    }
    Object.keys(comment.keys).forEach((field) => {
      if (!output[field]) {
        output[field] = {};
      }

      const value = comment.keys?.[field] ?? CATCHALL_GROUP_NAME;
      if (!output[field][value]) {
        output[field][value] = { eligible: 0, responses: [] };
      }

      output[field][value].eligible++;
      output[field][value].responses.push(comment.comment);
    });
  }

  return output;
}

const EmployeeNPSResultsCard: React.FC<EmployeeNPSResultsCardProps> = ({
  settings,
  data,
}) => {
  const { formatMessage } = useIntl();
  const [filterBy, setFilterBy] = useState<string>('overall');

  // Localize headings for browser and CSV export
  const commentsHeading = formatMessage({
    id: 'app.views.widgets.cards.employee_nps_results.question_title',
    defaultMessage: 'All eNPS comments',
  });

  const getDisplayName = (fieldName: string) =>
    getFilterDisplayName(
      data.fields.find((f) => f.name === fieldName)?.name ?? 'overall',
      formatMessage
    );

  const getFieldDisplayName = (field: string) => getDisplayName(field);

  const filterDisplayName = getDisplayName(filterBy);

  const commentsHeadingByGroup = formatMessage(
    {
      id: 'app.views.widgets.cards.employee_nps_results.question_title_by_group',
      defaultMessage: 'eNPS comments by {group}',
    },
    {
      group: filterDisplayName,
    }
  );

  const tableCardRows = [
    {
      id: filterBy,
      displayName:
        filterBy === 'overall' ? commentsHeading : commentsHeadingByGroup,
      count: data.comments.filter((item) => item.comment).length,
      data: formatComments(data.comments.filter((item) => item.comment)),
    },
  ];

  const ScoreDescriptionLink = () => {
    const targetRef = useRef(null);

    return (
      <>
        <span
          className="text-primary mt-3 fs-3 position-relative"
          ref={targetRef}
          role="link"
          style={{ top: '-10px', right: '-6px' }}
        >
          <i className={ICONS.HELP + ' me-2'} />
        </span>
        <UncontrolledPopover
          data-testid="enps-explanation-popover"
          placement="right"
          trigger="hover"
          target={targetRef}
        >
          <div>
            <FormattedMessage
              id="app.views.widgets.cards.employee_nps_results.score_description"
              defaultMessage="In most organizations, an eNPS score of 20+ is considered healthy. 50+ is excellent. 80+ is outstanding and would be considered best-in-class."
            />
          </div>
        </UncontrolledPopover>
      </>
    );
  };

  const createCsvData = () => {
    const headers = [
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.field',
        defaultMessage: 'Field',
      }),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.value',
        defaultMessage: 'Value',
      }),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.enps',
        defaultMessage: 'eNPS',
      }),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.promoters',
        defaultMessage: 'Promoters',
      }),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.detractors',
        defaultMessage: 'Detractors',
      }),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.passives',
        defaultMessage: 'Passives',
      }),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.missing',
        defaultMessage: 'Missing',
      }),
    ];

    let result: (string | number)[][] = [];
    Object.keys(data.by_field).forEach((field) => {
      const rows = Object.entries(data.by_field[field]).map(
        ([fieldValue, counts]) => {
          return [
            getFieldDisplayName(field),
            fieldValue,
            formatSimpleDecimal(calculateEnpsScore(counts)),
            counts.promoter,
            counts.detractor,
            counts.passive,
            counts.missing,
          ];
        }
      );
      if (rows) {
        result = result.concat(rows);
      }
    });

    return [headers, ...sortCsvRows(result)];
  };

  const createFullCsvData = () => {
    const csvFields = data.fields.filter((field) => field.name !== 'overall');
    const headers = [
      ...csvFields.map((field) => field.display_name),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.score',
        defaultMessage: 'Score',
      }),
      formatMessage({
        id: 'app.views.widgets.cards.employee_nps_results_card.csv.comment',
        defaultMessage: 'Comment',
      }),
    ];
    const otherForCsv = formatMessage({
      id: 'app.views.widgets.cards.employee_nps_results_card.csv.other',
      defaultMessage: 'Other',
    });

    const rows = data.comments.map((comment) => [
      ...csvFields.map((field) => comment.keys[field.name] ?? otherForCsv),
      comment.score,
      comment.comment.replace(/"/g, '""'),
    ]);

    return [headers, ...sortCsvRows(rows)];
  };

  const fields = data.fields.map((field) => ({
    id: field.name,
    name: field.display_name,
  }));

  const renderItem = (o: ObjectDropdownOption) =>
    getFilterDisplayName(o.id, formatMessage);

  const filterDropdown = (
    <ObjectsDropdown
      className="d-inline-block ms-3"
      objects={fields}
      size="sm"
      onChange={(e) => {
        const match = fields.find((f) => f.id === e.target.value);
        setFilterBy(match?.id ?? 'overall');
      }}
      value={filterBy}
      renderItem={renderItem}
    />
  );

  const exportButton = (
    <CSVLink
      className="btn btn-light btn-sm"
      filename={createCSVFileName('enps_groups')}
      data={createCsvData()}
    >
      <i className={ICONS.EXPORT + ' me-2'} />
      <FormattedMessage
        id="app.views.widgets.cards.employee_nps_results.csv_link.export_groups"
        defaultMessage="Export groups"
      />
    </CSVLink>
  );

  const exportScoresButton = (
    <CSVLink
      className="btn btn-light btn-sm ms-3"
      filename={createCSVFileName('enps_full')}
      data={createFullCsvData()}
    >
      <i className={ICONS.EXPORT + ' me-2'} />
      <FormattedMessage
        id="app.views.widgets.cards.employee_nps_results.csv_link.export_full"
        defaultMessage="Full Export"
      />
    </CSVLink>
  );

  // Only show the full export button if the org setting is not enabled
  const shouldShowFullExportsButton = !settings.disable_enps_full_export;

  const overallCounts = data?.by_field?.overall?.Overall;

  const noResultsFound =
    !overallCounts ||
    overallCounts.promoter + overallCounts.detractor + overallCounts.passive ===
      0;

  const summaryData = data.summaries as EnpsSummaryResponse | undefined;

  if (noResultsFound) {
    return (
      <Card>
        <CardHeader>
          <CardHeaderTitle>
            <FormattedMessage
              id="app.views.widgets.cards.employee_nps_results.title"
              defaultMessage="Results"
            />
          </CardHeaderTitle>
        </CardHeader>
        <CardBody>
          <div className="text-center">
            <FormattedMessage
              id="app.views.widgets.cards.employee_nps_results.no_results"
              defaultMessage="No eNPS scores have been received."
            />
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <span data-testid="enps-results-title">
            <FormattedMessage
              id="app.views.widgets.cards.employee_nps_results.title"
              defaultMessage="Results"
            />
          </span>
          {filterDropdown}
        </CardHeaderTitle>
        {exportButton}
        {shouldShowFullExportsButton && (
          <>
            &nbsp;
            {exportScoresButton}
          </>
        )}
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="2">
            <Card className="card-fill mb-0">
              <CardBody>
                <div className="fw-bold text-dark">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <FormattedMessage
                        id="app.views.widgets.cards.employee_nps_results.enps_score"
                        defaultMessage="<h2>eNPS</h2> <h1>{score}</h1>"
                        values={{
                          score: formatSimpleDecimal(
                            calculateEnpsScore(overallCounts)
                          ),
                          h2: (chunks) => (
                            <h2 className="text-muted mb-3">{chunks}</h2>
                          ),
                          h1: (chunks) => (
                            <h1>
                              {chunks}
                              {ScoreDescriptionLink()}
                            </h1>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="10">
            <Card className="bg-transparent border-0 shadow-none card-fill mb-0">
              <CardBody>
                <EnpsBar
                  promoters={overallCounts?.promoter}
                  detractors={overallCounts?.detractor}
                  passives={overallCounts?.passive}
                  missing={overallCounts?.missing}
                  showHeader={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {filterBy !== 'overall' && (
          <EnpsResultTable
            group={filterBy}
            results={data.by_field?.[filterBy] ?? {}}
            fields={data.fields}
          />
        )}
        {tableCardRows[0].count > 0 && (
          <EnpsCommentsCard
            filterDisplayName={filterDisplayName}
            currentSegment={filterBy}
            rows={tableCardRows}
          />
        )}
        {summaryData && summaryData.summary && (
          <EnpsSummaryCard data={summaryData} />
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: ReduxState): object => {
  const { settings } = state;

  return {
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(EmployeeNPSResultsCard));
