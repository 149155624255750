import React, { CSSProperties, FC } from 'react';

import { FormattedMessage } from 'react-intl';

interface Props {
  link: string;
  style?: CSSProperties;
}
export const Video: FC<Props> = ({ link, style = { width: '600px' } }) => {
  return (
    <p>
      <span
        className="fr-video fr-dvb fr-draggable fr-active"
        contentEditable="false"
        draggable="true"
      >
        <video className="fr-draggable" controls src={link} style={style}>
          <FormattedMessage
            id="app.components.media.video.html5_not_supported"
            defaultMessage="Your browser does not support HTML5 video."
          />
        </video>
      </span>
    </p>
  );
};

export default Video;
