import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo } from 'react';

import Avatar from '../Widgets/People/Avatar';
import { DEFAULT_FEEDBACK_QUESTION_PROPERTIES } from './consts';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Question from '../Widgets/Questions/Question';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import { activityOrSkillPageUrl } from '../../utils/util/util';
import { peopleObjectsAreEqual } from '../../utils/models/Person';

const FeedbackRequestCard: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const feedbackRequest = props.feedbackRequest;
  // @ts-expect-error
  const author = feedbackRequest?.author_person;

  // @ts-expect-error
  let filtered_subject_people = feedbackRequest?.subject_people;
  if (
    filtered_subject_people?.length === 1 &&
    peopleObjectsAreEqual(filtered_subject_people[0], author)
  ) {
    filtered_subject_people = [];
  }

  const usesCustomQuestions = useMemo(
    // @ts-expect-error
    () => feedbackRequest?.custom_questions?.length > 0,
    // @ts-expect-error
    [feedbackRequest.custom_questions]
  );

  const FeedbackRequestCardBody = useMemo(
    () =>
      feedbackRequest && (
        <div
          className={'comment-body d-block px-3'}
          style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
        >
          <Row>
            <Col>
              <Row>
                <Col>
                  <h4 className="mb-1 mt-1 text-dark small fw-bold">
                    {author?.id && (
                      <Link
                        to={author?.url}
                        target={props.isExternalUrl ? '_blank' : undefined}
                        rel="noopener noreferrer"
                      >
                        {author?.full_name}
                      </Link>
                    )}
                    {!author?.id && author.full_name}
                    <span className="fw-normal" style={{ lineHeight: 1.5 }}>
                      {' requested feedback'}
                      {filtered_subject_people?.length > 0 && (
                        <>
                          {/* @ts-expect-error */}
                          {feedbackRequest?.activities?.length > 0 ||
                          // @ts-expect-error
                          feedbackRequest?.skills?.length > 0
                            ? ' on behalf of '
                            : ' regarding '}
                          <FormattedList
                            value={
                              filtered_subject_people?.map((e, i) => {
                                return (
                                  <span key={i}>
                                    <a
                                      href={e?.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="fw-bold">
                                        {e.full_name}
                                      </span>
                                    </a>
                                  </span>
                                );
                              }) ?? []
                            }
                          />
                        </>
                      )}
                      <>
                        {/* @ts-expect-error */}
                        {(feedbackRequest?.activities?.length > 0 ||
                          // @ts-expect-error
                          feedbackRequest?.skills?.length > 0) && (
                          <>
                            {' regarding '}
                            <FormattedList
                              value={[
                                // @ts-expect-error
                                ...(feedbackRequest?.activities ?? []),
                                // @ts-expect-error
                                ...(feedbackRequest?.skills ?? []),
                              ].map((e, i) => {
                                return (
                                  <span key={i}>
                                    <a
                                      href={activityOrSkillPageUrl(e)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="fw-bold">{e.name}</span>
                                    </a>
                                  </span>
                                );
                              })}
                            />
                          </>
                        )}
                        {'.'}
                      </>
                    </span>
                  </h4>
                </Col>
              </Row>
              <div className="mt-2">
                {/* @ts-expect-error */}
                {feedbackRequest.comments?.length > 0 && (
                  <div className="py-1 fst-italic">
                    <RichTextViewer
                      // @ts-expect-error
                      model={feedbackRequest.comments}
                      expanded={true}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      ),
    [
      author.full_name,
      author?.id,
      author?.url,
      feedbackRequest,
      filtered_subject_people,
      props.isExternalUrl,
    ]
  );

  return (
    feedbackRequest && (
      <>
        <Row
          className={
            (props.className ? props.className + ' ' : '') + 'flex-nowrap'
          }
        >
          <Col className="col-auto pe-2">
            <Avatar
              className="avatar-sm mt-2"
              person={author}
              linked
              // @ts-expect-error
              isExternalUrl={props.isExternalUrl}
            />
          </Col>
          <Col className="ms-n3">{FeedbackRequestCardBody}</Col>
        </Row>
        {props.isPreview && (
          <Row className="mt-4 align-items-center">
            <Col>
              <Card className="mb-0">
                <CardBody>
                  <h6 className="text-uppercase text-muted mb-2">
                    <FormattedMessage
                      id="app.views.feedback.feedback_request_card.questions_that_were_asked"
                      defaultMessage="Questions that were asked"
                    />
                  </h6>
                  <div className="mb-n4">
                    {!usesCustomQuestions && (
                      <>
                        <form>
                          <Question
                            value={{
                              name: 'feedback_request_preview',
                              ...DEFAULT_FEEDBACK_QUESTION_PROPERTIES(
                                formatMessage
                              ),
                            }}
                            disabled={true}
                          />
                        </form>
                      </>
                    )}
                    {usesCustomQuestions &&
                      // @ts-expect-error
                      feedbackRequest.custom_questions?.map((question) => (
                        <Question
                          key={question.name}
                          value={question}
                          disabled={true}
                        />
                      ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </>
    )
  );
};

const FeedbackRequestCard_propTypes = {
  className: PropTypes.string,
  isExternalUrl: PropTypes.bool,
  feedbackRequest: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof FeedbackRequestCard_propTypes>;

export default React.memo(FeedbackRequestCard);
