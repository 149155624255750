import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import PropTypes from 'prop-types';

const StaticHeatmapRow: FC<Props> = (props) => {
  return (
    <Row className={'gx-0 heatmap-row'} style={{ height: '2rem' }}>
      {props.columns.map((c, ci) => {
        const isHeader = props.firstColumnIsTitle && !ci;

        let value;

        if (isHeader) {
          // @ts-expect-error
          value = <div>{props.data?.configs?.name}</div>;
        } else {
          if (props.total) {
            value = '100%';
          } else {
            // @ts-expect-error
            value = props.data?.responses?.[c];
          }
        }

        return (
          <Col
            key={ci}
            className={
              'heatmap-row-cell ' +
              (isHeader ? 'col-10 px-3 border-start ' : 'col-2 ') +
              (props.index ? '' : 'border-bottom border-top border-light ') +
              // @ts-expect-error
              (c.className ? c.className : '')
            }
            style={{
              backgroundColor: '#f9fbfd',
            }}
          >
            <Row
              className={
                `text-muted h-100 align-content-center justify-content-center fw-bold ` +
                (isHeader ? '' : 'text-center')
              }
            >
              {value}
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

const StaticHeatmapRow_propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.object.isRequired,
  firstColumnIsTitle: PropTypes.bool,
  formatValue: PropTypes.func,
  index: PropTypes.number,
  total: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof StaticHeatmapRow_propTypes>;

export default React.memo(StaticHeatmapRow);
