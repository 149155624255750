import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';

export const DEFAULT_FEEDBACK_QUESTION_PROPERTIES = (formatMessage) => ({
  label: formatMessage({
    id: 'app.feedback.modal_feedback_editor_button.label.what_is_your_feedback',
    defaultMessage: 'What is your feedback?',
  }),
  type: INPUT_TYPES.RICH_TEXT_EDITOR,
  multiLine: true,
  minRows: 3,
});
