import * as Sentry from '@sentry/browser';

import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FullStoryAPI } from 'react-fullstory';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { ReduxState } from 'types';
import config from '../../utils/util/config';
import { connect } from 'react-redux';
import { getUserRole } from '../../utils/models/User';
import { log } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';

// @ts-expect-error
const tracking = config.getTracking();

const trackPageview = (location) => {
  if (tracking.useGoogleAnalyticsTracking) {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }

  if (tracking.useConsoleEventTracking) {
    console.log('Page view: ' + location.pathname + location.search);
  }
};

const Analytics: FC<Props> = (props) => {
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [person, setPerson] = useState(null);

  const { user } = useAuth0();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (props.underlyingAuthMe) {
      log('underlyingAuthMe set from server or local storage:');
      log(props.underlyingAuthMe);
      setPerson({
        ...props.underlyingAuthMe,
        // add "first_name" and "last_name" for any analytics platforms that need it
        // instead of give_name/family_name/full_name
        // @ts-expect-error
        first_name: props.underlyingAuthMe.given_name,
        // @ts-expect-error
        last_name: props.underlyingAuthMe.family_name,
      });
    }

    if (props.me) {
      log('me (or proxy) set from server or local storage:');
      log(props.me);
    }
  }, [props.me, props.underlyingAuthMe]);

  useEffect(() => {
    if (!isAnalyticsInitialized && history && location && person && user) {
      const role = getUserRole(user);

      // Call this whenever information about your visitors becomes available
      // Please use Strings, Numbers, or Bools for value types.
      if (tracking.usePendoTracking) {
        // eslint-disable-next-line no-undef
        // @ts-expect-error
        pendo.initialize({
          visitor: {
            // @ts-expect-error
            id: person.user_id, // Required if user is logged in
            // @ts-expect-error
            email: person.email, // Recommended if using Pendo Feedback, or NPS Email
            // @ts-expect-error
            full_name: person.full_name, // Recommended if using Pendo Feedback
            role: role, // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
            // TODO
          },

          account: {
            // @ts-expect-error
            id: person.user_id, // Highly recommended
            // @ts-expect-error
            name: person.full_name, // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // @ts-expect-error
            creationDate: person.created_at, // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
            // TODO
          },
        });
      }

      // identify users for analytics software

      if (tracking.useLogRocketEventTracking) {
        // @ts-expect-error
        LogRocket.identify(person.id, {
          // @ts-expect-error
          name: person.full_name,
          role: role,
          // @ts-expect-error
          ...person,
        });
      }

      if (tracking.useFullStoryEventTracking) {
        // @ts-expect-error
        FullStoryAPI('identify', person.id, {
          role: role,
          // @ts-expect-error
          ...person,
          // @ts-expect-error
          displayName: person.full_name,
        });
      }

      if (tracking.useGoogleAnalyticsTracking) {
        const gaConfig = {
          titleCase: false,
          gaOptions: {
            // @ts-expect-error
            userId: person.user_id,
          },
        };

        ReactGA.initialize('G-KGSLTCGKLY', gaConfig);

        // @ts-expect-error
        if (!config.isProduction()) {
          // track locally but don't send to GA
          ReactGA.set({ sendHitTask: null });
        }
      }

      // identify user for Sentry
      Sentry.setUser({
        // @ts-expect-error
        id: person.user_id,
        // @ts-expect-error
        email: person.email,
        // @ts-expect-error
        full_name: person.full_name,
      });

      // track history for each pageview change
      history.listen(trackPageview);

      setIsAnalyticsInitialized(true);
      log('Analytics are initialized.');

      // manually track first page
      trackPageview(location);
    }
  }, [isAnalyticsInitialized, history, location, person, user]);

  return <></>;
};

const Analytics_propTypes = {
  me: PropTypes.object,
  underlyingAuthMe: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof Analytics_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  // @ts-expect-error
  const { me, underlyingAuthMe } = state;

  return {
    me,
    underlyingAuthMe,
  };
};

export default connect(mapStateToProps)(React.memo(Analytics));
