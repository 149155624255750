import React, { FC, useState } from 'react';

interface Props {
  name: string;
  model: string;
  onModelChange: (value: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled: boolean;
}

const TestRichTextEditor: FC<Props> = (props) => {
  const [value, setValue] = useState(props.model);

  return (
    <input
      name={props.name}
      data-testid={`test-rich-text-editor-${props.name}`}
      type="text"
      value={value}
      onChange={async (e) => {
        setValue(e.target.value);
        props.onModelChange(e.target.value);
      }}
      onKeyDown={(e) => props.onKeyDown(e)}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
};

export default TestRichTextEditor;
