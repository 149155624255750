import React, { FC, useCallback, useMemo, useState } from 'react';

import ObjectsDropdown from './Dropdowns/ObjectsDropdown';
import { useIntl } from 'react-intl';

interface Props {
  jobFamilies: object[];
  onChange: (jobFamily: object) => void;
}

const JobFamilyDropdown: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [defaultKey, setDefaultKey] = useState(0);

  // for use in simplified "job_family_descriptions" case
  // where org does not have a job_catalog dataset, only
  // a job_families dataset:
  // if each item does NOT have an id, auto-create one based
  // on "job_family_group" and "name" fields
  const jobFamilyChoices = useMemo(
    () =>
      props.jobFamilies.map((i) => {
        // if "job_family" is set but not "name", set "name" to that
        // @ts-expect-error
        if (i.job_family && !i.name) {
          // @ts-expect-error
          i.name = i.job_family;
        }

        // set id if not already set
        // @ts-expect-error
        if (!i.id) {
          // @ts-expect-error
          i.id = `${i.job_family_group}-${i.name}`;
        }
        return i;
      }),
    [props.jobFamilies]
  );

  const propsOnChange = props.onChange;
  const onChangeJobFamily = useCallback(
    (event) => {
      const id = event.target.value;
      setDefaultKey(id);

      // propagate object selected
      // @ts-expect-error
      propsOnChange(jobFamilyChoices.find((i) => i.id === id));
    },
    [jobFamilyChoices, propsOnChange]
  );

  return (
    <ObjectsDropdown
      selectText={formatMessage({
        id: 'app.views.widgets.job_family_dropdown.select_job_family',
        defaultMessage: 'Select job family...',
      })}
      objects={jobFamilyChoices}
      // @ts-expect-error
      defaultKey={defaultKey}
      onChange={onChangeJobFamily}
      submenuByField="job_family_group"
    />
  );
};

export default React.memo(JobFamilyDropdown);
