import {
  PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK,
  PERFORMANCE_FEATURE_ASSESS_MANAGER,
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_PEER_FEEDBACK,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_REPORTING,
  PHASE_TYPE_SELF,
  getCampaignHasFeatureEnabled,
  phaseWithFeatureEnabled,
} from './Performance';

import { RELATIONSHIP_TYPES } from './RelationshipUtils';
import { isEmpty } from 'lodash';

export const DECLINE_REASONS = {
  DOES_NOT_KNOW_PERSON: 'U',
  NOT_FAMILIAR_WITH_WORK: 'F',
  INTERPERSONAL_ISSUE: 'I',
  OTHER: 'O',
};

export const getDeclineReasonText = (relationship, peerName, formatMessage) => {
  if (!relationship?.decline_reason) {
    return null;
  }

  if (relationship.decline_reason === DECLINE_REASONS.DOES_NOT_KNOW_PERSON) {
    return formatMessage(
      {
        id: 'app.relationship.decline_reason.does_not_know_person',
        defaultMessage: "I don't know {peerName}",
      },
      { peerName }
    );
  }
  if (relationship.decline_reason === DECLINE_REASONS.NOT_FAMILIAR_WITH_WORK) {
    return formatMessage(
      {
        id: 'app.relationship.decline_reason.not_familiar_with_work',
        defaultMessage:
          'I have not interacted with {peerName} in the last six months.',
      },
      { peerName }
    );
  }
  if (relationship.decline_reason === DECLINE_REASONS.INTERPERSONAL_ISSUE) {
    return formatMessage(
      {
        id: 'app.relationship.decline_reason.interpersonal_issue',
        defaultMessage: 'I have an interpersonal issue with {peerName}',
      },
      { peerName }
    );
  }
  if (relationship.decline_reason === DECLINE_REASONS.OTHER) {
    return formatMessage(
      {
        id: 'app.relationship.decline_reason.other',
        defaultMessage: 'Other: {comments}',
      },
      { comments: relationship.negative_comments }
    );
  }

  return null;
};

export const getPersonIsParticipatingInPhase = (
  person,
  configs = null,
  phase = null,
  numEligibleDirectReports = 0
) => {
  // if person is_participating for entire campaign explicitly set to false,
  // they are not participating
  // @ts-expect-error
  if (configs && configs.is_participating === false) {
    return false;
  }

  if (!phase) {
    // we aren't checking a specific phase, just whole campaign eligibility
    return true;
  }

  // check the given survey config based on the given phase type's data
  // @ts-expect-error
  if (phase.type === PHASE_TYPE_SELF) {
    // @ts-expect-error
    return configs?.is_participating_in_self_phase !== false;
    // @ts-expect-error
  } else if (phase.type === PHASE_TYPE_OTHERS) {
    // you are participating if you are not a "is_only_receiving_review" person AND
    // either you have to assess your manager or have a peer review to write (whether
    // required or optional) or are doing an engagement survey
    return (
      // @ts-expect-error
      configs?.is_only_receiving_review !== true &&
      ((phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_ASSESS_MANAGER) &&
        // @ts-expect-error
        configs?.is_writing_upward_manager_review !== false) ||
        ((phaseWithFeatureEnabled(phase, PERFORMANCE_FEATURE_PEER_FEEDBACK) ||
          phaseWithFeatureEnabled(
            phase,
            PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK
          )) &&
          // @ts-expect-error
          configs?.is_writing_peer_reviews !== false) ||
        (phaseWithFeatureEnabled(
          phase,
          PERFORMANCE_FEATURE_ASSESS_ORGANIZATION
        ) &&
          // @ts-expect-error
          configs?.is_assessing_org !== false))
    );
    // @ts-expect-error
  } else if (phase.type === PHASE_TYPE_EVALUATION) {
    return numEligibleDirectReports > 0;
  } else if (
    // @ts-expect-error
    phase.type === PHASE_TYPE_REPORTING ||
    // @ts-expect-error
    phase.type === PHASE_TYPE_CALIBRATION
  ) {
    return (
      // @ts-expect-error
      configs?.is_participating_in_self_phase !== false ||
      // @ts-expect-error
      configs.is_only_receiving_review === true
    );
  } else {
    console.error(
      'Unrecognized phase type in getPersonIsActiveAndParticipatingInPhase for person ' +
        JSON.stringify(person) +
        ', configs ' +
        JSON.stringify(configs) +
        ', phase ' +
        JSON.stringify(phase)
    );
  }

  // no other exceptions, so the person is participating by default
  return true;
};

export const relationshipIsActive = (r) => {
  if (!r || !r.from_person || !r.to_person) {
    return false;
  }

  return (
    getPersonIsParticipatingInPhase(r.from_person) &&
    getPersonIsParticipatingInPhase(r.to_person)
  );
};

export const relationshipHasFeedback = (r) => {
  if (!r) {
    return false;
  }

  return (
    !isEmpty(r.positive_skills) ||
    !isEmpty(r.positive_comments) ||
    !isEmpty(r.negative_skills) ||
    !isEmpty(r.negative_comments) ||
    !isEmpty(r.responses)
  );
};

export const relationshipIsCompleted = (r, campaign) => {
  if (!campaign) {
    throw new Error('Missing campaign for checking Relationship ID:', r?.id);
  }

  if (!r) {
    return false;
  }

  const ratingRequired = !getCampaignHasFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
  );

  if (
    r.type === RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR &&
    ratingRequired
  ) {
    // If a rating is required for direct report feedback,
    // a rating must be given for the relationship to be considered complete.
    // We consider final_rating OR draft_rating to be valid, as rendering
    // the PersonPerformance report will have the backend set the
    // "final_rating" field to whatever rating is so the end user
    // doesn't see what the draft score was (as to them, only the final
    // score should be seen).

    // If a rating isn't required, we rely on the default fields below to
    // determine completion
    return (
      (typeof r.rating === 'number' && r.rating >= 0) ||
      (typeof r.final_rating === 'number' && r.final_rating >= 0) ||
      // Added in case the person is excluded from rating
      !isEmpty(r.rating_comments)
    );
  }

  // comments not required for peers, but if they exist we know they're done; so ensure
  // that's provided or, otherwise, at least one positive or negative skill is populated to indicate it is done
  // Some reviews may be composed of entirely custom questions (stored under responses). If we see any responses,
  // we also mark it as completed
  return (
    // NOTE: "completed" is passed from the backend as an extrapolation when we want to anonymize
    // the skill/comment data
    r.completed ||
    r.positive_skills?.length > 0 ||
    r.positive_comments?.length > 0 ||
    r.negative_skills?.length > 0 ||
    r.negative_comments?.length > 0 ||
    // prevent hard crash in demo mode where responses may be null
    (r.responses && Object.keys(r.responses).length > 0)
  );
};
