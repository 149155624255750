import React, { ReactElement } from 'react';

interface LoadingProps {
  className?: string;
  message?: string;
  small?: boolean;
  dataTestId?: string;
}

const Loading: React.FC<LoadingProps> = ({
  className = '',
  message = '',
  small = false,
  dataTestId = 'loader',
}: LoadingProps): ReactElement => {
  const combinedClassName =
    (small ? '' : 'p-3 text-center') +
    // mt-3 used for larger loading, but for small, default
    // to no margin as this is typically used on buttons
    (className ? ' ' + className : small ? '' : ' mt-3');

  // for loads that could potentially be longer, we use a spinner
  // and show a message; if no message, this means it should be a
  // quick load, so we use a pulse which is less prominent
  return (
    <div className={combinedClassName} data-testid={dataTestId}>
      {message && (
        <>
          {small && (
            <>
              <i
                className="spinner-border me-2"
                style={{
                  width: '0.8rem',
                  height: '0.8rem',
                }}
              />
              <span>{message}</span>
            </>
          )}
          {!small && (
            <>
              <div className="spinner-border text-muted m-auto"></div>
              <div className="mt-3">{message}</div>
            </>
          )}
        </>
      )}
      {!message && <div className="sk-pulse m-auto"></div>}
    </div>
  );
};

export default Loading;
