import { Button, Card, CardBody, Col, ListGroup, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import QuestionEditor, { generateUniqueQuestionId } from './QuestionEditor';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { CUSTOM_QUESTION_NONANONYMOUS_PREFIX } from '../../../utils/models/Performance';
import { INPUT_TYPES } from './ValidatedInputTypes';
import ModalFeedbackRequestSelector from '../../Feedback/ModalFeedbackRequestSelector';
import QuestionInput from './QuestionInput';
import update from 'immutability-helper';
import {
  AuthUser,
  Campaign,
  CampaignQuestion,
  QuestionDefaults,
  ReduxState,
} from 'types';
import { isPhaseLocked } from 'utils/models/Campaign';
import { useCampaignEditorPhase } from 'views/Administration/CampaignEditorPhaseContext';
import { getUserIsSuperAdmin } from 'utils/models/User';

type QuestionsEditorProps = {
  addText?: string;
  campaign?: Campaign | null;
  canBeAnonymous?: boolean | null;
  canBePrivate?: boolean | null;
  excludeSpecialQuestions?: string[] | null;
  inModal?: boolean | null;
  isReadOnly?: boolean | null;
  jsonPath?: string | null;
  // name?: string; this was listed in PropTypes but never passed nor used.
  onChange: (val: object[]) => void;
  preserveExistingAnswers?: boolean;
  // Questiond default for specific question types (optional)
  questionDefaults?: QuestionDefaults;
  submitButtonType?: string | null;
  translationNamespace?: string | null;
  value?: CampaignQuestion[];
};

const QuestionsEditor: FC<QuestionsEditorProps> = ({
  addText,
  campaign,
  canBeAnonymous,
  canBePrivate,
  excludeSpecialQuestions,
  inModal,
  isReadOnly = false,
  jsonPath,
  onChange,
  preserveExistingAnswers,
  questionDefaults,
  submitButtonType,
  translationNamespace,
  value,
}) => {
  const { formatMessage } = useIntl();

  const campaignEditorPhaseContext = useCampaignEditorPhase();
  const authUser = useSelector<ReduxState, AuthUser | undefined>(
    (state) => state?.authUser
  );
  const isSuperAdmin = getUserIsSuperAdmin(authUser);

  const propsOnChange = onChange;

  const [addQuestionObject, setAddQuestionObject] = useState(undefined);
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const toggleAddQuestionModal = useCallback(() => {
    setShowAddQuestionModal(!showAddQuestionModal);
  }, [showAddQuestionModal]);
  const [showImportQuestionsModal, setShowImportQuestionsModal] =
    useState(false);
  const toggleImportQuestionsModal = useCallback(() => {
    setShowImportQuestionsModal(!showImportQuestionsModal);
  }, [showImportQuestionsModal]);

  const readOnlyBecauseAnsweredAlready = isPhaseLocked(
    campaign?.campaign_question_locks ?? {},
    campaignEditorPhaseContext?.phaseType ?? ''
  );

  // if no campaign is provided, hide some of the richer/more advanced features
  // (e.g. in the case of feedback requests)
  const hideAdvancedFeatures = useMemo(() => {
    return campaign ? false : true;
  }, [campaign]);

  useEffect(() => {
    // clear out the add question object if this is a close
    if (!showAddQuestionModal) {
      setAddQuestionObject(undefined);
    }
  }, [showAddQuestionModal]);

  const onAddQuestion = useCallback(
    (newQuestion) => {
      const newListValue = [...(value ?? []), newQuestion];
      propsOnChange(newListValue);
    },
    [propsOnChange, value]
  );

  const onEditQuestion = useCallback(
    (questionIndex, newQuestionValue) => {
      const newListValue =
        value?.map((item, index) => {
          if (index === questionIndex) {
            return newQuestionValue;
          }
          return item;
        }) ?? [];
      propsOnChange(newListValue);
    },
    [propsOnChange, value]
  );

  const onCopyQuestion = useCallback((question) => {
    // duplicate question and generate different unique ID
    setAddQuestionObject({
      ...question,
      name: generateUniqueQuestionId(),
    });
    setShowAddQuestionModal(true);
  }, []);

  const onDeleteQuestion = useCallback(
    (question) => {
      const newListValue = value?.filter((r) => r !== question) ?? [];
      propsOnChange(newListValue);
    },
    [propsOnChange, value]
  );

  const questions = useMemo(() => {
    return value;
  }, [value]);

  const moveQuestion = useCallback(
    (dragIndex, hoverIndex) => {
      propsOnChange(
        // @ts-expect-error
        update(questions, {
          $splice: [
            [dragIndex, 1],
            // @ts-expect-error
            [hoverIndex, 0, questions[dragIndex]],
          ],
        })
      );
    },
    [propsOnChange, questions]
  );

  const questionsList = useMemo(() => {
    // @ts-expect-error

    return questions.map((question, index) => {
      const q = (
        <QuestionInput
          key={index}
          hoverIndex={index}
          campaign={campaign}
          value={question}
          callback={(q) => onEditQuestion(index, q)}
          onCopy={() => onCopyQuestion(question)}
          onDelete={() => onDeleteQuestion(question)}
          moveQuestion={moveQuestion}
          disabled={!!isReadOnly /*Force boolean for ts.*/}
          canBePrivate={canBePrivate}
          canBeAnonymous={canBeAnonymous}
          preserveExistingAnswers={preserveExistingAnswers}
          submitButtonType={submitButtonType}
          hideAdvancedFeatures={hideAdvancedFeatures}
          inModal={inModal}
          // only relevant for in modal situation
          autoFocus={true}
          translationNamespace={translationNamespace}
          jsonPath={
            question.name == null || jsonPath == null
              ? undefined
              : `${jsonPath}[?(@.name=='${question.name}')]`
          }
        />
      );

      return q;
    });
  }, [
    campaign,
    canBeAnonymous,
    canBePrivate,
    hideAdvancedFeatures,
    inModal,
    isReadOnly,
    jsonPath,
    moveQuestion,
    onCopyQuestion,
    onDeleteQuestion,
    onEditQuestion,
    preserveExistingAnswers,
    questions,
    submitButtonType,
    translationNamespace,
  ]);

  const onAddQuestionClicked = useCallback(() => {
    // if we're in a modal, we don't want to show the modal for adding a question,
    // we just want to create it right away
    if (inModal) {
      onAddQuestion({
        // note: must start with CUSTOM_QUESTION_NONANONYMOUS_PREFIX
        // for it to be save properly in the perf flow
        name: CUSTOM_QUESTION_NONANONYMOUS_PREFIX + generateUniqueQuestionId(),
        label: '',
        type: INPUT_TYPES.TEXTAREA,
      });
    } else {
      toggleAddQuestionModal();
    }
  }, [onAddQuestion, inModal, toggleAddQuestionModal]);

  const createQuestionButton = useMemo(() => {
    return (
      <Button
        color="white"
        onClick={onAddQuestionClicked}
        disabled={
          isReadOnly || (readOnlyBecauseAnsweredAlready && !isSuperAdmin)
        }
      >
        <i
          className="fe fe-plus"
          style={{ position: 'relative', top: '2px' }}
        />{' '}
        {addText ??
          formatMessage({
            id: 'app.views.widgets.inputs.questions_editor.create_question',
            defaultMessage: 'Create question',
          })}
      </Button>
    );
  }, [
    addText,
    formatMessage,
    isReadOnly,
    isSuperAdmin,
    onAddQuestionClicked,
    readOnlyBecauseAnsweredAlready,
  ]);

  const onImportQuestionsFromFeedbackRequest = useCallback(
    (fr) => {
      // add the custom questions from this feedback request to the current set of questions
      // @ts-expect-error
      const newListValue = [...value, ...fr.custom_questions];
      propsOnChange(newListValue);
    },
    [propsOnChange, value]
  );

  const existingQuestionNames = useMemo(
    () => (value ? value.map((x) => x.name) : []),
    [value]
  );

  const output = useMemo(
    () => (
      <>
        <QuestionEditor
          campaign={campaign}
          isOpen={showAddQuestionModal}
          toggle={toggleAddQuestionModal}
          question={addQuestionObject}
          extraProperties={{
            canBePrivate: canBePrivate,
            canBeAnonymous: canBeAnonymous,
          }}
          callback={onAddQuestion}
          title={formatMessage({
            id: 'app.views.widgets.inputs.questions_editor.title.create_question',
            defaultMessage: 'Create question',
          })}
          submitText={formatMessage({
            id: 'app.views.widgets.inputs.questions_editor.submit_text.create_question',
            defaultMessage: 'Create question',
          })}
          existingSpecialQuestions={existingQuestionNames}
          excludeSpecialQuestions={excludeSpecialQuestions}
          questionDefaults={questionDefaults}
          submitButtonType={submitButtonType}
          hideAdvancedFeatures={hideAdvancedFeatures}
          translationNamespace={translationNamespace}
        />
        {
          // @ts-expect-error
          questions?.length > 0 && (
            <>
              {inModal && (
                <Card>
                  <CardBody className="my-n3">
                    <ListGroup className="list-group-flush my-n3">
                      {questionsList}
                    </ListGroup>
                  </CardBody>
                </Card>
              )}
              {!inModal && questionsList}
            </>
          )
        }
        {inModal && (
          <Row className="align-items-center">
            <Col className="col-auto">{createQuestionButton}</Col>
            <Col className="ps-0">
              <ModalFeedbackRequestSelector
                isOpen={showImportQuestionsModal}
                toggle={toggleImportQuestionsModal}
                callback={onImportQuestionsFromFeedbackRequest}
              />
              <Button
                className="p-0"
                color="link"
                onClick={toggleImportQuestionsModal}
                // @ts-expect-error
                disabled={isReadOnly}
              >
                <FormattedMessage
                  id="app.views.widgets.inputs.questions_editor.or_import"
                  defaultMessage="or import from a template or previous request..."
                />
              </Button>
            </Col>
          </Row>
        )}
        {!inModal && createQuestionButton}
      </>
    ),
    [
      addQuestionObject,
      campaign,
      canBeAnonymous,
      canBePrivate,
      createQuestionButton,
      excludeSpecialQuestions,
      existingQuestionNames,
      formatMessage,
      hideAdvancedFeatures,
      inModal,
      isReadOnly,
      onAddQuestion,
      onImportQuestionsFromFeedbackRequest,
      questionDefaults,
      questions?.length,
      questionsList,
      showAddQuestionModal,
      showImportQuestionsModal,
      submitButtonType,
      toggleAddQuestionModal,
      toggleImportQuestionsModal,
      translationNamespace,
    ]
  );

  return output;
};

export default QuestionsEditor;
