// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';

import * as serviceWorker from './serviceWorker';

import {
  APP_INTEGRATION_POST_AUTH_PAGE,
  IDP_INITIATED_OIDC_FLOW,
  SESSION_FORCE_RELOGIN,
  SESSION_POST_FORCED_LOGIN,
} from './consts/consts';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import SessionForceRelogin, {
  SessionPostForcedLogin,
} from './views/Session/CustomSessionManagement';
import { applyMiddleware, createStore } from 'redux';

import App from './App';
import AppIntegrationPostAuthPage from './views/AppIntegrations/AppIntegrationPostAuthPage';
import AuthProvider from './utils/api/AuthProvider';
import { ConfirmIntlProvider } from './locale/ConfirmIntlContext';
import { DndProvider } from 'react-dnd';
import FullStory from 'react-fullstory';
import { HTML5Backend } from 'react-dnd-html5-backend';
import IdPInitiatedFlow from './views/Login/IdPInitiatedFlow';
import LogRocket from 'logrocket';
import { Provider } from 'react-redux';
import React from 'react';
import { ReloadAppOnNewVersion } from './views/AppVersion/ReloadAppOnNewVersion';
import ScrollToTop from './views/Layout/ScrollToTop';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
// all environment configurations
import config from './utils/util/config';
import { createRoot } from 'react-dom/client';
import createSagaMiddleware from 'redux-saga';
import { processRipplingData } from 'utils/util/MergeRipplingUtils';
import reducer from './reducers';
import rootSaga from './sagas';
import setupLogRocketReact from 'logrocket-react';

config.init();

// @ts-expect-error
const tracking = config.getTracking();

if (tracking.useLogRocketEventTracking) {
  // @ts-expect-error
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
    // @ts-expect-error
    shouldDebugLog: !config.isProduction(),
    release: process.env.REACT_APP_VERSION,
    console: {
      shouldAggregateConsoleErrors: true,
    },
    /*network: {
      responseSanitizer: (response) => {
        response.body = null;
        return response;
      },
    },
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },*/
  });
  setupLogRocketReact(LogRocket);
}

// React Redux Saga setup
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  // @ts-expect-error
  reducer,
  composeWithDevToolsDevelopmentOnly(
    applyMiddleware(
      ...(tracking.useLogRocketEventTracking
        ? [LogRocket.reduxMiddleware()]
        : []),
      sagaMiddleware
    )
  )
);
sagaMiddleware.run(rootSaga);

const container = document.getElementById('root');
// @ts-expect-error
const root = createRoot(container);

// Merge - Rippling setup. This needs to run before Auth0 redirects the
// user and as early as possible so that the cookie is correctly set.
if (window.location.pathname === '/integrations') {
  processRipplingData(window.location.search);
}

root.render(
  <ConfirmIntlProvider>
    <Provider store={store}>
      <Router>
        <AuthProvider>
          {tracking.useFullStoryEventTracking && (
            // @ts-expect-error
            <FullStory org={'R25WA'} debug={!config.isProduction()} />
          )}
          <ScrollToTop />
          <ReloadAppOnNewVersion />
          <DndProvider backend={HTML5Backend}>
            <Switch>
              <Route exact path={APP_INTEGRATION_POST_AUTH_PAGE.path}>
                <AppIntegrationPostAuthPage />
              </Route>
              <Route exact path={SESSION_FORCE_RELOGIN.path}>
                <SessionForceRelogin />
              </Route>
              <Route exact path={SESSION_POST_FORCED_LOGIN.path}>
                <SessionPostForcedLogin />
              </Route>
              <Route exact path={IDP_INITIATED_OIDC_FLOW.path}>
                <IdPInitiatedFlow />
              </Route>
              <Route path="*">
                <App />
              </Route>
            </Switch>
          </DndProvider>
        </AuthProvider>
      </Router>
    </Provider>
  </ConfirmIntlProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
