import React, { useMemo, useCallback } from 'react';
import PerformanceHistoryChart from './HistoryChart';
import { useIntl } from 'react-intl';
import {
  PerformanceHistoryData,
  computeDataValues,
  computeRatingData,
  TENURE_GAP_SIZE,
  lineBaseStyle,
  chartAlertPointStyle,
  computeLabels,
} from './HistoryChartUtils';
import { getCampaignRatings } from '../../../../utils/models/Performance';
import { Organization } from '../../../../types';

interface ManagerRatingHistoryChartProps {
  person?: {
    id: number;
    given_name: string;
  };
  showManagerOnlyPerformanceDetails: boolean;
  performanceData?: PerformanceHistoryData[];
  organization: Organization;
}

const ManagerRatingHistoryChart: React.FC<ManagerRatingHistoryChartProps> = ({
  person,
  showManagerOnlyPerformanceDetails,
  performanceData,
  organization,
}) => {
  const { formatMessage } = useIntl();
  const data = performanceData;

  const firstName = person?.given_name ?? '';

  const ratingValues = useMemo(() => computeRatingData(data), [data]);

  const dataValues = useMemo(
    () => computeDataValues(data, ratingValues),
    [data, ratingValues]
  );

  const alertPointStyle = useCallback(
    (x, type) => chartAlertPointStyle(dataValues, type, x),
    [dataValues]
  );

  const labels = useMemo(() => {
    return computeLabels(data || []);
  }, [data]);

  const maxRating = useMemo(() => {
    let max = 1;
    if (!data) {
      return max;
    }
    data.forEach((d) => {
      const ratingScale = getCampaignRatings(d.campaign);
      if (ratingScale) {
        if (ratingScale[0].value > max) {
          max = ratingScale[0].value;
        }
        if (ratingScale[ratingScale.length - 1].value > max) {
          max = ratingScale[ratingScale.length - 1].value;
        }
      }
    });

    // Adding 1 here to create vertical buffer in case
    // highest rating has an alert
    return max + 1;
  }, [data]);

  const managerRatingChartData = {
    datasets: [
      {
        showLine: true,
        pointStyle: (x) => alertPointStyle(x, 'ratings'),
        ...lineBaseStyle(firstName, '#2c7be5'),
        ...dataValues['ratings'],
      },
    ],
  };

  const managerRatingChartOptions = {
    x: {
      label: formatMessage({
        id: 'app.components.campaign_insight.manager_rating_options.x.label.tenure',
        defaultMessage: 'Tenure (months)',
      }),
      tickMin: labels[0],
      tickMax: labels[1],
      tickSize: TENURE_GAP_SIZE,
    },
    y: {
      label: formatMessage({
        id: 'app.components.campaign_insight.manager_rating_options.x.label.rating',
        defaultMessage: 'Rating',
      }),
      // need space if the (!) icon shows in the top row so it doesn't get cut off
      // (this is fine since we don't display the text on the left)
      tickMax: maxRating + 1,
      tickSize: 1,
      maxTicksLimit: 5,
      callback: () => {
        // we don't want to displat Y labels for rating names
        return '';
      },
    },
  };

  return (
    <PerformanceHistoryChart
      key={`chart_history_ratings_${person?.id}`}
      personId={person?.id}
      title={formatMessage(
        {
          id: 'app.views.person.person_performance.history.persons_manager_ratings',
          defaultMessage: 'Manager ratings over time',
        },
        {
          firstName,
        }
      )}
      firstName={firstName}
      data={managerRatingChartData}
      data2={performanceData}
      organization={organization}
      options={managerRatingChartOptions}
      individualAlerts={dataValues.ratings.alerts}
      cycleDatapoints={ratingValues.cycles}
      name={firstName}
      managerView={showManagerOnlyPerformanceDetails}
      chartType="rating"
    />
  );
};

export default ManagerRatingHistoryChart;
