import * as consts from '../../consts/consts';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  contributionIsEmpty,
  getActivityType,
  getContributionPerson,
} from '../../utils/models/Activity';
import {
  getInvalidPeopleError,
  getPersonOrInvitationObjectForSending,
  peopleIdsAreEqual,
} from '../../utils/models/Person';

import Avatar from '../Widgets/People/Avatar';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ContributionCard from './ContributionCard';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import ModalContributionEditor from './ModalContributionEditor';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import { connect } from 'react-redux';
import { isEnabled } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';

const ContributionsManager: React.FC<Props> = (props: Props) => {
  const [inviteContributorsModalIsOpen, setInviteContributorsModalIsOpen] =
    useState(false);
  const toggleInviteContributorsModal = () =>
    setInviteContributorsModalIsOpen(!inviteContributorsModalIsOpen);

  const [showEmptyContributions, setShowEmptyContributions] = useState(false);

  const { user } = useAuth0();
  const intl = useIntl();

  const userSub = user?.sub;
  const activity = props.activity;
  // @ts-expect-error
  const activityId = activity?.id;
  const activityTypeName = getActivityType(activity, intl)?.name;
  // @ts-expect-error
  const currentOrgId = props.currentOrganization?.id;
  // @ts-expect-error
  const validDomains = props.currentOrganization?.email_domains;
  const propsOnSubmitActivity = props.onSubmitActivity;
  const propsOnSubmitContribution = props.onSubmitContribution;
  const [
    contributionsWithRecommendations,
    setContributionsWithRecommendations,
  ] = useState([]);
  const [contributionToShow, setContributionToShow] = useState(null);

  const contributions = useMemo(
    // @ts-expect-error
    () => (props.activity?.contributions ? props.activity.contributions : []),
    [props.activity]
  );

  const updateRecommendations = useCallback(() => {
    // fetch latest recommended groups (note: this will always be an update behind,
    // but it's efficient in that it allows for ES to update the recommendations without
    // delaying saves of activities/contributions)
    // @ts-expect-error
    ElasticsearchAPI.getContributionsForActivity(
      userSub,
      // @ts-expect-error
      props.currentProxyPerson,
      currentOrgId,
      activityId,
      (contributions) => {
        setContributionsWithRecommendations(contributions);
      },
      (message) => {
        // do nothing as this data is less essential, but record to console
        console.error('Could not fetch latest contributions: ' + message);
      }
    );
    // @ts-expect-error
  }, [props.currentProxyPerson, userSub, currentOrgId, activityId]);

  const onSubmitActivity = useCallback(
    (activity) => {
      propsOnSubmitActivity(activity);
      updateRecommendations();
    },
    [propsOnSubmitActivity, updateRecommendations]
  );

  const onSubmitContribution = useCallback(
    (contribution) => {
      propsOnSubmitContribution(contribution);
      updateRecommendations();
    },
    [propsOnSubmitContribution, updateRecommendations]
  );

  // fetch recommended groups (which we fetch separately to avoid performance
  // delays by computing/returning it in real time when saving/updating contributions)
  useEffect(() => {
    updateRecommendations();
  }, [updateRecommendations]);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        id: activityId,
        contributions: object.contributions
          ? [
              ...object.contributions.map((c) =>
                getPersonOrInvitationObjectForSending('contributor_person', c)
              ),
            ]
          : [],
      };
    },
    [activityId]
  );

  const onDeleteContribution = useCallback(
    (contribution) => {
      onSubmitActivity({
        ...activity,
        // @ts-expect-error
        contributions: activity?.contributions.filter(
          (c) => c.id !== contribution.id
        ),
      });
      updateRecommendations();
    },
    [activity, onSubmitActivity, updateRecommendations]
  );

  // for when my contribution already exists
  const myFoundContribution = useMemo(() => {
    return contributions?.find(
      (c) =>
        c.contributor_person?.id &&
        peopleIdsAreEqual(c.contributor_person?.id, props.meId)
    );
  }, [contributions, props.meId]);

  // prepopulate contribution for me if one doesn't yet exist
  const myPrepopulatedContribution = useMemo(() => {
    if (myFoundContribution) {
      return myFoundContribution;
    }

    return {
      // @ts-expect-error
      activity: activity?.id,
      // @ts-expect-error
      contributor_person: props.me,
      contributor_role: '',
      description: '',
      skills: [],
    };
    // @ts-expect-error
  }, [activity?.id, myFoundContribution, props.me]);

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      errors['contributions'] =
        obj.contributions?.length > 0
          ? getInvalidPeopleError(
              intl.formatMessage,
              obj.contributions,
              validDomains
            )
          : 'Please enter a name.';
      return errors;
    },
    [validDomains, intl]
  );

  // add default contribution for self if not already in contribution list
  const activityWithMyContribution = useMemo(
    () =>
      contributions &&
      contributions?.findIndex((c) => c === myPrepopulatedContribution) === -1
        ? {
            ...activity,
            contributions: [...contributions, myPrepopulatedContribution],
          }
        : activity,
    [activity, contributions, myPrepopulatedContribution]
  );

  const activityWithMyContributionMyIndex = useMemo(
    () =>
      activityWithMyContribution &&
      // @ts-expect-error
      activityWithMyContribution.contributions.findIndex(
        (c) =>
          props.meId &&
          peopleIdsAreEqual(getContributionPerson(c).id, props.meId)
      ),
    [activityWithMyContribution, props.meId]
  );

  const populatedContributionsNotMe = useMemo(
    () =>
      contributions.filter(
        (c) => c !== myPrepopulatedContribution && !contributionIsEmpty(c)
      ),
    [contributions, myPrepopulatedContribution]
  );

  const emptyContributionsNotMe = useMemo(
    () =>
      contributions.filter(
        (c) => c !== myPrepopulatedContribution && contributionIsEmpty(c, false)
      ),
    [contributions, myPrepopulatedContribution]
  );

  const sortedExistingContributions = useMemo(
    () =>
      myFoundContribution
        ? [
            myFoundContribution,
            ...populatedContributionsNotMe,
            ...emptyContributionsNotMe,
          ]
        : [...populatedContributionsNotMe, ...emptyContributionsNotMe],
    [myFoundContribution, populatedContributionsNotMe, emptyContributionsNotMe]
  );

  const showContributionModal = useCallback((c) => {
    setContributionToShow(c);
  }, []);

  const onModalContributionEditorClosed = useCallback(() => {
    setContributionToShow(null);
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.activities.contributions_manager.card.header.contributors.text"
            defaultMessage="Contributors"
          />
        </CardHeaderTitle>
        <ModalContributionEditor
          isOpen={props.addMyContributionModalIsOpen}
          toggle={props.toggleAddMyContributionModal}
          activity={activityWithMyContribution}
          contributionIndex={activityWithMyContributionMyIndex}
          contributionsWithRecommendations={contributionsWithRecommendations}
          onSubmitActivity={propsOnSubmitActivity}
          callback={onSubmitContribution}
          anchorTrigger="claim"
          hideFeedbackRequestPrompt={
            !isEnabled(
              // @ts-expect-error
              props.features,
              consts.FLAGS.ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS
            )
          }
        />
        <ModalEditor
          isOpen={inviteContributorsModalIsOpen}
          toggle={toggleInviteContributorsModal}
          method="PATCH"
          url="activities"
          title={intl.formatMessage({
            id: 'app.views.activities.contributions_manager.title.invite_contributors',
            defaultMessage: 'Invite Contributors',
          })}
          transformObjectBeforeSubmit={transformObjectBeforeSubmit}
          inputs={[
            {
              type: INPUT_TYPES.PEOPLE_EDITOR,
              name: 'contributions',
              label: 'Invite other people who contributed',
              inline: true,
              inForm: true,
              excludeList: contributions?.map(getContributionPerson),
              showInviteInstructions: true,
              showInviteSuggestions: true,
            },
          ]}
          callback={onSubmitActivity}
          onValidate={onValidate}
        />
        {myPrepopulatedContribution?.id && (
          <Button
            color="link"
            className="p-0"
            onClick={toggleInviteContributorsModal}
          >
            <span className={'me-2 fe fe-user-plus'}></span>
            <small>
              <FormattedMessage
                id="app.views.activities.contributions_manager.modal_editor.button.add_contributor"
                defaultMessage="Add contributor"
              />
            </small>
          </Button>
        )}
        {!myPrepopulatedContribution?.id && (
          <UncontrolledDropdown>
            <DropdownToggle
              tag="div"
              role="button"
              className="dropdown-ellipses text-primary text-decoration-none"
            >
              <Button color="link" className="p-0">
                <span className={'me-2 fe fe-user-plus'}></span>
                <small>
                  <FormattedMessage
                    id="app.views.activities.contributions_manager.modal_editor.button.add_contributor"
                    defaultMessage="Add contributor"
                  />
                </small>
              </Button>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={props.toggleAddMyContributionModal}>
                <FormattedMessage
                  id="app.views.activities.contributions_manager.modal_editor.dropdown.add_my_contribution"
                  defaultMessage="
                Add my contribution
              "
                />
              </DropdownItem>
              <DropdownItem onClick={toggleInviteContributorsModal}>
                <FormattedMessage
                  id="app.views.activities.contributions_manager.modal_editor.button.invite_others"
                  defaultMessage="
                Invite others to contribute
              "
                />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </CardHeader>
      <CardBody>
        {!(sortedExistingContributions?.length > 0) && (
          <div className="text-center text-muted">
            <FormattedMessage
              id="app.views.activities.contributions_manager.card.body.header"
              defaultMessage="
            This {activityTypeName} has no contributions."
              values={{
                activityTypeName,
              }}
            />
          </div>
        )}
        {sortedExistingContributions?.length > 0 && (
          <>
            <ul className="list-group-flush mb-n3 list-group">
              {sortedExistingContributions?.map((c, index) => {
                // Note: we use findIndex here as the order we display here may not
                // match the expected order in ContributionCard
                const i =
                  contributions &&
                  contributions?.findIndex(
                    (c2) => c.id?.toString() === c2.id?.toString()
                  );

                const contributionModalIsOpen =
                  contributionToShow &&
                  // @ts-expect-error
                  c.id?.toString() === contributionToShow?.id?.toString()
                    ? true
                    : undefined;

                return (
                  <li
                    key={index}
                    className={
                      'list-group-item border-0 p-0' +
                      (c !== myPrepopulatedContribution &&
                      contributionIsEmpty(c)
                        ? ' d-none'
                        : '')
                    }
                  >
                    <ContributionCard
                      isOpen={contributionModalIsOpen}
                      onClosed={onModalContributionEditorClosed}
                      // @ts-expect-error
                      activity={activity}
                      contributionIndex={i}
                      contributionsWithRecommendations={
                        contributionsWithRecommendations
                      }
                      callback={onSubmitContribution}
                      onSubmitActivity={onSubmitActivity}
                      onDeleteContribution={onDeleteContribution}
                    />
                  </li>
                );
              })}
            </ul>
            {showEmptyContributions && emptyContributionsNotMe?.length > 0 && (
              <div className="row text-center">
                <div className="col-sm-10 offset-sm-1">
                  {emptyContributionsNotMe.map((c, index) => (
                    <Avatar
                      size="md"
                      className="mt-4 mx-1"
                      key={index}
                      person={getContributionPerson(c)}
                      onClick={() => showContributionModal(c)}
                    />
                  ))}
                </div>
                <div className="col-12">
                  <div className="my-3 text-muted">
                    <FormattedMessage
                      id="app.views.activities.contributions_manager.select_contributor_for_credit"
                      defaultMessage="
                    Select a contributor to give them credit
                  "
                    />
                  </div>
                </div>
              </div>
            )}
            {!showEmptyContributions && emptyContributionsNotMe?.length > 0 && (
              <div className="text-center mt-5 mb-3">
                <span
                  className="text-primary"
                  role="button"
                  onClick={() => setShowEmptyContributions(true)}
                >
                  <FormattedMessage
                    id="app.views.activities.contributions_manager.show_other_contributor"
                    defaultMessage="Show {emptyContributionsNotMineCount} {hasOther, select, true { other } other {}} {emptyContributionsNotMeCount, plural, one {contributor} other {contributors}}"
                    values={{
                      emptyContributionsNotMineCount:
                        emptyContributionsNotMe?.length,
                      hasOther: !!(
                        myFoundContribution ||
                        populatedContributionsNotMe?.length > 0
                      ),
                      emptyContributionsNotMeCount:
                        emptyContributionsNotMe?.length,
                    }}
                  />
                </span>
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

type Props = InferProps<typeof ContributionsManager_propTypes>;

const ContributionsManager_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  meId: PropTypes.number.isRequired,
  activity: PropTypes.object.isRequired,
  onSubmitActivity: PropTypes.func.isRequired,
  onSubmitContribution: PropTypes.func.isRequired,
  editButtonGenerator: PropTypes.func.isRequired,
  addMyContributionModalIsOpen: PropTypes.bool.isRequired,
  toggleAddMyContributionModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features, me } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
    me: me,
    meId: me?.id,
  };
};

export default connect(mapStateToProps)(React.memo(ContributionsManager));
