import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { ICONS } from '../../../consts/consts';
import { Row } from 'reactstrap';

const ObjectivesOverviewItemHeader: FC = () => {
  const letterSpacing = '0.08em';
  return (
    <Row className="align-items-center text-uppercase b-0 text-muted">
      <h6 className="col">
        <b className="ps-4" style={{ letterSpacing }}>
          <FormattedMessage
            id="app.views.objectives.objectives_overview_page.objectives_overview_item_header.objective"
            defaultMessage="Objective"
          />
        </b>
      </h6>
      <h6 style={{ width: 30, letterSpacing }}>
        <span className={'fe me-2 ' + ICONS.PEOPLE}></span>
      </h6>
      <h6 style={{ width: 85, letterSpacing }}>
        <b>
          <FormattedMessage
            id="app.views.objectives.objectives_overview_page.objectives_overview_item_header.status"
            defaultMessage="Status"
          />
        </b>
      </h6>
      <h6 style={{ width: 144, letterSpacing }}>
        <b>
          <FormattedMessage
            id="app.views.objectives.objectives_overview_page.objectives_overview_item_header.subitems"
            defaultMessage="Subitems"
          />
        </b>
      </h6>
    </Row>
  );
};

export default ObjectivesOverviewItemHeader;
