import { FormattedMessage, useIntl } from 'react-intl';
import {
  GroupField,
  HeaderDescriptor,
  computeAvailableData,
  generateFields,
} from './utils';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import CSVLinkAsync from '../../../../../components/CSVLinkAsync';
import { Campaign } from '../../../../../types';
import { ICONS } from '../../../../../consts/consts';
import ModalEditor from '../../../Modals/ModalEditor';
import { createCSVFileName } from '../../../../../utils/util/util';

interface Props {
  data: object[];
  headers: HeaderDescriptor[];
  campaign: Campaign;
}

const TeamDashboardCSVDownload: FC<Props> = ({ data, headers, campaign }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const downloadCSVRef = useRef<(proceed: boolean) => void>(() => () => {});
  const [obj, setObj] = useState<object>({});

  const handleClick = useCallback((done: (proceed: boolean) => void) => {
    downloadCSVRef.current = done;
    setModalIsOpen(true);
  }, []);

  const handleOnClose = useCallback((accepted) => {
    downloadCSVRef.current(accepted);
  }, []);

  const handleCallback = useCallback(() => {}, []);

  const handleOnToggle = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const onChange = useCallback((value) => {
    setObj(value);
  }, []);

  const { filteredHeaders } = useMemo(
    () =>
      computeAvailableData({
        selectedGroupFields: (obj['group_fields'] as GroupField[]) ?? [],
        headers,
        intl,
      }),
    [obj, headers, intl]
  );

  const inputs = useMemo(
    () => generateFields(campaign, headers, intl),
    [campaign, intl, headers]
  );

  return (
    <>
      <ModalEditor
        isOpen={modalIsOpen}
        toggle={handleOnToggle}
        title={formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.title',
          defaultMessage: 'Select fields to export',
        })}
        object={obj}
        onChange={onChange}
        callback={handleCallback}
        onClosed={handleOnClose}
        submitText={formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_csv.submit_text',
          defaultMessage: 'Export',
        })}
        inputs={inputs}
        disableUnsavedChangesPrompt={true}
      />
      <CSVLinkAsync
        onClick={handleClick}
        className="btn btn-light btn-sm"
        filename={createCSVFileName()}
        data={data}
        headers={filteredHeaders}
      >
        <i className={ICONS.EXPORT + ' me-2'} />
        <FormattedMessage
          id="app.views.widgets.dashboards.team_dashboard.export_num_to_csv"
          defaultMessage="Export {num} people to CSV"
          values={{
            num: data.length,
          }}
        />
      </CSVLinkAsync>
    </>
  );
};

export default TeamDashboardCSVDownload;
