import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  formatDateWithUnicode,
  getDateFormatForDatasetTransform,
  parseDateFromUnicode,
} from '../../../utils/util/time';

import { CAMPAIGN_STATUSES } from '../../../utils/models/Campaign';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import { INPUT_TYPES } from '../../Widgets/Inputs/ValidatedInputTypes';
import { IntercomAPI } from '../../../vendor/react-intercom';
import ModalEditor from './ModalEditor';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import { PERSON_STATUSES, PERSON_STATUSES_NAMES } from 'utils/models/Person';

// should we treat this as a boolean choice?
// because there can be "Yes", "Y", "NO", "N", etc.
const isBooleanChoice = (options) => {
  if (options.DEFAULT === undefined) {
    return false;
  }

  const possibleValues = uniq(Object.values(options));
  if (
    possibleValues.length != 2 ||
    !possibleValues.includes(true) ||
    !possibleValues.includes(false)
  ) {
    return false;
  }

  return true;
};

// have id, first name, last name, email always come first
const FIELD_NAMES_TO_PRIORITIZE = [
  'external_id',
  'given_name',
  'legal_given_name',
  'preferred_given_name',
  'family_name',
  'full_name',
  'email',
];

const FIELD_NAMES_TO_DEPRIORITIZE = [
  'is_participating',
  'is_participating_in_self_phase',
];

const PARTICIPATION_FIELDS = new Set([
  'is_participating_in_self_phase',
  'is_evaluated_by_manager',
  'is_writing_peer_reviews',
  'is_receiving_peer_reviews',
  'is_writing_upward_manager_review',
  'is_rated',
  'is_only_receiving_review',
  'is_only_selecting_high_priority_peers',
]);

const ModalDatasetRecordEditor = (props) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [wantToLoad, setWantToLoad] = useState(false);
  const [configs, setConfigs] = useState({});

  // reload the data whenever this is opened/re-opened, so we can
  // guarantee this dialog always has the most up-to-date starting point
  // need this extra variable so that we can allow the dialog to open
  // asynchronously / independently of when the load happens
  useEffect(() => {
    if (props.isOpen != isOpen) {
      if (props.isOpen) {
        setWantToLoad(true);
      } else {
        setWantToLoad(false);
      }
      setIsOpen(props.isOpen);
    }
  }, [isOpen, props.isOpen]);

  // do the actual data loading -- rather than get the data-to-be-changed
  // passed in via props, we just take the person/campaign/org and explicitly
  // call the backend for the latest info
  useEffect(() => {
    if (
      wantToLoad &&
      props.person_id &&
      props.campaign_id &&
      props.currentOrganization?.id
    ) {
      ConfirmAPI.sendRequestToConfirm(
        'GET',
        '/survey-response-configs',
        {
          person: props.person_id,
          campaign: props.campaign_id,
          organization: props.currentOrganization.id,
        },
        (data, error, message) => {
          if (error) {
            console.error('Could not fetch user configs: ' + message);
            setConfigs(null);
            return;
          }
          setConfigs(data);
        }
      );
      setWantToLoad(false);
    }
  }, [
    wantToLoad,
    props.person_id,
    props.campaign_id,
    props.currentOrganization.id,
  ]);

  const [mappings, setMappings] = useState();
  useEffect(() => {
    if (
      !props.currentOrganization?.id ||
      props.currentOrganization?.status === CAMPAIGN_STATUSES.DEMO ||
      !props.campaign_id
    ) {
      return;
    }

    const params = {
      organization: props.currentOrganization?.id,
      campaign: props.campaign_id,
    };

    ConfirmAPI.getUrlWithCache(
      '/performance/admin/get-dataset-mappings',
      'dataset_mappings',
      props.userSub,
      props.currentProxyPerson,
      params,
      (data) => {
        if (data?.keys) {
          setMappings(data);
        }
      },
      () => {
        setMappings(null);
      }
    );
  }, [
    props.currentOrganization?.status,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    props.userSub,
    props.campaign_id,
  ]);

  // we get the fields to display from props. for now, perf admin gets the fields
  // from campaign mappings but this may change in the future, and this allows the
  // component user to customize what fields to include
  const fields = useMemo(() => {
    if (!mappings?.keys) {
      return undefined;
    }
    let fields = {};
    // the ordering of fields is determined by the "order" field in dataset.mappings
    // but defaults to whatever arbitrary order we get from the keys() method
    const order = mappings?.order || Object.keys(mappings.keys);
    order.forEach((theirName) => {
      let ourName = mappings.keys[theirName];
      let info = {
        name: ourName,
        label: theirName,
      };

      // special handling for dates and multiple-choice
      // TODO: need to handle cost_center / strip_up_to?
      if (
        mappings?.transforms &&
        ourName in mappings.transforms &&
        ourName.includes('_date')
      ) {
        info.type = 'date';
        // extract date format from list of transforms
        mappings.transforms[ourName].forEach((transformObject) => {
          const transform = Object.keys(transformObject)[0];
          const format = getDateFormatForDatasetTransform(transform);
          if (format) {
            info.transform = transform;
            info.format = format.pikr;
            info.placeholder = format.placeholder;
            info.unicode = format.unicode;
          }
        });
      } else if (mappings?.values && ourName in mappings.values) {
        const optionMap = mappings.values[ourName];
        if (isBooleanChoice(optionMap)) {
          info.type = 'boolean';
          info.options = Object.entries(mappings.values[ourName]).map(
            ([label, value]) => ({ name: label, object: label, value })
          );
          info.defaultValue = mappings.values[ourName].DEFAULT ?? false;

          // because the modal holds `true` and `false`, we need to map
          // them back to valid "labels" before sending them to the backend.
          // arbitrarily choose labels (that are not "DEFAULT").
          info.trueChoice = info.options.find(
            (option) => option.value === true && option.name !== 'DEFAULT'
          ).name;
          info.falseChoice = info.options.find(
            (option) => option.value === false && option.name !== 'DEFAULT'
          ).name;
        } else {
          info.type = 'choice';
          info.options = Object.entries(mappings.values[ourName]).map(
            ([label]) => ({ name: label, object: label })
          );
        }
      } else if (ourName === 'manager_email') {
        info.type = 'manager_email_editor';
      } else if (ourName === 'status') {
        const statusNames = PERSON_STATUSES_NAMES(formatMessage);
        info.type = 'choice';
        info.options = [PERSON_STATUSES.ACTIVE, PERSON_STATUSES.LEAVE].map(
          (status) => ({ object: status, name: statusNames[status] })
        );
      }

      fields[theirName] = info;
    });
    return fields;
  }, [mappings, formatMessage]);

  const reverseKeyMapping = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(mappings?.keys || {}).map(([k, v]) => [v, k])
      ),
    [mappings]
  );

  const readOnlyFields = useMemo(() => {
    const hasParticipationFields =
      Object.keys(reverseKeyMapping).findIndex((k) =>
        PARTICIPATION_FIELDS.has(k)
      ) >= 0;
    return hasParticipationFields ? ['is_participating'] : [];
  }, [reverseKeyMapping]);

  // render inputs into three separate steps
  const renderInputs = useCallback((inputs) => {
    let rows = [];
    for (let i = 0; i < inputs.length; i += 3) {
      rows.push(inputs.slice(i, i + 3));
    }
    return (
      <>
        {rows.map((row, index) => (
          <Row key={`row-${index}`} className="align-items-top">
            {row.map((input, iindex) => (
              <Col key={`row-${index}-col-${iindex}`} className="col-4">
                {input}
              </Col>
            ))}
          </Row>
        ))}
      </>
    );
  }, []);

  const belowFormContents = (
    <div className="mt-4 text-center">
      <FormattedMessage
        id="app.views.widgets.modals.modal_dataset_record_editor.questions_or_concerns"
        defaultMessage="These changes will only affect this cycle. Questions or concerns?"
      />{' '}
      <button className="btn btn-link ps-0" onClick={() => IntercomAPI('show')}>
        <FormattedMessage
          id="app.views.widgets.modals.modal_dataset_record_editor.let_us_know"
          defaultMessage="Let us know."
        />
      </button>
    </div>
  );

  const inputs = useMemo(() => {
    if (!fields) {
      return [];
    }

    const unorderedInputs = Object.entries(fields).map(([name, info]) => {
      const ourName = mappings?.keys[name] || name;
      let input = {
        required: false,
        name: name,
        label: info.label,
        labelClassName: 'small',
        autoFocus: true,
        maxLength: 100,
        hideMaxLengthIndicator: true,
        // don't allow editing of the below fields
        // as we take them from the person object
        disabled:
          FIELD_NAMES_TO_PRIORITIZE.indexOf(ourName) !== -1 ||
          readOnlyFields.indexOf(ourName) !== -1,
      };
      if (info.type === 'date') {
        input.type = INPUT_TYPES.DATE_PICKER;
        input.autoFocus = false;
        input.transform = info.transform;
        input.dateFormat = info.format;
        input.altInput = true;
        input.altFormat = info.format;
        input.placeholder = info.placeholder;
      } else if (info.type === 'choice') {
        input.type = INPUT_TYPES.SELECT;
        input.autoFocus = false;
        input.clearable = true;
        input.defaultOptions = info.options;
        input.isOptionSelected = (option, selectValue) => {
          return option.name === selectValue.name;
        };
      } else if (info.type === 'boolean') {
        input.type = INPUT_TYPES.SWITCH;
        input.defaultValue = info.defaultValue;
        input.autoFocus = false;
      } else if (info.type === 'manager_email_editor') {
        input.type = INPUT_TYPES.AUTOSUGGEST;
        input.autoFocus = false;
        input.getSuggestionValue = (x) => {
          return x.object.email;
        };
        input.elasticsearchOptions = {
          url: 'get-survey-responses-by-name',
          getQuery: (query) => {
            return { name: query, campaign_id: props.campaign_id };
          },
        };
      }
      return input;
    });

    // put FIELD_NAMES_TO_PRIORITIZE first in UI
    const inputsAsObject = unorderedInputs.reduce((acc, input) => {
      acc[mappings?.keys[input.name]] = input;
      return acc;
    }, {});

    const prioritizedInputs = FIELD_NAMES_TO_PRIORITIZE.map(
      (i) => inputsAsObject[i]
    ).filter((x) => !!x);
    const otherInputs = unorderedInputs.filter(
      (i) =>
        FIELD_NAMES_TO_PRIORITIZE.indexOf(mappings?.keys[i.name]) === -1 &&
        FIELD_NAMES_TO_DEPRIORITIZE.indexOf(mappings?.keys[i.name]) === -1
    );
    const deprioritizedInputs = FIELD_NAMES_TO_DEPRIORITIZE.map(
      (i) => inputsAsObject[i]
    ).filter((x) => !!x);

    return [...prioritizedInputs, ...otherInputs, ...deprioritizedInputs];
  }, [fields, readOnlyFields, mappings?.keys, props.campaign_id]);

  // translate from external (e.g. survey response config) data to useful format for this modal
  const importData = useCallback(
    (data, fields) => {
      let modalData = { id: configs.id };
      Object.entries(fields).forEach(([name, info]) => {
        if (name in data) {
          if (info.type === 'date') {
            modalData[name] = data[name]
              ? parseDateFromUnicode(data[name], info.unicode)
              : null;
          } else if (info.type === 'choice') {
            modalData[name] =
              data[name] !== undefined && data[name] !== null
                ? info.options.find((x) => x.object === data[name])
                : undefined;
          } else if (info.type === 'boolean') {
            modalData[name] =
              data[name] !== undefined && data[name] !== null
                ? info.options.find((x) => x.object === data[name]).value
                : undefined;
          } else {
            modalData[name] = data[name];
          }
        } else {
          modalData[name] = undefined;
        }
      });
      return modalData;
    },
    [configs.id]
  );

  // translate from modal data to external (i.e. perf admin, survey response config) data
  const exportData = useCallback(
    (modalData) => {
      let data = { id: configs.id };
      Object.entries(fields).forEach(([name, info]) => {
        if (name in modalData) {
          if (info.type === 'date') {
            if (modalData[name] && typeof modalData[name] === 'object') {
              data[name] = formatDateWithUnicode(modalData[name], info.unicode);
            } else {
              data[name] = modalData[name];
            }
          } else if (info.type === 'choice') {
            if (modalData[name] && typeof modalData[name] === 'object') {
              data[name] = modalData[name].object;
            } else {
              data[name] = modalData[name];
            }
          } else if (info.type === 'boolean') {
            if (modalData[name] === undefined) {
              modalData[name] = info.defaultValue;
            }

            data[name] = modalData[name] ? info.trueChoice : info.falseChoice;
          } else if (info.type === 'manager_email_editor') {
            if (typeof modalData[name] === 'object') {
              data[reverseKeyMapping.manager_email] =
                modalData[name].object.email;
              if ('manager_full_name' in reverseKeyMapping) {
                data[reverseKeyMapping.manager_full_name] =
                  modalData[name].object.name;
              }
              if ('manager_external_id' in reverseKeyMapping) {
                if (modalData[name].object.external_id) {
                  data[reverseKeyMapping.manager_external_id] =
                    modalData[name].object.external_id;
                }
              }
            } else {
              data[name] = modalData[name];
            }
          } else {
            data[name] = modalData[name];
          }
        } else {
          data[name] = undefined;
        }
      });
      return data;
    },
    [reverseKeyMapping, fields, configs.id]
  );

  const dataObject = useMemo(() => {
    if (!fields || !configs) {
      return;
    }
    return importData(configs, fields);
  }, [configs, fields, importData]);

  return (
    <ModalEditor
      size="lg"
      method="PATCH"
      url="/survey-response-configs"
      title={`${formatMessage({
        id: 'app.views.widgets.modals.modal_dataset_record_editor.title.edit',
        defaultMessage: 'Edit',
      })} ${
        configs[reverseKeyMapping.preferred_given_name] ||
        configs[reverseKeyMapping.legal_given_name] ||
        configs[reverseKeyMapping.given_name]
      } ${configs[reverseKeyMapping.family_name]} (${props.campaign_name})`}
      submitText={formatMessage({
        id: 'app.views.widgets.modals.modal_dataset_record_editor.submit_text.save',
        defaultMessage: 'Save',
      })}
      renderInputs={renderInputs}
      isHorizontalLayout={true}
      object={dataObject}
      inputs={inputs}
      isOpen={props.isOpen}
      toggle={props.toggle}
      onClosed={props.onClosed}
      transformObjectBeforeSubmit={exportData}
      callback={props.onSaved}
      belowFormContents={belowFormContents}
    />
  );
};

ModalDatasetRecordEditor.defaultProps = {
  aspiration: {},
};

ModalDatasetRecordEditor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  onSaved: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  aspiration: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(ModalDatasetRecordEditor));
