import { Col, Container, Row } from 'reactstrap';
import React from 'react';

import { FormattedMessage } from 'react-intl';

const AppIntegrationPostAuthPage = () => {
  return (
    <Container>
      <Row>
        <Col className="bg-white justify-content-center text-center">
          <h2 className="py-4 my-4">
            <FormattedMessage
              id="app.views.app_integrations.post_auth_page.header.text"
              defaultMessage="Processing successful."
            />
          </h2>
          <p>
            <FormattedMessage
              id="app.views.app_integrations.post_auth_page.time"
              defaultMessage="It might take some time for the change to take effect."
            />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AppIntegrationPostAuthPage;
