import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import { ResponsiveNetwork } from '@nivo/network';

const NetworkGraphCard: FC<Props> = (props) => {
  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        {/* @ts-expect-error */}
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {props.data ? (
          <Row>
            <Col className="col-12 col-sm-4">
              {/* @ts-expect-error */}
              {props?.data?.content ? (
                // @ts-expect-error
                props?.data?.content
              ) : (
                // @ts-expect-error
                <EmptyState title={props.emptystate} />
              )}
            </Col>
            <Col className="col-12 col-sm-8">
              <ResponsiveNetwork
                // @ts-expect-error
                data={props.data.data}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                linkDistance={function (e) {
                  // @ts-expect-error
                  return e.distance * 9;
                }}
                centeringStrength={0.3}
                repulsivity={6}
                nodeSize={function (n) {
                  // @ts-expect-error
                  return n.size;
                }}
                activeNodeSize={function (n) {
                  // @ts-expect-error
                  return 1.5 * n.size;
                }}
                nodeColor={function (e) {
                  // @ts-expect-error
                  return e.color;
                }}
                nodeBorderWidth={1}
                nodeBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.8]],
                }}
                linkThickness={function (n) {
                  // @ts-expect-error
                  return 2 + 2 * n.target.data.height;
                }}
                linkBlendMode="multiply"
                motionConfig="wobbly"
              />
            </Col>
          </Row>
        ) : (
          // @ts-expect-error
          <EmptyState title={props.emptyStateText} />
        )}
      </CardBody>
    </Card>
  );
};

const NetworkGraphCard_propTypes = {
  color: PropTypes.string,
  fieldSingular: PropTypes.string,
  fieldPlural: PropTypes.string,
  data: PropTypes.object.isRequired,
  className: PropTypes.object,
  emptyStateText: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

type Props = PropTypes.InferProps<typeof NetworkGraphCard_propTypes>;

export default React.memo(NetworkGraphCard);
