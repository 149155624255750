import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { ReactNode } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PeopleTable from '../PeopleTable';

interface LeaderboardCardProps {
  contentId: string;
  data?: {
    leaderboard?: Array<Array<unknown>>;
    content?: ReactNode;
  };
  className?: string;
  emptyStateText?: string;
  shouldShowInfluence?: boolean;
  style?: React.CSSProperties;
  title: string;
  role?: string;
  showRating?: boolean;
}

const LeaderboardCard: React.FC<LeaderboardCardProps> = ({
  className,
  role,
  style,
  title,
  data,
  contentId,
  shouldShowInfluence = true,
  showRating = true,
  emptyStateText,
}) => (
  <Card className={className} role={role} style={style}>
    <CardHeader>
      <CardHeaderTitle>{title}</CardHeaderTitle>
    </CardHeader>
    <CardBody>
      {data ? (
        <Row>
          <Col sm="12" md="12" lg="6" xxl="8" className="mb-sm-4">
            <PeopleTable
              contentId={contentId + '-table'}
              people={data.leaderboard}
              shouldShowInfluence={shouldShowInfluence}
              showRating={showRating}
            />
          </Col>
          <Col sm="12" md="12" lg="6" xxl="4" className="mb-sm-4">
            {data.content ?? <EmptyState title={emptyStateText} />}
          </Col>
        </Row>
      ) : (
        <EmptyState title={emptyStateText} />
      )}
    </CardBody>
  </Card>
);

export default React.memo(LeaderboardCard);
