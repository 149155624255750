import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import { ResponsiveChord } from '@nivo/chord';

const ChordGraphCard: FC<Props> = (props) => {
  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        {/* @ts-expect-error */}
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {props.data ? (
          <Row>
            <Col className="col-12 col-sm-4">
              {/* @ts-expect-error */}
              {props?.data?.content ? (
                // @ts-expect-error
                props?.data?.content
              ) : (
                // @ts-expect-error
                <EmptyState title={props.emptystate} />
              )}
            </Col>
            <Col className="col-12 col-sm-8">
              <ResponsiveChord
                // @ts-expect-error
                data={props.data.data.values}
                // @ts-expect-error
                keys={props.data.data.labels}
                margin={
                  // @ts-expect-error
                  props.data.margin || {
                    top: 45,
                    right: 30,
                    bottom: 45,
                    left: 30,
                  }
                }
                valueFormat=".2f"
                padAngle={0.02}
                innerRadiusRatio={0.96}
                innerRadiusOffset={0.02}
                inactiveArcOpacity={0.25}
                arcBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.6]],
                }}
                activeRibbonOpacity={0.75}
                inactiveRibbonOpacity={0.25}
                ribbonBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.6]],
                }}
                labelRotation={-90}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1]],
                }}
                // @ts-expect-error
                colors={props.data.colors || { scheme: 'dark2' }}
                motionConfig="stiff"
              />
            </Col>
          </Row>
        ) : (
          // @ts-expect-error
          <EmptyState title={props.emptyStateText} />
        )}
      </CardBody>
    </Card>
  );
};

const ChordGraphCard_propTypes = {
  color: PropTypes.string,
  fieldSingular: PropTypes.string,
  fieldPlural: PropTypes.string,
  data: PropTypes.object.isRequired,
  className: PropTypes.object,
  emptyStateText: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

type Props = PropTypes.InferProps<typeof ChordGraphCard_propTypes>;

export default React.memo(ChordGraphCard);
