import * as consts from '../../consts/consts';

import { Person, ReduxState } from 'types';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import { SKILL_TYPE_BEHAVIOR } from '../../utils/models/Skill';
import SkillActivities from './SkillActivities';
import SkillDashboard from './SkillDashboard';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { titleCase } from 'title-case';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

interface Props {
  currentProxyPerson: Person;
}

const SkillPage: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [isMounted, setIsMounted] = useState(false);
  const [peopleCount, setPeopleCount] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const params = useParams<{ id: string }>();
  const skillId = params.id;

  const [skill, setSkill] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useAuth0();
  const userSub = user?.sub;

  // fetch activities associated with this person
  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (userSub && typeof skill === 'undefined') {
      ConfirmAPI.getObject(
        userSub,
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.SKILLS,
        encodeURIComponent(skillId),
        (data) => {
          if (isMounted) {
            if (data && isNaN(parseInt(skillId))) {
              history.replace(consts.SKILLS().path + '/' + data.id);
            } else {
              setSkill(data);
            }
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [isMounted, userSub, skillId, skill, props.currentProxyPerson, history]);

  const loadOrRenderOutput = loadOrRender(skill, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  const tabs = [
    {
      path: consts.SKILLS().path + '/' + skillId,
      name: formatMessage({
        id: 'app.views.skills.skill_page.tab.label.dashboard',
        defaultMessage: 'People',
      }),
      content: (
        <SkillDashboard
          setPeopleCount={setPeopleCount}
          skillId={skillId}
          // @ts-expect-error
          skill={skill}
        />
      ),
    },
    {
      path: consts.SKILLS().path + '/' + skillId + '/activities',
      name: formatMessage({
        id: 'app.views.skills.skill_page.tab.label.activities',
        defaultMessage: 'Activities',
      }),
      content: <SkillActivities skillId={skillId} skill={skill} />,
    },
  ];

  return (
    <>
      {!skill && <Loading />}
      <Page
        avatarIcon={
          // @ts-expect-error
          skill?.type === SKILL_TYPE_BEHAVIOR.id
            ? consts.ICONS.BEHAVIOR
            : consts.ICONS.SKILL
        }
        pretitle={
          // @ts-expect-error
          skill?.type === SKILL_TYPE_BEHAVIOR.id
            ? formatMessage({
                id: 'app.views.skills.skill_page.heading.label.behavior',
                defaultMessage: 'Behavior',
              })
            : formatMessage({
                id: 'app.views.skills.skill_page.heading.label.skill',
                defaultMessage: 'Skill',
              })
        }
        // @ts-expect-error
        title={skill?.name ? titleCase(skill.name) : ''}
        subtitle={
          typeof peopleCount !== 'undefined'
            ? formatMessage(
                {
                  id: 'app.views.skills.skill_page.heading.label.people',
                  defaultMessage: '{numOfPeople} with experience',
                },
                { numOfPeople: peopleCount }
              )
            : undefined
        }
        tabs={tabs}
      />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentProxyPerson } = state;

  return {
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(SkillPage));
