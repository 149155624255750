import * as consts from '../../../consts/consts';

import React, { FC } from 'react';

import ObjectCard from './ObjectCard';
import PropTypes from 'prop-types';
import { titleCase } from 'title-case';
import { useIntl } from 'react-intl';

const CredentialCard: FC<Props> = ({ size = 'sm', ...props }) => {
  const { formatMessage } = useIntl();
  // @ts-expect-error
  const name = titleCase(props.credential.name);
  const description = formatMessage({
    id: 'app.views.widgets.cards.credential_card.description',
    defaultMessage: 'Credential',
  });
  // @ts-expect-error
  const url = consts.CREDENTIALS().path + '/' + props.credential.id;

  return (
    <ObjectCard
      icon={consts.ICONS.CREDENTIAL}
      className={props.className}
      name={name}
      description={description}
      url={props.linked ? url : null}
      query={props.query}
      size={size}
      // @ts-expect-error
      role={props.role}
      style={props.style}
      bodyOnly={props.bodyOnly}
      inline={props.inline}
      inDropdown={props.inDropdown}
      closeButtonText={props.closeButtonText}
      onClose={props.onClose}
      // @ts-expect-error
      credential={props.credential}
      showEnrichedProfile={props.showEnrichedProfile}
      isExternalUrl={props.isExternalUrl}
      aside={props.aside}
      isValid={props.isValid}
    >
      {props.children}
    </ObjectCard>
  );
};

const CredentialCard_propTypes = {
  className: PropTypes.string,
  credential: PropTypes.object.isRequired,
  closeButtonText: PropTypes.string,
  onClose: PropTypes.func,
  bodyOnly: PropTypes.bool,
  inline: PropTypes.bool,
  inDropdown: PropTypes.bool,
  size: PropTypes.string,
  query: PropTypes.string,
  linked: PropTypes.bool,
  showEnrichedProfile: PropTypes.bool,
  style: PropTypes.object,
  isExternalUrl: PropTypes.bool,
  aside: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isValid: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof CredentialCard_propTypes>;

export default React.memo(CredentialCard);
