import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Organization, Person, PulseCheck } from '../../types';
import React, { FC } from 'react';

import Avatar from '../Widgets/People/Avatar';
import { FormattedMessage } from 'react-intl';
import LinkedPerson from '../Widgets/People/LinkedPerson';
import RelativeTime from '../Widgets/RelativeTime';
import SafeUncontrolledPopover from 'components/SafeUncontrolledPopover';
import StarRatingInput from '../Widgets/Inputs/StarRatingInput';
import { connect } from 'react-redux';

interface PulseCheckCardProps {
  bodyOnly?: boolean;
  avatarSize?: string;
  person: Person;
  pulseCheck: PulseCheck;
  toggleIsEditing?: () => void;
  onDelete?: () => void;
  onAdd?: () => void;
}

const PulseCheckCard: FC<PulseCheckCardProps> = (props) => {
  const object = props.pulseCheck;

  const output = (
    <Row className="flex-nowrap">
      <Col className="col-auto">
        <Avatar
          person={props.person}
          size={props.avatarSize ?? 'xs'}
          isExternalUrl={true}
        />
      </Col>
      <Col className="col ms-n3">
        <Row>
          <Col>
            <FormattedMessage
              id="app.views.pulse_checks.pulse_check_card.person_checked_in"
              defaultMessage="<bold>{name}</bold> checked in."
              values={{
                name: (
                  <LinkedPerson person={props.person} isExternalUrl={true} />
                ),
                bold: (chunks) => <span className="fw-bold">{chunks}</span>,
              }}
            />
          </Col>
        </Row>
        <div className="text-muted small mb-3">
          <RelativeTime unit="day" datetime={object.updated_at} />
          {
            <>
              {' '}
              <span
                id={'visibility-pulse-' + object.id}
                style={{ position: 'relative', top: '1px' }}
                className={'ms-1 fe fe-lock'}
              ></span>
              <SafeUncontrolledPopover
                placement="top"
                trigger="hover"
                target={'visibility-pulse-' + object.id}
              >
                <FormattedMessage
                  id="app.models.pulse_check.visibility.manager_only.popover"
                  defaultMessage="Visible to individual's manager and above and HR administrators"
                />
              </SafeUncontrolledPopover>
            </>
          }
        </div>
        <div className="comment-body d-block px-3 py-2">
          <Row>
            <Col>
              <StarRatingInput
                className="my-2"
                value={object.rating}
                disabled={true}
              />
              {object.rating_comments}
            </Col>
            {props.toggleIsEditing && (
              <Col
                className="col-auto pe-1 position-relative"
                style={{ top: '2px', right: '2px' }}
              >
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle
                    tag="button"
                    className="more-dropdown-button btn btn-sm"
                    role="button"
                  >
                    <i className="fe fe-more-horizontal"></i>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem onClick={props.toggleIsEditing}>
                      <FormattedMessage
                        id="app.views.pulse_checks.pulse_check_card.edit_check_in"
                        defaultMessage="Edit check-in"
                      />
                    </DropdownItem>
                    <DropdownItem onClick={props.onDelete}>
                      <FormattedMessage
                        id="app.views.pulse_checks.pulse_check_card.delete_check_in"
                        defaultMessage="Delete check-in"
                      />
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={props.onAdd}>
                      <FormattedMessage
                        id="app.views.pulse_checks.pulse_check_card.add_new_check_in"
                        defaultMessage="Add new check-in"
                      />
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );

  if (props.bodyOnly) {
    return output;
  }

  return (
    <Card>
      <CardBody>{output}</CardBody>
    </Card>
  );
};

interface RootState {
  currentOrganization: Organization;
  currentProxyPerson: Person;
}

const mapStateToProps = (state: RootState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return { currentOrganization, currentProxyPerson };
};

export default connect(mapStateToProps)(React.memo(PulseCheckCard));
