import { Button, Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import React, { FC, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import Modal from '../../../components/SafeModal';
import PropTypes from 'prop-types';
import { renderValidationError } from '../../../utils/util/formatter';

const ConfirmationDialogModal: FC<Props> = ({
  confirmationButtonColor = 'danger',
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmText = props.confirmText ? (
    props.confirmText
  ) : (
    <FormattedMessage
      id="app.widgets.modals.confirmation_dialog_modal.confirm"
      defaultMessage="Confirm"
      description="The default text for the confirm button in a simple popup windows that asks 'Confirm' or 'Cancel'."
    />
  );
  const cancelText = props.cancelText ? (
    props.cancelText
  ) : (
    <FormattedMessage
      id="app.widgets.modals.confirmation_dialog_modal.cancel"
      defaultMessage="Cancel"
      description="The default text for the cancel button in a simple popup windows that asks 'Confirm' or 'Cancel'."
    />
  );

  const onConfirm = () => {
    setIsSubmitting(true);
    // note: control of closing this modal is handled by the creator of this component
    props.confirmCallback();
  };

  const onClosed = () => {
    // enable reuse of this dialog by resetting it
    setIsSubmitting(false);

    if (props.onClosed) {
      props.onClosed();
    }
  };

  const isReadyForInput = props.validationErrors || !isSubmitting;

  return (
    <Modal
      // prevent Esc from closing editor (to avoid issues e.g.
      // when someone escapes file dialog and presses twice)
      keyboard={false}
      // prevent hiding when clicking outside
      backdrop="static"
      isOpen={props.isOpen}
      onClosed={onClosed}
      toggle={props.toggle}
      // @ts-expect-error
      zIndex={props.zIndex}
    >
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
      <ModalBody>
        {props.description}
        <Row className="mt-4">
          <Col className="col-md-6">
            <Button
              color="light"
              className="btn-block w-100"
              onClick={props.toggle}
            >
              {cancelText}
            </Button>
          </Col>
          <Col className="col-md-6">
            <Button
              disabled={!isReadyForInput}
              // @ts-expect-error
              color={confirmationButtonColor}
              className="btn-block w-100"
              onClick={onConfirm}
            >
              {isReadyForInput && confirmText}
              {!isReadyForInput && (
                <div
                  className="spinner-border"
                  style={{ width: '1rem', height: '1rem' }}
                />
              )}
            </Button>
          </Col>
        </Row>
        {props.validationErrors && (
          <div
            className={`text-center pt-2 message ${
              props.validationErrors && 'error'
            }`}
          >
            <span className="text-danger">
              {renderValidationError(props.validationErrors)}
            </span>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

const ConfirmationDialogModal_propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClosed: PropTypes.func,
  toggle: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  confirmationButtonColor: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  confirmText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cancelText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  validationErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

type Props = PropTypes.InferProps<typeof ConfirmationDialogModal_propTypes>;

export default React.memo(ConfirmationDialogModal);
