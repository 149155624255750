import { useEffect, useState } from 'react';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { OrgChartRelatives } from '../../types';
import { formatErrorMessage } from 'utils/util/error';
import { getValidDirectReports } from '../../utils/models/Person';
import { useAuth0 } from '@auth0/auth0-react';

const usePersonOrgChartRelatives = (
  currentOrganization,
  currentProxyPerson,
  person
) => {
  const [orgChartRelatives, setOrgChartRelatives] = useState<
    OrgChartRelatives | undefined
  >(undefined);
  const [isMounted, setIsMounted] = useState(false);
  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted || !person) {
      return;
    }

    const params = {
      person: person.id,
      organization: currentOrganization?.id,
    };

    ConfirmAPI.getUrlWithCache(
      '/get-org-chart-relatives',
      'get-org-chart-relatives-self',
      userSub,
      currentProxyPerson,
      params,
      (data) => {
        if (isMounted) {
          if (data?.results) {
            setOrgChartRelatives({
              manager: data.results.manager ? data.results.manager : null,
              above_manager: data.results.above_manager,
              direct_reports: getValidDirectReports(
                data.results.direct_reports
              ),
              peers: data.results.peers,
            });
          }
        }
      },
      (message) => {
        // instead of hard-failing the entire page, log the error but display nothing
        // in the "view team member objectives" side area
        console.error(
          'Unable to fetch org chart relatives: ' + formatErrorMessage(message)
        );
        setOrgChartRelatives({
          manager: null,
          above_manager: [],
          direct_reports: [],
          peers: [],
        });
      }
    );
  }, [isMounted, currentOrganization?.id, currentProxyPerson, person, userSub]);

  return orgChartRelatives;
};

export default usePersonOrgChartRelatives;
