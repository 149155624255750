import {
  AvailableMetricConfig,
  hasNoUpperBound,
  renderNumber,
} from '../TalentMatrix';
import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import { FormattedMessage } from 'react-intl';

interface Props {
  metric: AvailableMetricConfig;
}

const Legend: FC<Props> = ({ metric }) => {
  const noUpperBound = hasNoUpperBound(metric.possible_values);
  return (
    <div>
      <Row>
        <h4>{metric.name}</h4>
      </Row>

      {!noUpperBound && (
        <>
          {metric.possible_values.map((it, idx) => (
            <Row key={idx}>
              <Col className="col-2">
                <strong>{renderNumber(it)}</strong>
              </Col>
              <Col>{renderNumber(metric.possible_labels[idx])}</Col>
            </Row>
          ))}
        </>
      )}
      {noUpperBound && (
        <FormattedMessage
          id="app.views.talent_matrix.talent_matrix_grid_editor.legend.no_upper_bound"
          defaultMessage="The number of {metric}, from 0 to ∞"
          values={{ metric: metric.name }}
        />
      )}
    </div>
  );
};

export default React.memo(Legend);
