const chordData = {
  labels: [
    'Product',
    'Engineering',
    'Finance',
    'Sales',
    'Marketing',
    'Operations',
    'Executive',
    'Legal',
  ],
  values: [
    [274, 64, 54, 15, 1, 0, 2, 8],
    [20, 25, 11, 8, 12, 5, 6, 4],
    [15, 13, 9, 26, 28, 18, 0, 15],
    [25, 12, 17, 9, 0, 20, 0, 6],
    [15, 9, 15, 4, 10, 21, 28, 6],
    [20, 0, 33, 10, 0, 23, 16, 135],
    [0, 14, 20, 28, 3, 33, 20, 5],
    [1, 7, 3, 3, 3, 1, 0, 4],
  ],
};

const chordLevelData = {
  labels: ['L1', 'L2', 'L3', 'L4', 'E1', 'E2', 'M3', 'M4'],
  values: [
    [20, 25, 11, 8, 12, 5, 6, 4],
    [15, 13, 9, 26, 28, 18, 1, 15],
    [10, 10, 11, 14, 5, 8, 23, 18],
    [274, 64, 54, 15, 1, 0, 2, 8],
    [25, 12, 17, 9, 8, 20, 19, 6],
    [20, 20, 33, 10, 13, 23, 16, 19],
    [4, 64, 54, 15, 0, 8, 236, 10],
    [1, 7, 3, 3, 3, 1, 0, 4],
  ],
};

const chordLevelTeamData = {
  labels: [
    '(FocalPoint) L1',
    '(B.Riley) L1',
    '(FocalPoint) L2',
    '(B.Riley) L2',
    '(FocalPoint) L3',
    '(B.Riley) L3',
    '(FocalPoint) M4',
    '(B.Riley) M4',
    '(FocalPoint) M5',
    '(B.Riley) M5',
  ],
  values: [
    [20, 25, 11, 8, 12, 5, 6, 4, 21, 34],
    [15, 13, 9, 26, 28, 18, 1, 15, 27, 33],
    [10, 10, 11, 14, 5, 8, 23, 18, 33, 20],
    [274, 64, 54, 15, 1, 0, 2, 8, 6, 10],
    [25, 12, 17, 9, 8, 20, 19, 6, 9, 11],
    [15, 9, 15, 4, 10, 21, 28, 6, 8, 29],
    [30, 20, 35, 30, 30, 26, 31, 14, 20, 13],
    [20, 20, 33, 10, 13, 23, 16, 19, 9, 19],
    [4, 64, 54, 15, 1, 0, 2, 8, 236, 10],
    [1, 7, 3, 3, 3, 1, 0, 4, 1, 4],
  ],
};

const chordFunctionTeamSourceData = {
  labels: [
    'FocalPoint',
    'B.Riley',
    'Product',
    'Engineering',
    'Sales',
    'Legal',
    'Finance',
  ],
  values: [
    [0, 0, 84, 64, 27, 15, 36],
    [0, 0, 96, 80, 28, 18, 21],
    [40, 10, 0, 0, 0, 0, 0],
    [50, 24, 0, 0, 0, 0, 0],
    [25, 12, 0, 0, 0, 0, 0],
    [15, 9, 0, 0, 0, 0, 0],
    [30, 10, 0, 0, 0, 0, 0],
  ],
};

const chordLevelTeamSourceData = {
  labels: ['FocalPoint', 'B.Riley', 'L1', 'L2', 'L3', 'M4', 'M5'],
  values: [
    [0, 0, 84, 64, 27, 15, 36],
    [0, 0, 96, 80, 28, 18, 21],
    [40, 10, 0, 0, 0, 0, 0],
    [50, 24, 0, 0, 0, 0, 0],
    [25, 12, 0, 0, 0, 0, 0],
    [15, 9, 0, 0, 0, 0, 0],
    [30, 10, 0, 0, 0, 0, 0],
  ],
};

const sankeyData = {
  nodes: [
    {
      id: 'FocalPoint (Sent)',
      nodeColor: 'hsl(154, 70%, 50%)',
    },
    {
      id: 'B.Riley (Sent)',
      nodeColor: 'hsl(268, 70%, 50%)',
    },
    {
      id: 'FocalPoint (Received)',
      nodeColor: 'hsl(268, 70%, 50%)',
    },
    {
      id: 'B.Riley (Received)',
      nodeColor: 'hsl(154, 70%, 50%)',
    },
  ],
  links: [
    {
      source: 'B.Riley (Sent)',
      target: 'B.Riley (Received)',
      value: 72,
    },
    {
      source: 'B.Riley (Sent)',
      target: 'FocalPoint (Received)',
      value: 12,
    },
    {
      source: 'FocalPoint (Sent)',
      target: 'FocalPoint (Received)',
      value: 135,
    },
    {
      source: 'FocalPoint (Sent)',
      target: 'B.Riley (Received)',
      value: 45,
    },
  ],
};

const networkData = {
  nodes: [
    { id: 'Person 1', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 2', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 3', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 4', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 5', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 6', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 7', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 8', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 9', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 10', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 11', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 12', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 13', height: 0, size: 12, color: 'rgb(232, 193, 160)' },
    { id: 'Person 14', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 15', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 16', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 17', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 18', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 19', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 20', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 21', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 22', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 23', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 24', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 25', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 26', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 27', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 28', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 29', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 30', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 31', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 32', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
    { id: 'Person 33', height: 0, size: 12, color: 'rgb(97, 205, 187)' },
  ],
  links: [
    {
      source: 'Person 1',
      target: 'Person 2',
      distance: 23,
    },
    {
      source: 'Person 1',
      target: 'Person 5',
      distance: 1,
    },
    {
      source: 'Person 1',
      target: 'Person 8',
      distance: 37,
    },
    {
      source: 'Person 1',
      target: 'Person 11',
      distance: 44,
    },
    {
      source: 'Person 1',
      target: 'Person 24',
      distance: 32,
    },
    {
      source: 'Person 1',
      target: 'Person 25',
      distance: 41,
    },
    {
      source: 'Person 1',
      target: 'Person 30',
      distance: 2,
    },
    {
      source: 'Person 2',
      target: 'Person 6',
      distance: 34,
    },
    {
      source: 'Person 2',
      target: 'Person 8',
      distance: 28,
    },
    {
      source: 'Person 2',
      target: 'Person 9',
      distance: 20,
    },
    {
      source: 'Person 2',
      target: 'Person 11',
      distance: 46,
    },
    {
      source: 'Person 2',
      target: 'Person 12',
      distance: 25,
    },

    {
      source: 'Person 2',
      target: 'Person 20',
      distance: 4,
    },
    {
      source: 'Person 2',
      target: 'Person 22',
      distance: 42,
    },
    {
      source: 'Person 2',
      target: 'Person 28',
      distance: 2,
    },
    {
      source: 'Person 2',
      target: 'Person 29',
      distance: 22,
    },
    {
      source: 'Person 3',
      target: 'Person 4',
      distance: 3,
    },
    {
      source: 'Person 3',
      target: 'Person 12',
      distance: 41,
    },
    {
      source: 'Person 3',
      target: 'Person 16',
      distance: 9,
    },
    {
      source: 'Person 3',
      target: 'Person 18',
      distance: 16,
    },
    {
      source: 'Person 3',
      target: 'Person 23',
      distance: 38,
    },
    {
      source: 'Person 3',
      target: 'Person 28',
      distance: 9,
    },
    {
      source: 'Person 3',
      target: 'Person 30',
      distance: 45,
    },
    {
      source: 'Person 4',
      target: 'Person 2',
      distance: 41,
    },
    {
      source: 'Person 4',
      target: 'Person 16',
      distance: 36,
    },
    {
      source: 'Person 4',
      target: 'Person 18',
      distance: 5,
    },
    {
      source: 'Person 4',
      target: 'Person 20',
      distance: 31,
    },
    {
      source: 'Person 4',
      target: 'Person 22',
      distance: 21,
    },
    {
      source: 'Person 5',
      target: 'Person 16',
      distance: 35,
    },
    {
      source: 'Person 5',
      target: 'Person 19',
      distance: 0,
    },
    {
      source: 'Person 5',
      target: 'Person 20',
      distance: 40,
    },
    {
      source: 'Person 6',
      target: 'Person 2',
      distance: 49,
    },
    {
      source: 'Person 6',
      target: 'Person 3',
      distance: 32,
    },
    {
      source: 'Person 6',
      target: 'Person 8',
      distance: 5,
    },
    {
      source: 'Person 6',
      target: 'Person 12',
      distance: 1,
    },
    {
      source: 'Person 6',
      target: 'Person 14',
      distance: 14,
    },
    {
      source: 'Person 6',
      target: 'Person 15',
      distance: 7,
    },
    {
      source: 'Person 6',
      target: 'Person 16',
      distance: 18,
    },
    {
      source: 'Person 6',
      target: 'Person 19',
      distance: 23,
    },
    {
      source: 'Person 6',
      target: 'Person 20',
      distance: 46,
    },
    {
      source: 'Person 6',
      target: 'Person 24',
      distance: 15,
    },
    {
      source: 'Person 7',
      target: 'Person 6',
      distance: 41,
    },
    {
      source: 'Person 7',
      target: 'Person 18',
      distance: 25,
    },
    {
      source: 'Person 7',
      target: 'Person 19',
      distance: 3,
    },
    {
      source: 'Person 7',
      target: 'Person 24',
      distance: 32,
    },
    {
      source: 'Person 7',
      target: 'Person 27',
      distance: 25,
    },
    {
      source: 'Person 8',
      target: 'Person 1',
      distance: 49,
    },
    {
      source: 'Person 8',
      target: 'Person 4',
      distance: 30,
    },
    {
      source: 'Person 8',
      target: 'Person 6',
      distance: 41,
    },
    {
      source: 'Person 8',
      target: 'Person 7',
      distance: 43,
    },
    {
      source: 'Person 8',
      target: 'Person 9',
      distance: 18,
    },
    {
      source: 'Person 8',
      target: 'Person 12',
      distance: 35,
    },
    {
      source: 'Person 8',
      target: 'Person 17',
      distance: 6,
    },
    {
      source: 'Person 8',
      target: 'Person 19',
      distance: 38,
    },
    {
      source: 'Person 8',
      target: 'Person 20',
      distance: 43,
    },
    {
      source: 'Person 8',
      target: 'Person 27',
      distance: 12,
    },
    {
      source: 'Person 8',
      target: 'Person 28',
      distance: 5,
    },
    {
      source: 'Person 8',
      target: 'Person 29',
      distance: 44,
    },
    {
      source: 'Person 9',
      target: 'Person 10',
      distance: 20,
    },
    {
      source: 'Person 9',
      target: 'Person 11',
      distance: 45,
    },
    {
      source: 'Person 9',
      target: 'Person 16',
      distance: 49,
    },
    {
      source: 'Person 9',
      target: 'Person 17',
      distance: 15,
    },
    {
      source: 'Person 9',
      target: 'Person 18',
      distance: 49,
    },
    {
      source: 'Person 9',
      target: 'Person 20',
      distance: 4,
    },
    {
      source: 'Person 9',
      target: 'Person 21',
      distance: 34,
    },
    {
      source: 'Person 9',
      target: 'Person 23',
      distance: 3,
    },
    {
      source: 'Person 10',
      target: 'Person 2',
      distance: 35,
    },
    {
      source: 'Person 10',
      target: 'Person 3',
      distance: 25,
    },
    {
      source: 'Person 10',
      target: 'Person 8',
      distance: 37,
    },
    {
      source: 'Person 10',
      target: 'Person 12',
      distance: 10,
    },
    {
      source: 'Person 10',
      target: 'Person 26',
      distance: 15,
    },
    {
      source: 'Person 10',
      target: 'Person 30',
      distance: 8,
    },
    {
      source: 'Person 11',
      target: 'Person 1',
      distance: 9,
    },
    {
      source: 'Person 11',
      target: 'Person 7',
      distance: 1,
    },
    {
      source: 'Person 11',
      target: 'Person 10',
      distance: 6,
    },
    {
      source: 'Person 11',
      target: 'Person 12',
      distance: 1,
    },
    {
      source: 'Person 11',
      target: 'Person 16',
      distance: 12,
    },
    {
      source: 'Person 11',
      target: 'Person 26',
      distance: 9,
    },
    {
      source: 'Person 12',
      target: 'Person 8',
      distance: 19,
    },
    {
      source: 'Person 12',
      target: 'Person 10',
      distance: 17,
    },
    {
      source: 'Person 12',
      target: 'Person 21',
      distance: 34,
    },
    {
      source: 'Person 12',
      target: 'Person 24',
      distance: 18,
    },
    {
      source: 'Person 12',
      target: 'Person 25',
      distance: 4,
    },
    {
      source: 'Person 12',
      target: 'Person 26',
      distance: 3,
    },
    {
      source: 'Person 12',
      target: 'Person 27',
      distance: 3,
    },
    {
      source: 'Person 12',
      target: 'Person 29',
      distance: 26,
    },
    {
      source: 'Person 13',
      target: 'Person 6',
      distance: 1,
    },
    {
      source: 'Person 14',
      target: 'Person 6',
      distance: 32,
    },
    {
      source: 'Person 15',
      target: 'Person 6',
      distance: 32,
    },
    {
      source: 'Person 31',
      target: 'Person 15',
      distance: 5,
    },
    {
      source: 'Person 32',
      target: 'Person 15',
      distance: 5,
    },
    {
      source: 'Person 33',
      target: 'Person 15',
      distance: 5,
    },
  ],
};

export const sampleData = {
  chordData,
  chordLevelData,
  chordLevelTeamData,
  chordFunctionTeamSourceData,
  chordLevelTeamSourceData,
  sankeyData,
  networkData,
};
