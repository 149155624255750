import React, { FC, useCallback, useState } from 'react';

import { Button } from 'reactstrap';
import ConfirmationDialogModal from 'views/Widgets/Modals/ConfirmationDialogModal';
import { useIntl } from 'react-intl';

interface Props {
  onDelete: () => void;
}

const DeleteModalButton: FC<Props> = ({ onDelete }) => {
  const { formatMessage } = useIntl();

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = useCallback(
    () => setConfirmDeleteModal(!confirmDeleteModal),
    [confirmDeleteModal]
  );

  const confirmDeleteCallback = useCallback(() => {
    onDelete();
    toggleConfirmDeleteModal();
  }, [onDelete, toggleConfirmDeleteModal]);

  return (
    <>
      <ConfirmationDialogModal
        isOpen={confirmDeleteModal}
        toggle={toggleConfirmDeleteModal}
        confirmCallback={confirmDeleteCallback}
        title={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.title',
          defaultMessage: 'Delete this item?',
        })}
        description={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.description',
          defaultMessage: 'Are you sure that you want to delete this item?',
        })}
        confirmText={formatMessage({
          id: 'app.views.widgets.inputs.table_editor.confirm_delete.confirm_text',
          defaultMessage: 'Delete item',
        })}
      />
      <Button className="btn btn-white" onClick={toggleConfirmDeleteModal}>
        <i className="fe fe-trash-2" />
      </Button>
    </>
  );
};

export default DeleteModalButton;
