import {
  PERFORMANCE_FEATURE_ASSESS_MANAGER,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_HIGH_PRIORITY_PEERS,
  PERFORMANCE_FEATURE_PEER_FEEDBACK,
  PHASE_TYPE_EVALUATION,
  WIZARD_TYPE_PERFORMANCE,
  getCampaignHasFeatureEnabled,
  isWizardType,
} from '../../../utils/models/Performance';

import { Campaign } from '../../../types';
import { getPhaseByType } from '../../../utils/models/Campaign';
import { useMemo } from 'react';

export type CampaingToggles = {
  peer360sAreEnabled: boolean;
  upwardFeedbackisEnabled: boolean;
  evaluationPhaseIsEnabled: boolean;
  managerRatingsOfDirectsIsEnabled: boolean;
  highPriorityPeersAreEnabled: boolean;
};

export const useCampaingToggles = (campaign: Campaign): CampaingToggles => {
  const peer360sAreEnabled = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PEER_FEEDBACK
      ) && isWizardType(campaign, WIZARD_TYPE_PERFORMANCE),
    [campaign]
  );

  const upwardFeedbackisEnabled = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_ASSESS_MANAGER
      ) && isWizardType(campaign, WIZARD_TYPE_PERFORMANCE),
    [campaign]
  );

  const evaluationPhaseIsEnabled = useMemo(
    () => getPhaseByType(campaign, PHASE_TYPE_EVALUATION),
    [campaign]
  );

  const managerRatingsOfDirectsIsEnabled = useMemo(
    () =>
      evaluationPhaseIsEnabled &&
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      ),
    [evaluationPhaseIsEnabled, campaign]
  );

  const highPriorityPeersAreEnabled = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIGH_PRIORITY_PEERS
      ) && isWizardType(campaign, WIZARD_TYPE_PERFORMANCE),
    [campaign]
  );

  return {
    peer360sAreEnabled,
    upwardFeedbackisEnabled,
    evaluationPhaseIsEnabled,
    managerRatingsOfDirectsIsEnabled,
    highPriorityPeersAreEnabled,
  };
};
