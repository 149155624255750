import * as consts from '../../../consts/consts';
import { Campaign, NewCampaignWithConfigs, Phase } from '../../../types';
import {
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_SELF,
  WIZARD_TYPE_ENGAGEMENT_ONLY,
  WIZARD_TYPE_ENPS_ONLY,
  WIZARD_TYPE_ONA_ONLY,
  isWizardType,
} from '../../../utils/models/Performance';

import { INPUT_TYPES } from '../../Widgets/Inputs/ValidatedInputTypes';
import { getPhaseOpenedInThePast } from '../../../utils/models/Campaign';
import { getMMDDYYYYStringFromDate } from 'utils/util/util';

export interface PropsListOptions {
  disableSelfReflection: boolean;
  disableUpwardFeedback: boolean;
  disableEnps: boolean;
  disableEngagementSurvey: boolean;
  show360Features: boolean;
  isSuperAdmin: boolean;
  enableEmployeeNPS: boolean;
  enableEngagementSurveySummaries: boolean;
}

export function applyCampaignPhaseDates(newCampaign: NewCampaignWithConfigs) {
  // set start date of the first phase to be the day after
  // the coverage_end_date, ensure phases don't overlap
  // since E and R will fail if they do.
  const cycleStartDate = new Date(
    new Date(newCampaign.coverage_end_date).getTime() +
      consts.ONE_DAY_IN_MILLISECONDS
  );

  // default each phase to one week long
  newCampaign.phases = newCampaign.phases.map((p, index) => ({
    ...p,
    start_date: getMMDDYYYYStringFromDate(
      new Date(
        cycleStartDate.getTime() + index * consts.ONE_WEEK_IN_MILLISECONDS
      )
    ),
    end_date: getMMDDYYYYStringFromDate(
      new Date(
        cycleStartDate.getTime() +
          (index + 1) * consts.ONE_WEEK_IN_MILLISECONDS -
          // Subtract one day so it doesn't overlap with the next phase start_date.
          consts.ONE_DAY_IN_MILLISECONDS
      )
    ),
  }));
}

export const preserveExistingAnswers = (propsList, campaign: Campaign) => {
  const openedPhases: string[] = campaign.phases
    .filter((_, i) => getPhaseOpenedInThePast(campaign, i))
    .map((phase) => phase.type);

  // add "preserveExistingAnswers" to all QuestionsEditor inputs if the phase
  // is opened. this logic is an 80:20, it would be better to check for
  // existing answers--but that logic is more complex and probably involves
  // modifying elasticsearch indexing
  propsList.forEach((phase) => {
    phase.inputs.forEach((input) => {
      if (input.type === INPUT_TYPES.QUESTIONS_EDITOR) {
        input.preserveExistingAnswers = openedPhases.includes(phase.type);
      }
    });
  });
};

export const removeEnpsIfUnset = (propsList, enableEmployeeNPS: boolean) => {
  propsList.forEach((phase) => {
    // temporary for internal testing: remove eNPS unless explicitly enabled
    if (phase.type === PHASE_TYPE_OTHERS && !enableEmployeeNPS) {
      phase.inputs = phase.inputs.filter(
        (input) => !input.name.startsWith('employee_nps_')
      );
    }
  });
};

// remove the Cycle Admin-only "Introduction UI phase" so the campaign can be saved to the backend
export const removeUICampaignPhase = (campaign) => {
  if (campaign.configs.introduction_ui_phase) {
    return campaign;
  }
  const restoredCampaign = {
    ...campaign,
    configs: {
      ...campaign.configs,
      introduction_ui_phase: campaign.phases[0],
    },
    phases: campaign.phases.slice(1),
  };

  return restoredCampaign;
};

// transform a backend campaign by adding a Cycle Admin-only "Introduction UI phase"
export const addUICampaignPhase = (campaign) => {
  if (!campaign.configs.introduction_ui_phase) {
    return campaign;
  }
  const modifiedCampaign = {
    ...campaign,
    phases: [campaign.configs.introduction_ui_phase, ...campaign.phases],
    configs: {
      ...campaign.configs,
      introduction_ui_phase: undefined,
    },
  };
  return modifiedCampaign;
};

export const turnOnONA = (phase: Phase) => {
  delete phase.disable_network;
  delete phase.disable_callouts;
};

export const turnOnEngagementSurvey = (phase: Phase) => {
  phase.assess_organization = true;
};

export const turnOnEnps = (phase: Phase) => {
  phase.employee_nps_question_enabled = true;
};

export const setObviousPhaseDefaults = (campaign: Campaign) => {
  campaign.phases.forEach((phase: Phase) => {
    if (
      isWizardType(campaign, WIZARD_TYPE_ONA_ONLY) &&
      phase.type === PHASE_TYPE_SELF
    ) {
      turnOnONA(phase);
    } else if (
      isWizardType(campaign, WIZARD_TYPE_ENGAGEMENT_ONLY) &&
      phase.type === PHASE_TYPE_OTHERS
    ) {
      turnOnEngagementSurvey(phase);
    } else if (
      isWizardType(campaign, WIZARD_TYPE_ENPS_ONLY) &&
      phase.type === PHASE_TYPE_OTHERS
    ) {
      turnOnEnps(phase);
    }
  });
  return campaign;
};
