import * as consts from '../../consts/consts';

import { Button, Col, Row } from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getCurrentPhaseIndex,
  getPerfCampaignIsInReportingPhaseOrClosed,
  getPhaseByType,
  getPhaseIsClosed,
  getPhaseIsOpen,
} from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_SELF,
  getCampaignHasFeatureEnabled,
  getCampaignHasONA,
} from '../../utils/models/Performance';
import React, { useCallback, useMemo } from 'react';
import {
  peopleIdsAreEqual,
  peopleObjectsAreEqual,
} from '../../utils/models/Person';

import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import { Link } from 'react-router-dom';
import ModalEditorButton from '../Widgets/Modals/ModalEditorButton';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import { getPersonIsParticipatingInPhase } from '../../utils/models/Relationship';
import { toast } from 'react-toastify';

const REPORT_EDIT_LINK_TYPE_NO_EDIT_LINK = 1;
const REPORT_EDIT_LINK_TYPE_LINK_TO_PERF = 2;
const REPORT_EDIT_LINK_TYPE_MODAL_EDIT = 3;

const PersonPerformanceAlertElement = (props) => (
  <div className="alert-light py-4 alert alert-success fade show" role="alert">
    <Row>
      <Col>
        <Row>{props.children}</Row>
      </Col>
    </Row>
  </div>
);

// NOTE: this should match models.py's backend for SurveyResponse.ConversationVisibility
const ConversationVisibility = {
  HR: 'H',
  MANAGER: 'M',
};

export const PersonPerformanceAlertRow = ({
  setSurveyResponse,
  surveyResponse,
  personPreferredName,
  campaign,
  person,
  editRelationship,
  managerRelationshipsWithFeedback,
  meId,
  incomingRelationshipsWithFeedback,
  isInReviewFlow,
  isMe,
  isEligibleForReporting,
  reportHasBeenReleased,
  personFullName,
  isAdminable,
  hasONAFeedback,
}) => {
  const { formatMessage } = useIntl();

  const onReleaseReport = useCallback(
    (data, error) => {
      if (data) {
        // update release time (but keep all other data as not all
        // is passed back from this call)
        setSurveyResponse({
          ...surveyResponse,
          released_at: data.released_at,
        });
        toast.success(
          formatMessage(
            {
              id: 'app.views.person.person_performance_alert_row.report_released_via_email_to',
              defaultMessage:
                'Report released via email to {personPreferredName}!',
            },
            { personPreferredName }
          )
        );
      } else {
        toast.error('Error: ' + error.toString());
      }
    },
    [personPreferredName, setSurveyResponse, surveyResponse, formatMessage]
  );

  const onUnreleaseReport = useCallback(
    (data, error) => {
      if (data) {
        // update release time (but keep all other data as not all
        // is passed back from this call)
        setSurveyResponse({
          ...surveyResponse,
          released_at: data.released_at,
        });
        toast.success(
          formatMessage({
            id: 'app.views.person.person_performance_alert_row.report_unreleased_successfully',
            defaultMessage: 'Report unreleased successfully!',
          })
        );
      } else {
        toast.error(
          formatMessage(
            {
              id: 'app.views.person.person_performance_alert_row.error_unreleasing_report',
              defaultMessage: 'Error: {errorMessage}',
            },
            { errorMessage: error.toString() }
          )
        );
      }
    },
    [setSurveyResponse, surveyResponse, formatMessage]
  );

  const conversationHasBeenAcknowledged = useMemo(
    () => surveyResponse?.conversation_acknowledged_at,
    [surveyResponse]
  );

  const onAcknowledgeConversation = useCallback(
    (data, error) => {
      if (data) {
        setSurveyResponse({
          ...surveyResponse,
          conversation_acknowledged_at: data.conversation_acknowledged_at,
          conversation_rating: data.conversation_rating,
          conversation_comments: data.conversation_comments,
          conversation_visibility: data.conversation_visibility,
        });
        toast.success(
          formatMessage({
            id: 'app.views.person.person_performance_alert_row.thank_you_for_your_feedback',
            defaultMessage: 'Thank you for your feedback!',
          })
        );
      } else {
        toast.error(
          formatMessage(
            {
              id: 'app.views.person.person_performance_alert_row.error_unreleasing_report',
              defaultMessage: 'Error: {errorMessage}',
            },
            { errorMessage: error.toString() }
          )
        );
      }
    },
    [setSurveyResponse, surveyResponse, formatMessage]
  );

  const canReleaseReportForCampaignWithoutONA = useMemo(() => {
    if (!campaign?.phases || getCampaignHasONA(campaign)) return false;

    // find the latest phase between evaluation and calibration
    const phaseIndex = campaign.phases.findLastIndex(
      (phase) =>
        phase.type === PHASE_TYPE_EVALUATION ||
        phase.type === PHASE_TYPE_CALIBRATION
    );

    if (phaseIndex < 0) return false;

    return getPhaseIsClosed(campaign, phaseIndex);
  }, [campaign]);

  const reportIsAllowedToBeReleased = useMemo(() => {
    // we must have finished all phases OR be in the reporting phase
    // and this person's report should not be "on hold"
    // NOTE: this logic should match TeamDashboard.js's similar logic
    return (
      campaign &&
      getPerfCampaignIsInReportingPhaseOrClosed(campaign) &&
      !surveyResponse?.configs?.withhold_report_release &&
      (surveyResponse?.configs?.is_only_receiving_review ||
        (getPhaseByType(campaign, PHASE_TYPE_SELF) &&
          getPersonIsParticipatingInPhase(
            person,
            surveyResponse?.configs,
            getPhaseByType(campaign, PHASE_TYPE_SELF)
          )) ||
        canReleaseReportForCampaignWithoutONA)
    );
  }, [campaign, person, surveyResponse, canReleaseReportForCampaignWithoutONA]);

  const conversationAcknowledgementsEnabled = useMemo(
    () =>
      !getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS
      ),
    [campaign]
  );

  const myEditableRelationship = useMemo(() => {
    return managerRelationshipsWithFeedback?.find((r) =>
      peopleIdsAreEqual(r.from_person?.id, meId)
    );
  }, [managerRelationshipsWithFeedback, meId]);

  const onEditDefaultRelationship = useCallback(() => {
    return myEditableRelationship
      ? editRelationship(myEditableRelationship)
      : undefined;
  }, [editRelationship, myEditableRelationship]);

  const viewerIsManagerInPerfContext = useMemo(() => {
    return incomingRelationshipsWithFeedback?.some(
      (r) =>
        r.type === RELATIONSHIP_TYPES.REPORTS_TO &&
        peopleObjectsAreEqual(r.from_person, person) &&
        peopleIdsAreEqual(meId, r.to_person?.id)
    );
  }, [incomingRelationshipsWithFeedback, person, meId]);

  const evaluationPhaseIsOpen = useMemo(() => {
    if (!campaign) {
      return false;
    }

    const currentPhaseIndex = getCurrentPhaseIndex(campaign);

    // note: phase could be greater than length of phase
    if (campaign.phases?.length <= currentPhaseIndex) {
      return false;
    }

    // We explicitly check end date to ensure it hasn't passed,
    // since it's possible the current phase has ended but the next
    // phase hasn't started yet
    return (
      campaign.phases[currentPhaseIndex]?.type === PHASE_TYPE_EVALUATION &&
      getPhaseIsOpen(campaign, currentPhaseIndex)
    );
  }, [campaign]);

  const reportEditLinkType = useMemo(() => {
    if (viewerIsManagerInPerfContext) {
      if (evaluationPhaseIsOpen) {
        return REPORT_EDIT_LINK_TYPE_LINK_TO_PERF;
      } else if (myEditableRelationship) {
        return REPORT_EDIT_LINK_TYPE_MODAL_EDIT;
      }
    }
    return REPORT_EDIT_LINK_TYPE_NO_EDIT_LINK;
  }, [
    evaluationPhaseIsOpen,
    myEditableRelationship,
    viewerIsManagerInPerfContext,
  ]);

  return (
    <>
      {/* Manager view when person is eligible for reporting */}
      {!isInReviewFlow &&
        !isMe &&
        isEligibleForReporting &&
        !reportHasBeenReleased &&
        !reportIsAllowedToBeReleased &&
        !isAdminable && (
          <PersonPerformanceAlertElement>
            <Col>
              <h4 className="mt-1 mb-1">
                {evaluationPhaseIsOpen && (
                  <FormattedMessage
                    id="app.views.person.person_performance.note_manager_reviews_without_calibration"
                    defaultMessage="Note: manager reviews are underway."
                  />
                )}
                {!evaluationPhaseIsOpen && (
                  <FormattedMessage
                    id="app.views.person.person_performance.note_manager_reviews_with_calibration"
                    defaultMessage="Note: manager reviews and calibrations are underway."
                  />
                )}
              </h4>
              <p className="mb-0">
                <FormattedMessage
                  id="app.views.person.person_performance.managers_can_still_edit_feedback"
                  defaultMessage="Managers can still <span>edit feedback</span>, even when calibrations finish, prior to sharing with
                          their direct reports."
                  values={{
                    span: (chunks) => (
                      <>
                        {reportEditLinkType ===
                          REPORT_EDIT_LINK_TYPE_LINK_TO_PERF && (
                          <Link
                            to={
                              consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS()
                                .path
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {chunks}
                          </Link>
                        )}
                        {reportEditLinkType ===
                          REPORT_EDIT_LINK_TYPE_MODAL_EDIT && (
                          <span
                            className="text-primary"
                            role="button"
                            onClick={onEditDefaultRelationship}
                          >
                            {chunks}
                          </span>
                        )}
                        {reportEditLinkType ===
                          REPORT_EDIT_LINK_TYPE_NO_EDIT_LINK && (
                          <span>{chunks}</span>
                        )}
                      </>
                    ),
                  }}
                />
              </p>
            </Col>
            {reportEditLinkType === REPORT_EDIT_LINK_TYPE_LINK_TO_PERF && (
              <Col className="col-auto">
                <Link
                  tag="button"
                  className="btn btn-primary"
                  to={consts.PERFORMANCE_STEP_ASSESS_DIRECT_REPORTS().path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="app.views.person.person_performance.edit_feedback"
                    defaultMessage="Edit feedback"
                  />
                </Link>
              </Col>
            )}
            {reportEditLinkType === REPORT_EDIT_LINK_TYPE_MODAL_EDIT && (
              <Col className="col-auto">
                <Button color="primary" onClick={onEditDefaultRelationship}>
                  <FormattedMessage
                    id="app.views.person.person_performance.edit_feedback"
                    defaultMessage="Edit feedback"
                  />
                </Button>
              </Col>
            )}
          </PersonPerformanceAlertElement>
        )}
      {!isInReviewFlow &&
        !isMe &&
        isEligibleForReporting &&
        !reportHasBeenReleased &&
        !reportIsAllowedToBeReleased &&
        isAdminable && (
          <PersonPerformanceAlertElement>
            <Col>
              <h4 className="mt-1 mb-1">
                <FormattedMessage
                  id="app.views.person.person_performance.manager_reviews_and_calibrations_are_underway"
                  defaultMessage="Note: manager reviews and calibrations are underway."
                />
              </h4>
              <p className="mb-0">
                <FormattedMessage
                  id="app.views.person.person_performance.however_hr_administrator"
                  defaultMessage="However, as an HR administrator, you may release this report to the person early if necessary."
                />
              </p>
            </Col>

            <Col className="col-auto">
              <ModalEditorButton
                method="POST"
                url={
                  campaign.status === CAMPAIGN_STATUSES.DEMO
                    ? undefined
                    : 'performance/release-report'
                }
                buttonClassName="btn-block"
                color="primary"
                modalTitle={formatMessage(
                  {
                    id: 'app.views.person.person_performance.modalTitle.release_report_to_person',
                    defaultMessage: 'Release report to {personPreferredName}',
                  },
                  { personPreferredName }
                )}
                title={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.title.release_report_early',
                  defaultMessage: 'Release report early',
                })}
                submitText={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.submitText.release_report_early',
                  defaultMessage: 'Release report early',
                })}
                object={{
                  campaign: campaign?.id,
                  survey_response: surveyResponse?.id,
                }}
                callback={onReleaseReport}
              >
                <>
                  <FormattedMessage
                    id="app.views.person.person_performance.ready_to_release_report"
                    defaultMessage="
                      This person's manager will not be able to modify
                      their comments after the report is released. Are you
                      ready to release the report to {personPreferredName}?"
                    values={{
                      personPreferredName: personPreferredName,
                    }}
                  />
                </>
              </ModalEditorButton>
            </Col>
          </PersonPerformanceAlertElement>
        )}
      {!isInReviewFlow &&
        !isMe &&
        isEligibleForReporting &&
        reportHasBeenReleased &&
        isAdminable && (
          <PersonPerformanceAlertElement>
            <Col>
              <h4 className="mt-1 mb-1">
                <FormattedMessage
                  id="app.views.person.person_performance.report_already_released"
                  defaultMessage="This report has already been released."
                />
              </h4>
              <p className="mb-0">
                <FormattedMessage
                  id="app.views.person.person_performance.you_may_unrelease"
                  defaultMessage="As an HR administrator, you may unrelease this report if necessary."
                />
              </p>
            </Col>

            <Col className="col-auto">
              <ModalEditorButton
                method="POST"
                url={
                  campaign.status === CAMPAIGN_STATUSES.DEMO
                    ? undefined
                    : 'performance/release-report'
                }
                buttonClassName="btn-block"
                color="primary"
                modalTitle={formatMessage(
                  {
                    id: 'app.views.person.person_performance.modalTitle.unrelease_report_to_person',
                    defaultMessage: 'Unrelease report to {personPreferredName}',
                  },
                  { personPreferredName }
                )}
                title={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.title.unrelease_report',
                  defaultMessage: 'Unrelease report',
                })}
                submitText={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.submitText.unrelease_report',
                  defaultMessage: 'Unrelease report',
                })}
                object={{
                  campaign: campaign?.id,
                  survey_response: surveyResponse?.id,
                  undo: true,
                }}
                callback={onUnreleaseReport}
              >
                <>
                  <p>
                    <FormattedMessage
                      id="app.views.person.person_performance.already_received_notification"
                      defaultMessage="
                        This person already received a notification when the
                        report was released, so they may have already seen it.
                      "
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="app.views.person.person_performance.no_notification_will_be_sent"
                      defaultMessage="
                        No notification will be sent when this report is
                        unreleased.
                      "
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="app.views.person.person_performance.are_you_sure_unrelease"
                      defaultMessage="Are you sure you want to unrelease the report for {personPreferredName}?"
                      values={{
                        personPreferredName: personPreferredName,
                      }}
                    />
                  </p>
                </>
              </ModalEditorButton>
            </Col>
          </PersonPerformanceAlertElement>
        )}
      {!isMe &&
        isEligibleForReporting &&
        !reportHasBeenReleased &&
        reportIsAllowedToBeReleased && (
          <PersonPerformanceAlertElement>
            <Col>
              <h4 className="mt-1 mb-1">
                <FormattedMessage
                  id="app.views.person.person_performance.ready_to_be_released"
                  defaultMessage="{personFullName}'s performance report is ready to be released."
                  values={{ personFullName: personFullName }}
                />
              </h4>
              <p className="mb-0">
                {myEditableRelationship ? (
                  <FormattedMessage
                    id="app.views.person.person_performance.edit_your_feedback_if_needed"
                    defaultMessage="<span>Edit your feedback below</span> if needed. Then, release it to {personPreferredName}."
                    values={{
                      personPreferredName: personPreferredName,
                      span: (chunks) => (
                        <span
                          className="text-primary"
                          role="button"
                          onClick={onEditDefaultRelationship}
                        >
                          {chunks}
                        </span>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="app.views.person.person_performance.release_the_report_when_ready"
                    defaultMessage="Release the report to {personPreferredName} when you are ready."
                    values={{
                      personPreferredName: personPreferredName,
                    }}
                  />
                )}
              </p>
            </Col>
            <Col className="col-auto">
              <ModalEditorButton
                method="POST"
                url={
                  campaign.status === CAMPAIGN_STATUSES.DEMO
                    ? undefined
                    : 'performance/release-report'
                }
                buttonClassName="btn-block"
                color="primary"
                modalTitle={formatMessage(
                  {
                    id: 'app.views.person.person_performance_alert_row.title.release_report_to_person',
                    defaultMessage: 'Release report to {name}',
                  },
                  {
                    name: personPreferredName,
                  }
                )}
                title={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.title.release_report',
                  defaultMessage: 'Release report',
                })}
                submitText={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.submitText.release_report',
                  defaultMessage: 'Release report',
                })}
                object={{
                  campaign: campaign?.id,
                  survey_response: surveyResponse?.id,
                }}
                callback={onReleaseReport}
              >
                <>
                  <FormattedMessage
                    id="app.views.person.person_performance.will_not_be_able_to_edit"
                    defaultMessage="
                        You will not be able to edit your feedback after the
                        report is released. Are you ready to release the
                        report to {personPreferredName}?"
                    values={{
                      personPreferredName: personPreferredName,
                    }}
                  />
                </>
              </ModalEditorButton>
            </Col>
          </PersonPerformanceAlertElement>
        )}
      {/* Manager view when person is not eligible for reporting */}
      {!isMe &&
        !isEligibleForReporting &&
        campaign.status !== CAMPAIGN_STATUSES.DEMO && (
          <div
            className="alert-light py-4 alert alert-success fade show"
            role="alert"
          >
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h4 className="mt-1 mb-1">
                      {!hasONAFeedback && (
                        <FormattedMessage
                          id="app.views.person.person_performance.non_participant_note_without_feedback"
                          defaultMessage="Note: {personPreferredName} did not participate in this cycle and was not mentioned by others."
                          values={{
                            personPreferredName: personPreferredName,
                          }}
                        />
                      )}
                      {hasONAFeedback && (
                        <FormattedMessage
                          id="app.views.person.person_performance.non_participant_note_with_feedback"
                          defaultMessage="Note: {personPreferredName} did not participate in this cycle but was mentioned by others."
                          values={{
                            personPreferredName: personPreferredName,
                          }}
                        />
                      )}
                    </h4>
                    {hasONAFeedback && (
                      <p className="mb-0">
                        <FormattedMessage
                          id="app.views.person.person_performance.non_participant_note_description_with_feedback"
                          defaultMessage="{personPreferredName} cannot see the below information because they did not participate in this cycle."
                          values={{
                            personPreferredName: personPreferredName,
                          }}
                        />
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      {/* Self view when person is eligible for reporting */}
      {isMe &&
        isEligibleForReporting &&
        reportHasBeenReleased &&
        !conversationHasBeenAcknowledged &&
        conversationAcknowledgementsEnabled && (
          <PersonPerformanceAlertElement>
            <Col>
              <h4 className="mt-1 mb-1">
                <FormattedMessage
                  id="app.views.person.person_performance.review_feedback_then_conversation"
                  defaultMessage="
                        Review your feedback, then have a performance
                        conversation with your manager.
                      "
                />
              </h4>
              <p className="mb-0">
                <FormattedMessage
                  id="app.views.person.person_performance.work_complete_after_acknowledge"
                  defaultMessage="
                        Your work for this cycle is complete after you
                        acknowledge having this conversation.
                      "
                />
              </p>
            </Col>
            <Col className="col-auto">
              <ModalEditorButton
                method="POST"
                url={
                  campaign.status === CAMPAIGN_STATUSES.DEMO
                    ? undefined
                    : 'performance/acknowledge-report'
                }
                buttonClassName="btn-block"
                color="primary"
                modalTitle={formatMessage({
                  id: 'app.views.person.person_performance.modalTitle.acknowledge_conversation_with_manager',
                  defaultMessage: 'Acknowledge conversation with manager',
                })}
                title={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.title.i_have_had_this_conversation',
                  defaultMessage: 'I have had this conversation',
                })}
                submitText={formatMessage({
                  id: 'app.views.person.person_performance_alert_row.submitText.submit',
                  defaultMessage: 'Submit',
                })}
                object={{
                  campaign: campaign?.id,
                  survey_response: surveyResponse?.id,
                  conversation_rating: surveyResponse?.conversation_rating,
                  conversation_comments: surveyResponse?.conversation_comments,
                  conversation_visibility:
                    surveyResponse?.conversation_visibility,
                }}
                callback={onAcknowledgeConversation}
                inputs={[
                  {
                    type: INPUT_TYPES.MULTIPLE_CHOICE,
                    name: 'conversation_rating',
                    required: true,
                    label:
                      'How helpful was the conversation with your manager?',
                    helperText: (
                      <span>
                        <i className="fe fe-lock me-2" />
                        <FormattedMessage
                          id="app.views.person.person_performance.selection_identity_only_shared_with_hr"
                          defaultMessage="
                                Your selection and identity will only be
                                shared with HR.
                              "
                        />
                      </span>
                    ),
                    allowCustom: false,
                    options: [
                      {
                        id: '5',
                        name: 'Helpful',
                      },
                      {
                        id: '4',
                        name: 'Somewhat helpful',
                      },
                      {
                        id: '3',
                        name: 'Neither helpful nor unhelpful',
                      },
                      {
                        id: '2',
                        name: 'Somewhat unhelpful',
                      },
                      {
                        id: '1',
                        name: 'Unhelpful',
                      },
                    ],
                  },
                  {
                    type: INPUT_TYPES.TEXTAREA,
                    name: 'conversation_comments',
                    label:
                      'Do you have any other comments to share about this conversation?',
                    helperText: (
                      <span>
                        <i className="fe fe-lock me-2" />
                        <FormattedMessage
                          id="app.views.person.person_performance.comments_identity_only_shared_with_hr"
                          defaultMessage="
                                Your comments and identity will only be shared
                                with HR.
                              "
                        />
                      </span>
                    ),
                    minRows: 3,
                  },
                  {
                    type: INPUT_TYPES.SWITCH,
                    name: 'conversation_visibility',
                    label: 'Allow your manager to see this feedback.',
                    helperText: (
                      <span>
                        <FormattedMessage
                          id="app.views.person.person_performance.manager_and_above_will_see"
                          defaultMessage="
                                If you enable this, your manager and above
                                will see this feedback and your identity. If
                                not, only HR administrators will see this
                                feedback and your identity.
                              "
                        />
                      </span>
                    ),
                    defaultValue: ConversationVisibility.HR,
                    uncheckedValue: ConversationVisibility.HR,
                    checkedValue: ConversationVisibility.MANAGER,
                  },
                ]}
              />
            </Col>
          </PersonPerformanceAlertElement>
        )}
      {/* Self view when person is not eligible for reporting */}
      {isMe && !isEligibleForReporting && (
        <PersonPerformanceAlertElement>
          <Col>
            <h4 className="mt-1 mb-1">
              <FormattedMessage
                id="app.views.person.person_performance.self_not_participating"
                defaultMessage="You did not participate in this cycle"
              />
            </h4>
          </Col>
        </PersonPerformanceAlertElement>
      )}
    </>
  );
};
