import React, { FC } from 'react';

import { useHistory } from 'react-router-dom';

// @ts-expect-error
const RedirectToHome: FC = () => {
  const history = useHistory();
  history.replace('/');
};

export default React.memo(RedirectToHome);
