import { Col, Fade, Nav, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import React, { FC, useCallback, useEffect } from 'react';

import Avatar from '../../Widgets/People/Avatar';
import Breadcrumbs from '../Breadcrumbs';
import Page404 from './Errors/Page404';
import PropTypes from 'prop-types';
import config from '../../../utils/util/config';
import { stripQueryString } from '../../../utils/util/formatter';

const Page: FC<Props> = (props) => {
  // if two children, either:
  // - first child has "subtitle" class in which case place it where subtitle goes
  // - first child does not have "subtitle" class, then first is main action and second is body
  // if one child, no action, just body
  let subtitleDiv = null;
  let mainActionElement = null;
  let bodyElements = null;

  const location = useLocation();

  // add title to page itself
  useEffect(() => {
    let title = null;

    if (props.editableObject) {
      // @ts-expect-error
      if (props.editableObject.title) {
        // @ts-expect-error
        title = props.editableObject.title;
        // @ts-expect-error
      } else if (props.editableObject.name) {
        // @ts-expect-error
        title = props.editableObject.name;
      }
    }

    if (!title && props.title) {
      // @ts-expect-error
      title = props.title;
    }

    if (props.plainTextTitle) {
      // @ts-expect-error
      title = props.plainTextTitle;
    }

    const fullTitle = title ? title + ' - Confirm' : 'Confirm';

    // prepend environment if not production (so the user is aware)
    // @ts-expect-error
    document.title = config.isProduction()
      ? fullTitle
      : // @ts-expect-error
        '(' + config.getEnvironmentName() + ') ' + fullTitle;
  }, [props.title, props.editableObject, props.plainTextTitle]);

  if (props.subtitle) {
    // @ts-expect-error
    subtitleDiv = (
      <span className="d-block header-subtitle">{props.subtitle}</span>
    );
  }

  if (Array.isArray(props.children) && props.children.length > 1) {
    const firstClasses = props.children[0]?.props
      ? props.children[0].props.className
      : null;
    if (firstClasses && firstClasses.split(' ')[0].indexOf('subtitle') !== -1) {
      subtitleDiv = props.children[0];
      mainActionElement = props.children[1];
      // @ts-expect-error
      bodyElements = props.children.slice(2);
    } else {
      mainActionElement = props.children[0];
      // @ts-expect-error
      bodyElements = props.children.slice(1);
    }
  }

  const tabPathMatchesLocation = useCallback(
    (tab) =>
      stripQueryString(tab.path) === location.pathname ||
      tab?.paths?.find((p) => stripQueryString(p) === location.pathname),
    [location]
  );

  // if this is an embedded page, just return
  // the children without all the extras
  if (props.embedded) {
    return <>{props.children}</>;
  }

  let shouldRenderContainer = props.shouldRenderContainer;
  let containerClassName = props.containerClassName;
  let currentTab = undefined;

  // if this is a tabs page and tab content is provided, use that, else use
  // the children of the page passed in
  if (props.tabs && props.tabs.length > 0) {
    // @ts-expect-error
    currentTab = props.tabs.find(
      // allows us to put dynamic query string parameters on tab paths
      (tab) => tabPathMatchesLocation(tab)
    );

    if (!currentTab) {
      // tab doesn't exist; show 404
      return <Page404 />;
    }

    // @ts-expect-error
    if (currentTab?.action) {
      // @ts-expect-error
      mainActionElement = currentTab.action;
    } else if (props.children && !Array.isArray(props.children)) {
      // @ts-expect-error
      mainActionElement = props.children;
    }

    // @ts-expect-error
    if (currentTab?.content) {
      // @ts-expect-error
      bodyElements = currentTab.content;
    }

    // @ts-expect-error
    if (typeof currentTab?.shouldRenderContainer !== 'undefined') {
      // @ts-expect-error
      shouldRenderContainer = currentTab.shouldRenderContainer;
    }

    // @ts-expect-error
    if (currentTab?.containerClassName) {
      // @ts-expect-error
      containerClassName = currentTab.containerClassName;
    }
  }

  if (!bodyElements) {
    // @ts-expect-error
    bodyElements = props.children;
  }

  const hasTitle = props.title || props.editableObject;
  const titleClassName =
    // @ts-expect-error
    'col-md' + (props.avatar || props.avatarIcon ? ' ms-n2' : '');

  const pageContent = (
    <Row className="justify-content-center">
      <Col className={props.className ? props.className : ''}>
        {hasTitle && (
          <div
            className={
              'header mt-md-5' +
              (props.headerClassName ? ' ' + props.headerClassName : '')
            }
          >
            <div
              className={
                'header-body' +
                (props.headerBodyClassName
                  ? ' ' + props.headerBodyClassName
                  : '')
              }
            >
              {props.breadcrumbs && (
                // @ts-expect-error
                <Breadcrumbs breadcrumbs={props.breadcrumbs} />
              )}
              {/* @ts-expect-error */}
              <Row className={props.headerRowClassName}>
                {props.person && (
                  <Col className="col-auto pe-0">
                    <Avatar
                      // @ts-expect-error
                      person={props.person}
                      hidePopover={true}
                      className={
                        'avatar-' + props.avatarSize + ' header-avatar-top'
                      }
                      // @ts-expect-error
                      style={props.avatarStyle}
                    />
                  </Col>
                )}
                {props.avatarIcon && (
                  <Col className="col-auto">
                    <div className="avatar avatar-lg">
                      <div className="avatar-title bg-primary-soft rounded-circle text-primary">
                        <i className={props.avatarIcon}></i>
                      </div>
                    </div>
                  </Col>
                )}
                <Col>
                  {/* @ts-expect-error */}
                  <Row className={props.headerRowClassName} xs="1" sm="12">
                    {props.title && (
                      <Col className={titleClassName}>
                        {props.pretitle && (
                          <h6 className="header-pretitle">{props.pretitle}</h6>
                        )}
                        <h1 className="header-title d-inline-block">
                          {props.titleLink ? (
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              to={props.titleLink}
                            >
                              {props.title}
                            </Link>
                          ) : (
                            props.title
                          )}
                        </h1>
                        {subtitleDiv}
                      </Col>
                    )}
                    {props.editableObject && (
                      <Col className={titleClassName}>
                        {props.pretitle && (
                          <h6 className="header-pretitle">{props.pretitle}</h6>
                        )}
                        <h1 className="header-title d-inline-block">
                          <Row>
                            <Col className="col-auto">
                              {/* @ts-expect-error */}
                              {props.editableObject.title
                                ? // @ts-expect-error
                                  props.editableObject.title
                                : // @ts-expect-error
                                  props.editableObject.name}
                            </Col>
                            <Col className="ps-md-0 col-auto">
                              {/* @ts-expect-error */}
                              {props.editableObjectButton}
                            </Col>
                          </Row>
                        </h1>
                        {subtitleDiv}
                      </Col>
                    )}
                    {mainActionElement && (
                      <Col
                        className={
                          'col-auto pt-3 ' +
                          (props.pretitle ? 'pt-md-3' : 'pt-md-0')
                        }
                      >
                        {mainActionElement}
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              {props.tabs && props.tabs.length > 1 && (
                <Row className="align-items-center">
                  <Col>
                    <Nav className="nav-tabs nav-overflow header-tabs">
                      {props.tabs.map((tab, index) => (
                        <li key={index} className="nav-item">
                          <Link
                            // @ts-expect-error
                            to={tab.path}
                            className={
                              'hover-parent nav-link' +
                              (location && tabPathMatchesLocation(tab)
                                ? ' active'
                                : '')
                            }
                            target={
                              // @ts-expect-error
                              tab.content === undefined ? '_blank' : undefined
                            }
                            rel={
                              // @ts-expect-error
                              tab.content === undefined
                                ? 'noopener noreferrer'
                                : undefined
                            }
                          >
                            {/* @ts-expect-error */}
                            {tab.name}
                            {/* @ts-expect-error */}
                            {tab.content === undefined && (
                              <>
                                {' '}
                                <i className="hover-child fe fe-external-link" />
                              </>
                            )}
                          </Link>
                        </li>
                      ))}
                    </Nav>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        )}
        <Fade>{bodyElements}</Fade>
      </Col>
    </Row>
  );

  // @ts-expect-error
  if (shouldRenderContainer && !currentTab?.isUltraWide) {
    return (
      <div
        className={
          'container-fluid' +
          (containerClassName ? ' ' + containerClassName : '')
        }
      >
        {pageContent}
      </div>
    );
  }

  // @ts-expect-error
  if (containerClassName || currentTab?.isUltraWide) {
    return (
      <div
        className={
          (containerClassName ?? '') +
          // note: px-6 is needed for perf admin to have room for
          // "Edit" admin button hover to left of table
          // @ts-expect-error
          (currentTab?.isUltraWide ? ' container-fluid px-6' : '')
        }
      >
        {pageContent}
      </div>
    );
  }

  return pageContent;
};

Page.defaultProps = {
  shouldRenderContainer: true,
  headerRowClassName: 'align-items-center',
  avatarSize: 'lg',
  embedded: false,
};

const Page_propTypes = {
  shouldRenderContainer: PropTypes.bool,
  containerClassName: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  tabs: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerBodyClassName: PropTypes.string,
  mainActionElementClassName: PropTypes.string,
  person: PropTypes.object,
  avatarSize: PropTypes.string,
  avatarStyle: PropTypes.object,
  avatarIcon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  plainTextTitle: PropTypes.string,
  titleLink: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  pretitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  editableObject: PropTypes.object,
  editableObjectButton: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headerRowClassName: PropTypes.string,
  embedded: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof Page_propTypes>;

export default React.memo(Page);
