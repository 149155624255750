import { Button, ButtonGroup, Col, Input, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Organization } from '../../../types';
import { useIntl } from 'react-intl';

interface EmployeeNPSInputProps {
  onChange?: (value: string) => void;
  required?: boolean;
  value?: string;
  organization: Organization;
}

const EmployeeNPSInput: React.FC<EmployeeNPSInputProps> = ({
  onChange,
  required = false,
  value = '',
  organization,
}) => {
  const { formatMessage } = useIntl();
  const [selectedValue, setSelectedValue] = useState<string | null>(
    value || null
  );

  // Update selected value when value prop changes
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleClick = (value: string) => {
    const newValue = selectedValue === value ? '' : value;
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  // Deliberately not localizing numbers at the moment
  const scale: string[] = Array.from({ length: 11 }, (_, index) =>
    String(index)
  );

  return (
    <>
      <Row>
        <span className="fr-view fw-bold mb-4 mt-2" role="label">
          {formatMessage(
            {
              id: 'app.views.widgets.inputs.employee_nps.label',
              defaultMessage:
                'How likely are you to recommend {organization} as a great place to work?',
            },
            { organization: organization?.name }
          )}
        </span>
      </Row>
      <Row className="px-3">
        <ButtonGroup className="rounded-lg bg-white px-0">
          {scale.map((item) => (
            <Button
              key={item}
              className="text-nowrap d-flex align-items-center justify-content-center"
              onClick={() => handleClick(item)}
              outline={selectedValue !== item}
              active={selectedValue === item}
              color="secondary"
              style={{
                height: '4rem',
                fontVariantNumeric: 'tabular-nums',
              }}
            >
              {item}
            </Button>
          ))}
        </ButtonGroup>
        <Input
          type="number"
          value={selectedValue || ''}
          required={required}
          onChange={(e) => setSelectedValue(e.target.value)}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            opacity: '0',
            pointerEvents: 'none',
            zIndex: '-1',
          }}
        />
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between mt-3 mb-4 text-secondary">
            <div style={{ flex: 1, textAlign: 'start' }} role="label">
              {formatMessage({
                id: 'app.views.widgets.inputs.employee_nps_scale.values.strongly_disagree',
                defaultMessage: 'Not at all likely',
              })}
            </div>
            <div style={{ flex: 1, textAlign: 'end' }} role="label">
              {formatMessage({
                id: 'app.views.widgets.inputs.employee_nps_scale.values.strongly_agree',
                defaultMessage: 'Extremely likely',
              })}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="fr-view fw-bold mt-4" role="label">
          {formatMessage({
            id: 'app.views.widgets.inputs.employee_nps.comment_label',
            defaultMessage: 'Add any comments or context below',
          })}
        </span>
      </Row>
    </>
  );
};

export default React.memo(EmployeeNPSInput);
