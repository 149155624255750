import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { Campaign, UserGeneratedInputType } from 'types';
import {
  DRAG_AND_DROP_ICON,
  DraggableItemTypes,
  toggleUrlHash,
  useDragAndDrop,
} from '../../../utils/util/util';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import { QUESTION_NAMES_THAT_CANNOT_BE_COPIED_SET } from '../../../utils/models/Performance';
import Question from '../Questions/Question';
import QuestionEditor from './QuestionEditor';
import UncontrolledHoverDropdown from '../Dropdowns/UncontrolledHoverDropdown';
import { isPhaseLocked, isQuestionLocked } from 'utils/models/Campaign';
import { useCampaignEditorPhase } from 'views/Administration/CampaignEditorPhaseContext';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';

type QuestionInputType = {
  campaign?: Campaign | null;
  value: UserGeneratedInputType;
  callback: (q) => void;
  onCopy?: () => void;
  onDelete?: () => void;
  hoverIndex: number;
  moveQuestion?: (dragIndex: any, hoverIndex: any) => void;
  disabled?: boolean;
  preserveExistingAnswers?: boolean;
  submitButtonType?: string | null;
  hideAdvancedFeatures?: boolean;
  inModal?: boolean | null;
  autoFocus?: boolean;
  translationNamespace?: string | null;
  jsonPath?: string | null;
  canBePrivate?: boolean | null;
  canBeAnonymous?: boolean | null;
};

const QuestionInput: React.FC<QuestionInputType> = (
  props: QuestionInputType
) => {
  const { formatMessage } = useIntl();
  const [question, setQuestion] = useState(props.value);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);

  const campaignEditorPhaseContext = useCampaignEditorPhase();

  // update question when edited externally
  useEffect(() => {
    setQuestion(props.value);
  }, [props.value]);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const toggleIsEditingQuestion = useCallback(() => {
    setIsEditingQuestion(!isEditingQuestion);
    toggleUrlHash(!isEditingQuestion, 'Edit', '#edit');
  }, [isEditingQuestion]);
  const toggleConfirmDeleteModal = useCallback(
    () => setConfirmDeleteModal(!confirmDeleteModal),
    [confirmDeleteModal]
  );
  const confirmDeleteCallback = useCallback(() => {
    // @ts-expect-error
    props.onDelete();
    toggleConfirmDeleteModal();
  }, [props, toggleConfirmDeleteModal]);

  const [dragAndDropRef, handlerId, drop, isDragging, drag] = useDragAndDrop(
    DraggableItemTypes.QUESTION,
    question?.name,
    props.hoverIndex,
    props.moveQuestion,
    !props.disabled && !isEditingQuestion
  );

  // only used for inModal
  const propsCallback = props.callback;
  const updateLabel = useCallback(
    (label) => {
      propsCallback({ ...question, label: label });
    },
    [propsCallback, question]
  );

  const originalQuestion = useMemo(
    () => (
      <Question
        value={props.value}
        campaign={props.campaign}
        disabled={props.disabled}
        inModal={props.inModal}
        updateLabel={updateLabel}
        autoFocus={props.autoFocus}
      />
    ),
    [
      props.autoFocus,
      props.campaign,
      props.disabled,
      props.inModal,
      props.value,
      updateLabel,
    ]
  );

  // @ts-expect-error
  drag(drop(dragAndDropRef));

  const readOnlyBecauseAnsweredAlready =
    isQuestionLocked(
      props.campaign?.campaign_question_locks ?? {},
      campaignEditorPhaseContext?.phaseType ?? '',
      question?.name ?? ''
    ) ||
    // SECTION types have inconsistent behaviour,
    // sometimes they have `name` and sometimes
    // they don't.
    (question?.type == INPUT_TYPES.SECTION &&
      isPhaseLocked(
        props.campaign?.campaign_question_locks ?? {},
        campaignEditorPhaseContext?.phaseType ?? ''
      ));

  const rowContent = useMemo(() => {
    return (
      <>
        <QuestionEditor
          campaign={props.campaign}
          isOpen={isEditingQuestion}
          toggle={toggleIsEditingQuestion}
          question={question}
          callback={propsCallback}
          canBePrivate={props.canBePrivate}
          canBeAnonymous={props.canBeAnonymous}
          title={formatMessage({
            id: 'app.views.widgets.inputs.question_input.title.edit_question',
            defaultMessage: 'Edit question',
          })}
          submitText={formatMessage({
            id: 'app.views.widgets.inputs.question_input.submit_text.save_question',
            defaultMessage: 'Save question',
          })}
          preserveExistingAnswers={props.preserveExistingAnswers}
          submitButtonType={props.submitButtonType}
          hideAdvancedFeatures={props.hideAdvancedFeatures}
          autoFocus={props.autoFocus}
          translationNamespace={props.translationNamespace}
          jsonPath={props.jsonPath}
        />
        <Col>
          {props.inModal && originalQuestion}
          {!props.inModal && (
            <Card className="mb-0">
              <CardBody>
                <div
                  role="button"
                  className="hover-child position-absolute text-muted"
                  // @ts-expect-error
                  disabled={props.disabled}
                  style={{ left: '0.15rem' }}
                >
                  {!props.disabled &&
                    !isEditingQuestion &&
                    !isDragging &&
                    DRAG_AND_DROP_ICON}
                </div>
                <Row className="align-items-center">
                  <Col>{originalQuestion}</Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </Col>
        <ConfirmationDialogModal
          isOpen={confirmDeleteModal}
          toggle={toggleConfirmDeleteModal}
          confirmCallback={confirmDeleteCallback}
          title={formatMessage({
            id: 'app.views.widgets.inputs.question_input.title.delete_this_question',
            defaultMessage: 'Delete this question?',
          })}
          description={formatMessage({
            id: 'app.views.widgets.inputs.question_input.description.are_you_sure_that_you_want_to_delete',
            defaultMessage:
              'Are you sure that you want to delete this question?',
          })}
          confirmText={formatMessage({
            id: 'app.views.widgets.inputs.question_input.confirm_text.delete_question',
            defaultMessage: 'Delete question',
          })}
          // @ts-expect-error
          disabled={props.disabled}
        />
        {/* Do not show in modal if it's completely disabled */}
        {props.inModal && !props.disabled && (
          // with compressed space, show light-weight editing
          <Col className="col-auto">
            {/* @ts-expect-error */}
            <UncontrolledHoverDropdown>
              <DropdownToggle
                tag="div"
                role="button"
                className="dropdown-ellipses text-muted text-decoration-none"
              >
                <i className="fe fe-more-vertical"></i>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={toggleIsEditingQuestion}>
                  <i className="fe fe-edit me-2" />{' '}
                  <FormattedMessage
                    id="app.views.widgets.inputs.question_input.edit_details"
                    defaultMessage="Customize..."
                  />
                </DropdownItem>
                <DropdownItem
                  onClick={props.onCopy}
                  disabled={
                    QUESTION_NAMES_THAT_CANNOT_BE_COPIED_SET.has(
                      question.name ?? ''
                    ) || readOnlyBecauseAnsweredAlready
                  }
                >
                  <i className="fe fe-copy me-2" />{' '}
                  <FormattedMessage
                    id="app.views.widgets.inputs.question_input.duplicate"
                    defaultMessage="Duplicate..."
                  />
                </DropdownItem>
                <DropdownItem
                  onClick={toggleConfirmDeleteModal}
                  disabled={readOnlyBecauseAnsweredAlready}
                >
                  <i className="fe fe-trash-2 me-2" />{' '}
                  <FormattedMessage
                    id="app.views.widgets.inputs.question_input.delete"
                    defaultMessage="Delete"
                  />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledHoverDropdown>
          </Col>
        )}
        {!props.inModal && (
          // with more space, show all buttons in-line for discoverability,
          // but keep text shorter in each button given space constraints
          <Col className="col-auto">
            <Row>
              <Col className="col-auto pe-0">
                <Button
                  className="text-start"
                  color="white"
                  onClick={toggleIsEditingQuestion}
                  disabled={props.disabled}
                >
                  <i className="fe fe-edit me-2" />{' '}
                  <FormattedMessage
                    id="app.views.widgets.inputs.question_input.edit"
                    defaultMessage="Edit"
                  />
                </Button>
              </Col>
              <Col className="col-auto pe-0">
                <Button
                  color="white"
                  onClick={props.onCopy}
                  disabled={
                    props.disabled ||
                    QUESTION_NAMES_THAT_CANNOT_BE_COPIED_SET.has(
                      question.name ?? ''
                    ) ||
                    readOnlyBecauseAnsweredAlready
                  }
                >
                  <i className="fe fe-copy me-2" />{' '}
                  <FormattedMessage
                    id="app.views.widgets.inputs.question_input.copy"
                    defaultMessage="Copy"
                  />
                </Button>
              </Col>
              <Col className="col-auto">
                <Button
                  color="white"
                  onClick={toggleConfirmDeleteModal}
                  disabled={props.disabled || readOnlyBecauseAnsweredAlready}
                >
                  <i className="fe fe-trash-2" />
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </>
    );
  }, [
    confirmDeleteCallback,
    confirmDeleteModal,
    formatMessage,
    isDragging,
    isEditingQuestion,
    originalQuestion,
    props.autoFocus,
    props.campaign,
    props.canBeAnonymous,
    props.canBePrivate,
    props.disabled,
    props.hideAdvancedFeatures,
    props.inModal,
    props.jsonPath,
    props.onCopy,
    props.preserveExistingAnswers,
    props.submitButtonType,
    props.translationNamespace,
    propsCallback,
    question,
    readOnlyBecauseAnsweredAlready,
    toggleConfirmDeleteModal,
    toggleIsEditingQuestion,
  ]);

  const output = useMemo(
    () => (
      <>
        {props.inModal && (
          <ListGroupItem>
            <div className="hover-parent">
              <div
                // @ts-expect-error
                ref={dragAndDropRef}
                data-handler-id={handlerId}
                role="button"
                className="hover-child position-absolute text-muted pt-1"
                disabled={props.disabled}
                style={{ left: '-3.3rem' }}
              >
                {!props.disabled &&
                  !isEditingQuestion &&
                  !isDragging &&
                  DRAG_AND_DROP_ICON}
              </div>
              <Row className="align-items-center">{rowContent}</Row>
            </div>
          </ListGroupItem>
        )}
        {!props.inModal && (
          // @ts-expect-error
          <div ref={dragAndDropRef} data-handler-id={handlerId}>
            <Row className="hover-parent align-items-center mb-4">
              {rowContent}
            </Row>
          </div>
        )}
      </>
    ),
    [
      dragAndDropRef,
      handlerId,
      isDragging,
      isEditingQuestion,
      props.disabled,
      props.inModal,
      rowContent,
    ]
  );

  return output;
};

export default React.memo(QuestionInput);
