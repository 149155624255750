import {
  FORMER_EMPLOYEE_WORDING,
  NO_TITLE_WORDING,
  ON_LEAVE_WORDING,
} from '../../consts/consts';
import {
  SiAngellist,
  SiFacebook,
  SiGithub,
  SiLinkedin,
  SiQuora,
  SiStackoverflow,
  SiTwitter,
  SiYoutube,
} from 'react-icons/si';

import React from 'react';
import { getPluralNameByType } from './RelationshipUtils';
import unattributedAvatar1 from '../../assets/img/illustrations/avatars/a1.png';
import unattributedAvatar2 from '../../assets/img/illustrations/avatars/a2.png';
import unattributedAvatar3 from '../../assets/img/illustrations/avatars/a3.png';
import unattributedAvatar4 from '../../assets/img/illustrations/avatars/a4.png';
import unattributedAvatar5 from '../../assets/img/illustrations/avatars/a5.png';
import unattributedAvatar6 from '../../assets/img/illustrations/avatars/a6.png';

// IMPORTANT NOTE: if you make changes to any of the below lists, you should also update
// people_field_names_to_update and/or survey_response_config_field_names_to_update
// in the backend so that the backend knows which fields to update when changing people
export const ADMIN_ONLY_PERSON_FIELDS = [
  'external_id',
  'function',
  'function_id',
  'location',
  'cost_center_id',
  'cost_center',
  'department_id',
  'department',
  'business_unit_id',
  'business_unit',
  'level_id',
  'level',
  'job_code',
  'job_profile',
  'job_family',
  'original_hire_date',
  'latest_hire_date',
  'termination_date',
  'position_start_date',
  'position_effective_date',
  'manager_effective_date',
];

export const PERSON_STATUSES = {
  ACTIVE: 'A',
  TERMINATED: 'T',
  LEAVE: 'L',
  DECEASED: 'D',
};

export const PERSON_STATUSES_NAMES = (formatMessage) => ({
  [PERSON_STATUSES.ACTIVE]: formatMessage({
    id: 'app.widgets.task.PERSON_STATUSES_NAMES.ACTIVE',
    defaultMessage: 'Active',
  }),
  [PERSON_STATUSES.TERMINATED]: formatMessage({
    id: 'app.widgets.task.PERSON_STATUSES_NAMES.TERMINATED',
    defaultMessage: 'Terminated',
  }),
  [PERSON_STATUSES.LEAVE]: formatMessage({
    id: 'app.widgets.task.PERSON_STATUSES_NAMES.LEAVE',
    defaultMessage: 'On leave',
  }),
  [PERSON_STATUSES.DECEASED]: formatMessage({
    id: 'app.widgets.task.PERSON_STATUSES_NAMES.DECEASED',
    defaultMessage: 'Deceased',
  }),
});

export const getPersonStatusDisplayName = (formatMessage, status) => {
  return PERSON_STATUSES_NAMES(formatMessage)[status] ?? '';
};

export const POSITION_CHANGE_REASONS = {
  NEW_HIRE: 'N',
  REHIRE: 'R',
  PROMOTION: 'P',
  TRANSFER: 'T',
  UNSPECIFIED: 'U',
};

export const getPositionChangeReasonDisplayName = (formatMessage, reason) => {
  // note: this uses the values from POSITION_CHANGE_REASONS
  return formatMessage(
    {
      id: 'positionChangeReasons',
      defaultMessage:
        '{reason, select, N {new hire} R {rehire} P {promotion} T {transfer} U {unspecified} other {}}',
    },
    { reason }
  );
};

export const PERSON_METADATA_FIELDS = [
  'title',
  'status',
  'is_active',
  'is_adminable',
  'is_below_in_chain_of_command',
  'manager',
  // includes all direct reports, irrespective of employment status
  'direct_reports',
  'phone',
  'external_id',
  'function',
  'function_id',
  'location',
  'home_location',
  'cost_center_id',
  'cost_center',
  'country',
  'department_id',
  'department',
  'business_unit_id',
  'business_unit',
  'level_id',
  'level',
  'original_hire_date',
  'latest_hire_date',
  'termination_date',
  'position_start_date',
  'position_effective_date',
  'manager_effective_date',
  'classification',
  'position_change_reason',
];

//
// made this the common function for creating a button from a
// brand logo. could not find a way to dynamically render the
// right Simple Icon, so we must explicitly Import and use
// the correct icon
//
export const getExternalSiteProfileButton = (
  profile,
  siteName,
  baseUrl = null
) => {
  if (!profile[siteName]?.handle) {
    return '';
  }

  const siteProfile = profile[siteName];

  // default site name to https://www.<SITENAME>.com/
  if (!baseUrl) {
    // @ts-expect-error
    baseUrl = 'https://www.' + siteName + '.com/';
  }

  // if handle is a url itself, just use that (as some APIs pass that instead of a piece of it)
  const finalUrl =
    siteProfile.handle.startsWith('http://') ||
    siteProfile.handle.startsWith('https://')
      ? siteProfile.handle
      : baseUrl + siteProfile.handle;

  // use svg's from Simple Icons
  const icon =
    siteName === 'linkedin' ? (
      <SiLinkedin size={15} />
    ) : siteName === 'angellist' ? (
      <SiAngellist size={15} />
    ) : siteName === 'twitter' ? (
      <SiTwitter size={15} />
    ) : siteName === 'github' ? (
      <SiGithub size={15} />
    ) : siteName === 'stackoverflow' ? (
      <SiStackoverflow size={15} />
    ) : siteName === 'quora' ? (
      <SiQuora size={15} />
    ) : siteName === 'youtube' ? (
      <SiYoutube size={15} />
    ) : siteName === 'facebook' ? (
      <SiFacebook size={15} />
    ) : (
      <span className="fe fe-help-circle"></span>
    );

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={finalUrl}
      role="button"
      className={'rounded-circle btn btn-brand btn-' + siteName + ' me-2'}
    >
      <span className="rounded-circle">{icon}</span>
    </a>
  );
};

// Returns true if this matches the format a@b.c (a simple validator)
export const isValidEmailAddressWithDomain = (
  str,
  requiredDomainList = null
) => {
  const isValidEmail = str?.length > 0 && !!str.match(/^[^@]+@[^.]+\./);

  // if invalid format or there is no required domain to match against, return as is
  // @ts-expect-error
  if (!isValidEmail || !requiredDomainList || requiredDomainList.length === 0) {
    return isValidEmail;
  }

  // return true only if the email ends in one of the given domains
  // @ts-expect-error
  for (const i in requiredDomainList) {
    if (str.endsWith(requiredDomainList[i])) {
      return true;
    }
  }

  // does not match one of the required domains
  return false;
};

// returns string of errors, or null if no errors
export const getInvalidPeopleError = (
  formatMessage,
  people,
  validDomains,
  allowExternalInviting = false
) => {
  if (!people || people.length === 0) {
    return null;
  }

  const peopleWithErrors = people.reduce((acc, person) => {
    if (!allowExternalInviting && !person.id) {
      // this is an attempt to invite a person not already in the system,
      // we need to deny it
      acc.push(person.email);
    } else if (!isValidEmailAddressWithDomain(person.email, validDomains)) {
      acc.push(person.email);
    }

    return acc;
  }, []);

  if (!peopleWithErrors || peopleWithErrors.length === 0) {
    return null;
  }

  if (allowExternalInviting) {
    return formatMessage(
      {
        id: 'app.widgets.task.email_not_allowed',
        defaultMessage:
          '{num, plural, one {Email} other {Emails}} not allowed: {emailList}',
      },
      {
        num: peopleWithErrors.length,
        emailList: peopleWithErrors.join(', '),
      }
    );
  } else {
    return formatMessage(
      {
        id: 'app.widgets.task.person_not_found',
        defaultMessage:
          '{num, plural, one {Person} other {People}} not found: {personList}',
      },
      {
        num: peopleWithErrors.length,
        personList: peopleWithErrors.join(', '),
      }
    );
  }
};

export const getPersonOrInvitationObjectForSending = (userFieldName, obj) => {
  // id means user, else this is either manually entered or a clearbit external
  // person and we should send the fields as is
  const objToSend = {};
  objToSend[userFieldName] = obj.id
    ? obj.id
    : {
        ...obj,
        // ensure no {} recipient or organization is passed in
        organization: undefined,
        recipient: undefined,
      };

  return objToSend;
};

// removes duplicates based on id
export const filterUniqueByEmail = (arr) => {
  if (!arr) {
    return null;
  }

  const uniqueIds = arr
    .map((o) => o.email)
    .filter((itm, i, a) => i === a.indexOf(itm));
  return uniqueIds.map((email) => arr.find((o) => o.email === email));
};

export const getPersonFromItem = (item) => {
  const index = item._index;
  let p = null;
  switch (index) {
    // ElasticSearch
    case 'people':
      p = item.object;
      break;
    case 'clearbit':
      // needs email for matching in search
      p = item.object;
      break;
    default:
      // this is an email address with no match (just has a "name" attribute)
      // @ts-expect-error
      p = {
        email: item.name,
      };
      break;
  }

  return p;
};

export const getItemFromPerson = (person) => {
  if (person.id) {
    return {
      _index: 'people',
      id: person.id,
      name: person.full_name,
      url: person.url,
      object: person,
    };
  } else {
    return {
      _index: 'clearbit',
      name: person.full_name,
      object: person,
    };
  }
};

export const getPersonFromElasticsearchDocument = (doc) => {
  switch (doc._index) {
    // ElasticSearch
    case 'people':
      return doc._source;
    default:
      return null;
  }
};

export const peopleIdsAreEqual = (p1Id, p2Id) => {
  if (typeof p1Id === 'object' || typeof p2Id === 'object') {
    console.error('peopleIdsAreEqual received object instead of id');
    return false;
  }

  // one may be integer and one may be string, so cast to be safe
  return p1Id && p2Id && p1Id.toString() === p2Id.toString();
};

export const peopleObjectsAreEqual = (p1, p2) => {
  if (
    p1 === null ||
    typeof p1 === 'undefined' ||
    p2 === null ||
    typeof p2 === 'undefined'
  ) {
    // sometimes we intentionally get null objects due to anonymization
    return false;
  }

  if (typeof p1 !== 'object' || typeof p2 !== 'object') {
    console.error('peopleObjectsAreEqual received non-object');
    return false;
  }

  return peopleIdsAreEqual(p1?.id, p2?.id);
};

const unattributedAvatars = [
  unattributedAvatar1,
  unattributedAvatar2,
  unattributedAvatar3,
  unattributedAvatar4,
  unattributedAvatar5,
  unattributedAvatar6,
];

export const getUnattributedPerson = (
  formatMessage,
  hash,
  type,
  anonymous = false
) => {
  // for now, don't show difference between peer, direct report, etc.
  // to reduce probability of the user knowing who said what
  const showType = false;
  const formattedName = formatMessage(
    {
      id: 'app.widgets.task.unattributed_person',
      defaultMessage:
        '{anonymous, select, true {Confidential} other {{showType, select, true {One of your {type}} other {Someone you work with}}}}',
    },
    {
      anonymous,
      showType,
      type: formatMessage({
        id: `app.widgets.task.unattributed_person.${type}`,
        defaultMessage: getPluralNameByType(type),
      }),
    }
  );

  // get an unattributed person for displaying
  return {
    given_name: formattedName,
    family_name: '',
    full_name: formattedName,
    title: formatMessage({
      id: 'app.widgets.task.unattributed_person.title',
      defaultMessage: 'Unattributed',
    }),
    avatar: unattributedAvatars[hash % unattributedAvatars.length],
  };
};

export const isOnLeave = (status) => status === PERSON_STATUSES.LEAVE;

export const isCurrentEmployee = (status) =>
  [PERSON_STATUSES.ACTIVE, PERSON_STATUSES.LEAVE].includes(status);

export const isInactive = (status) =>
  [PERSON_STATUSES.TERMINATED, PERSON_STATUSES.DECEASED].includes(status);

export const getPersonDisplayTitleHtml = (formatMessage, person) => {
  if (person.status && !isCurrentEmployee(person.status)) {
    return (
      <span className="fst-italic">
        {FORMER_EMPLOYEE_WORDING(formatMessage)}
      </span>
    );
  }

  const onLeaveString = isOnLeave(person.status)
    ? `(${ON_LEAVE_WORDING(formatMessage)})`
    : '';

  const titleString = person.title?.length
    ? person.title
    : NO_TITLE_WORDING(formatMessage);

  return (
    <span className={!person.title?.length ? 'fst-italic' : ''}>
      {titleString}
      {onLeaveString && <span className="fst-italic"> {onLeaveString}</span>}
    </span>
  );
};

export const getPersonDisplayTitle = (formatMessage, person) => {
  if (person.status && !isCurrentEmployee(person.status)) {
    return (
      <div className="text-muted fw-normal fst-italic">
        {FORMER_EMPLOYEE_WORDING(formatMessage)}
      </div>
    );
  }

  const onLeaveString = isOnLeave(person.status)
    ? `(${ON_LEAVE_WORDING(formatMessage)})`
    : '';

  const titleString = person.title?.length
    ? person.title
    : NO_TITLE_WORDING(formatMessage);

  return (
    <div className="text-muted fw-normal">
      {titleString} {onLeaveString}
    </div>
  );
};

export const getPersonDisplayString = (formatMessage, person) => {
  if (person.status && !isCurrentEmployee(person.status)) {
    return FORMER_EMPLOYEE_WORDING(formatMessage);
  }

  const onLeaveString = isOnLeave(person.status)
    ? `(${ON_LEAVE_WORDING(formatMessage)})`
    : '';

  const titleString = person.title?.length
    ? person.title
    : NO_TITLE_WORDING(formatMessage);

  return `${titleString} ${onLeaveString}`;
};

export const getValidDirectReports = (directReports) => {
  if (!directReports) {
    return [];
  }

  // returns direct reports that are considered valid based on status
  return directReports.filter((d) => isCurrentEmployee(d.status));
};
