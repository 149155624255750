import React, { FC } from 'react';

import LinkedAvatar from './LinkedAvatar';
import { Person } from '../../../types';
import UnlinkedAvatar from './UnlinkedAvatar';

interface Props {
  person: Person;
  size?: string;
  src?: string;
  className?: string;
  alt?: string;
  onClick?: () => void;
  style?: object;
  imageStyle?: object;
  hidePopover?: boolean;
  linked?: boolean;
  popoverHeader?: React.ReactNode;
  popoverChildren?: React.ReactNode;
  popoverContent?: React.ReactNode;
  popoverPlacement?: string;
  unattributedRelationshipType?: string;
  leaderboardNumber?: number;
  isExternalUrl?: boolean;
  linkToSubPath?: string;
}

const Avatar: FC<Props> = ({ popoverPlacement = 'top', ...props }) => {
  if (props.linked) {
    return <LinkedAvatar {...{ ...props, popoverPlacement }} />;
  }

  return <UnlinkedAvatar {...{ ...props, popoverPlacement }} />;
};

export default React.memo(Avatar);
