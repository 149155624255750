import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useState } from 'react';

import BackGroundTaskModal from 'components/BackgroundTaskModal/BackGroundTaskModal';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

type Props = {
  surveyReponseIds: Set<number>;
  campaignId: number;
  onComplete: (data: unknown) => void;
};

const TeamDashboardReleaseReports: FC<Props> = ({
  surveyReponseIds,
  campaignId,
  onComplete,
}) => {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalToggle = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleOnComplete = useCallback(
    (data) => {
      setModalOpen(false);
      toast.success(
        formatMessage(
          {
            id: 'app.views.widgets.dashboards.team_dashboard.report_s_released_successfully',
            defaultMessage:
              '{count, plural, one {# report} other {# reports}} released successfully!',
          },
          {
            count: data?.length ?? 0,
          }
        )
      );
      onComplete(data);
    },
    [formatMessage, onComplete]
  );

  if (surveyReponseIds.size === 0) {
    return null;
  }

  return (
    <>
      <Button color="light" className="btn-sm" onClick={handleButtonClick}>
        <FormattedMessage
          id="app.views.widgets.dashboards.team_dashboard.release_reports_button"
          defaultMessage="Release {size} {size, plural, one {report} other {reports}}..."
          values={{ size: surveyReponseIds.size }}
        />
      </Button>
      <BackGroundTaskModal
        title={formatMessage({
          id: 'app.views.widgets.dashboards.team_dashboard.team_dashboard_release_reports.title.release_reports',
          defaultMessage: 'Release Reports',
        })}
        isOpen={modalOpen}
        onToggle={handleModalToggle}
        onComplete={handleOnComplete}
        requestConfig={{
          method: 'POST',
          url: '/performance/release-reports-async',
          params: {
            survey_responses: Array.from(surveyReponseIds),
            campaign: campaignId,
          },
        }}
        submitText={
          <FormattedMessage
            id="app.views.widgets.dashboards.team_dashboard.release_reports_action_button"
            defaultMessage="Release {size} {size, plural, one {report} other {reports}}"
            values={{ size: surveyReponseIds.size }}
          />
        }
      >
        <>
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.not_edit_feedback"
              defaultMessage="You will not be able to edit your feedback after these reports are released."
            />
          </p>
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.ready_release_reports"
              defaultMessage="Are you ready to release {size} {size, plural, one {report} other {reports}}?"
              values={{ size: surveyReponseIds.size }}
            />
          </p>
        </>
      </BackGroundTaskModal>
    </>
  );
};

export default React.memo(TeamDashboardReleaseReports);
