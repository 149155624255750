import { FormattedMessage } from 'react-intl';
import React from 'react';

export const TASK_TYPE_CUSTOM_PRIORITY = {
  id: 'P',
  heading: 'Important',
  queryParam: 'custom-priority-id',
};
export const TASK_TYPE_FEEDBACK_REQUEST = {
  id: 'R',
  heading: 'Feedback to provide',
  queryParam: 'feedback-request-id',
};
export const TASK_TYPE_CONTRIBUTION = {
  id: 'C',
  heading: 'Credit to accept',
  queryParam: 'contribution-id',
};

export const TASK_TYPES_IN_DISPLAY_ORDER = [
  TASK_TYPE_CUSTOM_PRIORITY,
  TASK_TYPE_FEEDBACK_REQUEST,
  TASK_TYPE_CONTRIBUTION,
];

export const getTaskHeadingByType = (taskTypeId) => {
  if (taskTypeId === TASK_TYPE_CUSTOM_PRIORITY.id) {
    return (
      <FormattedMessage
        id="app.utils.models.task.get_task_heading_by_type.custom"
        defaultMessage="Important"
        description="Heading for a custom priority tasks"
      />
    );
  } else if (taskTypeId === TASK_TYPE_FEEDBACK_REQUEST.id) {
    return (
      <FormattedMessage
        id="app.utils.models.task.get_task_heading_by_type.feedback_request"
        defaultMessage="Feedback to provide"
        description="Heading for card describing a task where the user is asked to provide feedback"
      />
    );
  } else if (taskTypeId === TASK_TYPE_CONTRIBUTION.id) {
    return (
      <FormattedMessage
        id="app.utils.models.task.get_task_heading_by_type.contribution_request"
        defaultMessage="Credit to accept"
        description="Heading for card describing a task where the user is asked to take credit for their contribution to an activity"
      />
    );
  }

  return '';
};
