import { Card, CardBody, CardHeader } from 'reactstrap';
import PropTypes, { InferProps } from 'prop-types';

import ActivityCard from './ActivityCard';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import { FormattedMessage } from 'react-intl';
import HierarchyList from '../Widgets/HierarchyList';
import React from 'react';

const ActivityHierarchy: React.FC<Props> = (props: Props) => {
  const activity = props.activity;

  const renderActivity = (item) => {
    return (
      <ActivityCard
        activity={item}
        bodyOnly={true}
        hideImages={true}
        hideContributions={true}
        hideDescription={true}
        setActivity={props.setActivity}
      />
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.activity.activity_hierarchy.header.title"
            defaultMessage="Structure"
          />
        </CardHeaderTitle>
        {props.editButton}
      </CardHeader>
      <CardBody>
        {
          // @ts-expect-error
          !activity.parent && !(activity.children?.length > 0) && (
            <div className="text-muted text-center">
              <FormattedMessage
                id="app.activity.activity_hierarchy.body.no_parent_or_children.text"
                defaultMessage="This activity is not part of any other activities."
              />
            </div>
          )
        }
        {
          // @ts-expect-error
          (activity.parent || activity.children?.length > 0) && (
            <HierarchyList
              object={{
                // @ts-expect-error
                ...activity.parent,
                children: [activity],
              }}
              renderObject={renderActivity}
            />
          )
        }
      </CardBody>
    </Card>
  );
};

type Props = InferProps<typeof ActivityHierarchy_propTypes>;

const ActivityHierarchy_propTypes = {
  editButton: PropTypes.node.isRequired,
  activity: PropTypes.object.isRequired,
  setActivity: PropTypes.func,
  parent: PropTypes.object,
};

export default React.memo(ActivityHierarchy);
