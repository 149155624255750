import React, { FC, useMemo } from 'react';

import { Doughnut } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';

interface PercentageCircleChartProps {
  percentage: number;
  onColor?: string;
  offColor?: string;
}

const completedLabel = (
  <FormattedMessage
    id="app.views.widgets.charts.percentage_circle_chart.completed"
    defaultMessage="Completed"
  />
);

const incompleteLabel = (
  <FormattedMessage
    id="app.views.widgets.charts.percentage_circle_chart.incomplete"
    defaultMessage="Incomplete"
  />
);

const PercentageCircleChart: FC<PercentageCircleChartProps> = ({
  percentage,
  onColor = '#3498db',
  offColor = '#ecf0f1',
}) => {
  const data = useMemo(() => {
    return {
      labels: [completedLabel, incompleteLabel],
      datasets: [
        {
          data: [percentage, 100 - percentage],
          backgroundColor: [onColor, offColor],
          hoverBackgroundColor: [onColor, offColor],
          borderWidth: [0, 0],
        },
      ],
    };
  }, [percentage, onColor, offColor]);

  const options = useMemo(
    () => ({
      tooltips: { enabled: false },
      cutoutPercentage: 80,
    }),
    []
  );

  return (
    <div className="position-relative">
      <Doughnut data={data} options={options} width={60} height={60} />
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        className="fw-bold"
      >
        {percentage.toFixed(0)}
        <FormattedMessage
          id="app.views.widgets.charts.percentage_circle_chart.percentage"
          defaultMessage="%"
        />
      </span>
    </div>
  );
};

export default React.memo(PercentageCircleChart);
