import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { OBJECTIVES, OKRS_LEARN_MORE } from '../../consts/consts';
import { OrgChartRelatives, Organization, Person } from '../../types';
import React, { FC } from 'react';

import { DATE_PARAMETER } from './ObjectivesTimeFrameSelector';
import Loading from '../Widgets/Loading';
import ObjectivesHoverablePersonCard from './ObjectivesHoverablePersonCard';
import { connect } from 'react-redux';
import { getDatePart } from '../../utils/util/time';
import usePersonOrgChartRelatives from '../Person/PersonOrgChartRelatives';

interface Props {
  currentOrganization: Organization;
  currentProxyPerson: Person;
  person: Person;
  currentFirstDayOfTimeFrame: Date;
}

const ObjectivesTeamNav: FC<Props> = ({
  currentOrganization,
  currentProxyPerson,
  person,
  currentFirstDayOfTimeFrame,
}) => {
  const { formatMessage } = useIntl();
  const orgChartRelatives: OrgChartRelatives | undefined =
    usePersonOrgChartRelatives(currentOrganization, currentProxyPerson, person);
  const urlSuffix = `${
    OBJECTIVES(formatMessage).path
  }?${DATE_PARAMETER}=${getDatePart(currentFirstDayOfTimeFrame)}`;

  if (orgChartRelatives === undefined) {
    return <Loading />;
  }

  const { manager, above_manager, peers, direct_reports } = orgChartRelatives;

  return (
    <>
      <Col className="col-12 col-md-3 mt-4 mt-md-0">
        {(manager || peers.length > 0 || direct_reports.length > 0) && (
          <>
            <h3>
              <FormattedMessage
                id="app.views.person.person_objectives.team_navigation.header.text"
                defaultMessage="View team member objectives"
              />
            </h3>
            {manager && (
              <>
                <h6 className="header-pretitle mt-4">
                  <FormattedMessage
                    id="app.views.person.person_objectives.team_navigation.manager_and_above.text"
                    defaultMessage="Manager and above"
                  />
                </h6>
                {above_manager.length > 0 && (
                  <>
                    {above_manager.map((p) => (
                      <ObjectivesHoverablePersonCard
                        key={p.id}
                        person={p}
                        urlSuffix={urlSuffix}
                      />
                    ))}
                  </>
                )}
                <ObjectivesHoverablePersonCard
                  person={manager}
                  urlSuffix={urlSuffix}
                />
              </>
            )}
            {direct_reports.length > 0 && (
              <>
                <h6 className="header-pretitle mt-4">
                  <FormattedMessage
                    id="app.views.person.person_objectives.team_navigation.direct_reports.text"
                    defaultMessage="Direct reports"
                  />
                </h6>
                {direct_reports.map((p) => (
                  <ObjectivesHoverablePersonCard
                    key={p.id}
                    person={p}
                    urlSuffix={urlSuffix}
                  />
                ))}
              </>
            )}
            {peers.length > 0 && (
              <>
                <h6 className="header-pretitle mt-4">
                  <FormattedMessage
                    id="app.views.person.person_objectives.team_navigation.peers.text"
                    defaultMessage="Peers"
                  />
                </h6>
                {peers.map((p) => (
                  <ObjectivesHoverablePersonCard
                    key={p.id}
                    person={p}
                    urlSuffix={urlSuffix}
                  />
                ))}
              </>
            )}
          </>
        )}
        <Row className="pt-3">
          <Col className="col-12">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={OKRS_LEARN_MORE(formatMessage).path}
            >
              {OKRS_LEARN_MORE(formatMessage).name}{' '}
              <i className="fe fe-external-link" />
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(ObjectivesTeamNav);
