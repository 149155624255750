import { Card, CardBody, Col, Row } from 'reactstrap';

import CompletionDoughnut from '../components/CompletionDoughnut';
import { FormattedMessage } from 'react-intl';
import React from 'react';

interface Props {
  denominator: number;
  height?: number;
  icon: string;
  numerator: number;
  title: string;
  width?: number;
}

const AdditionalProgressCard: React.FC<Props> = ({
  title,
  numerator,
  denominator,
  height = 40,
  width = 40,
  icon,
}) => {
  return (
    <Card className="mb-0">
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto pe-0">
            <CompletionDoughnut
              width={width}
              height={height}
              numerator={numerator}
              denominator={denominator}
            />
          </Col>
          <Col>
            <h6 className="text-uppercase text-muted mb-2">{title}</h6>
            <Row className="align-items-center no-gutters">
              <Col className="col-auto">
                <span className="h2 me-2 mb-0">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.team_dashboard.x_of_y_evaluations_received"
                    defaultMessage="{x} <span>of {y}</span>"
                    values={{
                      x: numerator,
                      y: denominator,
                      span: (chunks) => (
                        <span className="text-muted fs-base fw-normal">
                          {chunks}
                        </span>
                      ),
                    }}
                  />
                </span>
              </Col>
            </Row>
          </Col>
          <Col className="col-auto">
            <span className={icon + ' h2 text-muted mb-0'}></span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AdditionalProgressCard;
