import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { FormattedMessage } from 'react-intl';
import Loading from '../Widgets/Loading';
import React from 'react';
import { useConfirmApiWithDefault } from '../../utils/api/ApiHooks';

type IntegrationDescription = {
  name: string;
  slug: string;
  image: string;
  square_image: string;
  color: string;
  categories: string[];
};

const RELEVANT_CATEGORIES = ['HRIS', 'ATS'];

const filterOnlyRelevantIntegrations = (
  integrations: IntegrationDescription[]
) => {
  return integrations.filter((integration) =>
    integration.categories.some((c) =>
      RELEVANT_CATEGORIES.includes(c.toUpperCase())
    )
  );
};

const AppIntegrationListing = () => {
  const { status, error, data } = useConfirmApiWithDefault<
    IntegrationDescription[]
  >({
    method: 'GET',
    url: '/integrations/all',
    defaultValue: [],
    postProcess: filterOnlyRelevantIntegrations,
  });

  return (
    <div className={'mt-5'}>
      <h1>
        <FormattedMessage
          id="app.views.app_integrations.app_integration_listing.title"
          defaultMessage="Looking for a new HRIS?"
        />
      </h1>
      {status === 'LOADING' && (
        <Loading dataTestId="app-integration-listing-data-testid" />
      )}
      {status === 'ERROR' && JSON.stringify(error)}
      {status === 'SUCCESS' && (
        <Container>
          <Row xs={1} sm={1} md={1} lg={3} xl={5}>
            {data.map((integration) => (
              <Col key={integration.slug}>
                <Card>
                  <CardBody>
                    <div>
                      <div>
                        <b>{integration.name}</b>
                      </div>
                      <div>
                        <span className="text-muted text-uppercase">
                          {integration.categories.join(', ')}
                        </span>
                      </div>
                    </div>
                    <img
                      className="w-100 p-4 b-1 pb-0 pt-0"
                      alt={integration.name}
                      src={integration.square_image}
                    />
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default AppIntegrationListing;
