import { Col, Row } from 'reactstrap';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';

import PropTypes from 'prop-types';
import SwitchInput from './SwitchInput';

// N.B. properties which should be shared between the child switches
// can optionally be passed as part of SwitchBank. When the child switches
// are created, if a specific property is not explicitly provided in the
// "switches" array it will be pulled from the parent SwitchBank.
// switches. e.g., style, checkedValue, uncheckedValue, className,
// disabled, autofocus.
const SwitchBank: FC<Props> = ({
  value = {},
  checkedValue = true,
  uncheckedValue = false,
  ...props
}) => {
  const propsOnChange = props.onChange;

  // the backing data is an object like: { 'slack-feedback-requested': 'Y', 'email-feedback-requested': 'N' }
  const [backingData, setBackingData] = useState(value);

  const onChange = useCallback(
    (name, value) => {
      const newBackingData = { ...backingData, [name]: value };
      setBackingData(newBackingData);
      // @ts-expect-error
      propsOnChange(newBackingData);
    },
    [backingData, propsOnChange]
  );

  useEffect(() => {
    if (isEmpty(backingData) || !isEqual(backingData, value)) {
      setBackingData({ ...backingData, ...value });
    }
  }, [value, backingData]);

  return (
    <Row xs="1" sm="12">
      {props.switches?.map((sw) => {
        const value =
          // @ts-expect-error
          sw.name in backingData && backingData[sw.name] !== undefined
            ? // @ts-expect-error
              backingData[sw.name]
            : props.defaultValue;
        return (
          // @ts-expect-error
          <Col className="col-auto" key={sw.name}>
            <SwitchInput
              // @ts-expect-error
              role={props.role}
              // @ts-expect-error
              style={sw.style || props.style}
              // @ts-expect-error
              name={sw.name}
              value={value}
              // @ts-expect-error
              checkedValue={sw.checkedValue || checkedValue}
              // @ts-expect-error
              uncheckedValue={sw.uncheckedValue || uncheckedValue}
              // @ts-expect-error
              onChange={(val) => onChange(sw.name, val)}
              // @ts-expect-error
              className={sw.className || props.className}
              // @ts-expect-error
              disabled={sw.disabled || props.disabled}
              // @ts-expect-error
              autofocus={sw.autofocus || props.autofocus}
            />
          </Col>
        );
      })}
    </Row>
  );
};

const SwitchBank_propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  style: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  checkedValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  uncheckedValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  switches: PropTypes.arrayOf(PropTypes.object),
};

type Props = PropTypes.InferProps<typeof SwitchBank_propTypes>;

export default React.memo(SwitchBank);
