import * as consts from '../../consts/consts';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo, useState } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import { Link } from 'react-router-dom';
import ModalCredentialIssuanceEditor from '../Widgets/Modals/ModalCredentialIssuanceEditor';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const now = new Date();

const getMonDD = (dateString) => {
  const date = new Date(dateString);

  // if this year, show MONTH_NAME DD
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleString('default', { month: 'short', day: 'numeric' });
  }

  // not this year, so show MONTH_NAME DD, YYYY
  return date.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const getValidTimingText = (credentialIssuance) => {
  if (
    !credentialIssuance ||
    (!credentialIssuance.date_effective && !credentialIssuance.date_expired)
  ) {
    return 'Valid with no expiration';
  }

  if (credentialIssuance.date_expired) {
    if (credentialIssuance.date_effective) {
      return (
        'Valid from ' +
        getMonDD(credentialIssuance.date_effective) +
        ' to ' +
        getMonDD(credentialIssuance.date_expired)
      );
    } else {
      return 'Valid until ' + getMonDD(credentialIssuance.date_expired);
    }
  } else {
    return 'Valid since ' + getMonDD(credentialIssuance.date_effective);
  }
};

const PersonProfileCredentialIssuanceRow: FC<
  PersonProfileCredentialIssuanceRowProps
> = (props) => {
  const { formatMessage } = useIntl();

  const [showEditCredentialModal, setShowEditCredentialModal] = useState(false);
  const toggleEditCredentialModal = () =>
    setShowEditCredentialModal(!showEditCredentialModal);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setConfirmDeleteModal(!confirmDeleteModal);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);

  const credentialIssuance = props.credentialIssuance;
  const credentialIssuances = useMemo(
    // @ts-expect-error
    () => (props.credentialIssuances ? props.credentialIssuances : []),
    // @ts-expect-error
    [props.credentialIssuances]
  );

  const confirmDelete = () => {
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      // @ts-expect-error
      '/credential-issuances/' + credentialIssuance.id,
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            setDeleteValidationErrors(hardErrorMessage);
          } else {
            setDeleteValidationErrors(error);
          }
        } else {
          setConfirmDeleteModal(false);

          // update user and show toast
          toast.success(
            formatMessage({
              id: 'app.views.person.person_profile_credentials_summary.toast.credential_deleted',
              defaultMessage: 'Credential deleted!',
            })
          );
          props.successCallback(
            credentialIssuances.filter(
              // @ts-expect-error
              (ci) => ci?.id?.toString() !== credentialIssuance.id?.toString()
            )
          );
        }
      },
      null
    );
  };

  return (
    <Row>
      <Col>
        <h4 className="mb-2">
          <Link
            to={
              // @ts-expect-error
              consts.CREDENTIALS().path + '/' + credentialIssuance.credential.id
            }
          >
            {/* @ts-expect-error */}
            {credentialIssuance.credential.name}
          </Link>
        </h4>
        <p className="text-muted mb-1 small">
          {/* @ts-expect-error */}
          {credentialIssuance.authority_website && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              // @ts-expect-error
              href={credentialIssuance.authority_website}
            >
              {/* @ts-expect-error */}
              {credentialIssuance.authority_name}
            </a>
          )}
          {/* @ts-expect-error */}

          {!credentialIssuance.authority_website &&
            // @ts-expect-error
            credentialIssuance.authority_name}
        </p>
        <p className="text-muted small mb-0">
          {getValidTimingText(credentialIssuance)}
        </p>
      </Col>
      {props.isMe && (
        <Col className="col-auto">
          <ModalCredentialIssuanceEditor
            isOpen={showEditCredentialModal}
            toggle={toggleEditCredentialModal}
            // @ts-expect-error
            buttonTitle={formatMessage({
              id: 'app.views.person.person_profile_credentials_summary.button_title.edit',
              defaultMessage: 'Edit',
            })}
            title={formatMessage({
              id: 'app.views.person.person_profile_credentials_summary.title.edit_credential',
              defaultMessage: 'Edit Credential',
            })}
            object={credentialIssuance}
            successCallback={(updatedIssuance) =>
              props.successCallback(
                credentialIssuances.filter((ci) =>
                  ci?.id?.toString() === updatedIssuance.id?.toString()
                    ? updatedIssuance
                    : ci
                )
              )
            }
          />
          <ConfirmationDialogModal
            isOpen={confirmDeleteModal}
            onClosed={() => setDeleteValidationErrors(null)}
            toggle={toggleConfirmDeleteModal}
            confirmCallback={confirmDelete}
            title={formatMessage({
              id: 'app.views.person.person_profile_credentials_summary.title.delete_credential?',
              defaultMessage: 'Delete credential?',
            })}
            description={formatMessage({
              id: 'app.views.person.person_profile_credentials_summary.description.delete_credential',
              defaultMessage:
                'Are you sure that you want to delete this credential?',
            })}
            confirmText={formatMessage({
              id: 'app.views.person.person_profile_credentials_summary.confirm_text.delete_credential',
              defaultMessage: 'Delete credential',
            })}
            validationErrors={deleteValidationErrors}
          />
          <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle
              tag="div"
              role="button"
              className="dropdown-ellipses text-primary text-decoration-none"
            >
              <Button color="link" className="p-0">
                <span className={'me-2 fe fe-edit'}></span>
                <small>
                  <FormattedMessage
                    id="app.views.person.person_profile_credentials_summary.dropdown.edit"
                    defaultMessage="Edit"
                  />
                </small>
              </Button>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={toggleEditCredentialModal}>
                <FormattedMessage
                  id="app.views.person.person_profile_credentials_summary.dropdown.edit_credential"
                  defaultMessage="Edit credential"
                />
              </DropdownItem>
              <DropdownItem onClick={toggleConfirmDeleteModal}>
                <FormattedMessage
                  id="app.views.person.person_profile_credentials_summary.dropdown.delete_credential"
                  defaultMessage="Delete credential"
                />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      )}
    </Row>
  );
};

const PersonProfileCredentialIssuanceRow_propTypes = {
  isMe: PropTypes.bool.isRequired,
  credentialIssuance: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
};

type PersonProfileCredentialIssuanceRowProps = PropTypes.InferProps<
  typeof PersonProfileCredentialIssuanceRow_propTypes
>;

const PersonProfileCredentialsSummary: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [showAddCredentialModal, setShowAddCredentialModal] = useState(false);
  const toggleAddCredentialModal = () =>
    setShowAddCredentialModal(!showAddCredentialModal);

  const credentialIssuances = props.credentialIssuances;

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle icon={consts.ICONS.CREDENTIAL}>
          <FormattedMessage
            id="app.views.person.person_profile_credentials_summary.credentials"
            defaultMessage="
          Credentials
        "
          />
        </CardHeaderTitle>
        {props.isMe && (
          <>
            <ModalCredentialIssuanceEditor
              isOpen={showAddCredentialModal}
              toggle={toggleAddCredentialModal}
              // @ts-expect-error
              buttonIcon="fe fe-plus"
              buttonTitle={formatMessage({
                id: 'app.views.person.person_profile_credentials_summary.button_title.add',
                defaultMessage: 'Add',
              })}
              title={formatMessage({
                id: 'app.views.person.person_profile_credentials_summary.title.add_credential',
                defaultMessage: 'Add Credential',
              })}
              successCallback={(addedIssuance) =>
                props.successCallback([...credentialIssuances, addedIssuance])
              }
            />
            <Button
              color="link"
              className="p-0"
              onClick={toggleAddCredentialModal}
            >
              <span className={'me-2 fe fe-plus'}></span>
              <small>
                <FormattedMessage
                  id="app.views.person.person_profile_credentials_summary.add"
                  defaultMessage="Add"
                />
              </small>
            </Button>
          </>
        )}
      </CardHeader>
      <CardBody>
        {!(credentialIssuances?.length === 0) && (
          <ListGroup className="list-group-flush my-n3">
            {credentialIssuances.map((credentialIssuance, index) => (
              <ListGroupItem key={index}>
                <PersonProfileCredentialIssuanceRow
                  isMe={props.isMe}
                  // @ts-expect-error
                  credentialIssuance={credentialIssuance}
                  credentialIssuances={credentialIssuances}
                  successCallback={props.successCallback}
                />
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {(!credentialIssuances || credentialIssuances.length === 0) && (
          <p
            className={
              'text-center text-muted my-3 px-5' +
              (props.className ? ' ' + props.className : '')
            }
          >
            <FormattedMessage
              id="app.views.person.person_profile_credentials_summary.no_credentials_added"
              defaultMessage="
            No credentials added yet
          "
            />
          </p>
        )}
      </CardBody>
    </Card>
  );
};

const PersonProfileCredentialsSummary_propTypes = {
  isMe: PropTypes.bool.isRequired,
  successCallback: PropTypes.func.isRequired,
  credentialIssuances: PropTypes.arrayOf(PropTypes.object).isRequired,
  contributions: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  prepTagsForSubmit: PropTypes.func,
};

type Props = PropTypes.InferProps<
  typeof PersonProfileCredentialsSummary_propTypes
>;

export default React.memo(PersonProfileCredentialsSummary);
