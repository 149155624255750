import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { Me, Person, Task } from '../../types';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import {
  TASK_TYPES_IN_DISPLAY_ORDER,
  getTaskHeadingByType,
} from '../../utils/models/Task';
import { acceptTask, declineTask, loadTasks } from '../../actions';

import Avatar from '../Widgets/People/Avatar';
import { FormattedMessage } from 'react-intl';
import RelativeTime from '../Widgets/RelativeTime';
import TaskDescription from '../Widgets/TaskDescription';
import { connect } from 'react-redux';
import trophyImg from '../../assets/img/illustrations/trophy.png';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  isFullPage: boolean;
  me: Me;
  loadTasks: (userSub?: string, email?: string) => void;
  currentProxyPerson?: Person;
  tasks: Task[];
  acceptTask: (task: Task) => void;
  declineTask: (task: Task) => void;
}

interface ItemProps {
  acceptTask: (task: Task) => void;
  declineTask: (task: Task) => void;
  me: Me;
  task: Task;
}

const TaskItem: FC<ItemProps> = ({ task, me, acceptTask, declineTask }) => {
  if (task.time) {
    // @ts-expect-error
    task.timeText = <RelativeTime unit="day" datetime={task.time} />;
  }

  return (
    <ListGroupItem className="text-reset">
      <Row>
        <Col className="col-auto">
          <Avatar person={task.sender ? task.sender : me} />
        </Col>
        <Col className="ms-n3">
          <h4 className="mb-1 mt-1 fw-normal">
            <TaskDescription description={task.listDescription} />
          </h4>
          <p className="text-muted mb-0 small">{task.timeText}</p>
        </Col>
        {task.acceptText && (
          <Col className="col-auto">
            <Button color="primary" onClick={() => acceptTask(task)}>
              {task.acceptText}
            </Button>
          </Col>
        )}
        {task.declineText && (
          <Col className="col-auto ps-0">
            <Button color="light" onClick={() => declineTask(task)}>
              {task.declineText}
            </Button>
          </Col>
        )}
      </Row>
    </ListGroupItem>
  );
};

const TasksList: FC<Props> = ({
  acceptTask,
  currentProxyPerson,
  declineTask,
  isFullPage,
  loadTasks,
  me,
  tasks,
}: Props) => {
  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    if (me?.id) {
      loadTasks(userSub, currentProxyPerson?.email);
    }
  }, [loadTasks, me?.id, userSub, currentProxyPerson?.email]);

  const headerText = useMemo(() => {
    if (tasks?.length > 0) {
      return tasks.length === 1 ? (
        <FormattedMessage
          id="app.views.tasks.tasks_list.header_with_count.singular"
          defaultMessage="You have one task to complete."
          description="Full sentence indicating there is one task to complete."
        />
      ) : (
        <FormattedMessage
          id="app.views.tasks.tasks_list.header_with_count.plural"
          defaultMessage="You have {count} tasks to complete."
          description="Full sentence indicating the number of tasks left to complete."
          values={{ count: tasks.length }}
        />
      );
    } else {
      return (
        <FormattedMessage
          id="app.views.tasks.tasks_list.header"
          defaultMessage="Tasks"
          description="Title for list of tasks: 'Tasks'"
        />
      );
    }
  }, [tasks.length]);

  // separate tasks lists by type
  const taskListsDict = useMemo(() => {
    return tasks.reduce((acc: Record<string, Task[]>, t: Task) => {
      if (!(t.type in acc)) {
        acc[t.type] = [];
      }

      acc[t.type].push(t);

      return acc;
    }, {});
  }, [tasks]);

  return (
    <>
      {isFullPage && (
        <>
          <div className="text-center">
            <img src={trophyImg} alt="trophy" className="w-25 mb-3 mt-5" />
          </div>
          <h2 className="text-center mt-2 pb-4">{headerText}</h2>
        </>
      )}
      {!isFullPage && (
        <>
          <h4 className="mb-4 text-muted">{headerText}</h4>
        </>
      )}
      {tasks?.length === 0 && (
        <Card>
          <CardBody>
            <div className="text-muted text-center">
              <FormattedMessage
                id="app.views.tasks.tasks_list.no_tasks"
                defaultMessage="You have no other pending tasks. Hooray!"
                description="Happy message that there are no tasks left!"
              />
            </div>
          </CardBody>
        </Card>
      )}
      {isFullPage &&
        tasks?.length > 0 &&
        TASK_TYPES_IN_DISPLAY_ORDER.map(
          (type, index) =>
            taskListsDict[type.id]?.length > 0 && (
              <Fragment key={index}>
                <div className="text-center text-muted mb-3">
                  {getTaskHeadingByType(type.id)}
                </div>
                <Card>
                  <CardBody>
                    <ListGroup className="list-group-flush my-n3">
                      {taskListsDict[type.id]?.map((task, taskIndex) => (
                        <TaskItem
                          task={task}
                          key={taskIndex}
                          me={me}
                          acceptTask={acceptTask}
                          declineTask={declineTask}
                        />
                      ))}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Fragment>
            )
        )}
      {!isFullPage && tasks?.length > 0 && (
        <Fragment>
          <Card>
            <CardBody>
              <ListGroup className="list-group-flush my-n3">
                {tasks.map((task, taskIndex) => (
                  <TaskItem
                    task={task}
                    key={taskIndex}
                    me={me}
                    acceptTask={acceptTask}
                    declineTask={declineTask}
                  />
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        </Fragment>
      )}
    </>
  );
};

const mapStateToProps = (state: {
  me: Me;
  tasks: Task[];
  currentProxyPerson?: Person;
}) => {
  const { me, tasks, currentProxyPerson } = state;

  return {
    me,
    tasks,
    currentProxyPerson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTasks: (userSub: string | undefined, proxy: string | undefined) =>
      dispatch(loadTasks(userSub, proxy)),
    acceptTask: (task: Task) => dispatch(acceptTask(task)),
    declineTask: (task: Task) => dispatch(declineTask(task)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TasksList));
