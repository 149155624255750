import * as consts from '../../consts/consts';

import { Col, Row } from 'reactstrap';
import { Organization, Person, ReduxState } from '../../types';
import React, { FC } from 'react';
import { updateMe, updateMyConfigs } from '../../actions';
import {
  useConfirmIntl,
  useLanguageSettings,
} from '../../locale/ConfirmIntlContext';

import { INPUT_TYPES } from '../../views/Widgets/Inputs/ValidatedInputTypes';
import Loading from '../Widgets/Loading';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import WelcomePage from './WelcomePage';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface WelcomeStep1Props {
  currentOrganization: Organization;
  currentProxyPerson: Person;
  me: Person;
  updateMyConfigs: (changes: any) => void;
}

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentProxyPerson } = state;

  return {
    me,
    currentOrganization,
    currentProxyPerson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMe: (me) => dispatch(updateMe(me)),
    updateMyConfigs: (me) => dispatch(updateMyConfigs(me)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const WelcomeStep1: FC<WelcomeStep1Props & PropsFromRedux> = (props) => {
  const history = useHistory();
  const person = props.me;
  const urlParams = new URLSearchParams(location.search);
  const { formatMessage } = useIntl();
  const { setCurrentLocale } = useConfirmIntl();

  const languageSettings = useLanguageSettings();

  let continueUrl = urlParams.get(consts.URL_QUERY_PARAMETERS.CONTINUE_URL);
  if (!continueUrl) {
    continueUrl = consts.HOME(formatMessage).path;
  }

  const transformObjectBeforeSubmit = (object) => {
    return {
      // users that are created manually (not via
      // Google/Microsft oauth) need to enter their
      // first/last name manually
      legal_given_name: object.legal_given_name,
      preferred_given_name: object.preferred_given_name,
      family_name: object.family_name,
      locale: object.locale,
    };
  };

  const callback = (data) => {
    if (data) {
      // we set welcome_completed_at explicitly here
      // so that pulling me next time will correctly
      // move us onto the next page instead of again redirecting to welcome
      // due to ProtectedRoute.js
      data.configs ||= {};
      data.configs.welcome_completed_at = new Date();
      setCurrentLocale(data.locale);
      // update state so we don't try going to welcome again
      (async () => {
        // update language settings so it appears immediately
        await props.updateMe({ locale: data.locale });
        await props.updateMyConfigs(data.configs);
        // needs to be in async to prevent a React memory leak error
        return history.replace(continueUrl ? continueUrl : '/');
      })();
    }
  };

  const renderForm = (inputs, submitButton) => {
    return (
      <>
        <Row>{inputs}</Row>
        <hr className="my-5" />
        <Row className="align-items-center justify-content-center">
          <Col className="text-center align-self-center col-12 col-md-3">
            {submitButton}
          </Col>
        </Row>
      </>
    );
  };

  const renderValidationErrors = (validationErrors) => {
    return <Col>{validationErrors}</Col>;
  };

  const getCol6 = (element) => {
    return <Col className="col-12 col-md-6">{element}</Col>;
  };

  const getCol12 = (element) => {
    return <Col className="col-12">{element}</Col>;
  };

  return (
    <WelcomePage
      step="1"
      title={formatMessage({
        id: 'app.views.welcome.welcome_step1.title.let_s_set_up_your_account',
        defaultMessage: "Let's set up your account.",
      })}
      nextButtonText={formatMessage({
        id: 'app.views.welcome.welcome_step1.next_button_text.save',
        defaultMessage: 'Save',
      })}
    >
      {!languageSettings ? (
        <Loading />
      ) : (
        <ValidatedForm
          submitText={formatMessage({
            id: 'app.views.welcome.welcome_step1.submit_text.save',
            defaultMessage: 'Save',
          })}
          buttonClassName="btn-lg w-100"
          buttonIsBlock={false}
          url="complete-profile"
          method="POST"
          transformObjectBeforeSubmit={transformObjectBeforeSubmit}
          renderForm={renderForm}
          renderValidationErrors={renderValidationErrors}
          callback={callback}
          object={{
            ...person,

            // use backend field names (note: first name defaults
            // to email so if that's the case, blank it out)
            legal_given_name:
              person.legal_given_name === person.email
                ? ''
                : person.legal_given_name,
            family_name: person.family_name,
            preferred_given_name: person.preferred_given_name
              ? person.preferred_given_name
              : person.legal_given_name === person.email
              ? ''
              : person.legal_given_name,
            locale: person.locale,
          }}
          inputs={[
            {
              required: true,
              name: 'legal_given_name',
              label: formatMessage({
                id: 'app.views.welcomestep1.legal_name.text',
                defaultMessage: 'First name',
              }),
              wrapperFunction: getCol6,
            },
            {
              required: true,
              name: 'family_name',
              label: formatMessage({
                id: 'app.views.welcomestep1.family_name.text',
                defaultMessage: 'Last name',
              }),
              wrapperFunction: getCol6,
            },
            {
              required: true,
              name: 'preferred_given_name',
              label: formatMessage({
                id: 'app.views.welcomestep1.given_name.text',
                defaultMessage: 'Preferred first name',
              }),
              helperText: formatMessage({
                id: 'app.views.welcomestep1.given_name.helper_text',
                defaultMessage:
                  'The first name you want people to call you in most situations',
              }),
              wrapperFunction: getCol12,
            },
            {
              required: true,
              disabled: !languageSettings.enabled,
              name: 'locale',
              label: formatMessage({
                id: 'app.views.welcomestep1.locale.text',
                defaultMessage: 'Display language',
              }),
              helperText: formatMessage({
                id: 'app.views.welcomestep1.locale.helper_text',
                defaultMessage:
                  'Language for buttons, titles, emails, and other text from Confirm',
              }),
              type: INPUT_TYPES.DROPDOWN,
              objects: languageSettings.supported_locales,
              defaultValue: languageSettings.current_locale,
              wrapperFunction: getCol12,
            },
          ]}
        />
      )}
    </WelcomePage>
  );
};

export default connector(React.memo(WelcomeStep1));
