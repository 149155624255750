import * as consts from '../../consts/consts';

import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, Suspense, useState } from 'react';

import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import PeopleAdministrationHRAdministrators from './PeopleAdministrationAdministrators';
import SwitchInput from '../Widgets/Inputs/SwitchInput';

const PeopleAdministrationDashboard = React.lazy(
  () => import('./PeopleAdministrationDashboard')
);

const PersonAdministrationHubPage: FC = () => {
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [
    hasAtLeastOneHideByDefaultColumn,
    setHasAtLeastOneHideByDefaultColumn,
  ] = useState(false);
  const { formatMessage } = useIntl();

  const tabs = [
    {
      path: consts.ADMINISTRATION_PEOPLE(formatMessage).path,
      name: (
        <FormattedMessage
          id="app.widgets.task.all_people"
          defaultMessage="All people"
        />
      ),
      shouldRenderContainer: !showAllColumns,
      containerClassName: showAllColumns ? 'container-fluid px-5' : undefined,
      content: (
        <Suspense
          fallback={
            <Loading
              message={formatMessage({
                id: 'app.views.administration.people_administration_hub_page.message.loading_dashboard',
                defaultMessage: 'Loading dashboard...',
              })}
            />
          }
        >
          <PeopleAdministrationDashboard
            showAllColumns={showAllColumns}
            setHasAtLeastOneHideByDefaultColumn={
              setHasAtLeastOneHideByDefaultColumn
            }
          />
        </Suspense>
      ),
      action: (
        <Row>
          {!hasAtLeastOneHideByDefaultColumn && (
            <Col className="col-auto me-sm-3 mb-3 mb-sm-0">
              <SwitchInput
                switchLabel={
                  <FormattedMessage
                    id="app.widgets.task.show_advanced_columns"
                    defaultMessage="Show advanced columns"
                  />
                }
                value={showAllColumns}
                onChange={setShowAllColumns}
              />
            </Col>
          )}
        </Row>
      ),
    },
    {
      path: consts.ADMINISTRATION_PEOPLE_ADMINISTRATORS(formatMessage).path,
      name: consts.ADMINISTRATION_PEOPLE_ADMINISTRATORS(formatMessage).name,
      content: (
        <PeopleAdministrationHRAdministrators
          showAllColumns={showAllColumns}
          setHasAtLeastOneHideByDefaultColumn={
            setHasAtLeastOneHideByDefaultColumn
          }
        />
      ),
    },
  ];

  return (
    <Page
      title={consts.ADMINISTRATION_PEOPLE(formatMessage).name}
      tabs={tabs}
    />
  );
};

export default React.memo(PersonAdministrationHubPage);
