import React, { FC, useCallback, useEffect } from 'react';

import { Prompt } from 'react-router';
import { UNSAVED_CHANGES_PROMPT } from '../consts/consts';

type Props = {
  hasPendingChanges: boolean;
  message?: string;
};

const OperationInProgressPrompt: FC<Props> = ({
  message = UNSAVED_CHANGES_PROMPT,
  hasPendingChanges,
}) => {
  const leavePromptMessage = UNSAVED_CHANGES_PROMPT;
  const onUnload = useCallback(
    (e) => {
      if (hasPendingChanges) {
        e.preventDefault();
        e.returnValue = leavePromptMessage;
        return leavePromptMessage;
      }
    },
    [hasPendingChanges, leavePromptMessage]
  );
  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [hasPendingChanges, onUnload]);
  return (
    <>
      <Prompt when={hasPendingChanges} message={message} />
      {hasPendingChanges && (
        <span data-testid="background-saving" hidden={true}>
          {'Saving...'}
        </span>
      )}
    </>
  );
};

export default OperationInProgressPrompt;
