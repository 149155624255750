import * as consts from '../../consts/consts';

import {
  ACTIVITY_DEFAULT_DESCRIPTION,
  ACTIVITY_STAGES,
  ACTIVITY_STAGE_LIVE,
  ACTIVITY_TYPES,
  ACTIVITY_VISIBILITIES,
  ACTIVITY_VISIBILITY_EVERYONE,
} from '../../utils/models/Activity';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownToggle,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import {
  CONTRIBUTION_EDITOR_ATTRIBUTES,
  getQueryWithType,
} from '../Widgets/Inputs/ValidatedInput';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getInvalidPeopleError,
  getPersonOrInvitationObjectForSending,
} from '../../utils/models/Person';

import Avatar from '../Widgets/People/Avatar';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import MediaUploader from '../Widgets/Inputs/MediaUploader';
import ModalEditorButton from '../Widgets/Modals/ModalEditorButton';
import { connect } from 'react-redux';
import { prepTagsForSubmit } from '../../utils/util/util';
import { useHistory } from 'react-router-dom';

export const ACTIVITY_EDITOR_FIELDS = (intl) => ({
  visibility: {
    required: true,
    name: 'visibility',
    type: INPUT_TYPES.DROPDOWN,
    objects: ACTIVITY_VISIBILITIES(intl.formatMessage),
    defaultValue: ACTIVITY_VISIBILITIES(intl.formatMessage)[0].id,
  },
  name: {
    required: true,
    name: 'name',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.name.label',
      defaultMessage: 'Title',
    }),
    placeholder: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.name.placeholder',
      defaultMessage: 'Enter title',
    }),
    type: INPUT_TYPES.TEXTAREA,
  },
  description: {
    required: true,
    type: INPUT_TYPES.RICH_TEXT_EDITOR,
    name: 'description',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.description.label',
      defaultMessage: 'Description',
    }),
    autoFocus: true,
  },
  type: {
    required: true,
    name: 'type',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.type.label',
      defaultMessage: 'Type',
    }),
    objects: ACTIVITY_TYPES(intl),
    type: INPUT_TYPES.DROPDOWN,
  },
  stage: {
    required: true,
    name: 'stage',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.stage.label',
      defaultMessage: 'Stage',
    }),
    objects: ACTIVITY_STAGES,
    type: INPUT_TYPES.DROPDOWN,
  },
  date_started: {
    name: 'date_started',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.date_started.label',
      defaultMessage: 'Date started',
    }),
    placeholder: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.date_started.placeholder',
      defaultMessage: 'Enter the date this was started',
    }),
    defaultValue: new Date(),
    type: INPUT_TYPES.DATE_PICKER,
  },
  date_completed: {
    name: 'date_completed',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.date_completed.label',
      defaultMessage: 'Date completed',
    }),
    placeholder: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.date_completed.placeholder',
      defaultMessage: 'Leave blank if ongoing',
    }),
    defaultValue: new Date(),
    type: INPUT_TYPES.DATE_PICKER,
  },
  parent: {
    name: 'parent',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.parent.label',
      defaultMessage: 'Parent Activity',
    }),
    type: INPUT_TYPES.SELECT,
    elasticsearchOptions: {
      url: 'get-activities-by-name',
      index: 'activities',
      getQuery: getQueryWithType,
    },
  },
  contributions: {
    type: INPUT_TYPES.PEOPLE_EDITOR,
    name: 'contributions',
    label: intl.formatMessage({
      id: 'app.activity_page.modal_editor.fields.contributions.label',
      defaultMessage: 'Invite other people who contributed',
    }),
    inline: true,
    inForm: true,
    autoFocus: true,
    showInviteInstructions: true,
    showInviteSuggestions: true,
  },
});

const isVideo = (file) => {
  const extension = file.link.split('.').pop();
  switch (extension.toLowerCase()) {
    case 'mp4':
    case 'webm':
    case 'ogg':
    case 'mov':
    case 'quicktime':
    case 'm4v':
    case 'avi':
    case 'mpg':
      return true;
    default:
      return false;
  }
};

const ModalActivityEditorButton: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const INVITE_SUBMIT_TEXT = intl.formatMessage({
    id: 'app.activity_page.modal_editor.button.submit.text',
    defaultMessage: 'Create & Invite Contributors',
  });

  const [activityType, setActivityType] = useState(props.activityType);
  const [activityVisibility, setActivityVisibility] = useState(
    ACTIVITY_VISIBILITIES(intl.formatMessage)[0].id
  );
  const [activityName, setActivityName] = useState(props.activityName);
  const [activityDescription, setActivityDescription] = useState(null);
  const [contributions, setContributions] = useState(props.contributions);
  const [inviteEmailFieldToggle, setInviteEmailFieldToggle] = useState(false);
  const [showDescriptionEditor, setShowDescriptionEditor] = useState(false);
  const [showMediaUploader, setShowMediaUploader] = useState(false);
  const [showPeopleEditor, setShowPeopleEditor] = useState(
    // @ts-expect-error
    props.contributions?.length > 0
  );
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [dateStarted, setDateStarted] = useState(
    props.defaultActivityDateString
      ? new Date(props.defaultActivityDateString)
      : new Date()
  );
  const [dateCompleted, setDateCompleted] = useState(
    props.defaultActivityDateString
      ? new Date(props.defaultActivityDateString)
      : new Date()
  );
  // Add states for merged contribution dialog
  const [contributionDescription, setContributionDescription] =
    useState(undefined);
  const [contributionContributorRole, setContributionContributorRole] =
    useState(undefined);
  const [contributionSkills, setContributionSkills] = useState(undefined);

  const propsSetHasUnsavedChanges = props.setHasUnsavedChanges;

  // NOTE: the below should match the above values
  const resetModalData = useCallback(() => {
    setActivityType(props.activityType);
    setActivityVisibility(ACTIVITY_VISIBILITY_EVERYONE(formatMessage).id);
    setActivityName(props.activityName);
    setActivityDescription(null);
    setContributions(props.contributions);
    setInviteEmailFieldToggle(false);
    setShowDescriptionEditor(false);
    setShowMediaUploader(false);
    // @ts-expect-error
    setShowPeopleEditor(props.contributions?.length > 0);
    setShowDatePickers(false);
    setDateStarted(
      props.defaultActivityDateString
        ? new Date(props.defaultActivityDateString)
        : new Date()
    );
    setDateCompleted(
      props.defaultActivityDateString
        ? new Date(props.defaultActivityDateString)
        : new Date()
    );
    setContributionContributorRole(undefined);
    setContributionDescription(undefined);
    setContributionSkills(undefined);
  }, [
    props.activityType,
    props.activityName,
    props.contributions,
    props.defaultActivityDateString,
    formatMessage,
  ]);

  const propsButtonText =
    props.buttonText ??
    intl.formatMessage({
      id: 'app.activity_page.modal_editor.button.default.label',
      defaultMessage: 'Add to resume',
    });

  const title = useMemo(
    () =>
      // @ts-expect-error
      activityType?.name
        ? intl.formatMessage(
            {
              id: 'app.activity_page.modal_editor.title',
              defaultMessage: 'Create {activityType} ',
            },
            {
              // @ts-expect-error
              activityType: activityType?.name
                ? // @ts-expect-error
                  activityType.name
                : intl.formatMessage({
                    id: 'app.activity_page.modal_editor.activity.label',
                    defaultMessage: 'activity',
                  }),
            }
          )
        : propsButtonText,
    [activityType, propsButtonText, intl]
  );
  const modalTitle = title;
  const submitText =
    contributions && contributions.length > 0 ? INVITE_SUBMIT_TEXT : title;
  const anchorTrigger = props.anchorTrigger;
  const history = useHistory();
  const propsOnClosed = props.onClosed;
  const propsCallback = props.callback;
  const person = props.me;

  useEffect(() => {
    if (props.defaultActivityDateString) {
      setDateStarted(new Date(props.defaultActivityDateString));
      setDateCompleted(new Date(props.defaultActivityDateString));
    }
  }, [props.defaultActivityDateString]);

  // ensure that when outside props are updated,
  // internal state is updated
  useEffect(() => {
    setActivityType(props.activityType);
  }, [props.activityType]);

  useEffect(() => {
    setActivityName(props.activityName);
  }, [props.activityName]);

  useEffect(() => {
    setContributions(props.contributions);
    // @ts-expect-error
    if (props.contributions?.length > 0) {
      setShowPeopleEditor(true);
    }
  }, [props.contributions]);

  const onSubmitCallback = useCallback(
    (activity) => {
      if (activity) {
        // reset dialog so when reopened it starts fresh
        resetModalData();

        if (propsCallback) {
          // stay on current page (e.g. for Performance cycle activity creation)
          // and pass activity back for populating on existing page
          propsCallback(activity);
        } else {
          // store returned contribution on frontend and load corresponding page
          history.push(
            consts.ACTIVITIES().path + '/' + activity.id + '#created'
          );
        }
      }
    },
    [propsCallback, resetModalData, history]
  );

  const getButtonComponent = useCallback(
    (p) =>
      props.hideButton ? (
        <></>
      ) : (
        <Button color="primary" {...p}>
          {propsButtonText}
        </Button>
      ),
    [propsButtonText, props.hideButton]
  );

  const onClosed = useCallback(() => {
    // The warning dialog prompts users when they exit
    // so we reset the modal upon close
    resetModalData();

    if (propsOnClosed) {
      propsOnClosed();
    }
  }, [propsOnClosed, resetModalData]);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const contributions = object.contributions
        ? [
            ...object.contributions.map((c) =>
              getPersonOrInvitationObjectForSending('contributor_person', c)
            ),
          ]
        : [];

      return {
        id: object.id,
        visibility: object.visibility,
        name: object.name,
        description: object.description,
        type: object.type?.id,
        stage: ACTIVITY_STAGE_LIVE.id,
        // @ts-expect-error
        organization: props.currentOrganization?.id,
        date_started: object.date_started,
        date_completed: object.date_completed,
        contributions: props.showContributorFields
          ? [
              // lead in with this person's contribution
              {
                // @ts-expect-error
                contributor_person: person?.id,
                contributor_role: object.contribution_contributor_role,
                description: object.contribution_description,
                skills: prepTagsForSubmit(
                  object.contribution_skills,
                  // @ts-expect-error
                  props.currentOrganization?.id
                ),
              },
              ...contributions,
            ]
          : contributions,
      };
    },
    // @ts-expect-error
    [person?.id, props.currentOrganization?.id, props.showContributorFields]
  );

  const handleMention = useCallback(
    (item) => {
      let newPerson = null;

      if (item._index === 'people') {
        newPerson = item.object;
      }

      // TODO: get this to handle more than just the most recent person added via mentions
      if (newPerson) {
        // @ts-expect-error
        setContributions([...contributions, newPerson]);
      }
    },
    [contributions]
  );

  // @ts-expect-error
  const validDomains = props.currentOrganization?.email_domains;

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (props.showContributorFields) {
        if (!obj.contribution_contributor_role) {
          errors['contribution_contributor_role'] = intl.formatMessage({
            id: 'app.activity_page.modal_editor.error.validation.contribution_contributor_role.required',
            defaultMessage: 'A role is required.',
          });
        }

        if (!obj.contribution_description) {
          errors['contribution_description'] = intl.formatMessage({
            id: 'app.activity_page.modal_editor.error.validation.contribution_description.required',
            defaultMessage: 'A description is required.',
          });
        }

        if (!(obj.contribution_skills?.length > 0)) {
          errors['contribution_skills'] = intl.formatMessage({
            id: 'app.activity_page.modal_editor.error.validation.contribution_skills.required',
            defaultMessage: 'At least one skill is required.',
          });
        }
      }

      // allow no contributors to be passed in
      errors['contributions'] =
        obj.contributions?.length > 0
          ? getInvalidPeopleError(
              intl.formatMessage,
              obj.contributions,
              validDomains
            )
          : null;

      return errors;
    },
    [props.showContributorFields, validDomains, intl]
  );

  const onMediaUpload = useCallback(
    (file) => {
      // if video, embed as file, otherwise, embed as image
      // we set froala-related classes in the description
      // TODO: populate with any necessary data-media="[object Object]"
      const newDescription = isVideo(file)
        ? '<p><span class="fr-video fr-dvb fr-draggable fr-active" contenteditable="false" draggable="true"><video className="fr-draggable" controls="" src="' +
          file.link +
          '" style="width: 600px;">Your browser does not support HTML5 video.</video></span></p>'
        : '<p><img src="' +
          file.link +
          '" class="fr-fic fr-dib"></p>' +
          (activityDescription ? activityDescription : '');
      // @ts-expect-error
      setActivityDescription(newDescription);
    },
    [activityDescription]
  );

  const renderModalHeader = useCallback(
    (modalTitle) => {
      return activityType ? (
        <>
          <Button
            className="btn-sm me-2 btn-rounded-circle bg-primary-soft text-primary border-0"
            color="light"
            style={{ width: '1.5rem', height: '1.5rem' }}
            onClick={() => setActivityType(null)}
          >
            <span className="fe fe-chevron-left" />
          </Button>{' '}
          {modalTitle}
        </>
      ) : (
        modalTitle
      );
    },
    [activityType]
  );

  // render inputs into three separate steps
  const renderInputs = useCallback(
    (inputs) => {
      return (
        <>
          <Row>
            <Col>
              {!activityType && (
                <Row className="mb-n4">
                  {ACTIVITY_TYPES(intl).map((type, index) => (
                    <Col
                      key={index}
                      className="col-12 col-md-6 py-2 py-md-0 mb-4"
                      // @ts-expect-error
                      onClick={() => setActivityType(type)}
                    >
                      <Card
                        id={'add-activity-type-' + type.id}
                        className="py-4 mb-0 align-items-center lift"
                        role="button"
                        style={{
                          border:
                            // @ts-expect-error
                            activityType === type ? '1px solid #2c7be5' : '',
                        }}
                      >
                        <div className="avatar">
                          <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                            <i className={type.icon}></i>
                          </div>
                        </div>
                        <h3 className="mb-0 mt-3">{type.heading}</h3>
                        <p className="small text-muted mb-0">
                          {type.subheading}
                        </p>
                      </Card>
                      <UncontrolledPopover
                        placement="top"
                        trigger="hover"
                        target={'add-activity-type-' + type.id}
                      >
                        {type.description}
                      </UncontrolledPopover>
                    </Col>
                  ))}
                </Row>
              )}
              {activityType && (
                <Row className={props.showContributorFields ? '' : 'mb-4'}>
                  <Col className="col-auto">
                    {/* @ts-expect-error */}
                    <Avatar person={person} />
                  </Col>
                  <Col className="col-auto ms-n3">
                    <h4 className="mb-1 mt-1">
                      {
                        // @ts-expect-error
                        person.full_name
                      }
                    </h4>
                    {inputs[0]}
                  </Col>
                </Row>
              )}
              {activityType && inputs[1]}
              {activityType && props.showContributorFields && (
                <>
                  {inputs[6]}
                  {inputs[7]}
                  {inputs[8]}
                </>
              )}
              {activityType && showMediaUploader && (
                <MediaUploader
                  onUpload={onMediaUpload}
                  callToAction={intl.formatMessage({
                    id: 'app.activity_page.modal_editor.media_uploader.call_to_action',
                    defaultMessage: 'Add screenshot or video as evidence',
                  })}
                />
              )}
              {activityType && showPeopleEditor && inputs[2]}
              <Row>
                <Col>{activityType && showDatePickers && inputs[3]}</Col>
                <Col>{activityType && showDatePickers && inputs[4]}</Col>
              </Row>
              {activityType && showDescriptionEditor && inputs[5]}
            </Col>
          </Row>
          {activityType &&
            (!showPeopleEditor ||
              !showDatePickers ||
              !showMediaUploader ||
              !showDescriptionEditor) && (
              <Card className="mb-0">
                <CardBody className="py-3">
                  <Row className="justify-content-between">
                    <Col className="col-auto pb-3 pb-md-0 fw-bold mt-1 ms-n2">
                      <FormattedMessage
                        id="app.activity_page.modal_editor.card.add_details"
                        defaultMessage="Add details"
                      />
                    </Col>
                    <Col className="col-auto px-0 me-n2">
                      {!showPeopleEditor && (
                        <>
                          <Button
                            id="contributorsButtonId"
                            color="light"
                            className="btn-sm rounded-pill px-3 me-2"
                            onClick={() => setShowPeopleEditor(true)}
                          >
                            <i className="fe fe-user-plus me-2" />
                            <FormattedMessage
                              id="app.activity_page.modal_editor.card.add_details.contributors.button.text"
                              defaultMessage="Contributors"
                            />
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="contributorsButtonId"
                          >
                            <FormattedMessage
                              id="app.activity_page.modal_editor.card.add_details.contributors.popover"
                              defaultMessage="Add people who contributed to this activity to give them credit."
                            />
                          </UncontrolledPopover>
                        </>
                      )}
                      {!showMediaUploader && (
                        <>
                          <Button
                            id="mediaButtonId"
                            color="light"
                            className="btn-sm rounded-pill px-3 me-2"
                            onClick={() => setShowMediaUploader(true)}
                          >
                            <i className="fe fe-image" />
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="mediaButtonId"
                          >
                            <FormattedMessage
                              id="app.activity_page.modal_editor.card.add_details.media_uploader.popover"
                              defaultMessage="Add screenshot or video"
                            />
                          </UncontrolledPopover>
                        </>
                      )}
                      {!showDatePickers && (
                        <>
                          <Button
                            id="datepickerButtonId"
                            color="light"
                            className="btn-sm rounded-pill px-3 me-2"
                            onClick={() => setShowDatePickers(true)}
                          >
                            <i className="fe fe-calendar" />
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="datepickerButtonId"
                          >
                            <FormattedMessage
                              id="app.activity_page.modal_editor.card.add_details.date.popover"
                              defaultMessage="Add dates"
                            />
                          </UncontrolledPopover>
                        </>
                      )}
                      {!showDescriptionEditor && (
                        <>
                          <Button
                            id="descriptionButtonId"
                            color="light"
                            className="btn-sm rounded-pill px-3 me-2"
                            onClick={() => setShowDescriptionEditor(true)}
                          >
                            <i className="fe fe-edit" />
                          </Button>
                          <UncontrolledPopover
                            placement="top"
                            trigger="hover"
                            target="descriptionButtonId"
                          >
                            <FormattedMessage
                              id="app.activity_page.modal_editor.card.add_details.description.popover"
                              defaultMessage="Add description"
                            />
                          </UncontrolledPopover>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
        </>
      );
    },
    [
      activityType,
      onMediaUpload,
      person,
      props.showContributorFields,
      showDatePickers,
      showDescriptionEditor,
      showMediaUploader,
      showPeopleEditor,
      intl,
    ]
  );

  // render inputs into three separate steps
  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {inputs}
          <Row>{activityType && <Col>{submitButton}</Col>}</Row>
        </>
      );
    },
    [activityType]
  );

  const activity = useMemo(
    () => ({
      visibility: activityVisibility,
      name: activityName,
      type: activityType,
      description: activityDescription,
      contributions: contributions,
      date_started: dateStarted,
      date_completed: dateCompleted,
      contribution_contributor_role: contributionContributorRole,
      contribution_description: contributionDescription,
      contribution_skills: contributionSkills,
    }),
    [
      activityVisibility,
      activityName,
      activityType,
      activityDescription,
      contributions,
      dateStarted,
      dateCompleted,
      contributionContributorRole,
      contributionDescription,
      contributionSkills,
    ]
  );

  const onChange = useCallback(
    (obj) => {
      // update activity name, type, desc, dates, and contributions
      // if any is updated in the model
      // (this is necessary so we avoid, for example, deleting
      // what was typed for the title when media is uploaded
      // which changes the description programmatically)
      if (activityVisibility !== obj.visibility) {
        setActivityVisibility(obj.visibility);
      }
      if (activityName !== obj.name) {
        setActivityName(obj.name);
      }
      if (activityType !== obj.type) {
        setActivityType(obj.type);
      }
      if (activityDescription !== obj.description) {
        setActivityDescription(obj.description);
      }
      if (contributions !== obj.contributions) {
        setContributions(obj.contributions);
      }
      if (dateStarted !== obj.date_started) {
        setDateStarted(obj.date_started);
      }
      if (dateCompleted !== obj.date_completed) {
        setDateCompleted(obj.date_completed);
      }
      if (contributionContributorRole !== obj.contribution_contributor_role) {
        setContributionContributorRole(obj.contribution_contributor_role);
      }
      if (contributionDescription !== obj.contribution_description) {
        setContributionDescription(obj.contribution_description);
      }
      if (contributionSkills !== obj.contribution_skills) {
        setContributionSkills(obj.contribution_skills);
      }
    },
    [
      activityVisibility,
      activityName,
      activityType,
      activityDescription,
      contributions,
      dateStarted,
      dateCompleted,
      contributionContributorRole,
      contributionDescription,
      contributionSkills,
    ]
  );

  const renderVisibilityToggle = useCallback((o, onClick) => {
    return (
      <DropdownToggle
        tag="div"
        className="small text-muted mb-0"
        role="button"
        onClick={onClick}
      >
        {o}
        <span
          className="fe fe-chevron-down ms-1"
          style={{ fontSize: '0.65rem' }}
        ></span>
      </DropdownToggle>
    );
  }, []);

  const renderVisibilityItem = useCallback(
    (o) => {
      return (
        <>
          <span
            className={o.icon + ' me-1'}
            style={{ fontSize: '0.7rem' }}
          ></span>
          {o.id === ACTIVITY_VISIBILITY_EVERYONE(formatMessage).id && (
            <>
              <FormattedMessage
                id="app.activity.activity_card.visibility.public_to_organization"
                defaultMessage="Public to {organizationName}"
                // @ts-expect-error
                values={{ organizationName: props.currentOrganization.name }}
              />
            </>
          )}
          {o.id !== ACTIVITY_VISIBILITY_EVERYONE(formatMessage).id && (
            <>{o.name}</>
          )}
        </>
      );
    },
    // @ts-expect-error
    [props.currentOrganization.name, formatMessage]
  );

  const skillTagsRef = useRef();

  return (
    <ModalEditorButton
      block
      isOpen={props.isOpen}
      toggle={props.toggle}
      onOpened={props.onOpened}
      onClosed={onClosed}
      onChange={onChange}
      buttonComponentGenerator={getButtonComponent}
      inline={props.inline}
      buttonClassName={props.buttonClassName}
      url="activities"
      title={title}
      modalTitle={modalTitle}
      submitText={submitText}
      anchorTrigger={anchorTrigger}
      object={activity}
      callback={onSubmitCallback}
      onValidate={onValidate}
      hideSubmitButton={inviteEmailFieldToggle}
      transformObjectBeforeSubmit={transformObjectBeforeSubmit}
      renderModalHeader={renderModalHeader}
      renderInputs={renderInputs}
      renderForm={renderForm}
      setHasUnsavedChanges={propsSetHasUnsavedChanges}
      inputs={[
        {
          ...ACTIVITY_EDITOR_FIELDS(intl)['visibility'],
          renderToggle: renderVisibilityToggle,
          renderItem: renderVisibilityItem,
        },
        {
          ...ACTIVITY_EDITOR_FIELDS(intl)['name'],
          className: 'form-control form-control-flush pt-0',
          style: { fontSize: '1.35rem', overflowY: 'clip' },
          label: undefined,
          autoFocus: !props.showContributorFields,
        },
        {
          ...ACTIVITY_EDITOR_FIELDS(intl)['contributions'],
          onInviteEmailFieldToggle: setInviteEmailFieldToggle,
          // @ts-expect-error
          autoFocus: props.contributions?.length > 0 ? false : true,
        },
        {
          ...ACTIVITY_EDITOR_FIELDS(intl)['date_started'],
          autoFocus: true,
          defaultValue: props.defaultActivityDateString
            ? new Date(props.defaultActivityDateString)
            : ACTIVITY_EDITOR_FIELDS(intl)['date_started'].defaultValue,
        },
        {
          ...ACTIVITY_EDITOR_FIELDS(intl)['date_completed'],
          defaultValue: props.defaultActivityDateString
            ? new Date(props.defaultActivityDateString)
            : ACTIVITY_EDITOR_FIELDS(intl)['date_completed'].defaultValue,
        },
        {
          ...ACTIVITY_EDITOR_FIELDS(intl)['description'],
          className: 'mb-n3',
          helperText: intl.formatMessage({
            id: 'app.activity_page.modal_editor.custom.fields.description.helper_text',
            defaultMessage:
              'A default template is provided below. You can edit this later.',
          }),
          placeholder: intl.formatMessage({
            id: 'app.activity_page.modal_editor.custom.fields.description.placeholder',
            defaultMessage:
              'Enter description and @mention any people who contributed.',
          }),
          defaultValue: ACTIVITY_DEFAULT_DESCRIPTION,
          config: {
            charCounterMax: consts.ACTIVITY_DESCRIPTION_MAX_LENGTH,
          },
          onMention: handleMention,
        },
        ...(props.showContributorFields
          ? [
              {
                autoFocus: true,
                required: true,
                name: 'contribution_contributor_role',
                label: intl.formatMessage({
                  id: 'app.activity_page.modal_editor.custom.fields.contribution_contributor_role.label',
                  defaultMessage: 'What was your role?',
                }),
                placeholder: intl.formatMessage({
                  id: 'app.activity_page.modal_editor.custom.fields.contribution_contributor_role.placeholder',
                  defaultMessage: 'Your role',
                }),
                helperText: intl.formatMessage({
                  id: 'app.activity_page.modal_editor.custom.fields.contribution_contributor_role.helper_text',
                  defaultMessage:
                    'For example, "Lead programmer" or "Project advisor"',
                }),
              },
              {
                ...CONTRIBUTION_EDITOR_ATTRIBUTES,
                className: 'mb-n4',
                required: true,
                name: 'contribution_description',
                label: intl.formatMessage({
                  id: 'app.activity_page.modal_editor.custom.fields.contribution_description.label',
                  defaultMessage: 'Describe your contribution.',
                }),
                // NOTE: placeholder can't be changed after Froala instantiated
                placeholder: intl.formatMessage({
                  id: 'app.activity_page.modal_editor.custom.fields.contribution_description.placeholder',
                  defaultMessage: 'What work was done?',
                }),
                onMention: handleMention,
              },
              {
                required: true,
                name: 'contribution_skills',
                renderCreateOption: (x) => ({
                  name: x,
                  object: {
                    name: (
                      <span>
                        <span className="fw-normal">
                          <FormattedMessage
                            id="app.views.activities.modal_activity_editor_button.create_option"
                            defaultMessage='<span>Create "</span>{option}<span>"</span>'
                            values={{
                              option: x,
                              span: (chunks) => (
                                <span className="fw-normal">{chunks}</span>
                              ),
                            }}
                          />
                        </span>
                      </span>
                    ),
                  },
                }),
                type: INPUT_TYPES.TAGS_INPUT,
                label: intl.formatMessage({
                  id: 'app.activity_page.modal_editor.custom.fields.contribution_skills.label',
                  defaultMessage: 'What skills or behaviors did you use?',
                }),
                innerRef: skillTagsRef,
                elasticsearchOptions: {
                  url: 'get-skills-by-name',
                  getQuery: getQueryWithType,
                },
              },
            ]
          : []),
      ]}
    />
  );
};

ModalActivityEditorButton.defaultProps = {
  contributions: [],
};

type Props = InferProps<typeof ModalActivityEditorButton_propTypes>;

const ModalActivityEditorButton_propTypes = {
  me: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  activityType: PropTypes.string,
  activityName: PropTypes.string,
  contributions: PropTypes.arrayOf(PropTypes.object),
  anchorTrigger: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  inline: PropTypes.bool,
  buttonClassName: PropTypes.string,
  currentOrganization: PropTypes.object.isRequired,
  hideButton: PropTypes.bool,
  callback: PropTypes.func,
  showContributorFields: PropTypes.bool,
  setHasUnsavedChanges: PropTypes.func,
  defaultActivityDateString: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { me, currentOrganization } = state;

  return {
    me,
    currentOrganization,
  };
};

export default connect(mapStateToProps)(React.memo(ModalActivityEditorButton));
