import * as consts from '../../consts/consts';

import { Alert, Col, Row } from 'reactstrap';
import React, { useEffect, useMemo, useState } from 'react';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import { showPerfCampaignAlert } from '../../utils/models/Campaign';
import { useAuth0 } from '@auth0/auth0-react';

const DashboardAlerts: React.FC<Props> = (props: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  // TODO: refactor to use "useCurrentActiveCampaign" hook
  const [currentActiveCampaign, setCurrentActiveCampaign] = useState(undefined);
  const [phasePermissions, setPhasePermissions] = useState(undefined);

  const { user } = useAuth0();

  const userSub = user?.sub;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch basic info of currrent campaign
    if (
      // @ts-expect-error
      props.currentOrganization?.id &&
      typeof currentActiveCampaign === 'undefined'
    ) {
      ConfirmAPI.getUrlWithCache(
        '/campaigns/current-active',
        'campaigns/current-active',
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        // @ts-expect-error
        { organization: props.currentOrganization?.id },
        (data) => {
          if (isMounted) {
            setCurrentActiveCampaign(data?.campaign);
            setPhasePermissions(data?.phase_permissions);
          }
        },
        (message) => {
          // error to console but don't show to user
          console.error('Dashboard alert error: ' + JSON.stringify(message));
        }
      );
    }
  }, [
    currentActiveCampaign,
    isMounted,
    // @ts-expect-error
    props.currentOrganization?.id,
    props.currentProxyPerson,
    userSub,
  ]);

  const showCampaignAlert = useMemo(
    () => showPerfCampaignAlert(currentActiveCampaign, phasePermissions),
    [currentActiveCampaign, phasePermissions]
  );

  return (
    <>
      {showCampaignAlert && (
        <Alert className={'alert-light py-4 ' + props.className}>
          <Row>
            <Col className="col-auto">
              <i className={consts.ICONS.PERFORMANCE} />
            </Col>
            <Col>
              <Row>
                <Col className="ms-n3 mb-2 mb-md-0Link">
                  <h4 className="mt-1 mb-1">
                    <FormattedMessage
                      id="app.views.dashboard.dashboard_alerts.current_campaing_under_way.text"
                      defaultMessage="{organizationName}'s {currentActiveCampaign} cycle is underway!"
                      values={{
                        // @ts-expect-error
                        organizationName: props.currentOrganization?.name,
                        // @ts-expect-error
                        currentActiveCampaign: currentActiveCampaign.name,
                      }}
                    />
                  </h4>
                  <p className="mb-0">
                    <FormattedMessage
                      id="app.views.dashboard.dashboard_alerts.help.text"
                      defaultMessage="
                    You can edit your responses any time until the cycle closes.
                  "
                    />
                  </p>
                </Col>
                <Col className="col-12 col-md-auto ms-n3">
                  <Link
                    style={{ minWidth: '10rem' }}
                    // @ts-expect-error
                    tag="Button"
                    className="btn btn-primary"
                    to={consts.PERFORMANCE_COMPLETE_REVIEW().path}
                  >
                    <FormattedMessage
                      id="app.views.dashboard.dashboard_alerts.link.go_to_cycle"
                      defaultMessage="
                    Go to cycle
                  "
                    />
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Alert>
      )}
    </>
  );
};

type Props = InferProps<typeof DashboardAlerts_propTypes>;

const DashboardAlerts_propTypes = {
  className: PropTypes.string,
  features: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  currentOrganization: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { features, currentOrganization, currentProxyPerson } = state;

  return {
    features,
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(DashboardAlerts));
