import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { CampaignWithConfigs } from '../../../types';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import SafeModal from '../../../components/SafeModal';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FilterablePeopleTable from '../../Widgets/People/FilterablePeopleTable';
import {
  FORMAT_AVATAR_ONLY,
  FORMAT_AVATAR_WITH_TITLE,
} from '../../Widgets/People/Filters/common';
import EmptyState from '../../Widgets/EmptyState';
import { toastErrorOrCallback } from '../../../utils/util/util';
import { ADMINISTRATION_PEOPLE } from '../../../consts/consts';
import { useConfirmApiWithDefault } from '../../../utils/api/ApiHooks';
import Loading from '../../Widgets/Loading';

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  campaign: CampaignWithConfigs;
  organizationId?: number;
  onSuccess: (response: AddDatasetPeopleResponse) => void;
  columns: object[];
};

interface GetMissingDatasetPeopleResponse {
  people: any[];
  has_hris_integration: boolean;
}

export type AddDatasetPeopleApiStatus = 'modified' | 'unchanged';

export interface AddDatasetPeopleResponse {
  status: AddDatasetPeopleApiStatus;
  dataset_id: number;
}

const CampaignParticipantsAddPeopleModal: FC<Props> = ({
  isOpen,
  onToggle,
  campaign,
  organizationId,
  onSuccess,
}) => {
  const { formatMessage } = useIntl();

  const [selectedRowIdsSet, setSelectedRowIdsSet] = useState(new Set<string>());

  const { data, status } =
    useConfirmApiWithDefault<GetMissingDatasetPeopleResponse>({
      method: 'GET',
      url: `/datasets/${campaign.dataset_id}/get-missing-people`,
      defaultValue: {
        people: [],
        has_hris_integration: false,
      },
    });

  const people = data.people;
  const hasHrisIntegration = data.has_hris_integration;

  const submitPeople = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'POST',
      `/datasets/${campaign.dataset_id}/add-people`,
      {
        organization: organizationId,
        person_ids: Array.from(selectedRowIdsSet),
      },
      toastErrorOrCallback((response: AddDatasetPeopleResponse) => {
        onToggle();
        onSuccess(response);
      }),
      null
    );
  }, [
    selectedRowIdsSet,
    campaign.dataset_id,
    organizationId,
    onSuccess,
    onToggle,
  ]);

  const toggleSelectedRowField = useCallback(
    (rowId) => {
      if (selectedRowIdsSet.has(rowId)) {
        setSelectedRowIdsSet(
          new Set(Array.from(selectedRowIdsSet).filter((id) => id !== rowId))
        );
      } else {
        setSelectedRowIdsSet(
          new Set([...Array.from(selectedRowIdsSet), rowId])
        );
      }
    },
    [selectedRowIdsSet]
  );

  const rows = useMemo(() => {
    return people.map((r) => ({ ...r['_source'], person: r['_source'] }));
  }, [people]);

  const columns = [
    {
      name: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.participant"
          defaultMessage="Participant"
        />
      ),
      field: 'person',
      isPerson: true,
      format: FORMAT_AVATAR_WITH_TITLE,
      getFilterDisplayValue: (c) => c.full_name,
    },
    {
      field: 'location',
      name: formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.table.location',
        defaultMessage: 'Location',
      }),
      popoverContent: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.location_popover"
          defaultMessage="Location"
        />
      ),
    },
    {
      field: 'function',
      name: formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.table.function',
        defaultMessage: 'Function',
      }),
      popoverContent: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.function_popover"
          defaultMessage="Function"
        />
      ),
    },
    {
      name: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.manager"
          defaultMessage="Manager"
        />
      ),
      field: 'manager',
      isPerson: true,
      format: FORMAT_AVATAR_ONLY,
      getFilterDisplayValue: (c) => c.full_name,
    },
    {
      field: 'cost_center',
      name: formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.table.cost_center',
        defaultMessage: 'Cost center',
      }),
      popoverContent: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.cost_center_popover"
          defaultMessage="Cost center"
        />
      ),
    },
    {
      field: 'department',
      name: formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.table.department',
        defaultMessage: 'Department',
      }),
      popoverContent: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.department_popover"
          defaultMessage="Department"
        />
      ),
    },
    {
      field: 'business_unit',
      name: formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.table.business_unit',
        defaultMessage: 'Business unit',
      }),
      popoverContent: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.business_unit_popover"
          defaultMessage="Business unit"
        />
      ),
    },
    {
      field: 'level_id',
      name: formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.table.level',
        defaultMessage: 'Level',
      }),
      popoverContent: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.level_popover"
          defaultMessage="Level ID"
        />
      ),
    },
    {
      field: 'latest_hire_date',
      name: formatMessage({
        id: 'app.views.administration.campaign_participants_add_people_modal.table.hired',
        defaultMessage: 'Hired',
      }),
      popoverContent: (
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.table.hired_popover"
          defaultMessage="Latest hire date"
        />
      ),
    },
  ];

  return (
    <SafeModal isOpen={isOpen} toggle={onToggle} fullscreen="lg" size="xl">
      <ModalHeader toggle={onToggle}>
        <FormattedMessage
          id="app.views.administration.campaign_participants_add_people_modal.modal.title"
          defaultMessage="Add participants"
        />
      </ModalHeader>
      <ModalBody>
        {status === 'LOADING' && <Loading />}
        {status === 'SUCCESS' && rows.length > 0 && (
          <FilterablePeopleTable
            rows={rows}
            columns={columns}
            hideFilters={false}
            hideExportButton={true}
            isRowSelectable={() => true}
            toggleSelectedRowField={toggleSelectedRowField}
            selectedRowToggleFieldsSet={selectedRowIdsSet}
            setSelectedRowToggleFieldsSet={setSelectedRowIdsSet}
            getToggleField={(row) => row.id}
            initialTableSize={rows.length + 1}
          />
        )}
        {status === 'SUCCESS' && rows.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.administration.campaign_participants_add_people_modal.empty_state.title',
              defaultMessage:
                'All people in your organization are already included',
            })}
            subtitle={
              hasHrisIntegration
                ? formatMessage(
                    {
                      id: 'app.views.administration.campaign_participants_add_people_modal.empty_state.subtitle_hris',
                      defaultMessage:
                        'Everyone in your organization is already participating in {cycle_name}.<br></br><br></br>If you do not see someone that is in your HRIS, wait for the HRIS integration to sync with Confirm (this typically happens every 24 hours but may vary by organization), then come back here and try again.',
                    },
                    {
                      cycle_name: campaign.name,
                      br: () => <br />,
                    }
                  )
                : formatMessage(
                    {
                      id: 'app.views.administration.campaign_participants_add_people_modal.empty_state.subtitle',
                      defaultMessage:
                        'Everyone in your organization is already participating in {cycle_name}.<br></br><br></br>If you would like to add additional people to your organization, you can do so in <link>People administration</link>.',
                    },
                    {
                      cycle_name: campaign.name,
                      br: () => <br />,
                      link: (chunks) => (
                        <a href={ADMINISTRATION_PEOPLE(formatMessage).path}>
                          {chunks}
                        </a>
                      ),
                    }
                  )
            }
          ></EmptyState>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={rows.length > 0 && selectedRowIdsSet.size === 0}
          onClick={() => (rows.length > 0 ? submitPeople() : onToggle())}
        >
          {rows.length > 0 ? (
            <FormattedMessage
              id="app.views.administration.campaign_participants_add_people_modal.submit.title"
              defaultMessage="Add {count, plural, one {# person} other {# people}}"
              values={{
                count: selectedRowIdsSet.size,
              }}
            />
          ) : (
            <FormattedMessage
              id="app.views.administration.campaign_participants_add_people_modal.close.title"
              defaultMessage="Close"
            />
          )}
        </Button>
      </ModalFooter>
    </SafeModal>
  );
};

export default CampaignParticipantsAddPeopleModal;
