import { FormattedRelativeTime, useIntl } from 'react-intl';
import React, { FC } from 'react';

interface Props {
  datetime?: string;
  unit?: string;
}

// @ts-expect-error
const RelativeTime: FC<Props> = (props) => {
  const { locale, formatMessage } = useIntl();
  if (!props.datetime) {
    return formatMessage({
      id: 'app.views.widgets.relative_time.never',
      defaultMessage: 'never',
    });
  }

  const date = new Date(props.datetime.replace(' ', 'T'));
  const now = new Date();

  // get time from now in seconds
  // @ts-expect-error
  let timeSinceDate = (date - now) / 1000;

  if (props.unit === 'day') {
    timeSinceDate /= 86400; // seconds in a day
  }

  // within the past week, show relative time
  if (timeSinceDate > -7 && timeSinceDate < 7) {
    return (
      <FormattedRelativeTime
        numeric="auto"
        // @ts-expect-error
        unit={props.unit}
        updateIntervalInSeconds={props.unit === 'day' ? undefined : 60}
        value={Math.round(timeSinceDate)}
      />
    );
  }

  // if this year, show MONTH_NAME DD
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleString(locale, { month: 'long', day: 'numeric' });
  }

  // not this year, so show MONTH_NAME DD, YYYY
  return date.toLocaleString(locale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export default React.memo(RelativeTime);
