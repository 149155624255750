import React, { ReactNode, useRef } from 'react';

import { UncontrolledPopover } from 'reactstrap';

interface PersonDataProps {
  className: string;
  data: string | ReactNode;
  header: string;
  helperText?: string;
}

const PersonData: React.FC<PersonDataProps> = ({
  className,
  data,
  header,
  helperText,
}) => {
  const helperTextRef = useRef(null);

  return (
    <>
      <h6
        ref={helperTextRef}
        className={`text-uppercase text-secondary ${className}`}
      >
        {header}
        {helperText && (
          <i className="fs-6 ms-1 fe fe-help-circle text-primary" />
        )}
      </h6>
      <h3>{data}</h3>
      {helperText && (
        <UncontrolledPopover
          placement="left"
          trigger="hover"
          target={helperTextRef}
        >
          {helperText}
        </UncontrolledPopover>
      )}
    </>
  );
};

export default PersonData;
