import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { ReactNode } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PowerLawGraph from '../Charts/PowerLawGraph';

interface PowerLawCardProps {
  color?: string;
  fieldSingular?: string;
  fieldPlural?: string;
  data: {
    content?: ReactNode;
    field: string;
    [key: string]: unknown;
  };
  className?: string;
  emptyStateText?: string;
  style?: React.CSSProperties;
  title: string | ReactNode;
  role?: string;
}

const PowerLawCard: React.FC<PowerLawCardProps> = ({
  className,
  role,
  style,
  title,
  data,
  color,
  fieldSingular,
  fieldPlural,
  emptyStateText,
}) => {
  return (
    <Card className={className} role={role} style={style}>
      <CardHeader>
        <CardHeaderTitle>{title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {data ? (
          <Row>
            <Col sm="12" md="12" lg="12" xxl="8" className="mb-sm-4">
              <PowerLawGraph
                color={color}
                fieldSingular={fieldSingular}
                fieldPlural={fieldPlural}
                {...data}
              />
            </Col>
            <Col sm="12" md="12" lg="12" xxl="4" className="mb-sm-4">
              {data?.content ?? <EmptyState title={emptyStateText} />}
            </Col>
          </Row>
        ) : (
          <EmptyState title={emptyStateText} />
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(PowerLawCard);
