import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { FC } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';

const MultichartCard: FC<Props> = ({ emptyStateText = '', ...props }) => {
  return (
    // @ts-expect-error
    <Card className={props.className}>
      {props.title && (
        <CardHeader>
          {/* @ts-expect-error */}
          <CardHeaderTitle>{props.title}</CardHeaderTitle>
        </CardHeader>
      )}
      <CardBody className="position-relative">
        {/* @ts-expect-error */}
        {props.data ? <>{props.data}</> : <EmptyState title={emptyStateText} />}
      </CardBody>
    </Card>
  );
};

const MultichartCard_propTypes = {
  className: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  emptyStateText: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

type Props = PropTypes.InferProps<typeof MultichartCard_propTypes>;

export default React.memo(MultichartCard);
