import React, { FC, ReactNode } from 'react';

import { escapeRegExp } from 'lodash';

interface Props {
  value: string;
  textToHighlight?: string;
}

const getHighlightedText = (
  value: string,
  textToHighlight: string
): ReactNode => {
  const parts = value.split(
    new RegExp(`(${escapeRegExp(textToHighlight)})`, 'gi')
  );
  return (
    <>
      {parts.map((part, i) => (
        <span
          className={
            part.toLowerCase() === textToHighlight.toLowerCase()
              ? 'text-muted'
              : ''
          }
          key={i}
        >
          {part}
        </span>
      ))}
    </>
  );
};
const ObjectiveText: FC<Props> = ({ value, textToHighlight }) => {
  if (textToHighlight) {
    return <>{getHighlightedText(value, textToHighlight)}</>;
  }

  return <>{value}</>;
};

export default ObjectiveText;
