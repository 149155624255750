import { Button, UncontrolledPopover } from 'reactstrap';
import React, { FC, useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

/**
 * CalibrationFlags
 *
 * gets a list of calibration flag indices ()
 * @param {*} flags: array of strings of calibration flags
 * @param {*} missing: array of strings of unmatched calibration flags
 * @returns
 */
const CalibrationFlags: FC<Props> = (props) => {
  const ref = useRef(null);
  const [showAllRules, setShowAllRules] = useState(false);

  const propsFlags = props?.flags || [];
  const propsMissing = props?.missing || [];
  // @ts-expect-error
  const propsOnCalibrate = props?.onCalibrate;
  const needsAttention = propsFlags?.length;
  const isCalibrated = !!props?.isCalibrated;
  // @ts-expect-error
  const propsCanEditFinalRating = !!props?.canEditFinalRating;

  if (!props.canAutoCalibrate) {
    return <></>;
  }

  if (needsAttention) {
    return (
      <>
        <span ref={ref} className="badge bg-warning text-white rounded-pill">
          <i
            className="fe fe-alert-circle"
            style={{ position: 'relative', top: '0.5px' }}
          />{' '}
          {props.showText && (
            <FormattedMessage
              id="app.views.widgets.calibration_flags.needs_attention"
              defaultMessage="

      Needs attention
    "
            />
          )}
        </span>
        <UncontrolledPopover placement="left" trigger={'hover'} target={ref}>
          <div>
            {propsFlags.map((flag, i) => (
              <p className="popover-calibration-flag" key={`cal-flags-${i}`}>
                {flag}
              </p>
            ))}
            {propsCanEditFinalRating && (
              <Button
                className="px-3 py-0"
                color="primary"
                onClick={propsOnCalibrate}
              >
                <FormattedMessage
                  id="app.views.widgets.calibration_flags.calibrate_now"
                  defaultMessage="Calibrate now"
                />
              </Button>
            )}
            {propsMissing.length > 0 && (
              <>
                <div
                  className="text-primary mt-3"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault(); // it was triggering the popover to close
                    setShowAllRules(!showAllRules);
                  }}
                >
                  {showAllRules && (
                    <>
                      <FormattedMessage
                        id="app.views.widgets.calibration_flags.hide_other"
                        defaultMessage="Hide other calibration triggers"
                      />
                    </>
                  )}
                  {!showAllRules && (
                    <>
                      <FormattedMessage
                        id="app.views.widgets.calibration_flags.view_other"
                        defaultMessage="View other calibration triggers"
                      />
                    </>
                  )}
                </div>
                {showAllRules && (
                  <ul
                    className="mb-0 calibration-checklist"
                    style={{ paddingLeft: '0.5rem' }}
                  >
                    {propsMissing.map((flag, i) => (
                      <li key={`cal-flags-missing-${i}`} className="text-muted">
                        {flag}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
        </UncontrolledPopover>
      </>
    );
  }

  if (isCalibrated) {
    return (
      <span className="badge bg-success rounded-pill">
        <i
          className="fe fe-check"
          style={{ position: 'relative', top: '0.5px' }}
        />{' '}
        {props.showText && (
          <FormattedMessage
            id="app.views.widgets.calibration_flags.calibrated"
            defaultMessage="Calibrated"
          />
        )}
      </span>
    );
  }

  const noFlagsRaisedText = (
    <FormattedMessage
      id="app.views.widgets.calibration_flags.no_flags_raised"
      defaultMessage="No flags raised"
    />
  );

  return (
    <>
      <span ref={ref} className="badge bg-light rounded-pill">
        <i
          className="fe fe-info"
          style={{ position: 'relative', top: '0.5px' }}
        />{' '}
        {props.showText && noFlagsRaisedText}
      </span>
      {!props.showText && (
        <UncontrolledPopover placement="left" trigger={'hover'} target={ref}>
          {noFlagsRaisedText}
        </UncontrolledPopover>
      )}
    </>
  );
};

const CalibrationFlags_propTypes = {
  canAutoCalibrate: PropTypes.bool,
  flags: PropTypes.arrayOf(PropTypes.string),
  isCalibrated: PropTypes.bool,
  missing: PropTypes.arrayOf(PropTypes.string),
  showText: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof CalibrationFlags_propTypes>;

export default React.memo(CalibrationFlags);
