import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { Organization, ReduxState } from 'types';
import Page from 'views/Layout/Pages/Page';
import * as consts from '../../consts/consts';
import { FormattedMessage, useIntl, type IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { useConfirmApi } from 'utils/api/ApiHooks';
import Loading from 'views/Widgets/Loading';
import FilterablePeopleTable from 'views/Widgets/People/FilterablePeopleTable';
import cronstrue from 'cronstrue';
import {
  Nudge,
  NudgeMethod,
  nudgeRecipientsType,
  NudgeRecipientsType,
  NudgeScheduleType,
  NudgeStatus,
} from './types';
import { configForLocale } from 'locale/messages';
import ModalNudgeEditorButton from './ModalNudgeEditorButton';
import ConfirmationDialogModal from 'views/Widgets/Modals/ConfirmationDialogModal';
import ConfirmAPI from 'utils/api/ConfirmAPI';
import { toast } from 'react-toastify';
import EmptyState from 'views/Widgets/EmptyState';

interface NudgeListResult {
  next?: unknown;
  previous?: unknown;
  results: Nudge[];
}

interface Props {
  currentOrganization: Organization;
}

const formatBoolean = (value?: boolean): ReactNode => {
  return value ? <i className="h1 fe fe-check text-success" /> : '-';
};

const cronToReadableString = (
  cronExpression: string,
  language: string
): string => {
  const localeConfig = configForLocale(language);

  const humanReadableString = cronstrue.toString(cronExpression, {
    locale: localeConfig.cronstrueLocale,
  });
  return humanReadableString;
};

const formatRecipients = (
  recipientsType: NudgeRecipientsType,
  orgName: string,
  formatMessage: IntlShape['formatMessage']
): string => {
  const types = nudgeRecipientsType(
    formatMessage,
    true,
    true,
    true,
    true,
    orgName
  );
  const type = types.find((x) => x.id === recipientsType);
  if (!type)
    throw new Error(
      formatMessage({
        id: 'app.views.nudges.nudgeslist.recipients.unknown_recipient_type',
        defaultMessage: 'Unknown recipient type',
      })
    );
  return type.name;
};

const NudgesList: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();

  const [showCreateNudgeDialog, setShowCreateNudgeDialog] = useState(false);
  const toggleCreateNudgeDialog = useCallback(() => {
    setShowCreateNudgeDialog(!showCreateNudgeDialog);
  }, [showCreateNudgeDialog]);

  const [editingNudge, setEditingNudge] = useState<Nudge | null>(null);
  const isEditing = editingNudge !== null;

  const [deletingNudge, setDeletingNudge] = useState<Nudge | null>(null);
  const isDeletingNudge = deletingNudge !== null;
  const [deleteValidationErrors, setDeleteValidationErrors] = useState<
    string | null
  >(null);
  const [version, setVersion] = useState(0);

  const { data, status, error } = useConfirmApi<NudgeListResult>({
    method: 'GET',
    url: '/nudges',
    params: { version },
  });

  const createNudge = useCallback(
    () => setShowCreateNudgeDialog(true),
    [setShowCreateNudgeDialog]
  );

  const actionButtons = (
    <Button color="primary" onClick={createNudge}>
      <FormattedMessage
        id="app.views.nudges.nudgeslist.create_nudge"
        defaultMessage="Create a nudge"
      />
    </Button>
  );

  const setEditingNudgeCallback = useCallback(
    (nudge: Nudge | null) => {
      return () => setEditingNudge(nudge);
    },
    [setEditingNudge]
  );

  const setDeletingNudgeCallback = useCallback(
    (nudge: Nudge | null) => {
      return () => setDeletingNudge(nudge);
    },
    [setDeletingNudge]
  );

  const setDeleteNudgeCallback = useCallback(
    (nudge: Nudge) => {
      return () => setDeletingNudge(nudge);
    },
    [setDeletingNudge]
  );

  const onSubmitCallback = useCallback(() => {
    setVersion((v) => v + 1);
  }, [setVersion]);

  const deleteNudge = useCallback(() => {
    if (deletingNudge) {
      ConfirmAPI.sendRequestToConfirm(
        'DELETE',
        '/nudges/' + deletingNudge.id,
        {},
        (response, error, hardErrorMessage = null) => {
          if (error) {
            // failure; keep modal open
            if (hardErrorMessage) {
              // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
              // leave this message blank as those errors will get surfaced below
              setDeleteValidationErrors(hardErrorMessage);
            } else {
              setDeleteValidationErrors(error);
            }
          } else {
            setDeletingNudge(null);
            setVersion((v) => v + 1);
            // go to dashboard and show toast
            toast.success(
              formatMessage({
                id: 'app.views.nudges.nudgeslist.toast.text',
                defaultMessage: 'Nudge deleted!',
              })
            );
          }
        },
        null
      );
    }
    setDeletingNudge(null);
  }, [deletingNudge, formatMessage]);

  const columns = useMemo(
    () => [
      {
        name: formatMessage({
          id: 'app.views.nudges.nudgeslist.subject',
          defaultMessage: 'Nudge',
        }),
        field: 'subject',
      },
      {
        name: formatMessage({
          id: 'app.views.nudges.nudgeslist.frequency',
          defaultMessage: 'Frequency',
        }),
        field: 'frequency',
      },
      {
        name: formatMessage({
          id: 'app.views.nudges.nudgeslist.recipients',
          defaultMessage: 'Recipients',
        }),
        field: 'recipients',
      },
      {
        name: (
          <>
            <span className={consts.ICONS.EMAIL + ' me-2'} />
            <FormattedMessage
              id="app.views.nudges.nudgeslist.email"
              defaultMessage="Email"
            ></FormattedMessage>
          </>
        ),
        field: 'email',
        sortable: false,
      },
      ...(props.currentOrganization?.instant_messaging ===
      consts.NOTIFICATION_MEDIUM.SLACK
        ? [
            {
              name: (
                <>
                  <span className={consts.ICONS.SLACK + ' me-2'} />
                  <FormattedMessage
                    id="app.views.nudges.nudgeslist.slack"
                    defaultMessage="Slack"
                  ></FormattedMessage>
                </>
              ),
              field: 'slack',
              sortable: false,
            },
          ]
        : []),
      ...(props.currentOrganization?.instant_messaging ===
      consts.NOTIFICATION_MEDIUM.MSTEAMS
        ? [
            {
              name: (
                <>
                  <span className={consts.ICONS.MSTEAMS + ' me-2'} />
                  <FormattedMessage
                    id="app.views.nudges.nudgeslist.teams"
                    defaultMessage="Teams"
                  ></FormattedMessage>
                </>
              ),
              field: 'teams',
              sortable: false,
            },
          ]
        : []),
      {
        name: formatMessage({
          id: 'app.views.nudges.nudgeslist.last_sent',
          defaultMessage: 'Last sent',
        }),
        field: 'last_sent',
      },
      {
        name: formatMessage({
          id: 'app.views.nudges.nudgeslist.enabled',
          defaultMessage: 'Enabled',
        }),
        field: 'enabled',
        sortable: false,
      },
      {
        name: (
          <>
            <span className={consts.ICONS.ACTION + ' me-2'} />
            <FormattedMessage
              id="app.views.nudges.nudgeslist.actions"
              defaultMessage="Actions"
            ></FormattedMessage>
          </>
        ),
        field: 'actions',
        sortable: false,
      },
    ],
    [formatMessage, props.currentOrganization?.instant_messaging]
  );

  const rows = useMemo(() => {
    return status === 'SUCCESS'
      ? data?.results.map((x, index) => ({
          subject: x.subject,
          frequency:
            x.schedule_type === NudgeScheduleType.Once
              ? formatMessage(
                  {
                    id: 'app.views.nudges.nudgelist.scheduled_once',
                    defaultMessage: 'Once at {time} ({timezone})',
                  },
                  {
                    time: new Date(x.scheduled_time).toLocaleString(),
                    timezone: x.scheduled_timezone,
                  }
                )
              : formatMessage(
                  {
                    id: 'app.views.nudges.nudgelist.scheduled_recurrence',
                    defaultMessage: '{cron} ({timezone})',
                  },
                  {
                    cron: cronToReadableString(x.scheduled_recurrence, locale),
                    timezone: x.scheduled_timezone,
                  }
                ),
          recipients: formatRecipients(
            x.recipients_type,
            props.currentOrganization.name,
            formatMessage
          ),
          email: formatBoolean(x.methods.includes(NudgeMethod.Email)),
          slack: formatBoolean(x.methods.includes(NudgeMethod.Slack)),
          teams: formatBoolean(x.methods.includes(NudgeMethod.MsTeams)),
          last_sent: x.last_sent
            ? new Date(x.last_sent).toLocaleString()
            : formatMessage({
                id: 'app.views.nudges.nudgelist.last_sent.never',
                defaultMessage: 'Never',
              }),
          enabled: formatBoolean(x.status === NudgeStatus.Active),
          actions: (
            <UncontrolledDropdown key={index}>
              <DropdownToggle
                className="btn btn-sm btn-rounded-circle btn-white"
                role="button"
                style={{
                  marginLeft: '1.2rem',
                }}
              >
                <i
                  className="fe fe-more-horizontal"
                  style={{ position: 'relative', top: '2px' }}
                />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={setEditingNudgeCallback(x)}>
                  <FormattedMessage
                    id="app.views.nudges.nudges_list.edit"
                    defaultMessage="Edit"
                  />
                </DropdownItem>
                <DropdownItem onClick={setDeleteNudgeCallback(x)}>
                  <FormattedMessage
                    id="app.views.nudges.nudges_list.delete"
                    defaultMessage="Delete"
                  />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        })) ?? []
      : [];
  }, [
    status,
    data,
    formatMessage,
    locale,
    setEditingNudgeCallback,
    setDeleteNudgeCallback,
    props.currentOrganization?.name,
  ]);

  const setShowCreateNudgeDialogCallback = useCallback(() => {
    return () => setShowCreateNudgeDialog(false);
  }, []);

  return (
    <>
      <ModalNudgeEditorButton
        isOpen={showCreateNudgeDialog}
        toggle={toggleCreateNudgeDialog}
        onClosed={setShowCreateNudgeDialogCallback}
        hideButton={true}
        onSubmitCallback={onSubmitCallback}
      />
      <ModalNudgeEditorButton
        isOpen={isEditing}
        nudge={editingNudge}
        toggle={setEditingNudgeCallback(null)}
        onClosed={setEditingNudgeCallback(null)}
        hideButton={true}
        onSubmitCallback={onSubmitCallback}
      />
      <ConfirmationDialogModal
        isOpen={isDeletingNudge}
        onClosed={setDeletingNudgeCallback(null)}
        toggle={setDeletingNudgeCallback(null)}
        confirmCallback={deleteNudge}
        title={formatMessage({
          id: 'app.views.nudges.nudgelist.delete_nudge.confirm_delete.title',
          defaultMessage: 'Delete nudge',
        })}
        description={formatMessage(
          {
            id: 'app.views.nudges.nudgelist.delete_nudge.confirm_delete.description',
            defaultMessage:
              'Are you sure that you want to delete the nudge "{subject}"?',
          },
          { subject: deletingNudge?.subject }
        )}
        confirmText={formatMessage({
          id: 'app.views.nudges.nudgelist.delete_nudge.confirm_text',
          defaultMessage: 'Delete nudge',
        })}
        validationErrors={deleteValidationErrors}
      />

      <Page title={consts.NUDGES_ADMINISTRATION(formatMessage).name}>
        <Row>
          <Col>
            {status === 'LOADING' && <Loading />}
            {status === 'ERROR' && <div>{JSON.stringify(error)}</div>}
            {status === 'SUCCESS' && rows.length > 0 && (
              <FilterablePeopleTable
                arrayValuesUsedForFormatting={true}
                title={formatMessage({
                  id: 'app.views.nudges.nudgelist.table.title',
                  defaultMessage: 'Nudges',
                })}
                actions={actionButtons}
                rows={rows}
                columns={columns}
                hideFilters={true}
                hideExportButton={true}
              />
            )}
            {status === 'SUCCESS' && rows.length == 0 && (
              <EmptyState
                title={formatMessage({
                  id: 'app.views.nudges.nudgelist.empty_state.title',
                  defaultMessage: 'You have not created any nudges',
                })}
                subtitle={formatMessage({
                  id: 'app.views.nudges.nudgelist.empty_state.subtitle',
                  defaultMessage:
                    'To remind people to take action, you can create nudges to send automated reminders via email or chat.',
                })}
              >
                {actionButtons}
              </EmptyState>
            )}
          </Col>
        </Row>
      </Page>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization } = state;

  return {
    currentOrganization,
  };
};

export default connect(mapStateToProps)(React.memo(NudgesList));
