import { Col, Row } from 'reactstrap';
import React, { FC, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Star } from 'react-feather';
import { TEXT_COLORS } from '../../../consts/consts';
import { useIntl } from 'react-intl';

const getPulseRatingName = (rating, formatMessage) => {
  switch (rating) {
    case 1:
      return formatMessage({
        id: 'app.views.widgets.inputs.star_rating_input.1.awful',
        defaultMessage: 'Awful',
      });
    case 2:
      return formatMessage({
        id: 'app.views.widgets.inputs.star_rating_input.2.not_good',
        defaultMessage: 'Not good',
      });
    case 3:
      return formatMessage({
        id: 'app.views.widgets.inputs.star_rating_input.3.ok',
        defaultMessage: 'OK',
      });
    case 4:
      return formatMessage({
        id: 'app.views.widgets.inputs.star_rating_input.4.good',
        defaultMessage: 'Good',
      });
    case 5:
      return formatMessage({
        id: 'app.views.widgets.inputs.star_rating_input.5.great',
        defaultMessage: 'Great',
      });
    default:
      return '';
  }
};

const RatingStar = (props) => {
  const defaultColor = TEXT_COLORS.muted;
  const selectedColor = TEXT_COLORS.warning;

  // Check if the current star should be highlighted based on hovered value
  const isHoveredOrBelowHovered =
    props.hoveredValue !== undefined && props.rating <= props.hoveredValue;

  // Check if the current star should be highlighted based on selected value
  const isSelectedOrBelowSelected =
    props.selectedValue >= 0 &&
    props.hoveredValue === undefined &&
    props.rating <= props.selectedValue;

  // Decide the fill and color based on current hover and selected state
  const shouldFillAndColor =
    isHoveredOrBelowHovered || isSelectedOrBelowSelected;

  return (
    <div
      className={'d-inline-block' + (props.rating === 5 ? '' : ' pe-2')}
      role={props.disabled ? undefined : 'button'}
      onMouseEnter={
        props.disabled ? undefined : () => props.setHoveredValue(props.rating)
      }
      onMouseLeave={
        props.disabled ? undefined : () => props.setHoveredValue(undefined)
      }
      onClick={
        props.disabled ? undefined : () => props.setSelectedValue(props.rating)
      }
    >
      {!shouldFillAndColor && (
        <Star size={28} color={defaultColor} fill={defaultColor} />
      )}
      {shouldFillAndColor && (
        <Star size={28} color={selectedColor} fill={selectedColor} />
      )}
    </div>
  );
};

const StarRatingInput: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [hoveredValue, setHoveredValue] = useState(undefined);

  const currentRatingName = useMemo(() => {
    if (!props.value && !hoveredValue) {
      return formatMessage({
        id: 'app.views.widgets.inputs.star_rating_input.select_your_rating',
        defaultMessage: 'Select your rating',
      });
    }

    return getPulseRatingName(hoveredValue || props.value, formatMessage);
  }, [hoveredValue, props.value, formatMessage]);

  return (
    // @ts-expect-error
    <div className={props.className}>
      <Row className="align-items-center">
        <Col className="col-auto pe-0">
          {[1, 2, 3, 4, 5].map((rating) => (
            <RatingStar
              key={rating}
              rating={rating}
              selectedValue={props.value}
              setSelectedValue={props.onChange}
              hoveredValue={hoveredValue}
              setHoveredValue={setHoveredValue}
              disabled={props.disabled}
            />
          ))}
        </Col>
        <Col className="text-muted text-nowrap">{currentRatingName}</Col>
      </Row>
    </div>
  );
};

const StarRatingInput_propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof StarRatingInput_propTypes>;

export default StarRatingInput;
