import { Col, Input, Row } from 'reactstrap';
import { type IntlShape, useIntl } from 'react-intl';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { debounce } from 'lodash';

import { OnFilterChange } from '../../../utils/models/Objective';

type Props = {
  disabled: boolean;
  onChange: (delta: OnFilterChange) => void;
};

const ObjectivesOverviewTextFilter: FC<Props> = ({
  onChange,
  disabled,
}: Props) => {
  const { formatMessage }: IntlShape = useIntl();
  const [mustKeepFocus, setMustKeepFocus] = React.useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback<ChangeEventHandler>(
    (e: ChangeEvent<HTMLButtonElement>): void => {
      onChange({ textFilter: (e.target as HTMLInputElement).value as string });
      setMustKeepFocus(true);
    },
    [onChange]
  );

  useEffect(() => {
    if (mustKeepFocus && !disabled) {
      inputRef.current?.focus();
      setMustKeepFocus(false);
    }
  }, [mustKeepFocus, disabled]);

  const handleOnChangeDebounced = useRef(debounce(handleOnChange, 500)).current;
  return (
    <Row>
      <Col>
        <Input
          innerRef={inputRef}
          disabled={disabled}
          name="text_filter"
          type="text"
          placeholder={formatMessage({
            id: 'app.views.objectives.objectives_overview_page.objectives_overview_text_filter.placeholder.search_by_text',
            defaultMessage: 'Keyword search',
          })}
          onChange={handleOnChangeDebounced}
        />
      </Col>
    </Row>
  );
};

export default ObjectivesOverviewTextFilter;
