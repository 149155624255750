import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  computeHistoricalCampaignONAData,
  createDistributionData,
} from './HistoryChartUtils';
import React, { useCallback, useContext } from 'react';

import { Bar } from 'react-chartjs-2';
import { ICONS } from '../../../../consts/consts';
import { getSign } from '../../../../utils/util/util';
import PersonPerformanceContext from '../PersonPerformanceContext';

interface InfluenceDistributionChartProps {
  person?: {
    id: number;
    given_name: string;
  };
}

const InfluenceDistributionChart: React.FC<InfluenceDistributionChartProps> = ({
  person,
}) => {
  const personPerformanceContext = useContext(PersonPerformanceContext);
  const { formatMessage } = useIntl();
  const firstName = person?.given_name ?? '';

  const selectedCampaignData = personPerformanceContext.selectedCampaignData;

  const selectedCampaignPrevious =
    personPerformanceContext.previousCampaignDataWithOna;

  const selectedCampaignONA = computeHistoricalCampaignONAData(
    selectedCampaignData,
    selectedCampaignPrevious
  );

  const influenceHelperText = formatMessage(
    {
      id: 'performance.trajectory.summary.trajectory_summary.influence_helper_text',
      defaultMessage:
        'Influence is the total number of people who reported being advised or energized by {firstName}. Certain roles or groups of people may be more likely to receive a higher number. The absence of Influence should not be interpreted as a signal of poor performance.',
    },
    { firstName: firstName }
  );

  const createDistribution = useCallback(
    (type) => {
      const data = createDistributionData(
        formatMessage,
        type,
        selectedCampaignData,
        selectedCampaignONA
      );

      return data ? (
        <Bar data={data.chartData} options={data.chartOptions} />
      ) : null;
    },
    [selectedCampaignData, selectedCampaignONA, formatMessage]
  );

  return (
    <>
      <Row>
        <Col>{createDistribution('influence')}</Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h6 className="text-muted text-uppercase">
            <FormattedMessage
              id="app.views.person.person_performance.trajectory.trajectory_summary.influence_helper_text.how_to_read"
              defaultMessage="How to read this graph"
            />
          </h6>
          <p>{influenceHelperText}</p> <hr />
          <p>
            {selectedCampaignONA?.influence?.percentile === null ? (
              <>
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.trajectory_summary.influence_helper_text.no_percentile"
                  defaultMessage="{firstName} received {selectedCampaignONAInfluenceCount} {icon} this cycle"
                  values={{
                    firstName: firstName,
                    icon: <i className={ICONS.INFLUENCE} />,
                    selectedCampaignONAInfluenceCount:
                      selectedCampaignONA?.influence?.count ?? '',
                  }}
                />
              </>
            ) : (
              <>
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.trajectory_summary.influence_helper_text.with_percentile"
                  defaultMessage="{firstName}'s {selectedCampaignONAInfluenceCount} {icon} this cycle places them in the <bold>{percentile, selectordinal, one {#st} two {#nd} few {#rd} other {#th} } percentile</bold> of the organization"
                  values={{
                    firstName: firstName,
                    icon: <i className={ICONS.INFLUENCE} />,
                    selectedCampaignONAInfluenceCount:
                      selectedCampaignONA?.influence?.count ?? '',
                    bold: (text) => <span className="fw-bold">{text}</span>,
                    percentile: selectedCampaignONA?.influence?.percentile ?? 0,
                  }}
                />
              </>
            )}
            {selectedCampaignPrevious ? (
              <>
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.trajectory_summary.influence_helper_text.previous_cycle"
                  defaultMessage=", which is <bold>{influenceDifferenceType, select, zero {no change} negative {a {influenceDifferenceAbsolute} {icon} decline} other {a {influenceDifferenceAbsolute} {icon} improvement}}</bold> from the previous cycle's result of {previousCampaignInfluence} {icon} ({previousCampaignOnaPercentile, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} percentile)."
                  values={{
                    bold: (text) => <span className="fw-bold">{text}</span>,
                    icon: <i className={ICONS.INFLUENCE} />,
                    influenceDifferenceType: getSign(
                      selectedCampaignONA?.influence?.difference
                    ),
                    influenceDifferenceAbsolute: Math.abs(
                      selectedCampaignONA?.influence?.difference ?? 0
                    ),
                    previousCampaignInfluence:
                      selectedCampaignPrevious?.influence?.length ?? 0,
                    previousCampaignOnaPercentile:
                      selectedCampaignPrevious?.ona_percentiles?.influence ?? 0,
                  }}
                />
              </>
            ) : (
              <>{'.'}</>
            )}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default InfluenceDistributionChart;
