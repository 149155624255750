import React, { FC, useCallback, useMemo } from 'react';

import FeedbackRequestCard from './FeedbackRequestCard';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import ModalEditor from '../Widgets/Modals/ModalEditor';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadTasks } from '../../actions';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

const ModalFeedbackDeclineEditor: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const propsOnClosed = props.onClosed;
  const { user } = useAuth0();
  const userSub = user?.sub;

  const onClosed = useCallback(() => {
    // NOTE: we do NOT reset other defaults because someone may accidentally close
    // the dialog either by pressing Esc or clicking outside the dialog; we want
    // reopening to keep the info in there
    if (propsOnClosed) {
      propsOnClosed();
    }
  }, [propsOnClosed]);

  // @ts-expect-error
  const propsLoadTasks = props.loadTasks;
  const callback = useCallback(
    (data) => {
      if (data) {
        toast.success(
          formatMessage({
            id: 'app.views.feedback.modal_feedback_decline_editor.toast.feedback_request_declined',
            defaultMessage: 'Feedback request declined!',
          })
        );

        // refresh tasks (which should go down by one now that this has been declined)
        // @ts-expect-error
        propsLoadTasks(userSub, props.currentProxyPerson?.email);
      }
    },
    // @ts-expect-error
    [propsLoadTasks, userSub, props.currentProxyPerson?.email, formatMessage]
  );

  const sender = useMemo(
    () =>
      // @ts-expect-error
      props.feedbackRequest ? props.feedbackRequest.author_person : undefined,
    [props.feedbackRequest]
  );

  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {props.feedbackRequest && (
            <FeedbackRequestCard
              className="mb-4"
              feedbackRequest={props.feedbackRequest}
            />
          )}
          {inputs}
          {submitButton}
        </>
      );
    },
    [props.feedbackRequest]
  );

  return (
    <ModalEditor
      // @ts-expect-error
      toggle={props.toggle}
      // @ts-expect-error
      isOpen={props.isOpen}
      onOpened={props.onOpened}
      onClosed={onClosed}
      inline={props.inline}
      buttonClassName={props.buttonClassName}
      url="feedback-declines"
      title={formatMessage({
        id: 'app.views.feedback.modal_feedback_decline_editor.title.decline_feedback_request',
        defaultMessage: 'Decline feedback request',
      })}
      object={{
        // @ts-expect-error
        feedback_request: props.feedbackRequest?.id,
        // @ts-expect-error
        content_type: props.feedbackRequest?.content_type,
        // @ts-expect-error
        object_id: props.feedbackRequest?.object_id,
      }}
      callback={callback}
      renderForm={renderForm}
      inputs={[
        {
          required: true,
          name: 'comments',
          label: 'Let ' + sender?.given_name + ' know why you are declining.',
          type: INPUT_TYPES.MENTION_EDITOR,
          multiLine: true,
          minRows: 3,
          autoFocus: true,
        },
      ]}
    />
  );
};

const ModalFeedbackDeclineEditor_propTypes = {
  toggle: PropTypes.func,
  buttonText: PropTypes.string,
  feedback: PropTypes.object,
  isOpen: PropTypes.bool,
  onOpened: PropTypes.func,
  onClosed: PropTypes.func,
  inline: PropTypes.bool,
  buttonClassName: PropTypes.string,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  hideButton: PropTypes.bool,
  callback: PropTypes.func,
  feedbackRequest: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof ModalFeedbackDeclineEditor_propTypes>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTasks: (userSub, proxy) => dispatch(loadTasks(userSub, proxy)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalFeedbackDeclineEditor));
