import Confetti, { ConfettiConfig } from 'react-dom-confetti';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';

import { numberWithCommas } from '../../../../utils/util/util';
import trophyImg from '../../../../assets/img/illustrations/trophy.png';
import starsLeft from '../../../../assets/img/illustrations/stars-left.png';
import starsRight from '../../../../assets/img/illustrations/stars-right.png';

interface WelcomeProps {
  numParticipants: number;
  numTotalPeople: number;
  numTotalEdges: number;
  numInfluenceEdges: number;
  numTotalEnergizedEdges: number;
  numTotalHelpAndAdviceEdges: number;
  numTotalGoldStarEdges: number;
  numTotalHeadsUpEdges: number;
  surveyOnly?: boolean;
}

const confettiConfig: ConfettiConfig = {
  angle: 90,
  spread: 140,
  startVelocity: 56,
  elementCount: 200,
  dragFriction: 0.09,
  duration: 3440,
  stagger: 0,
  width: '10px',
  height: '14px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const confettiContainerStyle: React.CSSProperties = {
  position: 'relative',
  top: '-140px',
  right: '60px',
  bottom: '0',
  left: '0',
  pointerEvents: 'none',
  zIndex: '10',
};

const Welcome: React.FC<WelcomeProps> = ({
  numTotalPeople,
  numTotalEdges,
  numInfluenceEdges,
  numTotalEnergizedEdges,
  numTotalHelpAndAdviceEdges,
  numTotalGoldStarEdges,
  numTotalHeadsUpEdges,
  surveyOnly = false,
}) => {
  const { formatMessage } = useIntl();
  const [confettiShouldFire, setConfettiShouldFire] = useState<
    boolean | undefined
  >(undefined);

  // show confetti on page load
  useEffect(() => {
    setConfettiShouldFire(true);
  }, []);

  // reset confetti so it can be fired when clicking the trophy
  useEffect(() => {
    if (confettiShouldFire) {
      setConfettiShouldFire(false);
    }
  }, [confettiShouldFire]);

  const confettiHandler = useCallback(() => {
    return () => {
      setConfettiShouldFire(false);
      setConfettiShouldFire(true);
    };
  }, []);

  if (surveyOnly) return null;

  return (
    <>
      <div className="row row-cols-1 row-cols-md-12">
        <div className="col-12 col-md-12 col">
          <div className="card">
            <div className="row">
              <div className="col-2 align-self-baseline col">
                <img
                  src={starsLeft}
                  style={{ maxHeight: '200%', maxWidth: '200%' }}
                />
              </div>

              <div className="col-8 text-center mt-5 col pb-5">
                <div>
                  <img
                    onClick={confettiHandler()}
                    src={trophyImg}
                    alt={formatMessage({
                      id: 'app.views.widgets.dashboards.takeaways_dashboard.images.trophy.alt',
                      defaultMessage: 'trophy',
                    })}
                    className="mw-100 m-0"
                    role="button"
                    style={{ width: '12rem' }}
                  />
                  <div style={confettiContainerStyle}>
                    <Confetti
                      // Technically "className" is not a valid prop for Confetti, but it works
                      // and is needed to center the confetti shower on the trophy image.
                      // @ts-expect-error
                      className="react-dom-confetti-overlay"
                      active={!!confettiShouldFire}
                      config={confettiConfig}
                    />
                  </div>
                </div>
                <h1 className="header-title my-3">
                  {!surveyOnly && (
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.congratulations.headline"
                      defaultMessage="Congratulations on completing this cycle!"
                    />
                  )}
                  {surveyOnly && (
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.congratulations.headline.survey"
                      defaultMessage="Congratulations on completing this survey!"
                    />
                  )}
                </h1>
                {!surveyOnly && (
                  <h6 className="text-muted small mb-4">
                    <FormattedMessage
                      id="app.views.widgets.dashboards.takeaways_dashboard.congratulations.subheadline"
                      defaultMessage="Below are key takeaways, analytics, and recommendations for your team."
                    />
                  </h6>
                )}
                <div className="row">
                  <div className="col-8 offset-md-2">
                    <div className="card mt-3">
                      <div className="card-body">
                        <p className="card-text">
                          <FormattedMessage
                            id="app.views.widgets.dashboards.takeaways_dashboard.participation_text"
                            defaultMessage="For the people you have visibility into, we gathered {numTotalEdges} connections across {numTotalPeople} people, including {numInfluenceEdges} Influence connections ({numTotalEnergizedEdges} from giving energy, {numTotalHelpAndAdviceEdges} from help and advice), {numTotalGoldStarEdges} Gold Stars, and {numTotalHeadsUpEdges} Heads Ups."
                            values={{
                              numTotalEdges: numberWithCommas(numTotalEdges),
                              numTotalPeople: numberWithCommas(numTotalPeople),
                              numInfluenceEdges:
                                numberWithCommas(numInfluenceEdges),
                              numTotalEnergizedEdges: numberWithCommas(
                                numTotalEnergizedEdges
                              ),
                              numTotalHelpAndAdviceEdges: numberWithCommas(
                                numTotalHelpAndAdviceEdges
                              ),
                              numTotalGoldStarEdges: numberWithCommas(
                                numTotalGoldStarEdges
                              ),
                              numTotalHeadsUpEdges:
                                numberWithCommas(numTotalHeadsUpEdges),
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-2 align-self-normal col">
                <img
                  src={starsRight}
                  style={{
                    maxHeight: '200%',
                    maxWidth: '200%',
                    float: 'right',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
