import React, { FC } from 'react';

import { escapeRegExp } from 'lodash';

interface Props {
  text: string;
  highlight?: string;
  className?: string;
  highlightClassName?: string;
}

// Based on https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs
const Highlighted: FC<Props> = ({
  text,
  highlight = '',
  className,
  highlightClassName,
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);
  return (
    <span className={className}>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            // if highlightClassName is set, use
            // a span with that classname instead of mark
            highlightClassName ? (
              <span className={highlightClassName} key={i}>
                {part}
              </span>
            ) : (
              <mark key={i}>{part}</mark>
            )
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  );
};

export default Highlighted;
