import { FormattedMessage, useIntl } from 'react-intl';

import React from 'react';
import { formatSimpleDecimal } from '../../../../utils/util/util';

interface EnpsBarProps {
  promoters: number;
  detractors: number;
  passives: number;
  missing: number;
  showHeader?: boolean;
  showFooter?: boolean;
}

interface EnpsBarSectionProps {
  id: string;
  count: number;
  total: number;
  heading: React.ReactNode;
  barColor: string;
  showHeader: boolean;
  textColor?: string;
}

const EnpsBarSection: React.FC<EnpsBarSectionProps> = ({
  id,
  count,
  total,
  heading,
  barColor,
  showHeader,
  textColor,
}) => {
  const percentage = total > 0 ? (count / total) * 100 : 0;

  const textColorStyle = textColor ? { color: textColor } : {};

  return (
    <div style={{ width: `${percentage}%`, minWidth: '4%' }}>
      {showHeader && (
        <h6
          className="text-uppercase text-muted mb-3"
          style={{
            transform: 'rotate(-45deg)',
            minWidth: 'max-content',
            transformOrigin: 'left bottom',
            position: 'relative',
            left: '15px',
          }}
        >
          {heading}
        </h6>
      )}
      <div
        id={id}
        data-testid={id}
        style={{
          backgroundColor: barColor,
          height: '3rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className="fs-4 fw-bold opacity-75" style={textColorStyle}>
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaways_dashboard.enps_bar.percent"
            defaultMessage="{percentage}%"
            values={{
              percentage: formatSimpleDecimal((count / total) * 100),
            }}
          />
        </span>
      </div>
    </div>
  );
};

const EnpsBar: React.FC<EnpsBarProps> = ({
  promoters = 0,
  detractors = 0,
  passives = 0,
  missing = 0,
  showHeader = true,
  showFooter = true,
}) => {
  const { formatMessage } = useIntl();
  const totalResponses = promoters + detractors + passives;

  const promoterHeader = formatMessage({
    id: 'app.views.widgets.dashboards.takeaways_dashboard.enps_bar.promoter_header',
    defaultMessage: 'Promoters',
  });

  const passiveHeader = formatMessage({
    id: 'app.views.widgets.dashboards.takeaways_dashboard.enps_bar.passive_header',
    defaultMessage: 'Passives',
  });

  const detractorHeader = formatMessage({
    id: 'app.views.widgets.dashboards.takeaways_dashboard.enps_bar.detractor_header',
    defaultMessage: 'Detractors',
  });

  if (totalResponses === 0) {
    return (
      <div className="text-secondary">
        <FormattedMessage
          id="app.views.widgets.dashboards.takeaways_dashboard.enps_bar.no_responses"
          defaultMessage="No responses have been received."
        />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center w-100">
        {promoters > 0 && (
          <EnpsBarSection
            id="promoters-bar"
            count={promoters}
            total={promoters + detractors + passives}
            heading={promoterHeader}
            showHeader={showHeader}
            barColor="#00d97e"
            textColor="#ffffff"
          />
        )}
        {passives > 0 && (
          <EnpsBarSection
            id="passives-bar"
            count={passives}
            total={promoters + detractors + passives}
            heading={passiveHeader}
            barColor="#f6c343"
            showHeader={showHeader}
          />
        )}
        {detractors > 0 && (
          <EnpsBarSection
            id="detractors-bar"
            count={detractors}
            total={promoters + detractors + passives}
            heading={detractorHeader}
            barColor="#e63757"
            showHeader={showHeader}
            textColor="#ffffff"
          />
        )}
      </div>
      {showFooter ? (
        <div className="text-secondary enps-footer" role="label">
          <FormattedMessage
            id="app.views.widgets.dashboards.takeaways_dashboard.enps_bar.stats"
            defaultMessage="{numResponses, plural, one {{numResponses, number} response} other {{numResponses, number} responses}} from {numParticipants, plural, one {{numParticipants, number} participant} other {{numParticipants, number} participants}}"
            values={{
              numResponses: promoters + detractors + passives,
              numParticipants: promoters + detractors + passives + missing,
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EnpsBar;
