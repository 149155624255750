import * as consts from '../../consts/consts';

import { FormattedMessage } from 'react-intl';
import React from 'react';

// NOTE: these should match models.py's Skill model's type
export const SKILL_TYPE_EXPERIENCE = {
  id: 'E',
  name: 'skill',
  pluralName: 'skills',
  profileHeading: (
    <FormattedMessage
      id="app.views.person.person_profile.heading.label.skills"
      defaultMessage="Skills"
    />
  ),
  icon: consts.ICONS.SKILL,
};

export const SKILL_TYPE_BEHAVIOR = {
  id: 'B',
  name: 'behavior',
  pluralName: 'behaviors',
  profileHeading: (
    <FormattedMessage
      id="app.views.person.person_profile.heading.label.behaviors"
      defaultMessage="Behaviors"
    />
  ),
  icon: consts.ICONS.BEHAVIOR,
};
