import * as consts from '../../consts/consts';

import { Button, Col, Row, UncontrolledPopover } from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getCampaignCoverageDurationMonthString,
  getRelationships,
  replaceRelationships,
} from '../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_HIDE_ASSESS_MANAGER_FEEDBACK_FROM_SUBJECT,
  PERFORMANCE_FEATURE_HIDE_EVALUATION_NEGATIVE_TAG_RECOMMENDATIONS,
  PERFORMANCE_FEATURE_HIDE_EVALUATION_POSITIVE_TAG_RECOMMENDATIONS,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_HIDE_PEER_FEEDBACK_FROM_SUBJECT,
  PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_LABEL,
  PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL,
  extractAnonymousResponses,
  extractCustomResponses,
  getCampaignFeature,
  getCampaignHasCalibrationPhase,
  getCampaignHasFeatureEnabled,
  getCampaignRatings,
  prepareOpenResponseQuestion,
  replaceCampaignQuestionText,
} from '../../utils/models/Performance';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  filterUniqueByESIndexandIdAndPutMostFrequentFirst,
  isEnabled,
  prepTagsForSubmit,
  renderErrorOrCallback,
} from '../../utils/util/util';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import PersonProfileFeedback from '../Person/PersonProfileFeedback';
import PropTypes from 'prop-types';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { peopleObjectsAreEqual } from '../../utils/models/Person';
import { setCurrentPerfSurveyResponse } from '../../actions';
import { withRouter } from 'react-router-dom';

const PROMOTION_YES_OPTION = 'Yes';
const PROMOTION_NO_OPTION = 'No';
const SALARY_INCREASE_YES_OPTION = 'Yes';
const SALARY_INCREASE_NO_OPTION = 'No';
const DEFAULT_MIN_POSITIVE_TAGS = 2;
const DEFAULT_MIN_NEGATIVE_TAGS = 1;

const PerformancePersonAssessment = (props) => {
  const { formatMessage } = useIntl();

  const propsCallback = props.callback;
  const campaign = props.campaign;
  const setCampaign = props.setCampaign;

  const [feedbackCount, setFeedbackCount] = useState(0);
  const [userKnowsPersonsWork, setUserKnowsPersonsWork] = useState(undefined);
  const instructionsLearnMoreRef = useRef();
  const ratingLearnMoreRef = useRef();
  const ratingCommentLearnMoreRef = useRef();
  const promoLearnMoreRef = useRef();
  const person = useMemo(() => props.person, [props.person]);

  // if user has already given feedback, set the "Are you familiar..." text to true
  useEffect(() => {
    if (feedbackCount > 0) {
      setUserKnowsPersonsWork(true);
    }
  }, [feedbackCount]);

  // feedback to managers and peers should have negative tags/comments optional
  // as they can sometimes be incredibly difficult to provide
  const negativeTagsAndCommentsAreRequired = useMemo(
    () =>
      props.relationshipType !== RELATIONSHIP_TYPES.REPORTS_TO &&
      props.relationshipType !==
        RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR,
    [props.relationshipType]
  );

  const campaignHasCalibrationPhase = useMemo(
    () => getCampaignHasCalibrationPhase(campaign),
    [campaign]
  );

  const isDemoOrPreviewMode = useMemo(
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    [campaign?.status]
  );

  const callback = useCallback(
    (data) => {
      if (data) {
        let dataToSendToCallback = data;

        // simulate server response in demo, but for non-demo
        // get actual data from server
        if (isDemoOrPreviewMode) {
          // TODO: convert skills list for frontend rendering
          dataToSendToCallback.from_person = props.me;
          dataToSendToCallback.to_person = person;
        }

        setCampaign(
          replaceRelationships(
            campaign,
            [props.relationshipType],
            [dataToSendToCallback],
            dataToSendToCallback.from_person,
            dataToSendToCallback.to_person
          )
        );

        propsCallback(dataToSendToCallback);
      }
    },
    [
      isDemoOrPreviewMode,
      setCampaign,
      campaign,
      props.relationshipType,
      props.me,
      propsCallback,
      person,
    ]
  );

  const propsSetIsContinueDisabled = props.setIsContinueDisabled;
  const cannotProceed = useMemo(
    () =>
      propsSetIsContinueDisabled
        ? typeof userKnowsPersonsWork === 'undefined' ||
          (userKnowsPersonsWork && feedbackCount === 0)
        : false,
    [feedbackCount, propsSetIsContinueDisabled, userKnowsPersonsWork]
  );

  useEffect(() => {
    if (propsSetIsContinueDisabled) {
      propsSetIsContinueDisabled(cannotProceed);
    }
  }, [propsSetIsContinueDisabled, cannotProceed]);

  const personSRConfigs = useMemo(
    () => props.personSurveyResponse?.configs,
    [props.personSurveyResponse]
  );

  const shouldNotBeRated = useMemo(
    () =>
      props.isDirectReport &&
      campaign?.survey_responses?.find((sr) =>
        peopleObjectsAreEqual(sr.person, person)
      )?.configs?.is_rated === false,
    [person, campaign?.survey_responses, props.isDirectReport]
  );

  const questionsToMap = useMemo(
    () =>
      props.openResponseQuestions
        ? props.openResponseQuestions
        : // default open response questions
          [
            {
              name: 'positive_skills',
              label: formatMessage(
                {
                  id: 'app.views.performance.performance_person_assessment.in_the_past_duration_what_are_the_strongest_skills_and_behaviors_person_demonstrated',
                  defaultMessage:
                    'In the past {duration}, what are the strongest skills and behaviors {person} demonstrated?',
                },
                {
                  duration: getCampaignCoverageDurationMonthString(
                    campaign,
                    formatMessage
                  ),
                  person: person.given_name,
                }
              ),
              helperText: props.isDirectReport
                ? undefined
                : formatMessage({
                    id: 'app.views.performance.performance_person_assessment.list_2_or_more_skills_or_behaviors',
                    defaultMessage: 'List 2 or more skills or behaviors.',
                  }),
            },
            {
              name: 'positive_comments',
              label:
                (props.isPeer
                  ? formatMessage({
                      id: 'app.views.performance.performance_person_assessment.optional',
                      defaultMessage: 'Optional:',
                    }) + ' '
                  : '') +
                formatMessage({
                  id: 'app.views.performance.performance_person_assessment.give_an_example_or_two_of_how_you_saw_these_demonstrated',
                  defaultMessage:
                    'Give an example or two of how you saw these demonstrated.',
                }),
            },
            {
              name: 'negative_skills',
              label: formatMessage(
                {
                  id: 'app.views.performance.performance_person_assessment.what_skills_or_behaviors_does_person_need_guidance_or_support_with_moving_forward',
                  defaultMessage:
                    'What skills or behaviors does {person} need guidance or support with moving forward?',
                },
                { person: person.given_name }
              ),
              helperText: props.isDirectReport
                ? undefined
                : formatMessage({
                    id: 'app.views.performance.performance_person_assessment.list_1_or_more_skills_or_behaviors',
                    defaultMessage: 'List 1 or more skills or behaviors.',
                  }),
            },
            {
              name: 'negative_comments',
              label:
                (props.isPeer
                  ? formatMessage({
                      id: 'app.views.performance.performance_person_assessment.optional',
                      defaultMessage: 'Optional:',
                    }) + ' '
                  : '') +
                formatMessage(
                  {
                    id: 'app.views.performance.performance_person_assessment.share_an_idea_or_two_of_how_person_could_further_develop_in_these_areas',
                    defaultMessage:
                      'Share an idea or two of how {person} could further develop in these areas.',
                  },
                  { person: person.given_name }
                ),
            },
          ],
    [
      person.given_name,
      campaign,
      props.isDirectReport,
      props.isPeer,
      props.openResponseQuestions,
      formatMessage,
    ]
  );

  const object = useMemo(() => {
    const relevantRelationships = getRelationships(
      campaign,
      props.relationshipType
    );

    // fetch relationship with data if user already
    // entered data for this relationship (hence !r.dataset)
    const relationship = relevantRelationships?.find(
      (r) => !r.dataset && peopleObjectsAreEqual(r.to_person, person)
    );

    let o = {};

    if (relationship) {
      o = {
        ...relationship,
        positive_skills: [
          ...(relationship?.positive_skills
            ? relationship.positive_skills.map((o) => ({
                ...o,
                _index: 'skills',
              }))
            : []),
        ],
        negative_skills: [
          ...(relationship?.negative_skills
            ? relationship.negative_skills.map((o) => ({
                ...o,
                _index: 'skills',
              }))
            : []),
        ],
      };

      // Adding the individual key-value pairs for Relationship responses,
      // which could be answers for custom questions
      for (let customQuestion in relationship?.responses) {
        const question = questionsToMap.find((q) => q.name === customQuestion);
        if (question) {
          const responseValue = relationship.responses[customQuestion];

          const type = question.type.toUpperCase();
          if (
            question.options &&
            (type === INPUT_TYPES.MULTIPLE_CHOICE ||
              type === INPUT_TYPES.SELECT)
          ) {
            // if a multiple choice question, make sure the value selected
            // matches the id or a name of an option and store just the id
            const matchedOption =
              question.options.find((option) => option.id === responseValue) ||
              question.options.find((option) => option.name === responseValue);
            if (matchedOption) {
              o[customQuestion] = matchedOption;
            } else {
              console.warn(`No option matches value ${responseValue}`);
            }
          } else {
            o[customQuestion] = responseValue;
          }
        }
      }

      // TODO: investigate replacing the code above with
      // the pair addAnonymousResponseValues / addCustomResponseValues
      // if (typeof relationship?.responses === 'object') {
      //   addAnonymousResponseValues(relationship?.responses, o);
      // }
    }

    return o;
  }, [person, campaign, props.relationshipType, questionsToMap]);

  const [updatedObject, setUpdatedObject] = useState(object);

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const customResponses = extractCustomResponses(object);
      const anonymousResponses = extractAnonymousResponses(object);

      return {
        campaign: campaign.id,
        step: props.currentStepNumber,
        type: props.relationshipType,
        to_person: person.id,
        // we must pass [] if blank to avoid null 400 from server
        positive_skills: object?.positive_skills
          ? prepTagsForSubmit(
              object?.positive_skills,
              props.currentOrganization?.id
            )
          : [],
        positive_comments: object?.positive_comments,
        // we must pass [] if blank to avoid null 400 from server
        negative_skills: object?.negative_skills
          ? prepTagsForSubmit(
              object?.negative_skills,
              props.currentOrganization?.id
            )
          : [],
        negative_comments: object.negative_comments,
        rating: object.rating,
        rating_comments: object.rating_comments,
        recommend_for_promotion:
          object.promotion_comments?.length > 0
            ? object.promotion_comments !== PROMOTION_NO_OPTION
            : undefined,
        promotion_comments: object.promotion_comments,
        recommend_for_salary_increase:
          object.salary_increase_comments?.length > 0
            ? object.salary_increase_comments !== SALARY_INCREASE_NO_OPTION
            : undefined,
        salary_increase_comments: object.salary_increase_comments,
        responses: { ...customResponses, ...anonymousResponses },
      };
    },
    [
      person,
      props.currentOrganization?.id,
      campaign.id,
      props.currentStepNumber,
      props.relationshipType,
    ]
  );

  // default to show tag recommendations (unless config passed in to hide them)
  const hidePositiveTagRecommendations = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_EVALUATION_POSITIVE_TAG_RECOMMENDATIONS
      ),
    [campaign]
  );

  const hideNegativeTagRecommendations = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_EVALUATION_NEGATIVE_TAG_RECOMMENDATIONS
      ),
    [campaign]
  );

  const positiveTagRecommendations = useMemo(() => {
    if (
      hidePositiveTagRecommendations ||
      !props.isDirectReport ||
      !(
        props.incomingRelationshipsWithFeedback?.length > 0 ||
        props.personSurveyResponse?.positive_skills?.length > 0
      )
    ) {
      return undefined;
    }

    // get tags mentioned by others in order of most mentioned to least
    const skillsList = props.incomingRelationshipsWithFeedback
      ? props.incomingRelationshipsWithFeedback.reduce((arr, r) => {
          return [
            ...arr,
            ...(r.positive_skills?.map((s) =>
              ElasticsearchAPI.defaultSelectorMapFunction({
                _index: 'skills',
                _source: s,
              })
            ) ?? []),
          ];
        }, [])
      : [];

    const selfSkillsList =
      props.personSurveyResponse?.positive_skills?.map((s) =>
        ElasticsearchAPI.defaultSelectorMapFunction({
          _index: 'skills',
          _source: s,
        })
      ) ?? [];

    return filterUniqueByESIndexandIdAndPutMostFrequentFirst(
      skillsList.concat(selfSkillsList)
    );
  }, [
    hidePositiveTagRecommendations,
    props.incomingRelationshipsWithFeedback,
    props.isDirectReport,
    props.personSurveyResponse,
  ]);

  const negativeTagRecommendations = useMemo(() => {
    if (
      hideNegativeTagRecommendations ||
      !props.isDirectReport ||
      !(
        props.incomingRelationshipsWithFeedback?.length > 0 ||
        props.personSurveyResponse?.negative_skills?.length > 0
      )
    ) {
      return undefined;
    }

    // get tags mentioned by others in order of most mentioned to least
    const skillsList = props.incomingRelationshipsWithFeedback
      ? props.incomingRelationshipsWithFeedback.reduce((arr, r) => {
          return [
            ...arr,
            ...(r.negative_skills?.map((s) =>
              ElasticsearchAPI.defaultSelectorMapFunction({
                _index: 'skills',
                _source: s,
              })
            ) ?? []),
          ];
        }, [])
      : [];

    const selfSkillsList =
      props.personSurveyResponse?.negative_skills?.map((s) =>
        ElasticsearchAPI.defaultSelectorMapFunction({
          _index: 'skills',
          _source: s,
        })
      ) ?? [];

    return filterUniqueByESIndexandIdAndPutMostFrequentFirst(
      skillsList.concat(selfSkillsList)
    );
  }, [
    hideNegativeTagRecommendations,
    props.incomingRelationshipsWithFeedback,
    props.isDirectReport,
    props.personSurveyResponse,
  ]);

  const inputs = useMemo(
    () =>
      questionsToMap.map((q, index) =>
        prepareOpenResponseQuestion(
          formatMessage,
          q,
          negativeTagRecommendations,
          negativeTagsAndCommentsAreRequired,
          person.given_name,
          positiveTagRecommendations,
          props.isPeer,
          campaign,
          props.currentOrganization,
          index,
          isDemoOrPreviewMode
        )
      ),
    [
      questionsToMap,
      negativeTagRecommendations,
      negativeTagsAndCommentsAreRequired,
      person.given_name,
      positiveTagRecommendations,
      props.isPeer,
      props.currentOrganization,
      campaign,
      isDemoOrPreviewMode,
      formatMessage,
    ]
  );

  const minPositiveTagsRequired = useMemo(() => {
    // if not asking for positive skills, don't require
    const q = inputs?.find((q) => q.name === 'positive_skills');

    if (!q) {
      return 0;
    }

    // value could be 0 so check for undefined explicitly
    if (typeof q.minLength !== 'undefined') {
      return q.minLength;
    }

    return DEFAULT_MIN_POSITIVE_TAGS;
  }, [inputs]);

  const minNegativeTagsRequired = useMemo(() => {
    // if not asking for negative skills, don't require
    const q = inputs?.find((q) => q.name === 'negative_skills');

    if (!q) {
      return 0;
    }

    // value could be 0 so check for undefined explicitly
    if (typeof q.minLength !== 'undefined') {
      return q.minLength;
    }

    return DEFAULT_MIN_NEGATIVE_TAGS;
  }, [inputs]);

  const onValidate = useCallback(
    (obj) => {
      let errors = {};

      if (
        minPositiveTagsRequired > 0 &&
        !(obj.positive_skills?.length >= minPositiveTagsRequired)
      ) {
        errors['positive_skills'] =
          minPositiveTagsRequired === 1
            ? formatMessage({
                id: 'app.views.performance.performance_person_assessment.at_least_one_skill_or_behavior_is_required',
                defaultMessage: 'At least one skill or behavior is required.',
              })
            : formatMessage(
                {
                  id: 'app.views.performance.performance_person_assessment.at_least_count_skills_or_behaviors_are_required',
                  defaultMessage:
                    'At least {count} skills or behaviors are required.',
                },
                { count: minPositiveTagsRequired }
              );
      }

      if (
        minNegativeTagsRequired > 0 &&
        !(obj.negative_skills?.length >= minNegativeTagsRequired)
      ) {
        if (negativeTagsAndCommentsAreRequired) {
          errors['negative_skills'] =
            minNegativeTagsRequired === 1
              ? formatMessage({
                  id: 'app.views.performance.performance_person_assessment.at_least_one_skill_or_behavior_is_required',
                  defaultMessage: 'At least one skill or behavior is required.',
                })
              : formatMessage(
                  {
                    id: 'app.views.performance.performance_person_assessment.at_least_count_skills_or_behaviors_are_required',
                    defaultMessage:
                      'At least {count} skills or behaviors are required.',
                  },
                  { count: minNegativeTagsRequired }
                );
        }
      }

      return errors;
    },
    [
      minNegativeTagsRequired,
      minPositiveTagsRequired,
      negativeTagsAndCommentsAreRequired,
      formatMessage,
    ]
  );

  const allowPromotionYesComments = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_ALLOW_COMMENTS
      ),
    [campaign]
  );

  const allowSalaryIncreaseYesComments = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_ALLOW_COMMENTS
      ),
    [campaign]
  );

  const promotionPacketsRequired = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED
      ),
    [campaign]
  );

  const ratings = useMemo(() => getCampaignRatings(campaign), [campaign]);

  const levelOrTitlePhrase = useMemo(() => {
    if (
      !personSRConfigs?.title &&
      !personSRConfigs?.level_id &&
      !personSRConfigs?.level
    ) {
      return null;
    }

    return (
      <>
        <FormattedMessage
          id="app.views.performance.performance_person_assessment.as_title"
          defaultMessage="
        as <bold>{personSRConfigsTitle} {hasBoth, select, true {({personSRConfigsLevelId} - {personSRConfigsLevel})} other {( {personSRConfigsLevelId} {personSRConfigsLevel} )}}</bold>"
          values={{
            hasBoth: !!(personSRConfigs?.level_id && personSRConfigs?.level),
            personSRConfigsTitle: personSRConfigs?.title ?? '',
            personSRConfigsLevelId: personSRConfigs?.level_id ?? '',
            personSRConfigsLevel: personSRConfigs?.level ?? '',
            bold: (chunks) => <span className="fw-bold">{chunks}</span>,
          }}
        />
      </>
    );
  }, [
    personSRConfigs?.level,
    personSRConfigs?.level_id,
    personSRConfigs?.title,
  ]);

  const fetchAIRecommendation = useCallback(
    (successCallback, errorCallback) => {
      return ConfirmAPI.sendRequestToConfirm(
        'POST',
        'ai/recommendations',
        {
          organization: props.currentOrganization?.id,
          campaign: campaign?.id,
          person: person.id,
          ...(props.currentProxyPerson?.email
            ? { proxy: props.currentProxyPerson?.email }
            : {}),
          ...(isDemoOrPreviewMode
            ? {
                demo_text: formatMessage(
                  {
                    id: 'app.views.performance.performance_person_assessment.demo.person_meets_manager_expectations_in_the_role_of_title',
                    defaultMessage:
                      '{person} meets manager expectations in the role of {title}' +
                      '. She adapts to ever-changing client demands and works effectively under pressure. She maintains a positive attitude and acute sense of detail, but often at the expense of effective time management. ' +
                      'She demonstrates adequate communication skills and completes all requested tasks and required responsibilities. ' +
                      'She is very considerate of other team members and works well independently or collaboratively. ' +
                      'She shows great aptitude for leadership but doesn not seek out additional professional development opportunities.',
                  },
                  { person: person.given_name, title: person.title }
                ),
              }
            : {}),
          manager_evaluation: {
            positive_skills: updatedObject?.positive_skills
              ?.map((obj) => obj.name)
              .join(', '),
            positive_comments: updatedObject?.positive_comments,
            negative_skills: updatedObject?.negative_skills
              ?.map((obj) => obj.name)
              .join(', '),
            negative_comments: updatedObject?.negative_comments,
            rating_comments: updatedObject?.rating_comments,
            rating: ratings.find((r) => r.value === updatedObject?.rating)
              ?.name,
            promotion: updatedObject?.promotion_comments,
          },
        },
        renderErrorOrCallback(successCallback, errorCallback)
      );
    },
    [
      campaign?.id,
      isDemoOrPreviewMode,
      person.given_name,
      person.id,
      person.title,
      props.currentOrganization?.id,
      props.currentProxyPerson?.email,
      updatedObject,
      ratings,
      formatMessage,
    ]
  );

  const hideRatingFromDirectReport = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT
      ),
    [campaign]
  );

  // AI recommendations are defaulted on unless organization specifically
  // disables them
  const aiRecommendationsAreEnabled = useMemo(
    () => props.features?.ai_recommendations?.enabled !== false,
    [props.features?.ai_recommendations?.enabled]
  );

  const promotionQuestionOverrideLabel = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_LABEL
      ),
    [campaign]
  );

  const promotionQuestionLabel = useMemo(
    () =>
      promotionQuestionOverrideLabel ? (
        <RichTextViewer
          model={replaceCampaignQuestionText(
            promotionQuestionOverrideLabel,
            person.given_name,
            campaign,
            props.currentOrganization,
            formatMessage
          )}
          expanded={true}
        />
      ) : (
        <>
          {promotionPacketsRequired && (
            <span>
              <FormattedMessage
                id="app.views.performance.performance_person_assessment.for_planning"
                defaultMessage="
              For planning, is a promotion packet going to be submitted for {personName}?"
                values={{
                  personName: person.given_name,
                }}
              />{' '}
              <span
                className="text-primary"
                role="button"
                ref={promoLearnMoreRef}
              >
                <FormattedMessage
                  id="app.views.performance.performance_person_assessment.learn_more"
                  defaultMessage="
                Learn more
              "
                />
              </span>
            </span>
          )}
          {!promotionPacketsRequired && (
            <span>
              <FormattedMessage
                id="app.views.performance.performance_person_assessment.wish_nominate_for_promotion"
                defaultMessage="
              Do you wish to nominate {personName} for a promotion?"
                values={{
                  personName: person.given_name,
                }}
              />{' '}
              {props.orgHasLevelingFramework && (
                <span
                  className="text-primary"
                  role="button"
                  ref={promoLearnMoreRef}
                >
                  <FormattedMessage
                    id="app.views.performance.performance_person_assessment.learn_more"
                    defaultMessage="
                  Learn more
                "
                  />
                </span>
              )}
            </span>
          )}
          {(props.orgHasLevelingFramework || promotionPacketsRequired) && (
            <>
              <UncontrolledPopover
                delay={50}
                trigger={'click'}
                placement="top"
                target={promoLearnMoreRef}
              >
                {props.orgHasLevelingFramework && (
                  <>
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.base_decision"
                      defaultMessage="
                    Base your decision on <link>{organizationName}'s leveling framework {externalLink}</link>."
                      values={{
                        organizationName: props.currentOrganization.name,
                        link: (chunks) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={consts.LEVELING_FRAMEWORK(formatMessage).path}
                          >
                            {chunks}
                          </a>
                        ),
                        externalLink: (
                          <span className="fe fe-external-link ms-2"></span>
                        ),
                      }}
                    />
                  </>
                )}
                {promotionPacketsRequired && (
                  <div className="mt-2 text-muted">
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.inform_committee"
                      defaultMessage="
                    This will only inform the {campaignHasCalibrationPhase, select, true {calibration} other {review}} committee for their planning purposes. A <link>promotion packet</link> for this person still needs to be submitted separately."
                      values={{
                        campaignHasCalibrationPhase:
                          !!campaignHasCalibrationPhase,
                        link: (chunks) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={consts.PROMOTION_PACKETS(formatMessage).path}
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </div>
                )}
              </UncontrolledPopover>
            </>
          )}
        </>
      ),
    [
      campaign,
      campaignHasCalibrationPhase,
      person.given_name,
      promotionPacketsRequired,
      promotionQuestionOverrideLabel,
      props.currentOrganization,
      props.orgHasLevelingFramework,
      formatMessage,
    ]
  );

  const promotionQuestionOverrideHelperText = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT
      ),
    [campaign]
  );

  const promotionHelperText = useMemo(
    () =>
      promotionQuestionOverrideHelperText ? (
        <RichTextViewer
          model={replaceCampaignQuestionText(
            promotionQuestionOverrideHelperText,
            person.given_name,
            campaign,
            props.currentOrganization,
            formatMessage
          )}
          expanded={true}
        />
      ) : (
        <>
          {promotionPacketsRequired && (
            <span>
              <FormattedMessage
                id="app.views.performance.performance_person_assessment.promotion_packet"
                defaultMessage="
              A <link>Promotion packet</link> for this person still needs to be submitted separately."
                values={{
                  link: (chunks) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={consts.PROMOTION_PACKETS(formatMessage).path}
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </span>
          )}
        </>
      ),
    [
      promotionQuestionOverrideHelperText,
      person.given_name,
      campaign,
      props.currentOrganization,
      promotionPacketsRequired,
      formatMessage,
    ]
  );

  const salaryIncreaseQuestionOverrideLabel = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL
      ),
    [campaign]
  );

  const salaryIncreaseQuestionLabel = useMemo(
    () =>
      salaryIncreaseQuestionOverrideLabel ? (
        <RichTextViewer
          model={replaceCampaignQuestionText(
            salaryIncreaseQuestionOverrideLabel,
            person.given_name,
            campaign,
            props.currentOrganization,
            formatMessage
          )}
          expanded={true}
        />
      ) : (
        <>
          <span>
            <FormattedMessage
              id="app.views.performance.performance_person_assessment.wish_nominate_salary_increase"
              defaultMessage="
            Do you wish to nominate {personName} for a salary increase?"
              values={{
                personName: person.given_name,
              }}
            />
          </span>
        </>
      ),
    [
      campaign,
      person.given_name,
      props.currentOrganization,
      salaryIncreaseQuestionOverrideLabel,
      formatMessage,
    ]
  );

  const salaryIncreaseQuestionOverrideHelperText = useMemo(
    () =>
      getCampaignFeature(
        campaign,
        PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT
      ),
    [campaign]
  );

  const salaryIncreaseHelperText = useMemo(
    () =>
      salaryIncreaseQuestionOverrideHelperText ? (
        <RichTextViewer
          model={replaceCampaignQuestionText(
            salaryIncreaseQuestionOverrideHelperText,
            person.given_name,
            campaign,
            props.currentOrganization,
            formatMessage
          )}
          expanded={true}
        />
      ) : (
        <></>
      ),
    [
      salaryIncreaseQuestionOverrideHelperText,
      person.given_name,
      campaign,
      props.currentOrganization,
      formatMessage,
    ]
  );

  const SCORE_INPUTS = useMemo(
    () => [
      ...(!getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      )
        ? [
            ...(shouldNotBeRated
              ? []
              : [
                  {
                    name: 'rating',
                    required: true,
                    type: INPUT_TYPES.MULTIPLE_CHOICE,
                    options: ratings,
                    label: (
                      <>
                        <span>
                          <FormattedMessage
                            id="app.views.performance.performance_person_assessment.how_rate"
                            defaultMessage="
                    How would you rate {personName}'s overall performance {levelOrTitlePhrase}
                    over the past {campaignCoverageDurationMonthString} {isFormerManager, select, true {based on your time as {personName}'s manager} other {}}?"
                            values={{
                              personName: person.given_name,
                              levelOrTitlePhrase,
                              campaignCoverageDurationMonthString:
                                getCampaignCoverageDurationMonthString(
                                  campaign,
                                  formatMessage
                                ),
                              isFormerManager: props.isFormerManager,
                            }}
                          />{' '}
                          <span
                            className="text-primary"
                            role="button"
                            ref={ratingLearnMoreRef}
                          >
                            <FormattedMessage
                              id="app.views.performance.performance_person_assessment.learn_more"
                              defaultMessage="
                      Learn more
                    "
                            />
                          </span>
                        </span>
                        <UncontrolledPopover
                          delay={50}
                          trigger={'click'}
                          placement="top"
                          target={ratingLearnMoreRef}
                        >
                          {props.orgHasLevelingFramework && (
                            <>
                              <FormattedMessage
                                id="app.views.performance.performance_person_assessment.leveling_framework"
                                defaultMessage="
                        Reference <link>{orgName}'s leveling framework</link> and your team's metrics, deliverables, objectives,
                        etc. for guidance."
                                values={{
                                  orgName: props.currentOrganization.name,
                                  link: (chunks) => (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        consts.LEVELING_FRAMEWORK(formatMessage)
                                          .path
                                      }
                                    >
                                      {chunks}
                                    </a>
                                  ),
                                }}
                              />
                            </>
                          )}
                          {!props.orgHasLevelingFramework && (
                            <>
                              <FormattedMessage
                                id="app.views.performance.performance_person_assessment.consider_team_metrics"
                                defaultMessage="
                        Consider your team's metrics, deliverables,
                        objectives, etc. for guidance. You can hover over each
                        rating to see its definition.
                      "
                              />
                            </>
                          )}
                          <div className="mt-2 text-muted">
                            {campaignHasCalibrationPhase &&
                              !props.isFormerManager && (
                                <span>
                                  <FormattedMessage
                                    id="app.views.performance.performance_person_assessment.draft_rating"
                                    defaultMessage="
                            This draft rating may be revised as part of the
                            calibration process which will review equity and
                            fairness across manager groups.
                          "
                                  />
                                </span>
                              )}
                            {campaignHasCalibrationPhase &&
                              props.isFormerManager && (
                                <span>
                                  <FormattedMessage
                                    id="app.views.performance.performance_person_assessment.rating_shared"
                                    defaultMessage="
                          This rating will be shared only as guidance for {personName}'s current manager."
                                    values={{
                                      personName: person.given_name,
                                    }}
                                  />
                                </span>
                              )}
                            {!campaignHasCalibrationPhase &&
                              !props.isDirectReport && (
                                <span>
                                  <FormattedMessage
                                    id="app.views.performance.performance_person_assessment.rating_shared_guidance"
                                    defaultMessage="
                            This rating will be shared only as guidance for {personName}'s current manager and HR."
                                    values={{
                                      personName: person.given_name,
                                    }}
                                  />
                                </span>
                              )}
                          </div>
                        </UncontrolledPopover>
                      </>
                    ),
                    helperText: hideRatingFromDirectReport ? (
                      <>
                        <span>
                          <FormattedMessage
                            id="app.views.performance.performance_person_assessment.rating_viewable"
                            defaultMessage="This rating will be viewable to {personName}'s manager and above and HR, but NOT {personName}."
                            values={{
                              personName: person.given_name,
                            }}
                          />
                        </span>
                      </>
                    ) : null,
                  },
                ]),
            {
              name: 'rating_comments',
              required: true,
              type: INPUT_TYPES.TEXTAREA,
              maxLength: 1000,
              minRows: 3,
              maxRows: 15,
              label: (
                <>
                  {shouldNotBeRated && (
                    <p>
                      <b>
                        <FormattedMessage
                          id="app.views.performance.performance_person_assessment.the_person_is_not_eligible_to_receive_ratings_this_cycle"
                          defaultMessage="{name} was set by the HR/People team to not receive an overall rating this cycle."
                          values={{
                            name: person.given_name,
                          }}
                        />
                      </b>
                    </p>
                  )}
                  <span>
                    {shouldNotBeRated
                      ? formatMessage(
                          {
                            id: 'app.views.performance.performance_person_assessment.why_provide_rating',
                            defaultMessage:
                              "What is your overall comment on {personName}'s performance?",
                          },
                          { personName: person.given_name }
                        )
                      : formatMessage(
                          {
                            id: 'app.views.performance.performance_person_assessment.why_provide_rating_draft',
                            defaultMessage:
                              'Why did you provide this {campaignHasCalibrationPhase, select, true {draft} other {}} rating?',
                          },
                          {
                            campaignHasCalibrationPhase:
                              !!campaignHasCalibrationPhase,
                          }
                        )}
                  </span>
                </>
              ),
              helperText: (
                <>
                  <span>
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.non_visible_comments"
                      defaultMessage="
                    These comments are not visible to {personName}."
                      values={{
                        personName: person.given_name,
                      }}
                    />{' '}
                    <span
                      className="text-primary"
                      role="button"
                      ref={ratingCommentLearnMoreRef}
                    >
                      <FormattedMessage
                        id="app.views.performance.performance_person_assessment.learn_more"
                        defaultMessage="
                      Learn more
                    "
                      />
                    </span>
                  </span>
                  <UncontrolledPopover
                    delay={50}
                    trigger={'click'}
                    placement="top"
                    target={ratingCommentLearnMoreRef}
                  >
                    {campaignHasCalibrationPhase
                      ? formatMessage({
                          id: 'app.views.performance.performance_person_assessment.these_comments_will_be_referenced_as_part_of_the_calibration_process',
                          defaultMessage:
                            'These comments will be referenced as part of the calibration process which will review equity and fairness across manager groups.',
                        })
                      : formatMessage({
                          id: 'app.views.performance.performance_person_assessment.these_comments_will_be_reference_only_managers',
                          defaultMessage:
                            'These comments will be referenced only by managers and HR/People admins.',
                        })}
                  </UncontrolledPopover>
                </>
              ),
              ...(aiRecommendationsAreEnabled
                ? {
                    fetchAISuggestion: fetchAIRecommendation,
                    fetchAISuggestionPlaceholder: formatMessage(
                      {
                        id: 'app.views.performance.performance_person_assessment.ai_suggestion_placeholder',
                        defaultMessage: '{personName} is...',
                      },
                      { personName: person.given_name }
                    ),
                  }
                : {}),
            },
          ]
        : []),
      ...(getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER
      )
        ? [
            {
              name: 'promotion_comments',
              required: true,
              type: INPUT_TYPES.MULTIPLE_CHOICE,
              options: allowPromotionYesComments
                ? [
                    {
                      id: PROMOTION_NO_OPTION,
                      name: formatMessage({
                        id: 'app.views.performance.promotion_comments.no',
                        defaultMessage: 'No',
                      }),
                    },
                  ]
                : [
                    {
                      id: PROMOTION_NO_OPTION,
                      name: formatMessage({
                        id: 'app.views.performance.promotion_comments.no',
                        defaultMessage: 'No',
                      }),
                    },
                    {
                      id: PROMOTION_YES_OPTION,
                      name: formatMessage({
                        id: 'app.views.performance.promotion_comments.yes',
                        defaultMessage: 'Yes',
                      }),
                    },
                  ],
              allowCustom: allowPromotionYesComments,
              customOptionText: formatMessage({
                id: 'app.views.performance.promotion_comments.yes',
                defaultMessage: 'Yes',
              }),
              customOptionLabel: formatMessage({
                id: 'app.views.performance.performance_person_assessment.provide_one_paragraph_summary',
                defaultMessage:
                  "Provide a one paragraph summary that briefly justifies the promotion. This should include the person's impact on the problem/team/organization/company, and how that impact is commensurate with the proposed new level.",
              }),
              customOptionPlaceholder: '',
              label: promotionQuestionLabel,
              helperText: promotionHelperText,
            },
          ]
        : []),
      ...(getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER
      )
        ? [
            {
              name: 'salary_increase_comments',
              required: true,
              type: INPUT_TYPES.MULTIPLE_CHOICE,
              options: allowSalaryIncreaseYesComments
                ? [
                    {
                      id: SALARY_INCREASE_NO_OPTION,
                      name: formatMessage({
                        id: 'app.views.performance.salary_increase_comments.no',
                        defaultMessage: 'No',
                      }),
                    },
                  ]
                : [
                    {
                      id: SALARY_INCREASE_NO_OPTION,
                      name: formatMessage({
                        id: 'app.views.performance.salary_increase_comments.no',
                        defaultMessage: 'No',
                      }),
                    },
                    {
                      id: SALARY_INCREASE_YES_OPTION,
                      name: formatMessage({
                        id: 'app.views.performance.salary_increase_comments.yes',
                        defaultMessage: 'Yes',
                      }),
                    },
                  ],
              allowCustom: allowSalaryIncreaseYesComments,
              customOptionText: formatMessage({
                id: 'app.views.performance.salary_increase_comments.yes',
                defaultMessage: 'Yes',
              }),
              customOptionLabel: formatMessage({
                id: 'app.views.performance.performance_person_assessment.provide_one_paragraph_summary_salary_increase',
                defaultMessage:
                  "Provide a one paragraph summary that briefly justifies the salary increase. This should include the person's impact on the problem/team/organization/company, and how that impact is commensurate with the proposed salary increase.",
              }),
              customOptionPlaceholder: '',
              label: salaryIncreaseQuestionLabel,
              helperText: salaryIncreaseHelperText,
            },
          ]
        : []),
    ],
    [
      campaign,
      shouldNotBeRated,
      ratings,
      person.given_name,
      levelOrTitlePhrase,
      props.isFormerManager,
      props.orgHasLevelingFramework,
      props.currentOrganization.name,
      props.isDirectReport,
      campaignHasCalibrationPhase,
      aiRecommendationsAreEnabled,
      fetchAIRecommendation,
      allowPromotionYesComments,
      promotionQuestionLabel,
      promotionHelperText,
      allowSalaryIncreaseYesComments,
      salaryIncreaseQuestionLabel,
      salaryIncreaseHelperText,
      formatMessage,
      hideRatingFromDirectReport,
    ]
  );

  const currentInputs = useMemo(() => {
    // direct reports include score inputs
    if (props.isDirectReport) {
      return [...inputs, ...SCORE_INPUTS];
    }
    // show former manager (rating) fields if former manager
    return props.isFormerManager
      ? [...inputs, SCORE_INPUTS[0], SCORE_INPUTS[1]]
      : inputs;
  }, [SCORE_INPUTS, inputs, props.isDirectReport, props.isFormerManager]);

  const getFeedback = useCallback(
    (contributionFeedback) => {
      setFeedbackCount(
        contributionFeedback.filter((cf) =>
          peopleObjectsAreEqual(cf.author_person, props.me)
        )?.length
      );
    },
    [props.me]
  );

  // some companies will want people to see the peer/upward feedback, but unattributed,
  // whereas other companies will only want the person's manager and above (and HR) to
  // see this feedback.
  const feedbackWillBeHiddenFromSubject = useMemo(() => {
    if (!campaign) {
      return false;
    }

    // NOTE: for now we don't differentiate whether this is an optional peer (aka
    // "unsolicited peer feedback") or an assigned peer 360,
    // as it's not easy to tell in the backend on the object itself
    if (props.isPeer) {
      return getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_PEER_FEEDBACK_FROM_SUBJECT
      );
    } else if (props.isUpwardFeedbackToManager) {
      // upward feedback aka giving feedback to your manager
      return getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HIDE_ASSESS_MANAGER_FEEDBACK_FROM_SUBJECT
      );
    }

    // by default, feedback will be visible (but unattributed)
    return false;
  }, [campaign, props.isPeer, props.isUpwardFeedbackToManager]);

  const onChangeRelationship = useCallback((obj) => {
    setUpdatedObject(obj);
  }, []);

  // Denote that there are no "structured" questions in the
  // inputs (positive/negative skills/ comments)
  const noPositiveNegativeInputs = useMemo(
    () =>
      !(
        inputs?.find((q) => q.name === 'positive_skills') ||
        inputs?.find((q) => q.name === 'positive_comments') ||
        inputs?.find((q) => q.name === 'negative_skills') ||
        inputs?.find((q) => q.name === 'negative_comments')
      ),
    [inputs]
  );

  const confirmationDialogObj = useMemo(() => {
    return !(
      updatedObject.positive_skills?.length >= minPositiveTagsRequired
    ) ||
      // only show if positive tags are already required because if not
      // that validation error will show in the UI
      negativeTagsAndCommentsAreRequired ||
      (updatedObject?.negative_skills?.length >= minNegativeTagsRequired &&
        updatedObject?.negative_comments?.length > 0) ||
      noPositiveNegativeInputs
      ? undefined
      : {
          buttonColor: 'primary',
          title: formatMessage({
            id: 'app.views.performance.performance_person_assessment.skip',
            defaultMessage:
              'Save and continue without providing areas of improvement?',
          }),
          description: formatMessage({
            id: 'app.views.performance.performance_person_assessment.do_you_want_to_skip',
            defaultMessage:
              'Do you really want to skip providing areas of improvement? Your perspective matters, and constructive feedback is important.',
          }),
          confirmText: formatMessage({
            id: 'app.views.performance.performance_person_assessment.save_and_continue_anyway',
            defaultMessage: 'Save and continue anyway',
          }),
        };
  }, [
    updatedObject.positive_skills?.length,
    updatedObject?.negative_skills?.length,
    updatedObject?.negative_comments?.length,
    minPositiveTagsRequired,
    negativeTagsAndCommentsAreRequired,
    minNegativeTagsRequired,
    noPositiveNegativeInputs,
    formatMessage,
  ]);

  return (
    <>
      {!props.isDirectReport &&
        props.allowContributionFeedback &&
        props.isOnFirstPage && (
          <>
            <div className="mb-5">
              <Row className="mb-3">
                <Col>
                  <FormattedMessage
                    id="app.views.performance.performance_person_assessment.give_feedback_activity"
                    defaultMessage="
                  Would you like to give feedback on any of {personName}'s activities below?"
                    values={{
                      personName: person.given_name,
                    }}
                  />{' '}
                </Col>
              </Row>
              <Row className="mb-2 justify-content-left">
                <Col className="col-6 col-md-3">
                  <div className="btn-group" role="group">
                    <Button
                      style={{ width: '100px' }}
                      color={
                        typeof userKnowsPersonsWork === 'undefined'
                          ? 'primary'
                          : userKnowsPersonsWork !== true
                          ? 'light'
                          : 'primary'
                      }
                      onClick={() => setUserKnowsPersonsWork(true)}
                    >
                      <FormattedMessage
                        id="app.views.performance.performance_person_assessment.button.yes"
                        defaultMessage="
                      Yes
                    "
                      />
                    </Button>
                    <Button
                      className="border-start-0"
                      style={{ width: '100px', marginLeft: '1px' }}
                      color={
                        typeof userKnowsPersonsWork === 'undefined'
                          ? 'primary'
                          : userKnowsPersonsWork !== false
                          ? 'light'
                          : 'primary'
                      }
                      onClick={() => setUserKnowsPersonsWork(false)}
                    >
                      <FormattedMessage
                        id="app.views.performance.performance_person_assessment.button.no"
                        defaultMessage="
                      No
                    "
                      />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {userKnowsPersonsWork === false && (
              <div className="mb-4">
                <FormattedMessage
                  id="app.views.performance.performance_person_assessment.click_save_and_continue"
                  defaultMessage='
                Click "Save and continue" to go to the next step.
              '
                />
              </div>
            )}
            {userKnowsPersonsWork && (
              <div className="mb-4">
                <span>
                  <FormattedMessage
                    id="app.views.performance.performance_person_assessment.pick_activities"
                    defaultMessage="
                  Pick <bold>1-3 activities</bold> below and give feedback. Your identity <bold>will be unattributed</bold> for {personName}."
                    values={{
                      personName: person.given_name,
                      bold: (msg) => <span className="fw-bold">{msg}</span>,
                    }}
                  />{' '}
                </span>
                <span className="text-primary" ref={instructionsLearnMoreRef}>
                  <FormattedMessage
                    id="app.views.performance.performance_person_assessment.learn_more"
                    defaultMessage="
                  Learn more
                "
                  />
                </span>
                <UncontrolledPopover
                  delay={50}
                  trigger="hover"
                  placement="top"
                  target={instructionsLearnMoreRef}
                >
                  <FormattedMessage
                    id="app.views.performance.performance_person_assessment.person_feedback_view"
                    defaultMessage="{personName} will be able to see your feedback, but
                  your identity will be grouped with the other people who are
                  reviewing {personName} in this performance cycle. {br} {br} Your identity will be attributed for {personName}'s manager and above."
                    values={{
                      personName: person.given_name,
                      br: <br />,
                    }}
                  />
                </UncontrolledPopover>
              </div>
            )}{' '}
            <PersonProfileFeedback
              campaign={campaign}
              relationshipType={props.relationshipType}
              showFeedbackButtons={userKnowsPersonsWork}
              person={person}
              getFeedback={getFeedback}
              className="mb-4"
              hideFeedbackRequestPromptsWhenClaimingContributions={
                !isEnabled(
                  props.features,
                  consts.FLAGS
                    .ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS
                )
              }
            />
            {props.isOnFirstPage && (
              <Row className="align-items-center pt-3">
                <Col className="col-auto">
                  <span id="self-reflection-save-button2">
                    <Button
                      className="mt-0"
                      color="primary"
                      disabled={cannotProceed}
                      onClick={() => props.setIsOnFirstPage(false)}
                    >
                      <FormattedMessage
                        id="app.views.performance.performance_person_assessment.save_and_continue"
                        defaultMessage="
                      Save and continue
                    "
                      />
                    </Button>
                  </span>
                </Col>
                <Col>
                  <div className="text-muted">
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.step_1_of_2"
                      defaultMessage="Step 1 of 2"
                    />
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      {(props.isDirectReport ||
        !props.allowContributionFeedback ||
        !props.isOnFirstPage) && (
        <>
          {!props.isDirectReport && (
            <div className="mb-4">
              {feedbackWillBeHiddenFromSubject && (
                <>
                  <span>
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.few_questions_about"
                      defaultMessage="
                    Answer a few questions about {personName}. Your responses will not be visible to {personName}."
                      values={{
                        personName: person.given_name,
                      }}
                    />{' '}
                  </span>
                  <span className="text-primary" ref={instructionsLearnMoreRef}>
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.learn_more"
                      defaultMessage="
                    Learn more
                  "
                    />
                  </span>
                  <UncontrolledPopover
                    delay={50}
                    trigger="hover"
                    placement="top"
                    target={instructionsLearnMoreRef}
                  >
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.will_not_be_able_to_see_your_feedback"
                      defaultMessage="{personName} will not be able to see your feedback or
                    identity. {br} {br} {personName}'s manager and HR will see your feedback and identity.
                    "
                      values={{
                        personName: person.given_name,
                        br: <br />,
                      }}
                    />
                  </UncontrolledPopover>
                </>
              )}
              {!feedbackWillBeHiddenFromSubject && (
                <>
                  <span>
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.answer_few_questions_about"
                      defaultMessage="
                    Answer a few questions about {personName}. Your identity <bold>will be unattributed</bold> for {personName}."
                      values={{
                        personName: person.given_name,
                        bold: (chunks) => (
                          <span className="fw-bold">{chunks}</span>
                        ),
                      }}
                    />{' '}
                  </span>
                  <span className="text-primary" ref={instructionsLearnMoreRef}>
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.learn_more"
                      defaultMessage="
                    Learn more
                  "
                    />
                  </span>
                  <UncontrolledPopover
                    delay={50}
                    trigger="hover"
                    placement="top"
                    target={instructionsLearnMoreRef}
                  >
                    <FormattedMessage
                      id="app.views.performance.performance_person_assessment.will_be_able_to_see_your_feedback"
                      defaultMessage="{personName} will be able to see your feedback, but
                    your identity will be grouped with the other people who are
                    reviewing {personName} in this performance cycle. {br} {br}
                    Your identity will be attributed for {personName}'s manager and above."
                      values={{
                        personName: person.given_name,
                        br: <br />,
                      }}
                    />
                  </UncontrolledPopover>
                </>
              )}
            </div>
          )}{' '}
          <ValidatedForm
            draftAutosaveEnabled={!isDemoOrPreviewMode}
            uniqueFormKey={`campaign-${campaign.id}-relationship-${props.relationshipType}-${person.id}`}
            method={'POST'}
            url={isDemoOrPreviewMode ? undefined : 'relationships'}
            callback={callback}
            onValidate={onValidate}
            buttonIsBlock={false}
            buttonClassName="mt-0"
            submitText={formatMessage({
              id: 'app.views.performance.performance_person_assessment.submit_text.save_and_continue',
              defaultMessage: 'Save and continue',
            })}
            transformObjectBeforeSubmit={transformObjectBeforeSubmit}
            object={object}
            onChange={onChangeRelationship}
            inputs={currentInputs}
            confirmationDialog={confirmationDialogObj}
          />
        </>
      )}
    </>
  );
};

PerformancePersonAssessment.propTypes = {
  currentStepNumber: PropTypes.number.isRequired,
  relationshipType: PropTypes.string.isRequired,
  person: PropTypes.object,
  personSRConfigs: PropTypes.object,
  callback: PropTypes.func,
  allowContributionFeedback: PropTypes.bool,
  isDirectReport: PropTypes.bool,
  isOnFirstPage: PropTypes.bool,
  isPeer: PropTypes.bool,
  isOptionalPeer: PropTypes.bool,
  isUpwardFeedbackToManager: PropTypes.bool,
  isFormerManager: PropTypes.bool,
  includes: PropTypes.arrayOf(PropTypes.node),
  setIsOnFirstPage: PropTypes.func,
  setIsContinueDisabled: PropTypes.func,
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  setCampaign: PropTypes.func.isRequired,
  incomingRelationshipsWithFeedback: PropTypes.arrayOf(PropTypes.object),
  openResponseQuestions: PropTypes.arrayOf(PropTypes.object),
  orgHasLevelingFramework: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    me,
    authUser,
    currentOrganization,
    currentProxyPerson,
    currentPerfSurveyResponse,
    features,
    orgHasLevelingFramework,
    people,
  } = state;

  return {
    me,
    authUser,
    currentOrganization,
    currentProxyPerson,
    currentPerfSurveyResponse,
    orgHasLevelingFramework,
    people,
    features,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformancePersonAssessment)));
