import { ICONS, PERFORMANCE } from '../../../consts/consts';
import React, { FC, useMemo } from 'react';

import PersonCard from './PersonCard';
import { Table } from 'reactstrap';
import { getPersonField } from '../../../utils/models/Takeaways';
import { useIntl } from 'react-intl';

interface Props {
  fields: string[];
  data: any[];
}

const MedalTableCard: FC<Props> = ({
  fields = ['full_name', 'gold_stars'],
  data = [],
}) => {
  const { formatMessage } = useIntl();
  const header = useMemo(() => {
    const headerFields = {
      full_name: formatMessage({
        id: 'widgets.cards.medal_table_card.header.full_name.name',
        defaultMessage: 'Name',
      }),
      gold_stars: <i className={ICONS.GOLD_STAR} />,
    };

    return (
      <tr>
        {fields.map((f, index) => (
          <th className="ps-0 pe-2" key={index}>
            {headerFields[f]}&nbsp;
          </th>
        ))}
      </tr>
    );
  }, [fields, formatMessage]);

  const body = useMemo(() => {
    return data.map((p, personIndex) => (
      <tr key={personIndex}>
        {fields.map((f, fieldIndex) => {
          let value;

          if (f === 'full_name') {
            // replace full name with person widget
            value = (
              <PersonCard
                size="sm"
                // @ts-expect-error
                person={{
                  id: getPersonField(p, 'id'),
                  url: getPersonField(p, 'url'),
                  avatar: getPersonField(p, 'avatar'),
                  full_name: getPersonField(p, 'full_name'),
                  title: getPersonField(p, 'title'),
                  status: getPersonField(p, 'status'),
                }}
                hidePopover
                bodyOnly
                linked
                isExternalUrl
                urlSuffix={PERFORMANCE().path + '?manager=true'}
                leaderboardNumber={personIndex + 1}
              />
            );
          } else {
            value = getPersonField(p, f);
          }

          return (
            <td className="ps-0 pe-2" key={fieldIndex}>
              {value}
            </td>
          );
        })}
      </tr>
    ));
  }, [fields, data]);
  return (
    <Table className="card-table">
      <thead>{header}</thead>
      <tbody>{body}</tbody>
    </Table>
  );
};

export default React.memo(MedalTableCard);
