import { Comment } from '../../types';
import CommentsLog from 'views/Widgets/CommentsLog';
import React from 'react';
import { cloneDeep } from 'lodash';
import { getDevelopmentPlanStatus } from 'utils/models/DevelopmentPlan';
import { type IntlShape, useIntl } from 'react-intl';
import { getPrettyDate } from 'utils/util/util';

export const transformItems = (items: Comment[], intl) => {
  return items.map((item) => {
    const decoratedItem = cloneDeep(item);

    let parsedChange = item.body;
    try {
      parsedChange = JSON.parse(item.body);
    } catch (e) {
      // return the string as is
      return decoratedItem;
    }

    decoratedItem.body = Object.entries(parsedChange)
      .map((item) => generateItemText(item, intl))
      .join(', ');

    return decoratedItem;
  });
};

const generateItemText = (item, intl) => {
  const [field, oldValue, newValue, customText] = generateTextEntries(
    item[0],
    item[1],
    intl
  );

  if (customText) {
    return customText;
  }

  // case of removal, as sent by the backend
  if (newValue === 'None') {
    return intl.formatMessage(
      {
        id: 'pp.views.development_plans.development_plan.history.item_removed',
        defaultMessage: '{field} removed',
      },
      { field }
    );
  }

  return intl.formatMessage(
    {
      id: 'app.views.development_plans.development_plan.history.item_changed',
      defaultMessage: '{field} changed from {oldValue} to {newValue}',
    },
    {
      field,
      newValue,
      oldValue,
    }
  );
};

type EntryCange<T> = {
  from: T;
  to: T;
};

const bold = (text: string) => `<b><small>${text}</small></b>`;

const generateTextEntries = (
  type: string,
  value: EntryCange<string>,
  intl: IntlShape
) => {
  const { locale } = intl;
  switch (type) {
    case 'status': {
      // @ts-expect-error
      const toStatus = getDevelopmentPlanStatus(value.to);
      // @ts-expect-error
      const fromStatus = getDevelopmentPlanStatus(value.from);
      return [
        intl.formatMessage({
          id: 'app.views.development_plans.development_plan.history.change_type.status',
          defaultMessage: 'Status',
        }),
        `<b class="${fromStatus.textColor} text-uppercase small">${fromStatus.name}</b>`,
        `<b class="${toStatus.textColor} text-uppercase small">${toStatus.name}</b>`,
      ];
    }
    case 'supporter_person': {
      return [
        intl.formatMessage({
          id: 'app.views.development_plans.development_plan.history.change_type.supporter_person',
          defaultMessage: 'Supporter',
        }),
        bold(value.from),
        bold(value.to),
      ];
    }
    case 'candidate_person': {
      return [
        intl.formatMessage({
          id: 'app.views.development_plans.development_plan.history.change_type.candidate_person',
          defaultMessage: 'Candidate',
        }),
        bold(value.from),
        bold(value.to),
      ];
    }
    case 'start_date':
      return [
        intl.formatMessage({
          id: 'app.views.development_plans.development_plan.history.change_type.start_date',
          defaultMessage: 'Start Date',
        }),
        bold(
          getPrettyDate({
            dateString: value.to,
            locale,
          })
        ),
        bold(
          getPrettyDate({
            dateString: value.to,
            locale,
          })
        ),
      ];
    case 'end_date':
      return [
        intl.formatMessage({
          id: 'app.views.development_plans.development_plan.history.change_type.end_date',
          defaultMessage: 'End Date',
        }),
        bold(
          value.from !== 'None'
            ? getPrettyDate({
                dateString: value.from,
                locale,
              })
            : '-'
        ),
        value.to !== 'None'
          ? bold(
              getPrettyDate({
                dateString: value.to,
                locale,
              })
            )
          : 'None',
      ];
    case 'description':
      return [
        intl.formatMessage({
          id: 'app.views.development_plans.development_plan.history.change_type.description',
          defaultMessage: 'Development Plan Description',
        }),
        value.from,
        value.to,
      ];
    case 'template_id':
      return [
        intl.formatMessage({
          id: 'app.views.development_plans.development_plan.history.change_type.template',
          defaultMessage: 'Template',
        }),
        bold(value.from),
        bold(value.to),
      ];
    case 'candidate_acknowledged_at':
      return [
        null,
        null,
        null,
        String(value) === 'None'
          ? intl.formatMessage({
              id: 'app.views.development_plans.development_plan.history.change_type.candidate_acknowledment_dismissed',
              defaultMessage: 'Acknowledgment dismissed',
            })
          : intl.formatMessage(
              {
                id: 'app.views.development_plans.development_plan.history.change_type.candidate_acknowledged_at',
                defaultMessage: 'Candidate Acknowledged at {date}',
              },
              {
                date: bold(
                  getPrettyDate({
                    dateString: value,
                    includeTime: true,
                    locale,
                  })
                ),
              }
            ),
      ];
    default:
      return [null, null, null, null];
  }
};

const DevelopmentPlanHistory = ({ developmentPlan }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  return (
    <CommentsLog
      title={formatMessage({
        id: 'app.views.development_plans.development_plan.title.history',
        defaultMessage: 'History',
      })}
      isRichText={true}
      isExternalUrl={true}
      comments={transformItems(developmentPlan?.comments ?? [], intl)}
      emptyStateText={formatMessage({
        id: 'app.views.development_plans.development_plan.emptyStateText.no_comments_yet',
        defaultMessage:
          'No updates or comments recorded for this Development plan yet.',
      })}
    />
  );
};

export default DevelopmentPlanHistory;
