import { Button, Table } from 'reactstrap';
import { EnpsSummaryAnswer, EnpsSummaryTheme } from './types';

import EnpsThemeModal from './EnpsThemeModal';
import { FormattedMessage } from 'react-intl';
import React from 'react';

interface Props {
  segment: string;
  themes: EnpsSummaryTheme[];
  comments: EnpsSummaryAnswer[];
}

const EnpsSentimentTable: React.FC<Props> = ({ segment, themes, comments }) => {
  const [selectedTheme, setSelectedTheme] =
    React.useState<EnpsSummaryTheme | null>(null);

  const output = (
    <Table>
      <thead>
        <tr className="row">
          <th className="col col-4">
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.enps_sentiment_table.title"
              defaultMessage="{name} Sentiment"
              values={{ name: segment }}
            />
          </th>
          <th className="col col-6">
            <FormattedMessage
              id="app.takeaways_dashboard.enps_summary_card.table_header.themes.title"
              defaultMessage="Theme"
            />
          </th>
          <th className="col col-2"></th>
        </tr>
      </thead>
      <tbody>
        {themes?.map((theme: EnpsSummaryTheme) => {
          return (
            <tr className="align-baseline row" key={theme.title}>
              <td className="align-baseline col col-4">
                <h4>{theme.title}</h4>
                <p className="text-muted">
                  <FormattedMessage
                    id="app.views.widgets.dashboards.takeaways_dashboard.enps_sentiment_table.num_responses"
                    defaultMessage="{num} response(s)"
                    values={{ num: theme.relatedComments.length }}
                  />
                </p>
              </td>
              <td className="align-baseline col col-6">
                <p>{theme.summary}</p>
              </td>
              <td className="align-baseline text-end col-2">
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => setSelectedTheme(theme)}
                >
                  <FormattedMessage
                    id="app.views.engagement_survey.engagement_survey_summary_card.view_details"
                    defaultMessage="View details"
                  />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );

  return (
    <>
      <EnpsThemeModal
        isOpen={!!selectedTheme}
        theme={selectedTheme}
        toggle={() => setSelectedTheme(null)}
        comments={comments}
      />
      {output}
    </>
  );
};

export default React.memo(EnpsSentimentTable);
