import { FormattedDate, FormattedList, FormattedMessage } from 'react-intl';

import React from 'react';

// This page is not linked anyware,
// it is just for testing the i18n visually
// and to have a reference for the syntax
// and the different use cases
// (see https://formatjs.io/docs/react-intl/api/#formattedmessage)
// open this page on http://localhost:3000/i18n-test
const I18nTest = () => {
  const listOfNames = ['Hans', 'Gunther', 'Mazinga'];
  return (
    <div className="p-5">
      <table>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.simple_text"
            defaultMessage="Simple Text"
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.simple_text_with_parameter"
            defaultMessage="Simple Text with parameter {param}"
            values={{ param: 'test-parameter' }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.simple_text_with_parameter_component"
            defaultMessage="Simple Text with parameter component {param}"
            values={{ param: <small>{'test-parameter'}</small> }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.simple_text_with_parameter_link"
            defaultMessage="Simple Text with tag <link>my link</link>"
            values={{
              link: (chunks) => <a>{chunks}</a>,
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.simple_text_with_parameter_link_and_inner_parameter"
            defaultMessage="Simple Text with tag and inner parameters <link>{params}</link>"
            values={{
              link: (chunks) => <a>{chunks}</a>,
              params: 'test-parameter',
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_A"
            defaultMessage="Select {parameter, select, optionA {A} optionB {B} other {other}}"
            values={{
              parameter: 'optionA',
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_B"
            defaultMessage="Select {parameter, select, optionA {A} optionB {B} other {other}}"
            values={{
              parameter: 'optionB',
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_other"
            defaultMessage="Select {parameter, select, optionA {A} optionB {B} other {other}}"
            values={{
              parameter: 'optionC',
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_other_null"
            defaultMessage="Select null {parameter, select, optionA {A} optionB {B} other {other}}"
            values={{
              parameter: null,
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_other_undefined"
            defaultMessage="Select null {parameter, select, optionA {A} optionB {B} other {other}}"
            values={{ parameter: undefined }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_boolean"
            defaultMessage="Select boolean true{hasParameter, select, true { Truthy } other {Falsy}}value"
            values={{
              hasParameter: !!'parameter',
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_boolean_nospace"
            defaultMessage="Select boolean true{hasParameter, select, true {Truthy} other {Falsy}}value with no-space"
            values={{
              hasParameter: !!'parameter',
            }}
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.select_text_with_parameter_options_boolean_false"
            defaultMessage="Select boolean false {hasParameter, select, true { Truthy } other { Falsy }}"
            values={{
              hasParameter: !'parameter',
            }}
          />
        </tr>
        {/* https://formatjs.io/docs/core-concepts/icu-syntax/#quoting--escaping */}
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.escape_curly_braces"
            defaultMessage="Escape '{{curlybraces}}'"
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.escape_curly_braces_double_quotes"
            defaultMessage="Escape '''''{{curlybraces}}'''''"
          />
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.quotes_using_alternative_double_quotes"
            defaultMessage="Escape “'{{curlybraces}}'”"
          />
        </tr>
        <tr>
          {'Oxford comma list (2 elements): '}
          <FormattedList value={['Hans', 'Gunther']} />
        </tr>
        <tr>
          {'Oxford comma conjunction (3+ elements): '}
          <FormattedList value={['Hans', 'Gunther', 'Mazinga']} />
        </tr>
        <tr>
          {'Oxford comma disjunction (3+ elements): '}
          <FormattedList
            type="disjunction"
            value={['Hans', 'Gunther', 'Mazinga']}
          />
        </tr>
        <tr>
          {'Lists with rich text '}
          <FormattedList
            type="disjunction"
            value={listOfNames.map((name, index) => (
              <strong key={index}>{name}</strong>
            ))}
          />
        </tr>
        <tr>
          {'Empty list:'}
          <FormattedList type="disjunction" value={[]} />
        </tr>
        <tr>
          {'concatenate literals'}
          {'nospace'}
        </tr>
        <tr>
          <FormattedMessage
            id="app.views.test.i18n_test.selectordinal"
            defaultMessage="({number, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} percentile)."
            values={{ number: 4 }}
          />
        </tr>
        <tr>
          {'Date: '}
          <FormattedDate
            value={new Date(1459832991883)}
            weekday="long"
            month="long"
            day="numeric"
          />
        </tr>
      </table>
    </div>
  );
};

export default I18nTest;
