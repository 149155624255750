import * as consts from '../../consts/consts';

// NOTE: the below visibilities and type should match backend models.py

export const FEEDBACK_VISIBILITY_EVERYONE = (formatMessage) => ({
  id: 'E',
  name: formatMessage({
    id: 'app.models.feedback.visibility.everyone',
    defaultMessage: 'Everyone',
  }),
  icon: consts.ICONS.PUBLIC,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.everyone.popover',
    defaultMessage: 'Visible to everyone in the organization',
  }),
});

export const FEEDBACK_VISIBILITY_RECIPIENT_ONLY = (formatMessage) => ({
  id: 'P',
  name: formatMessage({
    id: 'app.models.feedback.visibility.recipient_only',
    defaultMessage: 'Individual',
  }),
  icon: consts.ICONS.PRIVATE,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.recipient_only.popover',
    defaultMessage: 'Visible to individual and HR administrators',
  }),
});

export const FEEDBACK_VISIBILITY_RECIPIENT_WITH_MANAGER = (formatMessage) => ({
  id: 'W',
  name: formatMessage({
    id: 'app.models.feedback.visibility.recipient_with_manager',
    defaultMessage: 'Individual + manager',
  }),
  icon: consts.ICONS.PRIVATE,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.recipient_with_manager.popover',
    defaultMessage:
      'Visible to individual, their manager and above, and HR administrators',
  }),
});

export const FEEDBACK_VISIBILITY_MANAGER_ONLY = (formatMessage) => ({
  id: 'O',
  name: formatMessage({
    id: 'app.models.feedback.visibility.manager_only',
    defaultMessage: 'Manager only',
  }),
  icon: consts.ICONS.PRIVATE,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.manager_only.popover',
    defaultMessage:
      "Visible to individual's manager and above and HR administrators",
  }),
});

// this is only for private notes
export const FEEDBACK_VISIBILITY_AUTHOR_ONLY = (formatMessage) => ({
  id: 'A',
  name: formatMessage({
    id: 'app.models.feedback.visibility.author_only',
    defaultMessage: 'Author only',
  }),
  icon: consts.ICONS.PRIVATE,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.author_only.popover',
    defaultMessage: 'Visible only to the author',
  }),
});

export const ALL_VISIBILITIES = (formatMessage) => [
  FEEDBACK_VISIBILITY_EVERYONE(formatMessage),
  FEEDBACK_VISIBILITY_RECIPIENT_ONLY(formatMessage),
  FEEDBACK_VISIBILITY_RECIPIENT_WITH_MANAGER(formatMessage),
  FEEDBACK_VISIBILITY_MANAGER_ONLY(formatMessage),
  FEEDBACK_VISIBILITY_AUTHOR_ONLY(formatMessage),
];

export const RECOGNITION_VISIBILITIES = (formatMessage) => [
  FEEDBACK_VISIBILITY_EVERYONE(formatMessage),
  FEEDBACK_VISIBILITY_RECIPIENT_ONLY(formatMessage),
  FEEDBACK_VISIBILITY_RECIPIENT_WITH_MANAGER(formatMessage),
];

export const CONSTRUCTIVE_FEEDBACK_VISIBILITIES = (formatMessage) => [
  FEEDBACK_VISIBILITY_RECIPIENT_ONLY(formatMessage),
  FEEDBACK_VISIBILITY_RECIPIENT_WITH_MANAGER(formatMessage),
  FEEDBACK_VISIBILITY_MANAGER_ONLY(formatMessage),
];

export const FEEDBACK_TYPE_RECOGNITION = (formatMessage) => ({
  id: 'E',
  name: 'recognition',
  title: formatMessage({
    id: 'app.models.feedback.recognition.title',
    defaultMessage: 'recognition',
  }),
  heading: formatMessage({
    id: 'app.models.feedback.recognition.heading',
    defaultMessage: 'Give recognition',
  }),
  subheading: formatMessage({
    id: 'app.models.feedback.recognition.subheading',
    defaultMessage: 'appreciation or thanks',
  }),
  icon: consts.ICONS.RECOGNITION,
  description: formatMessage({
    id: 'app.models.feedback.recognition.description',
    defaultMessage:
      'Tell someone that you acknowledge or appreciate something they did.',
  }),
});

export const FEEDBACK_TYPE_ACTIONABLE = (formatMessage) => ({
  id: 'A',
  name: 'feedback',
  title: formatMessage({
    defaultMessage: 'feedback',
    id: 'app.models.feedback.feedback.title',
  }),
  heading: formatMessage({
    id: 'app.models.feedback.feedback.heading',
    defaultMessage: 'Give feedback',
  }),
  subheading: formatMessage({
    id: 'app.models.feedback.feedback.subheading',
    defaultMessage: 'actionable feedback or comments',
  }),
  icon: consts.ICONS.FEEDBACK,
  description: formatMessage({
    id: 'app.models.feedback.feedback.description',
    defaultMessage:
      "Share your thoughts regarding a person's activities, skills, or behaviors.",
  }),
});

export const FEEDBACK_TYPE_REQUEST = (formatMessage) => ({
  id: 'R',
  name: 'request',
  title: formatMessage({
    defaultMessage: 'request',
    id: 'app.models.feedback.request.title',
  }),
  heading: formatMessage({
    id: 'app.models.feedback.request.heading',
    defaultMessage: 'Request feedback',
  }),
  subheading: formatMessage({
    id: 'app.models.feedback.request.subheading',
    defaultMessage: 'for you or someone else',
  }),
  icon: consts.ICONS.REQUEST,
  description: formatMessage({
    id: 'app.models.feedback.request.description',
    defaultMessage:
      "Request feedback on a person's activities, skills, or behaviors. This could be for you or on behalf of someone else.",
  }),
});

export const FEEDBACK_TYPE_NOTE = (formatMessage) => ({
  id: 'N',
  name: 'note',
  title: formatMessage({ defaultMessage: 'note', id: 'app.models.note.title' }),
  heading: formatMessage({
    id: 'app.models.feedback.note.heading',
    defaultMessage: 'Note to self',
  }),
  subheading: formatMessage({
    id: 'app.models.feedback.note.subheading',
    defaultMessage: 'private notes for later',
  }),
  icon: consts.ICONS.NOTE,
  description: formatMessage({
    id: 'app.models.feedback.note.description',
    defaultMessage:
      'Keep track of your observations so you can reference them later. This could be useful for Performance conversations or elsewhere.',
  }),
});

// ordered list of types
export const FEEDBACK_AND_REQUEST_TYPES = (formatMessage) => [
  FEEDBACK_TYPE_RECOGNITION(formatMessage),
  FEEDBACK_TYPE_ACTIONABLE(formatMessage),
  FEEDBACK_TYPE_REQUEST(formatMessage),
  FEEDBACK_TYPE_NOTE(formatMessage),
];

export const FEEDBACK_WITHOUT_REQUESTS_TYPES = (formatMessage) => [
  FEEDBACK_TYPE_RECOGNITION(formatMessage),
  FEEDBACK_TYPE_ACTIONABLE(formatMessage),
  FEEDBACK_TYPE_NOTE(formatMessage),
];

export const FEEDBACK_REQUEST_VISIBILITY_NOT_SET = (formatMessage) => ({
  id: 'N',
  name: formatMessage({
    id: 'app.models.feedback.visibility.not_set',
    defaultMessage: 'No preference',
  }),
  icon: consts.ICONS.UNLOCKED,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.not_set.popover',
    defaultMessage: 'The author of the feedback can decide who can see it.',
  }),
});

export const FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_ONLY = (formatMessage) => ({
  id: 'P',
  name: formatMessage({
    id: 'app.models.feedback.visibility.recipient_only',
    defaultMessage: 'Individual',
  }),
  icon: consts.ICONS.PRIVATE,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.recipient_only.popover',
    defaultMessage: 'Visible to individual and HR administrators',
  }),
});

export const FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_WITH_MANAGER = (
  formatMessage
) => ({
  id: 'W',
  name: formatMessage({
    id: 'app.models.feedback.visibility.recipient_with_manager',
    defaultMessage: 'Individual + manager',
  }),
  icon: consts.ICONS.PRIVATE,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.recipient_with_manager.popover',
    defaultMessage:
      'Visible to individual, their manager and above, and HR administrators',
  }),
});

export const FEEDBACK_REQUEST_VISIBILITY_MANAGER_ONLY = (formatMessage) => ({
  id: 'O',
  name: formatMessage({
    id: 'app.models.feedback.visibility.manager_only',
    defaultMessage: 'Manager only',
  }),
  icon: consts.ICONS.PRIVATE,
  popoverContent: formatMessage({
    id: 'app.models.feedback.visibility.manager_only.popover',
    defaultMessage:
      "Visible to individual's manager and above and HR administrators",
  }),
});

export const ALL_REQUEST_VISIBILITIES = (formatMessage) => [
  FEEDBACK_REQUEST_VISIBILITY_NOT_SET(formatMessage),
  FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_ONLY(formatMessage),
  FEEDBACK_REQUEST_VISIBILITY_RECIPIENT_WITH_MANAGER(formatMessage),
  FEEDBACK_REQUEST_VISIBILITY_MANAGER_ONLY(formatMessage),
];

export const getFeedbackType = (feedback, formatMessage) => {
  if (!feedback || !feedback.type) {
    return FEEDBACK_TYPE_ACTIONABLE(formatMessage); // default
  }

  return FEEDBACK_AND_REQUEST_TYPES(formatMessage).find(
    (s) => s.id === feedback.type
  );
};

export const getFeedbackVisibilityType = (feedback, formatMessage) => {
  if (!feedback) {
    return FEEDBACK_VISIBILITY_EVERYONE(formatMessage); // default
  }

  if (!feedback.visibility) {
    return ALL_VISIBILITIES(formatMessage).find(
      (v) =>
        v.id ===
        getDefaultFeedbackVisibilityValue(feedback?.type, formatMessage)
    );
  }

  return ALL_VISIBILITIES(formatMessage).find(
    (v) => v.id === feedback.visibility
  );
};

export const getFeedbackRequestVisibilityType = (feedback, formatMessage) => {
  if (!feedback?.feedback_visibility) {
    return FEEDBACK_REQUEST_VISIBILITY_NOT_SET(formatMessage); // default
  }

  return ALL_REQUEST_VISIBILITIES(formatMessage).find(
    (v) => v.id === feedback.feedback_visibility
  );
};

export const getDefaultFeedbackVisibilityValue = (
  feedbackType,
  formatMessage
) => {
  if (feedbackType?.id === FEEDBACK_TYPE_RECOGNITION(formatMessage).id) {
    return FEEDBACK_VISIBILITY_EVERYONE(formatMessage).id;
  } else if (feedbackType?.id === FEEDBACK_TYPE_ACTIONABLE(formatMessage).id) {
    return FEEDBACK_VISIBILITY_RECIPIENT_WITH_MANAGER(formatMessage).id;
  } else if (feedbackType?.id === FEEDBACK_TYPE_NOTE(formatMessage).id) {
    return FEEDBACK_VISIBILITY_AUTHOR_ONLY(formatMessage).id;
  } else {
    // visibility doesn't actually do anything for the others, but
    // for consistency default the others to FEEDBACK_VISIBILITY_RECIPIENT_ONLY
    return FEEDBACK_VISIBILITY_RECIPIENT_ONLY(formatMessage).id;
  }
};

export const insertFeedbackIntoActivities = (
  activities,
  feedbackList = null
) => {
  // @ts-expect-error
  if (!(feedbackList?.length > 0)) {
    return activities;
  }

  return activities.map((a) => ({
    ...a,
    feedback: (feedbackList ?? []).filter(
      (f) =>
        // @ts-expect-error
        f.activities && f.activities.findIndex((a2) => a.id === a2.id) !== -1
    ),
  }));
};

export const insertFeedbackSkillsIntoWordCloud = (
  wordObjects,
  feedbackList = null,
  skillTypeId
) => {
  // @ts-expect-error
  if (!(feedbackList?.length > 0)) {
    return wordObjects;
  }

  // NOTE: wordObjects is a list of objects with the following form:
  // - declaredPerson: <undefined or person that declared tag on self>
  // - contributedPerson: <undefined or person that contributed tag>
  // - feedbackPerson: <undefined or person that contributed tag>
  // - contribution: <undefined or single contribution that mentions tag>
  // - feedback: <undefined or single feedback object that mentions tag>
  // - wordObject: <dict with id and name of object>
  // A given tag could have more than one word object; later these
  // word objects will be combined to form the tag cloud, and each
  // instance of the object will count for 1
  const wordsFromFeedback = (feedbackList ?? []).reduce((acc, f) => {
    // @ts-expect-error
    if (!(f.skills?.length > 0)) {
      return acc;
    }

    return acc.concat(
      // @ts-expect-error
      f.skills
        .filter((s) => s.type === skillTypeId)
        .map((tag) => ({
          // @ts-expect-error
          feedbackPerson: f.author_person,
          wordObject: tag,
          feedback: f,
        }))
    );
  }, []);

  // convert feedback list to corresponding wordObject and pass it
  // along
  return [...wordObjects, ...wordsFromFeedback];
};
