import * as consts from '../../consts/consts';

import { FormattedMessage, type IntlShape } from 'react-intl';
import { addMonths, filterUniqueById, getPrettyDate } from '../util/util';

import React from 'react';
import RelativeTime from '../../views/Widgets/RelativeTime';
import { peopleObjectsAreEqual } from './Person';

export const ACTIVITY_DEFAULT_DESCRIPTION =
  '<p><strong>Problem</strong></p><p></p><p><strong>Solution</strong></p><p></p><p><strong>Results</strong></p><p></p>';

export const ACTIVITY_VISIBILITY_EVERYONE = (formatMessage) => ({
  id: 'E',
  name: formatMessage({
    id: 'app.utils.models.activity.visibility.everyone',
    defaultMessage: 'Visible to others',
  }),
  icon: consts.ICONS.PUBLIC,
});

export const ACTIVITY_VISIBILITY_MANAGER_PERSONNEL = (formatMessage) => ({
  id: 'P',
  name: formatMessage({
    id: 'app.utils.models.activity.visibility.manager_personnel',
    defaultMessage: 'Manager and above – personnel matter',
  }),
  icon: consts.ICONS.PRIVATE,
});

export const ACTIVITY_VISIBILITY_MANAGER_CONFIDENTIAL = (formatMessage) => ({
  id: 'C',
  name: formatMessage({
    id: 'app.utils.models.activity.visibility.manager_confidential',
    defaultMessage: 'Manager and above – confidential or unannounced plans',
  }),
  icon: consts.ICONS.PRIVATE,
});

export const ACTIVITY_VISIBILITY_MANAGER_LEGAL = (formatMessage) => ({
  id: 'L',
  name: formatMessage({
    id: 'app.utils.models.activity.visibility.manager_legal',
    defaultMessage: 'Manager and above – legal matter',
  }),
  icon: consts.ICONS.PRIVATE,
});

export const ACTIVITY_VISIBILITY_MANAGER_FINANCIAL = (formatMessage) => ({
  id: 'F',
  name: formatMessage({
    id: 'app.utils.models.activity.visibility.manager_financial',
    defaultMessage: 'Manager and above – financial matter',
  }),
  icon: consts.ICONS.PRIVATE,
});

export const ACTIVITY_VISIBILITY_INDIVIDUAL_MANAGER_AND_ABOVE = (
  formatMessage
) => ({
  id: 'M',
  name: formatMessage({
    id: 'app.utils.models.activity.visibility.individual_manager_and_above',
    defaultMessage: 'Manager and above',
  }),
  icon: consts.ICONS.PRIVATE,
});

export const ACTIVITY_VISIBILITY_LEGACY = (formatMessage) => [
  ACTIVITY_VISIBILITY_MANAGER_PERSONNEL(formatMessage),
  ACTIVITY_VISIBILITY_MANAGER_CONFIDENTIAL(formatMessage),
  ACTIVITY_VISIBILITY_MANAGER_LEGAL(formatMessage),
  ACTIVITY_VISIBILITY_MANAGER_FINANCIAL(formatMessage),
];

export const ACTIVITY_VISIBILITIES = (formatMessage) => [
  ACTIVITY_VISIBILITY_EVERYONE(formatMessage),
  ACTIVITY_VISIBILITY_INDIVIDUAL_MANAGER_AND_ABOVE(formatMessage),
];

export const ACTIVITY_VISIBILITIES_WITH_LEGACY = (formatMessage) => [
  ...ACTIVITY_VISIBILITIES(formatMessage),
  ...ACTIVITY_VISIBILITY_LEGACY(formatMessage),
];

export const fromLegacyVisibilityToSimplified = (
  visibility: string | undefined,
  formatMessage: IntlShape['formatMessage']
): string | undefined => {
  const matchedLegacyVisibility = ACTIVITY_VISIBILITY_LEGACY(
    formatMessage
  ).find((v) => v.id === visibility)?.id;

  if (matchedLegacyVisibility) {
    return ACTIVITY_VISIBILITY_INDIVIDUAL_MANAGER_AND_ABOVE(formatMessage).id;
  }

  return visibility;
};

// ids for these stages should match the backend stage ids
export const ACTIVITY_TYPE_ACTIVITY = ({ formatMessage }) => ({
  id: 'A',
  name: 'activity',
  heading: formatMessage({
    id: 'app.utils.models.activity.type.activity.heading',
    defaultMessage: 'Activity',
  }),
  icon: consts.ICONS.ACTIVITY,
});

export const ACTIVITY_TYPE_ACCOMPLISHMENT = ({ formatMessage }) => ({
  id: 'C',
  name: 'accomplishment',
  heading: formatMessage({
    id: 'app.utils.models.activity.type.accomplishment.heading',
    defaultMessage: 'Accomplishment',
  }),
  subheading: formatMessage({
    id: 'app.utils.models.activity.type.accomplishment.sub_heading',
    defaultMessage: 'award or achievement',
  }),
  icon: 'fe fe-award',
  description: (
    <>
      <div className="fw-bold">
        <FormattedMessage
          id="app.utils.models.activity.type.accomplishment.description"
          defaultMessage="An accomplishment is a recognizable award or achievement."
        />
      </div>
      <div>
        <FormattedMessage
          id="app.utils.models.activity.type.accomplishment.description.example"
          defaultMessage="Examples of accomplishments include a company presentation, an award
          from a manager or group, winning a contract, or making a key hire."
        />
      </div>
    </>
  ),
});

export const ACTIVITY_TYPE_PROJECT = ({ formatMessage }) => ({
  id: 'P',
  name: 'project',
  heading: formatMessage({
    id: 'app.utils.models.activity.type.project.heading',
    defaultMessage: 'Project',
  }),
  subheading: formatMessage({
    id: 'app.utils.models.activity.type.project.sub_heading',
    defaultMessage: 'work or experience',
  }),
  icon: consts.ICONS.ACTIVITY,
  description: (
    <>
      <div className="fw-bold">
        <FormattedMessage
          id="app.utils.models.activity.type.project.description"
          defaultMessage="A project is a unit of work that has a defined outcome and a beginning and end."
        />
      </div>
      <div>
        <FormattedMessage
          id="app.utils.models.activity.type.project.description.example"
          defaultMessage="Examples of projects include a sales campaign, a product release, a process implementation, or a company event."
        />
      </div>
    </>
  ),
});

export const ACTIVITY_TYPE_LEARNING = ({ formatMessage }) => ({
  id: 'L',
  name: 'learning',
  heading: formatMessage({
    id: 'app.utils.models.activity.type.learning.heading',
    defaultMessage: 'Learning',
  }),
  subheading: formatMessage({
    id: 'app.utils.models.activity.type.learning.sub_heading',
    defaultMessage: 'lesson or discovery',
  }),
  icon: 'fe fe-book',
  description: (
    <>
      <div className="fw-bold">
        <FormattedMessage
          id="app.utils.models.activity.type.learning.description"
          defaultMessage="A learning is a lesson, discovery, or new knowledge."
        />
      </div>
      <div>
        <FormattedMessage
          id="app.utils.models.activity.type.learning.description.example"
          defaultMessage="Examples of learnings include acquiring a new skill or technique, learning a new process, or taking a class or workshop."
        />
      </div>
    </>
  ),
});

export const ACTIVITY_TYPE_METRICS = ({ formatMessage }) => ({
  id: 'M',
  name: 'metrics',
  heading: formatMessage({
    id: 'app.utils.models.activity.type.metrics.heading',
    defaultMessage: 'Metrics',
  }),
  subheading: formatMessage({
    id: 'app.utils.models.activity.type.metrics.sub_heading',
    defaultMessage: 'numbers or statistics',
  }),
  icon: 'fe fe-trending-up',
  description: (
    <>
      <div className="fw-bold">
        <FormattedMessage
          id="app.utils.models.activity.type.metrics.description"
          defaultMessage="Metrics are one or more numbers or statistics."
        />
      </div>
      <div>
        <FormattedMessage
          id="app.utils.models.activity.type.metrics.description.example"
          defaultMessage="Examples of metrics include value of sales contracts won in a month or quarter, number of customer tickets resolved, or number of reports delivered."
        />
      </div>
    </>
  ),
});

// ordered list of types
export const ACTIVITY_TYPES = (intl) => [
  ACTIVITY_TYPE_PROJECT(intl),
  ACTIVITY_TYPE_ACCOMPLISHMENT(intl),
  ACTIVITY_TYPE_LEARNING(intl),
  ACTIVITY_TYPE_METRICS(intl),
];

export const getActivityType = (activity, intl) => {
  if (!activity || !activity.type) {
    return ACTIVITY_TYPE_ACTIVITY(intl); // default
  }

  return [ACTIVITY_TYPE_ACTIVITY(intl), ...ACTIVITY_TYPES(intl)].find(
    (s) => s.id === activity.type
  );
};

// ids for these stages should match the backend stage ids
export const ACTIVITY_STAGE_OPPORTUNITY = {
  id: 'O',
  name: 'Opportunity',
  suffix: ' Opportunity',
  icon: 'cis-lightbulb',
};

export const ACTIVITY_STAGE_LIVE = {
  id: 'L',
  name: 'Live',
  icon: consts.ICONS.ACTIVITY,
};

export const ACTIVITY_STAGE_ARCHIVED = {
  id: 'A',
  name: 'Archived',
  prefix: 'Archived ',
  icon: 'fe fe-archive',
};

// ordered list of stages
export const ACTIVITY_STAGES = [
  ACTIVITY_STAGE_OPPORTUNITY,
  ACTIVITY_STAGE_LIVE,
  ACTIVITY_STAGE_ARCHIVED,
];

export const getActivityStage = (activity) => {
  if (!activity || !activity.stage) {
    return ACTIVITY_STAGE_LIVE; // default
  }

  return ACTIVITY_STAGES.find((s) => s.id === activity.stage);
};

export const getContributionPerson = (contribution) => {
  if (!contribution) {
    return null;
  }

  return contribution.contributor_person;
};

export const getMyContributionOrContributionComment = (
  personId,
  contribution
) => {
  const person = getContributionPerson(contribution);
  if (person?.id === personId) {
    return {
      ...contribution,
      skills: contribution.skills ? contribution.skills : [],
    };
  } else {
    const contributionComment = contribution?.contribution_comments?.find(
      (cc) => cc.author_person?.id === personId
    );

    if (contributionComment) {
      return {
        ...contributionComment,
        id: contribution.id,
        skills: contributionComment?.skills ? contributionComment.skills : [],
      };
    } else {
      // nonexistent, so return blank contribution comment
      return {
        id: contribution?.id,
        contributor_role: null,
        description: '',
        skills: [],
      };
    }
  }
};

export const contributionMatchesPerson = (contribution, person) => {
  // only match based on top level person (not contribution comments person)
  // as we don't want to show, for example, someone's comments on their profile
  return peopleObjectsAreEqual(getContributionPerson(contribution), person);
};

const contributionMatchesObjectById = (
  contributionsInput,
  contributionAndCommentFields,
  object
) => {
  if (!contributionsInput || !object) {
    return false;
  }

  // if contributions isn't an array, assume it's just one contribution and convert it to an array
  const contributions = Array.isArray(contributionsInput)
    ? contributionsInput
    : [contributionsInput];

  // go through contribution AND all comments and if any have it tagged at top level
  // or as part of a comment, this is a match
  return (
    contributions &&
    contributions.findIndex((c) => {
      // top level array has object in it (matched by id)
      if (
        c[contributionAndCommentFields] &&
        c[contributionAndCommentFields]?.findIndex(
          (obj) => obj.id === object.id
        ) !== -1
      ) {
        return true;
      }

      // one of the contribution comments' arrays has object in it (matched by id)
      return (
        c?.contribution_comments &&
        c.contribution_comments.findIndex(
          (cc) =>
            cc[contributionAndCommentFields] &&
            cc[contributionAndCommentFields].findIndex(
              (obj) => obj.id === object.id
            ) !== -1
        ) !== -1
      );
    }) !== -1
  );
};

export const contributionMatchesSkill = (contribution, skill) => {
  return contributionMatchesObjectById(contribution, 'skills', skill);
};

export const contributionMatchesCredential = (contribution, credential) => {
  return contributionMatchesObjectById(contribution, 'credentials', credential);
};

const getContributionsForObject = (
  activitiesInput,
  object,
  contributionMatchesObjectFunction
) => {
  if (!activitiesInput || !object) {
    return [];
  }

  // if activity isn't an array, assume it's just one activity and convert it to an array
  const activities = Array.isArray(activitiesInput)
    ? activitiesInput
    : [activitiesInput];

  // go through activities and get contributions for relevant person, reduced to an array
  return activities.reduce((accu, activity) => {
    return accu.concat(
      activity.contributions
        .filter((c) => contributionMatchesObjectFunction(c, object))
        .map((c) => ({
          ...c,
          activity: {
            id: activity.id,
            name: activity.name,
          },
        }))
    );
  }, []);
};

export const getContributionsForPerson = (activities, person) => {
  return getContributionsForObject(
    activities,
    person,
    contributionMatchesPerson
  );
};

export const getContributionsForSkill = (activities, skill) => {
  return getContributionsForObject(activities, skill, contributionMatchesSkill);
};

export const getContributionsForCredential = (activities, credential) => {
  return getContributionsForObject(
    activities,
    credential,
    contributionMatchesCredential
  );
};

export const getUniqueSkillWordCloudsFromContribution = (
  contributions,
  showActivitiesInsteadOfPeople = false,
  skillType = null
) => {
  if (!contributions || contributions.length === 0) {
    return [];
  }

  const filterSkillByType = skillType
    ? // @ts-expect-error
      (s) => s.type === skillType.id
    : () => true;

  return contributions.reduce((acc, c) => {
    const contributionSkills = c?.skills?.filter(filterSkillByType);

    return acc.concat(
      contributionSkills?.filter(filterSkillByType)
        ? // ensure skills only mentioned once for a given
          // contributor
          filterUniqueById(
            contributionSkills.concat(
              c.contribution_comments
                ? c.contribution_comments.reduce(
                    (arr, cc) =>
                      arr.concat(cc.skills.filter(filterSkillByType)),
                    []
                  )
                : []
            )
          ).map((skill) => ({
            wordObject: skill,
            contributedPerson: showActivitiesInsteadOfPeople
              ? undefined
              : getContributionPerson(c),
            contribution: showActivitiesInsteadOfPeople ? c : undefined,
          }))
        : []
    );
  }, []);
};

export const getMostRecentUpdateDate = (contribution) => {
  if (!contribution?.updated_at) {
    return null;
  }

  const latestDate = new Date(contribution.updated_at);

  if (!(contribution?.contribution_comments?.length > 0)) {
    return latestDate;
  }

  const latestComment = getLatestNonEmptyContributionFromList(
    contribution.contribution_comments
  );

  if (!latestComment) {
    return latestDate;
  }

  if (latestDate > latestComment) {
    return latestDate;
  } else {
    return new Date(latestComment.updated_at);
  }
};

const isSameDate = (a, b) => {
  return !(a > b || a < b);
};

export const compareActivityDates = (a, b) => {
  const aCompleted = new Date(a.date_completed);
  const bCompleted = new Date(b.date_completed);
  const aStarted = new Date(a.date_started);
  const bStarted = new Date(b.date_started);
  // per search_queries.py sort order from Elasticsearch is descending in these priorities:
  // date_completed, date_started, created_at
  if (isSameDate(aCompleted, bCompleted)) {
    if (isSameDate(aStarted, bStarted)) {
      // @ts-expect-error
      return new Date(b.created_at) - new Date(a.created_at);
    } else {
      // @ts-expect-error
      return bStarted - aStarted;
    }
  } else {
    // @ts-expect-error
    return bCompleted - aCompleted;
  }
};

export const compareContributionDates = (a, b) => {
  // @ts-expect-error
  return getMostRecentUpdateDate(b) - getMostRecentUpdateDate(a);
};

export const contributionIsClaimed = (contribution) => {
  return !!(contribution?.contributor_role?.length > 0);
};

export const contributionIsEmpty = (
  contribution,
  requireNoCommentsToBeConsideredEmpty = true
) => {
  const isEmpty =
    !(contribution?.description?.length > 0) &&
    !(contribution?.contributor_role?.length > 0);

  if (!requireNoCommentsToBeConsideredEmpty) {
    return isEmpty;
  }

  return isEmpty && !(contribution?.contribution_comments?.length > 0);
};

export const getLatestNonEmptyContributionFromList = (contributions) => {
  if (!(contributions?.length > 0)) {
    return null;
  }

  return contributions
    .filter((c) => !contributionIsEmpty(c))
    .sort(compareContributionDates)[0];
};

export const getContributorRole = (activity, contributorPerson) => {
  // sort contributions newest to oldest to ensure that role provided is closest
  // to comment that provided it (and we take the newest role when the contributor
  // has not provided the role)

  const contribution = activity?.contributions?.find((c) =>
    peopleObjectsAreEqual(getContributionPerson(c), contributorPerson)
  );

  if (!contribution) {
    return null;
  }

  if (contribution?.contributor_role) {
    return contribution.contributor_role;
  }

  // get role from the first contribution comment's mentioned role for this person
  const sortedContributionComments = contribution?.contribution_comments?.sort(
    (a, b) => a.created_at - b.created_at
  );

  if (sortedContributionComments?.length > 0) {
    return sortedContributionComments[0].contributor_role;
  }

  return null;
};
export const getActivityVisibilityType = (activity, formatMessage) => {
  const visibilityObject = ACTIVITY_VISIBILITIES_WITH_LEGACY(
    formatMessage
  ).find((v) => v.id === activity.visibility);

  return visibilityObject ?? ACTIVITY_VISIBILITY_EVERYONE(formatMessage);
};

export const getActivityPermissionsSubtitle = (activity, formatMessage) => {
  if (
    !activity?.visibility ||
    activity.visibility === ACTIVITY_VISIBILITY_EVERYONE(formatMessage).id
  ) {
    return '';
  }

  const visibilityObject = ACTIVITY_VISIBILITIES_WITH_LEGACY(
    formatMessage
  ).find((v) => v.id === activity.visibility);

  if (!visibilityObject) {
    return '';
  }

  return <i className={visibilityObject.icon + ' me-2'} />;
};

export const getActivityDateSubtitle = (
  activity,
  showRelativeTime = false,
  locale
) => {
  const willBeCompletedInFuture =
    activity?.date_completed && new Date(activity.date_completed) > new Date();

  if (showRelativeTime) {
    return activity?.date_completed ? (
      <>
        {willBeCompletedInFuture ? (
          <FormattedMessage
            id="app.utils.models.activity.completed.future.relative.date.subtitle"
            defaultMessage="Will be completed {relativeDateFromToday}"
            values={{
              relativeDateFromToday: (
                <RelativeTime unit="day" datetime={activity.date_completed} />
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="app.utils.models.activity.completed.past.relative.date.subtitle"
            defaultMessage="Completed {relativeDateFromToday}"
            values={{
              relativeDateFromToday: (
                <RelativeTime unit="day" datetime={activity.date_completed} />
              ),
            }}
          />
        )}
      </>
    ) : (
      <>
        <FormattedMessage
          id="app.utils.models.activity.started.relative.date.subtitle"
          defaultMessage="Started {relativeDateFromToday}"
          values={{
            relativeDateFromToday: (
              <RelativeTime unit="day" datetime={activity.date_started} />
            ),
          }}
        />
      </>
    );
  } else {
    if (activity?.date_completed) {
      if (willBeCompletedInFuture) {
        return (
          <FormattedMessage
            id="app.utils.models.activity.not_completed.absolute.date.subtitle"
            defaultMessage="Will be completed on {absoluteDate}"
            values={{
              absoluteDate: getPrettyDate({
                dateString: activity?.date_completed,
                locale,
              }),
            }}
          />
        );
      } else {
        return (
          <FormattedMessage
            id="app.utils.models.activity.completed.absolute.date.subtitle"
            defaultMessage="Completed on {absoluteDate}"
            values={{
              absoluteDate: getPrettyDate({
                dateString: activity?.date_completed,
                locale,
              }),
            }}
          />
        );
      }
    } else {
      return (
        <FormattedMessage
          id="app.utils.models.activity.started.absolute.date.subtitle"
          defaultMessage="Started on {absoluteDate}"
          values={{
            absoluteDate: getPrettyDate({
              dateString: activity?.date_started,
              locale,
            }),
          }}
        />
      );
    }
  }
};

export const getQuarterDate = (date, getEndDate = false) => {
  // calculate quarter number
  const quarter = Math.floor(date.getMonth() / 3);

  const startFullQuarter = new Date(date.getFullYear(), quarter * 3, 1);
  const endFullQuarter = new Date(
    startFullQuarter.getFullYear(),
    startFullQuarter.getMonth() + 3,
    0
  );

  if (getEndDate) {
    return endFullQuarter;
  } else {
    return startFullQuarter;
  }
};

export const getQuarter = (dateOrDateString) => {
  // Parses a given date into Quarter label (ie. 'Q4 2021')

  let dateObj = dateOrDateString;
  if (typeof dateOrDateString === 'string') {
    dateObj = new Date(dateOrDateString);

    // If date is invalid, return the original string
    if (isNaN(dateObj)) {
      return dateOrDateString;
    }
  }
  const month = dateObj.getMonth() + 1;
  const quarter = Math.ceil(month / 3);

  return 'Q' + quarter.toString() + ' ' + dateObj.getFullYear().toString();
};

export const getTimeframeText = (
  dateOrDateString,
  futureEventsShouldBeSetToCurrentQuarter = true
) => {
  // anything without a completion date is considered as happening right now,
  // so use date completed or, if it doesn't exist, use now as the date
  // use date completed if it exists, else date started, else "Unknown"
  // this function will return the datestring of the last day in the quarter
  const now = new Date();
  if (dateOrDateString) {
    const date =
      dateOrDateString instanceof Date
        ? dateOrDateString
        : new Date(dateOrDateString);
    if (futureEventsShouldBeSetToCurrentQuarter && date > now) {
      return getLastDateOfQuarter(now);
    } else {
      return getLastDateOfQuarter(date);
    }
  } else {
    return getLastDateOfQuarter(now);
  }
};

export const getTimeframeTextDict = (dateJoined) => {
  // get a list of quarters from the beginning of time as a dictionary of empty arrays,
  // or the past 12 months if beginning is not provided
  // TODO
  let currentDate = new Date();
  const startDate = dateJoined
    ? new Date(dateJoined)
    : addMonths(currentDate, -12);

  const quartersDict = {};

  while (currentDate > startDate) {
    // @ts-expect-error
    quartersDict[getTimeframeText(currentDate)] = [];
    currentDate = addMonths(currentDate, -3);
  }
  // Adding the very last quarter to ensure complete coverage
  // @ts-expect-error
  quartersDict[getTimeframeText(currentDate)] = [];

  return quartersDict;
};

export const getLastDateOfQuarter = (date) => {
  // get the last date of a given quarter
  // (default end date for activities added during perf)

  // get the last date of the quarter by going to the start
  // of next quarter, and subtracting 1 day
  if (date) {
    const month = date.getMonth() + 1;
    const quarter = Math.ceil(month / 3);
    let nextMonth = +quarter * 3;
    const year = date.getFullYear();

    const nextMonthStart = new Date(year, nextMonth--);
    nextMonthStart.setDate(nextMonthStart.getDate() - 1);
    return nextMonthStart;
  }
};
