import { useConfirmApi } from 'utils/api/ApiHooks';

export const INFINITY = Number.MAX_SAFE_INTEGER;
export const INFINITY_SYMBOL = '∞';

const DEFAULT_AXIS_SIZE = 3;

export const DEFAULT_3x3_GRID = [
  {
    x: 0,
    y: 0,
    title: 'Bad hires',
  },
  {
    x: 1,
    y: 0,
    title: 'Up or out grinders',
  },
  {
    x: 2,
    y: 0,
    title: 'Workhorses',
  },
  {
    x: 0,
    y: 1,
    title: 'Up or out dilemmas',
  },
  {
    x: 1,
    y: 1,
    title: 'Core players',
  },
  {
    x: 2,
    y: 1,
    title: 'High performers',
  },
  {
    x: 0,
    y: 2,
    title: 'Dysfunctional geniuses',
  },
  { x: 1, y: 2, title: 'High potentials' },
  { x: 2, y: 2, title: 'Stars' },
];

export interface TalentMatrixGridCellProps {
  title: string;
  x: number;
  y: number;
}

export interface TalentMatrixAxisSettings {
  x_axis_label: string;
  x_axis_buckets: [string, string][];
  x_axis_metric: string;
  x_axis_metric_description: string;
  x_axis_order_reversed: boolean;
  y_axis_label: string;
  y_axis_buckets: [string, string][];
  y_axis_metric: string;
  y_axis_metric_description: string;
  y_axis_order_reversed: boolean;
}
export interface TalentMatrixCampaignModel {
  x_axis_label: string;
  x_axis_buckets: [string, string][];
  x_axis_metric: string;
  x_axis_metric_description: string;
  x_axis_order_reversed: boolean;
  y_axis_label: string;
  y_axis_buckets: [string, string][];
  y_axis_metric: string;
  y_axis_metric_description: string;
  y_axis_order_reversed: boolean;
  rows: number;
  columns: number;
  quadrants: TalentMatrixGridCellProps[];
}

export interface AvailableMetricConfig {
  id: string;
  name: string;
  possible_values: string[];
  possible_labels: string[];
  default_reverse_order: boolean;
}

export interface GetCampaignTalentMatricesApiResponse {
  available_metrics: AvailableMetricConfig[];
  x_axis_default_metric: AvailableMetricConfig;
  y_axis_default_metric: AvailableMetricConfig;
}

export const useTalentMatrixConfiguration = ({
  campaignId,
  disabled,
}: {
  campaignId?: number;
  disabled: boolean;
}) => {
  const apiCall = useConfirmApi<GetCampaignTalentMatricesApiResponse>({
    url: `campaigns/${campaignId}/talent-matrices`,
    method: 'GET',
    disabled: !campaignId || disabled,
  });

  return apiCall;
};

export const removeTrailingDecimalZeros = (number: string): string =>
  number.replace(/([^.])0+$/, '$1').replace(/\.0$/, '');

export const renderNumber = (number: string): string => {
  return removeTrailingDecimalZeros(
    String(INFINITY) === number ? INFINITY_SYMBOL : number
  );
};

export const nearestNextSegmentStart = (
  value: string,
  isReversed: boolean
): string => {
  return removeTrailingDecimalZeros(
    (Number(value) + (isReversed ? 0.01 : -0.01)).toFixed(2)
  );
};

export const hasNoUpperBound = (possibleValues: string[]): boolean => {
  return (
    possibleValues.findIndex(
      (it) => removeTrailingDecimalZeros(it) === String(INFINITY)
    ) >= 0
  );
};

export const calculateMaxBucketCount = (
  metric: string,
  availableMetrics: AvailableMetricConfig[]
) => {
  const possibleValues = availableMetrics.find(
    (it) => it.id === metric
  )!.possible_values;

  // Do not put a limit in case there's no upper bound in the possible elements
  if (hasNoUpperBound(possibleValues)) {
    return INFINITY;
  }
  // otherwise returns the number of possible values
  return possibleValues.length;
};

const calculateDefaultAxisSize = (metricConfig: AvailableMetricConfig) => {
  if (hasNoUpperBound(metricConfig.possible_values)) {
    return DEFAULT_AXIS_SIZE;
  }
  return Math.min(DEFAULT_AXIS_SIZE, metricConfig.possible_values.length);
};

export const calculateBuckets = (
  possible_values: string[],
  numberOfbuckets: number,
  isReversed: boolean
): [string, string][] => {
  const [min, max]: [number, number] = possible_values.reduce(
    ([min, max], value) => {
      const number = Number(value);
      return [Math.min(min, number), Math.max(max, number)];
    },
    [0, 0]
  );
  const isNotUpperBound = max === INFINITY;

  if (isNotUpperBound) {
    const buckets: [string, string][] = Array.from(
      Array(numberOfbuckets).keys()
    ).map((i) => {
      if (isReversed) {
        const lowerBound = removeTrailingDecimalZeros(
          (min + i + (i === 0 ? 0 : 0.01)).toFixed(2)
        );
        const upperBound = removeTrailingDecimalZeros(
          (numberOfbuckets - 1 === i ? max : min + i + 1).toFixed(2)
        );
        return [lowerBound, upperBound];
      }
      const lowerBound = removeTrailingDecimalZeros((min + i).toFixed(2));
      const upperBound = removeTrailingDecimalZeros(
        (numberOfbuckets - 1 === i ? max : min + i + 1 - 0.01).toFixed(2)
      );
      return [lowerBound, upperBound];
    });
    return isReversed ? flipAndReverse(buckets) : buckets;
  }

  // discrete case, split in n balanced segments and take max and min
  const buckets: [string, string][] = chunkArray(
    possible_values,
    numberOfbuckets
  ).map((bucket) => [
    removeTrailingDecimalZeros(bucket[0]),
    removeTrailingDecimalZeros(bucket[bucket.length - 1]),
  ]);
  return isReversed ? flipAndReverse(buckets) : buckets;
};

const flipAndReverse = (buckets: [string, string][]): [string, string][] => {
  const flipped: [string, string][] = buckets.map(([lower, upper]) => [
    upper,
    lower,
  ]);
  return flipped.reverse();
};

const chunkArray = (array: string[], n: number): string[][] => {
  const len = array.length;
  const chunkSize = Math.floor(len / n);
  const remainder = len % n;
  const chunks: string[][] = [];
  let start = 0;
  for (let i = 0; i < n; i++) {
    const end = start + chunkSize + (i < remainder ? 1 : 0);
    chunks.push(array.slice(start, end));
    start = end;
  }
  return chunks;
};

export const generateDefaultTalentMatrix = (
  data: GetCampaignTalentMatricesApiResponse
): TalentMatrixCampaignModel => {
  const defaultColumns = calculateDefaultAxisSize(data.x_axis_default_metric);
  const defaultRows = calculateDefaultAxisSize(data.y_axis_default_metric);
  return {
    x_axis_label: 'Performance',
    x_axis_buckets: calculateBuckets(
      data.x_axis_default_metric.possible_values,
      defaultColumns,
      data.x_axis_default_metric.default_reverse_order
    ),
    x_axis_metric: data.x_axis_default_metric.id,
    x_axis_metric_description: data.x_axis_default_metric.name,
    x_axis_order_reversed: data.x_axis_default_metric.default_reverse_order,
    y_axis_label: 'Potential',
    y_axis_buckets: calculateBuckets(
      data.y_axis_default_metric.possible_values,
      defaultRows,
      data.y_axis_default_metric.default_reverse_order
    ),
    y_axis_metric: data.y_axis_default_metric.id,
    y_axis_metric_description: data.y_axis_default_metric.name,
    y_axis_order_reversed: data.y_axis_default_metric.default_reverse_order,
    rows: defaultRows,
    columns: defaultColumns,
    quadrants: DEFAULT_3x3_GRID,
  };
};
