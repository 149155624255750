import {
  AvailableMetricConfig,
  TalentMatrixAxisSettings,
  calculateBuckets,
  calculateMaxBucketCount,
} from '../TalentMatrix';
import { Col, Row } from 'reactstrap';
import React, { FC, useCallback } from 'react';

import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import ValidatedForm from 'views/Widgets/Forms/ValidatedForm';

interface Props {
  axisSettings: TalentMatrixAxisSettings;
  onControlsChange: (value: TalentMatrixAxisSettings) => void;
  availableMetrics: AvailableMetricConfig[];
  columns: number;
  rows: number;
  onResizeColumns: (newCulumnsSize: number, newMaxColumns: number) => void;
  onResizeRows: (newRowsSize: number, newMaxRows: number) => void;
}

const AxisEditor: FC<Props> = ({
  axisSettings,
  onControlsChange,
  availableMetrics,
  columns,
  rows,
  onResizeColumns,
  onResizeRows,
}) => {
  const handleCallback = useCallback(() => null, []);

  const handleOnChangeMetricSideEffects = useCallback(
    (bucketsField, descriptionField, orderField, axisSize, onResizeAxisSize) =>
      (_currentObject, valueChanged) => {
        const selectedMetric = availableMetrics.find(
          (it) => it.id === valueChanged
        )!;
        const newMaxAxisSize = calculateMaxBucketCount(
          selectedMetric.id,
          availableMetrics
        );
        const newAxisSize = Math.min(axisSize, newMaxAxisSize);

        onResizeAxisSize(newAxisSize, newMaxAxisSize);

        const buckets = calculateBuckets(
          selectedMetric.possible_values,
          newAxisSize,
          selectedMetric.default_reverse_order
        );

        return {
          [bucketsField]: buckets,
          [orderField]: selectedMetric.default_reverse_order,
          [descriptionField]: selectedMetric.name,
        };
      },
    [availableMetrics]
  );

  const handleOnChangeReverseOrderSideEffects = useCallback(
    (metricField, bucketsField, axisSize) => (currentObject, valueChanged) => {
      const selectedMetric = availableMetrics.find(
        (it) => it.id === currentObject[metricField]
      )!;
      const buckets = calculateBuckets(
        selectedMetric.possible_values,
        axisSize,
        valueChanged
      );

      return {
        [bucketsField]: buckets,
      };
    },
    [availableMetrics]
  );
  const renderInputs = useCallback(
    ([xLabel, xMetric, xOrder, yLabel, yMetric, yOrder]) => {
      return (
        <>
          <Row className="col-12">
            <Col className="col-3" key="x_metric">
              {xMetric}
            </Col>
            <Col className="col-3" key="x_label">
              {xLabel}
            </Col>
            <Col className="col-auto" key="x_order">
              {xOrder}
            </Col>
          </Row>
          <Row className="col-12">
            <Col className="col-3" key="y_metric">
              {yMetric}
            </Col>
            <Col className="col-3" key="y_label">
              {yLabel}
            </Col>
            <Col className="col-auto" key="y_order">
              {yOrder}
            </Col>
          </Row>
        </>
      );
    },
    []
  );
  return (
    <ValidatedForm
      object={axisSettings}
      callback={handleCallback}
      onChange={onControlsChange}
      hideSubmitButton={true}
      className={'row'}
      renderInputs={renderInputs}
      inputs={[
        {
          name: 'x_axis_label',
          label: 'X-axis label',
          type: INPUT_TYPES.TEXT,
          className: 'w-100',
        },
        {
          name: 'x_axis_metric',
          label: 'X-axis metric',
          className: 'd-grid w-100',
          type: INPUT_TYPES.DROPDOWN,
          objects: availableMetrics,
          onChangeSideEffects: handleOnChangeMetricSideEffects(
            'x_axis_buckets',
            'x_axis_metric_description',
            'x_axis_order_reversed',
            columns,
            onResizeColumns
          ),
        },
        {
          name: 'x_axis_order_reversed',
          label: 'X-axis order',
          type: INPUT_TYPES.SWITCH,
          className: 'mt-2',
          switchLabel: 'Reverse order',
          switchLabelClassName: 'mt-2',
          enableHelp: true,
          hoverHelperText: 'Reverse order (high on left, low on right)',
          onChangeSideEffects: handleOnChangeReverseOrderSideEffects(
            'x_axis_metric',
            'x_axis_buckets',
            columns
          ),
        },
        {
          name: 'y_axis_label',
          label: 'Y-axis label',
          type: INPUT_TYPES.TEXT,
        },
        {
          name: 'y_axis_metric',
          label: 'Y-axis metric',
          className: 'd-grid w-100',
          type: INPUT_TYPES.DROPDOWN,
          objects: availableMetrics,
          onChangeSideEffects: handleOnChangeMetricSideEffects(
            'y_axis_buckets',
            'y_axis_metric_description',
            'y_axis_order_reversed',
            rows,
            onResizeRows
          ),
        },
        {
          name: 'y_axis_order_reversed',
          label: 'Y-axis order',
          type: INPUT_TYPES.SWITCH,
          className: 'mt-2',
          switchLabel: 'Reverse order',
          switchLabelClassName: 'mt-2',
          enableHelp: true,
          hoverHelperText: 'Reverse order (high on bottom, low on top)',
          onChangeSideEffects: handleOnChangeReverseOrderSideEffects(
            'y_axis_metric',
            'y_axis_buckets',
            rows
          ),
        },
      ]}
    />
  );
};

export default AxisEditor;
