import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Person, Skill } from '../../../types';
import React, { FC } from 'react';

import { CommentGroup } from './CommentGroup';
import TagsList from '../../Widgets/TagsList';
import { getAttributedOrUnattributedPersonHeading } from '../../../utils/models/Campaign';

interface PersonProps {
  index?: number;
  author?: string | Person;
  type?: string;
  text?: string;
  skills?: Array<Skill>;
  anonymous?: boolean;
  last?: boolean;
}

const PersonResponseRow: FC<PersonProps> = ({
  index,
  author,
  type,
  text,
  skills,
  anonymous,
  last,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Row key={index}>
      <Col>
        <CommentGroup author={author as Person} noMargin={last}>
          {getAttributedOrUnattributedPersonHeading(
            formatMessage,
            author,
            index,
            type,
            anonymous
          )}
          {text ? (
            <div className="text-dark mb-0 py-1">{text}</div>
          ) : (
            <div className="fst-italic mb-0 py-1">
              <FormattedMessage
                id="app.views.person_response_row.no_comments_provided"
                defaultMessage="No comments provided"
              />
            </div>
          )}
          {!!skills?.length && (
            <TagsList skills={skills} isExternalUrl={true} />
          )}
        </CommentGroup>
      </Col>
    </Row>
  );
};

PersonResponseRow.defaultProps = {
  anonymous: false,
};

export default PersonResponseRow;
