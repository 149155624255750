import {
  Button,
  Col,
  Form,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AutoSaveInfoBox from './AutoSaveInfoBox';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import PropTypes from 'prop-types';
import Spinner from '../../../components/Spinner';
import { connect } from 'react-redux';
import { getFriendlyUserFacingErrorObjectAndMessage } from '../../../utils/util/util';
import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';

const ConfirmForm = (props) => {
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] =
    useState(false);
  const toggleConfirmationDialog = useCallback(
    () => setConfirmationDialogIsOpen(!confirmationDialogIsOpen),
    [confirmationDialogIsOpen]
  );

  const propsObject = props.object;
  const propsPreSubmit = props.preSubmit;
  const propsCallback = props.callback;
  const propsDoNotSendOrganization = props.doNotSendOrganization;
  const propsCurrentOrganizationId = props.currentOrganizationId;
  const propsOnValidate = props.onValidate;
  const propsTransformObjectBeforeSubmit = props.transformObjectBeforeSubmit;
  const propsMethod = props.method;
  const propsUrl = props.url;
  const propsAction = props.action;
  const propsShowErrorsAsToasts = props.showErrorsAsToasts;
  const propsRenderValidationErrors = props.renderValidationErrors;
  const propsDisabledHoverText = props.disabledHoverText;
  const propsOnIsSubmittingChange = props.onIsSubmittingChange;

  const preSubmit = useCallback(() => {
    // clear any previous message
    setMessage(null);

    setIsSubmitting(true);
    if (typeof propsOnIsSubmittingChange !== 'undefined') {
      propsOnIsSubmittingChange(true);
    }
    if (typeof propsPreSubmit !== 'undefined') {
      propsPreSubmit();
    }
  }, [propsPreSubmit, propsOnIsSubmittingChange]);

  const onSubmitCallback = useCallback(
    (data, error, hardErrorMessage = null) => {
      setIsSubmitting(false);
      if (typeof propsOnIsSubmittingChange !== 'undefined') {
        propsOnIsSubmittingChange(false);
      }

      // if there's an additional confirmation modal, close that regardless
      // of the result so it isn't stuck hanging
      setConfirmationDialogIsOpen(false);

      // failure; keep modal open
      if (error || hardErrorMessage) {
        const [errorObject, friendlyErrorMessage] =
          getFriendlyUserFacingErrorObjectAndMessage(error, hardErrorMessage);
        setMessage(friendlyErrorMessage);
        setIsError(true);
        if (typeof propsCallback !== 'undefined') {
          propsCallback(null, errorObject, friendlyErrorMessage);
        }
      } else {
        // success; call back and toggle modal
        setMessage(null);
        setIsError(false);
        if (typeof propsCallback !== 'undefined') {
          propsCallback(data);
        }
      }
    },
    [propsCallback, propsOnIsSubmittingChange]
  );

  const handleSubmit = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      // insert the user's current organization into the object to be sent
      const obj = {
        ...propsObject,
        ...(propsDoNotSendOrganization
          ? {}
          : { organization: propsCurrentOrganizationId }),
      };

      if (propsOnValidate) {
        // if validation fails, stop here
        if (!propsOnValidate(obj)) {
          return;
        }
      }

      const preparedObject = propsTransformObjectBeforeSubmit
        ? propsTransformObjectBeforeSubmit(obj)
        : obj;

      const method = propsMethod
        ? propsMethod
        : preparedObject.id
        ? 'PATCH'
        : 'POST';

      // if no url provided, this is a frontend-only person widget
      if (propsUrl) {
        const url =
          method === 'PATCH' || method === 'DELETE'
            ? propsUrl + (preparedObject.id ? '/' + preparedObject.id : '')
            : propsUrl;
        const object =
          method === 'PATCH' || method === 'DELETE'
            ? { ...preparedObject, id: undefined }
            : preparedObject;
        ConfirmAPI.sendRequestToConfirm(
          method,
          propsAction ? url + '/' + propsAction : url,
          object,
          onSubmitCallback,
          preSubmit
        );
      } else {
        // frontend-only
        // Still want to call preSubmit in case it relates to
        // state changes (ex. for updating warning dialogs)
        if (typeof propsPreSubmit !== 'undefined') {
          propsPreSubmit();
        }

        // We use a promise here to simulate a mock API call, so that
        // React has the chance to update state changes (ex. related
        // to warning dialogs)
        const mockAPICall = new Promise((resolve) => resolve());
        mockAPICall.then(() => {
          if (typeof propsCallback !== 'undefined') {
            propsCallback(preparedObject);
          }
        });
      }
    },
    [
      propsObject,
      propsDoNotSendOrganization,
      propsCurrentOrganizationId,
      propsOnValidate,
      propsTransformObjectBeforeSubmit,
      propsMethod,
      propsUrl,
      propsAction,
      onSubmitCallback,
      preSubmit,
      propsCallback,
      propsPreSubmit,
    ]
  );

  const buttonClassName = props.buttonClassName ?? 'mt-4';

  const uniqueLocationString = uniqueId('confirm-form-');

  const submitButton = useMemo(
    () => (
      <>
        <span id={uniqueLocationString + '-save-button'}>
          <Button
            className={buttonClassName}
            disabled={
              props.disabled ||
              isSubmitting ||
              props.draftFetchState === 'LOADING'
                ? true
                : undefined
            }
            style={props.disabled ? { pointerEvents: 'none' } : undefined}
            color={props.buttonColor ? props.buttonColor : 'primary'}
            type={props.buttonType ? props.buttonType : 'submit'}
            block={props.buttonIsBlock}
            // if this is a non-submit button (used for forms within other
            // forms to avoid submitting the outer form), call handleSubmit
            // manually)
            onClick={
              props.buttonType && props.buttonType !== 'submit'
                ? handleSubmit
                : undefined
            }
          >
            {!isSubmitting &&
              (props.submitText
                ? props.submitText
                : formatMessage({
                    id: 'app.views.widgets.forms.confirm_form.submit',
                    defaultMessage: 'Submit',
                  }))}
            {isSubmitting && (
              <div
                className="spinner-border"
                style={{ width: '1rem', height: '1rem' }}
              />
            )}
          </Button>
          {props.isInDraftState && (
            <AutoSaveInfoBox
              className={buttonClassName + ' ms-3'}
              onDiscardDraft={props.discardDraft}
              disabled={props.disabled || isSubmitting}
            />
          )}
        </span>
        {props.disabled && propsDisabledHoverText && (
          <UncontrolledPopover
            placement="bottom"
            trigger="hover"
            target={uniqueLocationString + '-save-button'}
          >
            <PopoverBody className="text-dark">
              {propsDisabledHoverText}
            </PopoverBody>
          </UncontrolledPopover>
        )}
      </>
    ),
    [
      buttonClassName,
      handleSubmit,
      isSubmitting,
      props.buttonColor,
      props.buttonIsBlock,
      props.buttonType,
      props.disabled,
      props.submitText,
      propsDisabledHoverText,
      props.draftFetchState,
      uniqueLocationString,
      formatMessage,
      props.isInDraftState,
      props.discardDraft,
    ]
  );

  const defaultRenderForm = useCallback(
    (children, button, draftWarning) => {
      return (
        <>
          {children}
          {!props.hideSubmitButton && button}
          {draftWarning}
        </>
      );
    },
    [props.hideSubmitButton]
  );

  const draftWarning = useMemo(
    () => (
      <>
        {props.draftFetchState === 'LOADING' && (
          <span className="ps-3">
            <Spinner className="" spinnerStyle={{ top: undefined }}>
              <span>
                <FormattedMessage
                  id="app.views.widgets.forms.confirm_form.draft.loading"
                  defaultMessage="Loading draft data..."
                />
              </span>
            </Spinner>
          </span>
        )}
      </>
    ),
    [props.draftFetchState]
  );

  const renderForm = props.renderForm ? props.renderForm : defaultRenderForm;

  const handleOnSubmitWithDialog = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      if (propsOnValidate) {
        // if validation fails, stop here
        if (!propsOnValidate(propsObject)) {
          return;
        }
        // otherwise, show the confirmation dialog
        toggleConfirmationDialog();
      }
    },
    [propsObject, propsOnValidate, toggleConfirmationDialog]
  );

  useEffect(() => {
    if (message && propsShowErrorsAsToasts) {
      toast.error(propsRenderValidationErrors(message));
    }
  }, [message, propsRenderValidationErrors, propsShowErrorsAsToasts]);

  return (
    <>
      {props.confirmationDialog && (
        <ConfirmationDialogModal
          isOpen={confirmationDialogIsOpen}
          toggle={toggleConfirmationDialog}
          confirmCallback={handleSubmit}
          confirmationButtonColor={props.confirmationDialog?.buttonColor}
          title={props.confirmationDialog?.title}
          description={props.confirmationDialog?.description}
          confirmText={props.confirmationDialog?.confirmText}
          // need to pass along validation errors to avoid issue
          // where a 400 causes the dialog to hang
          validationErrors={message}
        />
      )}
      <Form
        noValidate={props.noValidate ? true : undefined}
        className={props.className}
        ref={props.formRef}
        onSubmit={
          props.confirmationDialog ? handleOnSubmitWithDialog : handleSubmit
        }
      >
        {!props.inlineSubmitButton &&
          renderForm(props.children, submitButton, draftWarning)}
        {props.inlineSubmitButton && (
          <Row>
            <Col>{props.children}</Col>
            {!props.hideSubmitButton && (
              <Col className="col-auto ps-0">{submitButton}</Col>
            )}
          </Row>
        )}
      </Form>
      {(isError || message) &&
        !props.showErrorsAsToasts &&
        props.renderValidationErrors(
          <div className={`text-center pt-2 message ${isError && 'error'}`}>
            <span className="text-danger">{message}</span>
          </div>
        )}
    </>
  );
};

ConfirmForm.defaultProps = {
  buttonIsBlock: true,
  renderValidationErrors: (x) => x,
};

ConfirmForm.propTypes = {
  className: PropTypes.string,
  formRef: PropTypes.object,
  preSubmit: PropTypes.func,
  callback: PropTypes.func,
  onValidate: PropTypes.func,
  url: PropTypes.string,
  action: PropTypes.string,
  method: PropTypes.string,
  object: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  currentOrganizationId: PropTypes.number.isRequired,
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inlineSubmitButton: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  buttonIsBlock: PropTypes.bool,
  buttonColor: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonType: PropTypes.string,
  transformObjectBeforeSubmit: PropTypes.func,
  renderForm: PropTypes.func,
  renderValidationErrors: PropTypes.func,
  doNotSendOrganization: PropTypes.bool,
  showErrorsAsToasts: PropTypes.bool,
  confirmationDialog: PropTypes.object,
  disabled: PropTypes.bool,
  onIsSubmittingChange: PropTypes.func,
  // ignores browser's native validation
  noValidate: PropTypes.bool,
  isInDraftState: PropTypes.bool,
  discardDraft: PropTypes.func,
  draftFetchState: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { currentOrganization } = state;

  return {
    currentOrganizationId: currentOrganization?.id,
  };
};

export default connect(mapStateToProps)(React.memo(ConfirmForm));
