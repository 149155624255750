import {
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useMemo } from 'react';

import { AgendaItem } from '../../types';
import AgendaItemInput from '../Widgets/Inputs/AgendaItemInput';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ObjectsDropdown from '../Widgets/Dropdowns/ObjectsDropdown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPrettyDate } from '../../utils/util/util';

const AgendaItemListGrouping = (props) => {
  const { formatMessage, locale } = useIntl();

  // sort agendaItems newest to oldest (in place)
  const agendaItems = useMemo(() => {
    if (!props.agendaItems) {
      return [];
    }

    return props.agendaItems.sort((a, b) => {
      // use completed at if it exists, else fall back to sort key, then created_at
      const sortKeyA = a.completed_at || a.sort_key || a.created_at;
      const sortKeyB = b.completed_at || b.sort_key || b.created_at;

      return new Date(sortKeyA).getTime() - new Date(sortKeyB).getTime();
    });
  }, [props.agendaItems]);

  // just get date of first item (as pretty date so we use local
  // timezone and that is the unique react string for list updating
  const completionDate = useMemo(
    () => getPrettyDate({ dateString: agendaItems?.[0]?.completed_at, locale }),
    [agendaItems, locale]
  );

  const agendaItemSubmenuByField = useMemo(() => {
    // if ALL of the items in the agenda templates have a "category" field,
    // then we want to group them by that field so will return "category",
    // else we return undefined to indicate no grouping/hierarchy
    return typeof props.agendaItemTemplates.every === 'function' &&
      props.agendaItemTemplates?.every((ait) => ait.category)
      ? 'category'
      : undefined;
  }, [props.agendaItemTemplates]);

  const hasAgendaItemTemplates = useMemo(() => {
    return props.agendaItemTemplates?.length > 0;
  }, [props.agendaItemTemplates?.length]);

  const propsAddNewCallback = props.addNewCallback;
  const onAddFromAgendaItemTemplate = useCallback(
    (e) => {
      propsAddNewCallback(
        e.target?.value
          ? props.agendaItemTemplates?.find((ait) => ait.id === e.target.value)
              ?.value
          : undefined
      );
    },
    [props.agendaItemTemplates, propsAddNewCallback]
  );

  const propsCallback = props.callback;
  const moveAgendaItem = useCallback(
    (dragIndex, hoverIndex) => {
      // to enable the backend to keep the agenda items sorted and only have to
      // update the specific item being dragged, we keep items sorted by "sort_key"
      // if that field is populated, else we use the "created_at" field. Thus, on
      // sort, we need to update the sort_key of the item being dragged by looking
      // at the sort_key of the item above and below it (if it exists) and
      // calculating the average of those two values. If the item being dragged
      // is the first or last item, then we just use the sort_key of the item
      // above or below it, respectively. If the item being dragged is the only
      // item, then we just use the current sort_key or created_at value.
      const agendaItemsCopy = [...agendaItems];
      const dragItem = agendaItemsCopy[dragIndex];
      const hoverItem = agendaItemsCopy[hoverIndex];

      // if this is the only item, then there is nothing needed to be done
      if (agendaItemsCopy.length === 1) {
        return;
      }

      let newSortKey;

      // if the hoverItem is the first item, then just use the sort_key of it
      // minus 1 second (note: this is a date, so need to do date math)
      if (hoverIndex === 0) {
        const hoverItemDate = hoverItem.sort_key || hoverItem.created_at;
        newSortKey = new Date(new Date(hoverItemDate).getTime() - 1000);
      }

      // if the dragItem is the last item, then just use the sort_key of the
      // item above it plus 1 second (note: this is a date, so need to do date math)
      else if (hoverIndex === agendaItemsCopy.length - 1) {
        const hoverItemDate = hoverItem.sort_key || hoverItem.created_at;
        newSortKey = new Date(new Date(hoverItemDate).getTime() + 1000);
      } else {
        // if we are moving by one, we need to use the index of the item
        // that would be in place of the one we moved after the move
        const indexAfter =
          dragIndex === hoverIndex + 1 ? hoverIndex : hoverIndex + 1;
        const indexBefore =
          dragIndex === hoverIndex + 1 ? hoverIndex - 1 : hoverIndex;

        // otherwise, calculate the average of the sort_key of the item above
        // and below it
        const afterItem = agendaItemsCopy[indexAfter];
        const beforeItem = agendaItemsCopy[indexBefore];
        const afterSortKey = afterItem.sort_key || afterItem.created_at;
        const beforeSortKey = beforeItem.sort_key || beforeItem.created_at;

        // set newSortKey as the date between aboveSortKey and belowSortKey
        newSortKey = new Date(
          (new Date(afterSortKey).getTime() -
            new Date(beforeSortKey).getTime()) /
            2 +
            new Date(beforeSortKey).getTime()
        );
      }

      propsCallback({ ...dragItem, sort_key: newSortKey });
    },
    [propsCallback, agendaItems]
  );

  return (
    <Card>
      {props.showDate && completionDate && (
        <CardHeader>
          <CardHeaderTitle>{completionDate}</CardHeaderTitle>
        </CardHeader>
      )}
      <CardBody className="my-n3">
        <ListGroup className="list-group-flush my-n3">
          {agendaItems.map((agendaItem, index) => (
            <ListGroupItem key={agendaItem.key}>
              <AgendaItemInput
                allowReordering={props.allowReordering}
                hoverIndex={index}
                agendaItem={agendaItem}
                moveAgendaItem={moveAgendaItem}
                callback={props.callback}
                deleteCallback={props.deleteCallback}
                inputCallback={props.inputCallback}
                disabled={props.disabled}
              />
            </ListGroupItem>
          ))}
          {props.disabled && (
            <Row className="py-3 mb-2 mt-1 align-items-center">
              <Col className="col-auto">
                <div
                  style={{ position: 'relative', left: '4px' }}
                  className="text-muted"
                >
                  <span className="fst-italic">
                    <FormattedMessage
                      id="app.views.one_on_ones.agenda_item_list.you_cannot_add_items"
                      defaultMessage="
                    You cannot add items when viewing as someone else.
                  "
                    />
                  </span>
                </div>
              </Col>
            </Row>
          )}
          {!props.disabled && propsAddNewCallback && (
            <Row className="py-3 mb-2 mt-1 align-items-center">
              <Col className="col-auto">
                <div
                  style={{ position: 'relative', left: '4px' }}
                  className="text-muted"
                  role="button"
                  onClick={() => props.addNewCallback()}
                >
                  <span className="me-3">{'+'}</span>{' '}
                  <FormattedMessage
                    id="app.views.one_on_ones.agenda_item_list.add_item"
                    defaultMessage="Add item"
                  />{' '}
                  {hasAgendaItemTemplates &&
                    formatMessage({
                      id: 'app.views.one_on_ones.agenda_item_list.or',
                      defaultMessage: 'or',
                    })}{' '}
                </div>
              </Col>
              {hasAgendaItemTemplates && (
                <Col className="col-auto ps-0">
                  <ObjectsDropdown
                    buttonClassName="btn-sm"
                    //dropdownItemClassName="btn-sm px-3 py-1"
                    alwaysShowSelectText={true}
                    selectText={formatMessage({
                      id: 'app.views.one_on_ones.agenda_item_list.add_from_template',
                      defaultMessage: 'Add from template',
                    })}
                    objects={props.agendaItemTemplates}
                    onChange={onAddFromAgendaItemTemplate}
                    submenuByField={agendaItemSubmenuByField}
                  />
                </Col>
              )}
            </Row>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

const AgendaItemList = (props) => {
  const { locale } = useIntl();
  const agendaItemListGroups = useMemo(() => {
    if (!props.agendaItems?.length) {
      return [[]];
    }

    if (props.groupByDate) {
      // hash the agendaItems into buckets by calendar date
      const agendaItemListGroups = {};

      for (let i = 0; i < props.agendaItems.length; i++) {
        const agendaItem = props.agendaItems[i];

        if (!agendaItem?.completed_at) {
          continue;
        }

        // get pretty date which converts to local timezone
        const agendaItemDate = getPrettyDate({
          dateString: agendaItem.completed_at,
          locale,
        });

        if (agendaItemListGroups[agendaItemDate]) {
          agendaItemListGroups[agendaItemDate].push(agendaItem);
        } else {
          agendaItemListGroups[agendaItemDate] = [agendaItem];
        }
      }

      // convert hash to list of lists
      const agendaItemListGroupsList: AgendaItem[][] = [];
      for (const key in agendaItemListGroups) {
        agendaItemListGroupsList.push(agendaItemListGroups[key]);
      }

      // sort from newest to oldest
      agendaItemListGroupsList.sort((a, b) => {
        return (
          new Date(b[0]?.completed_at || '1970-01-01').getTime() -
          new Date(a[0]?.completed_at || '1970-01-01').getTime()
        );
      });

      return agendaItemListGroupsList;
    } else {
      return [props.agendaItems];
    }
  }, [props.groupByDate, props.agendaItems, locale]);

  return (
    <>
      {agendaItemListGroups.map((agendaItems, i) => (
        <AgendaItemListGrouping
          // make date the unique key or fall back on index
          key={
            agendaItems?.[0]?.completed_at
              ? getPrettyDate({
                  dateString: agendaItems?.[0]?.completed_at,
                  locale,
                })
              : i
          }
          agendaItems={agendaItems}
          agendaItemTemplates={props.agendaItemTemplates}
          showDate={props.groupByDate}
          callback={props.callback}
          addNewCallback={props.addNewCallback}
          deleteCallback={props.deleteCallback}
          inputCallback={props.inputCallback}
          disabled={props.disabled}
          allowReordering={props.allowReordering}
        />
      ))}
    </>
  );
};

AgendaItemList.propTypes = {
  agendaItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  agendaItemTemplates: PropTypes.arrayOf(PropTypes.object),
  callback: PropTypes.func,
  groupByDate: PropTypes.bool,
  addNewCallback: PropTypes.func,
  deleteCallback: PropTypes.func,
  inputCallback: PropTypes.func,
  disabled: PropTypes.bool,
  allowReordering: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentProxyPerson, features } = state;

  return {
    me: me,
    currentOrganization: currentOrganization,
    currentProxyPerson: currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(AgendaItemList));
