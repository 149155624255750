import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useEffect, useState } from 'react';

import { Comment } from '../../types';
import CommentsLog from '../Widgets/CommentsLog';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import Loading from '../Widgets/Loading';
import { transformItems } from '../../utils/models/ObjectiveHistory';

interface Props {
  objectiveKey: string;
}

const ObjectiveHistory: FC<Props> = ({ objectiveKey }) => {
  const [items, setItems] = useState<Comment[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    if (!objectiveKey) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    ConfirmAPI.sendRequestToConfirm(
      'GET',
      `/objectives/history/${objectiveKey}`,
      null,
      (response, error) => {
        if (response) {
          setLoading(false);
          setItems(response.changes);
        }
        if (error) {
          setLoading(false);
          setError(true);
        }
      },
      null,
      null
    );
  }, [objectiveKey]);

  if (error)
    return (
      <div className="invalid-feedback">
        <FormattedMessage
          id="app.objectives.item.history.item.error"
          defaultMessage="There was an error loading the history for this objective."
        />
      </div>
    );

  if (!objectiveKey || loading) return <Loading />;

  return (
    <div>
      <CommentsLog
        title={null}
        isRichText={true}
        isExternalUrl={true}
        comments={transformItems(items || [], intl)}
        emptyStateText={intl.formatMessage({
          id: 'app.objectives.item.history.item.empty_state',
          defaultMessage: 'No changes have been made to this objective.',
        })}
        bodyStyle={{
          maxHeight: '300px',
          overflowY: 'auto',
          maxWidth: '20.5rem',
        }}
      />
    </div>
  );
};

export default ObjectiveHistory;
