import * as types from './types';

// core application actions

export const setAuthUser = (authUser) => ({
  type: types.SET_AUTH_USER,
  authUser: authUser,
});

export const setMe = (me) => ({
  type: types.SET_ME,
  me: me,
});

export const updateMe = (changes) => ({
  type: types.UPDATE_ME,
  // note: just passes in changes (instead of the full object) so not every field is replaced
  // thus minimizing areas in the code that update when part of the user changes
  changes: changes,
});

export const updateMyConfigs = (changes) => ({
  type: types.UPDATE_MY_CONFIGS,
  // note: just passes in changes (instead of the full object) so not every field is replaced
  // thus minimizing areas in the code that update when part of the user changes
  changes: changes,
});

export const setCurrentOrganization = (organization) => ({
  type: types.SET_CURRENT_ORGANIZATION,
  organization: organization,
});

export const setCurrentProxyPerson = (person) => ({
  type: types.SET_CURRENT_PROXY_PERSON,
  person: person,
});

export const loadApp = (proxy, isInRipplingInstallFlow, callback) => ({
  type: types.LOAD_APP,
  proxy: proxy,
  isInRipplingInstallFlow: isInRipplingInstallFlow,
  callback: callback,
});

export const loadTasks = (userSub, proxy) => ({
  type: types.LOAD_TASKS,
  userSub: userSub,
  proxy: proxy,
});

export const setTasks = (tasks) => ({
  type: types.SET_TASKS,
  data: {
    tasks,
  },
});

// perf-related actions

export const loadCampaignsAndSurveyResponses = (
  userSub,
  proxy,
  needsDirectReportReceivedRelationships,
  cancelToken
) => ({
  type: types.LOAD_CAMPAIGNS_AND_SURVEY_RESPONSES,
  userSub: userSub,
  proxy: proxy,
  needsDirectReportReceivedRelationships:
    needsDirectReportReceivedRelationships,
  cancelToken: cancelToken,
});

export const setCurrentDemoOrPreviewPerfCampaign = (perfCampaign) => ({
  type: types.SET_CURRENT_DEMO_OR_PREVIEW_PERF_CAMPAIGN,
  campaign: perfCampaign,
});

export const setCurrentPerfCampaign = (perfCampaign) => ({
  type: types.SET_CURRENT_PERF_CAMPAIGN,
  campaign: perfCampaign,
});

export const setCurrentPerfSurveyResponse = (surveyResponse) => ({
  type: types.SET_CURRENT_PERF_SURVEY_RESPONSE,
  surveyResponse: surveyResponse,
});

export const loadAppIntegrations = (organization_id, proxy) => ({
  type: types.LOAD_APP_INTEGRATIONS,
  organization_id: organization_id,
  proxy: proxy,
});

export const loadInvitation = (invitation, callback) => ({
  type: types.LOAD_INVITATION,
  invitation: invitation,
  callback: callback,
});

export const claimInvitation = (invitation, callback) => ({
  type: types.CLAIM_INVITATION,
  invitation: invitation,
  callback: callback,
});

export const addAppIntegration = (appIntegration) => ({
  type: types.ADD_APP_INTEGRATION,
  appIntegration: appIntegration,
});

export const updateAppIntegration = (appIntegration) => ({
  type: types.UPDATE_APP_INTEGRATION,
  appIntegration: appIntegration,
});

export const acceptTask = (task) => ({
  type: types.ACCEPT_TASK,
  task,
});

export const declineTask = (task) => ({
  type: types.DECLINE_TASK,
  task,
});

export const ignoreContribution = (contribution, callback) => ({
  type: types.IGNORE_CONTRIBUTION,
  contribution,
  callback,
});

export const unignoreContribution = (contribution, callback) => ({
  type: types.UNIGNORE_CONTRIBUTION,
  contribution,
  callback,
});
