import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import EmptyState from '../Widgets/EmptyState';

const OpportunityProblems: FC = () => {
  const { formatMessage } = useIntl();

  const problemOpportunities = [];

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.opportunities.opportunity_problems.card.header.title"
            defaultMessage="Problems"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {problemOpportunities?.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.opportunities.opportunity_problems.title.no_problems',
              defaultMessage: 'No problems',
            })}
            subtitle={formatMessage({
              id: 'app.views.opportunities.opportunity_problems.subtitle.there_are_no_problem_opportunities',
              defaultMessage:
                'There are no problem opportunities currently available.',
            })}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(OpportunityProblems);
