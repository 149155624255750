import { Card, CardHeader, Col, Row } from 'reactstrap';
import React, { ReactNode, useMemo } from 'react';

import AccordionTableRow from './AccordionTableRow';

interface AccordionTableProps {
  columns: Array<{
    _id?: string;
    name: string;
    className?: string;
    field: string;
  }>;
  rows?: Array<Record<string, string | ReactNode>>;
  hasAccordion?: boolean;
  customContent?: ReactNode;
  rowClassname?: string;
}

const AccordionTable: React.FC<AccordionTableProps> = ({
  columns,
  rows,
  hasAccordion,
  customContent,
  rowClassname,
}) => {
  const accordionColumns = useMemo(() => {
    columns.map((c, i) => {
      if (!c._id) {
        c._id = `${i}`;
      }
    });
    return (
      <Row>
        <Col className={hasAccordion ? 'col-11' : 'col-12'}>
          <Row className="gx-0">
            {columns.map((c) => (
              <Col key={c._id} className={'pe-1 ' + (c.className ?? '')}>
                {c.name}
              </Col>
            ))}
          </Row>
        </Col>
        {hasAccordion ? <Col className="col-1"></Col> : <></>}
      </Row>
    );
  }, [columns, hasAccordion]);

  const accordionRows = useMemo(() => {
    if (!rows) {
      return undefined;
    }
    rows.map((r, i) => {
      if (!r._id) {
        r._id = `${i}`;
      }
    });
    return rows?.map((r) => {
      const rowHasAccordion = r.accordion; // Content inside collapsed portion

      const row = (
        <Row key={`row-${r._id}`}>
          <Col className={hasAccordion ? 'col-11' : 'col-12'}>
            <Row
              key={`row-inner-${r._id}`}
              className={'gx-0 accordion-table-row'}
            >
              {columns.map((c) => (
                <Col
                  key={`cell-${c._id}-${r._id}`}
                  className={c.className ?? ''}
                >
                  {r[c.field]}
                </Col>
              ))}
            </Row>
          </Col>
          {hasAccordion && rowHasAccordion ? (
            <Col className="col-1 text-center align-self-center">
              <i className="fe fe-chevron-down" />
            </Col>
          ) : (
            <></>
          )}
        </Row>
      );

      if (rowHasAccordion) {
        return (
          <AccordionTableRow
            key={`accordion-row-${r._id}`}
            className={rowClassname}
            row={row}
            content={rowHasAccordion}
          />
        );
      } else {
        return row;
      }
    });
  }, [columns, rows, hasAccordion, rowClassname]);

  return (
    <Card>
      <CardHeader className={'accordion-table-header'}>
        {accordionColumns}
      </CardHeader>
      {customContent || accordionRows}
    </Card>
  );
};

export default React.memo(AccordionTable);
