import { ICONS } from '../../consts/consts';
import React from 'react';

export const COMMENT_TYPE_DEFAULT = {
  id: 'D',
  // no name, as this is the default
};

export const COMMENT_TYPE_SUBMISSION = (formatMessage) => ({
  id: 'S',
  name: 'submission',
  pastTense: formatMessage({
    id: 'app.utils.models.comment.type.past.submitted',
    defaultMessage: 'submitted',
  }),
  icon: ICONS.PENDING,
  textColor: 'text-muted',
});

export const COMMENT_TYPE_APPROVAL = (formatMessage) => ({
  id: 'A',
  name: 'approval',
  pastTense: formatMessage({
    id: 'app.utils.models.comment.type.past.approved',
    defaultMessage: 'approved',
  }),
  icon: ICONS.APPROVED,
  textColor: 'text-success',
});

export const COMMENT_TYPE_DENIAL = (formatMessage) => ({
  id: 'E',
  name: 'denial',
  pastTense: formatMessage({
    id: 'app.utils.models.comment.type.past.denied',
    defaultMessage: 'denied',
  }),
  icon: ICONS.DENIED,
  textColor: 'text-danger',
});

export const COMMENT_TYPE_WITHDRAWAL = (formatMessage) => ({
  id: 'W',
  name: 'withdrawal',
  pastTense: formatMessage({
    id: 'app.utils.models.comment.type.past.withdrew',
    defaultMessage: 'withdrew',
  }),
  icon: ICONS.WITHDRAWN,
  textColor: 'text-muted',
});

export const COMMENT_TYPE_UNAPPROVAL = (formatMessage) => ({
  id: 'U',
  name: 'unapproval',
  pastTense: formatMessage({
    id: 'app.utils.models.comment.type.past.unapproved',
    defaultMessage: 'unapproved',
  }),
  icon: ICONS.PENDING,
  textColor: 'text-muted',
});

export const COMMENT_TYPE_UNDENIAL = (formatMessage) => ({
  id: 'N',
  name: 'undenial',
  pastTense: formatMessage({
    id: 'app.utils.models.comment.type.past.undenied',
    defaultMessage: 'undenied',
  }),
  icon: ICONS.PENDING,
  textColor: 'text-muted',
});

const commentTypes = (formatMessage) => [
  COMMENT_TYPE_DEFAULT,
  COMMENT_TYPE_SUBMISSION(formatMessage),
  COMMENT_TYPE_APPROVAL(formatMessage),
  COMMENT_TYPE_DENIAL(formatMessage),
  COMMENT_TYPE_WITHDRAWAL(formatMessage),
  COMMENT_TYPE_UNAPPROVAL(formatMessage),
  COMMENT_TYPE_UNDENIAL(formatMessage),
];

export const getCommentTypeText = (typeId, formatMessage) => {
  const type = commentTypes(formatMessage).find((t) => t.id === typeId);

  if (!type || type.id === COMMENT_TYPE_DEFAULT.id) {
    return null;
  }
  // @ts-expect-error
  return <span className={type.textColor}>{type.pastTense}</span>;
};
