// stuff related to performance cycles (mainly to avoid circular dependencies between Performance.tsx and Campaign.tsx)

import {
  WIZARD_TYPES_PHASES,
  WIZARD_TYPE_ENGAGEMENT_ONLY,
  WIZARD_TYPE_ENPS_ONLY,
  WIZARD_TYPE_ONA_ONLY,
  WIZARD_TYPE_PERFORMANCE,
  getWizardType,
} from './Performance';

import { Campaign } from '../../types';

export const getWizardTypeDisplayName = (type, formatMessage) => {
  if (type === WIZARD_TYPE_PERFORMANCE) {
    return formatMessage({
      id: 'app.utils.models.campaign.type.name.performance',
      defaultMessage: 'Performance cycle',
    });
  }
  if (type === WIZARD_TYPE_ENGAGEMENT_ONLY) {
    return formatMessage({
      id: 'app.utils.models.campaign.type.name.engagement',
      defaultMessage: 'Engagement only',
    });
  }
  if (type === WIZARD_TYPE_ENPS_ONLY) {
    return formatMessage({
      id: 'app.utils.models.campaign.type.name.enps',
      defaultMessage: 'eNPS only',
    });
  }
  if (type === WIZARD_TYPE_ONA_ONLY) {
    return formatMessage({
      id: 'app.utils.models.campaign.type.name.ona',
      defaultMessage: 'ONA only',
    });
  }

  console.error('Unrecognized campaign type: ' + type);
  return '';
};

export const WIZARD_TYPES_LIST = (formatMessage) => [
  {
    id: WIZARD_TYPE_PERFORMANCE,
    name: getWizardTypeDisplayName(WIZARD_TYPE_PERFORMANCE, formatMessage),
    help: formatMessage({
      id: 'app.utils.models.campaign.type.help.performance',
      defaultMessage:
        'Performance reviews, including optional manager reviews, calibrations, pulse surveys, conversations, etc.',
    }),
  },
  {
    id: WIZARD_TYPE_ENGAGEMENT_ONLY,
    name: getWizardTypeDisplayName(WIZARD_TYPE_ENGAGEMENT_ONLY, formatMessage),
    help: formatMessage({
      id: 'app.utils.models.campaign.type.help.engagement_only',
      defaultMessage:
        'Check in with how people are feeling about the organization in depth.',
    }),
  },
  {
    id: WIZARD_TYPE_ENPS_ONLY,
    name: getWizardTypeDisplayName(WIZARD_TYPE_ENPS_ONLY, formatMessage),
    help: formatMessage({
      id: 'app.utils.models.campaign.type.help.enps_only',
      defaultMessage:
        'A super-quick employee NPS survey to capture quick employee sentiment about the organization.',
    }),
  },
  {
    id: WIZARD_TYPE_ONA_ONLY,
    name: getWizardTypeDisplayName(WIZARD_TYPE_ONA_ONLY, formatMessage),
    help: formatMessage({
      id: 'app.utils.models.campaign.type.help.ona_only',
      defaultMessage:
        'Organizational Network Analysis only, no other performance aspects, i.e. no manager reviews, etc.',
    }),
  },
];

export const getWizardPhasesForCampaign = (campaign: Campaign) => {
  return WIZARD_TYPES_PHASES[getWizardType(campaign)];
};
