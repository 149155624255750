import PersonCard from '../Cards/PersonCard';
import PropTypes from 'prop-types';
import React from 'react';

const PersonCardList = (props) => {
  const direction = props.stacked ? 'column' : 'row';

  props.people.sort((a, b) => {
    return a.full_name.localeCompare(b.full_name);
  });

  return (
    <div className={`d-flex flex-${direction} flex-wrap`}>
      {props.people.map((person) => (
        <PersonCard
          key={person.id}
          person={person}
          size={props.size}
          useTagFormat={props.useTagFormat}
          inPopover={props.inPopover}
        />
      ))}
    </div>
  );
};

PersonCardList.defaultProps = {
  size: 'xxs',
  stacked: false,
  useTagFormat: true,
  inPopover: false,
};

PersonCardList.propTypes = {
  people: PropTypes.array.isRequired,
  size: PropTypes.string,
  stacked: PropTypes.bool,
  useTagFormat: PropTypes.bool,
  inPopover: PropTypes.bool,
};

export default PersonCardList;
