import React, { useMemo, useCallback } from 'react';
import PerformanceHistoryChart from './HistoryChart';
import { useIntl } from 'react-intl';
import {
  ChartCoordinates,
  PerformanceHistoryData,
  computeDataValues,
  computeRatingData,
  TENURE_GAP_SIZE,
  lineBaseStyle,
  chartAlertPointStyle,
  computeLabels,
} from './HistoryChartUtils';
import { getCampaignHasONA } from 'utils/models/Performance';

interface NetworkRecognitionHistoryChartProps {
  person?: {
    id: number;
    given_name: string;
  };
  showManagerOnlyPerformanceDetails: boolean;
  performanceData?: PerformanceHistoryData[];
}

const NetworkRecognitionHistoryChart: React.FC<
  NetworkRecognitionHistoryChartProps
> = ({ person, showManagerOnlyPerformanceDetails, performanceData }) => {
  const { formatMessage } = useIntl();

  const data = useMemo(
    () => performanceData?.filter((x) => getCampaignHasONA(x.campaign)),
    [performanceData]
  );
  const firstName = person?.given_name ?? '';

  const ratingValues = useMemo(() => computeRatingData(data), [data]);

  const dataValues = useMemo(
    () => computeDataValues(data, ratingValues),
    [data, ratingValues]
  );

  const alertPointStyle = useCallback(
    (x, type) => chartAlertPointStyle(dataValues, type, x),
    [dataValues]
  );

  const labels = useMemo(() => {
    return computeLabels(data || []);
  }, [data]);

  const maxONA = useMemo(() => {
    let dataMax = Math.ceil(
      Math.max(
        1,
        ...(data?.map((e) => e.gold_stars.length + e.influence.length || 0) ||
          []),
        ...(data?.map(
          (e) => e?.benchmarks?.organization?.tenure?.positive_ona_avg || 0
        ) || []),
        ...(data?.map(
          (e) => e?.benchmarks?.organization?.all?.positive_ona_avg || 0
        ) || [])
      )
    );
    dataMax = 5 * Math.ceil(dataMax / 5);
    return dataMax;
  }, [data]);

  const networkChartOptions = {
    x: {
      label: formatMessage({
        id: 'app.components.campaign_insight.network_chart_options.x.label.tenure',
        defaultMessage: 'Tenure (months)',
      }),
      tickMin: labels[0],
      tickMax: labels[1],
      tickSize: TENURE_GAP_SIZE,
    },
    y: {
      label: formatMessage({
        id: 'app.components.campaign_insight.network_chart_options.x.label.recognition',
        defaultMessage: 'Recognition',
      }),
      tickMax: maxONA + 5,
      maxTicksLimit: 5,
    },
  };

  const testOneChartData = () => {
    if (data) {
      let slope = 0;
      let intercept = 0;
      let maxTenure = 0;
      const dataPts: ChartCoordinates[] = [];

      data?.forEach((d) => {
        const benchmark = d?.benchmarks?.organization?.cross_tenure;

        const overallBenchmark = benchmark?.overall;
        slope += overallBenchmark?.slope ?? 0;
        intercept += overallBenchmark?.intercept ?? 0;

        const bucketBenchmark = benchmark?.buckets || {};
        Object.values(bucketBenchmark)?.forEach(
          (b) => (maxTenure = Math.max(maxTenure, b?.next_point))
        );

        dataPts.push({
          x: d?.tenure_months,
          y: (d?.gold_stars?.length ?? 0) + (d?.influence?.length ?? 0),
        });
      });

      const avgIntercept = Number((intercept / data.length).toFixed(2));
      const avgSlope = Number((slope / data.length).toFixed(2));

      return {
        datasets: [
          {
            data: dataPts,
            pointStyle: (x) => alertPointStyle(x, 'individual'),
            showLine: true,
            ...lineBaseStyle(firstName, '#2c7be5'),
          },
          {
            label: formatMessage({
              id: 'app.components.campaign_insight.test_one_chart_data.tenure_avg',
              defaultMessage: 'Tenure avg.',
            }),
            data: [
              { x: 0, y: avgIntercept },
              {
                x: labels[1],
                y: avgIntercept + avgSlope * labels[1],
              },
            ],
            showLine: true,
            borderColor: '#f6c343',
            borderDash: [5, 5],
          },
        ],
      };
    }
  };

  const chartOverlaps = useMemo(() => {
    const individualOverlaps: { [index: number]: string[] } = {};
    const tenureOverlaps: { [index: number]: string[] } = {};
    const baselineOverlaps: { [index: number]: string[] } = {};

    dataValues.individual.data.forEach((_, i) => {
      if (dataValues.individual.data[i] === dataValues.tenure.data[i]) {
        (individualOverlaps[i] ||= []).push('tenure');
        (tenureOverlaps[i] ||= []).push('individual');
      }

      if (dataValues.individual.data[i] === dataValues.baseline.data[i]) {
        (individualOverlaps[i] ||= []).push('baseline');
        (baselineOverlaps[i] ||= []).push('individual');
      }

      if (dataValues.tenure.data[i] === dataValues.baseline.data[i]) {
        (baselineOverlaps[i] ||= []).push('tenure');
        (tenureOverlaps[i] ||= []).push('baseline');
      }
    });

    return {
      individual: individualOverlaps,
      tenure: tenureOverlaps,
      baseline: baselineOverlaps,
    };
  }, [dataValues]);

  const chartData = testOneChartData();
  return chartData ? (
    <PerformanceHistoryChart
      key={`chart_history_network_${person?.id}`}
      personId={person?.id}
      title={formatMessage(
        {
          id: 'app.views.person.person_performance.history.persons_network_recognition',
          defaultMessage: 'Network recognition over time',
        },
        {
          firstName,
        }
      )}
      firstName={firstName}
      data={chartData}
      options={networkChartOptions}
      individualAlerts={dataValues.individual.alerts}
      cycleDatapoints={data}
      overlaps={chartOverlaps}
      name={firstName}
      includeAverage={true}
      managerView={showManagerOnlyPerformanceDetails}
      chartType="recognition"
    />
  ) : null;
};

export default NetworkRecognitionHistoryChart;
