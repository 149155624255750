import { SUBMIT_ERROR } from 'consts/consts';

export const formatErrorMessageIfPresent = (errorMessageOrObject) => {
  if (errorMessageOrObject) {
    return formatErrorMessage(errorMessageOrObject);
  }
  return errorMessageOrObject;
};

export const formatErrorMessage = (errorMessageOrObject) => {
  if (typeof errorMessageOrObject === 'string') {
    // if this is a whole webpage, rather than spitting that out
    // to the user as code, replace with a generic response
    if (errorMessageOrObject.startsWith('<!DOCTYPE html>')) {
      return SUBMIT_ERROR;
    }

    return errorMessageOrObject;
  }

  if (typeof errorMessageOrObject === 'object') {
    if (errorMessageOrObject?.data) {
      if (typeof errorMessageOrObject.data === 'string') {
        return errorMessageOrObject.data;
      } else if (typeof errorMessageOrObject.data === 'object') {
        if (typeof errorMessageOrObject.data.detail === 'string') {
          return errorMessageOrObject.data.detail;
        }
      }
    } else if (errorMessageOrObject?.detail) {
      return errorMessageOrObject.detail;
    } else if (errorMessageOrObject?.error) {
      return errorMessageOrObject.error;
    } else if (errorMessageOrObject?.statusText) {
      return errorMessageOrObject.statusText;
    }
  }

  console.error(
    'Unrecognized error message format: ' + JSON.stringify(errorMessageOrObject)
  );
  return JSON.stringify(errorMessageOrObject);
};
