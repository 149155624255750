import * as consts from '../../consts/consts';

import {
  Collapse,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';

import { FLAGS } from '../../consts/consts';
import { FormattedMessage } from 'react-intl';
import PeopleNav from './PeopleNav';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import SiteNav from './SiteNav';
import { connect } from 'react-redux';
import { isEnabled } from '../../utils/util/util';
import logoWithName from '../../assets/img/brand/logo_reverse.svg';

const MainLeftNav: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => setIsOpen(!isOpen);

  const referralsEnabled = isEnabled(props.features, FLAGS.REFERRALS);

  // if the page changes, since we scroll to top, we need to close the navbar
  // if it is open to avoid annoying the user having to keep scrolling
  // down when they change tabs on a given page
  const { pathname } = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <Navbar
      container={false}
      expand="md"
      className="fixed-start navbar-vertical navbar-dark left-navbar"
    >
      <div className="container-fluid">
        <NavbarToggler onClick={toggleNavbar} />
        <div className="pe-sm-3 nav-item">
          <NavbarBrand
            tag={Link}
            to="/"
            className="nav-link navbar-brand pt-0 pb-0 pt-md-3 pb-md-4"
          >
            <img
              style={{ maxHeight: '3.5rem' }}
              src={logoWithName}
              alt="Confirm Logo"
            />
          </NavbarBrand>
        </div>
        {/* @ts-expect-error */}
        {props.currentOrganization?.id && (
          <Collapse isOpen={isOpen} navbar>
            <SiteNav
              showIfEnabled={props.showIfEnabled}
              isSuperAdmin={props.isSuperAdmin}
              isOrgSystemAdmin={props.isOrgSystemAdmin}
            />
            <hr className="navbar-divider my-3"></hr>
            <PeopleNav inNav={true} showSelf={true} />
            {referralsEnabled && (
              <Nav navbar className="mt-auto">
                <NavItem>
                  <NavLink className="nav-link" to={'/referrals'}>
                    <i className={consts.ICONS.REFERRAL}></i>
                    <FormattedMessage
                      id="app.views.layout.mainleftnav.referrals"
                      defaultMessage="Refer Confirm"
                    ></FormattedMessage>
                  </NavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        )}
      </div>
    </Navbar>
  );
};

const MainLeftNav_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  showIfEnabled: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  isOrgSystemAdmin: PropTypes.bool.isRequired,
};

type Props = PropTypes.InferProps<typeof MainLeftNav_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    features,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(MainLeftNav));
