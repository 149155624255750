import ObjectivesTimeFrameSelector, {
  useObjectivesTimeFrameSelector,
} from './ObjectivesTimeFrameSelector';
import {
  Organization,
  OrganizationSettings,
  Person,
  ReduxState,
} from '../../types';
import React, { FC, useCallback, useEffect, useState } from 'react';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import ObjectivesDashboard from '../Widgets/Dashboards/ObjectivesDashboard';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

type Props = {
  currentProxyPerson?: Person;
  currentOrganization?: Organization;
  settings: OrganizationSettings;
};

/**
 * A central Objectives page that presents an overview of all Objective-
 * related information for your team. (Initially, just the "participation"
 * stats from ObjectivesDashboard.)
 *
 * @param {*} props
 * @returns
 */
const ObjectivesParticipationPage: FC<Props> = ({
  currentOrganization,
  currentProxyPerson,
  settings,
}) => {
  const { user } = useAuth0();
  const { formatMessage } = useIntl();

  const [loadingErrorMessage, setLoadingErrorMessage] = useState();
  const [data, setData] = useState();

  const loadData = useCallback(
    (start, end) => {
      setData(undefined);
      ConfirmAPI.getUrlWithCache(
        '/search/get-objective-participation-stats/v2',
        'search-objective-participation-stats',
        user?.sub,
        currentProxyPerson,
        {
          organization_id: currentOrganization?.id,
          coverage_start_date: start,
          coverage_end_date: end,
          apply_feature_filters: true,
        },
        (stats) => {
          setData(stats);
        },
        (error) => {
          setLoadingErrorMessage(error);
        }
      );
    },
    [currentOrganization, currentProxyPerson, user?.sub]
  );

  const {
    firstDay,
    lastDay,
    currentTimeframeText,
    viewPreviousTimeframe,
    viewNextTimeframe,
  } = useObjectivesTimeFrameSelector({});

  useEffect(() => {
    loadData(firstDay, lastDay);
  }, [firstDay, lastDay, loadData]);

  return (
    <>
      {settings?.objectives_restrict_visibility_to_match_editability ? (
        <EmptyState
          title={formatMessage({
            id: 'app.objectives_hub_page.feature_not_enabled',
            defaultMessage: 'This feature is not enabled',
          })}
        ></EmptyState>
      ) : (
        <>
          <ObjectivesTimeFrameSelector
            currentTimeframeText={currentTimeframeText}
            viewNextTimeframe={viewNextTimeframe}
            viewPreviousTimeframe={viewPreviousTimeframe}
            disabled={data === undefined}
          />
          <ObjectivesDashboard data={data} errorMessage={loadingErrorMessage} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentProxyPerson, currentOrganization, settings } = state;

  return {
    currentProxyPerson,
    currentOrganization,
    settings,
  };
};

export default connect(mapStateToProps)(
  React.memo(ObjectivesParticipationPage)
);
