import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  computeHistoricalCampaignONAData,
  createDistributionData,
} from './HistoryChartUtils';
import React, { useCallback, useContext } from 'react';

import { Bar } from 'react-chartjs-2';

import { ICONS } from '../../../../consts/consts';
import { getSign } from '../../../../utils/util/util';
import PersonPerformanceContext from '../PersonPerformanceContext';

interface GoldStarsDistributionChartProps {
  person?: {
    id: number;
    given_name: string;
  };
}

const GoldStarsDistributionChart: React.FC<GoldStarsDistributionChartProps> = ({
  person,
}) => {
  const personPerformanceContext = useContext(PersonPerformanceContext);
  const { formatMessage } = useIntl();
  const firstName = person?.given_name ?? '';

  const selectedCampaignData = personPerformanceContext.selectedCampaignData;

  const selectedCampaignPrevious =
    personPerformanceContext.previousCampaignDataWithOna;

  const selectedCampaignONA = computeHistoricalCampaignONAData(
    selectedCampaignData,
    selectedCampaignPrevious
  );

  const goldStarHelperText = formatMessage({
    id: 'performance.trajectory.summary.trajectory_summary.gold_star_helper_text',
    defaultMessage:
      'In this cycle, each person was able to award a maximum of three Gold Stars. Certain roles or groups of people may be more likely to receive Gold Stars. The absence of Gold Stars should not be interpreted as a signal of poor performance.',
  });

  const createDistribution = useCallback(
    (type) => {
      const data = createDistributionData(
        formatMessage,
        type,
        selectedCampaignData,
        selectedCampaignONA
      );

      return data ? (
        <Bar data={data.chartData} options={data.chartOptions} />
      ) : null;
    },
    [selectedCampaignData, selectedCampaignONA, formatMessage]
  );

  return (
    <>
      <Row>
        <Col>{createDistribution('gold_stars')}</Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h6 className="text-muted text-uppercase">
            <FormattedMessage
              id="app.views.person.person_performance.trajectory.trajectory_summary.gold_star_helper_text.how_to_read"
              defaultMessage="How to read this graph"
            />
          </h6>
          <p>{goldStarHelperText}</p>
          <hr />
          <p>
            {selectedCampaignONA?.goldStars?.percentile === null ? (
              <>
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.trajectory_summary.gold_star_helper_text.no_percentile"
                  defaultMessage="{firstName} received {selectedCampaignONAGoldStarCount} {icon} this cycle"
                  values={{
                    firstName: firstName,
                    icon: <i className={ICONS.GOLD_STAR} />,
                    selectedCampaignONAGoldStarCount:
                      selectedCampaignONA?.goldStars?.count ?? 0,
                  }}
                />
              </>
            ) : (
              <>
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.trajectory_summary.gold_star_helper_text.with_percentile"
                  defaultMessage="{firstName}'s {selectedCampaignONAGoldStarCount} {icon} this cycle places them in the <bold>{percentile, selectordinal, one {#st} two {#nd} few {#rd} other {#th} } percentile</bold> of the organization"
                  values={{
                    firstName: firstName,
                    icon: <i className={ICONS.GOLD_STAR} />,
                    selectedCampaignONAGoldStarCount:
                      selectedCampaignONA?.goldStars?.count ?? 0,
                    bold: (text) => <span className="fw-bold">{text}</span>,
                    percentile: selectedCampaignONA?.goldStars?.percentile ?? 0,
                  }}
                />
              </>
            )}
            {selectedCampaignPrevious ? (
              <>
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.trajectory_summary.gold_star_helper_text.previous_cycle"
                  defaultMessage=", which is <bold>{goldStarsDifferenceType, select, zero {no change} negative {a {goldStarsDifferenceAbsolute} {icon} decline} other {a {goldStarsDifferenceAbsolute} {icon} improvement}}</bold> from the previous cycle's result of {previousCampaignGoldStars} {icon} ({previousCampaignOnaPercentile, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} percentile)."
                  values={{
                    bold: (text) => <span className="fw-bold">{text}</span>,
                    icon: <i className={ICONS.GOLD_STAR} />,
                    goldStarsDifferenceType: getSign(
                      selectedCampaignONA?.goldStars?.difference
                    ),
                    goldStarsDifferenceAbsolute: Math.abs(
                      selectedCampaignONA?.goldStars?.difference || 0
                    ),
                    previousCampaignGoldStars:
                      selectedCampaignPrevious?.gold_stars?.length ?? 0,
                    previousCampaignOnaPercentile:
                      selectedCampaignPrevious?.ona_percentiles?.gold_stars ??
                      0,
                  }}
                />
              </>
            ) : (
              <>{'.'}</>
            )}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default GoldStarsDistributionChart;
