import * as consts from '../../../consts/consts';

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import {
  CAMPAIGN_STATUSES,
  getPhaseByType,
  getRelationships,
  replaceRelationships,
} from '../../../utils/models/Campaign';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_PEER_CONTRIBUTION_FEEDBACK,
  PHASE_TYPE_OTHERS,
  getCurrentPerformancePreviewPathPrefix,
  getFilteredPhaseOpenResponseQuestions,
  getPerformanceFeatureEnabled,
  getStepNumber,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../../utils/models/Performance';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';

import Avatar from '../../Widgets/People/Avatar';
import {
  INPUT_TYPES,
  getPeopleOrAllQuery,
} from '../../Widgets/Inputs/ValidatedInputTypes';
import ModalEditor from '../../Widgets/Modals/ModalEditor';
import ModalEditorButton from '../../Widgets/Modals/ModalEditorButton';
import PerformancePage from '../PerformancePage';
import PerformancePersonAssessment from '../PerformancePersonAssessment';
import PropTypes from 'prop-types';
import { RELATIONSHIP_TYPES } from '../../../utils/models/RelationshipUtils';
import ValidatedForm from '../../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { peopleObjectsAreEqual } from '../../../utils/models/Person';
import { relationshipIsCompleted } from '../../../utils/models/Relationship';
import { setCurrentPerfSurveyResponse } from '../../../actions';

const PerformancePeersSelectorPage = (props) => {
  const { formatMessage } = useIntl();

  const location = useLocation();
  const history = useHistory();

  const [isOnFirstPage, setIsOnFirstPage] = useState(true);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [showAddPeerModal, setShowAddPeerModal] = useState(false);
  const toggleAddPeerModal = () => setShowAddPeerModal(!showAddPeerModal);

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;

  const propsDemoPeople = props.demoPeople;
  const declineModalLearnMoreRef = useRef();

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const currentPeerIndex = useMemo(
    () =>
      new URLSearchParams(location.search).get('index')
        ? parseInt(new URLSearchParams(location.search).get('index'))
        : undefined,
    [location.search]
  );

  const currentPeer = useMemo(
    () =>
      typeof currentPeerIndex !== 'undefined'
        ? props.peers[currentPeerIndex]
        : undefined,
    [currentPeerIndex, props.peers]
  );

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const callback = useCallback(
    (data) => {
      if (data) {
        propsSetCurrentPerfSurveyResponse(
          updateLatestPerfStep(
            props.currentPerfSurveyResponse,
            currentStepNumber
          )
        );
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          history,
          propsDemoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      campaign,
      history,
      propsDemoPeople,
      formatMessage,
    ]
  );

  const isDemoOrPreviewMode = useMemo(
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    [campaign?.status]
  );

  const getRealOrDemoPeerRelationship = useCallback(
    (r) => {
      // simulate server response in demo, but for non-demo
      // get actual data from server
      return isDemoOrPreviewMode
        ? {
            from_person: props.me,
            type: RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR,
            ...r,
          }
        : r;
    },
    [props.me, isDemoOrPreviewMode]
  );

  const onDeclinePeer = useCallback(
    (data) => {
      if (data) {
        const r = getRealOrDemoPeerRelationship(data);
        setCampaign(
          replaceRelationships(
            campaign,
            [r.type],
            [r],
            r.from_person,
            r.to_person,
            true
          )
        );
      }
    },
    [campaign, getRealOrDemoPeerRelationship, setCampaign]
  );

  const onUndeclinePeer = useCallback(
    (data) => {
      if (data) {
        const r = getRealOrDemoPeerRelationship(data);
        setCampaign(
          replaceRelationships(
            campaign,
            [r.type],
            [r],
            r.from_person,
            r.to_person,
            true
          )
        );
      }
    },
    [campaign, getRealOrDemoPeerRelationship, setCampaign]
  );

  useEffect(() => {
    setIsOnFirstPage(true);

    // scroll to top so we see everything intended (in case we scrolled below before)
    window.scrollTo(0, 0);
  }, [currentPeer]);

  const relevantRelationships = getRelationships(
    campaign,
    RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
  );

  const peersStatuses = useMemo(
    () =>
      props.peers.map((dr) => {
        return relevantRelationships?.find(
          (r) =>
            !r.dataset &&
            peopleObjectsAreEqual(r.from_person, props.me) &&
            peopleObjectsAreEqual(r.to_person, dr) &&
            // ensure at least one positive skill is populated to indicate it is done
            relationshipIsCompleted(r, campaign)
        )
          ? 2
          : 0;
      }),
    [props.me, props.peers, relevantRelationships, campaign]
  );

  const peersRemaining = useMemo(
    () =>
      peersStatuses?.length > 0
        ? peersStatuses.filter((drs) => drs !== 2)?.length
        : 0,
    [peersStatuses]
  );

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  const setCurrentPeerIndex = useCallback(
    (index) => {
      history.push(
        (props.isOptional
          ? previewPathPrefix +
            consts.PERFORMANCE_STEP_ASSESS_OPTIONAL_PEERS().path
          : previewPathPrefix + consts.PERFORMANCE_STEP_ASSESS_PEERS().path) +
          '?index=' +
          index
      );
    },
    [history, previewPathPrefix, props.isOptional]
  );

  const object = useMemo(
    () => ({
      id: props.currentPerfSurveyResponse
        ? props.currentPerfSurveyResponse.id
        : undefined,
      campaign: campaign.id,
      step: currentStepNumber,
      responses: {},
    }),
    [currentStepNumber, campaign.id, props.currentPerfSurveyResponse]
  );

  const onSubmitAddPeer = useCallback(
    (data) => {
      if (data) {
        const r = getRealOrDemoPeerRelationship(data);
        setCampaign(
          replaceRelationships(
            campaign,
            [RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR],
            [r],
            r.from_person,
            r.to_person
          )
        );
      }
    },
    [campaign, getRealOrDemoPeerRelationship, setCampaign]
  );

  const transformAddPeerObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        campaign: isDemoOrPreviewMode ? undefined : campaign?.id,
        step: isDemoOrPreviewMode ? undefined : props.currentStepNumber,
        type: RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR,
        to_person: isDemoOrPreviewMode ? object.to_person : object.to_person.id,
      };
    },
    [campaign?.id, isDemoOrPreviewMode, props.currentStepNumber]
  );

  const transformDeclineOrUndeclinePeerObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        ...object,
        campaign: isDemoOrPreviewMode ? undefined : campaign?.id,
        to_person: isDemoOrPreviewMode ? object.to_person : object.to_person.id,
        // declined peer relationships affect the existing objects with
        // a dataset attached, so in demo mode, we need to include a
        // fake dataset object so the frontend honors them appropriately
        ...(isDemoOrPreviewMode ? { dataset: 'demo' } : {}),
      };
    },
    [campaign?.id, isDemoOrPreviewMode]
  );

  const existingRelationships = useMemo(
    () => campaign?.relationships || [],
    [campaign?.relationships]
  );

  const onValidateAddPeer = useCallback(
    (obj) => {
      let errors = {};

      // ensure person is selected
      if (!obj.to_person) {
        errors['to_person'] =
          'Enter the name of the person you want to give feedback to.';
      }

      // ensure you aren't selecting yourself
      else if (peopleObjectsAreEqual(props.me, obj.to_person)) {
        errors['to_person'] = "You can't give yourself peer feedback.";
      }

      // ensure not already on list of peers that you have already given feedback
      // to (NOTE: we check !r.dataset here to avoid the case where someone is
      // on the waitlist but the person didn't get to them; it's true that this
      // means someone might add an optional peer that they already have on their
      // main peer list, but we allow this edge case to avoid this waitlist issue)
      else if (obj.to_person) {
        if (
          existingRelationships?.findIndex(
            (r) =>
              !r.dataset &&
              r.type ===
                RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR &&
              peopleObjectsAreEqual(r.from_person, props.me) &&
              peopleObjectsAreEqual(obj.to_person, r.to_person)
          ) !== -1
        ) {
          errors['to_person'] = formatMessage({
            id: 'app.views.performance.peers.performance_peers_selector_page.this_person_is_already_on_your_peer_feedback_list',
            defaultMessage:
              'This person is already on your peer feedback list.',
          });
        }
      }

      return errors;
    },
    [existingRelationships, props.me, formatMessage]
  );

  const allowContributionFeedback = getPerformanceFeatureEnabled(
    campaign,
    PERFORMANCE_FEATURE_PEER_CONTRIBUTION_FEEDBACK
  );

  const filteredOpenQuestions = getFilteredPhaseOpenResponseQuestions({
    targetPerson: currentPeer,
    campaign,
    phase: getPhaseByType(campaign, PHASE_TYPE_OTHERS),
  });

  return (
    <PerformancePage
      campaign={campaign}
      title={currentPeer ? currentPeer.full_name : props.title}
    >
      <>
        <>
          {!currentPeer && isOnFirstPage && (
            <>
              <Row>
                <Col>
                  {peersRemaining > 0 && (
                    <span className="text-muted me-4">
                      <FormattedMessage
                        id="app.views.performance.peers.performance_peers_selector_page.peers_remaining"
                        defaultMessage="
                      {count, plural , one {1 peer left} other {{count} peers left}}
                    "
                        values={{ count: peersRemaining }}
                      />
                    </span>
                  )}
                  {peersRemaining === 0 &&
                    (props.isOptional ||
                      props.peers?.length > 0 ||
                      props.declinedPeers?.length > 0) && (
                      <ValidatedForm
                        buttonClassName="mt-0"
                        method={object.id ? 'PATCH' : 'POST'}
                        url={
                          isDemoOrPreviewMode ? undefined : 'survey-responses'
                        }
                        buttonIsBlock={false}
                        object={object}
                        inputs={[]}
                        callback={callback}
                        submitText={
                          props.isOptional && props.peers?.length === 0
                            ? formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.button.skip',
                                defaultMessage: 'Skip',
                              })
                            : formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.button.continue',
                                defaultMessage: 'Continue',
                              })
                        }
                      />
                    )}
                  {peersRemaining > 0 && (
                    <Button className="mt-0" disabled color="primary">
                      <FormattedMessage
                        id="app.views.performance.peers.performance_peers_selector_page.button.continue"
                        defaultMessage="
                      Continue
                    "
                      />
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
          {allowContributionFeedback && currentPeer && !isOnFirstPage && (
            <>
              <span
                className="text-primary"
                role="button"
                onClick={() => setIsOnFirstPage(true)}
              >
                <FormattedMessage
                  id="app.views.performance.peers.performance_peers_selector_page.button.go_back"
                  defaultMessage="
                Go back
              "
                />
              </span>
              {<span className="text-muted mx-3">{'∙'}</span>}
            </>
          )}
          {allowContributionFeedback && currentPeer && (
            <span className="text-muted me-4">
              <FormattedMessage
                id="app.views.performance.peers.performance_peers_selector_page.step_1_of_2"
                defaultMessage="Step 1 of 2"
              />
            </span>
          )}
          {allowContributionFeedback && currentPeer && isOnFirstPage && (
            <Button
              color="primary"
              disabled={isContinueDisabled}
              onClick={() => setIsOnFirstPage(false)}
            >
              <FormattedMessage
                id="app.views.performance.peers.performance_peers_selector_page.button.save_and_continue"
                defaultMessage="

              Save and continue
            "
              />
            </Button>
          )}
        </>
      </>
      {!props.isOptional &&
        !currentPeer &&
        !(props.peers?.length > 0) &&
        !(props.declinedPeers?.length > 0) && (
          <>
            <div className="mb-4">
              <FormattedMessage
                id="app.views.performance.peers.performance_peers_selector_page.you_do_not_have_any_peer_feedback_to_provide"
                defaultMessage="
              You do not have any peer feedback to provide. You are free to skip
              this step!
            "
              />
            </div>
            <ValidatedForm
              buttonClassName="mt-3"
              method={object.id ? 'PATCH' : 'POST'}
              url={isDemoOrPreviewMode ? undefined : 'survey-responses'}
              buttonIsBlock={false}
              object={object}
              inputs={[]}
              callback={callback}
              submitText={formatMessage({
                id: 'app.views.performance.peers.performance_peers_selector_page.submit_text.continue',
                defaultMessage: 'Continue',
              })}
            />
          </>
        )}
      {currentPeer && (
        <PerformancePersonAssessment
          campaign={campaign}
          setCampaign={props.setCampaign}
          // subtract 1 because we don't consider the step done until we submit
          // ALL the peers (not just this individual one)
          currentStepNumber={currentStepNumber - 1}
          relationshipType={
            RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR
          }
          callback={callback}
          person={currentPeer}
          isPeer={true}
          isOptionalPeer={props.isOptional}
          isFormerManager={false} // TODO: calculate this
          allowContributionFeedback={allowContributionFeedback}
          isDirectReport={false}
          isOnFirstPage={isOnFirstPage}
          setIsOnFirstPage={setIsOnFirstPage}
          setIsContinueDisabled={setIsContinueDisabled}
          openResponseQuestions={filteredOpenQuestions}
        />
      )}
      {!currentPeer &&
        (props.isOptional ||
          props.peers?.length > 0 ||
          props.declinedPeers?.length > 0) && (
          <>
            {props.children}
            <Row>
              {props.peers.map((p, index) => {
                const peerRelationshipObject = existingRelationships?.find(
                  (r) =>
                    !r.dataset &&
                    r.type ===
                      RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR &&
                    peopleObjectsAreEqual(r.from_person, props.me) &&
                    peopleObjectsAreEqual(r.to_person, p)
                );

                const optionalPeerObject = {
                  id: peerRelationshipObject?.id
                    ? peerRelationshipObject.id
                    : undefined,
                  type: RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR,
                  campaign: campaign?.id,
                  person: p.id,
                };

                const onRemovePeer = (data) => {
                  if (data) {
                    setCampaign(
                      replaceRelationships(
                        campaign,
                        [
                          RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR,
                        ],
                        [],
                        peerRelationshipObject.from_person,
                        peerRelationshipObject.to_person
                      )
                    );
                  }
                };

                return (
                  <Col className="col-xl-4 col-md-6" key={index}>
                    <Card className="card-fill">
                      {peersStatuses[index] === 2 && (
                        <div
                          className="avatar avatar-sm"
                          style={{
                            position: 'absolute',
                            top: '-.85rem',
                            right: '-.85rem',
                          }}
                        >
                          <div className="avatar-title fs-lg bg-success rounded-circle text-white">
                            <i className="fe fe-check"></i>
                          </div>
                        </div>
                      )}
                      <CardBody className="text-center">
                        <Avatar size="lg" className="mb-3" person={p} />
                        <h3 className="card-title mb-1">{p.full_name}</h3>
                        <>
                          <div className="small text-muted mb-4">
                            {peersStatuses[index] === 0 &&
                              formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.not_started',
                                defaultMessage: 'Not started',
                              })}
                            {peersStatuses[index] === 1 &&
                              formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.in_progress',
                                defaultMessage: 'In progress',
                              })}
                            {peersStatuses[index] === 2 &&
                              formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.complete',
                                defaultMessage: 'Complete',
                              })}
                          </div>
                          <Button
                            color={
                              peersStatuses[index] === 2 ? 'white' : 'primary'
                            }
                            className="mb-3 w-100 btn-sm btn-block"
                            onClick={() => setCurrentPeerIndex(index)}
                          >
                            {peersStatuses[index] === 0 &&
                              formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.give_feedback',
                                defaultMessage: 'Give feedback',
                              })}
                            {peersStatuses[index] === 1 &&
                              formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.complete_feedback',
                                defaultMessage: 'Complete feedback',
                              })}
                            {peersStatuses[index] === 2 &&
                              formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.edit_feedback',
                                defaultMessage: 'Edit feedback',
                              })}
                          </Button>
                          {!props.isOptional && peersStatuses[index] !== 2 && (
                            <ModalEditorButton
                              method="POST"
                              url={
                                isDemoOrPreviewMode
                                  ? undefined
                                  : 'performance/decline-peer'
                              }
                              buttonClassName="w-100 btn-sm btn-block"
                              color="white"
                              modalTitle={formatMessage(
                                {
                                  id: 'app.views.performance.peers.performance_peers_selector_page.modalTitle.decline_to_give_feedback_to',
                                  defaultMessage:
                                    'Decline to give feedback to {personName}',
                                },
                                {
                                  personName: p.given_name,
                                }
                              )}
                              title={formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.title.decline',
                                defaultMessage: 'Decline',
                              })}
                              submitText={formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.submit_text.decline',
                                defaultMessage: 'Decline',
                              })}
                              transformObjectBeforeSubmit={
                                transformDeclineOrUndeclinePeerObjectBeforeSubmit
                              }
                              object={{
                                to_person: p,
                              }}
                              callback={onDeclinePeer}
                              inputs={[
                                {
                                  type: INPUT_TYPES.MULTIPLE_CHOICE,
                                  required: true,
                                  name: 'decline_reason',
                                  label: (
                                    <div>
                                      <div className="mb-4">
                                        <div>
                                          <FormattedMessage
                                            id="app.views.performance.peers.performance_peers_selector_page.confirm_picked_you"
                                            defaultMessage="
                                          Confirm picked you as someone outside
                                          of {given_name}'s day-to-day colleagues. You don't need to
                                          know {given_name}'s work closely to give {given_name} feedback."
                                            values={{
                                              given_name: p.given_name,
                                            }}
                                          />{' '}
                                          <span
                                            className="text-primary"
                                            ref={declineModalLearnMoreRef}
                                          >
                                            <FormattedMessage
                                              id="app.views.performance.peers.performance_peers_selector_page.learn_more"
                                              defaultMessage="
                                            Learn more
                                          "
                                            />
                                          </span>
                                        </div>
                                        <UncontrolledPopover
                                          delay={50}
                                          trigger="hover"
                                          placement="top"
                                          target={declineModalLearnMoreRef}
                                        >
                                          <FormattedMessage
                                            id="app.views.performance.peers.performance_peers_selector_page.we_selected"
                                            defaultMessage="
                                          We selected other people to provide
                                          work-related feedback to {given_name}. We need your help to
                                          provide a broader perspective. Even a
                                          small amount of feedback on casual
                                          interactions is better than declining."
                                            values={{
                                              given_name: p.given_name,
                                            }}
                                          />
                                        </UncontrolledPopover>
                                      </div>
                                      <div className="mb-1">
                                        <FormattedMessage
                                          id="app.views.performance.peers.performance_peers_selector_page.still_want_to_proceed"
                                          defaultMessage="
                                        If you still want to proceed, can you
                                        tell us why you declined to give
                                        feedback to {given_name}?"
                                          values={{
                                            given_name: p.given_name,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ),
                                  allowCustom: true,
                                  customOptionText: 'Other',
                                  options: [
                                    {
                                      id: 'U',
                                      name: formatMessage(
                                        {
                                          id: 'app.views.performance.peers.performance_peers_selector_page.i_dont_know',
                                          defaultMessage:
                                            "I don't know {personName}.",
                                        },
                                        { personName: p.given_name }
                                      ),
                                    },
                                    {
                                      id: 'F',
                                      name: formatMessage(
                                        {
                                          id: 'app.views.performance.peers.performance_peers_selector_page.i_dont_have_interactions',
                                          defaultMessage:
                                            'I have not interacted with {personName} in the last six months.',
                                        },
                                        {
                                          personName: p.given_name,
                                        }
                                      ),
                                    },
                                    {
                                      id: 'I',
                                      name: formatMessage(
                                        {
                                          id: 'app.views.performance.peers.performance_peers_selector_page.i_have_an_interpersonal_issue',
                                          defaultMessage:
                                            'I have an interpersonal issue with {personName}.',
                                        },
                                        {
                                          personName: p.given_name,
                                        }
                                      ),
                                    },
                                  ],
                                },
                              ]}
                            />
                          )}
                          {props.isOptional && peersStatuses[index] !== 2 && (
                            <ModalEditorButton
                              method="DELETE"
                              url={
                                isDemoOrPreviewMode
                                  ? undefined
                                  : 'relationships'
                              }
                              buttonClassName="btn-sm btn-block"
                              color="white"
                              modalTitle={formatMessage(
                                {
                                  id: 'app.views.performance.peers.performance_peers_selector_page.modalTitle.remove_person',
                                  defaultMessage: 'Remove {personName}',
                                },
                                { personName: p.given_name }
                              )}
                              title={formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.title.remove_peer',
                                defaultMessage: 'Remove peer',
                              })}
                              submitText={formatMessage({
                                id: 'app.views.performance.peers.performance_peers_selector_page.submit_text.remove_peer',
                                defaultMessage: 'Remove peer',
                              })}
                              object={optionalPeerObject}
                              callback={onRemovePeer}
                            >
                              <span>
                                <FormattedMessage
                                  id="app.views.performance.peers.performance_peers_selector_page.are_you_sure"
                                  defaultMessage="
                                Are you sure you no longer want to give this
                                person feedback?
                              "
                                />
                              </span>
                            </ModalEditorButton>
                          )}
                        </>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
              {props.declinedPeers?.map((p, index) => {
                return (
                  <Col className="col-xl-4 col-md-6" key={index}>
                    <Card className="card-fill">
                      <div
                        className="avatar avatar-sm"
                        style={{
                          position: 'absolute',
                          top: '-.85rem',
                          right: '-.85rem',
                        }}
                      >
                        <div className="avatar-title fs-lg bg-danger rounded-circle text-white">
                          <i className="fe fe-x"></i>
                        </div>
                      </div>
                      <CardBody className="text-center">
                        <Avatar size="lg" className="mb-3" person={p} />
                        <h3 className="card-title mb-1">{p.full_name}</h3>
                        <>
                          <div className="small text-muted mb-4">
                            <FormattedMessage
                              id="app.views.performance.peers.performance_peers_selector_page.declined"
                              defaultMessage="Declined"
                            />
                          </div>

                          <ModalEditorButton
                            method="POST"
                            url={
                              isDemoOrPreviewMode
                                ? undefined
                                : 'performance/undecline-peer'
                            }
                            buttonClassName="w-100 btn-sm btn-block"
                            color="white"
                            modalTitle={formatMessage(
                              {
                                id: 'app.views.performance.peers.performance_peers_selector_page.modalTitle.undecline_person',
                                defaultMessage: 'Undecline {personName}',
                              },
                              {
                                personName: p.given_name,
                              }
                            )}
                            title={formatMessage({
                              id: 'app.views.performance.peers.performance_peers_selector_page.title.undecline',
                              defaultMessage: 'Undecline',
                            })}
                            submitText={formatMessage({
                              id: 'app.views.performance.peers.performance_peers_selector_page.submit_text.undecline',
                              defaultMessage: 'Undecline',
                            })}
                            object={{
                              to_person: p,
                            }}
                            transformObjectBeforeSubmit={
                              transformDeclineOrUndeclinePeerObjectBeforeSubmit
                            }
                            callback={onUndeclinePeer}
                          >
                            <>
                              <FormattedMessage
                                id="app.views.performance.peers.performance_peers_selector_page.do_you_want_to_undecline"
                                defaultMessage="
                                Do you want to undecline {given_name} so you can provide feedback?"
                                values={{
                                  given_name: p.given_name,
                                }}
                              />
                            </>
                          </ModalEditorButton>
                        </>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
              {props.additionalPeerSlots > 0 && (
                <>
                  <ModalEditor
                    isOpen={showAddPeerModal}
                    toggle={toggleAddPeerModal}
                    method={'POST'}
                    url={isDemoOrPreviewMode ? undefined : 'relationships'}
                    title={formatMessage({
                      id: 'app.views.performance.peers.performance_peers_selector_page.title.add_optional_peer',
                      defaultMessage: 'Add optional peer',
                    })}
                    object={{}}
                    callback={onSubmitAddPeer}
                    transformObjectBeforeSubmit={
                      transformAddPeerObjectBeforeSubmit
                    }
                    onValidate={onValidateAddPeer}
                    submitText={formatMessage({
                      id: 'app.views.performance.peers.performance_peers_selector_page.submit_text.add_peer',
                      defaultMessage: 'Add peer',
                    })}
                    inputs={[
                      {
                        type: INPUT_TYPES.SELECT,
                        isDemoMode: isDemoOrPreviewMode,
                        name: 'to_person',
                        label: formatMessage({
                          id: 'app.views.performance.peers.performance_peers_selector_page.who_would_you_like_to_provide_feedback_to',
                          defaultMessage:
                            'Who would you like to provide feedback to?',
                        }),
                        clearable: false,
                        elasticsearchOptions: {
                          index: 'people',
                          url: 'get-people-by-name',
                          getQuery: getPeopleOrAllQuery,
                        },
                      },
                    ]}
                  />
                  {[...Array(props.additionalPeerSlots).keys()].map(
                    (i, index) => (
                      <div
                        className="col-xl-4 col-md-6 col"
                        key={index}
                        onClick={toggleAddPeerModal}
                      >
                        <div className="card-fill card bg-transparent select-peer-card">
                          <div className="text-center card-body py-6">
                            <div className="avatar avatar-md mb-3">
                              <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                                <i className="fe fe-plus"></i>
                              </div>
                            </div>
                            <div className="text-muted">
                              <FormattedMessage
                                id="app.views.performance.peers.performance_peers_selector_page.select_a_peer"
                                defaultMessage="Select a peer"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </Row>
          </>
        )}
    </PerformancePage>
  );
};

PerformancePeersSelectorPage.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  peers: PropTypes.arrayOf(PropTypes.object).isRequired,
  declinedPeers: PropTypes.arrayOf(PropTypes.object),
  additionalPeerSlots: PropTypes.number,
  isOptional: PropTypes.bool.isRequired,
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentPerfSurveyResponse, demoPeople } =
    state;

  return {
    me,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(React.memo(PerformancePeersSelectorPage)));
