import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import Page from '../Page';

const Page500: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Page
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page500.title.server_error',
        defaultMessage: 'Server Error',
      })}
    >
      <p>
        <FormattedMessage
          id="app.views.layout.pages.errors.page500.message"
          defaultMessage="
        A notification was just sent to the team of the issue. We'll look
        into it as soon as possible. Sorry about that!
      "
        />
      </p>
    </Page>
  );
};

export default React.memo(Page500);
