import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

const SkillActionButtons: FC<Props> = (props) => {
  return (
    <>
      {!props.currentUserHasDeclaredSkill && (
        <Button
          color="light"
          disabled={
            props.skillsToAdd.length > 0 || props.skillsToRemove.length > 0
          }
          onClick={props.addSkill}
        >
          {props.skillsToAdd.includes(props.skill) && (
            <div
              className="spinner-border me-2"
              style={{ width: '1rem', height: '1rem' }}
            />
          )}
          <span>
            <FormattedMessage
              id="app.views.skills.skill_action_buttons.add_to_resume"
              defaultMessage="Add to resume"
            />
          </span>
        </Button>
      )}
      {props.currentUserHasDeclaredSkill && (
        <>
          <UncontrolledDropdown
            className="ms-3 d-inline-block"
            disabled={
              props.skillsToAdd.length > 0 || props.skillsToRemove.length > 0
            }
          >
            <DropdownToggle
              tag="button"
              className="btn btn-light"
              role="button"
            >
              {!props.skillsToRemove.includes(props.skill) && (
                <i className="fe fe-more-horizontal"></i>
              )}
              {props.skillsToRemove.includes(props.skill) && (
                <div
                  className="spinner-border"
                  style={{ width: '1rem', height: '1rem' }}
                />
              )}
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={props.removeSkill}>
                <FormattedMessage
                  id="app.views.skills.skill_action_buttons.dropdown.remove_from_resume"
                  defaultMessage="
                Remove from resume
              "
                />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )}
    </>
  );
};

const SkillActionButtons_propTypes = {
  currentUserHasDeclaredSkill: PropTypes.bool.isRequired,
  skill: PropTypes.object.isRequired,
  skillsToAdd: PropTypes.arrayOf(PropTypes.object).isRequired,
  skillsToRemove: PropTypes.arrayOf(PropTypes.object).isRequired,
  addSkill: PropTypes.func.isRequired,
  removeSkill: PropTypes.func.isRequired,
};

type Props = PropTypes.InferProps<typeof SkillActionButtons_propTypes>;

export default React.memo(SkillActionButtons);
