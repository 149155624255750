import React, { FC } from 'react';

import PropTypes from 'prop-types';
import UserAdministration from '../Organization/UserAdministration';
import { connect } from 'react-redux';

const PeopleAdministrationAdministrators: FC<Props> = () => {
  // TODO: for now, use same content as confirm admin page
  return <UserAdministration />;
};

const PeopleAdministrationAdministrators_PropTypes = {
  showAllColumns: PropTypes.bool.isRequired,
  setHasAtLeastOneHideByDefaultColumn: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
};

type Props = PropTypes.InferProps<
  typeof PeopleAdministrationAdministrators_PropTypes
>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(
  React.memo(PeopleAdministrationAdministrators)
);
