import { ApiTimeFrame, TimeFrame, TimeFrameType } from '../../types';
import { addDays, addMonths, differenceInDays } from 'date-fns';

import { calculatePeriodForTimeFrameType } from '../../views/Objectives/ObjectivesTimeFrameSelector/utils';
import { formatDateWithUnicode } from '../util/time';
import { getQuarterDate } from '../models/Activity';

// Return the next timeframe if the pivot date is within the last 1/8 of the timeframe
// (The logic follows the same logic as per the quarter)
// assuming that a yearly review set after the second half of the Q4 in a Year means that the review is for the next year
const findYearWithNearestStartDate = (
  pivotDate: Date,
  startPeriodMMDD: string
): { start: Date; end: Date } => {
  const timeFrameIncludingPivotDate = calculatePeriodForTimeFrameType({
    date: pivotDate,
    type: 'YEAR',
    startPeriodMMDD,
  });

  const daysInTimeFrame = differenceInDays(
    timeFrameIncludingPivotDate.end,
    timeFrameIncludingPivotDate.start
  );

  const daysLeftInTimeFrame = differenceInDays(
    timeFrameIncludingPivotDate.end,
    pivotDate
  );

  if (daysLeftInTimeFrame < daysInTimeFrame / 8) {
    const { start, end } = calculatePeriodForTimeFrameType({
      date: addDays(timeFrameIncludingPivotDate.end, 1),
      type: 'YEAR',
      startPeriodMMDD,
    });
    return { start, end };
  }

  return {
    start: timeFrameIncludingPivotDate.start,
    end: timeFrameIncludingPivotDate.end,
  };
};

const findQuarterWithNearestStartDate = (pivotDate: Date) => {
  const startQuarterDate = getQuarterDate(pivotDate);
  const endQuarterDate = getQuarterDate(pivotDate, true);
  const isStartInFirstPartOfQuarter =
    pivotDate.valueOf() - startQuarterDate.valueOf() <
    endQuarterDate.valueOf() - pivotDate.valueOf();

  const start = isStartInFirstPartOfQuarter
    ? startQuarterDate
    : getQuarterDate(addMonths(startQuarterDate, 3));
  const end = isStartInFirstPartOfQuarter
    ? endQuarterDate
    : getQuarterDate(addMonths(endQuarterDate, 3), true);

  return { start, end };
};

export const findNearestTimeFrame = (
  date: Date,
  type: TimeFrameType,
  startPeriodMMDD: string
): TimeFrame => {
  switch (type) {
    case 'QUARTER':
      return { ...findQuarterWithNearestStartDate(date), type };
    case 'YEAR':
      return { ...findYearWithNearestStartDate(date, startPeriodMMDD), type };
  }
};

export const toTimeFrameDateFormat = (
  timeFrame: TimeFrame,
  format: string = 'yyyy-MM-dd'
): ApiTimeFrame => {
  return {
    ...timeFrame,
    start: formatDateWithUnicode(timeFrame.start, format),
    end: formatDateWithUnicode(timeFrame.end, format),
  };
};
