import React, { FC, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';

type Props = {
  network?: boolean;
  data?: object[];
};

const VisualizationTableCard: FC<Props> = ({ network = false, data = [] }) => {
  const header = useMemo(() => {
    return (
      <tr>
        <th className="ps-0 pe-2">
          <FormattedMessage
            id="app.views.widgets.cards.visualization_table_card.source"
            defaultMessage="Source"
          />
        </th>
        {!network && (
          <th className="ps-0 pe-2">
            <FormattedMessage
              id="app.views.widgets.cards.visualization_table_card.target"
              defaultMessage="Target"
            />
          </th>
        )}
        <th className="ps-0 pe-2 text-end">{network ? 'People' : 'Given'}</th>
        <th className="ps-0 pe-2 text-end">
          {network ? 'Influence' : 'Received'}
        </th>
      </tr>
    );
  }, [network]);

  const body = useMemo(() => {
    return data.map((p, pIndex) => (
      <tr key={pIndex}>
        {/* @ts-expect-error */}
        <td className="ps-0 pe-2">{p.source}</td>
        {/* @ts-expect-error */}
        {!network && <td className="ps-0 pe-2">{p.target}</td>}
        {/* @ts-expect-error */}
        <td className="ps-0 pe-2 text-end">{p.valueOne}</td>
        <td className="ps-0 pe-2 text-end">
          {/* @ts-expect-error */}
          {p.source === p.target ? '-' : p.valueTwo}
        </td>
      </tr>
    ));
  }, [data, network]);

  return (
    <div style={{ height: '25rem', overflow: 'auto' }}>
      <Table className="card-table">
        <thead style={{ position: 'sticky', top: '0px', margin: '0px' }}>
          {header}
        </thead>
        <tbody>{body}</tbody>
      </Table>
    </div>
  );
};

export default React.memo(VisualizationTableCard);
