import {
  Action,
  AppIntegration as AppIntegrationT,
  Organization,
  Person,
} from '../../types';
import { Button, Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { MISSING_PERMISSIONS, SUBMIT_ERROR } from '../../consts/consts';
import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import {
  addAppIntegration,
  loadAppIntegrations,
  updateAppIntegration,
} from '../../actions';

import AppIntegration from './AppIntegration';
import AppIntegrationListing from './AppIntegrationListing';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import { connect } from 'react-redux';
import { formatErrorMessage } from 'utils/util/error';
import { getFriendlyUserFacingErrorObjectAndMessage } from '../../utils/util/util';
import { toast } from 'react-toastify';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useLocation } from 'react-router-dom';
import {
  processRipplingData,
  ripplingRemoveCookie,
} from 'utils/util/MergeRipplingUtils';
import AnnouncementBar from 'views/Layout/AnnouncementBar';

interface Props {
  addAppIntegration: (
    appIntegration: AppIntegrationT
  ) => Action<'ADD_APP_INTEGRATION', { appIntegration: AppIntegrationT }>;
  appIntegrations: Array<AppIntegrationT>;
  currentOrganization?: Organization;
  currentProxyPerson?: Person;
  loadAppIntegrations: (
    organization_id?: number,
    proxy?: string
  ) => Action<
    'LOAD_APP_INTEGRATIONS',
    { organization_id?: number; proxy?: string }
  >;
  loading: boolean;
  updateAppIntegration: (
    appIntegration: AppIntegrationT
  ) => Action<'UPDATE_APP_INTEGRATION', { appIntegration: AppIntegrationT }>;
}

const AppIntegrations: FC<Props> = ({
  addAppIntegration,
  appIntegrations,
  currentOrganization,
  currentProxyPerson,
  loadAppIntegrations,
  loading,
  updateAppIntegration,
}: Props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const [linkToken, setLinkToken] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  let ripplingCode: string | null = null;
  let ripplingRedirectURI: string | null = null;
  const ripplingData = processRipplingData(location.search);
  if (ripplingData) {
    ripplingCode = ripplingData.code;
    ripplingRedirectURI = ripplingData.redirect_uri;
  }

  const onSuccess = useCallback(
    (public_token) => {
      // Send public_token to server
      return ConfirmAPI.sendRequestToConfirm(
        'POST',
        'integrations/save-public-token',
        {
          organization: currentOrganization?.id,
          public_token: public_token,
        },
        (data, error, hardErrorMessage) => {
          if (data?.app_integration) {
            if (ripplingRedirectURI) {
              // This needs to run first to avoid re-rendering, any re-render
              // would set the cookies again if the QS is present in the URL.
              ripplingRemoveCookie(ripplingRedirectURI);
              return;
            }

            if (data?.created) {
              addAppIntegration(data.app_integration);
              toast.success(
                formatMessage({
                  id: 'app.views.app_integrations.app_integrations.integration_complete',
                  defaultMessage:
                    'Integration complete! Please wait up to 24 hours for data to begin syncing.',
                })
              );
            } else {
              updateAppIntegration(data.app_integration);
              toast.success(
                formatMessage({
                  id: 'app.views.app_integrations.app_integrations.integration_updated',
                  defaultMessage:
                    'Integration updated! Please wait up to 24 hours for data to sync.',
                })
              );
            }
          }

          if (error || hardErrorMessage) {
            const [errorObject, friendlyErrorMessage] =
              getFriendlyUserFacingErrorObjectAndMessage(
                error,
                hardErrorMessage
              );
            console.error(
              'Error saving public token: ' + JSON.stringify(errorObject)
            );
            toast.error(friendlyErrorMessage);
          }
        }
      );
    },
    [
      addAppIntegration,
      currentOrganization?.id,
      formatMessage,
      ripplingRedirectURI,
      updateAppIntegration,
    ]
  );

  const onExit = () => {
    // clear link token which can only be used once (this will auto-trigger
    // fetching a new one for another integration so they use can do both
    // ATS and HRIS in one session)
    setLinkToken(undefined);
  };

  const { open, isReady } = useMergeLink({
    linkToken: linkToken ?? '', // Workaround for typechecking.
    onSuccess: onSuccess,
    onExit: onExit,
    // hidden flag which will pass the token at the time the integration finishes
    shouldSendTokenOnSuccessfulLink: true,
  });

  useEffect(() => {
    // fetch appIntegrations from backend
    loadAppIntegrations(currentOrganization?.id, currentProxyPerson?.email);
  }, [loadAppIntegrations, currentProxyPerson?.email, currentOrganization?.id]);

  // update UI any time app integrations change
  useEffect(() => {
    /* DO NOTHING */
  }, [appIntegrations]);

  useEffect(() => {
    // if linktoken set, don't try to fetch another one
    if (typeof linkToken !== 'undefined') {
      return;
    }

    // fetch merge.dev link token
    return ConfirmAPI.sendRequestToConfirm(
      'GET',
      'integrations/get-link-token',
      {
        organization: currentOrganization?.id,
        proxy: currentProxyPerson?.email,
        rippling_code: ripplingCode,
        rippling_redirect_uri: ripplingRedirectURI,
      },
      (data, error, hardErrorMessage) => {
        if (data) {
          setLinkToken(data?.link_token);
          return;
        }

        console.error(error, hardErrorMessage);

        if (error.status === 500) {
          // These are likely merge.dev API errors.
          const [, friendlyErrorMessage] =
            getFriendlyUserFacingErrorObjectAndMessage(error, hardErrorMessage);
          setErrorMessage(friendlyErrorMessage);
        } else if (error.status === 403) {
          // getFriendlyUserFacingErrorObjectAndMessage returns a null
          // friendlyErrorMessage on 404 and 403.
          setErrorMessage(MISSING_PERMISSIONS);
        } else {
          // Generic unexpected error.
          setErrorMessage(SUBMIT_ERROR);
        }
        console.error('Error getting link token: ' + hardErrorMessage);
        console.error('Error getting link token: ' + JSON.stringify(error));
      }
    );
  }, [
    currentOrganization?.id,
    currentProxyPerson,
    linkToken,
    ripplingCode,
    ripplingRedirectURI,
  ]);

  const addHRISButtonRef = useRef<HTMLButtonElement>(null);
  const selectHRISButtonRef = useRef<HTMLButtonElement>(null);

  const [autoClick, setAutoClick] = useState(false);

  useEffect(() => {
    if (ripplingData && !autoClick) {
      if (addHRISButtonRef.current) {
        addHRISButtonRef.current!.click();
        setAutoClick(true);
      }
      if (selectHRISButtonRef.current) {
        selectHRISButtonRef.current!.click();
        setAutoClick(true);
      }
    }
  }, [ripplingData, autoClick]);

  const announcementBar = ripplingData ? (
    <AnnouncementBar
      alertType={'light'}
      className={'mb-5'}
      message={
        <FormattedMessage
          id="app.views.app_integrations.app_integrations.in_rippling_install_flow"
          defaultMessage="You have a pending Rippling installation. You can't leave this page until the process is complete. If you cancel the installation, you will need to start it again from Rippling."
        />
      }
      actions={[
        {
          label: formatMessage({
            id: 'app.views.app_integrations.app_integrations.rippling_announcment_bar.cancel_button.label',
            defaultMessage: 'Cancel Rippling Installation',
          }),
          // With the redirect we ensure code and redirect_uri are rmoved from the url,
          // otherwise Cookies will be re-set again.
          onClick: () => ripplingRemoveCookie('/integrations'),
          color: 'danger',
        },
      ]}
    />
  ) : undefined;

  return (
    <Page
      pretitle={formatMessage({
        id: 'app.views.app_integrations.app_integrations.pretitle.integrations',
        defaultMessage: 'Integrations',
      })}
      title={formatMessage({
        id: 'app.views.app_integrations.app_integrations.title.hris_and_ats_integrations',
        defaultMessage: 'HRIS and ATS integrations',
      })}
    >
      {linkToken && appIntegrations?.length > 0 && (
        <Button
          color="primary"
          disabled={!isReady}
          onClick={open}
          innerRef={addHRISButtonRef}
        >
          <FormattedMessage
            id="app.views.app_integrations.app_integrations.hris.button.add.text"
            defaultMessage="Add or update HRIS or ATS integration"
          />
        </Button>
      )}
      {typeof linkToken === 'undefined' && !errorMessage && <Loading />}
      {linkToken && appIntegrations.length === 0 && (
        <>
          {announcementBar}
          <EmptyState
            title={formatMessage({
              id: 'app.views.app_integrations.app_integrations.title.keep_your_people_data_synced_with',
              defaultMessage: 'Keep your people data synced with Confirm.',
            })}
            subtitle={formatMessage({
              id: 'app.views.app_integrations.app_integrations.subtitle.connect_your_hris_to_sync_names',
              defaultMessage:
                'Connect your HRIS to sync names, managers, departments, etc.',
            })}
          >
            <Button
              color="primary"
              disabled={!isReady}
              onClick={open}
              innerRef={selectHRISButtonRef}
            >
              <FormattedMessage
                id="app.views.app_integrations.app_integrations.hris.button.select.text"
                defaultMessage="Select HRIS or ATS integration"
              />
            </Button>
          </EmptyState>
        </>
      )}
      {linkToken && !loading && appIntegrations.length > 0 && (
        <>
          {announcementBar}
          {appIntegrations.map((appIntegration) => {
            return (
              <AppIntegration
                key={appIntegration.id}
                appIntegration={appIntegration}
              />
            );
          })}
        </>
      )}
      {errorMessage && (
        <>
          {announcementBar}
          <Card>
            <CardBody>
              <p>{formatErrorMessage(errorMessage)}</p>
            </CardBody>
          </Card>
        </>
      )}
      <AppIntegrationListing />
    </Page>
  );
};

// These props come from the application's
// state when it is started
const mapStateToProps = (state) => {
  const {
    loading,
    currentOrganization,
    appIntegrations,
    renderErrorMessage,
    currentProxyPerson,
  } = state;

  return {
    loading,
    currentOrganization,
    appIntegrations,
    renderErrorMessage,
    currentProxyPerson,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAppIntegrations: (organization_id, proxy) =>
      dispatch(loadAppIntegrations(organization_id, proxy)),
    addAppIntegration: (appIntegration) =>
      dispatch(addAppIntegration(appIntegration)),
    updateAppIntegration: (appIntegration) =>
      dispatch(updateAppIntegration(appIntegration)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AppIntegrations));
