export const AGENDA_ITEM_VISIBILITY_ALL_PARTICIPANTS = {
  id: 'A',
  name: 'All participants',
  icon: 'fe fe-unlock',
};

export const AGENDA_ITEM_VISIBILITY_OWNER_ONLY = {
  id: 'P',
  name: 'Owner only',
  icon: 'fe fe-lock',
};
