import { Col, Row } from 'reactstrap';
import ObjectivesTimeFrameSelector, {
  useObjectivesTimeFrameSelector,
} from '../../Objectives/ObjectivesTimeFrameSelector';
import React, { FC, useCallback } from 'react';
import { TimeFrame, TimeFrameType } from '../../../types';
import {
  formatDateWithUnicode,
  parseDateFromUnicode,
} from '../../../utils/util/time';

import SwitchInput from './SwitchInput';

interface InputProps {
  enabled: boolean;
  // We keep the property 'quarter' instead of 'timeframe' for backward compatibility
  quarter: {
    start: string;
    end: string;
    type?: TimeFrameType;
  };
}

interface Props {
  id?: string;
  switchLabel?: string | JSX.Element;
  required?: boolean;
  centered?: boolean;
  style?: string;
  name: string;
  value?: InputProps;
  checkedValue?: any;
  uncheckedValue?: any;
  onChange?: any;
  className?: string;
  disabled?: boolean;
  enableHelp?: boolean;
  hoverHelperText?: string;
  quarterHelperText?: string;
  role?: string;
  isSubmitting?: boolean;
  dateFormat?: string;
}

const parseDefaultTimeFrameValue = (
  dateFormat: string,
  value?: InputProps
): TimeFrame | undefined => {
  if (!value) {
    return undefined;
  }

  return {
    start: parseDateFromUnicode(value.quarter.start, dateFormat),
    end: parseDateFromUnicode(value.quarter.end, dateFormat),
    type: value.quarter.type ?? 'QUARTER',
  };
};

const SwitchInputWithTimeFrameSelector: FC<Props> = ({
  value,
  dateFormat = 'yyyy-MM-dd',
  ...props
}) => {
  const handleChangeSwitch = (enabled: boolean) => {
    props.onChange({ ...value, enabled });
  };

  const handleChangeTimeFrame = useCallback(
    ({ start, end, type }: TimeFrame) => {
      props.onChange({
        ...value,
        quarter: {
          start: formatDateWithUnicode(start, dateFormat),
          end: formatDateWithUnicode(end, dateFormat),
          type,
        },
      });
    },
    [dateFormat, props, value]
  );

  const { currentTimeframeText, viewPreviousTimeframe, viewNextTimeframe } =
    useObjectivesTimeFrameSelector({
      defaultTimeFrame: parseDefaultTimeFrameValue(dateFormat, value),
      enableUrlForBookmark: false,
      onChangeTimeFrame: handleChangeTimeFrame,
    });

  const className = props.className;

  return (
    <div id={'switch_with_timeframe' + props.name}>
      <Row>
        <Col className="col-auto pe-1">
          <SwitchInput
            switchLabel={props.switchLabel}
            required={props.required}
            role={props.role}
            style={props.style}
            name={props.name}
            centered={props.centered}
            value={value?.enabled || false}
            checkedValue={props.checkedValue}
            uncheckedValue={props.uncheckedValue}
            onChange={handleChangeSwitch}
            className={className}
            disabled={props.disabled || props.isSubmitting}
            enableHelp={props.enableHelp}
            hoverHelperText={props.hoverHelperText}
          />
        </Col>
        <Col className="col-auto align-middle ps-0 pe-0">
          <small className="form-text text-muted align-middle pt-1">
            {props.quarterHelperText}
          </small>
        </Col>
        <Col className="col-auto">
          <ObjectivesTimeFrameSelector
            currentTimeframeText={currentTimeframeText}
            viewPreviousTimeframe={viewPreviousTimeframe}
            viewNextTimeframe={viewNextTimeframe}
            disabled={props.disabled || props.isSubmitting}
            buttonClassName="fs-5 py-0"
          />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(SwitchInputWithTimeFrameSelector);
