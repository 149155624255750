import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import { Doughnut } from 'react-chartjs-2';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import { unzip } from 'lodash';

const chartOptions = {
  tooltips: {
    enabled: false,
  },
  cutoutPercentage: 70,
  borderColor: '#FFFFFF',
  borderWidth: 0.1,
  animation: {
    duration: 400,
  },
};

const GenericDoughnutCard: FC<Props> = ({
  data: propsData = [],
  emptyStateText = '',
  ...props
}) => {
  const chartData = useMemo(() => {
    // @ts-expect-error
    const [backgroundColor, data] = unzip(propsData.data);
    const retval = {
      // @ts-expect-error
      labels: propsData?.labels || [],
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    };
    return retval;
  }, [propsData]);

  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      {props.title && (
        <CardHeader>
          <CardHeaderTitle>{props.title}</CardHeaderTitle>
        </CardHeader>
      )}
      <CardBody className="position-relative">
        {/* @ts-expect-error */}
        {propsData?.data?.length ? (
          <>
            {/* DO NOT REMOVE ENVELOPE DIV OR DOUGHNUT CHART WILL BREAK */}
            <div>
              <Doughnut data={chartData} options={chartOptions} />
            </div>
            {/* @ts-expect-error */}
            {propsData.message}
          </>
        ) : (
          // @ts-expect-error
          <EmptyState title={emptyStateText} />
        )}
      </CardBody>
    </Card>
  );
};

const GenericDoughnutCard_propTypes = {
  className: PropTypes.object,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  emptyStateText: PropTypes.string,
  style: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof GenericDoughnutCard_propTypes>;

export default React.memo(GenericDoughnutCard);
