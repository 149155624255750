import { Popover, PopoverBody } from 'reactstrap';
import React, { FC, useState } from 'react';

import Avatar from '../Widgets/People/Avatar';
import { FormattedMessage } from 'react-intl';
import { Person } from '../../types';
import PersonCard from '../Widgets/Cards/PersonCard';
import PersonalObjectives from '../Person/PersonalObjectives';

interface Props {
  person: Person;
  urlSuffix: string;
}

const ObjectivesHoverablePersonCard: FC<Props> = ({ person, urlSuffix }) => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const togglePopover = () => setPopoverIsOpen(!popoverIsOpen);
  const [ref, setRef] = useState<HTMLDivElement | null>();

  return (
    <>
      <div ref={(newRef) => setRef(newRef)}>
        <PersonCard
          size="xs"
          className="mb-3"
          headerClassName="fw-normal"
          person={person}
          showDescription={false}
          bodyOnly
          linked
          urlSuffix={urlSuffix}
          hidePopover={true}
        />
      </div>
      {ref && (
        <Popover
          className="popover-xxl"
          trigger="hover focus"
          placement="left"
          target={ref || ''}
          isOpen={popoverIsOpen}
          toggle={togglePopover}
        >
          <h6 className="header-pretitle">
            <Avatar
              person={person}
              size="xxs"
              className="me-2"
              style={{ position: 'relative', top: '-3px' }}
            />
            <FormattedMessage
              id="app.views.objectives.objectives_hoverable_person_card.persons_objectives"
              defaultMessage="{name}'s objectives"
              values={{
                name: person.full_name,
              }}
            />
          </h6>
          <PopoverBody>
            {/* only lazy load objectives if hover happens to avoid overloading
                backend fetching multiple in parallel when this loads in the
                sidebar */}
            {popoverIsOpen && (
              // mb-2 needed for empty state to avoid strange visual in popover arrow
              <div className="mb-2">
                <PersonalObjectives
                  isInPopover={true}
                  showTeamNav={false}
                  person={person}
                  isReadOnly={true}
                  hideDatePicker={true}
                  // SHOW scores and weights for easy reference
                  hideScoresAndWeight={false}
                  hideRelatedObjectives={true}
                  showMiniEmptyStateCallToAction={true}
                  shouldCacheObjectivesOnFrontend={true}
                />
              </div>
            )}
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default ObjectivesHoverablePersonCard;
