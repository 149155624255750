import { Features, OrganizationSettings, ReduxState, Tab } from '../../types';
import React, { useMemo } from 'react';
import {
  TEAM_OBJECTIVES_TAB_OVERVIEW,
  TEAM_OBJECTIVES_TAB_PARTICIPATION,
} from '../../consts/consts';

import ObjectivesOverviewPage from './ObjectivesOverviewPage';
import ObjectivesParticipationPage from './ObjectivesParticipationPage';
import Page from '../Layout/Pages/Page';
import { connect } from 'react-redux';
import { useIntl, type IntlShape } from 'react-intl';

interface Props {
  features: Features;
  settings: OrganizationSettings;
}

const insertIf = (condition: boolean, ...elements: Tab[]) =>
  condition ? elements : [];

const ObjectivesHubPage: React.FC<Props> = ({ features, settings }) => {
  const { formatMessage }: IntlShape = useIntl();

  const tabs: Tab[] = useMemo(
    () => [
      ...insertIf(features?.objectives?.rollup_enabled ?? true, {
        ...TEAM_OBJECTIVES_TAB_OVERVIEW(formatMessage),
        content: <ObjectivesOverviewPage />,
      }),
      ...insertIf(
        !settings?.objectives_restrict_visibility_to_match_editability,
        {
          ...TEAM_OBJECTIVES_TAB_PARTICIPATION(formatMessage),
          content: <ObjectivesParticipationPage />,
        }
      ),
    ],
    [
      formatMessage,
      features?.objectives?.rollup_enabled,
      settings?.objectives_restrict_visibility_to_match_editability,
    ]
  );

  return (
    <Page
      title={formatMessage({
        id: 'app.views.objectives.objectives_hub_page.title.objectives',
        defaultMessage: 'Objectives',
      })}
      tabs={tabs}
    />
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { authUser, features, settings } = state;
  return {
    features,
    authUser,
    settings,
  };
};

const connector = connect(mapStateToProps);

export default connector(React.memo(ObjectivesHubPage));
