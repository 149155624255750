import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import {
  EventSerializerProvider,
  useEventSerializer,
} from '../../utils/util/EventSerializer';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  InitOrganization,
  InitProxyPerson,
  Me,
  Person,
  TimeFrame,
} from '../../types';
import { Objective, ObjectiveWithRef } from '../../utils/models/Objective';
import ObjectivesTimeFrameSelector, {
  useObjectivesTimeFrameSelector,
} from '../Objectives/ObjectivesTimeFrameSelector';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getAllTreeObjectives,
  useObjectiveActions,
  useObjectiveOperations,
} from '../Objectives/ObjectiveOperations';

import ButtonWithIcon from '../../components/ButtonWithIcon/ButtonWithIcon';
import Loading from '../Widgets/Loading';
import ObjectiveAutosavePrompt from '../Objectives/ObjectiveAutosavePrompt';
import ObjectivesEmpty from '../Objectives/ObjectivesEmpty';
import PersonalObjectiveItemRoot from './PersonalObjectiveItemRoot';
import ReactTagsInput from '../Widgets/Inputs/ReactTagsInput';
import { connect } from 'react-redux';
import { peopleIdsAreEqual } from '../../utils/models/Person';
import { useAuth0 } from '@auth0/auth0-react';
import { useDeepEqualMemo } from '../../utils/util/hooks';

interface Props {
  currentOrganization: InitOrganization;
  currentProxyPerson: InitProxyPerson;
  me: Me;
  person: Person;
  isReadOnly?: boolean;
  // TODO: externalize the following props
  isInPopover?: boolean;
  showTeamNav?: boolean;
  renderTeamNav?: (
    person: Person,
    currentFirstDayOfTimeFrame: Date
  ) => React.ReactNode;
  hideDatePicker?: boolean;
  hideScoresAndWeight?: boolean;
  hideRelatedObjectives?: boolean;
  showMiniEmptyStateCallToAction?: boolean;
  shouldCacheObjectivesOnFrontend?: boolean;
  isDemoOrPreviewMode?: boolean;
  defaultTimeFrame?: TimeFrame;
  className?: string;
  getObjectives?: (
    objectives: Objective[],
    currentTimeframeText?: string
  ) => void; //this is a callback function that picks the objectives from this component..... rename it onChangeObjectives or something like that
}

type ApiActionState = 'LOADING' | 'FETCHED' | 'RELOAD'; // TODO: add ERROR

const PersonalObjectives: FC<Props> = ({
  currentProxyPerson,
  currentOrganization,
  me,
  person,
  isReadOnly = false, // This is to force the veiw to force the view to be read only. TODO: remove this and externalize the widget
  isInPopover = false,
  showTeamNav = false,
  renderTeamNav,
  hideDatePicker = false,
  hideScoresAndWeight = false,
  hideRelatedObjectives = false,
  showMiniEmptyStateCallToAction = false,
  shouldCacheObjectivesOnFrontend = false,
  isDemoOrPreviewMode = false,
  defaultTimeFrame,
  className,
  getObjectives = () => {
    // do nothing
  },
}) => {
  const { user } = useAuth0();
  const [state, setState] = React.useState<ApiActionState>('LOADING');
  const [objectives, setObjectives] = React.useState<ObjectiveWithRef[]>([]);
  const [accessiblePeopleIds, setAccessiblePeopleIds] = React.useState<
    number[]
  >([]);
  const userMemo = useDeepEqualMemo(user);
  const [companyOKRsFullVisibility, setCompanyOKRsFullVisibility] =
    useState(true);

  const handleChangeTimeFrame = useCallback(() => {
    setState('LOADING');
    setObjectives([]);
  }, []);

  const {
    currentFirstDayOfTimeFrame,
    currentTimeframeText,
    viewPreviousTimeframe,
    viewNextTimeframe,
    firstDay,
    lastDay,
  } = useObjectivesTimeFrameSelector({
    defaultTimeFrame,
    onChangeTimeFrame: handleChangeTimeFrame,
  });

  // TODO: here instead of using useffect, hook the getObjective on the setObjectives, making a wrapper method instead
  useEffect(() => {
    getObjectives(objectives, currentTimeframeText);
  }, [objectives, getObjectives, currentTimeframeText]);

  const { onSubmit, onSuccess, onError } = useObjectiveOperations({
    isDemoOrPreviewMode,
    currentOrganization,
    currentProxyPerson,
    person,
    firstDay,
    lastDay,
    setObjectives,
    setState,
  });

  // TODO Move this logic to the backend or at least to a function
  const isMe = useMemo(() => {
    return peopleIdsAreEqual(person?.id, me.id);
  }, [me.id, person?.id]);

  const isEditable = useMemo(
    () =>
      !isReadOnly &&
      (isMe || person?.is_adminable || person?.is_below_in_chain_of_command),
    [isMe, isReadOnly, person]
  );

  const onFetchedObjectives = useCallback(
    (objectives, companyOKRsFullVisibility, accessiblePeopleIds) => {
      setState((state) => (state === 'FETCHED' ? 'RELOAD' : 'FETCHED'));
      setObjectives(objectives);
      setCompanyOKRsFullVisibility(companyOKRsFullVisibility);
      setAccessiblePeopleIds(accessiblePeopleIds);
    },
    [setState, setObjectives, setCompanyOKRsFullVisibility]
  );

  // The components are uncontrolled, so a full reload is needed for the objectives are externally updated
  useEffect(() => {
    if (state === 'RELOAD') {
      setState('FETCHED');
    }
  }, [state]);

  useEffect(() => {
    getAllTreeObjectives({
      person,
      currentOrganization,
      currentProxyPerson,
      firstDay,
      lastDay,
      shouldCacheObjectivesOnFrontend,
      user: userMemo,
      onFetchedObjectives,
    });
  }, [
    firstDay,
    lastDay,
    currentOrganization,
    currentProxyPerson,
    person,
    userMemo,
    shouldCacheObjectivesOnFrontend,
    onFetchedObjectives,
  ]);

  return (
    <EventSerializerProvider
      onSubmit={onSubmit}
      onErrorCallback={onError}
      onSuccessCallback={onSuccess}
    >
      <ObjectiveAutosavePrompt />
      <MainArea
        person={person}
        setObjectives={setObjectives}
        objectives={objectives}
        firstDay={firstDay}
        lastDay={lastDay}
        currentOrganization={currentOrganization}
        hideScoresAndWeight={hideScoresAndWeight}
        hideDatePicker={hideDatePicker}
        isInPopover={isInPopover}
        showMiniEmptyStateCallToAction={showMiniEmptyStateCallToAction}
        hideRelatedObjectives={hideRelatedObjectives}
        showTeamNav={showTeamNav}
        renderTeamNav={renderTeamNav}
        isEditable={isEditable}
        viewNextTimeframe={viewNextTimeframe}
        viewPreviousTimeframe={viewPreviousTimeframe}
        currentTimeframeText={currentTimeframeText}
        state={state}
        currentFirstDayOfTimeFrame={currentFirstDayOfTimeFrame}
        me={me}
        companyOKRsFullVisibility={companyOKRsFullVisibility}
        accessiblePeopleIds={accessiblePeopleIds}
        className={className}
        isDemoOrPreviewMode={isDemoOrPreviewMode}
      />
    </EventSerializerProvider>
  );
};

const MainArea = ({
  person,
  setObjectives,
  objectives,
  firstDay,
  lastDay,
  currentOrganization,
  hideScoresAndWeight,
  hideDatePicker,
  showMiniEmptyStateCallToAction,
  hideRelatedObjectives,
  isInPopover,
  showTeamNav,
  renderTeamNav,
  isEditable,
  className,
  viewNextTimeframe,
  viewPreviousTimeframe,
  currentTimeframeText,
  state,
  currentFirstDayOfTimeFrame,
  me,
  companyOKRsFullVisibility,
  accessiblePeopleIds,
  isDemoOrPreviewMode,
}) => {
  const { createNewTopItem } = useObjectiveActions({
    me,
    person,
    firstDay,
    lastDay,
    currentOrganization,
  });

  const teamNav = useMemo(
    () => renderTeamNav?.(person, currentFirstDayOfTimeFrame),
    [currentFirstDayOfTimeFrame, person, renderTeamNav]
  );

  return (
    <Row
      // in hovers, we need to extend the width explicitly on hover, else the
      // hovers are super thin and super super long
      style={
        isInPopover && state === 'FETCHED' && objectives.length > 0
          ? { width: '50vw' }
          : undefined
      }
    >
      <Col
        className={
          className ??
          (!hideScoresAndWeight && !isInPopover
            ? `mb-n4 col-12 ${
                showTeamNav ? 'col-md-9' : 'col-md-10 offset-md-1'
              }`
            : '')
        }
      >
        {!hideDatePicker && (
          <ObjectivesTimeFrameSelector
            viewNextTimeframe={viewNextTimeframe}
            currentTimeframeText={currentTimeframeText}
            viewPreviousTimeframe={viewPreviousTimeframe}
            disabled={state === 'LOADING'}
          />
        )}
        {state === 'LOADING' && <Loading />}
        {state === 'FETCHED' && objectives.length === 0 && (
          <ObjectivesEmpty
            person={person}
            isEditable={isEditable}
            isInPopover={isInPopover}
            me={me}
            currentTimeframeText={currentTimeframeText}
            objectivesForTimeframe={objectives}
            addObjective={() => createNewTopItem({ setObjectives })}
            showMiniEmptyStateCallToAction={showMiniEmptyStateCallToAction}
          />
        )}
        {state === 'FETCHED' && objectives.length > 0 && (
          <PersonalObjectiveItemRootList
            objectives={objectives}
            isEditable={isEditable}
            isInPopover={isInPopover}
            hideScoresAndWeight={hideScoresAndWeight}
            hideRelatedObjectives={hideRelatedObjectives}
            collapseObjectivesByDefault={showMiniEmptyStateCallToAction}
            person={person}
            setObjectives={setObjectives}
            firstDay={firstDay}
            lastDay={lastDay}
            createNewTopItem={() => createNewTopItem({ setObjectives })}
            companyOKRsFullVisibility={companyOKRsFullVisibility}
            accessiblePeopleIds={accessiblePeopleIds}
            isDemoOrPreviewMode={isDemoOrPreviewMode}
          />
        )}
      </Col>
      {showTeamNav && teamNav}
    </Row>
  );
};

const PersonalObjectiveItemRootList = ({
  objectives,
  person,
  setObjectives,
  firstDay,
  lastDay,
  createNewTopItem,
  isEditable = false,
  isInPopover = false,
  hideScoresAndWeight = false,
  hideRelatedObjectives = false,
  collapseObjectivesByDefault = false,
  companyOKRsFullVisibility,
  accessiblePeopleIds,
  isDemoOrPreviewMode = false,
}) => {
  const { formatMessage } = useIntl();
  const [addToBufferQueue] = useEventSerializer();
  const [searchOpen, setSearchOpen] = useState(false);

  const [importedObjective, setImportedObjective] = useState<Objective>();

  return (
    <ListGroup>
      {objectives.map((objective, index) => (
        <PersonalObjectiveItemRoot
          isEditable={isEditable}
          isInPopover={isInPopover}
          hideScoresAndWeight={hideScoresAndWeight}
          hideRelatedObjectives={hideRelatedObjectives}
          person={person}
          key={objective.force_key ?? objective.key}
          objective={objective}
          showLabelHeadings={index === 0}
          forceShowChildren={
            !collapseObjectivesByDefault &&
            (objective?.children || []).length > 0
          }
          // TODO: rename this for a more generic CALLBACK name. It's not just updating.
          updateParentObjective={(deletedObjective) => {
            setObjectives((objectives) =>
              objectives.filter((o) => o.key !== deletedObjective.key)
            );
          }}
          enableParentEdit={companyOKRsFullVisibility && !isDemoOrPreviewMode}
          accessiblePeopleIds={accessiblePeopleIds}
        />
      ))}
      {/* create new Root object */}
      {isEditable && (
        <ListGroupItem color="white">
          <>
            {!searchOpen ? (
              <div className="d-flex gap-3">
                <ButtonWithIcon
                  color="link"
                  icon="plus"
                  onClick={createNewTopItem}
                >
                  <FormattedMessage
                    id="app.views.person.personal_objectives.add_new_objective"
                    defaultMessage="Add new objective"
                  />
                </ButtonWithIcon>
                {companyOKRsFullVisibility && (
                  <ButtonWithIcon
                    color="link"
                    icon="search"
                    onClick={() => setSearchOpen(true)}
                  >
                    <FormattedMessage
                      id="app.views.person.personal_objectives.search_existing"
                      defaultMessage="Add existing objectives"
                    />
                  </ButtonWithIcon>
                )}
              </div>
            ) : !importedObjective ? (
              <div className="d-flex gap-3">
                <div className="flex-grow-1">
                  <ReactTagsInput
                    autoFocus={true}
                    placeholder={formatMessage({
                      id: 'app.views.person.personal_objectives.plceholder',
                      defaultMessage: 'Start typing to search for an objective',
                    })}
                    allowNew={false}
                    elasticsearchOptions={{
                      url: 'get-objectives-for-linking/v2',
                      index: 'objectives',
                      getQuery: (q) => ({
                        query: q,
                        owner_person_id: person.id,
                        coverage_start_date: firstDay,
                        coverage_end_date: lastDay,
                        is_importing: true,
                        // tags: tags.map((x) => x.id),
                        apply_feature_filters: true,
                      }),
                    }}
                    callback={(e) => {
                      if (!e || e.length === 0) {
                        return;
                      }
                      setImportedObjective(e[0].object);
                    }}
                    useTagCards={true}
                  />
                </div>
                <ButtonWithIcon
                  className="d-flex align-items-center text-nowrap"
                  outline
                  icon="x"
                  onClick={() => setSearchOpen(false)}
                >
                  <FormattedMessage
                    id="app.views.person.personal_objectives.dismiss"
                    defaultMessage="Dismiss"
                  />
                </ButtonWithIcon>
              </div>
            ) : (
              <div className="d-flex align-items-baseline gap-3">
                <div className="flex-grow-1 text-secondary">
                  <div>
                    <p>
                      <FormattedMessage
                        id="app.views.person.personal_objectives.add_self_as_collaborator"
                        defaultMessage="Do you wish to add yourself as a collaborator to this existing objective?"
                      />
                    </p>
                    <i className="fe fe-arrow-right" /> {importedObjective.name}
                  </div>
                </div>
                <ButtonWithIcon
                  className="d-flex align-items-center text-nowrap"
                  outline
                  icon="check"
                  color="primary"
                  onClick={() => {
                    if (!importedObjective) {
                      return;
                    }

                    const updted = {
                      ...importedObjective,
                      collaborators: [
                        ...importedObjective.collaborators,
                        person,
                      ],
                      related_to: [], // set temporarly to empty to avoid error. is loading anuyway
                    };
                    setObjectives([...objectives, updted]);

                    addToBufferQueue({
                      ...updted,
                      operation: 'IMPORT',
                    });
                    setSearchOpen(false);
                    setImportedObjective(undefined);
                  }}
                >
                  <FormattedMessage
                    id="app.views.person.personal_objectives.search_confirm"
                    defaultMessage="Confirm"
                  />
                </ButtonWithIcon>
                <ButtonWithIcon
                  className="d-flex align-items-center text-nowrap"
                  outline
                  icon="x"
                  onClick={() => {
                    setSearchOpen(false);
                    setImportedObjective(undefined);
                  }}
                >
                  <FormattedMessage
                    id="app.views.person.personal_objectives.search_cancel"
                    defaultMessage="Cancel"
                  />
                </ButtonWithIcon>
              </div>
            )}
          </>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, me } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
  };
};

export default connect(mapStateToProps)(PersonalObjectives);
