import { EnpsCounts } from '../../../../apiTypes';

// it doesn't seem worth creating a separate consts file for just one constant
export const CATCHALL_GROUP_NAME = 'Other';

export function calculateEnpsScore(counts: EnpsCounts | undefined): number {
  if (!counts) {
    return 0.0;
  }

  const totalResponses = counts.promoter + counts.passive + counts.detractor;
  if (totalResponses === 0) {
    return 0.0;
  }

  const npsScore =
    ((counts.promoter - counts.detractor) / totalResponses) * 100;
  return npsScore;
}
