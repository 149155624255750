import React, { FC, useMemo } from 'react';

import TableCard from './TableCard';
import { getFilterAbbreviationForType } from '../Dashboards/TeamDashboard/common';

const buildPerformanceAdminLink = (dataKey, filterValue) => {
  const filterAbbreviaion = getFilterAbbreviationForType(
    dataKey === 'manager' ? 'manager_or_above' : dataKey
  );
  return `/performance/admin?filters[]=${filterAbbreviaion}-${filterValue}`;
};

const createNameObject = (stringOrJson, dataKey) => {
  let nameObj = {};
  let filterValue = '';
  try {
    const nameInfo = JSON.parse(stringOrJson);
    if (typeof nameInfo !== 'object') {
      throw new Error('Not a JSON, default');
    }
    nameObj = {
      name: nameInfo.full_name,
      sortKey: nameInfo.full_name,
    };
    filterValue = nameInfo.email || nameInfo.full_name || nameInfo.externalId;
  } catch (e) {
    nameObj = {
      name: stringOrJson,
      sortKey: stringOrJson,
    };
    filterValue = stringOrJson;
  }
  // @ts-expect-error
  nameObj.link = buildPerformanceAdminLink(
    dataKey,
    encodeURIComponent(filterValue)
  );
  return nameObj;
};

type Props = {
  className?: string;
  data: Record<string, [number, number]>;
  dataKey: string;
  defaultSortBy?: string;
  emptyStateText?: string;
  fields: string[];
  role?: string;
  style?: Record<string, unknown>;
  title: string;
};

const ProgressTableCard: FC<Props> = ({ data = {}, ...props }) => {
  const tableRows = useMemo(
    () =>
      !data || Object.keys(data).length === 0
        ? []
        : Object.entries(data).map((x) => {
            const [nameStringOrJson, [count, total]] = x;
            const nameObject = createNameObject(
              nameStringOrJson,
              props.dataKey
            );
            // @ts-expect-error
            const pct = parseInt((100.0 * count) / total + 0.5);
            return [nameObject, count, total, pct];
          }),
    [data, props.dataKey]
  );

  return (
    <TableCard
      // @ts-expect-error
      className={props.className}
      // @ts-expect-error
      defaultSortBy={props.defaultSortBy}
      emptyStateText={props.emptyStateText}
      // @ts-expect-error
      fields={props.fields}
      rowData={tableRows}
      role={props.role}
      style={props.style}
      title={props.title}
    />
  );
};

export default React.memo(ProgressTableCard);
