import { FormattedList, FormattedMessage } from 'react-intl';
import React, { FC, useMemo, useState } from 'react';

interface Props {
  people: { name: string; url: string }[];
}

const TooltipList: FC<Props> = (props) => {
  const [expanded, setExpanded] = useState(false);

  const peopleList = useMemo(() => {
    const people = props.people;

    if (!people || people.length === 0) return null;

    const TOOLTIP_PEOPLE_LIMIT = 3;
    const tooltipPeopleCount = expanded
      ? people.length
      : Math.min(people.length, TOOLTIP_PEOPLE_LIMIT);

    people.sort((a, b) => (a.name > b.name ? 1 : -1));

    return (
      <FormattedList
        value={[
          ...people
            .map((person) => (
              <span key={person.url}>
                <a href={person.url} target="_blank" rel="noopener noreferrer">
                  {person.name}
                </a>
              </span>
            ))
            .slice(0, tooltipPeopleCount),
          !expanded && people.length > tooltipPeopleCount && (
            <span key={people.length}>
              <a
                role="button"
                // @ts-expect-error
                href={null}
                onClick={() => setExpanded(!expanded)}
              >
                <FormattedMessage
                  id="app.views.widgets.tooltip_list.more"
                  defaultMessage={'{count} more'}
                  values={{ count: people.length - tooltipPeopleCount }}
                />
              </a>
            </span>
          ),
        ].filter((x) => !!x)}
      />
    );
  }, [props.people, expanded]);

  return peopleList;
};

export default React.memo(TooltipList);
