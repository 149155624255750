import { Col, Row, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { capitalize, getUniqueHtmlId } from '../../../../utils/util/formatter';

import PropTypes from 'prop-types';
import React from 'react';
import { createName } from '../../../EngagementSurvey/utils';

const HeatmapRow = (props) => {
  const { formatMessage } = useIntl();
  return (
    <Row className={'gx-0 heatmap-row'} style={{ height: '4rem' }}>
      {props.columns.map((c, ci) => {
        const isHeader = props.firstColumnIsTitle && !ci;

        const data = props.data?.[c]?.responses;
        const rawValue = data?.value || data;
        let popoverData;

        if (props.isMultipleChoice) {
          popoverData = { total: data?.count };
        } else {
          popoverData = props.formatPopover(rawValue);
        }

        const participationRate =
          Math.round(
            (100 * (popoverData?.total || 1)) / (props.data?.[c]?.eligible || 1)
          ) + '%';

        const columnName =
          c === '_others'
            ? formatMessage({
                id: 'app.views.widgets.charts.heatmap.heatmap_row.others',
                defaultMessage: 'Others',
              })
            : capitalize(c);

        let value;

        if (isHeader) {
          value = createName(
            formatMessage,
            props.data?.[c],
            props.data?.count,
            'likert-' + props.index
          );
        } else {
          value = props.formatValue(rawValue);
        }

        let displayValue = value;
        if (!isHeader && props.isMultipleChoice && value !== '-') {
          displayValue += '%';
        }

        const uniqueId = getUniqueHtmlId('heatcell');

        return (
          <Col
            key={ci}
            className={
              `heatmap-row-cell border-bottom ` +
              (isHeader ? 'col-10 px-3 border-start ' : 'col-2 ') +
              (props.index ? '' : 'border-top border-light ') +
              (c.className ? c.className : '')
            }
            style={{
              backgroundColor: props.colorScale(value, props.data)?.background,
              color: props.colorScale(value, props.data)?.text,
            }}
          >
            <Row
              className={
                `h-100 align-content-center justify-content-center ` +
                (isHeader ? '' : 'text-center ') +
                (props.firstColumnIsTitle && ci ? ' fw-bold' : '')
              }
            >
              <span id={uniqueId} className="py-2">
                {displayValue}
              </span>
            </Row>
            {!isHeader && displayValue !== '-' && (
              <UncontrolledPopover
                placement="top"
                trigger="hover click"
                target={uniqueId}
              >
                <>
                  <div className="fw-bold mb-3">{props.data?._name}</div>
                  <div className="d-flex justify-content-between">
                    <span className="me-4">
                      <FormattedMessage
                        id="app.views.widgets.charts.heatmap.heatmap_row.segment"
                        defaultMessage="{segment}:"
                        values={{
                          segment: capitalize(props.data?._segment),
                        }}
                      />
                    </span>{' '}
                    <span className="fw-bold">{columnName}</span>
                  </div>
                  {props.isMultipleChoice && (
                    <div className="d-flex justify-content-between">
                      <span className="me-2">
                        <FormattedMessage
                          id="app.views.widgets.charts.heatmap.heatmap_row.choice"
                          defaultMessage="Choice:"
                        />
                      </span>{' '}
                      <span className="fw-bold">{props.data?._choice}</span>
                    </div>
                  )}
                  <div className="d-flex justify-content-between">
                    <span>
                      <FormattedMessage
                        id="app.views.widgets.charts.heatmap.heatmap_row.responses"
                        defaultMessage="Responses:"
                      />
                    </span>{' '}
                    <span className="fw-bold">
                      {props.isMultipleChoice && <>{popoverData.total}</>}
                      {!props.isMultipleChoice && (
                        <span className="fw-normal">
                          <FormattedMessage
                            id="app.views.widgets.charts.heatmap.heatmap_row.total_of_eligible"
                            defaultMessage="{total} of {eligible}"
                            values={{
                              total: popoverData.total,
                              eligible: props.data?.[c]?.eligible,
                            }}
                          />
                        </span>
                      )}
                    </span>
                  </div>
                  {!props.isMultipleChoice && (
                    <div className="d-flex justify-content-between">
                      <span className="me-2">
                        <FormattedMessage
                          id="app.views.widgets.charts.heatmap.heatmap_row.participation_rate"
                          defaultMessage="Participation rate: "
                        />
                      </span>{' '}
                      <span className="fw-bold">{participationRate}</span>
                    </div>
                  )}
                  {!props.isMultipleChoice &&
                    props.popoverChart(uniqueId, popoverData)}
                </>
              </UncontrolledPopover>
            )}
          </Col>
        );
      })}
    </Row>
  );
};

HeatmapRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.object.isRequired,
  firstColumnIsTitle: PropTypes.bool,
  colorScale: PropTypes.func,
  formatValue: PropTypes.func,
  formatPopover: PropTypes.func,
  popoverChart: PropTypes.func,
  isMultipleChoice: PropTypes.bool,
};

export default React.memo(HeatmapRow);
