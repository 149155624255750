import { TimeFrame, TimeFrameType } from '../../../types';
import { getQuarter, getQuarterDate } from '../../../utils/models/Activity';

import { addMonths } from 'date-fns';
import { getDatePart } from '../../../utils/util/time';
import { getPrettyDateRange } from '../../../utils/util/util';

export type CalculatePeriodForTimeFrameTypeParams = {
  date: Date;
  type: TimeFrameType;
  startPeriodMMDD: string;
};

export const DATE_PARAMETER = 'date';

export const calculatePeriodForTimeFrameType = ({
  date,
  type,
  startPeriodMMDD,
}: CalculatePeriodForTimeFrameTypeParams): TimeFrame => {
  switch (type) {
    case 'YEAR': {
      const start = new Date(
        `${date.getFullYear()}-${startPeriodMMDD}T00:00:00`
      );

      if (date < start) {
        start.setFullYear(start.getFullYear() - 1);
      }
      const end = new Date(start);
      end.setFullYear(end.getFullYear() + 1);
      end.setDate(end.getDate() - 1);

      return {
        type: 'YEAR',
        start,
        end,
      };
    }
    case 'QUARTER':
      return {
        type: 'QUARTER',
        start: getQuarterDate(date),
        end: getQuarterDate(date, true),
      };
  }
};

export type TimeFrameDirection = 'NEXT' | 'PREVIOUS';

export const calculateTimeFrame = (
  { type, start }: TimeFrame,
  direction: TimeFrameDirection
): TimeFrame => {
  switch (type) {
    case 'YEAR': {
      const nextStart = new Date(start);
      nextStart.setFullYear(
        nextStart.getFullYear() + (direction === 'NEXT' ? 1 : -1)
      );

      const nextEnd = new Date(nextStart);
      nextEnd.setFullYear(nextEnd.getFullYear() + 1);
      nextEnd.setDate(nextEnd.getDate() - 1);

      return {
        type,
        start: nextStart,
        end: nextEnd,
      };
    }
    case 'QUARTER': {
      const nextQuarter = addMonths(start, direction === 'NEXT' ? 3 : -3);
      return {
        type,
        start: getQuarterDate(nextQuarter),
        end: getQuarterDate(nextQuarter, true),
      };
    }
  }
};

export const formatTimeFrame = (
  { type, start, end }: TimeFrame,
  locale: string
): string => {
  switch (type) {
    case 'YEAR': {
      const isYearRangeSkewed = start.getDate() !== 1 || start.getMonth() !== 0;
      const yearRange = getPrettyDateRange(start, end, false, locale);
      // show the full month and day if the year range is skewed, otherwise, only show the year
      return isYearRangeSkewed ? yearRange : start.getFullYear().toString();
    }
    case 'QUARTER': {
      return getQuarter(start);
    }
  }
};

export const rewriteUrlForBookmark = (timeframe: TimeFrame) => {
  // change url without reloading page (requires using window.history and
  // not useHistory/history.push)
  window.history.replaceState(
    null,
    '',
    `${location.pathname}?${DATE_PARAMETER}=${getDatePart(timeframe.start)}`
  );
};
