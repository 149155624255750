import * as consts from '../../consts/consts';

import { Button, Col, Row } from 'reactstrap';
import {
  CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS,
  DEFAULT_CAMPAIGN_CONFIGS_JSON,
  DEFAULT_CAMPAIGN_PHASES_JSON,
} from './CampaignFlow';
import {
  CAMPAIGN_STATUSES,
  getCampaignStatusDisplayNameWithIcon,
  getEarliestPhaseStartDate,
  getLatestPhaseEndDate,
  getPhaseByType,
} from '../../utils/models/Campaign';
import {
  DEFAULT_AUTO_CALIBRATION_RULES,
  DEFAULT_OPEN_RESPONSE_QUESTIONS,
  INTRODUCTION_UI_PHASE_KEY,
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_INTRODUCTION_UI_ONLY,
  PHASE_TYPE_OTHERS,
  WIZARD_TYPE_KEY,
  getPhaseOpenResponseQuestions,
  getPhaseOrHiddenPhase,
  setPhaseOpenResponseQuestions,
} from '../../utils/models/Performance';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getDatePart, parseDateFromUnicode } from '../../utils/util/time';
import {
  getLocaleDependentStringFromDateOrString,
  getQuarterNameFromDateInQXYYYYFormat,
  toastErrorOrCallback,
} from '../../utils/util/util';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import EmptyState from '../Widgets/EmptyState';
import FilterablePeopleTable from '../Widgets/People/FilterablePeopleTable';
import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWizardTypeDisplayName } from '../../utils/models/Cycle';
import { loadOrRender } from '../../utils/util/formatter';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { applyCampaignPhaseDates } from './CampaignFlow/common';
import { NewCampaignWithConfigs } from 'types';

const addDefaultOpenResponseQuestions = (campaign, phaseType, type) => {
  const phase = getPhaseOrHiddenPhase(campaign, phaseType);
  if (phase) {
    const questions = getPhaseOpenResponseQuestions(phase, type);
    // @ts-expect-error
    if (!questions?.length > 0) {
      setPhaseOpenResponseQuestions(
        phase,
        type,
        DEFAULT_OPEN_RESPONSE_QUESTIONS
      );
    }
  }
};

const parseMMDDYYYYDate = (dateString) => {
  return parseDateFromUnicode(dateString, 'MM/dd/yyyy');
};

const dateMMDDYYYYFieldSorter = (a, b, fieldFn) => {
  // @ts-expect-error
  return parseMMDDYYYYDate(fieldFn(a)) - parseMMDDYYYYDate(fieldFn(b));
};

const CampaignAdministrationDashboard: FC<Props> = (props) => {
  const { formatMessage, locale } = useIntl();
  const [campaigns, setCampaigns] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isCreatingCycle, setIsCreatingCycle] = useState(false);
  const [isDeletingCycle, setIsDeletingCycle] = useState(false);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);
  const [campaignIdPendingDeletion, setCampaignIdPendingDeletion] =
    useState(null);

  const confirmDeleteCallback = useCallback(() => {
    setIsDeletingCycle(true);
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      '/campaigns/' + campaignIdPendingDeletion,
      {
        // @ts-expect-error
        organization: props.currentOrganization?.id,
      },
      toastErrorOrCallback(
        () => {
          setCampaigns(
            // @ts-expect-error
            campaigns.filter((c) => c.id !== campaignIdPendingDeletion)
          );
          setIsDeletingCycle(false);
          setCampaignIdPendingDeletion(null);
          toast.success(
            formatMessage({
              id: 'app.views.administration.campaign_administration_dashboard.campaign_deleted_successfully',
              defaultMessage: 'Campaign deleted successfully.',
            })
          );
        },
        () => {
          setIsDeletingCycle(false);
          setCampaignIdPendingDeletion(null);
        }
      ),
      null
    );
  }, [
    campaignIdPendingDeletion,
    campaigns,
    // @ts-expect-error
    props.currentOrganization?.id,
    formatMessage,
  ]);

  const history = useHistory();
  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    // @ts-expect-error
    if (props.currentOrganization?.id) {
      ConfirmAPI.getUrlWithCache(
        '/campaigns',
        'campaigns',
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        {
          // @ts-expect-error
          organization: props.currentOrganization.id,
          include_dataset_mappings: true,
        },
        (data) => {
          if (data?.results) {
            setCampaigns(data.results);
          }
        },
        (message) => {
          console.error('Could not fetch campaigns: ' + message);
          // @ts-expect-error
          setCampaigns(null);
          setErrorMessage(message);
        }
      );
    }
    // @ts-expect-error
  }, [props.currentOrganization?.id, props.currentProxyPerson, userSub]);

  const rows = useMemo(
    () =>
      // @ts-expect-error
      campaigns?.length > 0
        ? // @ts-expect-error
          campaigns.map((c) => ({
            ...c,
            start_date: getEarliestPhaseStartDate(c.phases),
            end_date: getLatestPhaseEndDate(c.phases),
            performance_period:
              (c.coverage_start_date
                ? getLocaleDependentStringFromDateOrString(
                    c.coverage_start_date,
                    locale
                  )
                : formatMessage({
                    id: 'app.views.administration.campaign_administration_dashboard.rows.performance_period.unspecified',
                    defaultMessage: '(unspecified)',
                  })) +
              ' - ' +
              (c.coverage_end_date
                ? getLocaleDependentStringFromDateOrString(
                    c.coverage_end_date,
                    locale
                  )
                : formatMessage({
                    id: 'app.views.administration.campaign_administration_dashboard.rows.performance_period.unspecified',
                    defaultMessage: '(unspecified)',
                  })),
            action: (
              <Row className="mb-n3">
                <Col className="col-auto pe-0 pb-3">
                  <Link
                    // @ts-expect-error
                    tag="Button"
                    className="btn btn-light btn-sm"
                    to={
                      consts.ADMINISTRATION_CAMPAIGNS(formatMessage).path +
                      '/' +
                      c.id
                    }
                  >
                    <i className="fe fe-edit me-2" />
                    <FormattedMessage
                      id="app.views.administration.campaign_administration_dashboard.action.edit"
                      defaultMessage="Edit"
                    />
                  </Link>
                </Col>
                <Col className="col-auto px-0 pb-3">
                  <Link
                    // @ts-expect-error
                    tag="Button"
                    className="ms-3 btn btn-light btn-sm"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={
                      consts.PREVIEW_CAMPAIGN().path +
                      '/' +
                      c.id +
                      consts.PERFORMANCE().path
                    }
                  >
                    <i className="fe fe-external-link me-2" />
                    <FormattedMessage
                      id="app.views.administration.campaign_administration_dashboard.action.preview"
                      defaultMessage="Preview"
                    />
                  </Link>
                </Col>
                <Col className="col-auto ps-0 pe-2 pb-3">
                  <Button
                    disabled={isDeletingCycle}
                    className="ms-3 btn btn-light btn-sm"
                    onClick={() => setCampaignIdPendingDeletion(c.id)}
                  >
                    <i className="fe fe-trash-2" />
                  </Button>
                </Col>
              </Row>
            ),
          }))
        : [],
    [campaigns, isDeletingCycle, formatMessage, locale]
  );

  const columns = useMemo(
    () => [
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.name.name"
            defaultMessage="Campaign"
          />
        ),
        field: 'name',
      },
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.name.start_date"
            defaultMessage="Start date"
          />
        ),
        field: 'start_date',
        format: (date) =>
          getLocaleDependentStringFromDateOrString(date, locale),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.popover_content.start_date"
            defaultMessage="First day of the first phase"
          />
        ),
        sort: (a, b) => dateMMDDYYYYFieldSorter(a, b, (el) => el.start_date),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.name.end_date"
            defaultMessage="End date"
          />
        ),
        field: 'end_date',
        format: (date) =>
          getLocaleDependentStringFromDateOrString(date, locale),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.popover_content.end_date"
            defaultMessage="Last day of the last phase"
          />
        ),
        sort: (a, b) => dateMMDDYYYYFieldSorter(a, b, (el) => el.end_date),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.name.performance_period"
            defaultMessage="Performance period"
          />
        ),
        field: 'performance_period',
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.popover_content.performance_period"
            defaultMessage="The time frame people should keep in mind when evaluating their performance"
          />
        ),
        sort: (a, b) => a.coverage_start_date - b.coverage_start_date,
      },
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.name.status"
            defaultMessage="Status"
          />
        ),
        field: 'status',
        format: (status) =>
          getCampaignStatusDisplayNameWithIcon(status, formatMessage),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.popover_content.status"
            defaultMessage="Draft means nobody can see, demo means you can share a preview link for others to see, and live means participants can access it between the start date and end date."
          />
        ),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.name.wizard_type"
            defaultMessage="Type"
          />
        ),
        field: WIZARD_TYPE_KEY,
        format: (wizard_type) =>
          getWizardTypeDisplayName(wizard_type, formatMessage),
        popoverContent: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.popover_content.wizard_type"
            defaultMessage="What kind of cycle is this"
          />
        ),
      },
      {
        name: (
          <FormattedMessage
            id="app.views.administration.campaign_administration_dashboard.column.name.action"
            defaultMessage="Action"
          />
        ),
        field: 'action',
        sortable: false,
        hideFromCSV: true,
        hideFromFilters: true,
      },
    ],
    [formatMessage, locale]
  );

  const generateNewCampaign = useCallback(() => {
    let newCampaign: NewCampaignWithConfigs | null = null;

    // set name to next quarter in the format QX YYYY by default
    const threeMonthsFromNow = new Date(
      Date.now() + consts.ONE_DAY_IN_MILLISECONDS * 90
    );

    const defaults = {
      // defaults for all new campaigns for saving
      // @ts-expect-error
      organization: props.currentOrganization?.id,
      id: undefined,
      name: getQuarterNameFromDateInQXYYYYFormat(threeMonthsFromNow),
      status: CAMPAIGN_STATUSES.INACTIVE,
    };

    // if no campaings already exist, return the simplest default campaign,
    // otherwise return the most recent campaign based on coverage_start date
    // and increment all the relevante dates by the performance period's duration
    // and set the start date to the first day of the performance period
    const active_campaigns = (campaigns ?? []).filter(
      // @ts-expect-error
      (c) => c.status === CAMPAIGN_STATUSES.ACTIVE
    );
    if (active_campaigns.length) {
      const mostRecentCampaign = active_campaigns.reduce((prev, current) =>
        // @ts-expect-error
        prev.coverage_start_date > current.coverage_start_date ? prev : current
      );

      // set coverage_start_date to the day after mostRecentCampaign.coverage_end_date
      // in YYYY-MM-DD format
      const newCoverageStartDate = new Date(
        // @ts-expect-error
        new Date(mostRecentCampaign.coverage_end_date).getTime() + 86400000
      );

      const coverageDuration =
        // @ts-expect-error
        new Date(mostRecentCampaign.coverage_end_date) -
        // @ts-expect-error
        new Date(mostRecentCampaign.coverage_start_date);

      // set coverage_end_date to newCoverageStartDate plus the duration of the
      // most recent campaign's performance period
      const newCoverageEndDate = new Date(
        newCoverageStartDate.getTime() + coverageDuration
      );

      newCampaign = {
        // @ts-expect-error
        ...mostRecentCampaign,
        coverage_start_date: getDatePart(newCoverageStartDate),
        coverage_end_date: getDatePart(newCoverageEndDate),
        ...defaults,
      };

      // if there is a calibration phase, add default auto_calibration_rules
      // if they don't already exist
      // @ts-expect-error
      if (!newCampaign.phases.find((p) => p.auto_calibration_rules)) {
        const calibrationPhase = getPhaseByType(
          newCampaign,
          PHASE_TYPE_CALIBRATION
        );
        if (calibrationPhase) {
          calibrationPhase.auto_calibration_rules =
            DEFAULT_AUTO_CALIBRATION_RULES;
        }
      }
      // we add the default questions to the phases
      // @ts-expect-error
      addDefaultOpenResponseQuestions(newCampaign, PHASE_TYPE_EVALUATION);
      addDefaultOpenResponseQuestions(
        newCampaign,
        PHASE_TYPE_OTHERS,
        PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS
      );
      // @ts-expect-error
      addDefaultOpenResponseQuestions(newCampaign, PHASE_TYPE_OTHERS);
      // @ts-expect-error
      newCampaign.configs = newCampaign.configs ?? {};
      // @ts-expect-error
      newCampaign.configs[CAMPAIGN_CONFIG_CREATED_WITH_DEFAULT_QUESTIONS] =
        true;
    } else {
      newCampaign = {
        ...defaults,
        phases: DEFAULT_CAMPAIGN_PHASES_JSON,
        configs: DEFAULT_CAMPAIGN_CONFIGS_JSON,
        // set coverage_start_date to the beginning of the previous quarter
        // in YYYY-MM-DD format
        coverage_start_date: getDatePart(
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          )
        ),
        // set coverage_end_date to the end of the current quarter
        // in YYYY-MM-DD format
        coverage_end_date: getDatePart(
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          )
        ),
      };

      // add introduction ui-only phase if missing
      if (!newCampaign.configs?.[INTRODUCTION_UI_PHASE_KEY]) {
        newCampaign.configs[INTRODUCTION_UI_PHASE_KEY] = {
          type: PHASE_TYPE_INTRODUCTION_UI_ONLY,
        };
      }
    }

    applyCampaignPhaseDates(newCampaign!);

    return newCampaign;
    // @ts-expect-error
  }, [campaigns, props.currentOrganization?.id]);

  const createCycle = useCallback(() => {
    setIsCreatingCycle(true);
    ConfirmAPI.sendRequestToConfirm(
      'POST',
      '/campaigns',
      generateNewCampaign(),
      toastErrorOrCallback(
        (response) => {
          setIsCreatingCycle(false);
          const campaign = response;
          const path =
            consts.ADMINISTRATION_CAMPAIGNS(formatMessage).path +
            '/' +
            campaign?.id;
          history.replace(path);
        },
        () => {
          setIsCreatingCycle(false);
        }
      ),
      null
    );
  }, [generateNewCampaign, history, formatMessage]);

  const newCampaignButton = useMemo(
    () => (
      <>
        <Button
          color="primary"
          onClick={createCycle}
          disabled={isCreatingCycle}
        >
          {isCreatingCycle && (
            <>
              <i
                className="spinner-border me-2"
                style={{
                  width: '0.8rem',
                  height: '0.8rem',
                  position: 'relative',
                  top: '-4px',
                }}
              />{' '}
              <FormattedMessage
                id="app.views.administration.campaign_administration_dashboard.new_campaign_button.creating_cycle"
                defaultMessage="Creating cycle..."
              />
            </>
          )}
          {!isCreatingCycle && (
            <>
              <FormattedMessage
                id="app.views.administration.campaign_administration_dashboard.new_campaign_button.create_new_cycle"
                defaultMessage="Create new cycle"
              />
            </>
          )}
        </Button>
      </>
    ),
    [createCycle, isCreatingCycle]
  );

  const actionButtons = useMemo(
    () => (
      <Row>
        {/* @ts-expect-error */}
        {campaigns?.length > 0 && (
          <Col className="col-auto">{newCampaignButton}</Col>
        )}
      </Row>
    ),
    // @ts-expect-error
    [campaigns?.length, newCampaignButton]
  );

  const loadOrRenderOutput = loadOrRender(campaigns, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  return (
    <>
      {typeof campaigns === 'undefined' && <Loading />}
      {typeof campaigns !== 'undefined' && (
        <Page title={consts.ADMINISTRATION_CAMPAIGNS(formatMessage).name}>
          {actionButtons}
          {/* @ts-expect-error */}
          {!(campaigns?.length > 0) && (
            <EmptyState
              title={formatMessage({
                id: 'app.views.administration.campaign_administration_dashboard.title.you_do_not_have_any_cycles_created',
                defaultMessage: 'You do not have any cycles created.',
              })}
              subtitle={formatMessage({
                id: 'app.views.administration.campaign_administration_dashboard.subtitle.get_started_with_your_first_cycle',
                defaultMessage: 'Get started with your first cycle.',
              })}
            >
              {newCampaignButton}
            </EmptyState>
          )}
          {/* @ts-expect-error */}
          {campaigns?.length > 0 && (
            <>
              <ConfirmationDialogModal
                isOpen={!!campaignIdPendingDeletion}
                onClosed={() => {
                  setIsDeletingCycle(false);
                  setDeleteValidationErrors(null);
                }}
                toggle={() => setCampaignIdPendingDeletion(null)}
                confirmCallback={confirmDeleteCallback}
                title={formatMessage({
                  id: 'app.views.administration.campaign_administration_dashboard.title.delete_this_campaign',
                  defaultMessage: 'Delete this campaign?',
                })}
                description={formatMessage({
                  id: 'app.views.administration.campaign_administration_dashboard.description.delete_this_campaign',
                  defaultMessage:
                    'Are you sure that you want to delete this campaign?',
                })}
                confirmText={formatMessage({
                  id: 'app.views.administration.campaign_administration_dashboard.confirm_text.delete_campaign',
                  defaultMessage: 'Delete campaign',
                })}
                validationErrors={deleteValidationErrors}
              />
              <FilterablePeopleTable
                // TODO: determine if this flag is truly needed here
                arrayValuesUsedForFormatting={true}
                rows={rows}
                columns={columns}
                hideFilters={true}
                hideExportButton={true}
              />
            </>
          )}
        </Page>
      )}
    </>
  );
};

const CampaignAdministrationDashboard_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  features: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<
  typeof CampaignAdministrationDashboard_propTypes
>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(
  React.memo(CampaignAdministrationDashboard)
);
