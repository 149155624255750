import React, { FC } from 'react';

import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { ICONS } from '../../consts/consts';
import { map } from 'lodash';

// despite ChatGPT's protestations to the contrary, react-csv doesn't
// actually handle escaping double-quotes in the data, so we have to do it
// ourselves
const escapeDoublequotes = (arrayOfArrays: string[][]) =>
  map(arrayOfArrays, (array) =>
    map(array, (item) => (item ? item.replace(/"/g, '""') : item))
  );

const ExportButton: FC<{ filename: string; data: string[][] }> = ({
  filename,
  data,
}) => (
  <CSVLink
    className="btn btn-light btn-sm"
    filename={filename}
    data={escapeDoublequotes(data)}
  >
    <i className={`me-2 bi ${ICONS.EXPORT}`} />
    <FormattedMessage
      id="app.components.export_button.export"
      defaultMessage="Export"
    />
  </CSVLink>
);

export default ExportButton;
