import React, { FC, useCallback } from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import * as consts from '../../consts/consts';

interface Props {
  path: string;
}

const ManualCopyUrlButton: FC<Props> = ({ path }) => {
  const { formatMessage } = useIntl();

  const copyLink = useCallback(() => {
    if (path) {
      navigator.clipboard.writeText(
        process.env.REACT_APP_CONFIRM_APP_URL + path
      );
      toast.success(
        formatMessage({
          id: 'app.views.person.manyalcopyurlbutton.copy_successful',
          defaultMessage: 'Link copied!',
        })
      );
    }
  }, [path, formatMessage]);

  return (
    <Button color="primary" onClick={copyLink}>
      <i className={`m-0 p-0 me-2 bi ${consts.ICONS.LINK}`} />
      <FormattedMessage
        id="app.views.person.manyalcopyurlbutton.copy_link.title"
        defaultMessage={'Copy link to manual'}
      />
    </Button>
  );
};

export default ManualCopyUrlButton;
