import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo } from 'react';

import { Person } from '../../types';

interface Props {
  me: Person;
  isOpen: boolean;
  onClosed: () => void;
}

const PreviewModal: FC<Props> = ({ me, isOpen, onClosed }) => {
  const { formatMessage } = useIntl();

  const closeButton = useMemo(
    () => (
      <button
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label={formatMessage({
          id: 'app.views.referrals.preview_modal.modal.aria_label.close',
          defaultMessage: 'Close',
        })}
        onClick={onClosed}
      />
    ),
    [formatMessage, onClosed]
  );

  return (
    <Modal
      // prevent hiding when clicking outside
      backdrop="static"
      // autofocus must be false to enable inputs to have autoFocus on them directly
      autoFocus={false}
      isOpen={isOpen}
      onClosed={onClosed}
    >
      <ModalHeader close={closeButton}>
        <FormattedMessage
          id="app.views.referrals.preview_modal.modal.title"
          defaultMessage={'Email Preview'}
        />
      </ModalHeader>
      <ModalBody>
        <div className="wrapper mb-1">
          <strong>
            <FormattedMessage
              id="app.views.referrals.preview_modal.modal.subject"
              defaultMessage={
                '{full_name} has invited you to check out Confirm!'
              }
              values={{
                full_name: me.full_name,
              }}
            />
          </strong>
        </div>
        <div className="wrapper">
          <div className="banner my-4">
            <img
              style={{ maxWidth: '100%' }}
              src="https://mcusercontent.com/ed13eb4f28f620394aa4ef136/images/70ca635a-5899-4f5b-87f2-7ce1b66e563c.png"
            />
          </div>
          <div className="content">
            <FormattedMessage
              id="app.views.referrals.preview_modal.modal.content"
              defaultMessage={`
                <p>Hey,</p>
                <p>{full_name} wants you to try Confirm. Why? Well, because {given_name} believes Confirm is the tool you've been searching for.</p>
                <p>
                  Here's why you should check out Confirm for your organization:
                  <br></br>
                  <br></br>
                  🌟 <strong>Top Performance Insights</strong>: Recognize the top 15% of your company that's driving 50% of your impact. Harness the power of your star performers and propel your business forward.
                  <br></br>
                  <br></br>
                  ⚠️ <strong>Problem Identification</strong>: Pinpoint the bottom 5% of your company that's causing 50% of the challenges. Address these bottlenecks strategically to foster a more cohesive and efficient work environment.
                  <br></br>
                  <br></br>
                  📊 <strong>Holistic Performance Management</strong>: With Confirm, you can run engagement surveys, OKRs, promotion packets, performance plans, and continuous feedback - everything under one roof.
                  <br></br>
                  <br></br>
                  🔗 <strong>Organizational Network Analysis</strong>: Get unparalleled insight into your team's dynamics. Understand how work flows, where bottlenecks arise, and who the key influencers in your company are.
                </p>
                <br></br>
                <p>Whether you're looking to optimize your team's performance or get granular insights into your company's operational dynamics, Confirm provides the comprehensive tools you need to succeed.</p>
                <p>Discover the Future of Performance Management!</p>
                <action>Accept invitation</action>
                <br></br>
                <p>
                  Warm regards,
                  <br></br>
                  Confirm Team
                </p>
                `}
              values={{
                full_name: me.full_name,
                given_name: me.given_name,
                p: (chunks) => <p>{chunks}</p>,
                br: () => <br />,
                strong: (chunks) => <strong>{chunks}</strong>,
                action: (chunks) => (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Button color="primary" onClick={onClosed}>
                      {chunks}
                    </Button>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PreviewModal;
