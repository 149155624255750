import React, { FC, useCallback, useMemo } from 'react';

import Heatmap from './Heatmap/Heatmap';
import HorizontalBar from './HorizontalBar';
import { sumObjectValues } from '../../../utils/util/util';

const COLOR_SCALE = (value) => {
  if (typeof value !== 'number') {
    return '';
  }
  if (value < 2) {
    return {
      background: '#e63757',
      text: '#12263f',
    };
  }
  if (value < 2.5) {
    return {
      background: '#fb999d',
      text: '#12263f',
    };
  }
  if (value <= 3.5) {
    return {
      background: '#EDEDED',
      text: '#12263f',
    };
  }
  if (value < 4) {
    return {
      background: '#9aecb7',
      text: '#12263f',
    };
  }
  return {
    background: '#00d97e',
    text: '#12263f',
  };
};

export const FORMAT_VALUE = (valueObj) => {
  // No responses
  if (!valueObj || Object.keys(valueObj).length === 0) {
    return '-';
  }

  let responseCount = 0;
  let responseSum = 0;

  for (const score in valueObj) {
    const numScore = Number(score);
    const numResponses = Number(valueObj[score]);

    responseCount += numResponses;
    responseSum += numScore * numResponses;
  }
  return Math.round((10 * responseSum) / responseCount) / 10;
};

const LIKERT_SCALE = [
  {
    value: 5,
    text: '5. Strongly agree',
    color: '#00d97e',
  },
  {
    value: 4,
    text: '4. Agree',
    color: '#9aecb7',
  },
  {
    value: 3,
    text: '3. Neutral',
    color: '#EDEDED',
  },
  {
    value: 2,
    text: '2. Disagree',
    color: '#fb999d',
  },
  {
    value: 1,
    text: '1. Strongly disagree',
    color: '#e63757',
  },
];

interface Props {
  data: Array<object>;
  renderItem: (data: object) => JSX.Element;
  colorScale?: (value: number) => { background: string; text: string };
  formatValue?: (valueObj: object) => number;
}

const LikertHeatmap: FC<Props> = (props) => {
  const formatPopoverData = (obj) => {
    if (typeof obj !== 'object') {
      return null;
    }

    const total = sumObjectValues(obj);
    const result = {
      total: total,
    };

    LIKERT_SCALE.forEach(
      (v) =>
        (result[v.value] = total === 0 ? total : (obj[v.value] || 0) / total)
    );

    return result;
  };

  const createPopoverChart = (id, dataObj) => (
    <>
      <hr />
      <div className="justify-content-center">
        <HorizontalBar
          data={LIKERT_SCALE.map((v) => ({
            color: v.color,
            value: dataObj[v.value],
          }))}
          // @ts-expect-error
          uniqueId={id}
        />
      </div>
      <br />
      {LIKERT_SCALE.map((v, i) => (
        <div key={i} className="d-flex justify-content-between">
          <span>
            <span className={'pe-3 me-2 '} style={{ backgroundColor: v.color }}>
              &nbsp;
            </span>
            <span className="me-5">{v.text}</span>{' '}
          </span>
          <span className="fw-bold">
            {dataObj[v.value] === 0
              ? '-'
              : Math.round(100 * dataObj[v.value]) + '%'}
          </span>
        </div>
      ))}
    </>
  );

  const getColor = useMemo(() => {
    if (props.colorScale) {
      return props.colorScale;
    }
    return COLOR_SCALE;
  }, [props.colorScale]);

  const getValue = useMemo(() => {
    if (props.formatValue) {
      return props.formatValue;
    }
    return FORMAT_VALUE;
  }, [props.formatValue]);

  const frozenRowData = useMemo(() => {
    return props.data?.map((d) => ({
      // @ts-expect-error

      all: d.all,
      // @ts-expect-error
      count: sumObjectValues(d.all?.responses),
      // @ts-expect-error
      name: d.configs?.display_name,
      customPrefix: 'frozen',
      // @ts-expect-error
      _name: d?.configs?.display_name,
    }));
  }, [props.data]);

  const unFrozenRowData = useCallback(
    (segment) => {
      return props.data?.map((d) => ({
        // @ts-expect-error
        ...(d.responses?.[segment] || {}),
        // @ts-expect-error
        _name: d?.configs?.display_name,
        _segment: segment,
      }));
    },
    [props.data]
  );

  return (
    <Heatmap
      data={props.data}
      colorScale={getColor}
      formatValue={getValue}
      frozenRowData={frozenRowData}
      unFrozenRowData={unFrozenRowData}
      formatPopover={formatPopoverData}
      popoverChart={createPopoverChart}
      renderItem={props.renderItem}
    />
  );
};

export default React.memo(LikertHeatmap);
