import React, { FC } from 'react';

interface Props {
  value: number;
}

export const CycleDiffBadge: FC<Props> = ({ value }) => {
  const negativeDiff = value < 0;
  const badgeColorClassName = negativeDiff
    ? 'bg-secondary-soft'
    : 'bg-success-soft';

  if (!value) return null;

  return (
    <span className={`badge ${badgeColorClassName} ms-3 pt-2`}>
      {negativeDiff ? value : `+ ${value}`}
    </span>
  );
};
