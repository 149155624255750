import * as consts from '../../consts/consts';

import {
  CAMPAIGN_STATUSES,
  getCampaignCoverageDurationMonthString,
  getPeopleOnReceivingEnd,
  getRelationships,
  replaceRelationships,
} from '../../utils/models/Campaign';
import { Card, CardBody, Col, Row, UncontrolledPopover } from 'reactstrap';
import {
  DEFAULT_PERFORMANCE_CALLOUTS_DESCRIPTION,
  LearnMoreAboutOna,
  PERFORMANCE_CALLOUTS_DESCRIPTION,
  PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT,
  PERFORMANCE_FEATURE_GOLD_STAR_COMMENTS_REQUIRED,
  PERFORMANCE_FEATURE_HEADS_UP_ANONYMOUS_TOGGLE,
  PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED,
  PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_CALLOUTS,
  PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_COMMENTS_REQUIRED,
  getCampaignFeature,
  getCampaignHasCalibrationPhase,
  getCampaignHasFeatureEnabled,
  getCampaignHasFeatureEnabledDefaultOn,
  getCurrentPerformancePreviewPathPrefix,
  getPerfLearnMorePopover,
  getStepNumber,
  perfCampaignCallback,
  updateLatestPerfStep,
} from '../../utils/models/Performance';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  INPUT_ATTRIBUTES,
  INPUT_TYPES,
} from '../Widgets/Inputs/ValidatedInputTypes';
import PropTypes, { InferProps } from 'prop-types';
import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  confirmLeavePage,
  prepTagsForSubmit,
  stripHtml,
} from '../../utils/util/util';
import { useHistory, useLocation, withRouter } from 'react-router';

import Avatar from '../Widgets/People/Avatar';
import PerformancePage from './PerformancePage';
import { RELATIONSHIP_TYPES } from '../../utils/models/RelationshipUtils';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import ValidatedForm from '../Widgets/Forms/ValidatedForm';
import { connect } from 'react-redux';
import { continuousFeedbackRecognitionIsEnabled } from '../../utils/util/features';
import { peopleObjectsAreEqual } from '../../utils/models/Person';
import { setCurrentPerfSurveyResponse } from '../../actions';

const RELATIONSHIP_COMMENTS_PREFIX = 'relationship_comments_';
const RELATIONSHIP_SKILLS_PREFIX = 'relationship_skills_';
const ANONYMOUS_CALLOUT_PREFIX = 'is_anonymous_';

const relationshipTypes = [
  RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
  RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
  RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT,
];

// We limit callout text length to be "less than a tweet" aka 280 chars
// so it's lightweight for execs and other leaders to capture all the
// qualitative data about a given callout.
const NON_HEADS_UP_CALLOUT_TEXT_MAX_LENGTH = 200;

// We allow longer lengths heads up callouts because individuals receive
// far fewer of them so they can be more detailed and still feel like
// a lightweight "executive summary" about a person. We increased this
// based on feedback of the severity/impact of this feedback being more
// material.
const HEADS_UP_CALLOUT_TEXT_MAX_LENGTH = 400;

// When writing comment on a past direct report for new manager.
const PAST_DIRECT_REPORT_MAX_LENGTH = 500;

const uniqueKey = (relationship, isDemoOrPreviewMode) => {
  const toPersonId = isDemoOrPreviewMode
    ? relationship.to_person?.id ?? relationship.to_person
    : relationship.to_person?.id;
  const type = relationship.type;
  return `${toPersonId}_${type}`;
};

const PerformanceStepCallouts: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const goldStarLearnMoreRef = useRef();
  const headsUpLearnMoreRef = useRef();
  const formersLearnMoreRef = useRef();

  const location = useLocation();
  const history = useHistory();

  const campaign = props.campaign;
  const setCampaign = props.setCampaign;

  const isDemoOrPreviewMode = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.DEMO,
    // @ts-expect-error
    [campaign?.status]
  );

  const propsDemoPeople = props.demoPeople;
  const continuousRecognitionIsEnabled = useMemo(
    // @ts-expect-error
    () => continuousFeedbackRecognitionIsEnabled(props.features),
    // @ts-expect-error
    [props.features]
  );

  const isOnFirstPage = useMemo(
    () =>
      // "?page=2" means on second page, else on first page
      new URLSearchParams(location.search).get('page')
        ? new URLSearchParams(location.search).get('page') != '2'
        : true,
    [location.search]
  );

  const previousDirectReportCalloutsAreEnabled = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_CALLOUTS
      ),
    [campaign]
  );

  const calloutSkillsAreEnabled = true;

  const anonymousHeadsUpOptionIsEnabled = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HEADS_UP_ANONYMOUS_TOGGLE
      ),
    [campaign]
  );

  const goldStarsAndInfluenceVisibleToRecipientIsEnabled = useMemo(
    () =>
      getCampaignHasFeatureEnabledDefaultOn(
        campaign,
        PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT
      ),
    [campaign]
  );

  const calloutsHeaderText = useMemo(
    () =>
      getCampaignFeature(campaign, PERFORMANCE_CALLOUTS_DESCRIPTION) ||
      (getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_GOLD_STAR_COMMENTS_REQUIRED
      ) ||
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED
      ) ||
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_COMMENTS_REQUIRED
      )
        ? ''
        : formatMessage({
            id: 'app.views.performance.performance_step_callouts.optional',
            defaultMessage: 'Optional:',
          }) + ' ') + DEFAULT_PERFORMANCE_CALLOUTS_DESCRIPTION(formatMessage),
    [campaign, formatMessage]
  );

  const usedToHaveAsDirectReportCommentsRequired = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_COMMENTS_REQUIRED
      ),
    [campaign]
  );

  const goldStarCommentsRequired = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_GOLD_STAR_COMMENTS_REQUIRED
      ),
    [campaign]
  );

  const headsUpCommentsRequired = useMemo(
    () =>
      getCampaignHasFeatureEnabled(
        campaign,
        PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED
      ),
    [campaign]
  );

  const currentStepNumber = useMemo(
    () =>
      getStepNumber(
        props.me,
        props.currentOrganization,
        campaign,
        props.demoPeople,
        location.pathname,
        formatMessage
      ),
    [
      location.pathname,
      props.currentOrganization,
      campaign,
      props.demoPeople,
      props.me,
      formatMessage,
    ]
  );

  const me = props.me;

  const object = useMemo(
    () => ({
      goldstars: getPeopleOnReceivingEnd(
        campaign,
        RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
        // @ts-expect-error
        me
      ),
      headsups: getPeopleOnReceivingEnd(
        campaign,
        RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
        // @ts-expect-error
        me
      ),
      formers: getPeopleOnReceivingEnd(
        campaign,
        RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT,
        // @ts-expect-error
        me
      ),
    }),
    [campaign, me]
  );

  const myCalloutRelationshipsList = useMemo(
    () =>
      [
        ...getRelationships(
          campaign,
          RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
          true
        ),
        ...getRelationships(
          campaign,
          RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
          true
        ),
        ...getRelationships(
          campaign,
          RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT,
          true
        ),
      ].filter((r) => peopleObjectsAreEqual(r.from_person, me)),
    [campaign, me]
  );

  const calloutDetailsObject = useMemo(() => {
    const inputObject = {};

    // former directs and gold stars should go in positive, heads up in negative
    for (let i = 0; i < myCalloutRelationshipsList?.length; i++) {
      const key = uniqueKey(myCalloutRelationshipsList[i], isDemoOrPreviewMode);
      inputObject[RELATIONSHIP_COMMENTS_PREFIX + key] =
        myCalloutRelationshipsList[i].type ===
        RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT
          ? myCalloutRelationshipsList[i].negative_comments
          : myCalloutRelationshipsList[i].positive_comments;
      inputObject[RELATIONSHIP_SKILLS_PREFIX + key] =
        myCalloutRelationshipsList[i].type ===
        RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT
          ? myCalloutRelationshipsList[i].negative_skills
          : myCalloutRelationshipsList[i].positive_skills;
      inputObject[ANONYMOUS_CALLOUT_PREFIX + key] =
        myCalloutRelationshipsList[i].is_anonymous;
    }

    return inputObject;
  }, [myCalloutRelationshipsList, isDemoOrPreviewMode]);

  const campaignHasCalibrationPhase = useMemo(
    () => getCampaignHasCalibrationPhase(campaign),
    [campaign]
  );

  const previewPathPrefix = getCurrentPerformancePreviewPathPrefix();

  const propsSetCurrentPerfSurveyResponse = props.setCurrentPerfSurveyResponse;
  const callback = useCallback(
    (data) => {
      if (data) {
        // simulate server response in demo, but for non-demo
        // get actual data from server
        const updatedRelationships = isDemoOrPreviewMode
          ? data.relationships.map((r) => ({
              from_person: me,
              ...r,
            }))
          : data.relationships;

        setCampaign(
          replaceRelationships(
            campaign,
            relationshipTypes,
            updatedRelationships
          )
        );

        if (isOnFirstPage && data.relationships?.length > 0) {
          // go to second page
          history.push(
            previewPathPrefix +
              consts.PERFORMANCE_STEP_CALLOUTS(formatMessage).path +
              '?page=2'
          );
          return;
        }

        propsSetCurrentPerfSurveyResponse(
          updateLatestPerfStep(
            props.currentPerfSurveyResponse,
            currentStepNumber
          )
        );
        perfCampaignCallback(
          props.me,
          props.currentOrganization,
          campaign,
          history,
          propsDemoPeople,
          data,
          formatMessage
        );
      }
    },
    [
      isDemoOrPreviewMode,
      setCampaign,
      campaign,
      isOnFirstPage,
      propsSetCurrentPerfSurveyResponse,
      props.currentPerfSurveyResponse,
      props.me,
      props.currentOrganization,
      currentStepNumber,
      history,
      propsDemoPeople,
      me,
      previewPathPrefix,
      formatMessage,
    ]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      const getToPerson = (p) => (isDemoOrPreviewMode ? p : p.id);

      const goldstars = object.goldstars?.map((p) => ({
        type: RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
        to_person: getToPerson(p),
      }));

      const headsups = object.headsups?.map((p) => ({
        type: RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
        to_person: getToPerson(p),
      }));

      const formers = object.formers?.map((p) => ({
        type: RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT,
        to_person: getToPerson(p),
      }));

      return {
        // @ts-expect-error
        campaign: campaign.id,
        // NOTE: we pass in the PREVIOUS step here as completing
        // the optional callout screen should be when the step
        // advances (so if they abandon when at that optional step,
        // we take them back to the list of callouts when they return,
        // AND so that if this is the last step of the campaign, e.g.
        // in the case of no resume or self-reflection step, it's not
        // considered done until the optional step is completed)
        step: currentStepNumber - 1,
        types: relationshipTypes,
        relationships: [...goldstars, ...headsups, ...formers],
      };
    },
    // @ts-expect-error
    [campaign.id, currentStepNumber, isDemoOrPreviewMode]
  );

  const transformCalloutDetailsObjectBeforeSubmit = useCallback(
    (object) => {
      const relationships = myCalloutRelationshipsList.map((r) => {
        const key = uniqueKey(r, isDemoOrPreviewMode);
        return {
          type: r.type,
          to_person: isDemoOrPreviewMode ? r.to_person : r.to_person?.id,
          positive_comments:
            r.type !== RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT
              ? object[RELATIONSHIP_COMMENTS_PREFIX + key]
              : null,
          negative_comments:
            r.type === RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT
              ? object[RELATIONSHIP_COMMENTS_PREFIX + key]
              : null,
          positive_skills:
            r.type !== RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT &&
            object[RELATIONSHIP_SKILLS_PREFIX + key]?.length > 0
              ? prepTagsForSubmit(
                  object[RELATIONSHIP_SKILLS_PREFIX + key],
                  // @ts-expect-error
                  props.currentOrganization?.id
                )
              : undefined,
          negative_skills:
            r.type === RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT &&
            object[RELATIONSHIP_SKILLS_PREFIX + key]?.length > 0
              ? prepTagsForSubmit(
                  object[RELATIONSHIP_SKILLS_PREFIX + key],
                  // @ts-expect-error
                  props.currentOrganization?.id
                )
              : undefined,
          is_anonymous: object[ANONYMOUS_CALLOUT_PREFIX + key],
        };
      });

      return {
        // @ts-expect-error
        campaign: campaign.id,
        step: currentStepNumber,
        types: relationshipTypes,
        relationships: relationships,
      };
    },
    [
      myCalloutRelationshipsList,
      // @ts-expect-error
      campaign.id,
      currentStepNumber,
      isDemoOrPreviewMode,
      // @ts-expect-error
      props.currentOrganization?.id,
    ]
  );

  const peopleListDicts = useMemo(
    () => [
      {
        type: RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO,
        question: (
          <div className="mb-3 ms-1">
            <FormattedMessage
              id="app.views.performance.performance_step_callouts.outstanding_impact"
              defaultMessage="How did these people make an outstanding impact at {orgName}?"
              // @ts-expect-error
              values={{ orgName: props.currentOrganization.name }}
            />
            <p className="text-muted small">
              <i className="fe fe-gift" />{' '}
              {goldStarsAndInfluenceVisibleToRecipientIsEnabled
                ? formatMessage({
                    id: 'app.views.performance.performance_step_callouts.comments_shared',
                    defaultMessage:
                      'Your comments will be shared with the person confidentially. However, their manager and above and HR will see your identity.',
                  })
                : formatMessage({
                    id: 'app.views.performance.performance_step_callouts.comments_not_shared',
                    defaultMessage:
                      'Your comments will not be shared with the person, but their manager and above and HR can see them.',
                  })}
            </p>
          </div>
        ),
        iconContainerClass: 'bg-warning',
        icon: 'fe fe-star',
        peopleList: object.goldstars,
      },
      {
        type: RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT,
        question: (
          <div className="mb-3 ms-1">
            <FormattedMessage
              id="app.views.performance.performance_step_callouts.support_attention"
              defaultMessage="What behaviors, actions, or impact need additional support or attention?"
            />
            <p className="text-muted small">
              <i className={consts.ICONS.HEADS_UP_SOFT} />{' '}
              <FormattedMessage
                id="app.views.performance.performance_step_callouts.callouts_privacy"
                defaultMessage="Your responses will
              not be shared with the person, but their manager and above and HR will see
              your comments.
            "
              />
            </p>
          </div>
        ),
        iconContainerClass: 'bg-info',
        icon: consts.ICONS.HEADS_UP_SOFT,
        peopleList: object.headsups,
      },
      {
        type: RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT,
        question: (
          <div className="mb-3 ms-1">
            <strong>
              <FormattedMessage
                id="app.views.performance.performance_step_callouts.previous_direct_reports"
                defaultMessage="Previous direct deports:"
              />
            </strong>{' '}
            <FormattedMessage
              id="app.views.performance.performance_step_callouts.one_thing"
              defaultMessage="What is the one thing their current manager should know?"
            />
          </div>
        ),
        iconContainerClass: 'bg-secondary',
        icon: 'fe fe-user',
        peopleList: object.formers,
      },
    ],
    [
      // @ts-expect-error
      props.currentOrganization.name,
      goldStarsAndInfluenceVisibleToRecipientIsEnabled,
      formatMessage,
      object.goldstars,
      object.headsups,
      object.formers,
    ]
  );

  const calloutsPlainTextHeader = useMemo(
    () => stripHtml(calloutsHeaderText),
    [calloutsHeaderText]
  );

  const renderCalloutDetailsForm = useCallback(
    (inputs, submitButton, draftWarning) => {
      return (
        <PerformancePage
          campaign={campaign}
          title={<RichTextViewer model={calloutsHeaderText} expanded={true} />}
          plainTextTitle={calloutsPlainTextHeader}
        >
          <>
            <span
              className="text-primary me-4"
              role="button"
              onClick={() => {
                if (confirmLeavePage(hasUnsavedChanges)) {
                  history.push(
                    previewPathPrefix +
                      consts.PERFORMANCE_STEP_CALLOUTS(formatMessage).path
                  );
                }
              }}
            >
              <FormattedMessage
                id="app.views.performance.performance_step_callouts.go_back"
                defaultMessage="Go back"
              />
            </span>
            {submitButton}
            {draftWarning}
          </>
          {inputs}
        </PerformancePage>
      );
    },
    [
      calloutsHeaderText,
      calloutsPlainTextHeader,
      campaign,
      hasUnsavedChanges,
      history,
      previewPathPrefix,
      formatMessage,
    ]
  );

  const calloutDetailInputs = useMemo(() => {
    return myCalloutRelationshipsList
      .map((r) => {
        const preferredName = r.to_person.given_name;
        const calloutTextMaxLength =
          r.type === RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT
            ? HEADS_UP_CALLOUT_TEXT_MAX_LENGTH
            : r.type === RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT
            ? PAST_DIRECT_REPORT_MAX_LENGTH
            : NON_HEADS_UP_CALLOUT_TEXT_MAX_LENGTH;
        const key = uniqueKey(r, isDemoOrPreviewMode);
        return [
          {
            required:
              r.type === RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT
                ? usedToHaveAsDirectReportCommentsRequired
                : r.type === RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO
                ? goldStarCommentsRequired
                : headsUpCommentsRequired,
            name: RELATIONSHIP_COMMENTS_PREFIX + key,
            type: INPUT_TYPES.TEXTAREA,
            // allow slightly longer for previous managers
            maxLength: calloutTextMaxLength,
            minRows:
              r.type === RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT
                ? 3
                : 2,
            style: { height: '62px' },
            label:
              r.type === RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT
                ? formatMessage(
                    {
                      id: 'app.views.performance.performance_step_callouts.one_thing_manager_should_know',
                      defaultMessage:
                        "What is the one thing {preferredName}'s current manager should know?",
                    },
                    { preferredName }
                  )
                : formatMessage(
                    {
                      id: 'app.views.performance.performance_step_callouts.why_did_you',
                      defaultMessage:
                        'Why did you {type, select, goldstar {give this gold star} other {choose this person}}?',
                    },
                    {
                      type:
                        r.type === RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO
                          ? 'goldstar'
                          : 'other',
                    }
                  ),
            maxLengthHelperText: formatMessage(
              {
                id: 'app.views.performance.performance_step_callouts.character_limit',
                defaultMessage:
                  'There is a {count} character limit to ensure your feedback is easy for managers, HR, and others to read and understand quickly.',
              },
              {
                count: calloutTextMaxLength,
              }
            ),
          },
          ...(calloutSkillsAreEnabled
            ? [
                {
                  ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
                  name: RELATIONSHIP_SKILLS_PREFIX + key,
                  formGroupClassName: 'pt-2 mb-n2',
                  helperText: formatMessage({
                    id: 'app.views.performance.performance_step_callouts.example',
                    defaultMessage: 'Example: listening, writing, patience',
                  }),
                  label:
                    r.type === RELATIONSHIP_TYPES.USED_TO_HAVE_AS_DIRECT_REPORT
                      ? formatMessage(
                          {
                            id: 'app.views.performance.performance_step_callouts.one_thing_manager_should_know_skills',
                            defaultMessage:
                              "What are {preferredName}'s greatest skills and behaviors?",
                          },
                          { preferredName }
                        ) + ' '
                      : r.type === RELATIONSHIP_TYPES.GIVES_GOLD_STAR_TO
                      ? formatMessage({
                          id: 'app.views.performance.performance_step_callouts.what_skills',
                          defaultMessage: 'What skills or behaviors stood out?',
                        })
                      : formatMessage({
                          id: 'app.views.performance.performance_step_callouts.what_skills_behaviors',
                          defaultMessage:
                            'What skills or behaviors need attention?',
                        }),
                },
              ]
            : []),
          // Note: we add the anonymous toggle for all callouts,
          // but filter it out for all non-heads ups below
          ...(anonymousHeadsUpOptionIsEnabled
            ? [
                {
                  type: INPUT_TYPES.SWITCH,
                  switchLabel: formatMessage({
                    id: 'app.views.performance.performance_step_callouts.submit_confidentially',
                    defaultMessage: 'Submit confidentially',
                  }),
                  defaultValue: false,
                  centered: true,
                  name: ANONYMOUS_CALLOUT_PREFIX + key,
                  checkedValue: true,
                  uncheckedValue: false,
                  enableHelp: true,
                  hoverHelperText: formatMessage(
                    {
                      id: 'app.views.performance.performance_step_callouts.submit_confidentially_helper',
                      defaultMessage:
                        "If you do not want anyone at {organization} to know who you are, including {preferredName}'s manager and above and HR, submit confidentially.\n\nEven if you do not submit confidentially, {preferredName} will not see your heads up.",
                    },
                    {
                      // @ts-expect-error
                      organization: props.currentOrganization?.name,
                      preferredName,
                    }
                  ),
                },
              ]
            : []),
        ];
      })
      .flat();
  }, [
    myCalloutRelationshipsList,
    usedToHaveAsDirectReportCommentsRequired,
    goldStarCommentsRequired,
    headsUpCommentsRequired,
    calloutSkillsAreEnabled,
    anonymousHeadsUpOptionIsEnabled,
    // @ts-expect-error
    props.currentOrganization?.name,
    formatMessage,
    isDemoOrPreviewMode,
  ]);

  const renderCalloutDetailsInputs = useCallback(
    (inputs) => {
      const numGoldStars = object.goldstars?.length;
      const numHeadsUps = object.headsups?.length;
      const numFormers = object.formers?.length;

      // index multiplier to determine what index we're on
      let mult = 1;
      if (calloutSkillsAreEnabled) mult += 1;
      if (anonymousHeadsUpOptionIsEnabled) mult += 1;

      return (
        <>
          {peopleListDicts.map((d, dictIndex) =>
            (dictIndex === 0 && numGoldStars === 0) ||
            (dictIndex === 1 && numHeadsUps === 0) ||
            (dictIndex === 2 && numFormers === 0) ? (
              <Fragment key={dictIndex}></Fragment>
            ) : (
              <div key={dictIndex}>
                <div className={'mb-4' + (dictIndex === 0 ? '' : ' mt-4')}>
                  {d.question}
                </div>
                <Row>
                  {d.peopleList.map((p, index) => {
                    const textInputIndex =
                      mult *
                      (dictIndex === 0
                        ? index
                        : dictIndex === 1
                        ? numGoldStars + index
                        : numGoldStars + numHeadsUps + index);

                    return (
                      <Col className="col-xl-4 col-md-6" key={index}>
                        <Card className="card-fill">
                          <div
                            className="avatar avatar-sm"
                            style={{
                              position: 'absolute',
                              top: '-0.85rem',
                              left: '-0.85rem',
                            }}
                          >
                            <div
                              className={
                                'avatar-title fs-lg rounded-circle text-white ' +
                                d.iconContainerClass
                              }
                            >
                              <i
                                className={d.icon}
                                style={{ position: 'relative', top: '-1px' }}
                              ></i>
                            </div>
                          </div>
                          <CardBody className="text-center">
                            <Avatar size="lg" className="mb-3" person={p} />
                            <h3 className="card-title mb-1">{p.full_name}</h3>
                            <p className="small text-muted mb-4">{p.title}</p>
                            <div className="text-start">
                              {inputs[textInputIndex]}
                              {calloutSkillsAreEnabled &&
                                inputs[textInputIndex + 1]}
                              {d.type ===
                                RELATIONSHIP_TYPES.GIVES_HEADS_UP_ABOUT &&
                                anonymousHeadsUpOptionIsEnabled && (
                                  <Row className="mt-3">
                                    <Col className="col-12 pt-sm-3 px-0">
                                      {
                                        inputs[
                                          textInputIndex +
                                            // The "1" accounts for anonymousHeadsUpOptionIsEnabled, which
                                            // has already been checked in the earlier conditional
                                            1 +
                                            (calloutSkillsAreEnabled ? 1 : 0)
                                        ]
                                      }
                                    </Col>
                                  </Row>
                                )}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )
          )}
        </>
      );
    },
    [
      object.goldstars?.length,
      object.headsups?.length,
      object.formers?.length,
      calloutSkillsAreEnabled,
      peopleListDicts,
      anonymousHeadsUpOptionIsEnabled,
    ]
  );

  const onValidate = useCallback(
    (obj) => {
      const errors = {};

      if (!(obj.goldstars?.length <= 3)) {
        errors['goldstars'] = formatMessage({
          id: 'app.views.performance.performance_step_callouts.gold_stars_max',
          defaultMessage:
            'A maximum of three people is allowed to ensure that the most outstanding contributors stand out.',
        });
      }

      const peopleLists = ['goldstars', 'headsups', 'formers'];

      for (let i = 0; i < peopleLists.length; i++) {
        const listName = peopleLists[i];
        const invalidPeople =
          obj[listName]?.length > 0 && obj[listName].filter((p) => !p.id);
        if (invalidPeople?.length > 0) {
          if (invalidPeople.length === 1) {
            errors[listName] = formatMessage(
              {
                id: 'app.views.performance.performance_step_callouts.not_found_single',
                defaultMessage: '{email} was not found.',
              },
              {
                email: '"' + invalidPeople[0].email + '"',
              }
            );
          } else {
            errors[listName] = formatMessage(
              {
                id: 'app.views.performance.performance_step_callouts.not_found_multiple',
                defaultMessage:
                  'The following people were not found: {emailList}',
              },
              {
                emailList:
                  '"' + invalidPeople.map((p) => p.email).join('", "') + '"',
              }
            );
          }
        }
      }

      return errors;
    },
    [formatMessage]
  );

  return (
    <>
      {isOnFirstPage && (
        <PerformancePage
          campaign={campaign}
          title={formatMessage({
            id: 'app.views.performance.performance_step_callouts.title.call_out_notable_people',
            defaultMessage: 'Call out notable people.',
          })}
        >
          <></>
          <ValidatedForm
            method="POST"
            draftAutosaveEnabled={!isDemoOrPreviewMode}
            // @ts-expect-error
            uniqueFormKey={`campaign-${campaign.id}-callout`}
            url={isDemoOrPreviewMode ? undefined : 'relationships-list'}
            callback={callback}
            buttonIsBlock={false}
            buttonClassName="mt-0"
            submitText={formatMessage({
              id: 'app.views.performance.performance_step_callouts.submit_text.save_and_continue',
              defaultMessage: 'Save and continue',
            })}
            transformObjectBeforeSubmit={transformObjectBeforeSubmit}
            object={object}
            onValidate={onValidate}
            setHasUnsavedChanges={setHasUnsavedChanges}
            inputs={[
              {
                type: INPUT_TYPES.PEOPLE_EDITOR,
                isDemoMode: isDemoOrPreviewMode,
                name: 'goldstars',
                label: consts
                  .PERFORMANCE_QUESTIONS(formatMessage)
                  ['goldstars'](
                    getCampaignCoverageDurationMonthString(
                      campaign,
                      formatMessage
                    )
                  ),
                helperText: (
                  <span>
                    <i className="fe fe-star me-2" />
                    <FormattedMessage
                      id="app.views.performance.performance_step_callouts.three_impact"
                      defaultMessage="
                    Choose up to three people who you believe made an
                    outstanding impact at {orgName}."
                      // @ts-expect-error
                      values={{ orgName: props.currentOrganization.name }}
                    />{' '}
                    {/* @ts-expect-error */}
                    <span className="text-primary" ref={goldStarLearnMoreRef}>
                      <FormattedMessage
                        id="app.views.performance.performance_step_callouts.learn_more"
                        defaultMessage="Learn more"
                      />
                    </span>
                    <UncontrolledPopover
                      delay={50}
                      trigger="hover"
                      // @ts-expect-error
                      target={goldStarLearnMoreRef}
                    >
                      {getPerfLearnMorePopover(
                        <span>
                          <i className="fe fe-gift" />{' '}
                          <span className="fw-bold">
                            <FormattedMessage
                              id="app.views.performance.performance_step_callouts.recognize_outstanding.title"
                              defaultMessage="Who should I mention?"
                            />
                          </span>
                          <p>
                            <FormattedMessage
                              id="app.views.performance.performance_step_callouts.recognize_outstanding"
                              defaultMessage="Recognize outstanding
                            contributors for use in the {processName, select, calibration {calibration} other {review}} process. Note that each person can only pick up to
                            three people to ensure that the most outstanding
                            contributors stand out.
                            "
                              values={{
                                processName: campaignHasCalibrationPhase
                                  ? 'calibration'
                                  : 'review',
                              }}
                            />
                          </p>
                          <p>
                            <FormattedMessage
                              id="app.views.performance.performance_step_callouts.previous_focus"
                              defaultMessage="Unlike the previous questions which focus on people's impact on you, this question focuses on people who most impact the entire organization through their work."
                            />
                          </p>
                          {continuousRecognitionIsEnabled && (
                            <p>
                              <FormattedMessage
                                id="app.views.performance.performance_step_callouts.recognize_more_than_three"
                                defaultMessage="
                            Want to recognize more than three people? After
                            completing the cycle, you will see instructions for
                            how to give recognition to these additional people.
                          "
                              />
                            </p>
                          )}
                        </span>,
                        goldStarsAndInfluenceVisibleToRecipientIsEnabled
                          ? formatMessage({
                              id: 'app.views.performance.performance_step_callouts.shared_confidentially',
                              defaultMessage:
                                'It will be shared with the person confidentially. However, their manager and above and HR will see your identity.',
                            })
                          : formatMessage({
                              id: 'app.views.performance.performance_step_callouts.not_shared_with_person',
                              defaultMessage:
                                'It will not be shared with the person, but their manager and above and HR can see it.',
                            }),
                        <LearnMoreAboutOna />
                      )}{' '}
                    </UncontrolledPopover>
                  </span>
                ),
                autoFocus: true,
              },
              {
                type: INPUT_TYPES.PEOPLE_EDITOR,
                isDemoMode: isDemoOrPreviewMode,
                name: 'headsups',
                label: consts
                  .PERFORMANCE_QUESTIONS(formatMessage)
                  ['headsups'](
                    getCampaignCoverageDurationMonthString(
                      campaign,
                      formatMessage
                    )
                  ),
                helperText: (
                  <span>
                    <i className={consts.ICONS.HEADS_UP_SOFT + ' me-2'} />
                    <FormattedMessage
                      id="app.views.performance.performance_step_callouts.additional_support"
                      defaultMessage="
                    List anyone who needs additional support or attention
                    regarding their behaviors, actions, or impact at {orgName}."
                      // @ts-expect-error
                      values={{ orgName: props.currentOrganization.name }}
                    />{' '}
                    {anonymousHeadsUpOptionIsEnabled && (
                      <>
                        <FormattedMessage
                          id="app.views.performance.performance_step_callouts.anonymous_feedback"
                          defaultMessage="
                        If you select someone, you can provide confidential
                        feedback on the following screen."
                        />{' '}
                      </>
                    )}
                    {/* @ts-expect-error */}
                    <span className="text-primary" ref={headsUpLearnMoreRef}>
                      <FormattedMessage
                        id="app.views.performance.performance_step_callouts.learn_more"
                        defaultMessage="Learn more"
                      />
                    </span>
                    <UncontrolledPopover
                      delay={50}
                      trigger="hover"
                      placement="top"
                      // @ts-expect-error
                      target={headsUpLearnMoreRef}
                    >
                      {getPerfLearnMorePopover(
                        <span>
                          <FormattedMessage
                            id="app.views.performance.performance_step_callouts.flagged_optional"
                            defaultMessage="
                          This information is used to ensure that issues are
                          flagged during the {phaseName, select, calibration {calibration} other {review}} process and is totally optional.
                          "
                            values={{
                              phaseName: campaignHasCalibrationPhase
                                ? 'calibration'
                                : 'review',
                            }}
                          />
                        </span>,
                        <>
                          <span>
                            <FormattedMessage
                              id="app.views.performance.performance_step_callouts.not_shared"
                              defaultMessage="
                          They will not be shared with anyone you mention, but their
                          manager and above and HR will see your comments and identity
                          "
                            />
                            {anonymousHeadsUpOptionIsEnabled && (
                              <>
                                {' '}
                                <FormattedMessage
                                  id="app.views.performance.performance_step_callouts.optional_anonymous"
                                  defaultMessage="
                              (unless you choose to provide it confidentially on
                              the next screen)
                            "
                                />
                              </>
                            )}
                            <FormattedMessage
                              id="app.views.performance.performance_step_callouts.final_sentence_period"
                              defaultMessage="
                          .
                        "
                            />
                          </span>
                        </>,
                        <>
                          <br />
                          <br />
                          <span>
                            <i className="fe fe-gift" />{' '}
                            <span className="fw-bold">
                              <FormattedMessage
                                id="app.views.performance.performance_step_callouts.heads_ups.how_use_data.title"
                                defaultMessage="How will this data be used?"
                              />
                            </span>
                            <p>
                              <FormattedMessage
                                id="app.views.performance.performance_step_callouts.heads_ups.how_use_data.description"
                                defaultMessage="
                              The person's manager and above and HR will respect your privacy as they take your perspective into consideration.
                              In some circumstances someone may contact you privately to learn more if it is appropriate for the situation."
                              />
                            </p>
                          </span>
                        </>
                      )}
                    </UncontrolledPopover>
                  </span>
                ),
              },
              ...(previousDirectReportCalloutsAreEnabled
                ? [
                    {
                      type: INPUT_TYPES.PEOPLE_EDITOR,
                      isDemoMode: isDemoOrPreviewMode,
                      name: 'formers',
                      label: (
                        <span>
                          {consts
                            .PERFORMANCE_QUESTIONS(formatMessage)
                            ['formers'](
                              getCampaignCoverageDurationMonthString(
                                campaign,
                                formatMessage
                              )
                            )}{' '}
                          <span
                            className="text-primary"
                            // @ts-expect-error
                            ref={formersLearnMoreRef}
                          >
                            <FormattedMessage
                              id="app.views.performance.performance_step_callouts.learn_more"
                              defaultMessage="Learn more"
                            />
                          </span>
                          <UncontrolledPopover
                            delay={50}
                            trigger="hover"
                            placement="top"
                            // @ts-expect-error
                            target={formersLearnMoreRef}
                          >
                            {getPerfLearnMorePopover(
                              formatMessage({
                                id: 'app.views.performance.performance_step_callouts.former_direct_reports',
                                defaultMessage:
                                  'You may also add anyone whose work you informally managed if you have permission from their current manager.',
                              }),
                              formatMessage({
                                id: 'app.views.performance.performance_step_callouts.the_persons_current_manager',
                                defaultMessage:
                                  "The person's current manager and above and HR can see that you mentioned them.",
                              }),
                              <LearnMoreAboutOna />
                            )}
                          </UncontrolledPopover>
                        </span>
                      ),
                      helperText: (
                        <span>
                          <i className="fe fe-user me-2" />
                          <FormattedMessage
                            id="app.views.performance.performance_step_callouts.comments_after"
                            defaultMessage="
                          You can provide comments for their current manager on
                          the next screen if you haven't already via
                          continuous feedback.
                        "
                          />
                        </span>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </PerformancePage>
      )}
      {!isOnFirstPage && (
        <ValidatedForm
          method="POST"
          draftAutosaveEnabled={!isDemoOrPreviewMode}
          // @ts-expect-error
          uniqueFormKey={`campaign-${campaign.id}-callout-details`}
          url={isDemoOrPreviewMode ? undefined : 'relationships-list'}
          callback={callback}
          buttonIsBlock={false}
          buttonClassName="mt-0"
          submitText={formatMessage({
            id: 'app.views.performance.performance_step_callouts.submit_text.save_and_continue',
            defaultMessage: 'Save and continue',
          })}
          transformObjectBeforeSubmit={
            transformCalloutDetailsObjectBeforeSubmit
          }
          object={calloutDetailsObject}
          renderForm={renderCalloutDetailsForm}
          renderInputs={renderCalloutDetailsInputs}
          setShouldShowPrompt={setHasUnsavedChanges}
          inputs={calloutDetailInputs}
        />
      )}
    </>
  );
};

const PerformanceStepCallouts_propTypes = {
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  currentPerfSurveyResponse: PropTypes.object.isRequired,
  setCurrentPerfSurveyResponse: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
  demoPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

type Props = InferProps<typeof PerformanceStepCallouts_propTypes>;

const mapStateToProps = (state) => {
  const {
    me,
    features,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  } = state;

  return {
    me,
    features,
    currentOrganization,
    currentPerfSurveyResponse,
    demoPeople,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPerfSurveyResponse: (changes) =>
      dispatch(setCurrentPerfSurveyResponse(changes)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withRouter(React.memo(PerformanceStepCallouts)));
