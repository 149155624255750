import {
  Button,
  ButtonGroup,
  Col,
  Input,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Objective,
  STATUS_DESCRIPTOR,
  calculateScores,
  format2Decimals,
  formatUnit,
  statusDescriptorFor,
} from '../../utils/models/Objective';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isBoolean, isEqual } from 'lodash';
import { useEscape, useOutsideClick } from '../../utils/util/hooks';

import Avatar from '../Widgets/People/Avatar';
import DatePicker from '../Widgets/Inputs/DatePicker';
import { Doughnut } from 'react-chartjs-2';
import { Me } from '../../types';
import ObjectiveHistory from './ObjectiveHistory';
import RevealLink from '../Widgets/RevealLink';
import SwitchInput from '../Widgets/Inputs/SwitchInput';
import { getDatePart } from '../../utils/util/time';

interface ObjectiveIndicatorProps {
  objective: Objective;
  parentObjective?: Objective;
  disabled: boolean;
  hidden?: boolean;
  me: Me;
  onChange?: (objective: Objective) => void;
  omitDateInputs?: boolean;
}

const doughnutOptionsPercentage = {
  tooltips: false,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  events: [],
  cutoutPercentage: 70,
  animation: {
    duration: 400,
  },
};

const doughnutOptionsPlain = {
  tooltips: false,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  events: [],
  cutoutPercentage: 0,
  animation: {
    duration: 400,
  },
};

interface InputWrapperProps {
  name: string;
  disabled: boolean;
  disabledValue: string;
  validationMessage?: string;
}

const InputWrapper: FC<PropsWithChildren<InputWrapperProps>> = ({
  disabled,
  disabledValue,
  validationMessage,
  children,
}) => {
  if (disabled) {
    return <span>{disabledValue || '-'}</span>;
  }

  return (
    <>
      {children}{' '}
      {validationMessage && (
        <span className="invalid-feedback text-nowrap">
          {validationMessage}
        </span>
      )}
    </>
  );
};

const hasMoreDecimals = (num: number, precision: number) => {
  return String(num).split('.')[1]?.length > precision;
};

const ObjectiveIndicator: FC<ObjectiveIndicatorProps> = ({
  objective,
  parentObjective = null,
  disabled,
  hidden = false,
  me,
  onChange = () => {
    /*do nothing*/
  },
  omitDateInputs = false,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const goalAmountHelperRef = useRef<HTMLElement>(null);
  const weightHelperRef = useRef<HTMLElement>(null);
  const autoCalculationHelperRef = useRef<HTMLElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  // need to use the state instead of a ref since the datepicker is not a controlled component
  const [dataPickerOpen, setDataPickerOpen] = useState(false);
  const [currentObjective, setCurrentObjective] = useState(objective);
  const [validations, setValidations] = useState({});
  const intl = useIntl();

  const commonSectionClassName = 'small pt-3';

  useEffect(() => {
    if (!isEqual(currentObjective, objective)) {
      setCurrentObjective(objective);
    }
  }, [currentObjective, objective]);

  // todo add useEffect from external change on objective
  const handleChange = (e) => {
    handleChanges([e]);
  };

  const validate = useCallback(
    (e) => {
      const { name, value, min, max, type } = e.target;

      if (name === 'coverage_start_date' && !value) {
        return intl.formatMessage({
          id: 'app.views.objectives.objective_indicator.validation.coverage_start_date_required',
          defaultMessage: 'Start date is required',
        });
      }
      if (name === 'coverage_end_date' && !value) {
        return intl.formatMessage({
          id: 'app.views.objectives.objective_indicator.validation.coverage_end_date_required',
          defaultMessage: 'End date is required',
        });
      }
      if (
        (name === 'coverage_end_date' &&
          value <= currentObjective.coverage_start_date) ||
        (name === 'coverage_start_date' &&
          value >= currentObjective.coverage_end_date)
      ) {
        if (name === 'coverage_end_date') {
          return intl.formatMessage({
            id: 'app.views.objectives.objective_indicator.validation.coverage_end_date',
            defaultMessage: 'End date must be after start date',
          });
        } else if (name === 'coverage_start_date') {
          return intl.formatMessage({
            id: 'app.views.objectives.objective_indicator.validation.coverage_start_date',
            defaultMessage: 'Start date must be before end date',
          });
        }
      }

      if (
        value &&
        type === 'number' &&
        (Number(min) > value ||
          Number(max) < value ||
          hasMoreDecimals(value, 2))
      ) {
        return intl.formatMessage(
          {
            id: 'app.views.objectives.objective_indicator.validation.min_max',
            defaultMessage:
              'Please insert a value between {minValue} and {maxValue}',
          },
          { minValue: min, maxValue: max }
        );
      }
      return null;
    },
    [
      currentObjective.coverage_end_date,
      currentObjective.coverage_start_date,
      intl,
    ]
  );

  const handleChanges = useCallback(
    (events) => {
      const validations = events.reduce((acc, e) => {
        const validation = validate(e);
        if (validation) {
          return {
            ...acc,
            [e.target.name]: validation,
          };
        }
        return acc;
      }, {});

      if (Object.keys(validations).length > 0) {
        setValidations(validations);
        return;
      }

      setValidations({});
      const newValue = {
        ...currentObjective,
        ...events.reduce(
          (acc, e) => ({
            ...acc,
            [e.target.name]: isBoolean(e.target.value)
              ? e.target.value
              : e.target.value || null,
          }),
          {}
        ),
      };
      onChange(newValue);
      setCurrentObjective(newValue);
    },
    [currentObjective, onChange, validate]
  );

  const closePopover = useCallback(() => {
    // reset validations because when the popover is re-opened it will clear
    // the bad values
    setValidations({});
    setPopoverOpen(false);
  }, []);

  const toggle = useCallback(() => {
    if (popoverOpen) {
      closePopover();
    } else {
      setPopoverOpen(true);
    }
  }, [closePopover, popoverOpen]);

  useEscape(() => {
    closePopover();
  });
  useOutsideClick(popoverRef, () => {
    if (!dataPickerOpen) {
      closePopover();
    }
  });

  const handleMouseOver = useCallback(() => {
    if (disabled) {
      setPopoverOpen(true);
    }
  }, [disabled]);

  const status = objective.status;

  const start_date = objective.coverage_start_date;
  const end_date = objective.coverage_end_date;
  const score_comments = objective.score_comments ?? '';
  const scores = calculateScores(objective, parentObjective);
  const buttonGroupClass = intl?.locale != 'en' ? 'd-block w-100' : 'w-100';
  const buttonClass =
    intl?.locale != 'en' ? 'text-nowrap mb-1' : 'w-25 text-nowrap';
  // Note that che check x == null is to get undefined+null (but not zero)
  const showPercentage = objective.auto_calculation || objective.score != null;
  const OPACITY_STATUS = 0.85;
  const OPACITY_INDICATORS = 0.45;
  const OPACITY_INDICATORS_PERCENTAGE = 0.7;
  return (
    <>
      <Row hidden={hidden} onMouseOver={handleMouseOver}>
        <Col className="pe-0">
          <div
            className={
              'btn p-2 ' + (popoverOpen ? 'btn-light' : 'btn-outline-light')
            }
            role="button"
            aria-label={intl.formatMessage({
              id: 'app.views.objectives.objective_indicator.button.aria_label',
              defaultMessage: 'Objective status',
            })}
            aria-checked={popoverOpen}
            onClick={toggle}
            ref={ref}
          >
            <div
              style={{
                opacity: !showPercentage
                  ? OPACITY_INDICATORS
                  : OPACITY_INDICATORS_PERCENTAGE,
              }}
            >
              <Doughnut
                // added the hidden key to redraw the component
                // otherwise the canvas will appear with size zero when initially hidden
                key={objective.key + '-' + hidden}
                options={
                  showPercentage
                    ? doughnutOptionsPercentage
                    : doughnutOptionsPlain
                }
                height={20}
                width={20}
                data={{
                  datasets: [
                    {
                      data: showPercentage
                        ? [
                            Math.min(
                              (scores.derived.score || 0) /
                                (scores.derived.score_denominator || 100),
                              1
                            ),
                            Math.max(
                              1 -
                                (scores.derived.score || 0) /
                                  (scores.derived.score_denominator || 100),
                              0
                            ),
                          ]
                        : [1, 0],
                      backgroundColor: [
                        statusDescriptorFor(currentObjective.status, intl)
                          ?.colorHex ?? '#ABB1B9',
                        '#E3EBF6',
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </Col>
        <Col
          className="col-4 ps-2 pt-2 d-flex justify-content-start"
          style={{ width: '50px' }}
        >
          {showPercentage && (
            <span className="text-muted text-nowrap">
              {formatUnit(
                ((scores.derived.score || 0) /
                  (scores.derived.score_denominator || 100)) *
                  100
              )}
              <span className="fs-6">{'%'}</span>
            </span>
          )}
        </Col>
      </Row>

      <UncontrolledPopover
        className="popover-lg"
        target={ref}
        delay={0}
        placement="bottom"
        isOpen={popoverOpen}
      >
        <div ref={popoverRef} style={{ minWidth: '330px' }}>
          <div className="small">
            <h5>
              <FormattedMessage
                id="app.views.objectives.objective_indicator.status.header"
                defaultMessage="Status"
              />
            </h5>
            <ButtonGroup
              className={buttonGroupClass}
              style={{
                // filter: 'brightness(120%)',
                opacity: OPACITY_STATUS,
              }}
            >
              {STATUS_DESCRIPTOR(intl).map((element) => (
                <Button
                  disabled={disabled}
                  className={buttonClass}
                  name="status"
                  key={objective.key + '-' + element.status}
                  size="sm"
                  color={element.color}
                  onClick={() =>
                    handleChange({
                      target: { name: 'status', value: element.status },
                    })
                  }
                  outline={status != element.status}
                >
                  <span>{element.i18n}</span>
                </Button>
              ))}
            </ButtonGroup>
          </div>
          {!omitDateInputs && (
            <Row className={`${commonSectionClassName}`}>
              <Col className="col-auto">
                <h5>
                  <FormattedMessage
                    id="app.views.objectives.objective_indicator.start_date.header"
                    defaultMessage="Start Date"
                  />
                </h5>
                <InputWrapper
                  name="coverage_start_date"
                  disabled={disabled}
                  disabledValue={start_date}
                  validationMessage={validations['coverage_start_date']}
                >
                  <DatePicker
                    name="coverage_start_date"
                    label={intl.formatMessage({
                      id: 'app.views.objectives.objective_indicator.start_date.input.label',
                      defaultMessage: 'Start Date',
                    })}
                    hideClearButton={true}
                    inputClassName="form-control-sm"
                    bsSize="sm"
                    value={start_date}
                    onOpen={() => setDataPickerOpen(true)}
                    onChange={(newDate) => {
                      setDataPickerOpen(false);
                      handleChange({
                        target: {
                          name: 'coverage_start_date',
                          value: newDate ? getDatePart(newDate) : null,
                        },
                      });
                    }}
                  />
                </InputWrapper>
              </Col>
              <Col>
                <h5>
                  <FormattedMessage
                    id="app.views.objectives.objective_indicator.end_date.header"
                    defaultMessage="End Date"
                  />
                </h5>
                <InputWrapper
                  name="coverage_end_date"
                  disabled={disabled}
                  disabledValue={end_date}
                  validationMessage={validations['coverage_end_date']}
                >
                  <DatePicker
                    name="coverage_end_date"
                    label={intl.formatMessage({
                      id: 'app.views.objectives.objective_indicator.end_date.input.label',
                      defaultMessage: 'End Date',
                    })}
                    hideClearButton={true}
                    inputClassName="form-control-sm"
                    bsSize="sm"
                    value={end_date}
                    onOpen={() => setDataPickerOpen(true)}
                    onChange={(newDate) => {
                      setDataPickerOpen(false);
                      handleChange({
                        target: {
                          name: 'coverage_end_date',
                          value: newDate ? getDatePart(newDate) : null,
                        },
                      });
                    }}
                  />
                </InputWrapper>
              </Col>
            </Row>
          )}
          <div className={commonSectionClassName}>
            <h5>
              <FormattedMessage
                id="app.views.objectives.objective_indicator.goal_amount.header"
                defaultMessage="Goal Amount"
              />
              <>
                <i
                  className="ms-2 fe fe-help-circle text-primary small"
                  ref={goalAmountHelperRef}
                />
                <UncontrolledPopover
                  placement="top"
                  trigger="hover"
                  target={goalAmountHelperRef}
                >
                  <FormattedMessage
                    id="app.views.objectives.objective_indicator.goal_amount.popover"
                    defaultMessage="If this is a numeric goal, e.g. a sales quota, put that goal
                  amount here. If you leave it blank, by default each
                  objective's goal is 100, and each key result below it
                  counts for an equal portion of that 100."
                  />
                </UncontrolledPopover>
              </>
            </h5>
            <Row className="g-0">
              <Col className="col-sm-6">
                <InputWrapper
                  name="score_denominator"
                  disabled={disabled}
                  disabledValue={
                    scores.item.score_denominator ||
                    format2Decimals(scores.derived.score_denominator)
                  }
                  validationMessage={validations['score_denominator']}
                >
                  <Input
                    disabled={disabled}
                    name="score_denominator"
                    aria-label={intl.formatMessage({
                      id: 'app.views.objectives.objective_indicator.goal_amount.input.aria_label',
                      defaultMessage: 'Goal Amount',
                    })}
                    type="number"
                    bsSize="sm"
                    min={1}
                    max={999_999_999_999.99}
                    value={scores.item.score_denominator ?? ''}
                    placeholder={format2Decimals(
                      scores.derived.score_denominator
                    )}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </Col>
            </Row>
          </div>
          <div className={commonSectionClassName}>
            <h5>
              <FormattedMessage
                id="app.views.objectives.objective_indicator.amount_achieved.header"
                defaultMessage="Amount achieved so far"
              />
            </h5>
            <Row className="g-0">
              <Col className="col-sm-6">
                <InputWrapper
                  name="score"
                  disabled={disabled}
                  disabledValue={intl.formatMessage(
                    {
                      id: 'app.views.objectives.objective_indicator.amount_achieved.input.disabled_value',
                      defaultMessage: '{score}% ({scorePercentage}%) complete',
                    },
                    {
                      scorePercentage: format2Decimals(
                        ((scores.derived.score || 0) /
                          (scores.derived.score_denominator || 100)) *
                          100
                      )?.toString(),
                      score:
                        scores.item.score ||
                        format2Decimals(scores.derived.score),
                    }
                  )}
                  validationMessage={validations['score']}
                >
                  <Input
                    disabled={disabled}
                    name="score"
                    aria-label={intl.formatMessage({
                      id: 'app.views.objectives.objective_indicator.amount_achieved.input.aria_label',
                      defaultMessage: 'Amount achieved so far',
                    })}
                    type="number"
                    min={0}
                    max={999_999_999_999.99}
                    bsSize="sm"
                    value={scores.item.score ?? ''}
                    placeholder={format2Decimals(scores.derived.score)}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </Col>
              <Col className="col-auto ps-3 pe-1 pt-1">
                {!disabled && (
                  <span className="text-muted fs-5">
                    {' '}
                    <FormattedMessage
                      id="app.views.objectives.objective_indicator.amount_achieved.input.side_indicator.text"
                      defaultMessage="{percentageComplete}<tiny>%</tiny> complete"
                      values={{
                        tiny: (chunks) => (
                          <span className="fs-6">{chunks}</span>
                        ),
                        percentageComplete: format2Decimals(
                          ((scores.derived.score || 0) /
                            (scores.derived.score_denominator || 100)) *
                            100
                        ),
                      }}
                    />
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <div className={commonSectionClassName}>
            <h5>
              <FormattedMessage
                id="app.views.objectives.objective_indicator.weight.header"
                defaultMessage="Weight"
              />
              <>
                <i
                  className="ms-2 fe fe-help-circle text-primary small"
                  ref={weightHelperRef}
                />
                <UncontrolledPopover
                  placement="top"
                  trigger="hover"
                  target={weightHelperRef}
                >
                  <FormattedMessage
                    id="app.views.objectives.objective_indicator.weight.popover"
                    defaultMessage="If this item should be weighted equally with its siblings when
                  calculating the parent overall score, leave this blank.
                  Otherwise, you can specify a weight manually here to raise or
                  lower the impact of this specific item in auto-calculating the
                  parent's overall score."
                  />
                </UncontrolledPopover>
              </>
            </h5>
            <Row className="g-0">
              <Col className="col-2">
                <InputWrapper
                  name="weight"
                  disabled={disabled}
                  disabledValue={
                    (scores.item.weight ||
                      format2Decimals(scores.derived.weight)) + '%'
                  }
                  validationMessage={validations['weight']}
                >
                  <Input
                    disabled={disabled}
                    name="weight"
                    aria-label={intl.formatMessage({
                      id: 'app.views.objectives.objective_indicator.weight.input.aria_label',
                      defaultMessage: 'Weight',
                    })}
                    type="number"
                    min={0}
                    max={100}
                    bsSize="sm"
                    value={scores.item.weight ?? ''}
                    placeholder={format2Decimals(scores.derived.weight)}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </Col>
              {!disabled && (
                <Col className="col-auto ps-1 mt-2 pb-0 mb-0">
                  <span className="fs-5 text-muted">
                    {' '}
                    <FormattedMessage
                      id="app.views.objectives.objective_indicator.weight.percentage_symbol"
                      defaultMessage="%"
                    />
                  </span>
                </Col>
              )}
            </Row>
          </div>
          <div className={commonSectionClassName}>
            <h5>
              <FormattedMessage
                id="app.views.objectives.objective_indicator.auto_calculation.header"
                defaultMessage="Auto-calculation"
              />
              <>
                <i
                  className="ms-2 fe fe-help-circle text-primary small"
                  ref={autoCalculationHelperRef}
                />
                <UncontrolledPopover
                  placement="top"
                  trigger="hover"
                  target={autoCalculationHelperRef}
                >
                  <FormattedMessage
                    id="app.views.objectives.objective_indicator.auto_calculation.popover"
                    defaultMessage="If this is checked, the percentage of completion will be auto-calculated based on the values of the subobjectives, unless the scores are overridden."
                  />
                </UncontrolledPopover>
              </>
            </h5>
            <Row className="g-0">
              <Col className="col-2">
                <InputWrapper
                  name="auto_calculation"
                  disabled={disabled}
                  disabledValue={
                    objective.auto_calculation
                      ? intl.formatMessage({
                          id: 'app.views.objectives.objective_indicator.auto_calculation.input.enabled',
                          defaultMessage: 'Enabled',
                        })
                      : intl.formatMessage({
                          id: 'app.views.objectives.objective_indicator.auto_calculation.input.disabled',
                          defaultMessage: 'Disabled',
                        })
                  }
                  validationMessage={validations['auto_calculation']}
                >
                  <SwitchInput
                    ariaLabel={intl.formatMessage({
                      id: 'app.views.objectives.objective_indicator.auto_calculation.input.switch_label',
                      defaultMessage: 'Auto-calculation',
                    })}
                    name="auto_calculation"
                    value={objective.auto_calculation}
                    checkedValue={true}
                    uncheckedValue={false}
                    onChange={(value) => {
                      handleChange({
                        target: {
                          name: 'auto_calculation',
                          value: value,
                        },
                      });
                    }}
                  />
                </InputWrapper>
              </Col>
            </Row>
          </div>
          <div className={commonSectionClassName}>
            <h5>
              <FormattedMessage
                id="app.views.objectives.objective_indicator.notes.header"
                defaultMessage="Notes"
              />
            </h5>
            <Row style={{ maxWidth: '21.5rem' }}>
              <Col
                className={
                  objective?.score_comments_author_person ? 'col-10 pe-1' : ''
                }
              >
                {disabled ? (
                  score_comments ? (
                    <div className="comment-body w-100 p-1 ps-2 ">
                      {score_comments}
                    </div>
                  ) : (
                    '-'
                  )
                ) : (
                  <Input
                    disabled={disabled}
                    name="score_comments"
                    aria-label={intl.formatMessage({
                      id: 'app.views.objectives.objective_indicator.notes.input.aria_label',
                      defaultMessage: 'Notes',
                    })}
                    type="textarea"
                    bsSize="sm"
                    className="me-0 pe-0"
                    value={score_comments}
                    onChange={(e) =>
                      handleChanges([
                        e,
                        {
                          target: {
                            name: 'score_comments_author_person',
                            value: e.target.value ? me : null,
                          },
                        },
                      ])
                    }
                  />
                )}
              </Col>
              {objective?.score_comments_author_person && (
                <Col className="col-2 ms-0 ps-0 d-flex align-items-end">
                  <Avatar
                    className="ms-1 mb-1 ps-0"
                    size="xs"
                    person={objective.score_comments_author_person}
                  />
                </Col>
              )}
            </Row>
          </div>
          <div className={commonSectionClassName}>
            <RevealLink
              text={intl.formatMessage({
                id: 'app.views.objectives.objective_indicator.history.reveal_history.link.text',
                defaultMessage: 'Show history',
              })}
            >
              <h5>
                <FormattedMessage
                  id="app.views.objectives.objective_indicator.history.header"
                  defaultMessage="History"
                />
              </h5>
              <ObjectiveHistory objectiveKey={objective.key} />
            </RevealLink>
          </div>
        </div>
      </UncontrolledPopover>
    </>
  );
};

export default ObjectiveIndicator;
