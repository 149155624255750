import { Card, CardBody } from 'reactstrap';
import React, { FC, useEffect } from 'react';

import PeopleNav from '../Layout/PeopleNav';
import { useHistory } from 'react-router-dom';

interface Props {
  inMyProfile?: boolean;
}

const OneOnOnesDirectory: FC<Props> = ({ inMyProfile = false }) => {
  const history = useHistory();

  useEffect(() => {
    // if this is your own page but it has a ?with={person id} query param, then
    // redirect to the right place, namely the other person's page, i.e. swap
    // the ?with={person id} query param with the /p/{person id} param
    if (inMyProfile) {
      // get the id of the "with" param if provided and redirect
      // to the with person's one-on-ones page
      const url = new URL(window.location.href);
      const withPersonId = url.searchParams.get('with');
      if (withPersonId) {
        // redirect to the with person's one-on-ones page
        history.push(`/p/${withPersonId}/one-on-ones`);
      }
    }
  }, [history, inMyProfile]);

  return (
    <Card>
      <CardBody>
        <PeopleNav showSelf={false} />
      </CardBody>
    </Card>
  );
};

export default React.memo(OneOnOnesDirectory);
