import { Card, CardBody, Col, Row } from 'reactstrap';

import React from 'react';

type StatProperty = 'average' | 'median' | 'max' | 'total';
type StatData = { label: string; value: number };

type DistributionStatsCardProps = {
  organizationName: string;
  stats: Record<StatProperty, StatData>;
  description: string;
};

const DistributionStatsCard: React.FC<DistributionStatsCardProps> = ({
  stats,
  description,
}) => (
  <Card>
    <CardBody>
      <Row className="mb-4">
        {Object.values(stats).map((stat) => {
          return (
            <Col key={stat.label}>
              <h6 className="text-uppercase text-muted mb-3">{stat.label}</h6>
              <span className="h2 pb-1">{stat.value}</span>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col>{description}</Col>
      </Row>
    </CardBody>
  </Card>
);

export default DistributionStatsCard;
