import { debounce, isEqual } from 'lodash';
import {
  getFriendlyUserFacingErrorObjectAndMessage,
  renderErrorOrCallback,
} from '../../../utils/util/util';
import { useCallback, useEffect, useRef, useState } from 'react';

import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import { ReduxState } from '../../../types';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useEventsSerializer } from '../../../utils/util/hooks';
import { useSelector } from 'react-redux';

interface AutosaveForm {
  key: string;
  data: unknown;
  schema: unknown;
}

interface ValidatedFormInput {
  name: string;
  type: string;
}

interface InputSchema {
  [key: string]: {
    type: string;
  };
}

type HookState = 'LOADING' | 'SUCCESS' | 'ERROR' | 'DISABLED';

const ENDPOINT = '/autosaveform';
const AUTOSAVE_DEBOUNCE_MS = 500;

const inputsToSchema = (
  inputs: ValidatedFormInput[]
): InputSchema | undefined => {
  if (!inputs) return undefined;
  return inputs.reduce(
    (partialSchema, input) => ({
      ...partialSchema,
      [input.name]: { type: input.type },
    }),
    {}
  );
};

const filterValidData = (
  data: object,
  schema: InputSchema,
  acceptedSchema: InputSchema
) => {
  const filteredData = Object.entries(data).reduce((object, [key, value]) => {
    if (acceptedSchema[key] && isEqual(schema[key], acceptedSchema[key])) {
      return { ...object, [key]: value };
    }
    return object;
  }, {});
  return filteredData;
};

type AutosaveParams = {
  key: string;
  enabled: boolean;
  inputs: ValidatedFormInput[];
  submittedObject: object;
  extraKeysToCleanupOnDeleteRemote?: string[];
};

type AutosaveResponse = {
  latestObjectFetched: unknown;
  state: HookState;
  stash: (item: unknown, ...rest: any) => void;
  deleteRemote: (callback?: () => void) => void;
  hasPendingChanges: boolean;
  isChangedFromSubmitted: boolean;
  discard: () => void;
};

export const useAutosave = ({
  key,
  enabled = false,
  inputs,
  submittedObject,
  extraKeysToCleanupOnDeleteRemote = [],
}: AutosaveParams): AutosaveResponse => {
  const currentProxyPersonEmail = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );
  const currentOrganizationId = useSelector<ReduxState, number | undefined>(
    (state) => state.currentOrganization?.id
  );
  const [state, setState] = useState<HookState>(() =>
    enabled ? 'LOADING' : 'DISABLED'
  );
  const [latestObjectFetched, setLatestObjectFetched] =
    useState<unknown>(submittedObject);
  const [schema, setSchema] = useState<InputSchema | undefined>(() =>
    inputsToSchema(inputs)
  );
  const [isChangedFromSubmitted, setIsChangedFromSubmitted] = useState(false);
  const previousSubmittedObject = useRef<object>(submittedObject);

  useEffect(() => {
    setSchema(inputsToSchema(inputs));
  }, [inputs]);

  const onSubmit = useCallback(
    (form: AutosaveForm, onSuccess: () => void, onError: () => void) => {
      ConfirmAPI.sendRequestToConfirm(
        'POST',
        ENDPOINT,
        {
          ...form,
          proxy: currentProxyPersonEmail,
          organization_id: currentOrganizationId,
        },
        renderErrorOrCallback(onSuccess, onError)
      );
    },
    [currentProxyPersonEmail, currentOrganizationId]
  );

  const successCallback = useCallback((...args) => {
    console.log(
      'success saving autosave form with args:',
      JSON.stringify(args)
    );
  }, []);

  const errorCallback = useCallback((message, errorItem) => {
    if ((errorItem?.retryAttempt ?? 0) > 2) {
      console.error(
        'failure saving autosave form with args:',
        JSON.stringify({ message, errorItem })
      );
      return;
    }
    console.warn(
      'failure saving autosave form with args:',
      JSON.stringify({ message, errorItem })
    );
  }, []);

  const [addToBufferQueue, hasPendingChanges] = useEventsSerializer(
    // @ts-expect-error
    onSubmit,
    successCallback,
    errorCallback
  ) as [
    (newItemOrItems: any, preserveModifidedDate?: boolean) => void,
    boolean
  ];

  const addToBufferQueueDebounced = useRef(
    debounce(addToBufferQueue, AUTOSAVE_DEBOUNCE_MS)
  ).current;

  const stash = useCallback(
    (item, ...rest) => {
      if (state === 'SUCCESS') {
        setIsChangedFromSubmitted(true);
        addToBufferQueueDebounced({ schema, data: item, key }, ...rest);
      }
    },
    [addToBufferQueueDebounced, state, key, schema]
  );

  useDeepCompareEffectNoCheck(() => {
    if (!enabled || !schema) {
      return;
    }
    ConfirmAPI.sendRequestToConfirm(
      'GET',
      ENDPOINT,
      {
        proxy: currentProxyPersonEmail,
        organization_id: currentOrganizationId,
        key,
      },
      (response, error, hardErrorMessage) => {
        const [errorObject, friendlyErrorMessage] =
          getFriendlyUserFacingErrorObjectAndMessage(error, hardErrorMessage);

        if (errorObject && errorObject.status !== 404) {
          console.error(
            `Error loading Form with key ${key}`,
            errorObject,
            friendlyErrorMessage
          );
          setState('ERROR');
          return;
        }

        if (response) {
          setIsChangedFromSubmitted(true);
          setLatestObjectFetched({
            ...previousSubmittedObject.current,
            ...filterValidData(response.data, response.schema, schema),
          });
          console.log(`Form with key ${key} restored`);
        }
        setState('SUCCESS');
      }
    );
  }, [currentProxyPersonEmail, currentOrganizationId, enabled, key, schema]);

  const deleteRemote = useCallback(
    (callback?: () => void) => {
      if (enabled) {
        ConfirmAPI.sendRequestToConfirm(
          'DELETE',
          ENDPOINT,
          {
            proxy: currentProxyPersonEmail,
            organization_id: currentOrganizationId,
            keys: [
              key,
              ...(isChangedFromSubmitted
                ? extraKeysToCleanupOnDeleteRemote
                : []),
            ].join(','),
          },
          () => {
            console.log(`Form with key ${key} removed`);
            callback?.();
          }
        );
      }
    },
    [
      currentOrganizationId,
      currentProxyPersonEmail,
      enabled,
      key,
      extraKeysToCleanupOnDeleteRemote,
      isChangedFromSubmitted,
    ]
  );

  const discard = useCallback(() => {
    deleteRemote();
    setLatestObjectFetched({
      ...previousSubmittedObject.current,
      discard_id: Math.random(), // force re-render
    });
    setIsChangedFromSubmitted(false);
  }, [deleteRemote]);

  return {
    latestObjectFetched,
    state,
    stash,
    discard,
    hasPendingChanges,
    isChangedFromSubmitted,
    deleteRemote,
  };
};
