import * as consts from '../../consts/consts';

import { Activity, Contribution, Features } from '../../types';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  contributionIsEmpty,
  getContributionPerson,
  getContributorRole,
} from '../../utils/models/Activity';

import Avatar from '../Widgets/People/Avatar';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import ContributionCommentBody from './ContributionCommentBody';
import EmojiBar from '../Widgets/EmojiBar';
// @ts-expect-error
import { IntercomAPI } from '../../vendor/react-intercom';
import { Link } from 'react-router-dom';
import ModalContributionEditor from './ModalContributionEditor';
import ModalFeedbackEditorButton from '../Feedback/ModalFeedbackEditorButton';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import TagsList from '../Widgets/TagsList';
import { atLeastOneContinuousFeedbackFeatureIsEnabled } from '../../utils/util/features';
import { connect } from 'react-redux';
import { isEnabled } from '../../utils/util/util';
import { peopleIdsAreEqual } from '../../utils/models/Person';

interface Props {
  activity: Activity;
  callback?: (c: Contribution) => void;
  className?: string;
  contributionIndex: number;
  contributionsWithRecommendations?: Array<object>;
  features?: Features;
  feedbackModalIsOpen?: boolean;
  isExternalUrl?: boolean;
  isOpen?: boolean;
  meId: number;
  onClosed?: () => void;
  onDeleteContribution?: (c: Contribution) => void;
  onSubmitActivity?: (a: Activity) => void;
  preventInteraction?: boolean;
  preventLocking?: boolean;
}

const ContributionCard: FC<Props> = ({
  activity,
  callback,
  className,
  contributionIndex,
  contributionsWithRecommendations,
  features,
  feedbackModalIsOpen,
  isExternalUrl = false,
  isOpen,
  meId,
  onClosed,
  onDeleteContribution,
  onSubmitActivity = () => {
    // do nothing
  },
  preventInteraction = false,
  preventLocking,
}: Props) => {
  const intl = useIntl();
  const [editModal, setEditModal] = useState<boolean>(!!isOpen);
  const toggleEditModal = () => setEditModal(!editModal);
  const [editFeedbackModal, setEditFeedbackModal] = useState<boolean>(
    !!feedbackModalIsOpen
  );

  // only allow for feedback modal if org has it enabled
  const hasAnyFeedbackorRecognitionFeatureEnabled = useMemo(
    () => atLeastOneContinuousFeedbackFeatureIsEnabled(features),
    [features]
  );

  const toggleEditFeedbackModal = useMemo(
    () =>
      hasAnyFeedbackorRecognitionFeatureEnabled
        ? () => setEditFeedbackModal(!editFeedbackModal)
        : undefined,
    [editFeedbackModal, hasAnyFeedbackorRecognitionFeatureEnabled]
  );
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setConfirmDeleteModal(!confirmDeleteModal);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);

  const contribution =
    contributionIndex >= 0
      ? activity.contributions[contributionIndex]
      : activity.contributions.find((c) =>
          peopleIdsAreEqual(getContributionPerson(c).id, meId)
        );

  const propsOnDeleteContribution = onDeleteContribution;
  const propsCallback = callback;
  const propsOnSubmitActivity = onSubmitActivity;

  useEffect(() => {
    if (typeof isOpen !== 'undefined') {
      setEditModal(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (typeof feedbackModalIsOpen !== 'undefined') {
      setEditFeedbackModal(feedbackModalIsOpen);
    }
  }, [feedbackModalIsOpen]);

  const contributorPerson = useMemo(
    () => getContributionPerson(contribution),
    [contribution]
  );

  const confirmDelete = useCallback(() => {
    if (!contribution) {
      console.error('Cannot delete contribution that does not exist.');
      return;
    }

    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      '/contributions/' + contribution.id,
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            setDeleteValidationErrors(hardErrorMessage);
          } else {
            setDeleteValidationErrors(error);
          }
        } else {
          setConfirmDeleteModal(false);
          if (propsOnDeleteContribution) {
            propsOnDeleteContribution(contribution);
          }
        }
      },
      null
    );
  }, [contribution, propsOnDeleteContribution]);

  const isMyContribution = peopleIdsAreEqual(
    contribution?.contributor_person?.id,
    meId
  );
  const needsDescription = !contribution?.description;

  const needsAnotherPersonsContribution = needsDescription && !isMyContribution;
  const needsMyContribution = needsDescription && isMyContribution;

  const onDeleteMyContributionComment = useCallback(
    (comment) => {
      if (typeof propsCallback !== 'undefined') {
        if (!contribution) {
          console.error('Cannot delete contribution that does not exist.');
          return;
        }

        propsCallback({
          ...contribution,
          contribution_comments: contribution.contribution_comments.filter(
            (c) => c.id !== comment.id
          ),
        } as Contribution);
      } else {
        // TODO: This was surfaced by typechecking the code, either
        // propsCallback can be undefined so this if is ok-ish, or not
        // thus in canEdit computation (and others) we can remove it from
        // the boolean check (a function is always truthy).
        console.error('undefined propsCallback in ContributionCard.');
      }
    },
    [contribution, propsCallback]
  );

  const contributorNotYetProvidedDetails = contributionIsEmpty(
    contribution,
    false
  );

  const contributorRole = useMemo(
    () => getContributorRole(activity, contributorPerson),
    [activity, contributorPerson]
  );

  if (!contribution) {
    console.error('Contribution not found.');
    return null;
  }

  const canEdit =
    !preventInteraction && propsCallback && isMyContribution && contribution.id;

  const ContributionCardBody = (
    <div>
      {propsCallback && (
        <ModalContributionEditor
          isOpen={editModal}
          onClosed={onClosed}
          toggle={toggleEditModal}
          anchorTrigger={isMyContribution ? 'accept-credit' : undefined}
          activity={activity}
          contributionIndex={contributionIndex}
          contributionsWithRecommendations={contributionsWithRecommendations}
          onSubmitActivity={propsOnSubmitActivity}
          callback={propsCallback}
          hideFeedbackRequestPrompt={
            !isEnabled(
              features,
              consts.FLAGS.ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS
            )
          }
        />
      )}
      {hasAnyFeedbackorRecognitionFeatureEnabled && propsCallback && (
        <ModalFeedbackEditorButton
          isOpen={editFeedbackModal}
          onClosed={() => setEditFeedbackModal(false)}
          hideButton={true}
          feedback={{
            subject_people: [contributorPerson],
            activities: [activity],
          }}
        />
      )}
      <div
        className={
          'comment-body d-block px-3' +
          (contributorNotYetProvidedDetails ? ' mb-3 bg-white' : '')
        }
        style={
          contributorNotYetProvidedDetails
            ? {
                border: '1px dashed #d2ddec',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }
            : { paddingTop: '0.5rem', paddingBottom: '0.5rem' }
        }
      >
        <Row>
          <Col>
            <h4 className="mb-1 mt-1 text-dark small fw-bold">
              {contributorPerson?.id && (
                <Link
                  to={contributorPerson.url}
                  target={isExternalUrl ? '_blank' : undefined}
                  rel="noopener noreferrer"
                >
                  {contributorPerson.full_name}
                </Link>
              )}
              {contributorRole && (
                <span className="fw-normal">
                  <FormattedMessage
                    id="util.list_comma"
                    defaultMessage=","
                    description="comma between item in lists"
                  />{' '}
                  {contributorRole}
                </span>
              )}
            </h4>
            {(needsAnotherPersonsContribution || needsMyContribution) && (
              <>
                <div className="text-muted fst-italic">
                  {needsMyContribution ? (
                    <FormattedMessage
                      id="app.views.activities.contribution_card.have_not_added_contribution_details.you"
                      defaultMessage="You have not added contribution details."
                    />
                  ) : (
                    <FormattedMessage
                      id="app.views.activities.contribution_card.have_not_added_contribution_details.other"
                      defaultMessage="{contributor} has not added contribution details."
                      values={{ contributor: contributorPerson.given_name }}
                    />
                  )}
                </div>
                {!preventInteraction && needsMyContribution && (
                  <Button
                    className="btn btn-sm my-2"
                    color="primary"
                    onClick={toggleEditModal}
                  >
                    <FormattedMessage
                      id="app.views.activities.contribution_card.add_contribution_details"
                      defaultMessage="Add contribution details"
                    />
                  </Button>
                )}
              </>
            )}
            {contribution?.description && (
              <div className="mb-0 py-1">
                <RichTextViewer
                  model={contribution.description}
                  expanded={true}
                />
              </div>
            )}
            {contribution.skills.length > 0 && (
              <TagsList
                skills={contribution.skills}
                isExternalUrl={isExternalUrl}
                truncated={true}
              />
            )}
          </Col>
          {!preventInteraction && (
            <Col className="col-auto ps-0 pe-2 mb-n1" style={{ marginTop: 2 }}>
              {canEdit && (
                <ConfirmationDialogModal
                  isOpen={confirmDeleteModal}
                  onClosed={() => setDeleteValidationErrors(null)}
                  toggle={toggleConfirmDeleteModal}
                  confirmCallback={confirmDelete}
                  title={intl.formatMessage({
                    id: 'app.views.activities.contribution_card.delete_modal.title',
                    defaultMessage: 'Delete contribution?',
                  })}
                  description={intl.formatMessage({
                    id: 'app.views.activities.contribution_card.delete_modal.description',
                    defaultMessage:
                      'Are you sure that you want to delete this contribution?',
                  })}
                  confirmText={intl.formatMessage({
                    id: 'app.views.activities.contribution_card.delete_modal.confirmText',
                    defaultMessage: 'Delete Contribution',
                  })}
                  validationErrors={deleteValidationErrors}
                />
              )}
              <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle
                  tag="button"
                  className="more-dropdown-button btn btn-sm"
                  role="button"
                >
                  <i className="fe fe-more-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu end>
                  {canEdit && (
                    <DropdownItem onClick={toggleEditModal}>
                      <FormattedMessage
                        id="app.views.activities.contribution_card.options.edit"
                        defaultMessage="Edit contribution"
                      />
                    </DropdownItem>
                  )}
                  {canEdit && (
                    <DropdownItem onClick={toggleConfirmDeleteModal}>
                      <FormattedMessage
                        id="app.views.activities.contribution_card.options.delete"
                        defaultMessage="Delete contribution"
                      />
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => IntercomAPI('show')}>
                    <FormattedMessage
                      id="app.views.activities.contribution_card.options.report"
                      defaultMessage="Report a problem"
                    />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          )}
        </Row>
      </div>
      {!preventInteraction && !contributorNotYetProvidedDetails && (
        <EmojiBar
          isSmall={true}
          className="ms-3"
          objectId={contribution?.id?.toString()}
          contentType="contribution"
          reactions={contribution?.reactions}
          showCommentModal={toggleEditModal}
          showFeedbackModal={toggleEditFeedbackModal}
        />
      )}
      {contribution?.contribution_comments?.length > 0 && (
        <Row>
          <Col>
            <div>
              {contribution.contribution_comments.map((comment) => {
                const authorIsMe = peopleIdsAreEqual(
                  comment.author_person?.id,
                  meId
                );
                const onEditComment = authorIsMe ? toggleEditModal : undefined;
                const onDeleteComment = authorIsMe
                  ? onDeleteMyContributionComment
                  : undefined;

                const author = comment.author_person;

                return (
                  <ContributionCommentBody
                    key={comment.id}
                    comment={comment}
                    lockedMessage={
                      !preventLocking && needsMyContribution ? (
                        <FormattedMessage
                          id="app.views.activities.contribution_card.contribution_comment.needs_my_contribution"
                          defaultMessage="Add your contribution details to see what {author} said."
                          values={{ author: author.given_name }}
                        />
                      ) : undefined
                    }
                    lockedMessageOnClick={toggleEditModal}
                    authorRole={getContributorRole(activity, author)}
                    onEdit={onEditComment}
                    onDelete={onDeleteComment}
                    preventInteraction={preventInteraction}
                    isExternalUrl={isExternalUrl}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );

  return (
    <Row className={(className ? className + ' ' : '') + 'flex-nowrap'}>
      <Col className="col-auto">
        <Avatar className="avatar-sm mt-2" person={contributorPerson} linked />
      </Col>
      <Col className="ms-n3">{ContributionCardBody}</Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const { features, me } = state;

  return {
    features,
    meId: me?.id,
  };
};

export default connect(mapStateToProps)(React.memo(ContributionCard));
