import { FormattedMessage } from 'react-intl';
import { INPUT_TYPES } from 'views/Widgets/Inputs/ValidatedInputTypes';
import React from 'react';

interface CustomizationReponse {
  customInfoSection: object[];
  requireSkillsAndActivities: boolean;
  disabledFields: string[];
}

export const customizationsForSpecificFeedbackType = ({
  attachedObjectType,
  feedback,
}: {
  attachedObjectType?: string;
  feedback?: object;
}): CustomizationReponse => {
  // Customization for packet
  if (attachedObjectType === 'packet') {
    // Skills are not required for packets
    return customizeForPackets(feedback);
  }

  return {
    customInfoSection: [],
    requireSkillsAndActivities: true,
    disabledFields: [],
  };
};

const customizeForPackets = (
  feedback: object | undefined
): CustomizationReponse => {
  // Skill and activities are not shown
  const requireSkillsAndActivities = false;

  // These Fields are disabled
  const disabledFields = [
    'subject_people',
    'feedback_visibility',
    'visibility',
  ];

  // Do not display the custom info section if it's not a packet request
  // @ts-expect-error
  if (feedback?.type?.id !== 'R') {
    return {
      disabledFields,
      requireSkillsAndActivities,
      customInfoSection: [
        {
          type: INPUT_TYPES.CUSTOM_INPUT,
          name: 'custom_info_text',
          component: (
            <FormattedMessage
              id="app.feedback.modal_feedback_editor_button.custom_info_text.promotion_packet"
              defaultMessage="This feedback will be attached to <b>{full_name}</b>'s promotion packet."
              values={{
                b: (chunks) => <b>{chunks}</b>,
                // @ts-expect-error
                full_name: feedback?.subject_people?.[0]?.full_name,
              }}
            />
          ),
        },
      ],
    };
  }
  return { requireSkillsAndActivities, disabledFields, customInfoSection: [] };
};

// This render the custom skill instead for a custom attached object
export const customizableSkillAndActivityIndicator = ({
  attachedObjectType,
}: {
  attachedObjectType?: string;
  r?: object;
}): JSX.Element | undefined => {
  return attachedObjectType === 'packet' ? (
    <FormattedMessage
      id="app.views.promotion_packets.promotion_packet_feedback.promo_packet_feedback"
      defaultMessage="Promotion Packet"
      values={{
        b: (chunks) => <b>{chunks}</b>,
      }}
    >
      {(txt) => (
        <span className="badge rounded-pill me-2 bg-primary-soft">{txt}</span>
      )}
    </FormattedMessage>
  ) : undefined;
};

// This render the custom skill instead for a custom attached object
export const customizableSkillAndActivityIndicatorOnCard = ({
  attachedObjectType,
  subjectPeople,
}: {
  attachedObjectType?: string;
  subjectPeople?: object[];
}): JSX.Element | undefined => {
  return attachedObjectType === 'packet' ? (
    <FormattedMessage
      id="app.views.feedback.feedback_card.action.promo_packet_feedback_for"
      defaultMessage="<b>{personName}</b>'s promotion packet"
      values={{
        b: (chunks) => <b>{chunks}</b>,
        // @ts-expect-error
        personName: subjectPeople?.[0]?.full_name,
      }}
    />
  ) : undefined;
};
