// Component used to "fake" an IdP Initiated Login Flow
// for OIDC protocol (i.e. Auth0 Okta Worforce Enterprise Connection).
// Must have a `?connection=` querystring matching an Auth0 Enterprise
// Connection name.
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { redirectLoginOptionsGenerator } from '../../utils/util/utiltsx';

const IdPInitiatedFlow: React.FC = () => {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
  const location = useLocation();

  const redirectToAuthorize = (): Promise<void> => {
    const connection = new URLSearchParams(location.search).get('connection');
    const options = redirectLoginOptionsGenerator('/');

    if (connection) {
      // Pin to this connection.
      return loginWithRedirect({
        ...options,
        ...{ connection: connection },
      });
    }

    // Otherwise normal flow.
    return loginWithRedirect(options);
  };

  if (isLoading) {
    // This waits for Auth0 to complete the checks
    // to correctly value isAuthenticated.
    return <div>{'Logging in ...'}</div>;
  }

  if (isAuthenticated) {
    // We're already logged in.
    window.location.href = '/';
    return <span></span>;
  }

  // Not logged in for sure, redirect to Auth0 Enterprise
  // Connection.
  redirectToAuthorize().then(() => {});
  return <span></span>;
};

export default IdPInitiatedFlow;
