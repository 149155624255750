import {
  ONE_HOUR_IN_SECONDS,
  SESSION_FORCE_RELOGIN,
} from '../../../consts/consts';
import React, { FC } from 'react';

import ConfirmationDialogModal from './ConfirmationDialogModal';
import PropTypes from 'prop-types';
import { setHasSeenSelectAccountPrompt } from '../../../utils/models/User';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

const ForcedAuthModal: FC<Props> = (props) => {
  const { logout } = useAuth0();
  const { formatMessage } = useIntl();

  const confirmCallback = () => {
    const handle = window.open(SESSION_FORCE_RELOGIN.path);
    if (!handle) {
      // The window wasn't allowed to open. This is likely caused by
      // built-in popup blockers. Logging in here.
      setHasSeenSelectAccountPrompt(false);
      logout({
        // @ts-expect-error
        returnTo: props.returnTo,
        localOnly: true,
      });
    } else {
      // TODO: Probably we need to poll handle to check if it's still open, if it's not
      // and the modal hasn't been closed we need to cleanup.
    }

    return true;
  };

  const ttlInHours = props.ttl
    ? Math.floor(props.ttl / ONE_HOUR_IN_SECONDS)
    : undefined;

  const description =
    "Your organization's IT team has chosen to limit session time on Confirm" +
    (ttlInHours
      ? ' to ' + ttlInHours + ' hour' + (ttlInHours !== 1 ? 's' : '')
      : '') +
    '. You will need to log in again to continue.';

  return (
    <ConfirmationDialogModal
      title={formatMessage({
        id: 'app.views.widgets.modals.forced_auth_modal.title.your_session_has_expired',
        defaultMessage: 'Your session has expired.',
      })}
      description={description}
      isOpen={props.isOpen}
      confirmText={formatMessage({
        id: 'app.views.widgets.modals.forced_auth_modal.confirm_text.log_in',
        defaultMessage: 'Log in',
      })}
      confirmationButtonColor="primary"
      cancelText="Cancel"
      confirmCallback={confirmCallback}
      toggle={props.toggle}
      // For some strange reasons reactstrap exposes css for zIndex
      // so using it to ensure this modal is on top of every other
      // https://reactstrap.github.io/?path=/docs/components-modal--modal
      // https://getbootstrap.com/docs/5.2/layout/z-index/
      zIndex={1091}
    />
  );
};

const ForcedAuthModal_propTypes = {
  isOpen: PropTypes.bool.isRequired,
  returnTo: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  ttl: PropTypes.number,
};

type Props = PropTypes.InferProps<typeof ForcedAuthModal_propTypes>;

export default React.memo(ForcedAuthModal);
