import * as consts from '../../consts/consts';

import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import WelcomePage from './WelcomePage';
import { connect } from 'react-redux';
import welcome1 from '../../assets/img/illustrations/welcome1.png';
import welcome2 from '../../assets/img/illustrations/welcome2.png';
import welcome3 from '../../assets/img/illustrations/welcome3.png';

const WelcomeStep0: React.FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();
  const person = props.me;
  const history = useHistory();
  const location = useLocation();

  const onClick = () => {
    return history.push(
      consts.WELCOME_STEP_1(formatMessage).path + location.search
    );
  };

  return (
    <WelcomePage
      title={formatMessage(
        {
          id: 'app.views.welcome.welcome_step0.title.welcome_to_confirm',
          defaultMessage: 'Welcome to Confirm, {personName}!',
        },
        // @ts-expect-error
        { personName: person.given_name }
      )}
      onClick={onClick}
      nextButtonText={formatMessage({
        id: 'app.views.welcome.welcome_step0.next_button_text.continue',
        defaultMessage: 'Continue',
      })}
    >
      <Row className="justify-content-center align-items-center">
        <Col className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <p className="fs-lg">
            <FormattedMessage
              id="app.views.welcome.welcome_step0.paragraph_1"
              defaultMessage="Confirm is the home for your work accomplishments, skills, and credentials."
            />
          </p>
        </Col>
        <Col className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <img className="w-100" src={welcome1} alt="" />
        </Col>
        <Col className="col-12 my-5">
          <hr />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <img className="w-100" src={welcome2} alt="" />
        </Col>
        <Col className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <p className="fs-lg">
            <FormattedMessage
              id="app.views.welcome.welcome_step0.paragraph_2"
              defaultMessage="
            Share your accomplishments, or add your contributions to the
            accomplishments of others.
          "
            />
          </p>
        </Col>
        <Col className="col-12 my-5">
          <hr />
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <p className="fs-lg">
            <FormattedMessage
              id="app.views.welcome.welcome_step0.paragraph_3"
              defaultMessage="
            Find people by their accomplishments, skills, credentials, and more.
          "
            />
          </p>
        </Col>
        <Col className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <img className="w-100" src={welcome3} alt="" />
        </Col>
      </Row>
    </WelcomePage>
  );
};

type Props = InferProps<typeof WelcomeStep0_propTypes>;

const WelcomeStep0_propTypes = {
  me: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { me } = state;

  return {
    me,
  };
};

export default connect(mapStateToProps)(React.memo(WelcomeStep0));
