import {
  ApiState,
  useConfirmApi,
  useConfirmApiWithDefault,
} from '../../../utils/api/ApiHooks';
import {
  Objective,
  OnFilterChange,
  ViewScope,
  parseObjectivesForDisplay,
} from '../../../utils/models/Objective';
import { useCallback, useEffect, useRef, useState } from 'react';

import { IncludeExcludeFilterType } from '../../Widgets/Inputs/IncludeExcludeFilter';
import { getFriendlyUserFacingErrorObjectAndMessage } from '../../../utils/util/util';
import { toast } from 'react-toastify';
import { useObjectivesTimeFrameSelector } from '../ObjectivesTimeFrameSelector';

type ApiObjectivesOverviewResponse = {
  results: Objective[];
  available_scopes: ViewScope[];
  has_more_entries: boolean;
};

export const useObjectivesOverview = ({ availableScopes }) => {
  const [viewScope, setViewScope] = useState<ViewScope | undefined>();
  const [viewStatus, setViewStatus] = useState<string[]>([]);
  const [isLoadingMoreItems, setIsLoadingMoreItems] = useState<boolean>(false);
  const [objectivesToExclude, setObjectivesToExclude] = useState<string[]>([]);
  const [allObjectives, setAllObjectives] = useState<Objective[]>([]);
  const [viewPeopleFilters, setViewPeopleFilters] =
    useState<IncludeExcludeFilterType>(() => ({ include: [], exclude: [] }));
  const [viewTextFilter, setViewTextFilter] = useState<string>('');
  const [hasMoreEntries, setHasMoreEntries] = useState<boolean>(false);
  const [status, setStatus] = useState<ApiState>(() => 'LOADING');
  const [isTaskInProgress, setIsTaskInProgress] = useState<boolean>(false);
  const defaultScope: ViewScope | undefined = availableScopes?.[0];
  const loadMoreRef = useRef(null);

  const cleanupData = useCallback(() => {
    setStatus('LOADING');
    setIsLoadingMoreItems(false);
    setAllObjectives([]);
    setObjectivesToExclude([]);
  }, []);

  // Quarter selector
  const {
    currentTimeframeText,
    viewPreviousTimeframe,
    viewNextTimeframe,
    firstDay,
    lastDay,
  } = useObjectivesTimeFrameSelector({});
  const handleViewPreviousTimeframe = useCallback(() => {
    viewPreviousTimeframe();
    cleanupData();
  }, [cleanupData, viewPreviousTimeframe]);

  const handleViewNextTimeframe = useCallback(() => {
    viewNextTimeframe();
    cleanupData();
  }, [cleanupData, viewNextTimeframe]);

  // Other filters (scope, status)
  const handleFilterChange = useCallback(
    ({ scope, status, peopleFilters, textFilter }: OnFilterChange) => {
      if (scope) setViewScope(scope);
      if (status) setViewStatus(status);
      if (peopleFilters) setViewPeopleFilters(peopleFilters);
      if (textFilter != null) setViewTextFilter(textFilter);
      cleanupData();
    },
    [cleanupData]
  );

  const handleGenerateCSV = useCallback(() => {
    setStatus('DISABLED');
    setIsTaskInProgress(true);
  }, []);

  const handleGenerateCSVCompleted = useCallback(() => {
    setStatus('SUCCESS');
    setIsTaskInProgress(false);
  }, []);

  // transform data before presenting it to the user
  const postProcess = useCallback((data) => {
    return {
      ...data,
      results: data.results.map((objective) =>
        parseObjectivesForDisplay(objective)
      ),
    };
  }, []);

  // In-page load more handler
  const handleLoadMore = useCallback(() => {
    setIsLoadingMoreItems(true);
    const objectiveToExclude = allObjectives.map((o) => o.key);
    setObjectivesToExclude(objectiveToExclude);
  }, [allObjectives]);

  // Infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMoreEntries) {
        handleLoadMore();
      }
    });
    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
      return () => {
        observer.unobserve(currentRef);
      };
    }
  }, [handleLoadMore, hasMoreEntries]);

  useEffect(() => {
    if (isLoadingMoreItems && status === 'ERROR') {
      toast.error('Error loading more objectives');
      setIsLoadingMoreItems(false);
      setStatus('SUCCESS');
    }
  }, [isLoadingMoreItems, status]);

  useEffect(() => {
    if (defaultScope && !viewScope) {
      setViewScope(defaultScope);
    }
  }, [defaultScope, viewScope]);

  //   Api call
  const callback = useCallback(
    (data, error: unknown, hardErrorMessage: unknown) => {
      if (error || hardErrorMessage) {
        const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
          error,
          hardErrorMessage
        );
        console.log(JSON.stringify(errorObject));
        setStatus('ERROR');
        return;
      }
      if (data) {
        setAllObjectives((objectives) => [...objectives, ...data.results]);
        setIsLoadingMoreItems(false);
        setHasMoreEntries(data.has_more_entries);
      }
      setStatus('SUCCESS');
    },
    []
  );

  const markMatchingObjectives =
    viewStatus.length > 0 ||
    viewPeopleFilters.include.length > 0 ||
    viewPeopleFilters.exclude.length > 0 ||
    viewTextFilter.length > 0;

  useConfirmApi<ApiObjectivesOverviewResponse>({
    method: 'POST',
    url: '/objectives/overview',
    params: {
      coverage_start_date: firstDay,
      coverage_end_date: lastDay,
      scope: viewScope || defaultScope,
      status: viewStatus,
      excluded_objective_keys: objectivesToExclude,
      people_filters: viewPeopleFilters,
      text_filter: viewTextFilter,
      mark_matching_objectives: markMatchingObjectives,
    },
    postProcess,
    callback,
    disabled: !defaultScope, // wait until the default scope is set
  });

  return {
    handleViewNextTimeframe,
    currentTimeframeText,
    handleViewPreviousTimeframe,
    isLoadingMoreItems,
    handleFilterChange,
    viewStatus,
    viewScope,
    viewPeopleFilters,
    viewTextFilter,
    availableScopes,
    allObjectives,
    loadMoreRef,
    handleGenerateCSV,
    handleGenerateCSVCompleted,
    isTaskInProgress,
    status,
    firstDay,
    lastDay,
  };
};

type AvailableScopesGetResponse = {
  available_scopes: ViewScope[];
};

export const useAvailableScopes = (): ViewScope[] => {
  const {
    data: { available_scopes: availableScopes },
  } = useConfirmApiWithDefault<AvailableScopesGetResponse>({
    method: 'GET',
    url: '/objectives/overview/scopes',
    defaultValue: { available_scopes: [] },
  });

  return availableScopes;
};
