import { useCallback, useEffect, useMemo, useState } from 'react';

import { ColumnDescriptor } from './types';
import { difference } from 'lodash';

const COLUMN_FIELDS_WITH_CORESPONDING_FIELD_IDS = new Set([
  'function_id',
  'business_unit_id',
  'department_id',
  'cost_center_id',
]);

const COLUMN_FIELDS_WITH_CORESPONDING_FIELD_DESCRIPTIONS = new Set(['level']);

// This method is used to filter out columns that have both id and description fields
// and display as default the preferred, leaving the other as an option to be selected
export const filterColumnsWithBothIdAndDescriptionFields = (
  columns: ColumnDescriptor[]
) =>
  columns.filter(
    (column) =>
      (COLUMN_FIELDS_WITH_CORESPONDING_FIELD_IDS.has(column.field) &&
        columns.some((c) => c.field === column.field.replace('_id$', ''))) ||
      (COLUMN_FIELDS_WITH_CORESPONDING_FIELD_DESCRIPTIONS.has(column.field) &&
        columns.some((c) => c.field === `${column.field}_id`))
  );

export const useFilterableTableColumnSelectorModal = ({
  columns,
  disabled = false,
}: {
  columns: ColumnDescriptor[];
  disabled?: boolean;
}) => {
  const calaculateVisibleColumns = useCallback(
    (
      hideColumnSelector: boolean,
      columnsForVisualDisplayUnfiltered: ColumnDescriptor[]
    ): string[] | undefined => {
      return hideColumnSelector
        ? undefined
        : columnsForVisualDisplayUnfiltered.map((c) => c.field);
    },
    []
  );

  const columnsForVisualDisplayUnfiltered = useMemo(
    () => columns.filter((c: ColumnDescriptor) => !c.csvOnly && !c.filterOnly),
    [columns]
  );

  const [visibleColumns, setVisibleColumns] = useState<string[] | undefined>(
    () => calaculateVisibleColumns(disabled, columnsForVisualDisplayUnfiltered)
  );

  const [unselectedColumnsMemory, setUnselectedColumnsMemory] = useState<
    string[]
  >(
    filterColumnsWithBothIdAndDescriptionFields(
      columnsForVisualDisplayUnfiltered
    ).map((column) => column.field)
  );

  const [showColumnSelector, setShowColumnSelector] = useState<boolean>(false);

  useEffect(() => {
    setVisibleColumns(
      calaculateVisibleColumns(disabled, columnsForVisualDisplayUnfiltered)
    );
  }, [disabled, columnsForVisualDisplayUnfiltered, calaculateVisibleColumns]);

  const columnsForVisualDisplay = useMemo(
    () =>
      columnsForVisualDisplayUnfiltered.filter(
        (c) =>
          (!visibleColumns || visibleColumns.includes(c.field)) &&
          !unselectedColumnsMemory.includes(c.field)
      ),
    [columnsForVisualDisplayUnfiltered, visibleColumns, unselectedColumnsMemory]
  );

  const handleToggleColumnSelector = useCallback(
    () => setShowColumnSelector(false),
    []
  );

  const handleOpenColumnSelector = useCallback(
    () => setShowColumnSelector(true),
    []
  );

  const handleCallbackColumnSelector = useCallback(
    (selectedColumns: string[], unselectedColumns: string[]) => {
      setUnselectedColumnsMemory(unselectedColumns);
      setVisibleColumns(difference(selectedColumns, unselectedColumns));
    },
    []
  );

  return {
    columnsForVisualDisplay,
    columnsForVisualDisplayUnfiltered,
    showColumnSelector,
    visibleColumns,
    handleOpenColumnSelector,
    handleToggleColumnSelector,
    handleCallbackColumnSelector,
  };
};
