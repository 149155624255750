import CardRowDashboard, { CARD_TYPES } from './CardRowDashboard';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, Fragment, useCallback, useMemo } from 'react';

import { Link } from 'react-router-dom';
import Loading from '../Loading';
import PageError from '../../Layout/Pages/Errors/PageError';
import { Progress } from 'reactstrap';
import SafeUncontrolledPopover from '../../../components/SafeUncontrolledPopover';
import { statusDescriptorFor } from '../../../utils/models/Objective';
import { toPercent } from '../../../utils/util/util';
import { v4 as uuidv4 } from 'uuid';

const NO_DATA_AVAILABLE = (formatMessage) =>
  formatMessage({
    id: 'app.views.widgets.dashboards.objectives_dashboard.no_data_available',
    defaultMessage: 'There is no data available for this time period.',
  });

const tableFields = (formatMessage) => [
  {
    name: formatMessage({
      id: 'app.views.widgets.dashboards.objectives_dashboard.table_fields.created',
      defaultMessage: 'Created',
    }),
    className: 'pe-1 text-end',
    defaultSort: 'descending',
    getValue: (_, datum) => `${datum}%`,
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.dashboards.objectives_dashboard.table_fields.scored',
      defaultMessage: 'Scored',
    }),
    className: 'pe-1 text-end',
    defaultSort: 'descending',
    getValue: (_, datum) => `${datum}%`,
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.dashboards.objectives_dashboard.table_fields.completion_rate',
      defaultMessage: '% complete',
    }),
    className: 'pe-1 text-center',
    compare: (aList, bList) => {
      const a = aList[0][1];
      const b = bList[0][1];
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    defaultSort: 'descending',
    getValue: (_, datum) => (
      <Progress multi className="progress-sm">
        {datum.map(([color, count, popover], index) => {
          const id = `progress-${uuidv4()}`;
          return (
            <Fragment key={index}>
              <Progress id={id} bar color={color} value={count} />
              <SafeUncontrolledPopover
                placement="top"
                trigger="hover"
                target={id}
              >
                {popover}
              </SafeUncontrolledPopover>
            </Fragment>
          );
        })}
      </Progress>
    ),
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.dashboards.objectives_dashboard.table_fields.status',
      defaultMessage: 'status',
    }),
    className: 'pe-1 text-center',
    compare: (aList, bList) => {
      const a = aList[0][1];
      const b = bList[0][1];
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    defaultSort: 'descending',
    getValue: (_, datum) => (
      <Progress multi className="progress-sm">
        {datum.map(([color, count, popover], index) => {
          const id = `status-${uuidv4()}`;
          return (
            <Fragment key={index}>
              <Progress
                id={id}
                bar
                style={{ background: color }}
                value={count}
              />
              <SafeUncontrolledPopover
                placement="top"
                trigger="hover"
                target={id}
              >
                {popover}
              </SafeUncontrolledPopover>
            </Fragment>
          );
        })}
      </Progress>
    ),
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.dashboards.objectives_dashboard.table_fields.score',
      defaultMessage: 'Score',
    }),
    className: 'text-end',
    defaultSort: 'descending',
    getValue: (_, datum) => {
      const color =
        datum >= 70 ? 'success' : datum >= 40 ? 'warning' : 'danger';
      return (
        <span className={`badge bg-${color}-soft text-${color}`}>
          {datum}
          {'%'}
        </span>
      );
    },
  },
];

const makeTableCardInfo = (
  facet,
  facetTitle,
  dataKey,
  title,
  formatMessage
) => {
  return {
    title: title,
    type: CARD_TYPES.GENERIC_TABLE,
    fields: [
      {
        className: 'text-start',
        compare: (a, b) => {
          if (typeof a === 'object') {
            if (a.given_name < b.given_name) return -1;
            if (a.given_name > b.given_name) return 1;
            if (a.family_name < b.family_name) return -1;
            if (a.family_name > b.family_name) return 1;
            return 0;
          }
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        },
        getValue: (name, datum) => {
          if (name === 'manager' || name === 'manager or above') {
            if (typeof datum !== 'object') {
              return formatMessage({
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_fields.other',
                defaultMessage: 'Other',
              });
            }

            const label = `${datum.given_name} ${datum.family_name}`;
            const link = `/p/${datum.id}/objectives`;
            return (
              <Link to={link} target="_blank" rel="noopener noreferrer">
                {label}
              </Link>
            );
          }

          return datum;
        },
        name: facet,
        title: facetTitle,
      },
      ...tableFields(formatMessage),
    ],
    dataKey,
    defaultSortBy: 0,
    emptyStateText: NO_DATA_AVAILABLE(formatMessage),
  };
};

type Props = {
  data?: Stats;
  errorMessage?: string;
};

type Stats = {
  [key: string]: {
    [key: string]: { [key: string]: StatBreakdown };
  };
};

type StatBreakdown = {
  not_created: number;
  created: number;
  scored: number;
  total: number;
  total_score: number;
  status_T: number;
  status_R: number;
  status_F: number;
  status_P: number;
  status_N: number;
};

const ObjectivesDashboard: FC<Props> = ({ data, errorMessage }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const toTableRow = useCallback(
    (
      facet,
      {
        not_created,
        created,
        scored,
        total,
        total_score,
        status_T,
        status_R,
        status_F,
        status_P,
        status_N,
      }: StatBreakdown
    ): [any, number, number, any[], any[], number] => {
      const createdPct = toPercent(created, total);
      const createdOrScoredPct = toPercent(created + scored, total);
      const notCreatedPct = 100 - createdOrScoredPct;
      const scoredPct = toPercent(scored, total);
      const statusTotal = status_T + status_R + status_F + status_P + status_N;
      return [
        facet,
        createdOrScoredPct,
        scoredPct,
        [
          [
            'success',
            scoredPct,
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.created_and_scored',
                defaultMessage:
                  '{count} {count, plural, one {person ({percentage}%) has} other {people ({percentage}%) have}} created and scored an objective',
              },
              { count: scored, percentage: toPercent(scored, total) }
            ),
          ],
          [
            'success-soft',
            createdPct,
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.created_not_scored',
                defaultMessage:
                  '{count} {count, plural, one {person ({percentage}%) has} other {people ({percentage}%) have}} created but not scored an objective',
              },
              {
                count: created,
                percentage: toPercent(created, total),
              }
            ),
          ],
          [
            'light',
            notCreatedPct,
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.not_created',
                defaultMessage:
                  '{count} {count, plural, one {person ({percentage}%) has} other {people ({percentage}%) have}} not created an objective',
              },
              { count: not_created, percentage: toPercent(not_created, total) }
            ),
          ],
        ],
        [
          [
            statusDescriptorFor('T', intl)?.colorHex,
            toPercent(status_T, statusTotal),
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.status_T',
                defaultMessage:
                  '{count} {count, plural, one {objective ({percentage}%) has} other {objectives ({percentage}%) have}} been marked as On track',
              },
              { count: status_T, percentage: toPercent(status_T, statusTotal) }
            ),
          ],
          [
            statusDescriptorFor('R', intl)?.colorHex,
            toPercent(status_R, statusTotal),
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.status_R',
                defaultMessage:
                  '{count} {count, plural, one {objective ({percentage}%) has} other {objectives ({percentage}%) have}} been marked as At risk',
              },
              {
                count: status_R,
                percentage: toPercent(status_R, statusTotal),
              }
            ),
          ],
          [
            statusDescriptorFor('F', intl)?.colorHex,
            toPercent(status_F, statusTotal),
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.status_F',
                defaultMessage:
                  '{count} {count, plural, one {objective ({percentage}%) has} other {objectives ({percentage}%) have}} been marked as Off track',
              },
              { count: status_F, percentage: toPercent(status_F, statusTotal) }
            ),
          ],
          [
            statusDescriptorFor('P', intl)?.colorHex,
            toPercent(status_P, statusTotal),
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.status_P',
                defaultMessage:
                  '{count} {count, plural, one {objective ({percentage}%) has} other {objectives ({percentage}%) have}} been marked as Postponed',
              },
              { count: status_P, percentage: toPercent(status_P, statusTotal) }
            ),
          ],
          [
            '#E3EBF6',
            toPercent(status_N, statusTotal),
            formatMessage(
              {
                id: 'app.views.widgets.dashboards.objectives_dashboard.table_row.fragment.status_N',
                defaultMessage:
                  '{count} {count, plural, one {objective ({percentage}%) has} other {objectives ({percentage}%) have}} not been marked',
              },
              { count: status_N, percentage: toPercent(status_N, statusTotal) }
            ),
          ],
        ],
        scored ? Math.round(total_score / total) : 0,
      ];
    },
    [intl, formatMessage]
  );

  const toDoughnutRow = useCallback(
    (_, { not_created, created, scored, total }: StatBreakdown) => {
      const data = [
        ['#E3EBF6', not_created],
        ['#ccf7e5', created],
        ['#00d97e', scored],
      ];
      const labels = [
        formatMessage(
          {
            id: 'app.views.widgets.dashboards.objectives_dashboard.donughnut_row.fragment.not_created',
            defaultMessage:
              '{count} {count, plural, one {person ({percentage}%) has} other {people ({percentage}%) have}} not created an objective',
          },
          { count: not_created, percentage: toPercent(not_created, total) }
        ),
        formatMessage(
          {
            id: 'app.views.widgets.dashboards.objectives_dashboard.donughnut_row.fragment.created_not_scored',
            defaultMessage:
              '{count} {count, plural, one {person ({percentage}%) has} other {people ({percentage}%) have}} created but not scored an objective',
          },
          { count: created, percentage: toPercent(created, total) }
        ),
        formatMessage(
          {
            id: 'app.views.widgets.dashboards.objectives_dashboard.donughnut_row.fragment.created_and_scored',
            defaultMessage:
              '{count} {count, plural, one {person ({percentage}%) has} other {people ({percentage}%) have}} created and scored an objective',
          },
          { count: scored, percentage: toPercent(scored, total) }
        ),
      ];
      return {
        labels,
        message: (
          <Fragment>
            <div className="text-center small pt-3">
              <FormattedMessage
                id="app.views.widgets.dashboards.objectives_dashboard.fragment.created_and_scored"
                defaultMessage="{createdAndScored} out of {total} {total, plural, one {person} other {people} } created objectives"
                values={{
                  createdAndScored: created + scored,
                  total: total,
                }}
              />
            </div>
            <div
              className="text-center text-muted"
              style={{ fontSize: '0.7rem' }}
            >
              <FormattedMessage
                id="app.views.widgets.dashboards.objectives_dashboard.fragment.scored"
                defaultMessage="{scored} out of {total} {total, plural, one {person} other {people} } scored objectives"
                values={{
                  scored: scored,
                  total: total,
                }}
              />
            </div>
          </Fragment>
        ),
        data,
      };
    },
    [formatMessage]
  );

  const backingData = useMemo(() => {
    const retval = {};
    if (data?.stats) {
      if (Object.keys(data.stats).length === 0) {
        return undefined;
      }
      Object.keys(data.stats).forEach((key) => {
        if (key === 'total') {
          retval['overall'] = toDoughnutRow('overall', data.stats.total.other);
        } else if (key === 'manager' || key === 'manager_or_above') {
          if (data.managers) {
            retval[key] = Object.entries(data.stats[key]).map(
              ([name, value]) => {
                return toTableRow(data.managers[name], value);
              }
            );
          }
        } else {
          retval[key] = Object.entries(data.stats[key]).map(([name, value]) => {
            return toTableRow(name, value);
          });
        }
      });
    }
    return retval;
  }, [data, toTableRow, toDoughnutRow]);

  const rows = useMemo(
    () => [
      [
        {
          title: formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.overall_completion_rate',
            defaultMessage: 'Overall completion rate',
          }),
          type: CARD_TYPES.GENERIC_DOUGHNUT,
          labels: [
            formatMessage({
              id: 'app.views.widgets.dashboards.objectives_dashboard.rows.not_created',
              defaultMessage: 'No objectives created',
            }),
            formatMessage({
              id: 'app.views.widgets.dashboards.objectives_dashboard.rows.created',
              defaultMessage: 'Created objectives',
            }),
            formatMessage({
              id: 'app.views.widgets.dashboards.objectives_dashboard.rows.scored',
              defaultMessage: 'Scored objectives',
            }),
          ],
          dataKey: 'overall',
          emptyStateText: NO_DATA_AVAILABLE(formatMessage),
        },
        makeTableCardInfo(
          'business unit',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.business_unit',
            defaultMessage: 'business unit',
          }),
          'business_unit',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_business_unit',
            defaultMessage: 'Completion rate by business unit',
          }),
          formatMessage
        ),
      ],
      [
        makeTableCardInfo(
          'department',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.department',
            defaultMessage: 'department',
          }),
          'department',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_department',
            defaultMessage: 'Completion rate by department',
          }),
          formatMessage
        ),
        makeTableCardInfo(
          'reporting function',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.reporting_function',
            defaultMessage: 'reporting function',
          }),
          'function',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_reporting_function',
            defaultMessage: 'Completion rate by reporting function',
          }),
          formatMessage
        ),
      ],
      [
        makeTableCardInfo(
          'manager or above',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.manager_or_above',
            defaultMessage: 'manager or above',
          }),
          'manager_or_above',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_manager_or_above',
            defaultMessage: 'Completion rate by manager or above',
          }),
          formatMessage
        ),
        makeTableCardInfo(
          'location',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.location',
            defaultMessage: 'location',
          }),
          'location',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_location',
            defaultMessage: 'Completion rate by location',
          }),
          formatMessage
        ),
      ],
      [
        makeTableCardInfo(
          'classification',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.classification',
            defaultMessage: 'classification',
          }),
          'classification',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_classification',
            defaultMessage: 'Completion rate by classification',
          }),
          formatMessage
        ),
        makeTableCardInfo(
          'cost center',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.cost_center',
            defaultMessage: 'cost center',
          }),
          'cost_center',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_cost_center',
            defaultMessage: 'Completion rate by cost center',
          }),
          formatMessage
        ),
      ],
      [
        makeTableCardInfo(
          'title',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.job_title',
            defaultMessage: 'title',
          }),
          'title',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_title',
            defaultMessage: 'Completion rate by title',
          }),
          formatMessage
        ),
        makeTableCardInfo(
          'level',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.job_level',
            defaultMessage: 'level',
          }),
          'level',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_level',
            defaultMessage: 'Completion rate by level',
          }),
          formatMessage
        ),
      ],
      [
        makeTableCardInfo(
          'manager',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.manager',
            defaultMessage: 'manager',
          }),
          'manager',
          formatMessage({
            id: 'app.views.widgets.dashboards.objectives_dashboard.rows.completion_rate_by_manager',
            defaultMessage: 'Completion rate by manager',
          }),
          formatMessage
        ),
      ],
    ],
    [formatMessage]
  );

  const noDataAvailable = useMemo(
    () => NO_DATA_AVAILABLE(formatMessage),
    [formatMessage]
  );
  return errorMessage ? (
    <PageError message={errorMessage} />
  ) : data ? (
    <CardRowDashboard
      data={backingData}
      emptyStateText={noDataAvailable}
      rows={rows}
    />
  ) : (
    <Loading />
  );
};

export default React.memo(ObjectivesDashboard);
