import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import { ResponsiveSankey } from '@nivo/sankey';

const SankeyGraphCard: FC<Props> = (props) => {
  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        {/* @ts-expect-error */}
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {props.data ? (
          <Row>
            <Col className="col-12 col-sm-4">
              {/* @ts-expect-error */}
              {props?.data?.content ? (
                // @ts-expect-error
                props?.data?.content
              ) : (
                // @ts-expect-error
                <EmptyState title={props.emptystate} />
              )}
            </Col>
            <Col className="col-12 col-sm-8">
              <ResponsiveSankey
                // @ts-expect-error
                data={props.data.data}
                margin={{ top: 20, right: 50, bottom: 150, left: 50 }}
                align="justify"
                colors={['#00d97e', '#2c7be5']}
                nodeOpacity={1}
                nodeHoverOthersOpacity={0.35}
                nodeThickness={18}
                nodeSpacing={24}
                nodeBorderWidth={0}
                nodeBorderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.8]],
                }}
                nodeBorderRadius={3}
                linkOpacity={0.5}
                linkHoverOthersOpacity={0.1}
                linkContract={3}
                enableLinkGradient={true}
                labelPosition="outside"
                labelOrientation="vertical"
                labelPadding={16}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1]],
                }}
              />
            </Col>
          </Row>
        ) : (
          // @ts-expect-error
          <EmptyState title={props.emptyStateText} />
        )}
      </CardBody>
    </Card>
  );
};

const SankeyGraphCard_propTypes = {
  color: PropTypes.string,
  fieldSingular: PropTypes.string,
  fieldPlural: PropTypes.string,
  data: PropTypes.object.isRequired,
  className: PropTypes.object,
  emptyStateText: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

type Props = PropTypes.InferProps<typeof SankeyGraphCard_propTypes>;

export default React.memo(SankeyGraphCard);
