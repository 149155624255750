import * as consts from './consts/consts';

import Avatar from './views/Widgets/People/Avatar';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import React from 'react';
import RelativeTime from './views/Widgets/RelativeTime';

const now = new Date();

export const levelingFixtures = {
  people: [
    {
      id: '100000',
      avatar: 'https://randomuser.me/api/portraits/women/10.jpg',
      given_name: 'Jasmin',
      family_name: 'Freeman',
      title: 'CEO',
      department: 'Executive',
      performance: 2,
      level_id: 'L10',
      job_profile: 'JOB12345 - Chief Executive Officer',
      track: consts.TRACKS.MANAGEMENT,
      ote: 200000,
      equity: 1000000,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2
      ),
      start_date: new Date('2017-03-06'),
    },
    {
      id: '100001',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
      given_name: 'Aubrey',
      family_name: 'Henson',
      title: 'Senior Product Manager I',
      department: 'Product',
      career_path: 4,
      level_id: 'L7',
      job_profile: 'JOB00107 - Senior Product Manager I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100009,
      performance: 4,
      flight_risk: 1,
      ote: 155000,
      equity: 40000,
      fairness: 0.16,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4
      ),
      start_date: new Date('2017-06-11'),
    },
    {
      id: '100002',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      given_name: 'Basil',
      family_name: 'Moon',
      title: 'Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L4',
      job_profile: 'JOB00204 - Software Engineer I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 2,
      flight_risk: 1,
      ote: 135000,
      equity: 35000,
      fairness: 0.05,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2 * 7
      ),
      start_date: new Date('2019-11-23'),
    },
    {
      id: '100003',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      given_name: 'Colette',
      family_name: 'Lloyd',
      title: 'Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L4',
      job_profile: 'JOB00304 - Software Engineer I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 4,
      flight_risk: 3,
      ote: 130000,
      equity: 50000,
      fairness: 0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 3 * 7
      ),
      start_date: new Date('2019-10-24'),
    },
    {
      id: '100004',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      given_name: 'Douglas',
      family_name: 'Morales',
      title: 'Associate Product Manager I',
      department: 'Product',
      career_path: 4,
      level_id: 'L2',
      job_profile: 'JOB00402 - Associate Product Manager I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100009,
      performance: 3,
      flight_risk: 1,
      ote: 95000,
      equity: 20000,
      fairness: -0.01,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 6 * 7
      ),
      start_date: new Date('2018-02-17'),
    },
    {
      id: '100005',
      avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
      given_name: 'Elisabeth',
      family_name: 'Little',
      title: 'Product Manager II',
      department: 'Product',
      career_path: 4,
      level_id: 'L5',
      job_profile: 'JOB00505 - Product Manager II',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100009,
      performance: 1,
      flight_risk: 5,
      ote: 145000,
      equity: 40000,
      fairness: 0,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 30 * 2
      ),
      start_date: new Date('2018-01-05'),
    },
    {
      id: '100006',
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      given_name: 'Fredric',
      family_name: 'Vincent',
      title: 'Software Engineer II',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L5',
      job_profile: 'JOB00605 - Frontend SWE II',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 4,
      flight_risk: 1,
      ote: 145000,
      equity: 60000,
      fairness: -0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 2 * 7
      ),
      start_date: new Date('2019-07-09'),
    },
    {
      id: '100007',
      avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
      given_name: 'Glenda',
      family_name: 'Haas',
      title: 'Customer Success Manager I',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L4',
      job_profile: 'JOB00704 - Enterprise CSM I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100014,
      performance: 1,
      flight_risk: 1,
      ote: 75000,
      equity: 20000,
      fairness: 0.04,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4 * 30
      ),
      start_date: new Date('2015-12-12'),
    },
    {
      id: '100008',
      avatar: 'https://randomuser.me/api/portraits/women/5.jpg',
      given_name: 'Hannah',
      family_name: 'Gaines',
      title: 'Senior Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L7',
      job_profile: 'JOB00807 - Senior Frontent SWE I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 2,
      flight_risk: 3,
      ote: 155000,
      equity: 100000,
      fairness: -0.07,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 5 * 30
      ),
      start_date: new Date('2018-01-10'),
    },
    {
      id: '100009',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      given_name: 'Isaac',
      family_name: 'Salazar',
      title: 'Director, Product',
      department: 'Product',
      career_path: 4,
      level_id: 'L8',
      job_profile: 'JOB00908 - Product Director',
      track: consts.TRACKS.MANAGEMENT,
      manager: 100000,
      performance: 2,
      flight_risk: 3,
      ote: 185000,
      equity: 140000,
      fairness: -0.05,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4
      ),
      start_date: new Date('2018-02-08'),
    },
    {
      id: '100010',
      avatar: 'https://randomuser.me/api/portraits/women/6.jpg',
      given_name: 'Joan',
      family_name: 'Esparza',
      title: 'Senior Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L7',
      job_profile: 'JOB00107 - Senior SWE I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 3,
      flight_risk: 1,
      ote: 160000,
      equity: 100000,
      fairness: 0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 30
      ),
      start_date: new Date('2018-07-02'),
    },
    {
      id: '100011',
      avatar: 'https://randomuser.me/api/portraits/women/7.jpg',
      given_name: 'Kellie',
      family_name: 'Shaffer',
      title: 'Director, Engineering',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L8',
      job_profile: 'JOB00118 - Engineering Director',
      track: consts.TRACKS.MANAGEMENT,
      manager: 100000,
      performance: 3,
      flight_risk: 1,
      ote: 200000,
      equity: 150000,
      fairness: 0.01,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 3 * 7
      ),
      start_date: new Date('2017-09-22'),
    },
    {
      id: '100012',
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      given_name: 'Leland',
      family_name: 'Brown',
      title: 'Customer Success Manager II',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L5',
      job_profile: 'JOB00125 - Enterprise CSM II',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100014,
      performance: 1,
      flight_risk: 5,
      ote: 80000,
      equity: 30000,
      fairness: -0.12,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 5 * 30
      ),
      start_date: new Date('2019-08-19'),
      has_pip: true,
    },
    {
      id: '100013',
      avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
      given_name: 'Miles',
      family_name: 'Gallegos',
      title: 'Customer Success Manager I',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L4',
      job_profile: 'JOB00134 - Enterprise CSM I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100014,
      performance: 4,
      flight_risk: 1,
      ote: 75000,
      equity: 20000,
      fairness: 0,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 8 * 30
      ),
      start_date: new Date('2019-10-26'),
    },
    {
      id: '100014',
      avatar: 'https://randomuser.me/api/portraits/women/8.jpg',
      given_name: 'Nita',
      family_name: 'May',
      title: 'Director, Customer Success',
      department: 'Customer Success',
      career_path: 1,
      level_id: 'L8',
      job_profile: 'JOB00148 - Director, Custom Success',
      track: consts.TRACKS.MANAGEMENT,
      manager: 100000,
      performance: 3,
      flight_risk: 1,
      ote: 30000,
      equity: 120000,
      fairness: 0,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 4 * 7
      ),
      start_date: new Date('2019-02-14'),
    },
    {
      id: '100015',
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
      given_name: 'Ollie',
      family_name: 'Shields',
      title: 'Software Engineer I',
      department: 'Engineering',
      career_path: 6,
      level_id: 'L4',
      job_profile: 'JOB00154 - Backend SWE I',
      // @ts-expect-error
      track: consts.TRACKS.PROFESSIONAL,
      manager: 100011,
      performance: 2,
      flight_risk: 1,
      ote: 130000,
      equity: 70000,
      fairness: 0.02,
      next_review: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1
      ),
      start_date: new Date('2019-01-13'),
    },
  ].map((p) => {
    return {
      ...p,
      full_name: p.given_name + ' ' + p.family_name,
      link: '/leveling/people/' + p.id,
      pronouns:
        p.avatar.indexOf('women') !== -1 ? 'she/her/hers' : 'he/him/his',
    };
  }),
  expert_edges: [
    { from: 100000, to: 100001 },
    { from: 100000, to: 100011 },
    { from: 100000, to: 100013 },
    { from: 100001, to: 100009 },
    { from: 100001, to: 100011 },
    { from: 100001, to: 100014 },
    { from: 100002, to: 100003 },
    { from: 100002, to: 100006 },
    { from: 100002, to: 100008 },
    { from: 100003, to: 100002 },
    { from: 100003, to: 100008 },
    { from: 100003, to: 100011 },
    { from: 100004, to: 100005 },
    { from: 100004, to: 100000 },
    { from: 100004, to: 100013 },
    { from: 100005, to: 100001 },
    { from: 100005, to: 100014 },
    { from: 100005, to: 100009 },
    { from: 100006, to: 100010 },
    { from: 100006, to: 100011 },
    { from: 100006, to: 100013 },
    { from: 100007, to: 100013 },
    { from: 100007, to: 100014 },
    { from: 100007, to: 100015 },
    { from: 100008, to: 100000 },
    { from: 100008, to: 100006 },
    { from: 100008, to: 100011 },
    { from: 100009, to: 100000 },
    { from: 100009, to: 100001 },
    { from: 100009, to: 100011 },
    { from: 100010, to: 100011 },
    { from: 100010, to: 100013 },
    { from: 100010, to: 100001 },
    { from: 100011, to: 100000 },
    { from: 100011, to: 100013 },
    { from: 100011, to: 100009 },
    { from: 100012, to: 100013 },
    { from: 100012, to: 100014 },
    { from: 100012, to: 100007 },
    { from: 100013, to: 100014 },
    { from: 100013, to: 100000 },
    { from: 100013, to: 100009 },
    { from: 100014, to: 100000 },
    { from: 100014, to: 100011 },
    { from: 100014, to: 100001 },
    { from: 100015, to: 100001 },
    { from: 100015, to: 100003 },
    { from: 100015, to: 100006 },
  ],
  energizer_edges: [
    { from: 100000, to: 100012 },
    { from: 100000, to: 100011 },
    { from: 100000, to: 100004 },
    { from: 100001, to: 100000 },
    { from: 100001, to: 100012 },
    { from: 100001, to: 100003 },
    { from: 100002, to: 100003 },
    { from: 100002, to: 100006 },
    { from: 100002, to: 100012 },
    { from: 100003, to: 100012 },
    { from: 100003, to: 100008 },
    { from: 100003, to: 100010 },
    { from: 100004, to: 100001 },
    { from: 100004, to: 100005 },
    { from: 100004, to: 100015 },
    { from: 100005, to: 100001 },
    { from: 100005, to: 100013 },
    { from: 100005, to: 100014 },
    { from: 100006, to: 100003 },
    { from: 100006, to: 100008 },
    { from: 100006, to: 100004 },
    { from: 100007, to: 100014 },
    { from: 100007, to: 100013 },
    { from: 100007, to: 100012 },
    { from: 100008, to: 100002 },
    { from: 100008, to: 100013 },
    { from: 100008, to: 100009 },
    { from: 100009, to: 100006 },
    { from: 100009, to: 100011 },
    { from: 100009, to: 100015 },
    { from: 100010, to: 100000 },
    { from: 100010, to: 100001 },
    { from: 100010, to: 100003 },
    { from: 100011, to: 100002 },
    { from: 100011, to: 100009 },
    { from: 100011, to: 100008 },
    { from: 100012, to: 100010 },
    { from: 100012, to: 100011 },
    { from: 100012, to: 100014 },
    { from: 100013, to: 100009 },
    { from: 100013, to: 100006 },
    { from: 100013, to: 100004 },
    { from: 100014, to: 100007 },
    { from: 100014, to: 100010 },
    { from: 100014, to: 100009 },
    { from: 100015, to: 100011 },
    { from: 100015, to: 100005 },
    { from: 100015, to: 100002 },
  ],
  tasks: [
    {
      feature_required: 'performance',
      title: 'You have a self assessment to complete',
      subtitle: 'Your performance cycle requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      duedate: 'tomorrow',
      link: '/leveling/self-assessment',
    },
    {
      feature_required: 'pips',
      title: 'Check in on a PIP',
      subtitle: 'Leland Brown has a PIP that requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      duedate: 'Friday',
      link: '/leveling/people/100012',
    },
    {
      feature_required: 'compensation',
      title: 'Upcoming compensation review',
      subtitle:
        'Hannah Gains has a compensation review that requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/women/5.jpg',
      duedate: 'in one week',
      link: '/leveling/people/100008',
    },
  ],
  fyis: [
    {
      feature_required: 'performance',
      icon: 'fe-chevrons-up',
      category: 'Overperformance',
      title: 'Three people are performing above expectations. Nice!',
      subtitle:
        'Basil Moon has a performance cycle that requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      time: 'Today',
      people: [
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
        },
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
        },
      ],
    },
    {
      feature_required: 'performance',
      icon: 'fe-zap',
      category: 'Promotion',
      title: 'A person appears to be ready for a promotion.',
      subtitle:
        'Basil Moon has a performance cycle that requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      time: 'Today',
      people: [
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        },
      ],
    },
    {
      feature_required: 'performance',
      icon: 'fe-chevrons-down',
      category: 'Underperformance',
      title: 'Uh oh! A person is performing below expectations.',
      subtitle:
        'Basil Moon has a performance cycle that requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      time: 'Two days ago',
      people: [
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
        },
      ],
    },
    {
      icon: 'fe-life-buoy',
      feature_required: 'pips',
      category: 'PIP recommended',
      title: 'A person appears to be ready for a PIP.',
      subtitle:
        'Basil Moon has a performance cycle that requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      time: 'Last week',
      people: [
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
        },
      ],
    },
    {
      icon: 'fe-dollar-sign',
      feature_required: 'compensation',
      category: 'Underpaid',
      title: 'Two of your people are underpaid.',
      subtitle:
        'Basil Moon has a performance cycle that requires your feedback.',
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      time: 'Last week',
      people: [
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
        },
        {
          given_name: 'John',
          family_name: 'Doe',
          avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
        },
      ],
    },
  ],
  recentActivities: [
    {
      icon: 'fe-zap',
      title: 'Promotion',
      subtitle: 'Aubrey Henson was promoted to Senior Product Manager I',
      time: 'Two days ago',
    },
    {
      icon: 'fe-life-buoy',
      title: 'PIP started',
      subtitle: 'Nita May started a PIP with Leland Brown',
      time: 'Last week',
    },
    {
      icon: 'fe-dollar-sign',
      title: 'Compensation raised',
      subtitle: "Kellie Shaffer increased Colette Lloyd's OTE to $130K",
      time: 'Last week',
    },
    {
      icon: 'fe-clipboard',
      title: 'Performance cycle completed',
      subtitle:
        'Isaac Salazar completed a performance cycle for Elisabeth Little',
      time: 'Last week',
    },
  ],
};

const Person = {
  // eslint-disable-next-line react/display-name
  getAvatarWithName: (person, shouldLinkToProfile) => {
    return (
      <>
        <Avatar
          size="xs"
          className="me-2"
          person={person}
          imageStyle={{ verticalAlign: '-9px' }}
        />
        {shouldLinkToProfile && <Link to={person.url}>{person.full_name}</Link>}
        {!shouldLinkToProfile && <span>{person.full_name}</span>}
      </>
    );
  },

  getManager: (person, people) => {
    return people.find((e) => e.id === person.manager);
  },

  getDirectReports: (person, people) => {
    return people.filter((e) => e.manager === person.id);
  },

  performanceGreatlyExceedsExpectations: (person) => {
    return person.performance >= 4.5;
  },

  performanceExceedsExpectations: (person) => {
    return person.performance >= 3.5;
  },

  performanceMeetsExpectations: (person) => {
    return (
      person.performance &&
      !Person.performanceExceedsExpectations(person) &&
      !Person.performanceMissesExpectations(person)
    );
  },

  performanceMissesExpectations: (person) => {
    return person.performance <= 2.5;
  },

  performanceGreatlyMissesExpectations: (person) => {
    return person.performance <= 1.5;
  },

  getPerfIcon: (person) => {
    // override with PIP indicator if there is a PIP
    if (person.has_pip) {
      return (
        <span className="badge bg-danger rounded-pill">
          <FormattedMessage id="app.fixtures_data.pip" defaultMessage="PIP" />
        </span>
      );
    }

    if (typeof person.performance === 'undefined') {
      return (
        <span className="mb-0">
          <FormattedMessage id="util.dash" defaultMessage="-" />
        </span>
      );
    }

    if (Person.performanceGreatlyExceedsExpectations(person)) {
      return (
        <span
          className="fe fe-chevrons-up text-success mb-0"
          style={{ fontSize: '1.0625rem' }}
        ></span>
      );
    } else if (Person.performanceExceedsExpectations(person)) {
      return (
        <span
          className="fe fe-chevron-up text-success mb-0"
          style={{ fontSize: '1.0625rem' }}
        ></span>
      );
    } else if (Person.performanceMeetsExpectations(person)) {
      return (
        <span
          className="fe fe-minus text-info mb-0"
          style={{ fontSize: '1.0625rem' }}
        ></span>
      );
    } else if (Person.performanceMissesExpectations(person)) {
      return (
        <span
          className="fe fe-chevron-down text-danger mb-0"
          style={{ fontSize: '1.0625rem' }}
        ></span>
      );
    } else if (Person.performanceGreatlyMissesExpectations(person)) {
      return (
        <span
          className="fe fe-chevrons-down text-danger mb-0"
          style={{ fontSize: '1.0625rem' }}
        ></span>
      );
    }
  },

  getFlightRiskIcon: (person) => {
    if (typeof person.flight_risk === 'undefined') {
      return (
        <span>
          <FormattedMessage id="util.dash" defaultMessage="-" />
        </span>
      );
    }

    if (person.flight_risk > 3) {
      return (
        <span
          className="badge bg-danger rounded-pill"
          style={{ fontWeight: 'bold' }}
        >
          <FormattedMessage
            id="app.fixtures_data.high"
            defaultMessage="
          High
        "
          />
        </span>
      );
    } else if (person.flight_risk > 2) {
      return (
        <span
          className="badge bg-warning rounded-pill"
          style={{ fontWeight: 'bold', color: '#ffffff' }}
        >
          <FormattedMessage
            id="app.fixtures_data.medium"
            defaultMessage="
          Med
        "
          />
        </span>
      );
    } else {
      return (
        <span
          className="badge bg-success rounded-pill"
          style={{ fontWeight: 'bold' }}
        >
          <FormattedMessage
            id="app.fixtures_data.low"
            defaultMessage="
          Low
        "
          />
        </span>
      );
    }
  },

  getTotalComp: (person) => {
    return person.ote + person.equity;
  },

  // eslint-disable-next-line react/display-name
  getFairnessCell: (person) => {
    const fairness = person.fairness ? person.fairness : 0;
    let fairnessPercent = Math.round(fairness * 100.0);

    if (person.fairness > 0) {
      // @ts-expect-error
      fairnessPercent = '+' + fairnessPercent;
    }

    let pinColor = '';
    const absPercent = Math.abs(fairnessPercent);

    // calculate position of slider
    let distancePercent = fairness;
    if (distancePercent < -0.2) {
      distancePercent = -0.2;
    } else if (distancePercent > 0.2) {
      distancePercent = 0.2;
    }
    distancePercent += 0.2;
    distancePercent /= 0.4;
    distancePercent = Math.round(distancePercent * 100) + '%';

    if (absPercent <= 5) {
      // green
      pinColor = '#00D97E';
    } else if (absPercent <= 10) {
      // warning
      pinColor = '#F6C343';
    } else {
      // red
      pinColor = '#E63757';
    }

    return (
      <>
        <div
          style={{
            width: '30px',
            display: 'inline-block',
            textAlign: 'right',
          }}
        >
          {fairnessPercent + '%'}
        </div>
        <div
          style={{
            position: 'relative',
            backgroundColor: '#D2DDED',
            height: '4px',
            width: '24px',
            display: 'inline-block',
            verticalAlign: '2px',
            marginLeft: '8px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              height: '8px',
              width: '8px',
              backgroundColor: pinColor,
              borderRadius: '12px',
              top: '-2px',
              marginLeft: '-4px',
              left: distancePercent,
            }}
          ></div>
        </div>
      </>
    );
  },

  // eslint-disable-next-line react/display-name
  getNextReviewCell: (person) => {
    return <RelativeTime datetime={person.next_review.toISOString()} />;
  },
};

export const Currency = {
  getAbbreviation: (dollarAmount) => {
    return '$' + dollarAmount / 1000 + 'K';
  },
};

export default Person;
