import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback } from 'react';

import ElasticsearchAPI from '../../../utils/api/ElasticsearchAPI';
import { INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import ModalEditor from './ModalEditor';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getQueryWithoutType } from '../Inputs/ValidatedInput';
import { isValidUrl } from '../../../utils/util/util';

const onValidate = (obj, formatMessage) => {
  let errors = {};

  if (!obj.credential) {
    errors['credential'] = formatMessage({
      id: 'app.views.widgets.modals.modal_credential_issuance_editor.error.credential_required',
      defaultMessage: 'A credential is required.',
    });
  }

  if (!obj.authority_name) {
    errors['authority_name'] = formatMessage({
      id: 'app.views.widgets.modals.modal_credential_issuance_editor.error.authority_required',
      defaultMessage: 'An authority is required.',
    });
  }

  if (obj.authority_website && !isValidUrl(obj.authority_website)) {
    errors['authority_website'] = formatMessage({
      id: 'app.views.widgets.modals.modal_credential_issuance_editor.error.authority_website_invalid',
      defaultMessage: 'Enter a valid url.',
    });
  }

  if (!obj.date_effective) {
    errors['date_effective'] = formatMessage({
      id: 'app.views.widgets.modals.modal_credential_issuance_editor.error.effective_date_required',
      defaultMessage: 'An effective date is required.',
    });
  }

  return errors;
};

const ModalCredentialIssuanceEditor = (props) => {
  const { formatMessage } = useIntl();

  const onValidateCallback = useCallback(
    (obj) => {
      onValidate(obj, formatMessage);
    },
    [formatMessage]
  );

  return (
    <ModalEditor
      isOpen={props.isOpen}
      toggle={props.toggle}
      method={props.object?.id ? 'PATCH' : 'POST'}
      url="credential-issuances"
      title={props.title}
      submitText={formatMessage({
        id: 'app.views.widgets.modals.modal_credential_issuance_editor.submit_text.save',
        defaultMessage: 'Save',
      })}
      object={{
        ...props.object,
        credential: props.object?.credential
          ? ElasticsearchAPI.defaultSelectorMapFunction({
              _source: props.object.credential,
              _index: 'credentials',
            })
          : undefined,
      }}
      transformObjectBeforeSubmit={(object) => {
        // set values from other fields entered manually
        return {
          ...object,
          // get name or id
          credential: object.credential?.id
            ? object.credential.id
            : {
                name: object.credential,
                organization: props.currentOrganization?.id,
              },
        };
      }}
      // remove values from inputs which are passed in via the object above instead
      // (this will prevent the input from not changing as you type)
      callback={props.successCallback}
      renderInputs={props.renderInputs}
      onValidate={onValidateCallback}
      inputs={[
        {
          name: 'credential',
          required: true,
          type: INPUT_TYPES.AUTOSUGGEST,
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.label.credential',
            defaultMessage: 'Credential',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.helper_text.credential',
            defaultMessage: 'For example, "Series 63" or "CEP Level I"',
          }),
          elasticsearchOptions: {
            url: 'get-credentials-by-name',
            getQuery: getQueryWithoutType,
          },
        },
        {
          name: 'authority_name',
          required: true,
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.label.authority',
            defaultMessage: 'Authority',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.helper_text.authority',
            defaultMessage: 'Name of authority that issued the credential',
          }),
        },
        {
          name: 'authority_website',
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.label.website',
            defaultMessage: 'Authority website',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.helper_text.website',
            defaultMessage:
              'Website of the authority that issued the credential',
          }),
          defaultValue: 'https://',
        },
        {
          name: 'date_effective',
          required: true,
          type: INPUT_TYPES.DATE_PICKER,
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.label.effective_date',
            defaultMessage: 'Effective Date',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.helper_text.effective_date',
            defaultMessage: 'When this credential became effective',
          }),
        },
        {
          name: 'date_expired',
          type: INPUT_TYPES.DATE_PICKER,
          label: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.label.expiration_date',
            defaultMessage: 'Expiration Date',
          }),
          helperText: formatMessage({
            id: 'app.views.widgets.modals.modal_credential_issuance_editor.helper_text.expiration_date',
            defaultMessage:
              'When this credential will expire (leave blank if no expiration)',
          }),
        },
      ]}
    />
  );
};

ModalCredentialIssuanceEditor.defaultProps = {
  object: {},
  title: (
    <FormattedMessage
      id="app.views.widgets.modals.modal_credential_issuance_editor.title.edit_credential"
      defaultMessage="Edit Credential"
    />
  ),
};

ModalCredentialIssuanceEditor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  object: PropTypes.object,
  title: PropTypes.string,
  renderInputs: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { me, currentOrganization } = state;

  return {
    me,
    currentOrganization,
  };
};

export default connect(mapStateToProps)(
  React.memo(ModalCredentialIssuanceEditor)
);
