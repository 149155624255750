import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import { Button } from 'reactstrap';
import ModalEditorButton from './ModalEditorButton';
import PropTypes from 'prop-types';

const ModalPersonProfileEditButton: FC<Props> = ({
  object = {},
  title = (
    <FormattedMessage
      id="app.views.widgets.modals.modal_person_profile_edit_button.default.title"
      defaultMessage="Edit Operating Manual Section"
    />
  ),
  buttonTitle = (
    <FormattedMessage
      id="app.views.widgets.modals.modal_person_profile_edit_button.default.button_title"
      defaultMessage="Edit"
    />
  ),
  isOpen = false,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const onSuccess = (profile) => {
    if (profile) {
      props.successCallback(profile);
    }
  };

  const getButtonComponent = (p) => (
    <Button color="link" className="p-0" {...p}>
      <span className="me-2 fe fe-edit"></span>
      {/* @ts-expect-error */}
      <small>{buttonTitle}</small>
    </Button>
  );

  const inputNameValuePairs = props.inputs.reduce((acc, input) => {
    // @ts-expect-error
    if (input?.name) {
      // @ts-expect-error
      acc[input.name] = input.value;
    }
    return acc;
  }, {});

  return (
    <ModalEditorButton
      buttonClassName={props.className}
      method="PATCH"
      url="me"
      // @ts-expect-error
      title={title}
      buttonComponentGenerator={getButtonComponent}
      submitText={formatMessage({
        id: 'app.views.widgets.modals.modal_person_profile_edit_button.submit_text.save',
        defaultMessage: 'Save',
      })}
      object={{ ...inputNameValuePairs, ...object }}
      transformObjectBeforeSubmit={props.transformObjectBeforeSubmit}
      // remove values from inputs which are passed in via the object above instead
      // (this will prevent the input from not changing as you type)
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      inputs={props.inputs.map(({ value, ...i }) => i)}
      callback={onSuccess}
      anchorTrigger={props.anchorTrigger}
      renderInputs={props.renderInputs}
      isOpen={isOpen}
      onClosed={props.onClosed}
    />
  );
};

const ModalPersonProfileEditButton_propTypes = {
  className: PropTypes.string,
  object: PropTypes.object,
  transformObjectBeforeSubmit: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  successCallback: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  renderInputs: PropTypes.func,
  anchorTrigger: PropTypes.string,
};

type Props = PropTypes.InferProps<
  typeof ModalPersonProfileEditButton_propTypes
>;

export default React.memo(ModalPersonProfileEditButton);
