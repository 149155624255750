import React, {
  ChangeEvent,
  MouseEventHandler,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import AvatarEditor, { type Position } from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import AvatarImagePath from '../../../assets/img/illustrations/avatars/avatar-default.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person } from '../../../types';
import { Button, Col, Input, ModalBody, ModalHeader, Row } from 'reactstrap';
import Modal from '../../../components/SafeModal';

import Avatar from './Avatar';
import SwitchInput from '../Inputs/SwitchInput';
import { connect } from 'react-redux';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import {
  getFriendlyUserFacingErrorObjectAndMessage,
  isCORSEnabledFilesUrl,
} from '../../../utils/util/util';
import Loading from '../Loading';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';

interface Props {
  person: Person;
  currentOrganization: Organization;
  currentProxyPerson: Person;
  callback: (link: string) => void;
}

type State = {
  image: string | File;
  position: Position;
  scale: number;
  rotate: number;
  preview?: {
    img: string;
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    scale: number;
    width: number;
    height: number;
  };
  width: number;
  height: number;
  isTransparent: boolean;
  backgroundColor?: string;
};

const DEFAULT_AVATAR_STATE_ATTRIBUTES = {
  position: { x: 0.5, y: 0.5 },
  scale: 1,
  rotate: 0,
  preview: undefined,
  width: 200,
  height: 200,
  isTransparent: false,
  backgroundColor: '#ffffff', // default to white
};

const PersonAvatarEditor = ({
  person,
  currentOrganization,
  currentProxyPerson,
  callback,
}: Props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [isEditing, setIsEditing] = useState(false);
  const [isImageUploadInProgress, setIsImageUploadInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [avatarEditorIsOpen, setAvatarEditorIsOpen] = useState(false);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const editor: RefObject<AvatarEditor> = useRef<AvatarEditor | null>();

  const onEditCurrentPicture = () => {
    setIsEditing(true);
  };

  const onFileInputClick = () => {
    const current = fileInput.current as HTMLInputElement;
    if (current) {
      current.click();
    }
  };

  const [state, setNewState] = useState<State>({
    ...DEFAULT_AVATAR_STATE_ATTRIBUTES,
    image: person.avatar ? person.avatar : AvatarImagePath,
  });

  const setState = useCallback(
    (dict) => {
      setNewState({ ...state, ...dict });
    },
    [state]
  );

  const toggleAvatarEditor = () => {
    setAvatarEditorIsOpen(!avatarEditorIsOpen);

    // reset everything when UI is closed
    setIsEditing(false);
    setState({
      ...DEFAULT_AVATAR_STATE_ATTRIBUTES,
      image: person.avatar ? person.avatar : AvatarImagePath,
    });
  };

  const resizeAndEditLocalImage = (image: File) => {
    Resizer.imageFileResizer(
      image,
      200, // max width
      200, // max height
      'JPEG', // format of file after resize
      100, // quality
      0, // rotation
      (uri) => {
        if (typeof uri === 'string') {
          // if image is uploaded, reset the avatar state accordingly
          setState({
            ...DEFAULT_AVATAR_STATE_ATTRIBUTES,
            image: uri, // use base64 string directly
          });

          // enable editor for zoom/etc.
          setIsEditing(true);
        }
      },
      'base64' // output type
    );
  };

  const onUploadNewPicture = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      resizeAndEditLocalImage(e.target.files[0]);
    }
  };

  const uploadImageToServer = async () => {
    if (editor?.current) {
      setErrorMessage(null);
      setIsImageUploadInProgress(true);

      // take the background color and apply it before uploading
      const originalCanvas = editor.current.getImageScaledToCanvas();
      const newCanvas = document.createElement('canvas');
      newCanvas.width = originalCanvas.width;
      newCanvas.height = originalCanvas.height;

      const ctx = newCanvas.getContext('2d');
      if (ctx) {
        // Fill the context with the desired background color.
        if (state.backgroundColor) {
          ctx.fillStyle = state.backgroundColor;
        }
        ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // Draw the original canvas onto the new one.
        ctx.drawImage(originalCanvas, 0, 0);
      }

      const canvas = newCanvas.toDataURL('image/jpeg', 0.8);

      const response = await fetch(canvas);
      const blob = await response.blob();

      const uniqueFilename = new Date().toISOString().split('T')[0] + '.jpg';
      const file = new File([blob], uniqueFilename, { type: 'image/jpeg' });

      const formData = new FormData();
      formData.append('file', file);
      if (currentOrganization?.id) {
        formData.append('organization', currentOrganization.id.toString());
      }
      if (currentProxyPerson?.email) {
        formData.append('proxy', currentProxyPerson.email);
      }

      ConfirmAPI.sendRequestToConfirm(
        'PUT',
        '/files/avatar-image',
        formData,
        (data, error, hardErrorMessage) => {
          setIsImageUploadInProgress(false);
          if (error || hardErrorMessage) {
            const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
              error,
              hardErrorMessage
            );
            console.error(
              'Error uploading avatar image: ' + JSON.stringify(errorObject)
            );
            setErrorMessage(hardErrorMessage);
          } else {
            callback(data.link);
            toast.success(
              formatMessage({
                id: 'app.views.widgets.people.person_avatar_editor.image_updated_successfully',
                defaultMessage: 'Image updated successfully!',
              })
            );
            toggleAvatarEditor();
          }
        }
      );
    }
  };

  const handleNewImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setState({ image: e.target.files[0] });
    }
  };

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    const scale = parseFloat(e.target.value);
    setState({ scale });
  };

  const rotateScale = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setState({ rotate: parseFloat(e.target.value) });
  };

  const rotateLeft: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setState({ rotate: (state.rotate - 90) % 360 });
  };

  const rotateRight: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setState({ rotate: (state.rotate + 90) % 360 });
  };

  const loadFailureCallback = (e: ErrorEvent | Error) => {
    console.error('Avatar editor image load failure:', JSON.stringify(e));
  };

  const handlePositionChange = (position: Position) => {
    setState({ position });
  };

  const setBackgroundColor = (e: ChangeEvent<HTMLInputElement>) => {
    setState({ backgroundColor: e.target.value });
  };

  const setTransparent = (isTransparent: boolean) => {
    setState({ isTransparent });
  };

  // if the image is from a domain that ends in ".confirm.com", it is accessible via CORS
  const isAccessibleViaCORS = useMemo(
    () =>
      typeof state.image !== 'string' ||
      state.image.startsWith('data:') || // for file just uploaded
      isCORSEnabledFilesUrl(state.image),
    [state.image]
  );

  return (
    <>
      <Modal isOpen={avatarEditorIsOpen} toggle={toggleAvatarEditor}>
        <ModalHeader toggle={toggleAvatarEditor}>
          <FormattedMessage
            id="app.views.widgets.people.person_avatar_editor.edit_profile picture"
            defaultMessage="Edit profile picture"
          />
        </ModalHeader>
        <ModalBody>
          <p className="text-center">
            <FormattedMessage
              id="app.views.widgets.people.person_avatar_editor.a_picture_helps"
              defaultMessage="A picture helps people recognize you across the Confirm platform."
            />
          </p>
          <Row className="align-items-center justify-content-md-center">
            <Col className="col-auto">
              <div>
                <Dropzone
                  onDrop={([image]) => resizeAndEditLocalImage(image)}
                  noClick
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="preview">
                      <div
                        style={{
                          backgroundColor: state.backgroundColor,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {isAccessibleViaCORS && (
                          <AvatarEditor
                            ref={editor}
                            scale={state.scale}
                            width={state.width}
                            height={state.height}
                            position={state.position}
                            onPositionChange={handlePositionChange}
                            rotate={state.rotate}
                            borderRadius={state.width / 2}
                            backgroundColor={state.backgroundColor}
                            onLoadFailure={loadFailureCallback}
                            image={state.image}
                            disableCanvasRotation={false}
                            // allow pulling in images from other domains for editing,
                            // e.g. imported from Google
                            crossOrigin="anonymous"
                          />
                        )}
                        {!isAccessibleViaCORS &&
                          typeof state.image === 'string' && (
                            <img src={state.image} alt="avatar" />
                          )}
                        <input
                          name="newImage"
                          type="file"
                          onChange={handleNewImage}
                          {...getInputProps()}
                        />
                      </div>
                    </div>
                  )}
                </Dropzone>
                {isEditing && (
                  <>
                    <div>
                      <h6 className="text-uppercase text-muted mb-2 pt-4">
                        <FormattedMessage
                          id="app.views.widgets.people.person_avatar_editor.zoom"
                          defaultMessage="Zoom"
                        />
                      </h6>
                      <Input
                        style={{ width: '100%' }}
                        name="scale"
                        type="range"
                        onChange={handleScale}
                        min={'0.1'}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                      />
                      <h6 className="text-uppercase text-muted mb-2 pt-4">
                        <FormattedMessage
                          id="app.views.widgets.people.person_avatar_editor.rotate"
                          defaultMessage="Rotate"
                        />
                      </h6>
                      <Row className="align-items-center">
                        <Col className="col-auto pe-0">
                          <Button
                            color="white"
                            className="btn-sm btn-rounded-circle"
                            onClick={rotateLeft}
                          >
                            <span className="fe fe-arrow-left" />
                          </Button>
                        </Col>
                        <Col className="col-auto pe-0">
                          <Button
                            color="white"
                            className="btn-sm btn-rounded-circle"
                            onClick={rotateRight}
                          >
                            <span className="fe fe-arrow-right" />
                          </Button>
                        </Col>
                        <Col>
                          <Input
                            style={{
                              width: '100%',
                              position: 'relative',
                              top: '2px',
                            }}
                            name="rotation"
                            type="range"
                            onChange={rotateScale}
                            min="0"
                            max="180"
                            step="1"
                            defaultValue="0"
                          />
                        </Col>
                      </Row>
                      <h6 className="text-uppercase text-muted mb-2 pt-4">
                        <FormattedMessage
                          id="app.views.widgets.people.person_avatar_editor.background_color"
                          defaultMessage="Background color"
                        />
                      </h6>
                      <Row>
                        <Col className="pe-0">
                          <SwitchInput
                            switchLabel={intl.formatMessage({
                              id: 'app.views.widgets.people.person_avatar_editor.add_background',
                              defaultMessage: 'Add background color',
                            })}
                            value={state.isTransparent}
                            onChange={setTransparent}
                          />
                        </Col>
                        {state.isTransparent && (
                          <Col className="col-auto">
                            <Input
                              style={{
                                width: '1.5rem',
                                height: '1.5rem',
                                padding: 0,
                              }}
                              name="backgroundColor"
                              type="color"
                              value={state.backgroundColor}
                              onChange={setBackgroundColor}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
          {errorMessage && (
            <div className="text-center pt-2 message error">
              <span className="text-danger">{errorMessage}</span>
            </div>
          )}
          {!isEditing && (
            <Row>
              {isAccessibleViaCORS && (
                <Col className="col-6">
                  <Button
                    className="w-100 mt-4"
                    color="primary"
                    onClick={onEditCurrentPicture}
                  >
                    <i className="fe fe-edit-2 me-2" />
                    <FormattedMessage
                      id="app.views.widgets.people.person_avatar_editor.edit_current_picture"
                      defaultMessage="Edit current picture"
                    />
                  </Button>
                </Col>
              )}
              <Col className={isAccessibleViaCORS ? 'col-6' : ''}>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInput}
                  name="uploaded_image"
                  onChange={onUploadNewPicture}
                />
                <Button
                  className="file w-100 mt-4"
                  color="primary"
                  onClick={onFileInputClick}
                >
                  <i className="fe fe-upload me-2" />
                  <FormattedMessage
                    id="app.views.widgets.people.person_avatar_editor.upload_new"
                    defaultMessage="Upload new picture..."
                  />
                </Button>
              </Col>
            </Row>
          )}
          {isEditing && (
            <>
              <Button
                className="w-100 mt-4"
                color="primary"
                onClick={uploadImageToServer}
                disabled={isImageUploadInProgress}
              >
                {isImageUploadInProgress && (
                  <Loading
                    small={true}
                    message={intl.formatMessage({
                      id: 'app.views.widgets.people.person_avatar_editor.uploading_image',
                      defaultMessage: 'Uploading image...',
                    })}
                  />
                )}
                {!isImageUploadInProgress && (
                  <FormattedMessage
                    id="app.views.widgets.people.person_avatar_editor.save"
                    defaultMessage="Save changes"
                  />
                )}
              </Button>
            </>
          )}
        </ModalBody>
      </Modal>
      <div
        style={{ position: 'relative' }}
        role="button"
        onClick={toggleAvatarEditor}
      >
        <Avatar person={person} size="lg" />
        <div
          style={{ position: 'absolute', bottom: '-0.15rem', right: '-0.5rem' }}
        >
          <Button
            color="white"
            className="btn-sm btn-rounded-circle"
            onClick={rotateLeft}
          >
            <i className="fe fe-camera" />
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(PersonAvatarEditor));
