import { Organization, ReduxState } from 'types';
import { useCallback, useEffect, useRef, useState } from 'react';

import ConfirmAPI from 'utils/api/ConfirmAPI';
import { useSelector } from 'react-redux';

const POLL_FREQUENCY = 5000;

export type Completion = {
  progress: number;
  total: number;
};

type ApiStatusResponse = {
  status: string;
  progress: number;
  total: number;
};

type TaskDescriptor = {
  task_key: string;
  status: string;
};

type UseBackgroundTaskParams<T = any> = {
  disabled?: boolean;
  taskDescriptor?: TaskDescriptor;
  onTaskCompleted: (data: T) => void;
  onError: (data: object) => void;
};

export const useBackgroundTask = ({
  disabled = false,
  taskDescriptor,
  onTaskCompleted,
  onError,
}: UseBackgroundTaskParams) => {
  const timers = useRef<NodeJS.Timeout[]>([]);

  const currentProxyPersonEmail = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );
  const currentOrganization = useSelector<ReduxState, Organization | undefined>(
    (state) => state.currentOrganization
  );

  const [completion, setCompletion] = useState<Completion>({
    progress: 0,
    total: 100,
  });

  const resetInternalState = useCallback(() => {
    timers.current.forEach((timer) => clearTimeout(timer));
    setCompletion({ progress: 0, total: 100 });
  }, []);

  useEffect(() => {
    resetInternalState();
  }, [resetInternalState, taskDescriptor?.task_key]);

  const handleOnTaskCompleted = useCallback(
    (data) => {
      timers.current.forEach((timer) => clearTimeout(timer));
      onTaskCompleted(data);
    },
    [onTaskCompleted]
  );

  const handleOnError = useCallback(
    (error) => {
      timers.current.forEach((timer) => clearTimeout(timer));
      onError(error);
    },
    [onError]
  );

  useEffect(() => {
    if (taskDescriptor?.status === 'submitted' && !disabled) {
      const poll = async () => {
        ConfirmAPI.sendRequestToConfirm(
          'GET',
          '/backgroundtasks/' + taskDescriptor.task_key + '/status',
          {
            proxy: currentProxyPersonEmail,
            organization_id: currentOrganization?.id,
          },
          (data_status: ApiStatusResponse) => {
            setCompletion(data_status);
            if (data_status?.status === 'C') {
              ConfirmAPI.sendRequestToConfirm(
                'GET',
                '/backgroundtasks/' + taskDescriptor.task_key + '/output',
                {
                  proxy: currentProxyPersonEmail,
                  organization_id: currentOrganization?.id,
                },
                (response) => {
                  handleOnTaskCompleted(response);
                }
              );
            } else {
              timers.current.push(setTimeout(poll, POLL_FREQUENCY));
            }
          }
        );
      };
      timers.current.push(setTimeout(poll, POLL_FREQUENCY));
    }
  }, [
    disabled,
    taskDescriptor?.status,
    taskDescriptor?.task_key,
    currentProxyPersonEmail,
    currentOrganization?.id,
    currentOrganization?.name,
    handleOnTaskCompleted,
    handleOnError,
  ]);
  return { completion };
};
