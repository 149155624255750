import React, { CSSProperties, FC } from 'react';

interface Props {
  link: string;
  style?: CSSProperties;
}

const Image: FC<Props> = ({ link, style = { width: '600px' } }) => {
  return (
    <p>
      <img style={style} src={link} className="fr-fic fr-dib" />
    </p>
  );
};

export default Image;
