import React, { FC } from 'react';
import { UncontrolledPopover, type UncontrolledPopoverProps } from 'reactstrap';

import ErrorBoundary from '../views/Error/ErrorBoundary';

const SafeUncontrolledPopover: FC<UncontrolledPopoverProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <ErrorBoundary fallback={<div />}>
      <UncontrolledPopover {...otherProps}>{children}</UncontrolledPopover>
    </ErrorBoundary>
  );
};

export default SafeUncontrolledPopover;
