import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { getFriendlyUserFacingErrorObjectAndMessage } from '../../utils/util/util';
import { rrulestr } from 'rrule';

export const getRRuleString = (rrule) => {
  if (rrule) {
    return rrulestr(rrule).toText();
  }
  return '';
};

export const getRecurringDateString = (date, days) => {
  if (!days) {
    return '';
  }

  const GOOGLE_DAYS_MAPPING = {
    SU: 'Sunday',
    MO: 'Monday',
    TU: 'Tuesday',
    WE: 'Wednesday',
    TH: 'Thursday',
    FR: 'Friday',
    SA: 'Saturday',
  };

  const SORTED_WEEKDAYS = [
    'Mondays',
    'Tuesdays',
    'Wednesdays',
    'Thursdays',
    'Fridays',
    'Saturdays',
    'Sundays',
  ];

  const localDate = new Date(Date.parse(date));

  let daysArray = days.split(',');
  daysArray = daysArray.map((d) => GOOGLE_DAYS_MAPPING[d] + 's');
  daysArray.sort(
    (a, b) => SORTED_WEEKDAYS.indexOf(a) - SORTED_WEEKDAYS.indexOf(b)
  );

  return `${localDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })} (${daysArray.join(', ')})`;
};

export const getSchedules = (peopleIds, orgId, callback) => {
  // Will return object where keys are Person IDs, and values
  // are objects with 2 keys (self- denotes the event(s) that
  // could be 1-1 btw/ that person and their manager) and
  // (direct_reports- denotes event(s) for 1-1 with their
  // direct reports)
  return ConfirmAPI.sendRequestToConfirm(
    'POST',
    'get-schedules/',
    {
      people: peopleIds,
      organization_id: orgId,
    },
    (data, error, hardErrorMessage) => {
      if (error || hardErrorMessage) {
        const [errorObject] = getFriendlyUserFacingErrorObjectAndMessage(
          error,
          hardErrorMessage
        );
        if (errorObject === true) {
          // this is not an error, just means there was no available data
          // see api.schedules.py:get_schedules()
          return;
        }
        console.error('Error locating calendar:', errorObject);
        return;
      }

      const events = data?.events;
      if (!events) {
        return null;
      }

      for (const personId in events) {
        const personEvents = events[personId];
        if (personEvents?.self) {
          personEvents.self?.forEach((e) => {
            e.recurrenceSummary = getRRuleString(e?.rrule);
          });
        }
      }

      callback(events);

      // TODO: Clean up data for direct report 1-1 (once needed)
      // if (events?.direct_reports) {
      //   for (const dr of events.direct_reports)
      //     dr.forEach(
      //       (e) =>
      //         (e.recurrence_days =
      //           e.recurrence_days && GOOGLE_DAYS_MAPPING?.[e.recurrence_days])
      //     );
      // }
    }
  );
};
