import { Col, Row } from 'reactstrap';
import React, { FC, useCallback } from 'react';

import Checkbox from './Checkbox';

export interface CheckboxMultiInput {
  key: string;
  label: string;
}

interface Props {
  name: string;
  className?: string;
  options: CheckboxMultiInput[];
  value?: string[];
  required?: boolean;
  indeterminateValue?: boolean | string;
  disabled?: boolean;
  onChange?: (value: string[]) => void;
  rowProps?: object;
}

const CheckboxMulti: FC<Props> = ({
  name,
  value: keyList,
  className = '',
  required = false,
  disabled = false,
  options,
  onChange = (_) => {},
  rowProps = { xs: 1 },
}) => {
  const handleOnChange = useCallback(
    ({ key, value }) => {
      if (keyList?.includes(key) && value === false) {
        onChange(keyList?.filter((item) => item !== key));
        return;
      }
      if (!keyList?.includes(key) && value === true) {
        onChange([...(keyList ?? []), key]);
        return;
      }
    },
    [keyList, onChange]
  );

  return (
    <Row {...rowProps}>
      {options?.map((option) => (
        <Col key={`col_${name}_${option.key}`}>
          <Row key={`row_${name}_${option.key}`} className="pb-3">
            <Col className="col-auto pe-0">
              <CheckBoxOption
                name={`${option.key}`}
                value={keyList?.includes(option.key) ?? false}
                className={className}
                required={required}
                disabled={disabled}
                onChange={handleOnChange}
              />
            </Col>
            <Col
              onClick={() =>
                handleOnChange({
                  key: option.key,
                  value: !(keyList?.includes(option.key) ?? false),
                })
              }
            >
              {option.label}
            </Col>
          </Row>
        </Col>
      )) ?? null}
    </Row>
  );
};

const CheckBoxOption = ({
  name,
  className,
  value,
  required,
  disabled,
  onChange,
}) => {
  const handleOnChange = useCallback(
    (value) => onChange({ key: name, value }),
    [name, onChange]
  );

  return (
    <Checkbox
      name={name}
      value={value}
      className={className}
      required={required}
      disabled={disabled}
      checkedValue={true}
      uncheckedValue={false}
      onChange={handleOnChange}
    />
  );
};

export default CheckboxMulti;
