import React, { FC, PropsWithChildren } from 'react';

import { intercomArticleLink } from './util';
import { useIntl } from 'react-intl';

interface Props {
  id: number;
  className?: string;
}

const ArticleLink: FC<PropsWithChildren<Props>> = ({
  id,
  className = '',
  children,
}) => {
  const { locale } = useIntl();
  return (
    <a
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      href={intercomArticleLink(locale, id)}
    >
      {children}
    </a>
  );
};

export default ArticleLink;
