import { Card, CardBody } from 'reactstrap';
import React, { FC } from 'react';

import EmptyState from '../Widgets/EmptyState';
import { useIntl } from 'react-intl';

export const EmptyFeedbackState: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Card>
      <CardBody>
        <EmptyState
          title={formatMessage({
            id: 'app.views.person.empty_feedback_state.title.no_feedback_yet',
            defaultMessage: 'No feedback yet',
          })}
          subtitle={formatMessage({
            id: 'app.views.person.empty_feedback_state.subtitle.you_do_not_have_any_feedback_published',
            defaultMessage:
              'You do not have any feedback published yet for this cycle.',
          })}
        />
      </CardBody>
    </Card>
  );
};
