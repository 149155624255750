import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo } from 'react';

import ActivityCard from '../Activities/ActivityCard';
import EmptyState from '../Widgets/EmptyState';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { insertFeedbackIntoActivities } from '../../utils/models/Feedback';

const PersonProfileActivities: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const hasActivities = props.activities?.length > 0;
  const emptyStateTitle = useMemo(() => {
    return formatMessage(
      {
        id: 'app.views.person.person_profile_activities.welcome_to_your',
        defaultMessage:
          'Welcome to your{hasOrganizationName, select, true { {organizationName}} other {}} resume.',
      },
      {
        // @ts-expect-error
        organizationName: props.currentOrganization?.name ?? '',
        // @ts-expect-error
        hasOrganizationName: !!props.currentOrganization?.name,
      }
    );
  }, [props.currentOrganization, formatMessage]);

  const activities = useMemo(
    // @ts-expect-error
    () => insertFeedbackIntoActivities(props.activities, props.feedbackList),
    [props.activities, props.feedbackList]
  );

  return (
    <>
      {props.showEmptyStateIfEmpty && !hasActivities && props.isMe && (
        <Card>
          <CardBody>
            <EmptyState
              enlargeTitle={true}
              title={emptyStateTitle}
              subtitle={formatMessage({
                id: 'app.views.person.person_profile_activities.add_activities',
                defaultMessage: 'Add accomplishments, projects, or learnings.',
              })}
            />
            <Row className="justify-content-center">
              <Col className="col-auto">
                <Button
                  onClick={() => props.openCreateActivityDialog()}
                  color="outline-primary"
                  className="btn mb-4"
                >
                  <FormattedMessage
                    id="app.views.person.person_profile_activities.add_to_my_resume"
                    defaultMessage="Add to my resume"
                  />
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {props.showEmptyStateIfEmpty && !hasActivities && !props.isMe && (
        <Card>
          <CardBody>
            <EmptyState
              title={formatMessage({
                id: 'app.views.person.person_profile_activities.no_activities.title',
                defaultMessage: 'Nothing to see here yet',
              })}
              subtitle={formatMessage(
                {
                  id: 'app.views.person.person_profile_activities.no_activities_yet.subtitle',
                  defaultMessage:
                    '{personName} has not shared any activities yet.',
                },
                {
                  // @ts-expect-error
                  personName: props.person.given_name,
                }
              )}
            />
          </CardBody>
        </Card>
      )}
      {activities.map((activity, acIndex) => (
        <ActivityCard
          key={acIndex}
          showPrompt={true}
          focalPerson={props.person}
          activity={activity}
          openCreateActivityDialog={props.openCreateActivityDialog}
          setActivity={(a) =>
            props.setActivities(
              // @ts-expect-error
              props.activities.map((a2) => (a2.id === a.id ? a : a2))
            )
          }
          showFeedback={props.showFeedback}
        />
      ))}
    </>
  );
};

const PersonProfileActivities_propTypes = {
  openCreateActivityDialog: PropTypes.func.isRequired,
  isMe: PropTypes.bool.isRequired,
  person: PropTypes.object.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  setActivities: PropTypes.func.isRequired,
  showEmptyStateIfEmpty: PropTypes.bool.isRequired,
  currentOrganization: PropTypes.object,
  showFeedback: PropTypes.bool,
  feedbackList: PropTypes.arrayOf(PropTypes.object),
};

type Props = PropTypes.InferProps<typeof PersonProfileActivities_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization } = state;

  return {
    currentOrganization,
  };
};

export default connect(mapStateToProps)(React.memo(PersonProfileActivities));
