import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';

interface SimpleCardProps {
  bodyOnly?: boolean;
  className?: string;
  emptyStateText?: string;
  role?: string;
  style?: CSSProperties;
  title: string | ReactNode;
}

const SimpleCard: React.FC<PropsWithChildren<SimpleCardProps>> = ({
  bodyOnly,
  className,
  role,
  style,
  title,
  children,
  emptyStateText = '',
}) => {
  const output = (
    <>{children ? children : <EmptyState title={emptyStateText} />}</>
  );

  return bodyOnly ? (
    output
  ) : (
    <Card className={className} role={role} style={style}>
      <CardHeader>
        <CardHeaderTitle>{title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>{output}</CardBody>
    </Card>
  );
};

export default SimpleCard;
