import { InitOrganization, InitProxyPerson, Me, Person } from '../../types';
import {
  ObjectiveWithRef as Objective,
  calculateDescendantsStats,
} from '../../utils/models/Objective';
import {
  ObjectiveItem,
  ObjectiveItemBody,
  ObjectiveItemControls,
  ObjectiveItemHead,
  ObjectiveItemTitle,
} from './components';
import React, { FC, useCallback, useState } from 'react';
import {
  hasNoUsefulInformation,
  hasPersonalEditability,
  useObjectiveActions,
} from '../Objectives/ObjectiveOperations';

import { Badge } from 'reactstrap';
import LinkedObjectives from '../Objectives/LinkedObjectives';
import ObjectiveCollaborators from '../Objectives/ObjectiveCollaborators';
import ObjectiveIndicator from '../Objectives/ObjectiveIndicator';
import ObjectiveItemAddDeleteControls from './ObjectiveItemAddDeleteControls';
import ObjectiveItemMoreControls from './ObjectiveItemMoreControls';
import ObjectiveText from '../Objectives/ObjectiveText';
import ObjectivesBreakdownBar from '../Objectives/ObjectivesBreakdownBar';
import PersonalObjectiveAddItem from './PersonalObjectiveAddItem';
import PersonalObjectiveItemCopyToNextPeriodModal from './PersonalObjectiveItemCopyToNextPeriodModal';
import PersonalObjectiveItemDeleteModal from './PersonalObjectiveItemDeleteModal';
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

interface Props {
  objective: Objective;
  parentObjective: Objective;
  showLabelHeadings?: boolean;
  updateParentObjective?: (objective: Objective) => void;
  forceShowChildren?: boolean;
  hidden: boolean;
  currentOrganization: InitOrganization;
  currentProxyPerson: InitProxyPerson;
  me: Me;
  person: Person;
  isEditable?: boolean;
  isInPopover?: boolean;
  hideScoresAndWeight?: boolean;
  hideRelatedObjectives?: boolean;
  indent: number;
  accessiblePeopleIds: number[];
  showDescendantsCount?: boolean;
  showDescendantsBreakdown?: boolean;
  textToHighlight?: string;
}

const PersonalObjectiveItem: FC<Props> = ({
  objective,
  parentObjective,
  updateParentObjective = () => {
    //do nothing
  },
  forceShowChildren = false,
  hidden,
  currentOrganization,
  currentProxyPerson,
  me,
  person,
  isEditable: isGlobalEditable = false,
  isInPopover = false,
  hideScoresAndWeight = false,
  hideRelatedObjectives = false,
  indent,
  accessiblePeopleIds,
  showDescendantsCount = false,
  showDescendantsBreakdown = false,
  textToHighlight,
}) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = React.useState<Objective>({
    ...objective,
    parent: parentObjective?.id ?? null,
  });

  const { totalDescendants, descedantsStatusBreakdown, matchDescendants } =
    calculateDescendantsStats(value);

  const [showChildren, setShowChildren] = React.useState<boolean>(
    () => forceShowChildren || matchDescendants
  );

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCopyToNextPeriodModal, setShowCopyToNextPeriodModal] =
    useState<boolean>(false);

  const isEditable: boolean =
    isGlobalEditable && hasPersonalEditability(value, accessiblePeopleIds);

  const onChildChange = useCallback((objective) => {
    setValue(objective);
    if (objective.children.length == 0) {
      setShowChildren(false);
    }
  }, []);

  const {
    deleteObjective,
    addChild,
    addSibling,
    updateRelatedObjectives,
    updateIndicators,
    updateText,
    updateTextKeyDown,
  } = useObjectiveActions({
    me,
    person,
    firstDay: objective.coverage_start_date,
    lastDay: objective.coverage_end_date,
    currentOrganization,
  });

  const onToggleItemCopyModal = useCallback(
    () => setShowCopyToNextPeriodModal(false),
    []
  );

  if (!isEditable && hasNoUsefulInformation(value)) {
    return null;
  }

  return (
    <>
      <ObjectiveItem
        hidden={hidden}
        key={value.key}
        aria-label={'container-objective-' + value.key}
      >
        <ObjectiveItemHead>
          <PersonalObjectiveItemDeleteModal
            isOpen={showDeleteModal}
            onClosed={() => setShowDeleteModal(false)}
            toggle={() => setShowDeleteModal(!showDeleteModal)}
            objective={value}
            onDelete={({ deleteAllChildren, removeForAllCollaborators }) =>
              deleteObjective({
                value,
                parentObjective,
                updateParentObjective,
                deleteAllChildren,
                removeForAllCollaborators,
              })
            }
            me={me}
            person={person}
          />
          <PersonalObjectiveItemCopyToNextPeriodModal
            isOpen={showCopyToNextPeriodModal}
            onClosed={onToggleItemCopyModal}
            toggle={onToggleItemCopyModal}
            objective={value}
            person={person}
          />
        </ObjectiveItemHead>
        <ObjectiveItemBody>
          <ObjectiveItemTitle
            isInPopover={isInPopover}
            showChildren={showChildren}
            fullWidth={hideScoresAndWeight && !isInPopover && !isEditable}
            hasChildren={(value?.children?.length ?? 0) > 0}
            onClick={() =>
              (value?.children?.length ?? 0) > 0 &&
              setShowChildren(!showChildren)
            }
            indent={indent}
          >
            {isEditable ? (
              <TextareaAutosize
                style={{
                  overflowY: 'hidden',
                }}
                className="form-control form-control-flush mt-2"
                placeholder={formatMessage({
                  id: 'app.views.person.personal_objective_item.text_editor.placeholder',
                  defaultMessage: 'Write a key result',
                })}
                value={value.name}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  updateText({ e, value, parentObjective, setValue })
                }
                ref={value.ref}
                autoFocus={value.isNew}
                onKeyDown={(e) =>
                  updateTextKeyDown({
                    e,
                    addSibling,
                    value,
                    parentObjective,
                    setShowDeleteModal,
                    deleteObjective,
                    updateParentObjective,
                  })
                }
              />
            ) : (
              <p
                className={
                  'form-control form-control-flush mb-0 ' +
                  (value.matched ? 'fw-bold' : '')
                }
                role="listitem"
                aria-label={'objective-' + value.key}
                id={'input-' + value.key}
              >
                {showDescendantsCount && totalDescendants > 0 && (
                  <Badge className="bg-light rounded-pill me-2">
                    {totalDescendants}
                  </Badge>
                )}
                <ObjectiveText
                  value={value.name}
                  textToHighlight={textToHighlight}
                />
              </p>
            )}
          </ObjectiveItemTitle>
          <ObjectiveItemControls>
            {isEditable && !hideScoresAndWeight && (
              <ObjectiveItemAddDeleteControls
                isEditable={isEditable}
                setShowChildren={setShowChildren}
                setShowDeleteModal={setShowDeleteModal}
                addChild={addChild}
                value={value}
                setValue={setValue}
              />
            )}
            {value.related_to &&
              value.related_to.length > 0 &&
              !hideRelatedObjectives &&
              value.name?.length > 0 && (
                <LinkedObjectives
                  id={`linked-objectives-${objective.key}`}
                  textIfEmpty={formatMessage({
                    id: 'app.views.widget.inputs.person_objectives.linked_objectives.related_to.editing',
                    defaultMessage: 'link related',
                  })}
                  objectives={value.related_to}
                  owner_person={value.owner_person}
                  coverageStartDate={value.coverage_start_date}
                  coverageEndDate={value.coverage_end_date}
                  isEditable={false}
                  callback={(relatedObjectives) =>
                    updateRelatedObjectives({
                      value,
                      setValue,
                      relatedObjectives,
                      parentObjective,
                    })
                  }
                />
              )}
            {!hideScoresAndWeight && (
              <>
                <ObjectiveCollaborators
                  objective={value}
                  disabled={!isEditable}
                  onSave={(updatedChild) => {
                    setValue({
                      ...value,
                      collaborators: updatedChild.collaborators,
                    });
                  }}
                />
                <ObjectiveIndicator
                  disabled={!isEditable}
                  hidden={hidden}
                  objective={value}
                  parentObjective={parentObjective}
                  me={me}
                  onChange={(updatedObj) =>
                    updateIndicators({
                      updatedObj,
                      value,
                      parentObjective,
                      updateParentObjective,
                      setValue,
                    })
                  }
                  omitDateInputs={indent > 0}
                />
              </>
            )}
            {showDescendantsBreakdown && (
              <ObjectivesBreakdownBar breakdown={descedantsStatusBreakdown} />
            )}
            {isEditable && !hideScoresAndWeight && (
              <ObjectiveItemMoreControls
                isEditable={isEditable}
                setShowChildren={setShowChildren}
                setShowDeleteModal={setShowDeleteModal}
                setShowCopyToNextPeriodModal={setShowCopyToNextPeriodModal}
                addChild={addChild}
                value={value}
                setValue={setValue}
              />
            )}
          </ObjectiveItemControls>
        </ObjectiveItemBody>
      </ObjectiveItem>
      {value.children && (
        <>
          {value.children.map((child) => (
            <PersonalObjectiveItem
              hidden={!showChildren || hidden}
              isEditable={isEditable}
              isInPopover={isInPopover}
              hideScoresAndWeight={hideScoresAndWeight}
              hideRelatedObjectives={hideRelatedObjectives}
              key={child.key}
              objective={child}
              parentObjective={value}
              updateParentObjective={(updated) => {
                onChildChange(updated);
                updateParentObjective({
                  ...parentObjective,
                  // @ts-expect-error
                  children: parentObjective.children.map((child) =>
                    child.key !== updated.key ? child : updated
                  ),
                });
              }}
              currentOrganization={currentOrganization}
              currentProxyPerson={currentProxyPerson}
              me={me}
              person={person}
              indent={indent + 1}
              accessiblePeopleIds={accessiblePeopleIds}
              showDescendantsCount={showDescendantsCount}
              showDescendantsBreakdown={showDescendantsBreakdown}
              textToHighlight={textToHighlight}
            />
          ))}
          {isEditable && (
            <PersonalObjectiveAddItem
              hidden={!showChildren || hidden}
              indent={indent}
              onClick={() => addChild({ value, setValue, setShowChildren })}
              value={value}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { me, currentOrganization, currentProxyPerson, features } = state;

  return {
    me,
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PersonalObjectiveItem));
