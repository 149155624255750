import { Organization, Person } from 'types';
import React, { FC, useEffect, useState } from 'react';
import { type RouteComponentProps, withRouter } from 'react-router-dom';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import JobFamilyExpectationsMatrix from '../Widgets/JobFamilyExpectationsMatrix';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';

interface Props extends RouteComponentProps {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
}

const JobFamily: FC<Props> = (props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [jobFamiliesDataset, setJobFamiliesDataset] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const { user } = useAuth0();
  const userSub = user?.sub;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch levels from the backend based on your permissions
    if (
      userSub &&
      props.currentOrganization?.id &&
      typeof jobFamiliesDataset === 'undefined'
    ) {
      ConfirmAPI.getUrlWithCache(
        '/job-families',
        'job-families',
        userSub,
        props.currentProxyPerson,
        { organization: props.currentOrganization?.id },
        (dataset) => {
          if (isMounted) {
            setJobFamiliesDataset(dataset?.data);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [
    isMounted,
    jobFamiliesDataset,
    userSub,
    props.currentOrganization,
    props.currentProxyPerson,
  ]);

  const loadOrRenderOutput = loadOrRender(jobFamiliesDataset, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  const jobFamily =
    // @ts-expect-error
    jobFamiliesDataset?.job_families &&
    // @ts-expect-error
    jobFamiliesDataset.job_families.find(
      // @ts-expect-error
      (l) => l.url_slug === props.match.params.slug
    );

  const levels = jobFamily?.levels
    ? jobFamily.levels
    : // @ts-expect-error
      jobFamiliesDataset?.levels;
  const expectations = jobFamily?.expectations
    ? jobFamily.expectations
    : // @ts-expect-error
      jobFamiliesDataset?.expectations;

  return (
    <JobFamilyExpectationsMatrix levels={levels} expectations={expectations} />
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(withRouter(React.memo(JobFamily)));
