import * as consts from '../../consts/consts';

import {
  AuthUser,
  Campaign,
  CurrentActiveResponse,
  Features,
  Organization,
  Person,
  ReduxState,
} from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import {
  currentIncompletePhase,
  showPerfCampaignAlert,
} from '../../utils/models/Campaign';
import {
  getUserLocalStorage,
  setUserLocalStorage,
} from '../../utils/models/User';
import { useHistory, useLocation } from 'react-router-dom';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { connect } from 'react-redux';
import { isEnabled } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';
import { processRipplingData } from 'utils/util/MergeRipplingUtils';

interface HeaderAlertsPropts {
  className?: string;
  features: Features;
  currentProxyPerson?: Person;
  currentOrganization?: Organization;
  authUser?: AuthUser;
}

const HeaderAlerts: React.FC<HeaderAlertsPropts> = ({
  features,
  currentProxyPerson,
  currentOrganization,
  authUser,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [currentActiveCampaign, setCurrentActiveCampaign] = useState<
    Campaign | undefined
  >(undefined);
  const [phasePermissions, setPhasePermissions] = useState<object | undefined>(
    undefined
  );
  const [phaseCompletions, setPhaseCompletions] = useState<object | undefined>(
    undefined
  );
  const [isFirstTimeParticipant, setIsFirstTimeParticipant] = useState(false);

  const { user } = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const userSub = user?.sub;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (
      currentOrganization?.id &&
      typeof currentActiveCampaign === 'undefined'
    ) {
      ConfirmAPI.getUrlWithCache(
        '/campaigns/current-active',
        'campaigns/current-active',
        userSub,
        currentProxyPerson,
        { organization: currentOrganization?.id },
        (data: CurrentActiveResponse) => {
          if (isMounted) {
            setCurrentActiveCampaign(data?.campaign);
            setPhasePermissions(data?.phase_permissions);
            setPhaseCompletions(data?.completions);
            setIsFirstTimeParticipant(!!data?.is_first_time_participant);
          }
        },
        (message: object) => {
          console.error('Header alert error: ' + JSON.stringify(message));
        }
      );
    }
  }, [
    currentActiveCampaign,
    currentOrganization?.id,
    currentProxyPerson,
    isMounted,
    userSub,
  ]);

  const style = {
    fontWeight: 'bold',
    fontSize: '85%',
    backgroundColor: consts.STANDARD_COLORS.primary,
    color: consts.STANDARD_COLORS.white,
  };

  const shouldShowCampaignAlert = useMemo(
    () => showPerfCampaignAlert(currentActiveCampaign, phasePermissions),
    [currentActiveCampaign, phasePermissions]
  );

  const isInPerformanceCycle = useMemo(
    () =>
      location.pathname.startsWith(consts.PERFORMANCE().path) &&
      location.pathname !== consts.TEAM_PERFORMANCE_DASHBOARD().path,
    [location]
  );

  const canShowAlertOnPage = useMemo(
    () =>
      location.pathname !== consts.HOME(formatMessage).path &&
      !isInPerformanceCycle &&
      !location.pathname.startsWith(consts.WELCOME(formatMessage).path),
    [location, isInPerformanceCycle, formatMessage]
  );

  const incompletePhase = useMemo(
    () =>
      currentIncompletePhase(
        formatMessage,
        currentActiveCampaign,
        phasePermissions,
        phaseCompletions
      ),
    [currentActiveCampaign, phasePermissions, phaseCompletions, formatMessage]
  );

  const ripplingData = processRipplingData(location.search);

  const shouldRedirectToPerformance = useMemo(() => {
    if (
      !isEnabled(features, consts.FLAGS.AUTO_REDIRECT_TO_PERF) ||
      !authUser ||
      isInPerformanceCycle ||
      location.pathname.startsWith(consts.WELCOME(formatMessage).path) ||
      ripplingData
    ) {
      return false;
    }

    if (shouldShowCampaignAlert && isFirstTimeParticipant) {
      const key = 'app.views.dashboard.have_already_been_redirected';

      const valueFromLocalStorage = getUserLocalStorage(
        authUser.sub,
        currentProxyPerson,
        currentOrganization?.id,
        key
      );

      if (!valueFromLocalStorage) {
        setUserLocalStorage(
          authUser.sub,
          currentProxyPerson,
          currentOrganization?.id,
          key,
          true
        );

        return true;
      }

      return false;
    }
  }, [
    authUser,
    currentOrganization?.id,
    currentProxyPerson,
    features,
    formatMessage,
    isFirstTimeParticipant,
    isInPerformanceCycle,
    location.pathname,
    ripplingData,
    shouldShowCampaignAlert,
  ]);

  useEffect(() => {
    if (shouldRedirectToPerformance) {
      history.replace('/performance');
    }
  }, [shouldRedirectToPerformance, history]);

  return shouldShowCampaignAlert && canShowAlertOnPage ? (
    <div
      className="text-center px-3"
      style={{ ...style, paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
    >
      <FormattedMessage
        id="app.views.dashboard.performance_cycle_underway"
        defaultMessage="{orgName}'s {campaignName} cycle {incompletePhase, select, completed {} other {({incompletePhase})}} is underway!"
        values={{
          orgName: currentOrganization?.name ?? '',
          campaignName: currentActiveCampaign?.name ?? '',
          incompletePhase: incompletePhase ?? 'completed',
        }}
      />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={consts.PERFORMANCE().path}
        style={{
          ...style,
          textDecoration: 'underline',
          fontSize: undefined,
        }}
        className="ms-2"
      >
        {incompletePhase
          ? formatMessage({
              id: 'app.views.dashboard.complete_cycle',
              defaultMessage: 'Complete cycle ›',
            })
          : formatMessage({
              id: 'app.views.dashboard.edit_responses',
              defaultMessage: 'Edit responses ›',
            })}
      </a>
    </div>
  ) : null;
};

const mapStateToProps = (state: ReduxState) => {
  const { authUser, features, currentOrganization, currentProxyPerson } = state;

  return {
    authUser,
    features,
    currentOrganization,
    currentProxyPerson,
  };
};

export default connect(mapStateToProps)(React.memo(HeaderAlerts));
