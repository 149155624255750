import { Col, Row, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';
import {
  getNotificationMethods,
  NOTIFICATION_METHODS,
  PERFORMANCE_PHASE_NAMES,
} from '../../../consts/consts';
import {
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_REPORTING,
} from '../../../utils/models/Performance';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createCSVFileName } from '../../../utils/util/util';

import { CSVLink } from 'react-csv';
import { INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import Loading from '../Loading';
import ModalEditorButton from './ModalEditorButton';
import { capitalize } from '../../../utils/util/formatter';
import { connect } from 'react-redux';
import {
  getPhaseRomanNumeral,
  isEngagementSurveyOnly,
} from '../../../utils/models/Campaign';
import phaseEnds from '../../../assets/img/illustrations/phase_ends_soon.png';
import phaseOngoing from '../../../assets/img/illustrations/phase_ongoing.png';
import phaseReporting from '../../../assets/img/illustrations/phase_reporting.png';
import phaseStarts from '../../../assets/img/illustrations/phase_has_started.png';
import reportAck from '../../../assets/img/illustrations/report_ack.png';
import takeawaysLive from '../../../assets/img/illustrations/takeaways_live.png';
import { useConfirmApi } from 'utils/api/ApiHooks';
import { useBackgroundTask } from 'components/BackgroundTaskModal/hooks';
import { Campaign, Features, Organization, Person } from 'types';

interface InputObject {
  recipients?: number;
  recipientsList?: object[];
  notification_type?: string;
  schedule_type?: string;
  methods: string[];
}

interface TypedProps {
  callback: (...args: any[]) => any;
  campaign: Campaign;
  currentOrganization: Organization;
  me: Person;
  currentProxyPerson?: Person;
  features: Features;
  urlInputAttributes?: object;
  disabled?: boolean;
  disabledPopoverText?: string;
  object: InputObject;
  readOnly: boolean;
  onClosed?: (...args: any[]) => any;
}

interface NotificationDescriptor {
  action: string;
  phaseType: string;
  id: string;
  name: string;
}

const IMAGE_MAPPING = {
  'phase-start': phaseStarts,
  'phase-ongoing': phaseOngoing,
  'phase-ends-soon': phaseEnds,
  'takeaways-live': takeawaysLive,
  'phase-reporting': phaseReporting,
  'report-ack': reportAck,
};

const NOTIFICATIIONS_WITH_CUSTOM_TEXT = new Set([
  'phase-start',
  'phase-ongoing',
  'phase-ends-soon',
]);

const notificationScheduleTypes = (formatMessage) => [
  {
    id: 'I',
    name: formatMessage({
      id: 'app.views.widgets.modals.modal_schedule_notification_button.immediate',
      defaultMessage: 'Immediate',
    }),
  },
  {
    id: 'S',
    name: formatMessage({
      id: 'app.views.widgets.modals.modal_schedule_notification_button.scheduled',
      defaultMessage: 'Scheduled',
    }),
  },
];

export const getCampaignNotificationTypes = (
  campaign: Campaign,
  formatMessage: IntlShape['formatMessage']
): { [key: string]: NotificationDescriptor } => {
  const isSurveyOnly = isEngagementSurveyOnly(campaign);

  // Skip email sends for certain phases (ie. Calibration, Reporting)
  const validCampaignPhases =
    campaign?.phases.filter(
      (p) => ![PHASE_TYPE_CALIBRATION, PHASE_TYPE_REPORTING].includes(p?.type)
    ) || [];

  const notificationActions = [
    {
      name: 'phase-start',
      item: 'phase_start',
    },
    {
      name: 'phase-ongoing',
      item: 'phase_ongoing',
    },
    {
      name: 'phase-ends-soon',
      item: 'phase_ends_soon',
    },
  ];

  // TODO: remove once we figure out styling of this form
  const enableLongPhaseNames = false;
  const notifications = validCampaignPhases.reduce((acc, validPhase, i) => {
    notificationActions.forEach((action) => {
      const label = isSurveyOnly
        ? formatMessage(
            {
              id: 'app.views.widgets.modals.modal_schedule_notification_button.phase_action.survey_only',
              defaultMessage:
                'Cycle {action, select, phase_start {has started} phase_ongoing {is ongoing} phase_ends_soon {ends soon} other {}}',
            },
            {
              action: action.item,
            }
          )
        : formatMessage(
            {
              id: 'app.views.widgets.modals.modal_schedule_notification_button.phase_action',
              defaultMessage:
                'Phase {phaseName} {action, select, phase_start {has started} phase_ongoing {is ongoing} phase_ends_soon {ends soon} other {}}',
            },
            {
              phaseName:
                validPhase.type && enableLongPhaseNames
                  ? PERFORMANCE_PHASE_NAMES(formatMessage)[validPhase.type]
                  : getPhaseRomanNumeral(i),
              action: action.item,
            }
          );
      const notifObj = {
        action: action.name,
        phaseType: validPhase.type,
        id: label,
        name: label,
      };

      acc[label] = notifObj;
    });

    return acc;
  }, {});

  if (!isSurveyOnly) {
    notifications['takeaways-live'] = {
      action: 'takeaways-live',
      phaseType: '-',
      id: 'takeaways-live',
      name: formatMessage({
        id: 'app.views.widgets.modals.modal_schedule_notification_button.phase_takeaways_live',
        defaultMessage: 'Takeaways live',
      }),
    };
  }

  const reportingPhase = campaign?.phases.find(
    (p) => p?.type === PHASE_TYPE_REPORTING
  );
  if (reportingPhase) {
    notifications['phase-reporting'] = {
      action: 'phase-reporting',
      phaseType: PHASE_TYPE_REPORTING,
      id: 'phase-reporting',
      name: formatMessage({
        id: 'app.views.widgets.modals.modal_schedule_notification_button.phase_reporting',
        defaultMessage: 'Release performance report',
      }),
    };

    notifications['report-ack'] = {
      action: 'report-ack',
      phaseType: PHASE_TYPE_REPORTING,
      id: 'report-ack',
      name: formatMessage({
        id: 'app.views.widgets.modals.modal_schedule_notification_button.phase_report_ack',
        defaultMessage: 'Acknowledge conversation',
      }),
    };
  }

  return notifications;
};

const instantMessagingUpsell = (formatMessage, currentOrganization) => {
  if (currentOrganization.instant_messaging) {
    return undefined;
  }

  return (
    <Row>
      <div className="text-muted mb-4">
        {formatMessage({
          id: 'app.views.widgets.modals.modalschedulenotificationbutton.instant_messaging_upsell',
          defaultMessage:
            'If you would rather receive your notifications in Slack or Microsoft Teams, reach out to Confirm.',
        })}
      </div>
    </Row>
  );
};

interface RecipientResult {
  total: number | undefined;
  list?: object[];
}

const ModalScheduleNotificationButton = (props: TypedProps) => {
  const { formatMessage } = useIntl();
  const [recipientTotalIsLoading, setRecipientTotalIsLoading] = useState(false);
  const [notificationType, setNotificationType] = useState<string | undefined>(
    undefined
  );
  const [notificationSchedule, setNotificationSchedule] = useState<
    string | undefined
  >(undefined);
  const [object, setObject] = useState<object | undefined>(undefined);
  const [notificatioDescriptor, setNotificationDescriptor] = useState<
    NotificationDescriptor | undefined
  >(undefined);
  const [recipientsCache, setRecipientsCache] = useState<{
    [key: string]: RecipientResult;
  }>({});

  const [cachedRecipients, setCachedRecipients] = useState<
    RecipientResult | undefined
  >(undefined);

  const [computedRecipients, setComputedRecipients] = useState<
    RecipientResult | undefined
  >(undefined);

  const recipients: RecipientResult | undefined = useMemo(
    () =>
      cachedRecipients ||
      computedRecipients || {
        total: props.object?.recipients,
        list: props.object?.recipientsList,
      },
    [cachedRecipients, computedRecipients, props.object]
  );

  const cacheKey = `${props.currentOrganization?.id}_${props?.campaign?.id}_${notificatioDescriptor?.phaseType}_${notificatioDescriptor?.action}_${NOTIFICATION_METHODS.EMAIL}_${notificationSchedule}`;

  useEffect(() => {
    if (!notificatioDescriptor || !notificationSchedule) {
      setCachedRecipients(undefined);
    } else {
      setCachedRecipients(recipientsCache[cacheKey]);
    }
    setComputedRecipients(undefined);
  }, [recipientsCache, notificationSchedule, notificatioDescriptor, cacheKey]);

  const isApiDisabled =
    !notificatioDescriptor || !notificationSchedule || !!cachedRecipients;

  const apiCallback = useCallback((data, error) => {
    if (!error) {
      setRecipientTotalIsLoading(true);
    }
  }, []);

  const { data, error, status } = useConfirmApi<{
    status: string;
    task_key: string;
  }>({
    method: 'GET',
    url: 'get-notification-recipients/',
    params: {
      organization: props.currentOrganization?.id,
      campaign_id: props?.campaign?.id,
      phase: notificatioDescriptor?.phaseType,
      type: notificatioDescriptor?.action,
      methods: NOTIFICATION_METHODS.EMAIL,
      schedule: notificationSchedule ?? 'S',
    },
    callback: apiCallback,
    disabled: isApiDisabled,
  });

  useEffect(() => {
    if (status === 'ERROR') {
      setComputedRecipients(undefined);
    }
  }, [status, error]);

  const handleOnTaskError = useCallback(() => {
    setComputedRecipients(undefined);
    setRecipientTotalIsLoading(false);
  }, []);

  const handleOnTaskComplete = useCallback(
    (data) => {
      setComputedRecipients(data);
      setRecipientsCache((cache) => ({ ...cache, [cacheKey]: data }));
      setRecipientTotalIsLoading(false);
    },
    [cacheKey]
  );

  useBackgroundTask({
    disabled:
      !recipientTotalIsLoading || !!computedRecipients || status !== 'SUCCESS',
    taskDescriptor: data,
    onTaskCompleted: handleOnTaskComplete,
    onError: handleOnTaskError,
  });

  const getValidNotificationTypes = useMemo(() => {
    return getCampaignNotificationTypes(props.campaign, formatMessage);
  }, [props.campaign, formatMessage]);

  const mapNotificationToObject = useCallback(
    (notification) => {
      if (notification) {
        const notificationTypeEntry = Object.entries(
          getValidNotificationTypes
        ).find(
          ([, nt]) =>
            nt.action === notification.notification_type &&
            nt.phaseType === notification.phase
        );

        // gets the second item of the array without failing if undefined
        const [, notificationType] = notificationTypeEntry || [];

        return {
          id: notification.id,
          type: notificationType?.id,
          schedule: notification.schedule_type,
          status: notification.status,
          scheduled_send_time: notification.scheduled_send_time,
          text: notification.data?.text,
          total: notification.recipients,
          methods: notification.methods.join(','),
        };
      }

      return undefined;
    },
    [getValidNotificationTypes]
  );

  useEffect(() => {
    const object = mapNotificationToObject(props.object);
    setObject(object);
    setNotificationType(props.object?.notification_type);
    setNotificationSchedule(props.object?.schedule_type);
  }, [props.object, mapNotificationToObject]);

  const csvHeaders = useMemo(() => {
    // Assumes all recipients in List have same keys
    if (recipients?.list?.length) {
      const sampleRecipient = recipients.list[0];
      return Object.keys(sampleRecipient).reduce(
        // @ts-expect-error
        (acc, i) => [...acc, { label: capitalize(i), key: i }],
        []
      );
    }

    return [];
  }, [recipients?.list]);

  const userTimezone = useMemo(() => {
    const tz = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
    return tz ? ` (${tz})` : '';
  }, []);

  const getCol2 = (element) => {
    return <Col className="col-12 col-md-2">{element}</Col>;
  };

  const getCol4 = (element) => {
    return <Col className="col-12 col-md-4">{element}</Col>;
  };

  const getCol5 = (element) => {
    return <Col className="col-12 col-md-5">{element}</Col>;
  };

  const getCol6 = (element) => {
    return <Col className="col-12 col-md-6">{element}</Col>;
  };

  const getCol12 = (element) => {
    return <Col className="col-12">{element}</Col>;
  };

  const renderForm = useCallback(
    (inputs, button) => {
      return (
        <>
          {!props.readOnly && (
            <div className="text-muted mb-4">
              <FormattedMessage
                id="app.views.widgets.modals.modal_schedule_notification_button.form_description"
                defaultMessage="Select a notification type and medium to continue."
              />
            </div>
          )}
          <Row>{inputs}</Row>
          {!props.readOnly && (
            <>
              {notificationType && notificationSchedule === 'I' && (
                <>
                  <div className="text-center fw-bold my-4">
                    <FormattedMessage
                      id="app.views.widgets.modals.modal_schedule_notification_button.notification_schedule_i.warning"
                      defaultMessage="Warning: clicking 'Send notification' will send this notification right now to all recipients. It cannot be undone."
                    />
                  </div>
                </>
              )}
              {button}
              {instantMessagingUpsell(formatMessage, props.currentOrganization)}
            </>
          )}
        </>
      );
    },
    [
      formatMessage,
      notificationSchedule,
      notificationType,
      props.currentOrganization,
      props.readOnly,
    ]
  );

  const inputs = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const notification_medium_options = getNotificationMethods(
      formatMessage,
      props.currentOrganization
    );

    return [
      {
        required: true,
        type: INPUT_TYPES.DROPDOWN,
        name: 'type',
        label: formatMessage({
          id: 'app.views.widgets.modals.modal_schedule_notification_button.notification_type',
          defaultMessage: 'Notification type',
        }),
        objects: Object.values(getValidNotificationTypes),
        wrapperFunction: getCol5,
      },
      {
        required: true,
        type: INPUT_TYPES.DROPDOWN,
        name: 'methods',
        label: formatMessage({
          id: 'app.views.widgets.modals.modal_schedule_notification_button.medium',
          defaultMessage: 'Medium',
        }),
        objects: notification_medium_options,
        wrapperFunction: getCol4,
      },
      ...(typeof recipients !== 'undefined' || recipientTotalIsLoading
        ? [
            {
              type: INPUT_TYPES.CUSTOM_INPUT,
              name: 'total',
              label: (
                <span>
                  <FormattedMessage
                    id="app.views.widgets.modals.modal_schedule_notification_button.recipients"
                    defaultMessage="Recipients"
                  />
                  {!recipientTotalIsLoading &&
                    recipients &&
                    (recipients.total ?? 0) > 0 &&
                    recipients.list && (
                      <CSVLink
                        filename={createCSVFileName()}
                        data={recipients.list}
                        headers={csvHeaders}
                      >
                        <i
                          role="button"
                          className="fe fe-download text-primary small ms-2"
                        ></i>
                      </CSVLink>
                    )}
                </span>
              ),
              component: (
                <>
                  {recipientTotalIsLoading && (
                    <Loading
                      className="mt-3"
                      small={true}
                      message={formatMessage({
                        id: 'app.views.widgets.modals.modal_schedule_notification_button.message.calculating',
                        defaultMessage: 'Calculating...',
                      })}
                    />
                  )}
                  {!recipientTotalIsLoading && (
                    <Row style={{ fontSize: '1.75rem' }}>
                      <Col className="d-flex justify-content-center">
                        {recipients &&
                        (recipients?.total ?? 0) > 0 &&
                        recipients?.list ? (
                          <>
                            <span
                              className="text-primary"
                              id="recipients-download"
                              role="button"
                            >
                              {recipients.total}
                            </span>
                            <UncontrolledPopover
                              trigger={'hover click'}
                              placement="right"
                              target={'recipients-download'}
                            >
                              <CSVLink
                                className="text-primary"
                                filename={createCSVFileName()}
                                data={recipients.list}
                                headers={csvHeaders}
                              >
                                <FormattedMessage
                                  id="app.views.widgets.modals.modal_schedule_notification_button.popover.csv.link"
                                  defaultMessage="
                                Download recipient list
                              "
                                />
                              </CSVLink>
                            </UncontrolledPopover>
                          </>
                        ) : (
                          recipients?.total
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              ),
              wrapperFunction: (element) => {
                return (
                  <Col className="col-12 col-md-3 d-flex justify-content-center border-start">
                    {element}
                  </Col>
                );
              },
            },
          ]
        : []),
      ...(notificationType !== undefined
        ? [
            {
              required: true,
              type: INPUT_TYPES.DROPDOWN,
              name: 'schedule',
              label: formatMessage({
                id: 'app.views.widgets.modals.modal_schedule_notification_button.notification_schedule',
                defaultMessage: 'Notification schedule',
              }),
              objects: notificationScheduleTypes(formatMessage),
              wrapperFunction: getCol4,
            },
          ]
        : []),
      ...(notificationSchedule === 'S'
        ? [
            {
              type: INPUT_TYPES.SWITCH,
              name: 'status',
              label: formatMessage({
                id: 'app.views.widgets.modals.modal_schedule_notification_button.enabled',
                defaultMessage: 'Enabled',
              }),
              defaultValue: 'W',
              checkedValue: 'W',
              uncheckedValue: 'P',
              enableHelp: true,
              hoverHelperText: formatMessage({
                id: 'app.views.widgets.modals.modal_schedule_notification_button.enabled.help_text',
                defaultMessage:
                  "If disabled, the notification will be paused until it's enabled again.",
              }),
              wrapperFunction: getCol2,
            },
            {
              type: INPUT_TYPES.DATE_PICKER,
              name: 'scheduled_send_time',
              label: formatMessage(
                {
                  id: 'app.views.widgets.modals.modal_schedule_notification_button.send_time',
                  defaultMessage: 'Send time {userTimezone}',
                },
                { userTimezone }
              ),
              enableTime: true,
              wrapperFunction: getCol6,
              minDate: today,
            },
          ]
        : []),
      ...(notificationType
        ? [
            ...(NOTIFICATIIONS_WITH_CUSTOM_TEXT.has(notificationType)
              ? [
                  {
                    type: INPUT_TYPES.TEXTAREA,
                    name: 'text',
                    label: formatMessage({
                      id: 'app.views.widgets.modals.modal_schedule_notification_button.notification_body',
                      defaultMessage: 'Custom notification body text',
                    }),
                    minRows: 2,
                    wrapperFunction: getCol12,
                  },
                ]
              : []),
            {
              type: INPUT_TYPES.CUSTOM_INPUT,
              name: 'preview',
              label: formatMessage({
                id: 'app.views.widgets.modals.modal_schedule_notification_button.preview',
                defaultMessage: 'Preview',
              }),
              wrapperFunction: getCol12,
              component: (
                <Row className="mt-3 justify-content-md-center">
                  <Col className="col-auto">
                    <img src={IMAGE_MAPPING[notificationType]} width="500px" />
                  </Col>
                </Row>
              ),
            },
          ]
        : []),
    ];
  }, [
    csvHeaders,
    formatMessage,
    getValidNotificationTypes,
    notificationSchedule,
    notificationType,
    props.currentOrganization,
    recipients,
    recipientTotalIsLoading,
    userTimezone,
  ]);

  const onChange = useCallback(
    (o) => {
      if (o?.type) {
        const notif = getValidNotificationTypes?.[o?.type];
        setNotificationDescriptor(notif);
        setNotificationType(notif.action);
      }

      if (o?.schedule) {
        setNotificationSchedule(o.schedule);
      }
    },
    [getValidNotificationTypes, setNotificationType]
  );

  const transformObjectBeforeSubmit = useCallback(
    (o) => {
      const notif = getValidNotificationTypes?.[o?.type];
      const methods = o.methods.split(',');

      return {
        ...o,
        phase: notif?.phaseType,
        type: notif?.action,
        campaign_id: props?.campaign?.id,
        methods: methods,
        recipients: recipients?.total ?? 0,
      };
    },
    [props?.campaign?.id, getValidNotificationTypes, recipients?.total]
  );

  const onValidate = useCallback(
    (obj) => {
      const errors = {};
      if (obj.schedule === 'S' && !obj.scheduled_send_time) {
        errors['scheduled_send_time'] = formatMessage({
          id: 'app.views.widgets.modals.modal_schedule_notification_button.validation.send_time',
          defaultMessage: 'Please select a time for the notication.',
        });
      } else if (
        obj.schedule === 'S' &&
        new Date(obj.scheduled_send_time) < new Date()
      ) {
        errors['scheduled_send_time'] = formatMessage({
          id: 'app.views.widgets.modals.modal_schedule_notification_button.validation.send_time_future',
          defaultMessage: 'The send time should be in the future.',
        });
      }

      return errors;
    },
    [formatMessage]
  );

  const output = useMemo(
    () => (
      <>
        <ModalEditorButton
          buttonClassName="btn-sm"
          block
          url="send-notifications"
          title={
            // @ts-expect-error
            object?.id
              ? props.readOnly
                ? formatMessage({
                    id: 'app.views.widgets.modals.modal_schedule_notification_button.view_notification',
                    defaultMessage: 'View notification',
                  })
                : formatMessage({
                    id: 'app.views.widgets.modals.modal_schedule_notification_button.edit_notification',
                    defaultMessage: 'Edit notification',
                  })
              : formatMessage({
                  id: 'app.views.widgets.modals.modal_schedule_notification_button.create_notification',
                  defaultMessage: 'Create notification',
                })
          }
          submitText={
            notificationSchedule === 'S'
              ? formatMessage({
                  id: 'app.views.widgets.modals.modal_schedule_notification_button.schedule_notification',
                  defaultMessage: 'Schedule notification',
                })
              : formatMessage({
                  id: 'app.views.widgets.modals.modal_schedule_notification_button.send_notification',
                  defaultMessage: 'Send notification',
                })
          }
          object={object}
          onChange={onChange}
          onValidate={onValidate}
          inputs={inputs}
          transformObjectBeforeSubmit={transformObjectBeforeSubmit}
          callback={props.callback}
          disabled={props.disabled}
          readOnly={props.readOnly}
          renderForm={renderForm}
          // @ts-expect-error
          color={object?.id ? 'light' : null}
          onClosed={props.onClosed}
        />
      </>
    ),
    [
      object,
      notificationSchedule,
      onChange,
      onValidate,
      inputs,
      transformObjectBeforeSubmit,
      props.callback,
      props.disabled,
      props.onClosed,
      props.readOnly,
      renderForm,
      formatMessage,
    ]
  );

  return output;
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, me, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    features,
  };
};

export default connect(mapStateToProps)(
  React.memo(ModalScheduleNotificationButton)
);
