import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import RichTextEditor from '../Inputs/RichTextEditor';
import ValidatedInput from '../Inputs/ValidatedInput';
import { connect } from 'react-redux';
import { prepareOpenResponseQuestion } from '../../../utils/models/Performance';
import { useIntl } from 'react-intl';

const Question: FC<Props> = ({ disabled = false, ...props }) => {
  const { formatMessage } = useIntl();
  const [demoValue, setDemoValue] = useState(undefined);

  // when question changes, clear demo value
  useEffect(() => {
    setDemoValue(undefined);
  }, [props.value]);

  // @ts-expect-error
  const myName = useMemo(() => props.me?.given_name, [props.me?.given_name]);

  const type = useMemo(
    // @ts-expect-error
    () => props.value?.type?.toUpperCase(),
    // @ts-expect-error
    [props.value?.type]
  );

  const formattedQuestion = prepareOpenResponseQuestion(
    formatMessage,
    props.value,
    [],
    false,
    myName,
    false,
    false,
    props.campaign,
    props.currentOrganization,
    -1,
    false
  );

  const propsUpdateLabel = props.updateLabel;
  const onChange = useCallback(
    (label) => {
      // @ts-expect-error
      propsUpdateLabel(label);
    },
    [propsUpdateLabel]
  );

  const output = useMemo(() => {
    if (props.inModal) {
      // for modal, only show label and make it editable inline
      return (
        <RichTextEditor
          className="hide-border"
          // unsaved changes are handled by the parent component
          disableUnsavedChangesPrompt={true}
          placeholder={formatMessage({
            id: 'app.views.widgets.questions.question.placeholder.write_a_question',
            defaultMessage: 'Write a question',
          })}
          inForm={true}
          disabled={disabled}
          showToolbar={true}
          toolbarInline={true}
          // @ts-expect-error
          expanded={true}
          name={'custom_question_' + formattedQuestion?.id}
          // @ts-expect-error
          value={props.value?.label?.length > 0 ? props.value?.label : ''}
          onChange={(label) => onChange(label)}
          autoFocus={props.autoFocus}
        />
      );
    } else {
      return (
        <>
          {type === INPUT_TYPES.SECTION && (
            <h2 className="header-title">{formattedQuestion.label}</h2>
          )}
          {type !== INPUT_TYPES.SECTION && (
            <ValidatedInput
              {...formattedQuestion}
              value={demoValue}
              onChange={(e) => setDemoValue(e.target.value)}
              type={type}
              disabled={disabled}
            />
          )}
        </>
      );
    }
  }, [
    demoValue,
    formattedQuestion,
    onChange,
    props.autoFocus,
    disabled,
    props.inModal,
    // @ts-expect-error
    props.value?.label,
    type,
    formatMessage,
  ]);

  return output;
};

const Question_propTypes = {
  value: PropTypes.object.isRequired,
  campaign: PropTypes.object,
  disabled: PropTypes.bool,
  me: PropTypes.object.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  inModal: PropTypes.bool,
  // only required for inModal (where inline editing modal is possible)
  updateLabel: PropTypes.func,
  autoFocus: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof Question_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization } = state;

  return {
    me,
    currentOrganization,
  };
};

export default connect(mapStateToProps)(React.memo(Question));
