import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useMemo, useState } from 'react';

import { BREAKDOWN_FIELDS } from '../../../utils/models/Takeaways';
import CardHeaderTitle from './CardHeaderTitle';
import DashboardDropdown from '../../Widgets/Dropdowns/DashboardDropdown';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import TakeawaysBreakdownChart from '../Charts/TakeawaysBreakdownChart';

const BreakdownChartCard: FC<Props> = ({
  breakdownType = 'gold_star',
  emptyStateText = (
    <FormattedMessage
      id="app.views.widgets.cards.breakdown_chart_card.empty_state_text"
      defaultMessage="There is no data available"
    />
  ),
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [currentIndex, setCurrentIndex] = useState(0);

  const fields = useMemo(
    () =>
      Object.keys(props.data).filter(
        (x) => x !== 'individual' && props?.data[x]?.length > 1
      ),
    [props.data]
  );

  const options = useMemo(
    () =>
      fields.map((x, index) => [
        BREAKDOWN_FIELDS(formatMessage)[x] || x,
        index,
      ]),
    [fields, formatMessage]
  );

  const dropdown = useMemo(() => {
    const selected = currentIndex in options ? options[currentIndex][0] : '';
    return (
      <>
        {options && options[currentIndex]?.length > 0 ? (
          <DashboardDropdown
            options={options}
            selected={selected}
            onClick={(index) => {
              setCurrentIndex(index);
            }}
          />
        ) : (
          ''
        )}
      </>
    );
  }, [currentIndex, options]);

  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>
          {props.title} {dropdown}
        </CardHeaderTitle>
      </CardHeader>
      {fields && fields?.length > 0 ? (
        <CardBody>
          {props.beforeContent && (
            <div className="mb-4">{props.beforeContent}</div>
          )}
          <div style={{ minHeight: '40vh' }}>
            <TakeawaysBreakdownChart
              barChartValueFieldName={breakdownType}
              data={props.data[fields[currentIndex]]}
              lineChartValueFieldName="tenure"
              barChartValueFieldLabel={formatMessage({
                id: 'app.views.widgets.cards.breakdown_chart_card.bar_chart_value_field_label',
                defaultMessage: 'Tenure',
              })}
              color={props.color}
              lineColor={props.tenureColor}
              xAxisLabel={fields[currentIndex]}
            />
          </div>
          {props.afterContent && (
            <div className="mt-4">{props.afterContent}</div>
          )}
        </CardBody>
      ) : (
        // @ts-expect-error
        <EmptyState title={emptyStateText} />
      )}
    </Card>
  );
};

const BreakdownChartCard_propTypes = {
  className: PropTypes.object,
  color: PropTypes.string,
  tenureColor: PropTypes.string,
  data: PropTypes.object.isRequired,
  emptyStateText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  breakdownType: PropTypes.string.isRequired,
  beforeContent: PropTypes.node,
  afterContent: PropTypes.node,
};

type Props = PropTypes.InferProps<typeof BreakdownChartCard_propTypes>;

export default React.memo(BreakdownChartCard);
