import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback } from 'react';
import {
  WIZARD_TYPES_LIST,
  getWizardTypeDisplayName,
} from '../../utils/models/Cycle';
import {
  removeUICampaignPhase,
  setObviousPhaseDefaults,
} from './CampaignFlow/common';

import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import PropTypes from 'prop-types';
import SafeUncontrolledPopover from '../../components/SafeUncontrolledPopover';
import ValidatedWizardForm from '../Widgets/Forms/ValidatedWizardForm';
import { WIZARD_TYPE_KEY } from '../../utils/models/Performance';
import { YYYY_MM_DD_WITH_DASHES_FORMAT } from '../Widgets/Inputs/DatePicker';
import { connect } from 'react-redux';
import { moveUnusedPhasesToConfigs } from './CampaignFlow';
import { toast } from 'utils/deduped-toast/toast';

const getCol6 = (element) => {
  return <Col className="col-12 col-md-6">{element}</Col>;
};

const CampaignBasics: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const campaign = props.campaign;

  const transformObjectBeforeSubmit = useCallback(
    (object) =>
      setObviousPhaseDefaults(
        removeUICampaignPhase(moveUnusedPhasesToConfigs(object)),
        // @ts-expect-error
        props.currentOrganization.name,
        formatMessage
      ),
    [
      // @ts-expect-error
      props.currentOrganization.name,
      formatMessage,
    ]
  );

  return (
    <>
      <ValidatedWizardForm
        method={'PATCH'}
        url="/campaigns"
        object={campaign}
        // @ts-expect-error
        setHasUnsavedChanges={props.setHasUnsavedChanges}
        // @ts-expect-error
        onGoBack={props.onGoBack}
        // only use save and continue, not save, given how simple the page is
        // so won't require reviewing further after saving
        onSaveAndContinue={props.onSaveAndContinue}
        transformObjectBeforeSubmit={transformObjectBeforeSubmit}
        // @ts-expect-error
        renderInputs={(inputs) => <Row>{inputs}</Row>}
        inputs={[
          {
            name: 'name',
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.label.name"
                defaultMessage="Name"
              />
            ),
            required: true,
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.helper_text.name"
                defaultMessage="Your cycle's display name"
              />
            ),
            wrapperFunction: getCol6,
          },
          {
            name: WIZARD_TYPE_KEY,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.label.cycle_type"
                defaultMessage="Type"
              />
            ),
            required: true,
            type: INPUT_TYPES.DROPDOWN,
            objects: WIZARD_TYPES_LIST(formatMessage),
            defaultValue: WIZARD_TYPES_LIST(formatMessage)[0].id,
            onChangeSideEffects: (currentObject, newValue, intl) => {
              toast.warning(
                intl.formatMessage(
                  {
                    id: 'app.views.administration.campaign_basics.change_type_warning',
                    defaultMessage:
                      'Since you chaged the cycle type from {previousType} to {newType}, once you click save and continue some configuration will be reset to their default values.',
                  },
                  {
                    previousType: getWizardTypeDisplayName(
                      currentObject.wizard_type,
                      intl.formatMessage
                    ),
                    newType: getWizardTypeDisplayName(
                      newValue,
                      intl.formatMessage
                    ),
                  }
                )
              );
            },
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.helper_text.cycle_type"
                defaultMessage="What kind of cycle is this"
              />
            ),
            renderItem: (o) => (
              <>
                <span id={`wizard-type-dropdown-help-${o.id}`} className="pe-1">
                  {o.name}
                </span>
                <SafeUncontrolledPopover
                  target={`wizard-type-dropdown-help-${o.id}`}
                  trigger="hover click"
                  placement="left"
                >
                  {o.help}
                </SafeUncontrolledPopover>
              </>
            ),
            wrapperFunction: getCol6,
          },
          {
            name: 'coverage_start_date',
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.label.coverage_start_date"
                defaultMessage="Evaluation start date"
              />
            ),
            type: INPUT_TYPES.DATE_PICKER,
            format: YYYY_MM_DD_WITH_DASHES_FORMAT,
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.helper_text.coverage_start_date"
                defaultMessage="Start of the time frame people should keep in mind when completing the cycle questions."
              />
            ),
            wrapperFunction: getCol6,
          },
          {
            name: 'coverage_end_date',
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.label.coverage_end_date"
                defaultMessage="Evaluation end date"
              />
            ),
            type: INPUT_TYPES.DATE_PICKER,
            format: YYYY_MM_DD_WITH_DASHES_FORMAT,
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_basics.helper_text.coverage_end_date"
                defaultMessage="End of the time frame people should keep in mind when completing the cycle questions."
              />
            ),
            wrapperFunction: getCol6,
          },
        ]}
      />
    </>
  );
};

const CampaignBasics_propTypes = {
  campaign: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndContinue: PropTypes.func.isRequired,
  onGoBack: PropTypes.func,
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  features: PropTypes.object.isRequired,
  setHasUnsavedChanges: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof CampaignBasics_propTypes>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(CampaignBasics));
