import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import GroupResumeCreationImg from '../../assets/img/illustrations/group-resume-creation.png';

interface Props {
  videoId: string;
  description?: string;
}

const VideoEmbed: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const [hasError, setHasError] = React.useState(false);

  const handleError = () => {
    setHasError(true);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;

    document.body.appendChild(script);

    // Cleanup the script tag when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (hasError) {
    // fallback image if video fails to load for some reason
    console.error('Video failed to load, falling back to image');
    return <img src={GroupResumeCreationImg} className="w-100" />;
  }

  return (
    <div>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src={`https://player.vimeo.com/video/${props.videoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          allow="autoplay; fullscreen; picture-in-picture"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          title={formatMessage({
            id: 'app.views.widgets.video_embed.title.video_title',
            defaultMessage: 'Performance Reviews With Confirm: What to Expect',
          })}
          onError={handleError}
        ></iframe>
      </div>
      {props.description && (
        <div className="text-center text-muted mt-3">{props.description}</div>
      )}
    </div>
  );
};

export default VideoEmbed;
