import { RatingScaleItem } from './HistoryChartUtils';

export const compareRatingScales = (
  scaleA: RatingScaleItem[],
  scaleB: RatingScaleItem[]
): boolean => {
  if (scaleA.length !== scaleB.length) return false;
  // we assume both scales are sorted
  // we try to find the first element that differs
  const diff = scaleA.find((item, idx) => item.value !== scaleB[idx].value);
  // if no item differs, they are the same
  return diff === undefined;
};
