import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';

import { Button } from 'reactstrap';
import ConfirmationDialogModal from '../Modals/ConfirmationDialogModal';
import Media from '../../../components/media/Media';
import MediaUploader from './MediaUploader';

interface Props {
  onChange: (value?: string) => void;
  value: string;
}

const MediaUploaderInput = ({ value, onChange }: Props) => {
  const { formatMessage } = useIntl();
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const handleClose = useCallback(() => {
    setConfirmDeleteModalOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    setConfirmDeleteModalOpen(!confirmDeleteModalOpen);
  }, [confirmDeleteModalOpen]);

  const handleCallback = useCallback(() => {
    onChange(undefined);
    setConfirmDeleteModalOpen(false);
  }, [onChange]);

  const handleOpen = useCallback(() => {
    setConfirmDeleteModalOpen(true);
  }, []);

  return (
    <>
      {value && <Media link={value} />}
      {!value && (
        <MediaUploader
          onUpload={(file) => onChange(file.link)}
          callToAction={formatMessage({
            id: 'app.views.widgets.inputs.media_uploader_input.call_to_action',
            defaultMessage: 'Add image or video',
          })}
        />
      )}
      {value && (
        <Button className="btn btn-white" onClick={handleOpen}>
          <FormattedMessage
            id="app.views.widgets.inputs.media_uploader_input.delete"
            defaultMessage="Delete"
          />
        </Button>
      )}
      <ConfirmationDialogModal
        isOpen={confirmDeleteModalOpen}
        onClosed={handleClose}
        toggle={handleToggle}
        confirmCallback={handleCallback}
        title={formatMessage({
          id: 'app.views.widgets.inputs.media_uploader_input.modal.title.delete_media',
          defaultMessage: 'Delete media',
        })}
        description={formatMessage({
          id: 'app.views.widgets.inputs.media_uploader_input.modal.description.delete_media',
          defaultMessage: 'Are you sure that you want to delete this media?',
        })}
        confirmText={formatMessage({
          id: 'app.views.widgets.inputs.media_uploader_input.modal.confirm_text.delete_media',
          defaultMessage: 'Delete',
        })}
      />
    </>
  );
};

export default MediaUploaderInput;
