import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface Props {
  breadcrumbs: { name: string; path: string }[];
}

const Breadcrumbs: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  // Don't render a single breadcrumb.
  if (props.breadcrumbs.length <= 1) {
    return null;
  }

  // Link back to any previous steps of the breadcrumb
  return (
    <nav
      aria-label={formatMessage({
        id: 'app.views.layout.breadcrumbs.aria_label.breadcrumb',
        defaultMessage: 'breadcrumb',
      })}
    >
      <ol className="breadcrumb">
        {props.breadcrumbs.map(({ name, path }, key) =>
          key + 1 === props.breadcrumbs.length ? (
            <li
              key={key}
              className="breadcrumb-item active"
              aria-current="page"
            >
              {name}
            </li>
          ) : (
            <li key={key} className="breadcrumb-item">
              <Link key={key} to={path}>
                {name}
              </Link>
            </li>
          )
        )}
      </ol>
    </nav>
  );
};

export default React.memo(Breadcrumbs);
