export const getIntlStrings = (formatMessage) => ({
  Segment: formatMessage({
    id: 'app.takeaways_dashboard.segment',
    defaultMessage: 'Segment',
  }),
  Theme: formatMessage({
    id: 'app.takeaways_dashboard.theme',
    defaultMessage: 'Theme',
  }),
  Summary: formatMessage({
    id: 'app.takeaways_dashboard.summary',
    defaultMessage: 'Summary',
  }),
  Description: formatMessage({
    id: 'app.takeaways_dashboard.description',
    defaultMessage: 'Description',
  }),
  TopQuote: formatMessage({
    id: 'app.takeaways_dashboard.top_quote',
    defaultMessage: 'Top quote',
  }),
  RelatedComments: formatMessage({
    id: 'app.takeaways_dashboard.related_comments',
    defaultMessage: 'Related comments',
  }),
  promoter: formatMessage({
    id: 'app.takeaways_dashboard.promoter',
    defaultMessage: 'Promoter',
  }),
  detractor: formatMessage({
    id: 'app.takeaways_dashboard.detractor',
    defaultMessage: 'Detractor',
  }),
  passive: formatMessage({
    id: 'app.takeaways_dashboard.passive',
    defaultMessage: 'Passive',
  }),
  ThemeDetails: formatMessage({
    id: 'app.takeaways_dashboard.theme_details',
    defaultMessage: 'Theme details',
  }),
  Name: formatMessage({
    id: 'app.takeaways_dashboard.name',
    defaultMessage: 'Name',
  }),
  TopQuotes: formatMessage({
    id: 'app.takeaways_dashboard.top_quotes',
    defaultMessage: 'Top quotes',
  }),
  ShowRelatedComments: formatMessage({
    id: 'app.takeaways_dashboard.show_related_comments',
    defaultMessage: 'Show related comments',
  }),
});
