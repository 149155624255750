import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import PropTypes from 'prop-types';
import TagCloudCard from './TagCloudCard';
import { useIntl } from 'react-intl';

const SkillCloudsCard: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const propsPositiveLabels = props.positiveLabels ?? [
    formatMessage({
      id: 'widgets.cards.skill_clouds_card.positive_labels.strength_skills',
      defaultMessage: 'Strength skills',
    }),
    formatMessage({
      id: 'widgets.cards.skill_clouds_card.positive_labels.strength_behaviors',
      defaultMessage: 'Strength behaviors',
    }),
  ];
  const propsNegativeLabels = props.negativeLabels ?? [
    formatMessage({
      id: 'widgets.cards.skill_clouds_card.negative_labels.growth_skills',
      defaultMessage: 'Growth skills',
    }),
    formatMessage({
      id: 'widgets.cards.skill_clouds_card.negative_labels.growth_behaviors',
      defaultMessage: 'Growth behaviors',
    }),
  ];
  const havePositiveSkills = useMemo(
    // @ts-expect-error
    () => !!(props?.data[0] && Object.keys(props.data[0][0]).length),
    [props?.data]
  );
  const haveNegativeSkills = useMemo(
    // @ts-expect-error
    () => !!(props?.data[1] && Object.keys(props.data[1][0]).length),
    [props?.data]
  );
  const havePositiveBehaviors = useMemo(
    // @ts-expect-error
    () => !!(props?.data[0] && Object.keys(props.data[0][1]).length),
    [props?.data]
  );
  const haveNegativeBehaviors = useMemo(
    // @ts-expect-error
    () => !!(props?.data[1] && Object.keys(props.data[1][1]).length),
    [props?.data]
  );

  if (
    !havePositiveSkills &&
    !havePositiveBehaviors &&
    !haveNegativeSkills &&
    !haveNegativeBehaviors
  ) {
    return <></>;
  }

  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {/* @ts-expect-error */}
        {props.beforeContent}
        <Row>
          {havePositiveSkills && (
            <Col className="col-12 col-md-6">
              <TagCloudCard
                // @ts-expect-error
                title={propsPositiveLabels[0]}
                // @ts-expect-error
                tags={props?.data[0] ? props.data[0][0] : undefined}
                // @ts-expect-error
                skillOwners={props.skillOwners.positive}
              />
            </Col>
          )}
          {haveNegativeSkills && (
            <Col className="col-12 col-md-6">
              <TagCloudCard
                // @ts-expect-error
                title={propsNegativeLabels[0]}
                // @ts-expect-error
                tags={props?.data[1] ? props.data[1][0] : undefined}
                // @ts-expect-error
                skillOwners={props.skillOwners.negative}
              />
            </Col>
          )}
          {havePositiveBehaviors && (
            <Col className="col-12 col-md-6">
              <TagCloudCard
                // @ts-expect-error
                title={propsPositiveLabels[1]}
                // @ts-expect-error
                tags={props?.data[0] ? props.data[0][1] : undefined}
                // @ts-expect-error
                skillOwners={props.skillOwners.positive}
              />
            </Col>
          )}
          {haveNegativeBehaviors && (
            <Col className="col-12 col-md-6">
              <TagCloudCard
                // @ts-expect-error
                title={propsNegativeLabels[1]}
                // @ts-expect-error
                tags={props?.data[1] ? props.data[1][1] : undefined}
                // @ts-expect-error
                skillOwners={props.skillOwners.negative}
              />
            </Col>
          )}
        </Row>
        {/* @ts-expect-error */}
        {props.afterContent}
      </CardBody>
    </Card>
  );
};

const SkillCloudsCard_propTypes = {
  className: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  positiveLabels: PropTypes.arrayOf(PropTypes.string),
  negativeLabels: PropTypes.arrayOf(PropTypes.string),
  skillOwners: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof SkillCloudsCard_propTypes>;

export default React.memo(SkillCloudsCard);
