import { Card, CardBody, CardHeader } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import WordCloud from '../WordCloud';

// assign "size" to different words in this list based on their percentile
// WordCloud basically takes "count" to be === "size", assigning h1 to count=5, h2 to count=4 and so forth.
//
// 1. sort
// 2. count total words
// 3. start at beginning
//    a. make list of all words with same count
//    b. find current percentile given current list and words added so far to retval
//    c. set "count" appropriately
//       100-98 == 5, 98-94 == 4, 94-86 == 3, 86-70 == 2, otherwise 1
//    d. add to retval
//    e. if over "limit", stop
const processTags = (tags, limit) => {
  let retval = [];
  let currentWordCountValue = -1;
  let currentWordList = [];
  const totalWordCount = tags.length;
  tags
    .sort((a, b) => parseInt(b.wordObject.count) - parseInt(a.wordObject.count))
    .forEach((tag) => {
      if (retval.length > limit) {
        return;
      }
      if (currentWordList.length === 0) {
        currentWordCountValue = tag.wordObject.count;
      }
      if (currentWordCountValue === tag.wordObject.count) {
        // @ts-expect-error
        currentWordList.push(tag);
        return;
      } else {
        const remainingWords =
          totalWordCount - (retval.length + currentWordList.length);
        const currentPercentile = remainingWords / totalWordCount;
        const tagSize =
          currentPercentile >= 0.98
            ? 5
            : currentPercentile >= 0.94
            ? 4
            : currentPercentile >= 0.86
            ? 3
            : currentPercentile >= 0.7
            ? 2
            : 1;
        // @ts-expect-error
        currentWordList.forEach((w) => (w.wordObject.size = tagSize));
        retval = retval.concat(currentWordList);
        // @ts-expect-error
        currentWordList = [tag];
        currentWordCountValue = tag.wordObject.count;
      }
    });
  if (retval.length < limit && currentWordList.length > 0) {
    // @ts-expect-error
    currentWordList.forEach((w) => (w.wordObject.size = 1));
    retval = retval.concat(currentWordList);
  }
  retval.forEach((w) => {
    // @ts-expect-error
    w.wordObject.rawCount = w.wordObject.count;
    // @ts-expect-error
    w.wordObject.count = w.wordObject.size;
  });
  return retval;
};

const TagCloudCard: FC<Props> = ({
  noun = 'person',
  nounPlural = 'people',
  limit = 50,
  tags: propsTags = [],
  ...props
}) => {
  const tags = useMemo(
    () =>
      // @ts-expect-error
      Object.entries(propsTags).map(([tag, count]) => ({
        wordObject: {
          id: tag,
          name: tag,
          count,
        },
      })),
    [propsTags]
  );

  const processedTags = useMemo(() => processTags(tags, limit), [limit, tags]);

  return (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        {/* @ts-expect-error */}
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      {tags ? (
        <CardBody>
          <WordCloud
            className="text-center"
            emptyText=""
            pathPrefix="/skills"
            objects={processedTags}
            noun={noun}
            nounPlural={nounPlural}
            // @ts-expect-error
            skillOwners={props.skillOwners}
          />
        </CardBody>
      ) : (
        // @ts-expect-error
        <EmptyState title={props.emptyStateText} />
      )}
    </Card>
  );
};

const TagCloudCard_propTypes = {
  noun: PropTypes.string,
  nounPlural: PropTypes.string,
  limit: PropTypes.number,
  tags: PropTypes.object,
  skillOwners: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof TagCloudCard_propTypes>;

export default React.memo(TagCloudCard);
