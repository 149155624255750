import { Col, Row } from 'reactstrap';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';
import {
  PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT,
  getCampaignHasFeatureEnabled,
  getCampaignRatings,
} from '../../../../utils/models/Performance';
import React, { FC, useMemo } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ConfirmIntlProvider } from '../../../../locale/ConfirmIntlContext';
import { ManagerComments } from './ManagerComments';
import { PERFORMANCE } from '../../../../consts/consts';
import PersonCard from '../../../Widgets/Cards/PersonCard';
import { PrivateDataBadge } from '../../components/PrivateDataBadge';

interface TooltipCardProps {
  data: any;
  type?: string;
  previousData: any;
  callback: any;
  managerView: boolean;
  formatMessage: IntlShape['formatMessage'];
}

export const TooltipCard: FC<TooltipCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const hideRatingFromDirectReports = props?.data?.campaign
    ? getCampaignHasFeatureEnabled(
        props?.data?.campaign,
        PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT
      )
    : true;

  const scale = useMemo(
    () =>
      props?.data?.campaign ? getCampaignRatings(props?.data?.campaign) : [],
    [props?.data?.campaign]
  );

  const ratingName = scale.find(
    (x) => x.value === props?.data?.rating?.value
  )?.name;

  return (
    <ConfirmIntlProvider>
      <PersonCard
        size="sm"
        hideAvatar={!props.type}
        // @ts-expect-error
        person={
          props.type
            ? {
                id: props?.data?.id,
                url: props?.data?.url,
                avatar: props?.data?.avatar,
                full_name: formatMessage(
                  {
                    id: 'app.views.person.person_performance.trajectory.history_chart_tooltip.full_name.cycle',
                    defaultMessage: 'Cycle: {campaignName}',
                  },
                  { campaignName: props?.data?.campaign?.name ?? '' }
                ),
                title: formatMessage(
                  {
                    id: 'app.views.person.person_performance.trajectory.history_chart_tooltip.title',
                    defaultMessage:
                      '{title} {level, select, empty { } other {(level)}}',
                  },
                  {
                    title: props?.data?.title ?? '',
                    level: props?.data?.level ?? 'empty',
                  }
                ),
                status: props?.data?.status,
              }
            : {
                id: props?.data?.id,
                url: null,
                avatar: null,
                full_name: formatMessage(
                  {
                    id: 'app.views.person.person_performance.trajectory.history_chart_tooltip.full_name.cycle',
                    defaultMessage: 'Cycle: {campaignName}',
                  },
                  { campaignName: props?.data?.campaign?.name ?? '' }
                ),
                title: props?.data?.title,
                status: props?.data?.status,
              }
        }
        hidePopover
        bodyOnly
        linked
        isExternalUrl
        urlSuffix={PERFORMANCE().path + '?manager=true'}
      >
        {props.type && (
          <div className="mt-3">
            {props?.data?.department && (
              <Row>
                <Col className="col-auto">
                  <span className="me-2 fe fe-archive text-muted"></span>
                  {props.formatMessage(
                    {
                      id: 'app.views.person.person_performance.trajectory.history_chart_tooltip.department',
                      defaultMessage: '<bold>{department}</bold> department',
                    },
                    {
                      department: props.data.department,
                      bold: (chunks) => (
                        <span className="fw-bold">{chunks}</span>
                      ),
                    }
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col className="col-auto">
                <span className="me-2 fe fe-calendar text-muted"></span>
                <FormattedMessage
                  id="app.views.person.person_performance.trajectory.history_chart_tooltip.months_tenure"
                  defaultMessage="<bold>{count}</bold> months tenure"
                  values={{
                    count: Math.round(props?.data?.tenure_months),
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </Col>
            </Row>
            {(!hideRatingFromDirectReports || props.managerView) && (
              <Row>
                <Col className="col-auto">
                  <span className="me-2 fe fe-edit text-info"></span>
                  <span className="fw-bold">
                    {ratingName ??
                      props.formatMessage({
                        id: 'app.views.person.person_performance.trajectory.history_chart_tooltip.not_available',
                        defaultMessage: 'N/A',
                      })}
                  </span>{' '}
                  <FormattedMessage
                    id="app.views.person.person_performance.trajectory.history_chart_tooltip.rating"
                    defaultMessage="rating"
                  />{' '}
                  {hideRatingFromDirectReports && props.managerView ? (
                    <PrivateDataBadge />
                  ) : null}
                </Col>
              </Row>
            )}
            {props.data?.rating?.from_person && (
              <Row>
                <Col className="col-auto mt-3">
                  <ManagerComments
                    author={props.data?.rating?.from_person}
                    comments={props.data?.rating?.rating_comments}
                  />
                </Col>
              </Row>
            )}
          </div>
        )}
      </PersonCard>
    </ConfirmIntlProvider>
  );
};

interface PerformanceHistoryTooltipProps {
  data: any;
  previousData: any;
  type: string;
  content: any;
}

const PerformanceHistoryTooltip: FC<PerformanceHistoryTooltipProps> = (
  props
) => (
  <div
    style={{
      width: '350px', // making large enough that 3 digit months tenures don't overflow to second line
      borderRadius: '10px',
      border: '1px solid lightGray',
      backgroundColor: 'white',
      padding: '20px',
    }}
  >
    <ConfirmIntlProvider>
      <BrowserRouter>
        {props.type && props.data?.titleChange && (
          <div className="text-primary mb-3">
            <i className="fe fe-alert-circle" />
            <span className="fw-bold ms-2">
              <FormattedMessage
                id="app.views.person.person_performance.trajectory.history_chart_tooltip.title_change"
                defaultMessage="Title change: {previousTitle} {previousLevel, select, empty { } other {(level)}} -> {title} {level, select, empty { } other {(level)}}"
                values={{
                  previousTitle: props?.previousData?.title,
                  previousLevel: props?.previousData?.level ?? 'empty',
                  title: props?.data?.title,
                  level: props?.data?.level ?? 'empty',
                }}
              />
            </span>
          </div>
        )}
        {props.content}
      </BrowserRouter>
    </ConfirmIntlProvider>
  </div>
);

export default PerformanceHistoryTooltip;
