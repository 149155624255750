import React, { FC } from 'react';
import {
  TalentMatrixCampaignModel,
  useTalentMatrixConfiguration,
} from '../TalentMatrix';

import Editor from './Editor';
import Loading from 'views/Widgets/Loading';
import { useCampaignEditorPhase } from 'views/Administration/CampaignEditorPhaseContext';
import { useIntl } from 'react-intl';

interface Props {
  disabled: boolean;
  onCallback: (value: TalentMatrixCampaignModel) => void;
  value: TalentMatrixCampaignModel;
}

const GridEditorWrapper: FC<Props> = ({ disabled, onCallback, value }) => {
  const { formatMessage } = useIntl();
  const campaignContext = useCampaignEditorPhase();

  const { status, data } = useTalentMatrixConfiguration({
    campaignId: campaignContext?.campaignId,
    disabled,
  });

  return (
    <>
      {status === 'LOADING' ||
        (!data && (
          <Loading
            message={formatMessage({
              id: 'app.views.talent_matrix.talent_matrix_grid_editor_wrapper.message.fetching_campaign_data',
              defaultMessage: 'Fetching campaign data',
            })}
          />
        ))}
      {status === 'SUCCESS' && !!data && (
        <Editor
          availableMetrics={data.available_metrics}
          onCallback={onCallback}
          value={value}
        />
      )}
    </>
  );
};
export default GridEditorWrapper;
