import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import Avatar from './People/Avatar';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import RelativeTime from './RelativeTime';
import RichTextViewer from './Inputs/RichTextViewer';
import { getCommentTypeText } from '../../utils/models/Comment';

const CommentsLog: FC<Props> = ({
  className = '',
  title = 'Comments',
  emptyStateText = 'No comments provided',
  bodyStyle = {},
  ...props
}) => {
  const { formatMessage } = useIntl();
  return (
    // @ts-expect-error
    <Card className={className}>
      {title && (
        <CardHeader>
          <CardHeaderTitle>{title}</CardHeaderTitle>
        </CardHeader>
      )}
      {/* @ts-expect-error */}
      <CardBody style={bodyStyle}>
        {/* @ts-expect-error */}
        {!(props.comments?.length > 0) && (
          <span className="fst-italic">{emptyStateText}</span>
        )}
        {/* @ts-expect-error */}
        {props.comments.map((c, index) => {
          // @ts-expect-error
          const author = c.author_person;
          // @ts-expect-error
          const onBehalfOf = c.on_behalf_of_person;
          // @ts-expect-error
          const typeText = getCommentTypeText(c.type, formatMessage);

          return (
            <Row key={index} className={index === 0 ? '' : 'pt-3'}>
              <Col className="col-auto">
                <Avatar
                  person={author}
                  size="xs"
                  className="mt-2"
                  linked
                  isExternalUrl={true}
                />
              </Col>
              <Col style={{ marginLeft: '-1rem' }}>
                <Row>
                  <Col>
                    <div
                      className={'comment-body d-block px-3'}
                      style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                    >
                      <Row>
                        <Col>
                          <span className="fw-bold small">
                            <FormattedMessage
                              id="app.views.widgets.comments_log.comment_by"
                              defaultMessage="{authorLink}{hasTypeText, select, true { <normal>{typeText}</normal>} other {} }{hasOnBehalfOf, select, true { <normal>on behalf of {behalfPerson}</normal> } other {} } <muted>{relativeTime}</muted>{hastTypeTextOrOnBehalfOf, select, true {.} other {} }"
                              values={{
                                authorLink: (
                                  <Link
                                    style={{ color: 'inherit' }}
                                    to={author?.url}
                                    target={
                                      props.isExternalUrl ? '_blank' : undefined
                                    }
                                    rel="noopener noreferrer"
                                  >
                                    {author?.full_name}
                                  </Link>
                                ),
                                normal: (chunks) => (
                                  <span className="fw-normal">{chunks}</span>
                                ),
                                muted: (chunks) => (
                                  <span className="small text-muted">
                                    {chunks}
                                  </span>
                                ),
                                typeText,
                                hasTypeText: !!typeText,
                                hasOnBehalfOf: !!onBehalfOf,
                                behalfPerson: (
                                  <span className="fw-bold">
                                    <Link
                                      style={{ color: 'inherit' }}
                                      to={onBehalfOf?.url}
                                      target={
                                        props.isExternalUrl
                                          ? '_blank'
                                          : undefined
                                      }
                                      rel="noopener noreferrer"
                                    >
                                      {onBehalfOf?.full_name}
                                    </Link>
                                  </span>
                                ),
                                hastTypeTextOrOnBehalfOf:
                                  !!typeText || !!onBehalfOf,
                                relativeTime: (
                                  <RelativeTime
                                    unit="day"
                                    // @ts-expect-error
                                    datetime={c.created_at}
                                  />
                                ),
                              }}
                            />
                          </span>
                        </Col>
                      </Row>
                      <div className={'mb-0'}>
                        {/* @ts-expect-error */}

                        {c.body && (
                          // @ts-expect-error
                          <RichTextViewer model={c.body} expanded={true} />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </CardBody>
    </Card>
  );
};

const CommentsLog_propTypes = {
  title: PropTypes.string,
  emptyStateText: PropTypes.string,
  isRichText: PropTypes.bool.isRequired,
  isExternalUrl: PropTypes.bool,
  comments: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  bodyStyle: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof CommentsLog_propTypes>;

export default React.memo(CommentsLog);
