import { useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import PeopleEditor from '../../Forms/PeopleEditor';
import { Person, Campaign, Relationship } from '../../../../types';
import { useEscape, useOutsideClick } from '../../../../utils/util/hooks';
import SafeUncontrolledPopover from '../../../../components/SafeUncontrolledPopover';
import { toast } from 'react-toastify';
import {
  PERFORMANCE_FEATURE_MAXIMUM_PEERS_TO_SHOW,
  getCampaignFeature,
} from 'utils/models/Performance';
import { RELATIONSHIP_TYPES } from 'utils/models/RelationshipUtils';

export type TeamDashboardEditPeersModalType = 'incoming' | 'outgoing';

interface TeamDashboardPeersPopoverProps {
  campaign?: Campaign;
  relationships: Relationship[];
  peers?: Person[];
  title: string;
  toggle: () => void;
  onSave: (peers: Person[]) => void;
  onClose: () => void;
  target: React.RefObject<HTMLElement>;
  isOpen: boolean;
  targetPersonId: number;
  type: TeamDashboardEditPeersModalType;
  dataset_people: any[];
}

const TeamDashboardPeersPopover: FC<TeamDashboardPeersPopoverProps> = ({
  peers,
  title,
  toggle,
  onSave,
  onClose,
  target,
  isOpen,
  targetPersonId,
  campaign,
  relationships,
  type,
  dataset_people,
}) => {
  const { formatMessage } = useIntl();
  const [people, setPeople] = useState(peers || []);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const targetPerson = useMemo(
    () => dataset_people.find((p) => p.id === targetPersonId),
    [dataset_people, targetPersonId]
  );

  const popoverRef = useRef<HTMLDivElement>(null);

  useEscape(() => onClose());

  const handleInputChange = useCallback((value) => {
    setIsEditing(!!value);
  }, []);

  useOutsideClick(popoverRef, () => {
    if (!isEditing) {
      toggle();
    }
  });

  const nonParticipatingPeople = useMemo(() => {
    const p =
      type === 'incoming'
        ? (dataset_people || []).filter(
            (person) => person.is_writing_peer_reviews === false
          )
        : (dataset_people || []).filter(
            (person) => person.is_receiving_peer_reviews === false
          );

    return p.map((person) => ({ id: person.id }));
  }, [dataset_people, type]);

  const maxPeers = useMemo(() => {
    if (!campaign) return 5;
    const campaignMaxPeers = getCampaignFeature(
      campaign,
      PERFORMANCE_FEATURE_MAXIMUM_PEERS_TO_SHOW
    );
    return campaignMaxPeers ?? 5;
  }, [campaign]);

  const countRelationships = useCallback(
    (selectedPersonId) => {
      const from_person_id =
        type === 'outgoing' ? targetPersonId : selectedPersonId;
      const list = relationships.filter(
        (relationship) =>
          // @ts-expect-error
          !!relationship.dataset &&
          relationship.type ===
            RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR &&
          relationship.from_person.id === from_person_id
      );
      return list.length;
    },
    [relationships, targetPersonId, type]
  );

  const handleCallback = useCallback(
    (value) => {
      setPeople(value);
      onSave(value);
    },
    [onSave]
  );

  const excludeListErrorMessageFunction = useCallback(
    (tag) => {
      if (tag.object.id === targetPersonId) {
        return formatMessage({
          id: 'app.views.widgets.dashboards.teamdashboard.teamdashboardpeerspopover.people_editor.exclude_list_message',
          defaultMessage: 'The peers cannot include the receiving person.',
        });
      } else if (people.find((p) => p.id === tag.object.id)) {
        return formatMessage(
          {
            id: 'app.views.widgets.dashboards.teamdashboard.teamdashboardpeerspopover.people_editor.duplicate_message',
            defaultMessage: '{name} is already a peer.',
          },
          { name: tag.object.full_name }
        );
      } else {
        return formatMessage(
          {
            id: 'app.views.widgets.dashboards.teamdashboard.teamdashboardpeerspopover.people_editor.not_participating_message',
            defaultMessage:
              '{name} is not participating in the peer review phase.',
          },
          { name: tag.object.full_name }
        );
      }
    },
    [formatMessage, targetPersonId, people]
  );

  const onBeforeChange = useCallback(
    (action, target) => {
      const personId = type === 'incoming' ? target.object.id : targetPersonId;
      if (action === 'add' && countRelationships(personId) >= maxPeers) {
        const full_name =
          type === 'incoming'
            ? target.object.full_name
            : targetPerson.full_name;

        toast.warning(
          formatMessage(
            {
              id: 'app.views.widgets.dashboards.teamdashboard.teamdashboardpeerspopover.people_editor.max_peers',
              defaultMessage:
                '{name} cannot provide feedback for more than {maxPeers} peers.',
            },
            { maxPeers, name: full_name }
          )
        );
        return false;
      } else if (action === 'delete' && target.object.is_completed) {
        toast.warning(
          formatMessage(
            {
              id: 'app.views.widgets.dashboards.teamdashboard.teamdashboardpeerspopover.people_editor.cannot_remove_peer',
              defaultMessage:
                '{name} has already completed the feedback for his peer. You cannot remove them.',
            },
            { name: target.object.full_name }
          )
        );
        return false;
      }
      return true;
    },
    [
      countRelationships,
      maxPeers,
      formatMessage,
      targetPerson,
      type,
      targetPersonId,
    ]
  );

  return (
    <SafeUncontrolledPopover
      target={target}
      delay={0}
      isOpen={isOpen}
      toggle={toggle}
    >
      <div
        ref={popoverRef}
        role="dialog"
        title={title}
        style={{ minWidth: '300px' }}
      >
        <div className="fw-bold">{title}</div>
        <PeopleEditor
          className={'mx-2'}
          allowSelf={true}
          placeholder={formatMessage({
            id: 'app.views.widgets.dashboards.teamdashboard.teamdashboardpeerspopover.people_editor.placeholder',
            defaultMessage: 'Enter names',
          })}
          value={people}
          excludeList={[{ id: targetPersonId }, ...nonParticipatingPeople]}
          excludeListErrorMessageFunction={excludeListErrorMessageFunction}
          callback={handleCallback}
          onInputChange={handleInputChange}
          deliverEmptyInputChange={true}
          propagateOnClearInput={true}
          onBeforeChange={onBeforeChange}
        />
      </div>
    </SafeUncontrolledPopover>
  );
};

export default TeamDashboardPeersPopover;
