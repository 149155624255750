import React, { FC, useState } from 'react';

import { Dropdown } from 'reactstrap';

const UncontrolledHoverDropdown: FC = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      // prevent typing spacebar in a menu BELOW this dropdown
      // from closing the dropdown, e.g. in top right account
      // dropdown when proxying/viewing as and typing
      // space which would otherwise close this dropdown due to
      // accessibility defaults
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      onKeyUp={(e) => {
        e.preventDefault();
      }}
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
      {...props}
    />
  );
};

export default UncontrolledHoverDropdown;
