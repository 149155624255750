import { Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const PrivateDataBadge = () => (
  <Badge
    pill
    className="py-2 badge rounded-pill me-2 bg-warning-soft text-uppercase"
  >
    <i className="fe fe-lock" />
    <FormattedMessage
      id="app.views.shared_componentes.badges.private_data"
      defaultMessage="Private"
    />
  </Badge>
);
