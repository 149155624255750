import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ICONS, MY_TEAM_OBJECTIVES } from '../../consts/consts';
import React, { useMemo } from 'react';

import EmptyState from '../Widgets/EmptyState';
import { Link } from 'react-router-dom';
import { peopleIdsAreEqual } from '../../utils/models/Person';

const ObjectivesEmpty = ({
  person,
  me,
  isEditable,
  isInPopover,
  currentTimeframeText,
  objectivesForTimeframe,
  addObjective,
  showMiniEmptyStateCallToAction, // Reverse this boolean logic here
}) => {
  const isMe = useMemo(() => {
    return peopleIdsAreEqual(person?.id, me.id);
  }, [me.id, person?.id]);

  const { formatMessage } = useIntl();
  const output = (
    <>
      {isMe && showMiniEmptyStateCallToAction && (
        <Link to={MY_TEAM_OBJECTIVES(formatMessage).path}>
          <Row>
            <Col className="col-auto pe-0">
              <div
                className="avatar avatar-xs"
                style={{ position: 'relative', top: 1 }}
              >
                <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                  <i className={ICONS.OBJECTIVE}></i>
                </div>
              </div>
            </Col>
            <Col className="pt-1">
              <FormattedMessage
                id="app.views.person.person_objectives.me.emptyState"
                defaultMessage="Create objectives for this period"
              />
            </Col>
          </Row>
        </Link>
      )}
      {!isMe && showMiniEmptyStateCallToAction && (
        <Row>
          {!isInPopover && (
            <Col className="col-auto pe-0">
              <div
                className="avatar avatar-xs"
                style={{ position: 'relative', top: 1 }}
              >
                <div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                  <i className={ICONS.OBJECTIVE}></i>
                </div>
              </div>
            </Col>
          )}
          <Col className="pt-1">
            <FormattedMessage
              id="app.views.person.person_objectives.not_me.emptyState"
              defaultMessage="{personName} has not created any objectives for this period that are visible to you."
              values={{ personName: person.given_name }}
            />
          </Col>
        </Row>
      )}
      {!showMiniEmptyStateCallToAction && (
        <>
          {isEditable ? (
            <EmptyState
              title={
                objectivesForTimeframe?.length > 0
                  ? formatMessage(
                      {
                        id: 'app.views.person.person_objectives.emptyState.add_objective.existing.title',
                        defaultMessage: 'Add objectives for {quarterName}',
                      },
                      { quarterName: currentTimeframeText }
                    )
                  : formatMessage({
                      id: 'app.views.person.person_objectives.emptyState.add_objective.not_existing.title',
                      defaultMessage: 'Get started with objectives',
                    })
              }
              subtitle={
                isMe
                  ? formatMessage({
                      id: 'app.views.person.person_objectives.emptyState.add_objective.me.sub_title',
                      defaultMessage: 'Create objectives for yourself.',
                    })
                  : formatMessage(
                      {
                        id: 'app.views.person.person_objectives.emptyState.add_objective.not_me.sub_title',
                        defaultMessage: 'Create objectives for {personName}.',
                      },
                      { personName: person.given_name }
                    )
              }
            >
              <Button color="primary" onClick={addObjective}>
                <FormattedMessage
                  id="app.views.person.person_objectives.emptyState.add_objective.button.text"
                  defaultMessage="Add objective"
                />
              </Button>
            </EmptyState>
          ) : (
            <EmptyState
              title={formatMessage({
                id: 'app.views.person.person_objectives.emptyState.access_denied.title',
                defaultMessage: 'No objectives visible to you',
              })}
              subtitle={formatMessage(
                {
                  id: 'app.views.person.person_objectives.emptyState.access_denied.sub_title',
                  defaultMessage:
                    '{personName} has not created any objectives for this period that are visible to you.',
                },
                { personName: person.given_name }
              )}
            />
          )}
        </>
      )}
    </>
  );

  if (!isInPopover) {
    return (
      <Card>
        <CardBody>{output}</CardBody>
      </Card>
    );
  }

  return output;
};

export default ObjectivesEmpty;
