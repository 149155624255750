import React, { FC, PropsWithChildren } from 'react';

import { FormattedMessage } from 'react-intl';
import { UncontrolledPopover } from 'reactstrap';

interface Props {
  hide_from_recipient?: boolean;
}

const HideFromRecipientDescriptor: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const ref = React.createRef<HTMLSpanElement>();

  return props.hide_from_recipient ? (
    <div className="d-lg-flex flex-row">
      {children}
      <span ref={ref} className="fw-normal ms-0 ms-md-3 text-nowrap">
        <i className="fe fe-eye-off text-primary small align-middle" />{' '}
        <span className="text-primary small">
          <FormattedMessage
            id="app.views.widgets.inputs.hide_from_recipient_descriptor.private_question"
            defaultMessage="Private question"
          />
        </span>
      </span>
      <UncontrolledPopover placement="top" trigger="hover" target={ref}>
        <FormattedMessage
          id="app.views.widgets.inputs.hide_from_recipient_descriptor.person_being_evaluated"
          defaultMessage="The person being evaluated will not see the response to this question,
        but the person's manager and above and HR administrators will."
        />
      </UncontrolledPopover>
    </div>
  ) : (
    <>{children}</>
  );
};

export default HideFromRecipientDescriptor;
