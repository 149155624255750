import { Card, CardBody, CardHeader } from 'reactstrap';
import {
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PHASE_TYPE_EVALUATION,
  getCampaignHasFeatureEnabled,
  getCampaignRatings,
} from '../../../utils/models/Performance';
import {
  PERFORMANCE_METRICS,
  PERFORMANCE_METRIC_NAMES,
} from '../../../consts/consts';
import React, { FC, useMemo, useState } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import DashboardDropdown from '../Dropdowns/DashboardDropdown';
import PeopleEditor from '../Forms/PeopleEditor';
import PropTypes from 'prop-types';
import { SCATTERPLOT_POINT_COLORS } from '../../../utils/models/Takeaways';
import ScatterPlot from '../Charts/ScatterPlot';
import { getPhaseByType } from '../../../utils/models/Campaign';
import { useIntl } from 'react-intl';

const ScatterPlotCard: FC<Props> = ({
  colors = SCATTERPLOT_POINT_COLORS,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [field, setField] = useState(props.field);
  const [fieldIndex, setFieldIndex] = useState(0);

  const [metric, setMetric] = useState(props.metric);
  const [metricIndex, setMetricIndex] = useState(0);

  const [finder, setFinder] = useState([]);

  const fields = useMemo(() => Object.keys(props.data), [props.data]);

  const formatField = (str) => str.toLowerCase().replace(/_/g, ' ');

  const findLineByLeastSquares = (values_x, values_y) => {
    let sum_x = 0;
    let sum_y = 0;
    let sum_xy = 0;
    let sum_xx = 0;
    let count = 0;

    let x = 0;
    let y = 0;
    const values_length = values_x.length;

    for (let v = 0; v < values_length; v++) {
      x = values_x[v];
      y = values_y[v];
      sum_x += x;
      sum_y += y;
      sum_xx += x * x;
      sum_xy += x * y;
      count++;
    }

    const m =
      (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
    const b = sum_y / count - (m * sum_x) / count;
    const maxX = Math.max(...values_x);
    const maxY = maxX * m + b;

    return [
      { x: 0, y: b },
      { x: maxX, y: maxY },
    ];
  };

  const onChangeFilter = (e) => setFinder(e.map((p) => p.id));

  const fieldDropdown = useMemo(
    () =>
      fields.length ? (
        <DashboardDropdown
          options={fields.map((x, index) => [formatField(x), index])}
          selected={formatField(fields[fieldIndex])}
          onClick={(index) => {
            setFieldIndex(index);
            setField(fields[index]);
            setFinder([]);
          }}
        />
      ) : (
        <div />
      ),
    [fieldIndex, fields]
  );

  const metricDropdown = useMemo(
    () => (
      <DashboardDropdown
        options={PERFORMANCE_METRICS.map((x, index) => [formatField(x), index])}
        selected={formatField(PERFORMANCE_METRICS[metricIndex])}
        onClick={(index) => {
          setMetricIndex(index);
          setMetric(PERFORMANCE_METRICS[index]);
        }}
      />
    ),
    [metricIndex]
  );

  const campaignHasRatings = useMemo(
    () =>
      // @ts-expect-error
      !!getPhaseByType(props?.data?.campaign, PHASE_TYPE_EVALUATION) &&
      !getCampaignHasFeatureEnabled(
        // @ts-expect-error
        props?.data?.campaign,
        PERFORMANCE_FEATURE_HIDE_MANAGER_RATING
      ),
    [props?.data]
  );

  const getJitterBuffer = (max, threshold = 0.01) => {
    const maxChange = max * threshold;

    // The "*2 - 1" is designed to create a number between -1 and 1
    return (Math.random() * 2 - 1) * maxChange;
  };

  const preparedData = useMemo(() => {
    if (!fields.length) return props.data;
    // @ts-expect-error
    const campaign = props.data.campaign;
    // @ts-expect-error
    const data = props.data[field];

    const formatDatapoints = (data) => {
      // Calculate max for determining "jitter" value (to separate out
      // visually overlapping datapoints)
      const maxX = Math.max(...data.map((p) => p.tenure));
      const maxY = Math.max(
        // @ts-expect-error
        ...data.map((p) => p[`${metric}__stats`]?.average || p[metric])
      );

      return data.map((p) => ({
        x: Math.max(0, p.tenure + getJitterBuffer(maxX)).toFixed(1),
        y: Math.max(
          0,
          // @ts-expect-error
          (p[`${metric}__stats`]?.average || p[metric]) + getJitterBuffer(maxY)
        ).toFixed(1),
        rating: p.rating,
      }));
    };

    const datapoints = formatDatapoints(data);

    const trendlineEndpoints = findLineByLeastSquares(
      datapoints.map((p) => +p.x),
      datapoints.map((p) => +p.y)
    );

    const calculateLimits = (campaign) => {
      const ratings = getCampaignRatings(campaign);
      const ratingsValues = ratings.map((rating) => rating.value);
      const maxRating = Math.max(...ratingsValues);
      const minRating = Math.min(...ratingsValues);

      return [minRating, maxRating];
    };

    const calculatePercentiles = (data) => {
      const ratios = data.map((p) => p.y / p.x);
      ratios.sort();
      const len = ratios.length;
      const per20 = Math.floor(len * 0.2) - 1;
      const per80 = Math.floor(len * 0.8) - 1;

      return [ratios[per20], ratios[per80]];
    };

    // datapoint colors (blue by default)
    const [lowLimit, highLimit] = campaignHasRatings
      ? calculateLimits(campaign)
      : calculatePercentiles(datapoints);

    const getPointColor = (point) => {
      const rating = point.rating;

      if (campaignHasRatings) {
        // @ts-expect-error
        if (typeof rating !== 'number') return colors.tbd;
        // @ts-expect-error
        if (rating <= lowLimit) return colors.low;
        // @ts-expect-error
        if (rating >= highLimit) return colors.high;
      } else {
        const value = +point.y / +point.x;
        if (props.reverse) {
          // When higher count is bad (ie. heads ups)
          // @ts-expect-error
          if (value <= lowLimit) return colors.high;
          // @ts-expect-error
          if (value >= highLimit) return colors.low;
        } else {
          // @ts-expect-error
          if (value <= lowLimit) return colors.low;
          // @ts-expect-error
          if (value >= highLimit) return colors.high;
        }
      }

      // @ts-expect-error
      return colors.medium;
    };

    return {
      datasets: [
        {
          label: 'Trendline',
          data: trendlineEndpoints,
          fill: false,
          showLine: true,
          borderColor: 'orange',
          borderDash: [5, 5],
        },
        {
          label: 'Tenure v. Influence',
          data: datapoints,
          fill: true,
          // @ts-expect-error
          pointBorderColor: () => colors.transparent,
          pointBackgroundColor: (context) => {
            const index = context.dataIndex;
            const point = context.dataset.data[index];
            if (!point) {
              return;
            }

            // Colors background black if single point is selected
            // @ts-expect-error
            const rawPoint = props.data[field][index];
            // @ts-expect-error
            if (rawPoint && finder.includes(rawPoint.id)) return 'black';

            return getPointColor(point);
          },
          pointBorderWidth: 1,
          pointHoverRadius: 4,
          pointRadius: 4,
          pointHitRadius: 4,
        },
      ],
    };
  }, [
    fields.length,
    props.data,
    field,
    metric,
    finder,
    props.reverse,
    campaignHasRatings,
    colors,
  ]);

  return fields.length ? (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>
          {props.showMetric
            ? metricDropdown
            : // @ts-expect-error
              PERFORMANCE_METRIC_NAMES(formatMessage)[props.metric]}{' '}
          {props.title}
          {props.showField ? 'by' + fieldDropdown : <></>}
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {props.beforeContent && (
          <div className="mb-4">{props.beforeContent}</div>
        )}
        <PeopleEditor
          className="mb-4"
          placeholder={formatMessage({
            id: 'app.views.widgets.cards.scatter_plot_card.people_editor.placeholder',
            defaultMessage: 'Gold stars',
          })}
          callback={onChangeFilter}
        />
        <div>
          <ScatterPlot
            field={formatField(field)}
            // @ts-expect-error
            metric={PERFORMANCE_METRIC_NAMES(formatMessage)[metric]}
            data={preparedData}
            // @ts-expect-error
            rawData={props.data[field]}
            hasRatings={campaignHasRatings}
          />
        </div>
        {props.afterContent && <div className="mt-4">{props.afterContent}</div>}
      </CardBody>
    </Card>
  ) : (
    <div />
  );
};

const ScatterPlotCard_propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  field: PropTypes.string,
  metric: PropTypes.string,
  showField: PropTypes.bool,
  showMetric: PropTypes.bool,
  style: PropTypes.object,
  beforeContent: PropTypes.node,
  afterContent: PropTypes.node,
  reverse: PropTypes.bool,
  colors: PropTypes.object,
};

type Props = PropTypes.InferProps<typeof ScatterPlotCard_propTypes>;

export default React.memo(ScatterPlotCard);
