import * as consts from '../../consts/consts';

import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledPopover,
} from 'reactstrap';
import { Features, Me, Organization, Person } from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getPersonDisplayTitleHtml,
  peopleIdsAreEqual,
} from '../../utils/models/Person';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { DEVELOPMENT_PLANS_BASE_URL_PATH } from 'utils/models/DevelopmentPlan';
import DevelopmentPlansDashboard from 'views/DevelopmentPlans/DevelopmentPlansDashboard';
import EmptyState from '../Widgets/EmptyState';
import Loading from '../Widgets/Loading';
import ManualCopyUrlButton from './ManualCopyUrlButton';
import ModalActivityEditorButton from '../Activities/ModalActivityEditorButton';
import ModalAspirationEditor from '../Widgets/Modals/ModalAspirationEditor';
import ModalFeedbackEditorButton from '../Feedback/ModalFeedbackEditorButton';
import ObjectivesTeamNav from '../Objectives/ObjectivesTeamNav';
import OneOnOnesDirectory from '../OneOnOnes/OneOnOnesDirectory';
import Page from '../Layout/Pages/Page';
import Page404 from '../Layout/Pages/Errors/Page404';
import PersonDashboard from './PersonDashboard';
import PersonFeedbackList from './PersonFeedbackList';
import PersonGoals from './PersonGoals';
import PersonOneOnOnes from './PersonOneOnOnes';
import PersonOperatingManual from './PersonOperatingManual';
import PersonPerformance from './PersonPerformance';
import PersonalObjectives from './PersonalObjectives';
import SwitchInput from '../Widgets/Inputs/SwitchInput';
import { atLeastOneContinuousFeedbackFeatureIsEnabled } from '../../utils/util/features';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { useAuth0 } from '@auth0/auth0-react';

const getTabHeader = (name, icon) => {
  return (
    <>
      <span className={'d-lg-none display-4 me-n1 ' + icon}></span>
      <span className="d-none d-lg-inline">{name}</span>
    </>
  );
};

type Props = {
  personId?: number;
  currentOrganization: Organization;
  me: Me;
  features: Features;
  currentProxyPerson: Person;
};

const PersonProfile: FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(false);
  const [createActivityIsOpen, setCreateActivityIsOpen] = useState(false);
  const [activityType, setActivityType] = useState(null);
  const [contributions, setContributions] = useState([]);
  const [filterName, setFilterName] = useState('');

  // default to showing manager-only details for discoverability
  const [
    showManagerOnlyPerformanceDetails,
    setShowManagerOnlyPerformanceDetails,
  ] = useState(true);

  const [showSentFeedback, setShowSentFeedback] = useState(false);
  const [aspirations, setAspirations] = useState(undefined);
  const [aspirationCreateModalIsOpen, setAspirationCreateModalIsOpen] =
    useState(false);
  const toggleAspirationCreateModal = useCallback(
    () => setAspirationCreateModalIsOpen(!aspirationCreateModalIsOpen),
    [aspirationCreateModalIsOpen]
  );

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const openCreateActivityDialog = useCallback((type) => {
    setActivityType(type);
    setCreateActivityIsOpen(true);
  }, []);

  const onCreateActivityDialogClosed = useCallback(() => {
    // clear activity type so reopening allows picking new type
    setActivityType(null);
    setCreateActivityIsOpen(false);
  }, []);

  const params = useParams();
  // @ts-expect-error
  const personId = props.personId ? props.personId : params.personId;
  // @ts-expect-error
  const personSlug = params.slug;
  const meId = props.me?.id;
  const isMe =
    peopleIdsAreEqual(meId, personId) ||
    (!!personSlug &&
      props.me?.url?.toString() === '/' + personSlug?.toString());
  const [errorMessage, setErrorMessage] = useState(null);
  const [person, setPerson] = useState(undefined);
  const [feedbackErrorMessage, setFeedbackErrorMessage] = useState(null);
  const [feedbackList, setFeedbackList] = useState(undefined);
  const { user } = useAuth0();

  const userSub = user?.sub;

  const urlPrefix = personSlug
    ? '/' + personSlug
    : personId
    ? consts.PEOPLE_SUBROUTE().path + '/' + personId
    : null;

  const noTabProvidedInUrl = useMemo(
    () => location?.pathname === urlPrefix,
    [location?.pathname, urlPrefix]
  );

  // Determine if we are on the "resume" tab
  const isResumeTab = useMemo(
    () => noTabProvidedInUrl && props.features.resumes?.enabled,
    [noTabProvidedInUrl, props.features.resumes?.enabled]
  );

  const setAndCachePerson = useCallback(
    (p) => {
      // cache so fetching page next time has latest data from cache
      ConfirmAPI.setObjectInCache(
        userSub,
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.PEOPLE,
        p?.id,
        p
      );

      setPerson(p);
    },
    [props.currentProxyPerson, userSub]
  );

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // fetch person's details
    if (userSub && typeof person === 'undefined' && (personSlug || personId)) {
      const queryParams = personSlug ? { slug: 'true' } : undefined;

      ConfirmAPI.getObject(
        userSub,
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.PEOPLE,
        personSlug || personId,
        (data) => {
          if (isMounted) {
            const newPerson = data || null;
            setPerson(newPerson);
            // don't add self to invitation list for creating new contributions, but
            // add the current person whose profile you are on
            if (!isMe && newPerson) {
              // @ts-expect-error
              setContributions([newPerson]);
            }
          }
        },
        (message) => {
          // TODO: don't hardcode based on error message, use status code
          if (message === 'Not Found') {
            // since slugs may return a not found error, just show not found page in this case
            return <Page404 />;
          } else {
            setErrorMessage(message);
          }
        },
        queryParams
      );
    }
  }, [
    isMe,
    isMounted,
    person,
    personId,
    personSlug,
    props.currentProxyPerson,
    userSub,
  ]);

  // @ts-expect-error
  const isAdminable = useMemo(() => person?.is_adminable, [person]);

  const continuousFeedbackFeatureIsEnabled = useMemo(
    () => atLeastOneContinuousFeedbackFeatureIsEnabled(props.features),
    [props.features]
  );

  useEffect(() => {
    if (!isMounted || !continuousFeedbackFeatureIsEnabled || !person) {
      return;
    }

    const params = {
      // @ts-expect-error
      person: person.id,
      organization: props.currentOrganization?.id,
      proxy: props.currentProxyPerson
        ? props.currentProxyPerson.email
        : undefined,
      manager_view: showManagerOnlyPerformanceDetails,
    };

    ConfirmAPI.getUrlWithCache(
      '/feedback',
      // @ts-expect-error
      null,
      null, // this is too much data, don't cache it
      null,
      params,
      (data) => {
        if (isMounted) {
          if (data?.results) {
            setFeedbackList(data.results);
          }
        }
      },
      (message) => {
        setFeedbackErrorMessage(message);
      }
    );
  }, [
    isMounted,
    props.currentOrganization?.id,
    props.currentProxyPerson,
    showManagerOnlyPerformanceDetails,
    continuousFeedbackFeatureIsEnabled,
    person,
  ]);

  const isBelowInChainOfCommand = useMemo(
    () =>
      // @ts-expect-error
      person?.is_below_in_chain_of_command ||
      // @ts-expect-error
      person?.is_direct_report_in_campaign,
    // @ts-expect-error
    [person?.is_below_in_chain_of_command, person?.is_direct_report_in_campaign]
  );

  const showPerformance = useMemo(
    () =>
      // note: we still show performance tab if !person to prevent the flicker of a 404 page
      // when visiting /performance directly (once the person loads, it will go to the
      // 404 page if necessary)
      isMe || !person || isBelowInChainOfCommand || isAdminable,
    [isAdminable, isBelowInChainOfCommand, isMe, person]
  );

  const showDevelopmentPlans = useMemo(
    () =>
      props.features?.development_plans?.enabled &&
      // note: we still show performance tab if !person to prevent the flicker of a 404 page
      // when visiting /performance directly (once the person loads, it will go to the
      // 404 page if necessary)
      (isMe || !person || isBelowInChainOfCommand || isAdminable),
    [
      isAdminable,
      isBelowInChainOfCommand,
      isMe,
      person,
      props.features?.development_plans?.enabled,
    ]
  );

  useEffect(() => {
    // Only allow if user can see performance, but is not the user
    // and querystring exists
    if (isMe) {
      setShowManagerOnlyPerformanceDetails(false);
    } else {
      if (showPerformance && location.search) {
        const pageConfigs = new URLSearchParams(location.search);
        if (pageConfigs?.get('manager') === 'false') {
          setShowManagerOnlyPerformanceDetails(false);
        }
      }
    }
  }, [location, isMe, showPerformance]);

  const subtitle = useMemo(() => {
    if (!person) {
      return '';
    }
    // construct subtitle based on information populated (or not) by user
    const titleInsert = getPersonDisplayTitleHtml(formatMessage, person);
    // @ts-expect-error
    const locationInsert = person.location
      ? formatMessage(
          {
            id: 'app.views.person.person_profile.location',
            defaultMessage:
              '{withTitle, select, true { in {location}} other { Located in {location}}}',
          },
          {
            // @ts-expect-error
            location: person.location,
            withTitle: !!titleInsert,
          }
        )
      : '';

    // return additional information related to profile / operating manual
    // @ts-expect-error
    return person.is_active ? (
      <>
        <div>
          {(titleInsert || locationInsert) && (
            <span className="me-2">
              {titleInsert}
              {locationInsert}
            </span>
          )}
          {isMe && (
            <>
              {isResumeTab && (
                <>
                  <ModalActivityEditorButton
                    buttonClassName="d-block d-sm-none"
                    buttonText={formatMessage({
                      id: 'app.views.person.person_profile.add_to_my_resume',
                      defaultMessage: 'Add to my resume',
                    })}
                    activityType={activityType}
                    contributions={contributions}
                    isOpen={createActivityIsOpen}
                    onClosed={onCreateActivityDialogClosed}
                  />
                </>
              )}
            </>
          )}
        </div>
      </>
    ) : (
      <span className="fst-italic">
        {consts.FORMER_EMPLOYEE_WORDING(formatMessage)}
      </span>
    );
  }, [
    activityType,
    contributions,
    createActivityIsOpen,
    isMe,
    isResumeTab,
    onCreateActivityDialogClosed,
    person,
    formatMessage,
  ]);

  const renderTeamNav = useCallback(
    (person, currentFirstDayOfTimeFrame) => (
      <ObjectivesTeamNav
        person={person}
        currentFirstDayOfTimeFrame={currentFirstDayOfTimeFrame}
      />
    ),
    []
  );

  // Construct action buttons for feedback and performance tab
  const getTabActions = useCallback(
    (isManager, showFeedbackButton) => (
      <Row xs="1" sm="12" className="align-items-center">
        {isManager && (
          <Col className="col-auto me-sm-3 mb-3 mb-md-0">
            <SwitchInput
              switchLabel={formatMessage({
                id: 'app.views.person.person_profile.show_manager_view',
                defaultMessage: 'Show manager view',
              })}
              value={showManagerOnlyPerformanceDetails}
              onChange={setShowManagerOnlyPerformanceDetails}
            />
          </Col>
        )}
        {showFeedbackButton && continuousFeedbackFeatureIsEnabled && (
          <Col className="col-sm-auto mb-3 mb-md-0 pe-0">
            <ModalFeedbackEditorButton
              buttonText={formatMessage({
                id: 'app.views.person.person_profile.add_feedback_or_notes',
                defaultMessage: 'Add feedback or notes',
              })}
            />
          </Col>
        )}
        <Col className="col-sm-auto mb-3 mb-md-0">
          <Button color="light" onClick={() => window.print()}>
            <i className="me-2 fe fe-upload" />
            <FormattedMessage
              id="app.views.person.person_profile.export"
              defaultMessage="Export"
            />
          </Button>
        </Col>
      </Row>
    ),
    [
      continuousFeedbackFeatureIsEnabled,
      showManagerOnlyPerformanceDetails,
      formatMessage,
    ]
  );

  const tabs = useMemo(() => {
    const tabs = [];

    if (urlPrefix) {
      if (props.features?.resumes?.enabled) {
        // @ts-expect-error
        tabs.push({
          path: urlPrefix,
          name: getTabHeader(
            formatMessage({
              id: 'app.views.person.person_profile.overview_tab_heading',
              defaultMessage: 'Overview',
            }),
            consts.ICONS.RESUME
          ),
          action: isMe ? (
            <ModalActivityEditorButton
              buttonClassName="d-none d-sm-block"
              buttonText={formatMessage({
                id: 'app.views.person.person_profile.add_to_my_resume',
                defaultMessage: 'Add to my resume',
              })}
              activityType={activityType}
              contributions={contributions}
              isOpen={createActivityIsOpen}
              onClosed={onCreateActivityDialogClosed}
            />
          ) : (
            continuousFeedbackFeatureIsEnabled && (
              <ModalFeedbackEditorButton
                buttonText={formatMessage({
                  id: 'app.views.person.person_profile.add_feedback_or_notes',
                  defaultMessage: 'Add feedback or notes',
                })}
                feedback={{
                  subject_people: [person],
                }}
              />
            )
          ),
          content: (
            <PersonDashboard
              isMe={isMe}
              // @ts-expect-error
              person={person}
              showPerformance={showPerformance}
              setPerson={setAndCachePerson}
              openCreateActivityDialog={openCreateActivityDialog}
              setCreateActivityIsOpen={setCreateActivityIsOpen}
              filterName={filterName}
              setFilterName={setFilterName}
              feedbackList={feedbackList}
            />
          ),
        });
      }

      if (props.features?.one_on_ones?.enabled) {
        // @ts-expect-error
        tabs.push({
          path: urlPrefix + consts.PROFILE_TAB_ONE_ON_ONES(formatMessage).path,
          name: getTabHeader(
            formatMessage({
              id: 'app.views.person.person_profile.one_on_ones_tab_heading',
              defaultMessage: '1:1s',
            }),
            consts.ICONS.ONE_ON_ONES
          ),
          content: isMe ? (
            <OneOnOnesDirectory inMyProfile={true} />
          ) : (
            // @ts-expect-error
            <PersonOneOnOnes person={person} />
          ),
        });
      }

      // you can write OKRs for people who haven't yet created an account
      if (props.features?.objectives?.enabled) {
        // @ts-expect-error
        tabs.push({
          path: urlPrefix + consts.OBJECTIVES(formatMessage).path,
          name: getTabHeader(
            formatMessage({
              id: 'app.views.person.person_profile.objectives_tab_heading',
              defaultMessage: 'Objectives',
            }),
            consts.ICONS.OBJECTIVE
          ),
          content: (
            <>
              {person && (
                <PersonalObjectives
                  renderTeamNav={renderTeamNav}
                  showTeamNav={true}
                  person={person}
                />
              )}
            </>
          ),
        });
      }

      // the next tabs are meant for people with accounts
      if (props.features?.goals?.enabled) {
        // @ts-expect-error
        tabs.push({
          path: urlPrefix + '/goals',
          name: getTabHeader(
            formatMessage({
              id: 'app.views.person.person_profile.aspirations_tab_heading',
              defaultMessage: 'Aspirations',
            }),
            consts.ICONS.GOAL
          ),
          action: isMe && (
            <>
              <ModalAspirationEditor
                isOpen={aspirationCreateModalIsOpen}
                toggle={toggleAspirationCreateModal}
                title={formatMessage({
                  id: 'app.views.person.person_profile.title.add_aspiration',
                  defaultMessage: 'Add aspiration',
                })}
                // @ts-expect-error
                callback={(a) => setAspirations([a, ...aspirations])}
              />
              <Button color="primary" onClick={toggleAspirationCreateModal}>
                <FormattedMessage
                  id="app.views.person.person_profile.add_aspiration"
                  defaultMessage="Add aspiration"
                />
              </Button>
            </>
          ),
          content: (
            <>
              {person && (
                <PersonGoals
                  person={person}
                  aspirations={aspirations}
                  setAspirations={setAspirations}
                  aspirationCreateModalIsOpen={aspirationCreateModalIsOpen}
                  toggleAspirationCreateModal={toggleAspirationCreateModal}
                  setPerson={setPerson}
                />
              )}
            </>
          ),
        });

        if (props.features?.operating_manual?.enabled) {
          // @ts-expect-error
          tabs.push({
            path: urlPrefix + '/manual',
            name: getTabHeader(
              formatMessage({
                id: 'app.views.person.person_profile.manual_tab_heading',
                defaultMessage: 'Manual',
              }),
              consts.ICONS.OPERATING_MANUAL
            ),
            action: isMe && (
              <ManualCopyUrlButton
                path={urlPrefix + '/manual'}
              ></ManualCopyUrlButton>
            ),
            content: (
              <>
                {person && (
                  <PersonOperatingManual
                    isMe={isMe}
                    setPerson={setAndCachePerson}
                    // @ts-expect-error
                    fullName={person?.full_name}
                    // ensure that {} is there in case someone's profile has a null operating manual
                    // @ts-expect-error
                    operatingManual={person?.profile?.operating_manual || {}}
                  />
                )}
              </>
            ),
          });
        }
      }

      if (continuousFeedbackFeatureIsEnabled) {
        // @ts-expect-error
        tabs.push({
          path: urlPrefix + '/feedback',
          name: getTabHeader(
            <>
              <div>
                <i id="feedback-tab-lock-icon" className="fe fe-lock me-2" />
                <FormattedMessage
                  id="app.views.person.person_profile.feedback_and_notes"
                  defaultMessage="Feedback & notes"
                />
              </div>
              <UncontrolledPopover
                placement="right"
                trigger="hover"
                target="feedback-tab-lock-icon"
              >
                <FormattedMessage
                  id="app.views.person.person_profile.only_manager_and_above_can_see_feedback_information"
                  defaultMessage="Only manager and above (and HR administrators) can see feedback information."
                />
              </UncontrolledPopover>
            </>,
            consts.ICONS.FEEDBACK
          ),
          action: getTabActions(!isMe && person, true),
          content: (
            <>
              {person && (
                <Row className="justify-content-center">
                  <Col className="col-12 col-md-2 pt-0 pt-md-6">
                    <ListGroup className="list-group-flush">
                      <ListGroupItem
                        role="button"
                        className={showSentFeedback ? '' : 'fw-bold'}
                        onClick={() => setShowSentFeedback(false)}
                      >
                        <FormattedMessage
                          id="app.views.person.person_profile.received"
                          defaultMessage="Received"
                        />
                      </ListGroupItem>
                      <ListGroupItem
                        role="button"
                        className={showSentFeedback ? 'fw-bold' : ''}
                        onClick={() => setShowSentFeedback(true)}
                      >
                        <FormattedMessage
                          id="app.views.person.person_profile.sent"
                          defaultMessage="Sent"
                        />
                      </ListGroupItem>
                      {isMe && (
                        <ListGroupItem>
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to="/feedback/sent"
                          >
                            <FormattedMessage
                              id="app.views.person.person_profile.view_sent_requests"
                              defaultMessage="View requests"
                            />{' '}
                            <i className="fe fe-external-link" />
                          </Link>
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </Col>
                  <Col className="col-12 col-md-10">
                    <PersonFeedbackList
                      person={person}
                      feedbackList={feedbackList}
                      errorMessage={feedbackErrorMessage}
                      isAdminable={isAdminable}
                      showManagerOnlyPerformanceDetails={
                        showManagerOnlyPerformanceDetails
                      }
                      showSentFeedback={showSentFeedback}
                    />
                  </Col>
                </Row>
              )}
            </>
          ),
        });
      }

      if (showPerformance) {
        // @ts-expect-error
        tabs.push({
          path: urlPrefix + '/performance',
          name: getTabHeader(
            <>
              <div>
                <i id="perf-tab-lock-icon" className="fe fe-lock me-2" />
                <FormattedMessage
                  id="app.views.person.person_profile.surveys_and_performance"
                  defaultMessage="Surveys & performance"
                />
              </div>
              <UncontrolledPopover
                placement="right"
                trigger="hover"
                target="perf-tab-lock-icon"
              >
                <FormattedMessage
                  id="app.views.person.person_profile.only_manager_and_above_can_see_performance_information"
                  defaultMessage="Only manager and above (and HR administrators) can see performance information."
                />
              </UncontrolledPopover>
            </>,
            consts.ICONS.PERFORMANCE
          ),
          content: (
            <>
              {person ? (
                <PersonPerformance
                  actions={getTabActions(!isMe && person, false)}
                  isInReviewFlow={false}
                  person={person}
                  // @ts-expect-error
                  isAdminable={isAdminable}
                  showManagerOnlyPerformanceDetails={
                    showManagerOnlyPerformanceDetails
                  }
                  showCampaignsSelector={true}
                  showHistory={true}
                />
              ) : null}
            </>
          ),
        });
      }

      if (showDevelopmentPlans) {
        // @ts-expect-error
        tabs.push({
          path: `${urlPrefix}/${DEVELOPMENT_PLANS_BASE_URL_PATH}`,
          name: getTabHeader(
            <>
              <div>
                <i id="perf-tab-lock-icon" className="fe fe-lock me-2" />
                <FormattedMessage
                  id="app.views.person.person_profile.development_plans"
                  defaultMessage="Plans"
                />
              </div>
              <UncontrolledPopover
                placement="right"
                trigger="hover"
                target="perf-tab-lock-icon"
              >
                <FormattedMessage
                  id="app.views.person.person_profile.only_manager_and_above_can_see_developent_plans_information"
                  defaultMessage="Only manager and above (and HR administrators) can see development plans information."
                />
              </UncontrolledPopover>
            </>,
            consts.ICONS.DEVELOPMENT_PLAN
          ),
          content: (
            <>
              {person && (
                <DevelopmentPlansDashboard
                  embedded={true}
                  person={person}
                  hideHeaderControls={true}
                />
              )}
            </>
          ),
        });
      }
    }

    return tabs;
  }, [
    activityType,
    aspirationCreateModalIsOpen,
    aspirations,
    contributions,
    createActivityIsOpen,
    feedbackErrorMessage,
    feedbackList,
    filterName,
    getTabActions,
    isAdminable,
    isMe,
    onCreateActivityDialogClosed,
    openCreateActivityDialog,
    person,
    continuousFeedbackFeatureIsEnabled,
    props.features?.goals?.enabled,
    props.features?.objectives?.enabled,
    props.features?.one_on_ones?.enabled,
    props.features?.operating_manual?.enabled,
    props.features?.resumes?.enabled,
    setAndCachePerson,
    showManagerOnlyPerformanceDetails,
    showPerformance,
    showDevelopmentPlans,
    showSentFeedback,
    toggleAspirationCreateModal,
    urlPrefix,
    formatMessage,
    renderTeamNav,
  ]);

  // if no tab provided in url, and first tab is not resume tab (because it
  // is disabled, then redirect to the first tab's path)
  useEffect(() => {
    // @ts-expect-error
    if (noTabProvidedInUrl && tabs?.[0]?.path !== urlPrefix) {
      // @ts-expect-error
      history.replace(tabs?.[0]?.path);
    }
  }, [history, noTabProvidedInUrl, tabs, urlPrefix]);

  const mainPageContent = useMemo(() => {
    // if only one tab, just show that page (without tabs on it),
    // e.g. for ONA-only experience with no resumes and just perf tab, else show tabs
    if (tabs.length === 1) {
      return (
        // @ts-expect-error
        <Page person={person} title={person?.full_name} subtitle={subtitle}>
          {
            // @ts-expect-error
            tabs[0].action
          }
          {
            // @ts-expect-error
            tabs[0].content
          }
        </Page>
      );
    } else if (tabs.length > 1) {
      return (
        <Page
          person={person}
          // @ts-expect-error
          title={person?.full_name}
          subtitle={subtitle}
          tabs={tabs}
        />
      );
    } else {
      return (
        // @ts-expect-error
        <Page person={person} title={person?.full_name} subtitle={subtitle}>
          <Card>
            <CardBody>
              <EmptyState
                title={formatMessage({
                  id: 'app.views.person.person_profile.title.nothing_to_see_here',
                  defaultMessage: 'Nothing to see here',
                })}
                subtitle={formatMessage(
                  {
                    id: 'app.views.person.person_profile.subtitle.nothing_to_see_here',
                    defaultMessage:
                      '{name} has not shared anything with others yet.',
                  },
                  {
                    // @ts-expect-error
                    name: person.given_name,
                  }
                )}
              />
            </CardBody>
          </Card>
        </Page>
      );
    }
  }, [person, subtitle, tabs, formatMessage]);

  const loadOrRenderOutput = loadOrRender(person, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (typeof person === 'undefined') {
    return <Loading />;
  }

  return mainPageContent;
};

const mapStateToProps = (state) => {
  const { me, currentProxyPerson, currentOrganization, features, authUser } =
    state;

  return {
    me,
    currentProxyPerson,
    currentOrganization,
    features,
    authUser,
  };
};

export default connect(mapStateToProps)(React.memo(PersonProfile));
