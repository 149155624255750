import { Card, CardBody } from 'reactstrap';
import React, { FC } from 'react';

import Page from '../Page';
import Page404 from './Page404';
import { formatErrorMessage } from 'utils/util/error';
import { useIntl } from 'react-intl';

interface Props {
  message: string | object;
}

const PageError: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  // if this is a 404 error object, show a 404 error page instead
  // @ts-expect-error
  if (props.message?.status === 404) {
    return <Page404 />;
  }

  // this page could be for a user getting permission denied or other types of
  // expected errors, so we do not record the error in the console log here.
  return (
    <Page
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page_error.title.error',
        defaultMessage: 'Error',
      })}
    >
      <Card>
        <CardBody>{formatErrorMessage(props.message)}</CardBody>
      </Card>
    </Page>
  );
};

export default React.memo(PageError);
