import { Button } from 'reactstrap';
import React, { FC, PropsWithChildren } from 'react';

interface ButtonWithIconProps {
  icon: string;
  color?: string;
  onClick?: () => void;
  className?: string;
  outline?: boolean;
}

const ButtonWithIcon: FC<PropsWithChildren<ButtonWithIconProps>> = ({
  icon,
  color,
  onClick,
  children,
  ...other
}) => {
  return (
    <Button color={color} onClick={onClick} size="sm" {...other}>
      <i className={`fe fe-${icon} me-2`} />
      {children}
    </Button>
  );
};

export default ButtonWithIcon;
