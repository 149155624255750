import * as consts from '../../consts/consts';

import {
  Campaign,
  MessageDescriptor,
  Organization,
  Person,
  ReduxState,
} from '../../types';
import { Card, CardBody } from 'reactstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CAMPAIGN_STATUSES } from '../../utils/models/Campaign';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import EmptyState from '../Widgets/EmptyState';
import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import PerformanceCycleDropdown from '../Widgets/Dropdowns/PerformanceCycleDropdown';
import TakeawaysDashboard from 'views/Widgets/Dashboards/TakeawaysDashboard';
import { asInt } from '../../utils/util/util';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntl } from 'react-intl';

function buildQueryString(campaignId?: number) {
  const queryParameters: Record<string, string> = {
    ...Object.fromEntries(new URLSearchParams(location.search)),
    ...(campaignId && campaignId > 0
      ? { campaignId: campaignId.toString() }
      : {}),
  };
  const querystring = new URLSearchParams(queryParameters).toString();
  return querystring ? `?${querystring}` : '';
}

type EngagementHubPageProps = {
  currentOrganization: Organization;
  currentProxyPerson?: Person;
};

const EngagementHubPage: React.FC<EngagementHubPageProps> = (props) => {
  const { formatMessage } = useIntl();
  const [campaigns, setCampaigns] = useState<Campaign[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth0();
  const campaignId = asInt(
    new URLSearchParams(location.search).get('campaignId')
  );

  useEffect(() => {
    setLoading(true);
    // will fix when ConfirmAPI.js is converted
    ConfirmAPI.getUrlWithCache(
      '/campaigns',
      'campaigns',
      user?.sub,
      props.currentProxyPerson,
      {
        status: CAMPAIGN_STATUSES.ACTIVE,
        organization: props.currentOrganization?.id,
      },
      (data) => {
        if (data?.results) {
          setCampaigns(
            data.results.filter((x: Campaign) => !!x?.properties?.has_survey)
          );
        }
        setLoading(false);
      },
      (message) => {
        console.error('Could not fetch campaigns: ' + message);
        setCampaigns(null);
        setLoading(false);
      }
    );
  }, [props.currentOrganization?.id, props.currentProxyPerson, user?.sub]);

  const onChange = useCallback(
    ({ campaignId }: { campaignId: number }) => {
      const querystring = buildQueryString(campaignId);
      history.push(`${location.pathname}${querystring}`);
    },
    [history, location.pathname]
  );

  const cycleDropdown = useMemo(() => {
    return (
      campaigns?.length &&
      campaigns?.length > 0 && (
        <PerformanceCycleDropdown
          campaignId={campaignId}
          campaigns={campaigns}
          onChange={onChange}
        />
      )
    );
  }, [campaignId, campaigns, onChange]);

  const buildTabs = (
    buildQueryString: (campaignId?: number) => string,
    campaignId: number | undefined,
    campaigns: Campaign[] | null,
    cycleDropdown: false | 0 | JSX.Element | undefined,
    formatMessage: (messageDescriptor: MessageDescriptor) => string
  ) => {
    if (!campaigns?.length) {
      return [];
    }
    const querystring = buildQueryString(campaignId);

    const selectedCampaign =
      campaigns.find((campaign) => campaign.id === campaignId) || campaigns[0]; // use first one until campaignId selected

    return [
      {
        name: formatMessage({
          id: 'app.views.engagement.engagement_hub_page.tab_label.overview',
          defaultMessage: 'Overview',
        }),
        action: cycleDropdown,
        path: consts.ENGAGEMENT_HUB(formatMessage).path + querystring,
        content: (
          <TakeawaysDashboard
            campaign={selectedCampaign}
            campaigns={campaigns}
            forceShowEngagementSurveyOnly={true}
          />
        ),
      },
    ];
  };

  const tabs = buildTabs(
    buildQueryString,
    campaignId,
    campaigns,
    cycleDropdown,
    formatMessage
  );

  if (loading) {
    return <Loading />;
  } else if (tabs?.length > 0) {
    return (
      <Page title={consts.ENGAGEMENT_HUB(formatMessage).name} tabs={tabs} />
    );
  } else {
    return (
      <Page title={consts.ENGAGEMENT_HUB(formatMessage).name}>
        <Card>
          <CardBody>
            <EmptyState
              title={formatMessage({
                id: 'app.views.engagement.engagement_hub_page.no_campaigns.title',
                defaultMessage: 'Nothing to see here',
              })}
              subtitle={formatMessage({
                id: 'app.views.engagement.engagement_hub_page.no_campaigns.subtitle',
                defaultMessage:
                  'This page needs at least one campaign with engagement enabled.',
              })}
            />
          </CardBody>
        </Card>
      </Page>
    );
  }
};

const mapStateToProps = (state: ReduxState) => {
  const { authUser, currentProxyPerson, currentOrganization } = state;

  return {
    authUser,
    currentProxyPerson,
    currentOrganization,
  };
};

export default connect(mapStateToProps)(React.memo(EngagementHubPage));
