import { ListGroup, ListGroupItem } from 'reactstrap';
import React, { FC } from 'react';

import PersonCard from '../Widgets/Cards/PersonCard';

interface Props {
  people: any[];
}

const PersonList: FC<Props> = ({ people }) => {
  return (
    <ListGroup className="list-group-flush my-n3">
      {people.map((person, index) => (
        <ListGroupItem key={index}>
          <PersonCard person={person} bodyOnly linked={true} />
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

// all tracking in app will be passed through here
export default React.memo(PersonList);
