import React, { FC } from 'react';

interface Props {
  object: any;
  renderObject: (object: any) => JSX.Element;
  isChild?: boolean;
}

const HierarchyList: FC<Props> = ({ isChild = false, ...props }) => {
  if (!props.object) {
    return <></>;
  }

  const children = props.object.children?.length > 0 && (
    <ul
      className={
        'hierarchy-list' + (isChild || props.object.id ? '' : ' my-n3')
      }
    >
      {props.object.children.map((object, index) => (
        <li key={index} className="hierarchy-list-item">
          <HierarchyList
            object={object}
            renderObject={props.renderObject}
            isChild={true}
          />
        </li>
      ))}
    </ul>
  );

  return (
    <>
      {isChild &&
        props.object &&
        props.object.id &&
        props.renderObject(props.object) && (
          <div className="hierarchy-list-item-content">
            {props.renderObject(props.object)}
          </div>
        )}
      {!isChild && props.object.id && (
        <ul className="hierarchy-list my-n3">
          <li className="hierarchy-list-item">
            <div className="hierarchy-list-item-content">
              {props.renderObject(props.object)}
            </div>
            {children}
          </li>
        </ul>
      )}
      {(isChild || !props.object.id) && children}
    </>
  );
};

export default React.memo(HierarchyList);
