import { ERROR_404_DB_RECORD } from '../../consts/consts';
import Page404 from 'views/Layout/Pages/Errors/Page404';
import PageError from 'views/Layout/Pages/Errors/PageError';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const stripQueryString = (path: string): string => {
  const questionMark = path.indexOf('?');
  return questionMark === -1 ? path : path.slice(0, questionMark);
};

export const getUniqueHtmlId = (prefix = 'id') => {
  return `${prefix}_${uuidv4()}`;
};

// capitalize the given string
export const capitalize = (str) => {
  if (!str) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.substr(1);
};

export const renderValidationError = (error) => {
  const renderValidationSubError = (error) => {
    if (!error) {
      return <></>;
    }

    if (typeof error === 'string') {
      return <span>{error}</span>;
    } else if (error.constructor === Object) {
      return (
        <>
          {Object.keys(error).map((key, keyIndex) => (
            <div key={keyIndex}>
              {key === 'non_field_errors' || key === '__all__'
                ? ''
                : capitalize(key) + ': '}
              {renderValidationSubError(error[key])}
            </div>
          ))}
        </>
      );
    } else if (Array.isArray(error)) {
      if (error.length === 1) {
        return renderValidationSubError(error[0]);
      } else {
        return (
          <div>
            {error.map((e, eIndex) => (
              <div key={eIndex}>{renderValidationSubError(e)}</div>
            ))}
          </div>
        );
      }
    } else {
      return error;
    }
  };

  const errors = error?.data ? error?.data : error;

  return errors ? (
    <div className="invalid-feedback">{renderValidationSubError(errors)}</div>
  ) : (
    <></>
  );
};

// Helper for page rendering to ensure consistent experiences for pages that fetch
// content and need to display a loader or, when there is an error, an appropriate error page;
// a null result indicates continue rendering (or loading)
export const loadOrRender = (data, errorMessage) => {
  // TODO: use status codes instead of an exact string comparison
  if (errorMessage === ERROR_404_DB_RECORD.name) {
    return <Page404 />;
  }

  if (errorMessage) {
    return <PageError message={errorMessage} />;
  } else {
    if (typeof data === 'undefined') {
      // note: we return here null instead of <Loading /> so the rest of the page continues to
      // render on its own without being blocked
      return null;
    }

    if (!data) {
      return <Page404 />;
    }
  }

  return null;
};
