import {
  ACTIVITY_VISIBILITIES,
  fromLegacyVisibilityToSimplified,
} from '../../../utils/models/Activity';
import { Card, CardBody, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { INPUT_ATTRIBUTES, INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import React, { useCallback, useMemo, useState } from 'react';

import { CONTRIBUTION_EDITOR_ATTRIBUTES } from '../Inputs/ValidatedInput';
import ModalActivitySelector from '../Modals/ModalActivitySelector';
import PropTypes from 'prop-types';
import ValidatedForm from '../Forms/ValidatedForm';

const SimpleActivityEditorCard = (props) => {
  const { formatMessage } = useIntl();
  const campaign = props.campaign;

  const thisElementId = useMemo(
    () => `simple-activity-editor-card-${props.object.id}`,
    [props.object]
  );

  const visibilityOptions = useMemo(
    () =>
      ACTIVITY_VISIBILITIES(formatMessage).map((x) => ({
        ...x,
        name:
          x.id === 'E'
            ? formatMessage(
                {
                  id: 'app.views.widgets.cards.simple_activity_editor_card.everyone_at_organization',
                  defaultMessage: 'Everyone at {organizationName}',
                },
                { organizationName: props.organizationName }
              )
            : x.name,
      })),
    [props.organizationName, formatMessage]
  );

  const isActivityImported = useMemo(
    () => props.object.isImport,
    [props.object]
  );

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const toggleImportModal = useCallback(
    () => setIsImportModalOpen(!isImportModalOpen),
    [isImportModalOpen]
  );
  const importActivity = useCallback(
    (activity) => {
      const contribution = activity.contributions.find(
        (x) => x.contributor_person.id === props.person.id
      );
      props.callback({
        isImport: true,
        id: activity.id,
        contribution_id: contribution.id,
        description: contribution.description,
        organization: activity.organization.id,
        title: activity.name,
        visibility: activity.visibility,
        skills: contribution.skills,
      });
    },
    [props]
  );

  const clearImport = useCallback(() => {
    props.callback(null);
  }, [props]);

  const titlePopoverId = useMemo(
    () => `title-${props.object.id}`,
    [props.object]
  );

  const visibilityPopoverId = useMemo(
    () => `visibility-${props.object.id}`,
    [props.object]
  );

  const inputs = useMemo(
    () => [
      {
        inputElementId: titlePopoverId,
        name: 'title',
        placeholder: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.title',
          defaultMessage: 'Title',
        }),
        required: true,
        disabled: isActivityImported,
        className: 'fw-bold',
        maxLength: 255,
        postscript: (
          <div
            className="small text-primary"
            onClick={() =>
              isActivityImported ? clearImport() : toggleImportModal()
            }
            role="button"
            style={{
              position: 'relative',
              top: '-5px',
              left: '14px',
            }}
          >
            {formatMessage(
              {
                id: 'app.views.widgets.cards.simple_activity_editor_card.import_existing',
                defaultMessage:
                  '{isActivityImported, select, true {Undo import} other {or import existing...}}',
              },
              {
                isActivityImported: !!isActivityImported,
              }
            )}
          </div>
        ),
      },
      {
        ...CONTRIBUTION_EDITOR_ATTRIBUTES,
        label: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.what_impact_did_you_make',
          defaultMessage: 'What impact did you make?',
        }),
        name: 'description',
        className: 'simple-activity-editor-rte',
      },
      {
        label: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.what_skills',
          defaultMessage: 'What skills or behaviors did you use?',
        }),
        ...INPUT_ATTRIBUTES(formatMessage).SKILLS,
        helperText: formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.helper_text',
          defaultMessage: 'Example: communication, writing, patience',
        }),
        name: 'skills',
      },
      {
        inputElementId: `visibility-${props.object.id}`,
        required: true,
        disabled: isActivityImported,
        name: 'visibility',
        type: INPUT_TYPES.SELECT,
        clearable: false,
        searchable: false,
        defaultOptions: visibilityOptions,
        isOptionSelected: (option, selectValue) => {
          return selectValue.length > 0 && selectValue[0] === option.id;
        },
        defaultValue: props.defaultVisibility,
        label: (
          <>
            <span id={visibilityPopoverId}>
              {formatMessage({
                id: 'app.views.widgets.cards.simple_activity_editor_card.who_can_see',
                defaultMessage: 'Who can see your accomplishment?',
              })}{' '}
              <i
                id={visibilityPopoverId + '-help'}
                className="small text-primary fe fe-help-circle"
              />
            </span>
            <UncontrolledPopover
              placement="top"
              trigger="hover"
              target={visibilityPopoverId + '-help'}
            >
              <FormattedMessage
                id="app.views.widgets.cards.simple_activity_editor_card.everyone_at"
                defaultMessage="
              Everyone at {organizationName} has a profile page where
              others can see their accomplishments. You can choose to display
              this accomplishment on your profile or keep it private so only
              managers and above and HR can see."
                values={{
                  organizationName: props.organizationName,
                }}
              />
            </UncontrolledPopover>
          </>
        ),
        className: 'simple-activity-editor-card-visibility',
      },
    ],
    [
      clearImport,
      isActivityImported,
      props.defaultVisibility,
      props.object.id,
      props.organizationName,
      titlePopoverId,
      toggleImportModal,
      visibilityOptions,
      visibilityPopoverId,
      formatMessage,
    ]
  );

  const transformObjectBeforeSubmit = useCallback(
    (object) => {
      return {
        ...object,
        visibility: object?.visibility
          ? object.visibility.id
          : props.defaultVisibility,
      };
    },
    [props.defaultVisibility]
  );

  const validatedFormBackingData = useMemo(() => {
    const adaptedObjectVisibility = fromLegacyVisibilityToSimplified(
      props.object?.visibility,
      formatMessage
    );
    const visibility = visibilityOptions.find(
      (x) => x.id === (adaptedObjectVisibility || props.defaultVisibility)
    );
    return {
      ...props.object,
      visibility,
    };
  }, [formatMessage, props.defaultVisibility, props.object, visibilityOptions]);

  return (
    <div id={thisElementId}>
      <ModalActivitySelector
        campaign={campaign}
        isOpen={isImportModalOpen}
        onSelect={(activity) => importActivity(activity)}
        toggle={toggleImportModal}
        startDate={props.startDate}
        person={props.person}
        title={formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.import_existing_activity',
          defaultMessage: 'Import existing activity',
        })}
        description={formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.import_existing_activity_description',
          defaultMessage: 'Import an existing activity from your profile.',
        })}
        selectActivityText={formatMessage({
          id: 'app.views.widgets.cards.simple_activity_editor_card.select_activity',
          defaultMessage: 'Select activity',
        })}
        omit={props.omit}
      />
      <Card className={props.className} role={props.role} style={props.style}>
        <CardBody className="pb-1">
          <div
            className="avatar avatar-sm"
            style={{
              position: 'absolute',
              top: '-0.85rem',
              left: '-0.85rem',
            }}
          >
            <div
              className={
                'avatar-title fs-lg rounded-circle text-white ' + 'bg-warning'
              }
            >
              <i
                className={'fe fe-award'}
                style={{ position: 'relative', top: '-1px' }}
              ></i>
            </div>
          </div>
          <ValidatedForm
            className="mb-0"
            method="PATCH"
            url={undefined}
            callback={props.callback}
            submitOnChange={true}
            object={validatedFormBackingData}
            buttonClassName={'mt-0'}
            transformObjectBeforeSubmit={transformObjectBeforeSubmit}
            inputs={inputs}
            disableUnsavedChangesPrompt={props.disableUnsavedChangesPrompt}
            isSubmitting={props.isSubmitting}
          />
        </CardBody>
      </Card>
    </div>
  );
};

SimpleActivityEditorCard.defaultProps = {
  defaultVisibility: 'E',
};

SimpleActivityEditorCard.propTypes = {
  defaultVisibility: PropTypes.string.isRequired,
  campaign: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  callback: PropTypes.func.isRequired,
  className: PropTypes.object,
  organizationName: PropTypes.string,
  emptyStateText: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableUnsavedChangesPrompt: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default React.memo(SimpleActivityEditorCard);
