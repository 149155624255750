import React, { FC } from 'react';

import { Bar } from 'react-chartjs-2';

export const SINGLE_VALUE_PLACEHOLDER = 'single_value_placeholder';

const isSingleValue = (label) => {
  return label === SINGLE_VALUE_PLACEHOLDER;
};

interface Props {
  data: object;
  metric: string;
  field: string;
}

const CandlestickChart: FC<Props> = ({ field = '', ...props }) => {
  const options = {
    maintainAspectRatio: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const index = tooltipItem.index;
          const dsIndex = tooltipItem.datasetIndex;
          const label = data.datasets[dsIndex].label || '';

          let total = 0;
          for (let i = 0; i <= dsIndex; i++) {
            total += +data.datasets[i].data[index];
          }
          // display a single value in the tooltip in the case of percentiles colliding on the same value
          return isSingleValue(label) ? '' : label + ' ' + total.toFixed(1);
        },
        labelColor: () => {
          return {
            borderColor: 'white',
            backgroundColor: 'white',
          };
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: field,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: props.metric,
          },
        },
      ],
    },
  };

  return <Bar data={props.data} options={options} />;
};

export default React.memo(CandlestickChart);
