import { compress, decompress } from 'lz-string';

import config from '../util/config';

const getLocalStorageFullKey = (auth0SubId, currentProxyPerson, orgId, key) => {
  return (
    '@@confirm@@::' +
    process.env.REACT_APP_VERSION +
    '::' +
    compress(
      'user::' +
        auth0SubId +
        (currentProxyPerson?.email
          ? '::proxy::' + currentProxyPerson.email
          : '') +
        (orgId ? '::org::' + orgId : '') +
        '::' +
        key
    )
  );
};

const LOGGED_OUT_LOCAL_STORAGE_KEY = 'logged-out-locale';

const getLoggedOutLocaleKey = () => {
  return getLocalStorageFullKey('', '', '', LOGGED_OUT_LOCAL_STORAGE_KEY);
};

export const setLoggedOutLocaleInLocalStorage = (value) => {
  return localStorage.setItem(getLoggedOutLocaleKey(), value);
};

export const getLoggedOutLocaleFromLocalStorage = () => {
  return localStorage.getItem(getLoggedOutLocaleKey());
};

export const reverseLocalStorageFullKey = (fullKey) => {
  const compressedKey = fullKey.split('::').slice(-1)[0];
  let decompressedKey = compressedKey;
  try {
    decompressedKey = decompress(compressedKey);
  } catch (e) {
    console.log(e);
  }
  return decompressedKey;
};

// serialize and save object to local storage for given auth0 user
export const removeUserLocalStorage = (
  auth0SubId,
  currentProxyPerson,
  orgId,
  key
) => {
  const fullKey = getLocalStorageFullKey(
    auth0SubId,
    currentProxyPerson,
    orgId,
    key
  );
  return localStorage.removeItem(fullKey);
};

// serialize and save object to local storage for given auth0 user
export const setUserLocalStorage = (
  auth0SubId,
  currentProxyPerson,
  orgId,
  key,
  value,
  // for expiration, we encode it differently per
  // https://www.sohamkamani.com/blog/javascript-localstorage-with-ttl-expiry/
  ttlInMilliseconds = null,
  // generate and store UTC timestamp in MS per
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime
  withTimestamp = false
) => {
  const fullKey = getLocalStorageFullKey(
    auth0SubId,
    currentProxyPerson,
    orgId,
    key
  );

  if (value) {
    try {
      const valueToSave =
        ttlInMilliseconds || withTimestamp
          ? {
              value,
              // @ts-expect-error
              expiry: new Date().getTime() + ttlInMilliseconds,
              timestamp: new Date().getTime(),
            }
          : value;
      return localStorage.setItem(
        fullKey,
        compress(JSON.stringify(valueToSave))
      );
    } catch (e) {
      // quota exceeded, so clear local storage for now
      // If this happens it will mess up short sessions management (if in use).
      console.error('quota exceeded, clear local storage for now', e);
      localStorage.clear();
    }
  } else {
    return localStorage.removeItem(fullKey);
  }
};

// fetch and deserialize object from local storage for given auth0 user
export const getUserLocalStorage = (
  auth0SubId,
  currentProxyPerson,
  orgId,
  key,
  hasExpiry = false,
  withTimestamp = false
) => {
  const fullKey = getLocalStorageFullKey(
    auth0SubId,
    currentProxyPerson,
    orgId,
    key
  );

  const output = localStorage.getItem(fullKey);

  // parse and decompress, but if failure, just return undefined
  try {
    // no item to return
    if (!output) {
      return withTimestamp ? [null, null] : null;
    }

    const parsedOutput = JSON.parse(decompress(output));

    if (hasExpiry || withTimestamp) {
      // if expired, clear out storage and return null
      if (hasExpiry && new Date().getTime() > parsedOutput.expiry) {
        localStorage.removeItem(fullKey);
        return withTimestamp ? [null, null] : null;
      }

      if (withTimestamp) {
        return [parsedOutput.value, parsedOutput.timestamp];
      }

      return parsedOutput.value;
    } else {
      return parsedOutput;
    }
  } catch (err) {
    console.error(
      'Error fetching from local storage ' + key + ': ' + JSON.stringify(err)
    );
    return undefined;
  }
};

// default universal params for checking if we should show the user their various
// accounts to select for logging in
const DEFAULT_AUTH0_SUB_ID = 0;
const DEFAULT_PROXY_PERSON = null;
const DEFAULT_ORG_ID = null;
const HAS_SEEN_SELECT_ACCOUNT_PROMPT_KEY = 'has-seen-select-account-prompt';
export const SHOULD_SHOW_FORCED_AUTH_MODAL_KEY =
  'should-show-forced-auth-modal';

export const getHasSeenSelectAccountPrompt = () => {
  return getUserLocalStorage(
    DEFAULT_AUTH0_SUB_ID,
    DEFAULT_PROXY_PERSON,
    DEFAULT_ORG_ID,
    HAS_SEEN_SELECT_ACCOUNT_PROMPT_KEY
  );
};

export const setHasSeenSelectAccountPrompt = (value) => {
  setUserLocalStorage(
    DEFAULT_AUTH0_SUB_ID,
    DEFAULT_PROXY_PERSON,
    DEFAULT_ORG_ID,
    HAS_SEEN_SELECT_ACCOUNT_PROMPT_KEY,
    value
  );
};

export const getShouldShowForcedAuthModal = () => {
  return getUserLocalStorage(
    DEFAULT_AUTH0_SUB_ID,
    DEFAULT_PROXY_PERSON,
    DEFAULT_ORG_ID,
    SHOULD_SHOW_FORCED_AUTH_MODAL_KEY
  );
};

export const setShouldShowForcedAuthModal = (value) => {
  setUserLocalStorage(
    DEFAULT_AUTH0_SUB_ID,
    DEFAULT_PROXY_PERSON,
    DEFAULT_ORG_ID,
    SHOULD_SHOW_FORCED_AUTH_MODAL_KEY,
    value
  );
};

export const getUserRole = (authUser) => {
  return getUserIsSuperAdmin(authUser) ? 'admin' : null;
};

export const getUserIsSuperAdmin = (authUser) => {
  return (
    authUser && authUser['https://app.confirmhr.com/roles'].includes('admin')
  );
};

export const userHasCompletedWelcome = (myConfigs) => {
  return myConfigs?.welcome_completed_at ? true : false;
};

export const logOutAndCleanUp = (auth0LogoutFunction) => {
  // don't ask for which account when auto-refreshing session and checking if still
  // logged into third party used to authenticate
  setHasSeenSelectAccountPrompt(true);

  // log out fully
  // @ts-expect-error
  if (config.isProduction() || config.isUat()) {
    auth0LogoutFunction();
  } else {
    auth0LogoutFunction({ returnTo: window.location.origin });
  }

  // clear local storage (we do after logout to ensure no longering storage happens during logout)
  localStorage.clear();
};

const UNSAVED_CHANGES_TTL_IN_MS = 24 * 3600 * 1000; // 24 hours

export const storeUnsavedChanges = (
  auth0SubId,
  currentProxyPerson,
  orgId,
  name,
  value
) => {
  return setUserLocalStorage(
    auth0SubId,
    currentProxyPerson,
    orgId,
    name,
    value,
    // @ts-expect-error
    UNSAVED_CHANGES_TTL_IN_MS,
    true
  );
};

export const clearUnsavedChanges = (
  auth0SubId,
  currentProxyPerson,
  orgId,
  name
) => {
  return removeUserLocalStorage(auth0SubId, currentProxyPerson, orgId, name);
};

export const getUnsavedChanges = (
  auth0SubId,
  currentProxyPerson,
  orgId,
  name
) => {
  return getUserLocalStorage(
    auth0SubId,
    currentProxyPerson,
    orgId,
    name,
    true,
    true
  );
};
