// this should match the backend RelationshipType in models.py
export const RELATIONSHIP_TYPES = {
  REPORTS_TO: 'R',
  USED_TO_REPORT_TO: 'U',
  USED_TO_HAVE_AS_DIRECT_REPORT: 'T',
  ENERGIZED_BY: 'E',
  ADVISED_BY: 'A',
  HAS_AS_STAKEHOLDER: 'S',
  GIVES_GOLD_STAR_TO: 'G',
  GIVES_HEADS_UP_ABOUT: 'H',
  NEEDS_AS_HIGH_PRIORITY_PEER: 'P',
  IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR: 'I',
  HAS_DIRECT_REPORT_FEEDBACK_FOR: 'D',
  SELF: 'L',
};

export const getPluralNameByType = (type) => {
  if (type === RELATIONSHIP_TYPES.REPORTS_TO) {
    return 'direct reports';
  }
  if (type === RELATIONSHIP_TYPES.USED_TO_REPORT_TO) {
    return 'former direct reports';
  }
  if (type === RELATIONSHIP_TYPES.IS_CHOSEN_TO_WRITE_PEER_FEEDBACK_FOR) {
    return 'peers';
  }
  if (type === RELATIONSHIP_TYPES.HAS_DIRECT_REPORT_FEEDBACK_FOR) {
    return 'managers';
  }

  // default
  return 'coworkers';
};
