import { Me, Organization } from '../../types';
import React, { createRef } from 'react';

import { type IntlShape } from 'react-intl';
import { IncludeExcludeFilterType } from '../../views/Widgets/Inputs/IncludeExcludeFilter';

export const OBJECTIVE_VISIBILITY_EVERYONE = {
  id: 'E',
  name: 'Visible to others',
  icon: 'fe fe-unlock',
};

export const OBJECTIVE_VISIBILITY_MANAGER = {
  id: 'M',
  name: 'Just my manager and above',
  icon: 'fe fe-lock',
};

export const GOAL_VISIBILITIES = [
  OBJECTIVE_VISIBILITY_EVERYONE,
  OBJECTIVE_VISIBILITY_MANAGER,
];

// if oldObjToPersistStringsFrom, we replace what is returned with any
// string values here so that the UI doesn't change text from underneath the user
export const convertScoresAndWeightsToFloats = (
  obj,
  oldObjToPersistStringsFrom: Objective | null = null
) => {
  const scoreToUse =
    oldObjToPersistStringsFrom &&
    typeof oldObjToPersistStringsFrom.score === 'string'
      ? oldObjToPersistStringsFrom.score
      : typeof obj.score === 'string'
      ? format2Decimals(100 * parseFloat(obj.score))
      : typeof obj.score === 'number'
      ? obj.score
      : null;

  const scoreDenominaorToUse =
    oldObjToPersistStringsFrom &&
    typeof oldObjToPersistStringsFrom.score_denominator === 'string'
      ? oldObjToPersistStringsFrom.score_denominator
      : typeof obj.score_denominator === 'string'
      ? format2Decimals(100 * parseFloat(obj.score_denominator))
      : typeof obj.score_denominator === 'number'
      ? obj.score_denominator
      : null;

  const weightToUse =
    oldObjToPersistStringsFrom &&
    typeof oldObjToPersistStringsFrom.weight === 'string'
      ? oldObjToPersistStringsFrom.weight
      : typeof obj.weight === 'string'
      ? format2Decimals(100 * parseFloat(obj.weight))
      : typeof obj.weight === 'number'
      ? obj.weight
      : null;

  return {
    ...obj,
    score: scoreToUse,
    score_denominator: scoreDenominaorToUse,
    weight: weightToUse,
    children: obj?.children?.map((c, i) =>
      convertScoresAndWeightsToFloats(
        c,
        oldObjToPersistStringsFrom?.children
          ? oldObjToPersistStringsFrom.children[i]
            ? oldObjToPersistStringsFrom.children[i]
            : null
          : null
      )
    ),
  };
};

export interface GetObjectivesTreeResponseObjective {
  id: number;
  key: string;
  name: string;
  score: string;
  score_denominator: string;
  weight: string;
  collaborators: ObjectivePerson[];
  created_at: string;
  updated_at: string;
  owner_person: ObjectivePerson;
  coverage_start_date: string;
  coverage_end_date: string;
  status: string;
  due_date: string;
  auto_calculation: boolean;
  score_comments: string;
  score_comments_author_person: ObjectivePerson | null;
  parent: number;
  children: GetObjectivesTreeResponseObjective[];
  parent_objective: GetObjectivesTreeResponseObjective;
  related_to: ESObjective[];
}

export interface ObjectivePerson {
  id: number;
  email: string;
  given_name: string;
  family_name: string;
  full_name: string;
  url: string;
  avatar: string;
  title: string;
  status: string;
}
export interface GetObjectivesTreeResponse {
  results: GetObjectivesTreeResponseObjective[];
  accessible_people_ids: number[];
}

export interface Objective {
  id: number;
  key: string;
  name: string;
  coverage_start_date: string;
  coverage_end_date: string;
  // created_at: Date; ? do i need it at all? that cannot be changed
  due_date: string;
  owner_person: ObjectivePerson;
  score: number | null;
  score_denominator: number | null;
  weight: number | null;
  auto_calculation: boolean;
  score_comments?: string;
  score_comments_author_person?: Me;
  status: string;
  // updated_at: Date; TODO: check not needed
  // visibility: string; TODO: check not needed
  matched: boolean;
  children: Objective[] | null;
  parent: number | null;
  parent_objective?: Objective | null;
  parent_key?: string;
  related_to: ESObjective[];
  collaborators: ObjectivePerson[];
  organization: Organization;
}

export interface ObjectiveWithRef extends Objective {
  ref: React.RefObject<HTMLTextAreaElement>;
  isNew?: boolean;
  children: ObjectiveWithRef[] | null;
  parent_objective?: ObjectiveWithRef | null;
}

export interface ESObjective {
  owner_family_name: string;
  created_at: string;
  owner_given_name: string;
  related_to: number[];
  score: number;
  updated_at: string;
  collaborators: ESObjectivePerson[];
  manager_or_above: ESObjectiveManagerOrAbove[];
  visible_to_user: number[];
  id: number;
  owner_person: ESObjectivePerson;
  key: string;
  calculated_score: number;
  auto_calculation: boolean;
  visibility: string;
  parent_key: string;
  due_date: string;
  weight: number | null;
  deleted_at: string | null;
  is_objective: boolean;
  organization: Organization;
  name: string;
  coverage_end_date: string;
  score_denominator: number;
  coverage_start_date: string;
  status: string;
}
export interface ESObjectivePerson {
  id: number;
  avatar: string;
  given_name: string;
  title: string;
  family_name: string;
  email: string;
  url: string;
  status: string;
}

export interface ESObjectiveManagerOrAbove {
  id: number;
  given_name: string;
  family_name: string;
  email: string;
}

export interface ApiObjectiveGet {
  id: number;
  key?: string;
  coverage_start_date: string;
  coverage_end_date: string;
  due_date: string;
  name: string;
  score: number | null;
  score_denominator: number | null;
  weight: number | null;
  score_comments?: string;
  score_comments_author_person?: Me;
  status: string;
  parent: number | null;
  parent_key?: string;
}

export interface ObjectiveScore {
  score: number | undefined;
  score_denominator: number | undefined;
  weight: number | undefined;
}

export interface ObjectiveCalculatedScores {
  item: ObjectiveScore;
  derived: ObjectiveScore;
}

export type ObjectiveStatusCount = {
  T: number;
  R: number;
  F: number;
  P: number;
  N: number;
};

export type ObjectiveDescendantsStats = {
  totalDescendants: number;
  descedantsStatusBreakdown: ObjectiveStatusCount;
  matchDescendants: boolean;
};

const calculaterDerivedScore = (objective: Objective): number => {
  if (!objective.auto_calculation) {
    return objective.score || 0;
  }
  return (objective?.children ?? []).reduce((acc, child: Objective) => {
    const derivedWeight =
      child.weight || calculateDerivedWeight(child, objective);

    const childScore =
      child.children && !child.score
        ? calculaterDerivedScore(child) * 100
        : child.score;

    return (
      acc +
      (derivedWeight * (childScore || 0)) /
        (child.score_denominator || 100) /
        100
    );
  }, 0);
};

const calculateDerivedWeight = (
  objective: Objective,
  parentObjective: Objective | null
) => {
  if (!parentObjective) {
    return 100;
  }
  const result = parentObjective.children?.reduce(
    (acc, child) => {
      return {
        weightNotSet: acc.weightNotSet - (child.weight || 0),
        itemsWithDefault: acc.itemsWithDefault + (child.weight ? 0 : 1),
      };
    },
    { weightNotSet: 100, itemsWithDefault: 0 }
  );

  return result ? result.weightNotSet / result.itemsWithDefault : 100;
};

export const format2Decimals = (value) =>
  value ? (Math.round(value * 100) / 100).toString() : value;

export const formatNumber4Decimals = (value) =>
  value ? value.toFixed(4) : value;

export const formatUnit = (value) =>
  value ? Math.round(value).toString() : value;

const EMPTY_STATUS_BREAKDOWN: ObjectiveStatusCount = {
  T: 0,
  R: 0,
  F: 0,
  P: 0,
  N: 0,
};

const calculateStatusBreakdown = (
  acc: ObjectiveDescendantsStats,
  childStats: ObjectiveDescendantsStats,
  child: Objective
): ObjectiveStatusCount => {
  return Object.entries(acc.descedantsStatusBreakdown).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        value +
        childStats.descedantsStatusBreakdown[key] +
        (child.status === key || (!child.status && key === 'N') ? 1 : 0),
    }),
    acc.descedantsStatusBreakdown
  );
};

export const calculateDescendantsStats = (
  objective: Objective
): ObjectiveDescendantsStats => {
  const eligibleChildren = objective?.children?.filter((it) => !!it.name) ?? [];

  return eligibleChildren.reduce(
    (acc: ObjectiveDescendantsStats, child: Objective) => {
      const childStats = calculateDescendantsStats(child);
      return {
        totalDescendants:
          acc.totalDescendants + childStats.totalDescendants + 1,
        descedantsStatusBreakdown: calculateStatusBreakdown(
          acc,
          childStats,
          child
        ),
        matchDescendants:
          acc.matchDescendants || child.matched || childStats.matchDescendants,
      };
    },
    {
      totalDescendants: 0,
      descedantsStatusBreakdown: EMPTY_STATUS_BREAKDOWN,
      matchDescendants: false,
    }
  );
};

export const calculateScores = (
  objective: Objective,
  parentObjective: Objective | null = null
): ObjectiveCalculatedScores => {
  const derived_score = calculaterDerivedScore(objective);

  const score = objective.score || undefined;
  const score_denominator = objective.score_denominator || undefined;
  const weight = objective.weight || undefined;

  const final_score = score || Math.round(derived_score * 100);
  const final_denominator = score_denominator || 100;
  const final_weight =
    weight || calculateDerivedWeight(objective, parentObjective);

  return {
    item: {
      score,
      score_denominator,
      weight,
    },
    derived: {
      score: final_score,
      score_denominator: final_denominator,
      weight: final_weight,
    },
  };
};

export type StatusDescriptorType = {
  color: string;
  label: string;
  i18n: string;
  status: string | undefined;
  colorHex: string;
};

export const STATUS_DESCRIPTOR = (intl: IntlShape): StatusDescriptorType[] => [
  {
    color: 'success',
    label: 'On track',
    i18n: intl.formatMessage({
      id: 'app.objectives.item.status.on_track',
      defaultMessage: 'On track',
    }),
    status: 'T',
    colorHex: '#00d97e',
  },
  {
    color: 'warning',
    label: 'At risk',
    i18n: intl.formatMessage({
      id: 'app.objectives.item.status.at_risk',
      defaultMessage: 'At risk',
    }),
    status: 'R',
    colorHex: '#f6c343',
  },
  {
    color: 'danger',
    label: 'Off track',
    i18n: intl.formatMessage({
      id: 'app.objectives.item.status.off_track',
      defaultMessage: 'Off track',
    }),
    status: 'F',
    colorHex: '#e63757',
  },
  {
    color: 'dark',
    label: 'Postponed',
    i18n: intl.formatMessage({
      id: 'app.objectives.item.status.postponed',
      defaultMessage: 'Postponed',
    }),
    status: 'P',
    colorHex: '#12263F',
  },
];

export const STATUS_DESCRIPTOR_NOT_SET = (intl: IntlShape) => ({
  color: 'light-gray',
  label: 'Not yet started',
  i18n: intl.formatMessage({
    id: 'app.objectives.item.status.not_yet_started',
    defaultMessage: 'Not yet started',
  }),
  status: 'N',
  colorHex: '#E3EBF6',
});

export const statusDescriptorFor = (status, intl) => {
  return STATUS_DESCRIPTOR(intl).find((x) => x.status === status);
};

// N.B. the newest api for this is not supported by Opera, whereas this
// deprecated api is still supported everywhere.
//
// TODO: wrap into a util function since this is version sensitive and
// likely replaced later once Opera is updated (e.g. navigatedViaBackArrow())

export const navigationArrowBackClicked = () => {
  return (
    performance.navigation.type === PerformanceNavigation.TYPE_BACK_FORWARD
  );
};
export const parseObjectivesForDisplay = (
  o: GetObjectivesTreeResponseObjective
): ObjectiveWithRef => {
  return {
    ...o,
    // @ts-expect-error //that should be parsed for coherence but it's not used
    parent_objective: o?.parent_objective,
    score: o.score ? parseFloat(o.score) * 100 : null,
    score_denominator: o.score_denominator
      ? parseFloat(o.score_denominator) * 100
      : null,
    weight: o.weight ? parseFloat(o.weight) * 100.0 : null,
    children:
      o.children?.length > 0 ? o.children.map(parseObjectivesForDisplay) : null,
    ref: createRef(),
  };
};

export type ObjectiveFilterType = {
  status: string[];
  includeExclude?: IncludeExcludeFilterType;
};

export const filterObjectiveTreeList = (
  objectives: Objective[] | null,
  filter: ObjectiveFilterType
): Objective[] | null => {
  if (!objectives) {
    return null;
  }

  if (filter.status.length === 0) {
    return objectives;
  }
  return objectives.reduce((acc: Objective[], o: Objective) => {
    const filteredObjective: Objective = {
      ...o,
      children: filterObjectiveTreeList(o.children, filter),
    };
    if (
      filter.status.includes(o.status || 'N') ||
      (filteredObjective?.children ?? []).length > 0
    ) {
      return [...acc, filteredObjective];
    }
    return acc;
  }, []);
};

export type ViewScope =
  | 'everyone'
  | 'my_full_team'
  | 'my_direct_reports'
  | 'my_objectives';

export type OnFilterChange = {
  scope?: ViewScope;
  status?: string[];
  peopleFilters?: IncludeExcludeFilterType;
  textFilter?: string;
};
