import {
  BREAKDOWN_FIELDS,
  getFormattedLabel,
} from '../../../utils/models/Takeaways';
import CandlestickChart, {
  SINGLE_VALUE_PLACEHOLDER,
} from '../Charts/CandlestickChart';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  PERFORMANCE_METRICS,
  PERFORMANCE_METRIC_NAMES,
} from '../../../consts/consts';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import DashboardDropdown from '../Dropdowns/DashboardDropdown';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';

const FIELDS_EXCLUSION_LIST = ['individual'];

const CandlestickChartCard: FC<Props> = ({ showMetric = true, ...props }) => {
  const { formatMessage } = useIntl();
  const emptyStateText =
    // @ts-expect-error
    props.emptyStateText ??
    formatMessage({
      id: 'app.views.widgets.cards.candlestick_chart_card.empty_state_text',
      defaultMessage: 'No data is available.',
    });

  const [currentIndex, setCurrentIndex] = useState(0);
  const [field, setField] = useState(props.field);

  const [metric, setMetric] = useState(props.metric);
  const [metricIndex, setMetricIndex] = useState(0);

  const fields = useMemo(
    () =>
      // @ts-expect-error
      Object.keys(props.data).filter(
        // @ts-expect-error
        (x) => !FIELDS_EXCLUSION_LIST.includes(x) && props.data[x]?.length > 1
      ),
    [props.data]
  );

  const options = useMemo(
    () =>
      fields.map((x, index) => [
        BREAKDOWN_FIELDS(formatMessage)[x] || x,
        index,
      ]),
    [fields, formatMessage]
  );

  useEffect(() => {
    if (!field && fields) {
      setField(fields[0]);
    }
  }, [field, fields, setField]);

  const levelMessage = formatMessage({
    id: 'app.views.widgets.cards.candlestick_chart_card.field.level',
    defaultMessage: 'Level',
  });

  const formatField = useCallback(
    (str) =>
      str
        ? str === 'level_id'
          ? levelMessage
          : str[0].toUpperCase() + str.slice(1).toLowerCase().replace(/_/g, ' ')
        : '',
    [levelMessage]
  );

  const fieldDropdown = useMemo(
    () =>
      fields.length ? (
        <DashboardDropdown
          options={options}
          selected={options[currentIndex][0]}
          onClick={(index) => {
            setCurrentIndex(index);
          }}
        />
      ) : (
        <div />
      ),
    [currentIndex, fields.length, options]
  );

  const metricDropdown = useMemo(
    () => (
      <DashboardDropdown
        options={PERFORMANCE_METRICS.map((x, index) => [formatField(x), index])}
        selected={formatField(PERFORMANCE_METRICS[metricIndex])}
        onClick={(index) => {
          setMetricIndex(index);
          setMetric(PERFORMANCE_METRICS[index]);
        }}
      />
    ),
    [formatField, metricIndex]
  );

  const preparedData = useMemo(() => {
    if (!fields.length) return props.data;
    // @ts-expect-error
    const data = props.data[fields[currentIndex]];
    if (!data) {
      return undefined;
    }

    const datapoints = data.map((p) => {
      const stats = p[`${metric}__stats`];
      const avg = stats.average;

      return {
        label: p.name,
        avg: avg.toFixed(1),
        perc_25: stats.p25,
        perc_75: stats.p75,
        min: stats.min,
        max: stats.max,
      };
    });

    return {
      datasets: [
        {
          label: formatMessage({
            id: 'app.views.widgets.cards.candlestick_chart_card.dataset.min.label',
            defaultMessage: 'Min:',
          }),
          data: datapoints.map((p) => p.min),
          backgroundColor: 'white',
        },
        {
          label: formatMessage({
            id: 'app.views.widgets.cards.candlestick_chart_card.dataset.25.label',
            defaultMessage: '25%:',
          }),
          data: datapoints.map((p) => (p.perc_25 - p.min).toFixed(1)),
          backgroundColor: props.color,
          barThickness: 1,
        },
        {
          label: formatMessage({
            id: 'app.views.widgets.cards.candlestick_chart_card.dataset.75.label',
            defaultMessage: '75%:',
          }),
          data: datapoints.map((p) => (p.perc_75 - p.perc_25).toFixed(1)),
          backgroundColor: props.color,
          barThickness: 10,
        },
        {
          label: formatMessage({
            id: 'app.views.widgets.cards.candlestick_chart_card.dataset.max.label',
            defaultMessage: 'Max:',
          }),
          data: datapoints.map((p) => (p.max - p.perc_75).toFixed(1)),
          backgroundColor: props.color,
          barThickness: 1,
        },
        {
          label: SINGLE_VALUE_PLACEHOLDER,
          data: datapoints.map((p) => (p.max == p.min && p.min != 0 ? 0.5 : 0)),
          backgroundColor: props.color,
          barThickness: 10,
        },
      ],
      labels: datapoints.map((p) =>
        getFormattedLabel(fields[currentIndex], p.label)
      ),
    };
  }, [fields, props.data, props.color, currentIndex, metric, formatMessage]);

  return fields.length ? (
    // @ts-expect-error
    <Card className={props.className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>
          {showMetric
            ? metricDropdown
            : // @ts-expect-error
              PERFORMANCE_METRIC_NAMES(formatMessage)[props.metric]}{' '}
          <FormattedMessage
            id="app.views.widgets.cards.candlestick_chart_card.distribution_by"
            defaultMessage="distribution by"
          />{' '}
          {fieldDropdown}
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {props.beforeContent && (
          <div className="mb-4">{props.beforeContent}</div>
        )}
        {/* @ts-expect-error */}
        {props?.data && props?.data[field] ? (
          <div>
            <CandlestickChart
              field={options[currentIndex][0]}
              // @ts-expect-error
              metric={PERFORMANCE_METRIC_NAMES(formatMessage)[metric]}
              // @ts-expect-error
              data={preparedData}
              rawData={props.data[fields[currentIndex]]}
              color={props.color}
            />
          </div>
        ) : (
          <EmptyState title={emptyStateText} />
        )}
        {props.afterContent && <div className="mt-4">{props.afterContent}</div>}
      </CardBody>
    </Card>
  ) : (
    <div />
  );
};

const CandlestickChartCard_propTypes = {
  data: PropTypes.object,
  field: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  metric: PropTypes.string,
  showMetric: PropTypes.bool,
  style: PropTypes.object,
  beforeContent: PropTypes.node,
  afterContent: PropTypes.node,
};

type Props = PropTypes.InferProps<typeof CandlestickChartCard_propTypes>;

export default React.memo(CandlestickChartCard);
