import { FormattedMessage, useIntl } from 'react-intl';
import ObjectivesOverviewCSVGenerationBar, {
  CompletionBar,
} from './ObjectivesOverviewCSVGenerationBar';
import { Organization, ReduxState } from '../../../types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Button } from 'reactstrap';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import { ICONS } from '../../../consts/consts';
import { IncludeExcludeFilterType } from '../../Widgets/Inputs/IncludeExcludeFilter';
import { ViewScope } from '../../../utils/models/Objective';
import { autoDownloadLink } from '../../../utils/util/util';
import { formatDateWithUnicode } from '../../../utils/util/time';
import { toast } from 'react-toastify';
import { useConfirmApi } from '../../../utils/api/ApiHooks';
import { useSelector } from 'react-redux';

type Props = {
  disabled: boolean;
  firstDay: string;
  lastDay: string;
  status: string[];
  scope: ViewScope;
  peopleFilters: IncludeExcludeFilterType;
  textFilter: string;
  onGenerateCSV: () => void;
  onGenerateCSVCompleted: () => void;
};

const POLL_FREQUENCY = 1000;

const ObjectivesOverviewCSVDownloader: FC<Props> = ({
  disabled,
  textFilter,
  status,
  scope,
  firstDay,
  lastDay,
  peopleFilters,
  onGenerateCSV,
  onGenerateCSVCompleted,
}: Props) => {
  const currentProxyPersonEmail = useSelector<ReduxState, string | undefined>(
    (state) => state?.currentProxyPerson?.email
  );
  const currentOrganization = useSelector<ReduxState, Organization | undefined>(
    (state) => state.currentOrganization
  );
  const [generationRequested, setGenerationRequested] = useState(false);
  const [completion, setCompletion] = useState<CompletionBar>({
    progress: 0,
    total: 100,
  });
  const timers = useRef<NodeJS.Timeout[]>([]);

  const { formatMessage } = useIntl();

  const {
    data,
    error,
    status: apiStatus,
  } = useConfirmApi<{ status: string; task_key: string }>({
    method: 'POST',
    url: '/objectives/overview',
    params: {
      coverage_start_date: firstDay,
      coverage_end_date: lastDay,
      scope: scope,
      status: status,
      people_filters: peopleFilters,
      text_filter: textFilter,
      media_type: 'text/csv',
    },
    disabled: !generationRequested,
  });

  const handleRequestCompleted = useCallback(() => {
    onGenerateCSVCompleted();
    setGenerationRequested(false);
    setCompletion({ progress: 0, total: 100 });
    timers.current.forEach(clearTimeout);
    timers.current = [];
  }, [onGenerateCSVCompleted]);

  useEffect(() => {
    if (data) {
      if (data.status === 'submitted') {
        const poll = async () => {
          ConfirmAPI.sendRequestToConfirm(
            'GET',
            '/backgroundtasks/' + data.task_key + '/status',
            {
              proxy: currentProxyPersonEmail,
              organization_id: currentOrganization?.id,
            },
            (data_status) => {
              setCompletion(data_status);
              if (data_status?.status === 'C') {
                ConfirmAPI.sendRequestToConfirm(
                  'GET',
                  '/backgroundtasks/' + data.task_key + '/output',
                  {
                    proxy: currentProxyPersonEmail,
                    organization_id: currentOrganization?.id,
                  },
                  (data) => {
                    autoDownloadLink({
                      data: data,
                      filename: `okrs_${
                        currentOrganization?.name
                      }_${formatDateWithUnicode(
                        new Date(),
                        'yyyy_MM_dd_hh_mm_ss'
                      )}.csv`,
                      mime: 'text/csv',
                    });
                    handleRequestCompleted();
                  }
                );
              } else {
                timers.current.push(setTimeout(poll, POLL_FREQUENCY));
              }
            }
          );
        };
        timers.current.push(setTimeout(poll, POLL_FREQUENCY));
      }
    }
    if (error) {
      console.log(error);
      toast.error(
        formatMessage({
          id: 'app.views.objectives.objectives_overview_page.error_generating_csv',
          defaultMessage: 'Error generating CSV',
        })
      );
      handleRequestCompleted();
    }
  }, [
    data,
    error,
    formatMessage,
    handleRequestCompleted,
    currentProxyPersonEmail,
    currentOrganization?.id,
    currentOrganization?.name,
  ]);

  const handleClick = useCallback(() => {
    onGenerateCSV();
    setGenerationRequested(true);
  }, [onGenerateCSV]);

  return (
    <>
      <Button
        className="btn btn-sm btn-light float-end"
        onClick={handleClick}
        disabled={disabled || apiStatus !== 'DISABLED'}
        hidden={generationRequested}
      >
        <i className={ICONS.EXPORT + ' me-2'} />
        <FormattedMessage
          id="app.views.objectives.objectives_overview_page.export_to_csv"
          defaultMessage="Export to CSV"
        />
        {apiStatus === 'LOADING' && (
          <i
            className="ms-2 spinner-border me-2"
            style={{
              width: '0.8rem',
              height: '0.8rem',
            }}
          />
        )}
      </Button>
      {generationRequested && (
        <ObjectivesOverviewCSVGenerationBar
          {...completion}
          extraText={formatMessage({
            id: 'app.views.objectives.objectives_overview_page.progress_text_generating_csv',
            defaultMessage: 'Generating CSV file...',
          })}
        />
      )}
    </>
  );
};

export default ObjectivesOverviewCSVDownloader;
