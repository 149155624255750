import React, { Fragment, useCallback } from 'react';
import ValidatedAutosuggest, {
  renderSuggestion,
} from '../Inputs/ValidatedAutosuggest';
import { FormattedMessage, useIntl } from 'react-intl';

import PropTypes from 'prop-types';

export const getFilterESParams = (excludeList, objectFilters) => {
  if (!(objectFilters?.length > 0)) {
    // return undefined instead of null to ensure no param is passed in
    return undefined;
  }

  return {
    activities: excludeList.includes('activities')
      ? undefined
      : objectFilters.filter((f) => f._index === 'activities').map((f) => f.id),
    skills: excludeList.includes('skills')
      ? undefined
      : objectFilters.filter((f) => f._index === 'skills').map((f) => f.id),
    credentials: excludeList.includes('credentials')
      ? undefined
      : objectFilters
          .filter((f) => f._index === 'credentials')
          .map((f) => f.id),
    people: excludeList.includes('people')
      ? undefined
      : objectFilters.filter((f) => f._index === 'people').map((f) => f.id),
  };
};

const FilterInput = (props) => {
  const propsSetObjectFilters = props.setObjectFilters;
  const propsObjectFilters = props.objectFilters;
  const propsOnChange = props.onChange;
  const intl = useIntl();

  const onChangeObject = useCallback(
    (object) => {
      propsSetObjectFilters([...propsObjectFilters, object]);

      // clear input
      propsOnChange({
        target: {
          name: 'q',
          value: '',
        },
      });
    },
    [propsSetObjectFilters, propsObjectFilters, propsOnChange]
  );

  const onRemoveObjectFilter = useCallback(
    (o) => {
      propsSetObjectFilters(propsObjectFilters.filter((o2) => o.id !== o2.id));
    },
    [propsSetObjectFilters, propsObjectFilters]
  );

  const renderObject = useCallback(
    (o) => {
      return renderSuggestion(
        intl,
        o,
        '',
        true,
        'xxs',
        false,
        'Remove filter',
        () => onRemoveObjectFilter(o),
        true,
        false,
        false
        //validationFunction
      );
    },
    [intl, onRemoveObjectFilter]
  );

  const activityFilters = props.objectFilters?.filter(
    (f) => f._index === 'activities'
  );
  const personFilters = props.objectFilters?.filter(
    (f) => f._index === 'people'
  );
  const skillFilters = props.objectFilters?.filter(
    (f) => f._index === 'skills'
  );
  const credentialFilters = props.objectFilters?.filter(
    (f) => f._index === 'credentials'
  );

  const spanClasses = 'd-inline-block text-muted mt-2 me-2';

  const placeholder =
    props.placeholder ??
    intl.formatMessage({
      id: 'app.widgets.search.filter_input.placeholder',
      defaultMessage: 'Filter by name',
      description: 'Placeholder for the filter input',
    });

  return (
    <>
      <ValidatedAutosuggest
        size="sm"
        className="w-100 mb-4"
        iconClassName="fe fe-search"
        placeholder={placeholder}
        name="q"
        value={props.value}
        onInputChange={props.onChange}
        onSuggestionSelected={onChangeObject}
        autoFocus={props.autoFocus}
        suggestions={props.suggestions}
        clearInputOnSelect={true}
        dropdownMenuClass={'dropdown-menu-card'}
        elasticsearchOptions={
          props.fetchObjectsFromElasticsearch
            ? {
                url: 'get-all-by-name',
                getQuery: (q) => ({
                  exclude: props.excludeIndices,
                  name: q,
                  sort: [
                    {
                      status: {
                        unmapped_type: 'keyword',
                        order: 'asc',
                      },
                      'full_name.raw': {
                        unmapped_type: 'keyword',
                        order: 'asc',
                      },
                    },
                  ],
                }),
              }
            : undefined
        }
      />
      {props.objectFilters?.length > 0 && (
        <>
          <span className="d-inline-block text-muted mt-2">
            <FormattedMessage
              id="app.widgets.search.filter_input.showing"
              defaultMessage="Showing {typeDisplayName}"
              description="Prefix for the filter input result display"
              values={{ typeDisplayName: props.typeDisplayName }}
            />
            &nbsp;
          </span>
          {activityFilters?.length > 0 && (
            <>
              <span className={spanClasses}>
                {' '}
                <FormattedMessage
                  id="app.widgets.search.filter_input.for"
                  defaultMessage="for"
                  description="Word before a list of activities"
                />{' '}
              </span>
              {activityFilters.map((o, index) => (
                <Fragment key={index}>{renderObject(o)}</Fragment>
              ))}
            </>
          )}
          {personFilters?.length > 0 && (
            <>
              <span className={spanClasses}>
                {' '}
                <FormattedMessage
                  id="app.widgets.search.filter_input.with"
                  defaultMessage="with"
                  description="Word before a list of people"
                />{' '}
              </span>
              {personFilters.map((o, index) => (
                <Fragment key={index}>{renderObject(o)}</Fragment>
              ))}
            </>
          )}
          {skillFilters?.length > 0 && (
            <>
              <span className={spanClasses}>
                {' '}
                <FormattedMessage
                  id="app.widgets.search.filter_input.using"
                  defaultMessage="using"
                  description="Word before a list of skills"
                />{' '}
              </span>
              {skillFilters.map((o, index) => (
                <Fragment key={index}>{renderObject(o)}</Fragment>
              ))}
            </>
          )}
          {credentialFilters?.length > 0 && (
            <>
              <span className={spanClasses}>
                {' '}
                <FormattedMessage
                  id="app.widgets.search.filter_input.credentialed"
                  defaultMessage="credentialed"
                  description="Word before a list of credentials"
                />{' '}
              </span>
              {credentialFilters.map((o, index) => (
                <Fragment key={index}>{renderObject(o)}</Fragment>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

FilterInput.defaultProps = {
  autoFocus: false,
};

FilterInput.propTypes = {
  typeDisplayName: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  // list of indices that can be passed to ElasticSearch as a comma-separated string
  excludeIndices: PropTypes.string,
  objectFilters: PropTypes.arrayOf(PropTypes.object),
  setObjectFilters: PropTypes.func,
  fetchObjectsFromElasticsearch: PropTypes.bool,
};

// all tracking in app will be passed through here
export default React.memo(FilterInput);
