import React, { FC, useCallback, useState } from 'react';
import { Campaign, Organization, Relationship } from '../../../types';
import { Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import ConfirmAPI from '../../../utils/api/ConfirmAPI';
import { renderErrorOrCallback } from '../../../utils/util/util';
import ConfirmationDialogModal from '../../Widgets/Modals/ConfirmationDialogModal';

interface RecalculatePeersButtonProps {
  organization: Organization;
  campaign: Campaign;
  callback: (relationships: Relationship[]) => void;
}

const RecalculatePeersButton: FC<RecalculatePeersButtonProps> = ({
  organization,
  campaign,
  callback,
}) => {
  const { formatMessage } = useIntl();
  const [confirmRegenerateModal, setConfirmRegenerateModal] =
    useState<boolean>(false);

  const toggleConfirmRegenerateModal = useCallback(() => {
    setConfirmRegenerateModal(!confirmRegenerateModal);
  }, [confirmRegenerateModal]);

  const onRegeneratePeersSuccess = useCallback(
    (data: { peer_relationships: Relationship[] }) => {
      if (data?.peer_relationships?.length > 0) {
        toast.success(
          formatMessage({
            id: 'app.views.performance.participation_dashboard.regenerate_peers.success',
            defaultMessage: 'Peer feedback assignments regenerated!',
          })
        );
      } else {
        toast.warning(
          formatMessage({
            id: 'app.views.performance.participation_dashboard.regenerate_peers.warning',
            defaultMessage:
              'There were no peer feedback assignments to regenerate. Please wait until the self phase has ended and try again.',
          })
        );
      }
      callback(data?.peer_relationships ?? []);
    },
    [formatMessage, callback]
  );

  const onRegeneratePeersError = useCallback(() => {
    toast.error(
      formatMessage({
        id: 'app.views.performance.participation_dashboard.regenerate_peers.error',
        defaultMessage:
          'There was an error regenerating the peer feedback assignments',
      })
    );
  }, [formatMessage]);

  const onRegeneratePeers = useCallback(() => {
    setConfirmRegenerateModal(true);
  }, [setConfirmRegenerateModal]);

  const confirmRegenerateCallback = useCallback(() => {
    setConfirmRegenerateModal(false);
    ConfirmAPI.sendRequestToConfirm(
      'POST',
      '/performance/admin/regenerate-peers',
      { organization: organization.id, campaign: campaign?.id },
      renderErrorOrCallback(onRegeneratePeersSuccess, onRegeneratePeersError),
      null,
      null
    );
  }, [
    campaign,
    onRegeneratePeersSuccess,
    onRegeneratePeersError,
    organization.id,
  ]);

  return (
    <>
      <ConfirmationDialogModal
        isOpen={confirmRegenerateModal}
        onClosed={() => setConfirmRegenerateModal(false)}
        toggle={toggleConfirmRegenerateModal}
        confirmCallback={confirmRegenerateCallback}
        title={formatMessage({
          id: 'app.views.performance.participation_dashboard.regenerate_peers.confirm.title',
          defaultMessage: 'Regenerate peer feedback assignments?',
        })}
        description={formatMessage({
          id: 'app.views.performance.participation_dashboard.regenerate_peers.confirm.description',
          defaultMessage:
            'Are you sure you want to regenerate the peer feedback assignments? This may delete existing peer feedback assignments. This action cannot be undone.',
        })}
        confirmText={formatMessage({
          id: 'app.views.performance.participation_dashboard.regenerate_peers.confirm.confirm_text',
          defaultMessage: 'Continue',
        })}
        confirmationButtonColor={'primary'}
      />
      <Button
        color="primary"
        onClick={onRegeneratePeers}
        style={{ whiteSpace: 'nowrap' }}
      >
        <FormattedMessage
          id="app.views.performance.participation_dashboard.regenerate_peers"
          defaultMessage="Regenerate peer feedback assignments"
        />
      </Button>
    </>
  );
};

export default RecalculatePeersButton;
