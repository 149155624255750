import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ConfirmIntlProvider } from '../../../locale/ConfirmIntlContext';
import { PERFORMANCE } from '../../../consts/consts';
import PersonCard from '../Cards/PersonCard';
import ReactDOM from 'react-dom/client';
import { Scatter } from 'react-chartjs-2';

interface Props {
  data: object;
  metric: string;
  field?: string;
  rawData: Array<object>;
  hasRatings: boolean;
}

const ScatterPlot: FC<Props> = (props) => {
  // const calculateLimit = useCallback(
  //   (axis, max = true) => {
  //     const AXES_PADDING = 5;
  //     const dataPoints = data.datasets[0].data;
  //     const values = dataPoints.map((p) => p[axis]);
  //     const padding = Math.min(
  //       AXES_PADDING,
  //       Math.ceil((Math.max(...values) - Math.min(...values)) / 20)
  //     );
  //     return max
  //       ? Math.max(...values) + padding
  //       : Math.min(...values) - padding;
  //   },
  //   [data]
  // );
  const { formatMessage } = useIntl();

  const calculateStepSize = useCallback(
    (axis) => {
      // @ts-expect-error
      const dataPoints = props.data.datasets[0].data;
      const values = dataPoints.map((p) => p[axis]);
      return Math.ceil((Math.max(...values) - Math.min(...values)) / 5);
    },
    [props.data]
  );

  const options = {
    maintainAspectRatio: true,
    events: ['mousemove'],
    tooltips: {
      enabled: false,
      mode: 'point',
      custom: function (tooltipModel) {
        const TOOLTIP_ID = 'scatterplot-chartjs-tooltip';

        // Tooltip Element
        let tooltipEl = document.getElementById(TOOLTIP_ID);

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          if (tooltipEl) {
            tooltipEl.remove();
          }
          return;
        }

        if (tooltipModel.dataPoints) {
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = TOOLTIP_ID;
            document.body.appendChild(tooltipEl);
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          let tooltipBody;
          // Set Text
          if (tooltipModel.body) {
            const index = tooltipModel.dataPoints[0].index;
            const dataPoint = props.rawData[index];
            if (dataPoint) {
              tooltipBody = (
                <ScatterPlotTooltip
                  data={dataPoint}
                  hasRatings={props.hasRatings}
                />
              );
            }
          }

          // `this` will be the overall tooltip
          // @ts-expect-error
          const position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          // @ts-expect-error
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          // @ts-expect-error
          ReactDOM.createRoot(document.getElementById(TOOLTIP_ID)).render(
            tooltipBody
          );
        }
      },
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: formatMessage({
              id: 'app.widgets.charts.scatterplot.x_axes.label.tenure',
              defaultMessage: 'Tenure (months)',
            }),
          },
          offset: true,
          ticks: {
            display: true,
            stepSize: calculateStepSize('x'),
            min: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: props.metric,
          },
          offset: true,
          ticks: {
            stepSize: calculateStepSize('y'),
            min: 0,
          },
          gridLines: {
            display: true,
            color: '#edf2f9',
            lineWidth: 2,
          },
        },
      ],
    },
  };

  return <Scatter data={props.data} options={options} />;
};

const ScatterPlotTooltip = (props) => {
  return (
    <div
      style={{
        width: '315px', // making large enough that 3 digit months tenures don't overflow to second line
        borderRadius: '10px',
        border: '1px solid lightGray',
        backgroundColor: 'white',
        padding: '20px',
      }}
    >
      <BrowserRouter>
        <ConfirmIntlProvider>
          <PersonCard
            size="sm"
            person={props?.data}
            hidePopover
            bodyOnly
            linked
            isExternalUrl
            urlSuffix={PERFORMANCE().path + '?manager=true'}
          >
            <Row className="mt-2">
              <Col className="col-6">
                <span className="me-2 fe fe-share-2 text-primary"></span>
                <FormattedMessage
                  id="app.views.widgets.chart.scatterplot.influence"
                  defaultMessage="<bold>{influenceCount}</bold> influence"
                  values={{
                    influenceCount: props?.data?.influence,
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </Col>
              <Col className="col-6">
                <span className="me-2 fe fe-star text-warning"></span>
                <FormattedMessage
                  id="app.views.widgets.chart.scatterplot.gold_star"
                  defaultMessage="<bold>{goldStarsCount}</bold> gold stars"
                  values={{
                    goldStarsCount: props?.data?.gold_stars,
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <span className="me-2 fe fe-calendar text-muted"></span>
                <FormattedMessage
                  id="app.views.widgets.chart.scatterplot.month_tenure"
                  defaultMessage="<bold>{monthTenure}</bold> month tenure"
                  values={{
                    monthTenure: Math.round(props?.data?.tenure),
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </Col>
              <Col className="col-6">
                <span className="me-2 fe fe-flag text-danger"></span>
                <FormattedMessage
                  id="app.views.widgets.chart.scatterplot.heads_ups"
                  defaultMessage="<bold>{headsUpsCount}</bold> heads ups"
                  values={{
                    headsUpsCount: props?.data?.heads_ups,
                    bold: (chunks) => <span className="fw-bold">{chunks}</span>,
                  }}
                />
              </Col>
            </Row>
            {props.hasRatings && (
              <Row>
                <Col className="col-6">
                  <span className="me-2 fe fe-edit text-info"></span>
                  <FormattedMessage
                    id="app.views.widgets.chart.scatterplot.rating"
                    defaultMessage="<bold>{rating}</bold> rating"
                    values={{
                      rating: props?.data?.rating ?? (
                        <FormattedMessage
                          id="app.views.widgets.chart.scatterplot.rating.no_rating_provided"
                          defaultMessage="N/A"
                        />
                      ),
                      bold: (chunks) => (
                        <span className="fw-bold">{chunks}</span>
                      ),
                    }}
                  />
                </Col>
              </Row>
            )}
          </PersonCard>
        </ConfirmIntlProvider>
      </BrowserRouter>
    </div>
  );
};

export default React.memo(ScatterPlot);
