const fakeNames = [
  'Adam Lambert',
  'Luke Allen',
  'Julio Mendez',
  'Darrell Lee',
  'April Guerrero',
  'Billy Hale',
  'Sherry Norton',
  'Norman Kelly',
  'Rickey Oliver',
  'Jeremy Sparks',
  'Edmund Harrington',
  'Albert Henderson',
  'Darnell Graham',
  'Kristin Simmons',
  'Cornelius Frazier',
  'Pearl Mendoza',
  'Elizabeth Casey',
  'Archie Pratt',
  'Gustavo Cobb',
  'Julie Hines',
  'Marcella Morton',
  'Ollie Montgomery',
  'Eloise Moody',
  'Kathleen Elliott',
  'Brittany Flowers',
  'Renee Boyd',
  'Simon Marshall',
  'Agnes Dennis',
  'Krystal Ferguson',
  'Rita Grant',
  'Dominick Tucker',
  'Marcus Thompson',
  'Javier Hill',
];

export const prepNetworkData = (data) => {
  return {
    nodes: data.nodes.map((p) => ({
      ...p,
      id: fakeNames[Number(p.id.split(' ')[1]) - 1],
    })),
    links: data.links.map((p) => ({
      ...p,
      source: fakeNames[Number(p.source.split(' ')[1]) - 1],
      target: fakeNames[Number(p.target.split(' ')[1]) - 1],
    })),
  };
};

export const formatChordTableData = (data) => {
  const labels = data.labels;
  const values = data.values;

  const result = [];

  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];

    for (let j = 0; j < labels.length; j++) {
      // @ts-expect-error
      result.push({
        source: label,
        target: labels[j],
        valueOne: values[i][j],
        valueTwo: values[j][i],
      });
    }
  }

  return result;
};

export const formatSankeyTableData = (data) => {
  const values = data.links;
  const valuesObj = {};

  values.forEach((v) => {
    const sourceName = v.source.split(' ')[0];
    const targetName = v.target.split(' ')[0];
    if (!(sourceName in valuesObj)) {
      valuesObj[sourceName] = {};
    }
    valuesObj[sourceName][targetName] = v.value;
  });

  const result = [];

  for (const key in valuesObj) {
    for (const innerKey in valuesObj[key]) {
      // @ts-expect-error
      result.push({
        source: key,
        target: innerKey,
        valueOne: valuesObj[key][innerKey],
        valueTwo: valuesObj[innerKey][key],
      });
    }
  }

  return result;
};

export const aggregateSankeyData = (data) => {
  const obj = {};

  data.forEach((p) => {
    if (!(p.source in obj)) {
      obj[p.source] = { internal: 0, external: 0 };
    }
    if (p.source === p.target) {
      obj[p.source].internal += p.valueOne;
    } else {
      obj[p.source].external += p.valueOne;
    }
  });

  const result = [];
  for (const key in obj) {
    // @ts-expect-error
    result.push({
      name: key,
      value: Math.round(
        (100 * obj[key].external) / (obj[key].internal + obj[key].external)
      ),
    });
  }

  return result;
};

export const formatNetworkTableData = (data) => {
  const values = data.links;
  const valuesObj = {};

  values.forEach((v) => {
    if (!(v.source in valuesObj)) {
      valuesObj[v.source] = {
        valueOne: 0, // # of people
        valueTwo: 0, // # of influence
      };
    }
    valuesObj[v.source].valueOne++;
    valuesObj[v.source].valueTwo += Number(v.distance);
  });

  const result = [];

  for (const key in valuesObj) {
    // @ts-expect-error
    result.push({
      source: key,
      target: '-',
      valueOne: valuesObj[key].valueOne,
      valueTwo: valuesObj[key].valueTwo,
    });
  }

  // @ts-expect-error
  result.sort((a, b) => (a.valueOne > b.valueOne ? -1 : 1));

  return result;
};
