import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  List,
  UncontrolledPopover,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person, Relationship } from '../../../types';
import React, { FC, useRef } from 'react';

import CardHeaderTitle from '../../Widgets/Cards/CardHeaderTitle';
import { CommentGroup } from './CommentGroup';
import { getUnattributedPerson } from '../../../utils/models/Person';

export interface GoldStarsCardProps {
  organization: Organization;
  person?: Person;
  goldStars?: Relationship[];
}

export const GoldStarsCard: FC<GoldStarsCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const goldStarsBoxLearnMoreRef = useRef<HTMLAnchorElement>(null);

  const firstName = props.person?.given_name ?? '';

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <i className="fe fe-star me-2 text-warning" />
          <span>
            <FormattedMessage
              id="app.views.person.person_performance.gold_stars_card.gold_stars"
              defaultMessage="Gold Stars"
            />
          </span>
          <Badge className="bg-warning-soft fw-bold fs-5 ms-3">
            {props.goldStars?.length}
          </Badge>
        </CardHeaderTitle>
      </CardHeader>
      <CardBody className="my-0">
        {(props.goldStars?.length ?? 0) > 0 ? (
          <>
            {props.goldStars?.map((gs, index, arr) => (
              <CommentGroup
                key={gs.id}
                author={
                  gs.from_person ??
                  getUnattributedPerson(formatMessage, gs.id, gs.type)
                }
                noMargin={index === arr.length - 1}
              >
                {gs.positive_comments || (
                  <div className="fst-italic">
                    <FormattedMessage
                      id="app.views.person.person_performance.gold_stars_card.no_comments_provided"
                      defaultMessage="No comments provided"
                    />
                  </div>
                )}
                {(gs.positive_skills?.length ?? 0) > 0 && (
                  <div className="gap-3 mt-3">
                    {gs.positive_skills?.map((skill) => (
                      <Badge key={skill.name} pill className="bg-primary-soft">
                        {skill.name}
                      </Badge>
                    ))}
                  </div>
                )}
              </CommentGroup>
            ))}
          </>
        ) : (
          <div>
            <FormattedMessage
              id="app.views.person.person_performance.gold_stars_card.empty_state"
              defaultMessage="{firstName} did not receive Gold Stars this cycle. <a>Learn more</a>"
              values={{
                firstName: firstName,
                a: (chunks) => (
                  <>
                    <a className="text-link" ref={goldStarsBoxLearnMoreRef}>
                      {chunks}
                    </a>
                    <UncontrolledPopover
                      trigger={'hover click'}
                      placement="top"
                      target={goldStarsBoxLearnMoreRef}
                    >
                      <FormattedMessage
                        id="app.views.person.person_performance.gold_stars_card.learn_more"
                        defaultMessage="Your Gold Star total is the number of people who identified you as making an outstanding impact this cycle. It’s important to remember: <ol><li>Not everyone receives a Gold Star! In a typical performance cycle, up to 33% of employees will receive none. The absence of Gold Stars should not be interpreted as a signal of poor performance.</li> <li>Certain roles or groups may be more likely to receive Gold Stars than others. Individual contributors, for example, are more likely to receive Gold Stars than managers. Employees with more tenure are also more likely to receive Gold Stars.</li></ol> If you feel your Gold Stars are low relative to the quality of your contributions, talk to your manager. You may find opportunities to increase visibility or impact for your team."
                        values={{
                          ol: (chunks) => (
                            <List unstyled="true" tag="ol" className="ps-4">
                              {chunks}
                            </List>
                          ),
                          li: (chunks) => (
                            <li className="my-3 px-1">{chunks}</li>
                          ),
                        }}
                      />
                    </UncontrolledPopover>
                  </>
                ),
              }}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
