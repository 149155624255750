import { Button, Card, CardHeader, Col, Row, Table } from 'reactstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { min, range } from 'lodash';

import { CSVLink } from 'react-csv';
import CardHeaderTitle from './CardHeaderTitle';
import EmptyState from '../EmptyState';
import { FormattedMessage } from 'react-intl';
import { ICONS } from '../../../consts/consts';
import PropTypes from 'prop-types';
import { createCSVFileName } from '../../../utils/util/util';

const DEFAULT_ROW_LIMIT = 15;

const TableCell = (props) => {
  const content = useMemo(
    () =>
      typeof props.field.getValue === 'function'
        ? props.field.getValue(props.field.name, props.datum)
        : props.datum,
    [props]
  );
  return (
    <td className={props.field.className} style={props.field.style}>
      {content}
    </td>
  );
};

const GenericTableCard = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [rowLimit, setRowLimit] = useState(DEFAULT_ROW_LIMIT);
  const [currentSortBy, setCurrentSortBy] = useState(undefined);
  const [sortDirections, setSortDirections] = useState(undefined); // array of booleans, true for ascending

  useEffect(() => {
    if (props.rowLimit != rowLimit) {
      setRowLimit(props.rowLimit);
    }
  }, [props.rowLimit, rowLimit]);

  useEffect(() => {
    const newSortDirections = props.fields.map((f) =>
      f?.defaultSort === 'descending' ? false : true
    );
    setSortDirections(newSortDirections);
    setCurrentSortBy(props.defaultSortBy);
  }, [props.fields, props.defaultSortBy]);

  const sortedRows = useMemo(() => {
    const compare = (a, b, compareFunc) => {
      if (compareFunc) {
        return compareFunc(a, b);
      }
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
    if (sortDirections !== undefined && props.rows?.length > 0) {
      let sortedRowData = [...props.rows];
      sortedRowData.sort(
        (a, b) =>
          compare(
            a[currentSortBy],
            b[currentSortBy],
            props.fields[currentSortBy]?.compare
          ) * (sortDirections[currentSortBy] ? 1 : -1)
      );
      return sortedRowData;
    }
    return [];
  }, [sortDirections, props.rows, props.fields, currentSortBy]);

  const TableHeader = useCallback(() => {
    return (
      <thead>
        <tr>
          {props.fields.map(({ name, title, className }, index) => (
            <th
              className={className}
              key={index}
              role="button"
              onClick={() => {
                if (currentSortBy === index) {
                  setSortDirections(
                    sortDirections.map((d, dindex) =>
                      dindex === index ? !d : d
                    )
                  );
                } else {
                  setCurrentSortBy(index);
                }
              }}
            >
              <span className="text-muted sort">{title ?? name}</span>
              {currentSortBy === index ? (
                <>
                  <i
                    className={`fe fe-chevron-${
                      sortDirections[index] ? 'up' : 'down'
                    }`}
                  />
                </>
              ) : (
                <></>
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  }, [currentSortBy, props.fields, sortDirections]);

  const MoreLink = useCallback(() => {
    return (
      <Button
        className="mb-4"
        color="link"
        onClick={() => {
          setRowLimit(isExpanded ? props.rowLimit : props.rows.length + 1);
          setIsExpanded(!isExpanded);
        }}
      >
        <FormattedMessage
          id="app.views.widgets.cards.generic_table_card.show_more_or_less"
          defaultMessage="Show {isExpanded, select, true {less} other {more}}"
          values={{ isExpanded: isExpanded }}
        />
      </Button>
    );
  }, [isExpanded, props.rowLimit, props.rows.length]);

  const rowsToShow = useMemo(
    () =>
      isExpanded
        ? props.rows.length
        : min([
            rowLimit === 0 ? props.rows.length : rowLimit,
            props.rows.length,
          ]),
    [isExpanded, props.rows.length, rowLimit]
  );

  const exportButton = useMemo(() => {
    if (!props.fields?.length || !sortedRows?.length) {
      return '';
    }

    const headers = props.fields.map((field) => field.name);
    const rows = sortedRows.map((row) =>
      row.map((datum, index) =>
        props.fields[index].getCSV ? props.fields[index].getCSV(datum) : datum
      )
    );

    return sortedRows?.length ? (
      <CSVLink
        className="btn btn-sm btn-light float-end"
        filename={createCSVFileName()}
        data={rows}
        headers={headers}
      >
        <i className={ICONS.EXPORT + ' me-2'} />
        <FormattedMessage
          id="app.views.widgets.cards.generic_table_card.export_to_csv"
          defaultMessage="Export to CSV"
        />
      </CSVLink>
    ) : (
      <></>
    );
  }, [props.fields, sortedRows]);

  const title = useMemo(
    () =>
      props.showExportButton ? (
        <Row className="align-items-center">
          <Col>{props.title}</Col>
          <Col className="col-auto">{exportButton}</Col>
        </Row>
      ) : (
        props.title
      ),
    [props.showExportButton, props.title, exportButton]
  );

  return !props.fields?.length ? (
    <EmptyState title={props.emptyStateText} />
  ) : (
    <Card className={props.className} role={props.role} style={props.style}>
      {title && (
        <CardHeader>
          <CardHeaderTitle>{title}</CardHeaderTitle>
        </CardHeader>
      )}
      <Table className={`card-table table-sm ${props.tableClassName}`}>
        {props.showTableHeader && <TableHeader />}
        {!sortedRows?.length ? (
          <tbody>
            <tr>
              <td colSpan={props.fields.length}>{props.emptyStateText}</td>
            </tr>
          </tbody>
        ) : (
          <tbody className="list">
            {range(rowsToShow).map((index) => (
              <tr key={index} className="p-1">
                {sortedRows[index].map((datum, i) => (
                  <TableCell key={i} field={props.fields[i]} datum={datum} />
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {rowLimit !== 0 && props.rows.length > props.rowLimit && <MoreLink />}
    </Card>
  );
};

GenericTableCard.propTypes = {
  className: PropTypes.object,
  defaultSortBy: PropTypes.number,
  emptyStateText: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  rowLimit: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  showExportButton: PropTypes.bool,
  showTableHeader: PropTypes.bool,
  style: PropTypes.object,
  title: PropTypes.string,
};

GenericTableCard.defaultProps = {
  rowLimit: DEFAULT_ROW_LIMIT,
  rows: [],
  showTableHeader: true,
};

export default React.memo(GenericTableCard);
