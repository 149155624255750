import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback } from 'react';
import { getDatePart, parseDateFromUnicode } from '../../../utils/util/time';

import { CampaignWithConfigs } from '../../../types';
import { INPUT_TYPES } from '../../Widgets/Inputs/ValidatedInputTypes';
import ModalEditor from '../../Widgets/Modals/ModalEditor';
import SafeUncontrolledPopover from '../../../components/SafeUncontrolledPopover';

type Props = {
  isOpen: boolean;
  onToggle: () => void;
  campaign: CampaignWithConfigs;
  onSuccess: (dataset_id: number) => void;
};

type ApiResponse = {
  dataset_id: number;
};

const CampaignParticipantsSnapshotEditorModal: FC<Props> = ({
  isOpen,
  onToggle,
  campaign,
  onSuccess,
}) => {
  const { formatMessage } = useIntl();

  const handleCallback = useCallback(
    (data?: ApiResponse, error?: unknown, hardErrorMessage?: unknown) => {
      if (!error && !hardErrorMessage && data) {
        onSuccess(data.dataset_id);
      }
    },
    [onSuccess]
  );

  const inputFields = [
    {
      name: 'cutoff_date',
      label:
        'Only people who started on or before the following date should participate',
      type: INPUT_TYPES.DATE_PICKER,
      autoFocus: false,
      maxDate: new Date(),
      required: true,
    },
  ];

  const object = {
    cutoff_date: parseDateFromUnicode(
      campaign.coverage_start_date,
      'yyyy-MM-dd'
    ),
  };

  return (
    <>
      <ModalEditor
        title={formatMessage(
          {
            id: 'app.views.administration.campaign_participants_snapshot_editor_modal.title',
            defaultMessage: 'Create participants snapshot for {cycle_name}',
          },
          {
            cycle_name: campaign.name,
          }
        )}
        callback={handleCallback}
        isOpen={isOpen}
        toggle={onToggle}
        method="POST"
        url={`/datasets/${campaign.dataset_id}/snapshot`}
        submitText={formatMessage({
          id: 'app.views.administration.campaign_participants_snapshot_editor_modal.button.save',
          defaultMessage: 'Create Snapshot',
        })}
        object={object}
        inputs={inputFields}
        transformObjectBeforeSubmit={(payload) => {
          return {
            ...payload,
            cutoff_date: getDatePart(payload.cutoff_date),
          };
        }}
        renderForm={(inputs, button) => {
          return (
            <>
              <div className="mb-4">
                <FormattedMessage
                  id="app.views.administration.campaign_participants_snapshot_editor_modal.body"
                  defaultMessage="Confirm will take a snapshot of each person in the system. <link>Learn more</link>"
                  values={{
                    link: (chunks) => (
                      <>
                        <a
                          className="text-link"
                          id="campaign_participants_snapshot_editor_modal_learn_more"
                        >
                          {chunks}
                        </a>
                        <SafeUncontrolledPopover
                          placement="top"
                          trigger="hover"
                          target="campaign_participants_snapshot_editor_modal_learn_more"
                        >
                          <FormattedMessage
                            id="app.views.administration.campaign_participants_snapshot_editor_modal.helper_text"
                            defaultMessage="The cycle participants snapshot, also known as a dataset, captures everyone's  manager, department, etc. for use in this cycle. Once taken, any changes in Confirm to people outside of the cycle, e.g. manager and department changes, will not affect this snapshot. You can manually update this snapshot after taking it."
                          />
                        </SafeUncontrolledPopover>
                      </>
                    ),
                  }}
                />
              </div>
              {inputs}
              {button}
            </>
          );
        }}
      />
    </>
  );
};

export default CampaignParticipantsSnapshotEditorModal;
