import * as types from '../actions/types';

import { FormattedListParts, FormattedMessage } from 'react-intl';
import React, { Fragment } from 'react';
import {
  TASK_TYPE_CONTRIBUTION,
  TASK_TYPE_CUSTOM_PRIORITY,
  TASK_TYPE_FEEDBACK_REQUEST,
} from '../utils/models/Task';
import { all, put, takeLatest } from 'redux-saga/effects';

import ConfirmAPI from '../utils/api/ConfirmAPI';
import RelativeTime from '../views/Widgets/RelativeTime';
import RichTextViewer from '../views/Widgets/Inputs/RichTextViewer';
import { activityOrSkillPageUrl } from '../utils/util/util';
import { defaultBackoffRetryConfig } from 'utils/api/axiosRetry';
import { peopleObjectsAreEqual } from '../utils/models/Person';

function* loadConfirmUrl(
  url,
  returnType = null,
  dataCallback = null,
  config = {},
  method = 'GET'
) {
  let returnData = null;
  let error = null;
  let hardErrorMessage = '';

  // send request to confirm API
  try {
    let apiPromise;

    if (method === 'GET') {
      apiPromise = yield ConfirmAPI.get(url, {
        ...config,
        // @ts-expect-error
        retryConfig: defaultBackoffRetryConfig(),
      });
    } else if (method === 'DELETE') {
      apiPromise = yield ConfirmAPI.delete(url, config);
    } else if (method === 'POST') {
      apiPromise = yield ConfirmAPI.post(url, config);
    } else if (method === 'PUT') {
      apiPromise = yield ConfirmAPI.put(url, config);
    } else if (method === 'PATCH') {
      apiPromise = yield ConfirmAPI.patch(url, config);
    }

    const status = apiPromise.status;
    if (status === 200 || status === 201 || status === 204) {
      // return data field if one provided, else return root data itself
      returnData = status === 204 && !apiPromise.data ? {} : apiPromise.data;
    } else {
      returnData = null;
      error = apiPromise.data;
      hardErrorMessage = apiPromise.data.toString();
    }
  } catch (e) {
    returnData = null;
    // @ts-expect-error
    error = e;
    hardErrorMessage =
      // @ts-expect-error
      e && e.response && e.response.data && e.response.data.detail
        ? // @ts-expect-error
          e.response.data.detail
        : // @ts-expect-error
          e.toString();
  }

  // pass data to callback if one was provided
  if (typeof dataCallback === 'function') {
    // @ts-expect-error
    dataCallback(returnData, error, hardErrorMessage);
  }

  // send a returnType event if one was provided along with the associated data
  if (returnType) {
    yield put({ type: returnType, data: returnData });
  }
}

const RichTextFormattedList = ({ value = [] }) => {
  return (
    <FormattedListParts value={value}>
      {(parts) => (
        <>
          {parts.map((p, index) => (
            <Fragment key={index}>
              {p.type === 'literal' && (
                <span className="text-muted">{p.value}</span>
              )}
              {p.type === 'element' && p.value}
            </Fragment>
          ))}
        </>
      )}
    </FormattedListParts>
  );
};

function* fetchTasks(proxy) {
  // fetch tasks for user to show in header
  // NOTE: we get live from the DB instead of via
  // ElasticsearchAPI.getContributionTasks because we need
  // it to be accurate in real time as claiming a contribution
  // and immediately reloading should NOT prompt the user again
  const apiPromise = new Promise((resolve, reject) =>
    ConfirmAPI.sendRequestToConfirm(
      'GET',
      '/tasks' + (proxy ? '?proxy=' + encodeURIComponent(proxy) : ''),
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            reject(hardErrorMessage);
          } else {
            reject(error);
          }
        } else {
          resolve(response);
        }
      }
    )
  );

  let tasks = [];

  yield apiPromise
    .then((response) => {
      // we need to convert these objects into a list of self-contained
      // tasks so we can act on these tasks in other widgets without
      // those widgets needing to know their inner workings
      // (namely the task notification widget which pops up the first
      // task, and the tasks list which is on the tasks page when someone
      // goes to view all of their tasks
      // @ts-expect-error
      tasks = [
        // convert "custom priority tasks"
        // @ts-expect-error
        ...(response?.custom_priorities.map((t) => {
          return {
            type: TASK_TYPE_CUSTOM_PRIORITY.id,
            object: t,
            sender: t.sender || null,
            time: t.time || null,
            listDescription: t.description,
            popupDescription: t.description,
            acceptText: t.action,
          };
        }) ?? []),
        // convert feedback requests to tasks
        // @ts-expect-error
        ...(response?.feedback_requests.map((fr) => {
          const senderPerson = fr.author_person;

          let filtered_subject_people = fr?.subject_people;
          if (
            filtered_subject_people?.length === 1 &&
            peopleObjectsAreEqual(filtered_subject_people[0], senderPerson)
          ) {
            filtered_subject_people = [];
          }

          const introductionText =
            fr?.activities?.length > 0 || fr?.skills?.length > 0 ? (
              <FormattedMessage
                id="app.sagas.index.fetch_tasks.feedback_request.introduction.has_activity_or_skill"
                defaultMessage="on behalf of"
                description="A phrase introducing a list of people who are the subject of a feedback request."
              />
            ) : (
              <FormattedMessage
                id="app.sagas.index.fetch_tasks.feedback_request.introduction.no_activity_or_skill"
                defaultMessage="regarding"
                description="A phrase introducing a list of people who are the subject of a feedback request."
              />
            );

          const subjectPeopleNiceList = (
            <RichTextFormattedList
              value={
                filtered_subject_people?.map((e, i) => {
                  return (
                    <span key={i}>
                      <a
                        href={e?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="text-primary">{e.full_name}</span>
                      </a>
                    </span>
                  );
                }) ?? []
              }
            />
          );

          return {
            type: TASK_TYPE_FEEDBACK_REQUEST.id,
            object: fr,
            sender: senderPerson,
            listDescription: (
              <span>
                <FormattedMessage
                  id="app.sagas.index.fetchTasks.requested_your_feedback"
                  defaultMessage="{person} requested your feedback."
                  description="Full sentence indicating that a person has requested your feedback."
                  values={{ person: <strong>{senderPerson.full_name}</strong> }}
                />
              </span>
            ),
            popupDescription: (
              <>
                <h2 className="mb-1">
                  <FormattedMessage
                    id="app.sagas.index.fetchTasks.popup.requested_your_feedback"
                    defaultMessage="{person} requested your feedback"
                    description="Title of a popup indicating someone requested your feedback"
                    values={{ person: senderPerson.full_name }}
                  />
                </h2>
                <div className="mb-4">
                  <div className="mb-3">
                    <h4 className="mt-3" style={{ lineHeight: 2 }}>
                      {filtered_subject_people?.length > 0 && (
                        <>
                          <span className="text-muted">{introductionText}</span>
                          {subjectPeopleNiceList}
                        </>
                      )}
                      {(fr?.activities?.length > 0 ||
                        fr?.skills?.length > 0) && (
                        <>
                          <span className="text-muted">{' regarding '}</span>
                          <RichTextFormattedList
                            // @ts-expect-error
                            value={[
                              ...(fr?.activities ?? []),
                              ...(fr?.skills ?? []),
                            ].map((e, i) => {
                              return (
                                <span key={i}>
                                  <a
                                    href={activityOrSkillPageUrl(e)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="text-primary">
                                      {e.name}
                                    </span>
                                  </a>
                                </span>
                              );
                            })}
                          />
                        </>
                      )}
                      <span className="text-muted">{'.'}</span>
                    </h4>
                  </div>
                  {fr.comments && (
                    <div className="mt-4 comment-body d-block fst-italic small">
                      <RichTextViewer model={fr.comments} expanded={true} />
                    </div>
                  )}
                </div>
              </>
            ),
            acceptText: (
              <FormattedMessage
                id="app.sagas.index.fetch_tasks.feedback_request.accept_text"
                defaultMessage="Provide feedback"
                description="Button on a modal dialog to allow user to provide feedback as requested"
              />
            ),
            declineText: (
              <FormattedMessage
                id="app.sagas.index.fetch_tasks.feedback_request.decline_text"
                defaultMessage="Decline"
                description="Button on a modal dialog to allow user to decline a feedback request"
              />
            ),
            timeText: <RelativeTime unit="day" datetime={fr.created_at} />,
          };
        }) ?? []),
        // convert contributions awaiting confirmation from user to tasks
        // @ts-expect-error
        ...(response?.contributions
          .map((c) => {
            if (!c) {
              console.error(
                'Contribution for task does not match contributor for activity ' +
                  c.activity.id
              );
              return null;
            }

            const senderPerson = c?.invitation?.sender_person;

            if (!senderPerson.full_name) {
              console.error(
                'Contribution missing invitation sender for activity ' +
                  c.activity.id
              );
              return null;
            }

            return {
              type: TASK_TYPE_CONTRIBUTION.id,
              object: c,
              sender: senderPerson,
              listDescription: (
                <span>
                  <FormattedMessage
                    id="app.sagas.index.fetch_tasks.contribution_request.list_description"
                    defaultMessage="{person} wants to give you credit for {activity}."
                    values={{
                      person: <strong>{senderPerson.full_name}</strong>,
                      activity: <strong>{c.activity.name}</strong>,
                    }}
                    description="Full sentence indicating that a person wants to give you credit for an activity."
                  />
                </span>
              ),
              popupDescription: (
                <>
                  <h2 className="mb-1">
                    <FormattedMessage
                      id="app.sagas.index.fetch_tasks.contribution_request.popup_content"
                      defaultMessage="{person} wants to give you credit."
                      values={{ person: senderPerson.full_name }}
                      description="Content of a popup being a full sentence indicating someone wants to give you credit for an activity, without the activity name."
                    />
                  </h2>
                  <div className="mb-4 text-muted">
                    <span>{c.activity.name}</span>
                  </div>
                </>
              ),
              acceptText: (
                <FormattedMessage
                  id="app.sagas.index.fetch_tasks.contribution_request.accept_text"
                  defaultMessage="Accept credit"
                  description="Button on a modal dialog to allow user to accept credit for a contribution, as requested"
                />
              ),
              declineText: (
                <FormattedMessage
                  id="app.sagas.index.fetch_tasks.contribution_request.decline_text"
                  defaultMessage="Ignore"
                  description="Button on a modal dialog to allow user to ignore a contribution request"
                />
              ),
              declinePopoverContent: (
                <FormattedMessage
                  id="app.sagas.index.fetch_tasks.contribution_request.decline_popover_content"
                  defaultMessage="This activity will be hidden from your resume."
                  description="Help message explains what happens if user decides to ignore a contribution request"
                />
              ),
              timeText: <RelativeTime unit="day" datetime={c.created_at} />,
            };
          })
          .filter((t) => t !== null) ?? []),
      ];
    })
    .catch((error) => {
      console.error('Error fetching tasks: ' + JSON.stringify(error));
    });

  yield put({
    type: types.SET_TASKS,
    data: {
      tasks: tasks,
    },
  });
}

function* watchLoadApp() {
  yield takeLatest(
    // @ts-expect-error
    types.LOAD_APP,
    function* loadApp(action: {
      proxy: string;
      isInRipplingInstallFlow: boolean;
      callback: () => void;
    }) {
      const params = new URLSearchParams();
      if (action.proxy) {
        params.append('proxy', action.proxy);
      }

      if (action.isInRipplingInstallFlow) {
        params.append('in_rippling_install', 'true');
      }

      const qs = params.toString();

      yield loadConfirmUrl(
        'init-app' + (qs ? '?' + qs : ''),
        // @ts-expect-error
        types.RENDER_APP,
        action.callback
      );
    }
  );
}

function* watchLoadTasks() {
  yield takeLatest(types.LOAD_TASKS, function* loadTasks(action) {
    // @ts-expect-error
    yield fetchTasks(action.proxy);
  });
}

function* watchLoadCampaignsAndSurveyResponses() {
  yield takeLatest(
    types.LOAD_CAMPAIGNS_AND_SURVEY_RESPONSES,
    function* loadCampaignsAndSurveyResponses(action) {
      yield loadConfirmUrl(
        'performance/init?directs=' +
          // @ts-expect-error
          (action.needsDirectReportReceivedRelationships ? 'true' : 'false') +
          // @ts-expect-error
          (action.proxy ? '&proxy=' + encodeURIComponent(action.proxy) : ''),
        // @ts-expect-error
        types.SET_CAMPAIGNS_AND_SURVEY_RESPONSES,
        null,
        // @ts-expect-error
        { cancelToken: action.cancelToken }
      );
    }
  );
}

function* watchLoadAppIntegrations() {
  yield takeLatest(
    types.LOAD_APP_INTEGRATIONS,
    function* loadAppIntegrations(action) {
      yield loadConfirmUrl(
        'app-integrations' +
          // @ts-expect-error
          (action.organization_id
            ? // @ts-expect-error
              '?organization_id=' + action.organization_id
            : '') +
          // @ts-expect-error
          (action.proxy ? '&proxy=' + encodeURIComponent(action.proxy) : ''),
        // @ts-expect-error
        types.RENDER_APP_INTEGRATIONS
      );
    }
  );
}

function* watchLoadInvitation() {
  yield takeLatest(types.LOAD_INVITATION, function* loadInvitation(action) {
    yield loadConfirmUrl(
      'invitations/' +
        // @ts-expect-error
        action.invitation.id +
        '?token=' +
        // @ts-expect-error
        action.invitation.token,
      null,
      // @ts-expect-error
      action.callback
    );
  });
}

function* watchClaimInvitation() {
  yield takeLatest(types.CLAIM_INVITATION, function* claimInvitation(action) {
    yield loadConfirmUrl(
      // @ts-expect-error
      'invitations/' + action.invitation.id,
      null,
      // @ts-expect-error
      action.callback,
      {
        // @ts-expect-error
        token: action.invitation.token,
      },
      'PATCH'
    );
  });
}

function* watchIgnoreContribution() {
  yield takeLatest(
    types.IGNORE_CONTRIBUTION,
    function* ignoreContribution(action) {
      yield loadConfirmUrl(
        // @ts-expect-error
        'contributions/' + action.contribution.id + '/ignore',
        null,
        // @ts-expect-error
        action.callback,
        {
          status: true,
        },
        'POST'
      );
    }
  );
}

function* watchUnignoreContribution() {
  yield takeLatest(
    types.UNIGNORE_CONTRIBUTION,
    function* unignoreContribution(action) {
      yield loadConfirmUrl(
        // @ts-expect-error
        'contributions/' + action.contribution.id + '/ignore',
        null,
        // @ts-expect-error
        action.callback,
        {
          status: false,
        },
        'POST'
      );
    }
  );
}

export default function* rootSaga() {
  yield all([
    watchLoadApp(),
    watchLoadTasks(),
    watchLoadCampaignsAndSurveyResponses(),
    watchLoadAppIntegrations(),
    watchLoadInvitation(),
    watchClaimInvitation(),
    watchIgnoreContribution(),
    watchUnignoreContribution(),
  ]);
}
