import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import EmptyState from '../Widgets/EmptyState';

const OpportunityIdeas: FC = () => {
  const { formatMessage } = useIntl();

  const ideaOpportunities = [];

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.opportunities.opportunity_ideas.header.title.ideas"
            defaultMessage="Ideas"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {ideaOpportunities?.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.opportunities.opportunity_ideas.title.no_ideas',
              defaultMessage: 'No ideas',
            })}
            subtitle={formatMessage({
              id: 'app.views.opportunities.opportunity_ideas.subtitle.there_are_no_idea_opportunities',
              defaultMessage:
                'There are no idea opportunities currently available.',
            })}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(OpportunityIdeas);
