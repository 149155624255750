// any new filters should be added here and in engagement_survey.py:get_engagement_survey_filters
export const getFilterDisplayName = (
  filterId: string,
  formatMessage
): string => {
  if (filterId === 'overall') {
    return formatMessage({
      id: 'app.utils.models.filters.overall',
      defaultMessage: 'Overall',
    });
  } else if (filterId === 'latest_hire_date') {
    return formatMessage({
      id: 'app.utils.models.filters.hiring_month',
      defaultMessage: 'Hiring Month (YYYY-MM)',
    });
  } else if (filterId === 'location') {
    return formatMessage({
      id: 'app.utils.models.filters.location',
      defaultMessage: 'Location',
    });
  } else if (filterId === 'department') {
    return formatMessage({
      id: 'app.utils.models.filters.department',
      defaultMessage: 'Department',
    });
  } else if (filterId === 'business_unit') {
    return formatMessage({
      id: 'app.utils.models.filters.business_unit',
      defaultMessage: 'Business Unit',
    });
  } else if (filterId === 'cost_center') {
    return formatMessage({
      id: 'app.utils.models.filters.cost_center',
      defaultMessage: 'Cost Center',
    });
  } else if (filterId === 'function') {
    return formatMessage({
      id: 'app.utils.models.filters.function',
      defaultMessage: 'Function',
    });
  } else if (filterId === 'home_location') {
    return formatMessage({
      id: 'app.utils.models.filters.home_location',
      defaultMessage: 'Home',
    });
  } else if (filterId === 'level' || filterId === 'level_id') {
    return formatMessage({
      id: 'app.utils.models.filters.level',
      defaultMessage: 'Level',
    });
  } else if (filterId === 'manager_email' || filterId === 'manager') {
    return formatMessage({
      id: 'app.utils.models.filters.manager',
      defaultMessage: 'Manager',
    });
  } else if (filterId === 'leader') {
    return formatMessage({
      id: 'app.utils.models.filters.leader',
      defaultMessage: 'Senior leader',
    });
  }
  console.error(`Unexpected filter '${filterId}', add it to filter list`);
  return formatMessage({
    id: 'app.utils.models.filters.unrecognized',
    defaultMessage: 'Unrecognized',
  });
};
