import React, { FC, useMemo } from 'react';

import { Bar } from 'react-chartjs-2';
import { ConfirmIntlProvider } from '../../../locale/ConfirmIntlContext';
import EmptyState from '../EmptyState';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/client';
import TooltipList from '../TooltipList';
import { useIntl } from 'react-intl';

const PowerLawGraph: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const options = useMemo(() => {
    return {
      tooltips: {
        enabled: false,
        mode: 'index',
        events: ['mousemove'],
        custom: function (tooltipModel) {
          const TOOLTIP_ID = 'powerlaw-chartjs-tooltip';

          // Tooltip Element
          let tooltipEl = document.getElementById(TOOLTIP_ID);

          // Remove if moving out of the grid, with a 5 seconds delay
          if (tooltipEl && !tooltipModel.dataPoints) {
            setTimeout(function () {
              document.getElementById(TOOLTIP_ID)?.remove();
            }, 5000);
            return;
          }

          if (tooltipModel.dataPoints) {
            if (tooltipEl) {
              tooltipEl.remove();
            }

            // Create element on first render
            tooltipEl = document.createElement('div');
            tooltipEl.id = TOOLTIP_ID;
            document.body.appendChild(tooltipEl);

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            let tooltipBody;
            // Set Text
            if (tooltipModel.body) {
              const index = tooltipModel.dataPoints[0].index;
              // @ts-expect-error
              const dataPoint = props.data[index];
              if (dataPoint) {
                tooltipBody = (
                  <ConfirmIntlProvider>
                    <PowerLawGraphTooltip
                      title={formatMessage(
                        {
                          id: 'app.views.widgets.charts.power_law_graph.title.people_with_x_field',
                          defaultMessage:
                            '{index} {index, plural, one {{fieldSingular}} other {{fieldPlural}}}',
                        },
                        {
                          index,
                          fieldSingular: props.fieldSingular,
                          fieldPlural: props.fieldPlural,
                        }
                      )}
                      count={formatMessage(
                        {
                          id: 'app.views.widgets.charts.power_law_graph.title.people_count',
                          defaultMessage:
                            '{count} {count, plural, one {person} other {people}}',
                        },
                        {
                          count: dataPoint.count,
                        }
                      )}
                      people={dataPoint.people}
                      expanded={false}
                    />
                  </ConfirmIntlProvider>
                );
              }
            }

            // `this` will be the overall tooltip
            // @ts-expect-error
            const position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            // @ts-expect-error
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding =
              tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';

            // @ts-expect-error
            ReactDOM.createRoot(document.getElementById(TOOLTIP_ID)).render(
              tooltipBody
            );
          }
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: props.xAxis || props.field,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString:
                props.yAxis ||
                formatMessage({
                  id: 'app.views.widgets.charts.power_law_graph.y_axes.label_string.default',
                  defaultMessage: 'Number of people',
                }),
            },
          },
        ],
      },
    };
  }, [
    props.data,
    props.field,
    props.fieldPlural,
    props.fieldSingular,
    props.xAxis,
    props.yAxis,
    formatMessage,
  ]);

  const data = useMemo(() => {
    if (!props.data) {
      return undefined;
    }
    const maxValue = Object.keys(props.data).reduce(
      (acc, a) => (parseInt(a) > acc ? parseInt(a) : acc),
      0
    );
    const labels = [];
    const dataset = {
      type: 'bar',
      label: 'people',
      data: [],
      backgroundColor: props.color,
      barThickness: 20,
    };
    for (let i = 0; i <= maxValue; i++) {
      // @ts-expect-error
      labels.push(i);
      // @ts-expect-error
      dataset.data.push(props?.data[`${i}`]?.count || 0);
    }

    return {
      labels,
      datasets: [dataset],
    };
  }, [props.color, props.data]);

  return data ? (
    <Bar data={data} options={options} />
  ) : (
    <EmptyState
      title={formatMessage({
        id: 'app.views.widgets.charts.power_law_graph.title.no_data_available',
        defaultMessage: 'No data available',
      })}
    />
  );
};

const PowerLawGraph_propTypes = {
  color: PropTypes.string,
  data: PropTypes.object,
  field: PropTypes.string.isRequired,
  fieldSingular: PropTypes.string,
  fieldPlural: PropTypes.string,
  xAxis: PropTypes.string,
  yAxis: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof PowerLawGraph_propTypes>;

const PowerLawGraphTooltip: FC<PowerLawGraphTooltipProps> = (props) => (
  <div
    style={{
      width: '350px',
      borderRadius: '10px',
      border: '1px solid lightGray',
      backgroundColor: 'white',
      padding: '20px',
    }}
  >
    <div>{props.title}</div>
    <div className="text-muted">{props.count}</div>
    {/* @ts-expect-error */}
    <div>{<TooltipList people={props?.people} />}</div>
  </div>
);

const PowerLawGraphTooltip_propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(PropTypes.object),
  expanded: PropTypes.bool,
};

type PowerLawGraphTooltipProps = PropTypes.InferProps<
  typeof PowerLawGraphTooltip_propTypes
>;

export default React.memo(PowerLawGraph);
