import React, { FC, useEffect } from 'react';

import Loading from '../Widgets/Loading';
import { logOutAndCleanUp } from '../../utils/models/User';
import { useAuth0 } from '@auth0/auth0-react';

const Logout: FC = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logOutAndCleanUp(logout);
  }, [logout]);

  return <Loading />;
};

export default React.memo(Logout);
