import {
  ACTIVITY_VISIBILITY_EVERYONE,
  getActivityType,
  getContributionPerson,
  getContributorRole,
} from '../../../utils/models/Activity';
import React, { FC, useCallback, useMemo } from 'react';

import AvatarGroup from '../People/AvatarGroup';
import ObjectCard from './ObjectCard';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ActivityMentionCard: FC<Props> = ({
  bodyOnly = false,
  // Makes user see blurred out Activity.
  // Originally used to block brand new users who haven't engaged with the app
  isLocked = false,
  disabled = false,
  showDescription = true,
  inline = false,
  className = '',
  ...props
}) => {
  const intl = useIntl();
  const formatMessage = intl.formatMessage;

  const formatActivityTitle = useCallback(
    (activityName) => {
      const name = (
        <>
          {/* @ts-expect-error */}
          {props.activity.visibility !==
          ACTIVITY_VISIBILITY_EVERYONE(formatMessage).id ? (
            <span>
              <i className="fe fe-lock me-2" />
            </span>
          ) : (
            ''
          )}
          {activityName
            ? activityName
            : formatMessage(
                {
                  id: 'app.views.widgets.cards.activity_mention_card.untitled',
                  defaultMessage: 'Untitled {activity}',
                },
                // @ts-expect-error
                { activity: props.activity?.type?.name ?? '' }
              )}
        </>
      );

      if (props.isResumeView) {
        const firstContributor =
          // @ts-expect-error
          props.focalContributions?.length > 0
            ? // @ts-expect-error
              getContributionPerson(props.focalContributions[0])
            : null;
        const person = props.focalPerson || firstContributor;
        const role =
          getContributorRole(props.activity, person) ||
          formatMessage({
            id: 'app.views.widgets.cards.activity_mention_card.contributor',
            defaultMessage: 'Contributor',
          });

        return (
          <span>
            {role}
            <span
              className={props.isCompressedView ? 'text-muted' : 'fw-normal'}
            >
              {', '}
              {name}
            </span>
          </span>
        );
      }

      return name;
    },
    [
      props.activity,
      props.isResumeView,
      props.focalPerson,
      props.focalContributions,
      props.isCompressedView,
      formatMessage,
    ]
  );

  const activityTitle = useMemo(() => {
    // @ts-expect-error
    return formatActivityTitle(props.activity?.name);
    // @ts-expect-error
  }, [formatActivityTitle, props.activity?.name]);

  const contributors = useMemo(
    () =>
      // @ts-expect-error
      props.activity?.contributions
        ? // @ts-expect-error
          props.activity.contributions.map(getContributionPerson)
        : [],
    // @ts-expect-error
    [props.activity?.contributions]
  );

  const lockedMessage = useMemo(
    () =>
      isLocked
        ? formatMessage({
            id: 'app.views.widgets.cards.activity_mention_card.create_activity',
            defaultMessage:
              'Create an activity to see what others are sharing.',
          })
        : undefined,
    [isLocked, formatMessage]
  );

  const isExternalUrl = useMemo(
    () => props.isEditablePerfResumeMode || props.isUneditablePerfResumeMode,
    [props.isEditablePerfResumeMode, props.isUneditablePerfResumeMode]
  );

  return (
    <ObjectCard
      disabled={disabled}
      role={props.role}
      style={props.style}
      headerClassName={
        props.isCompressedView ? 'small mb-0 fw-normal' : undefined
      }
      headerStyle={props.isCompressedView ? { lineHeight: '1.5' } : undefined}
      className={`${className} ${
        inline ? 'd-inline-block align-top me-2' : ''
      }`}
      inline={inline}
      lockedMessage={lockedMessage}
      icon={getActivityType(props.activity, intl)?.icon}
      iconTitle={getActivityType(props.activity, intl)?.heading}
      description={
        showDescription
          ? getActivityType(props.activity, intl)?.heading
          : undefined
      }
      name={activityTitle}
      formatNameForQuery={formatActivityTitle}
      // @ts-expect-error
      stringNameForQueries={props.activity.name}
      //url={consts.ACTIVITIES.path + '/' + props.activity.id}
      isExternalUrl={isExternalUrl}
      query={props.query}
      size={props.size}
      inDropdown={props.inDropdown}
      isValid={props.isValid}
      bodyOnly={bodyOnly}
      onClose={props.onClose}
      aside={
        props.hidePeople ? undefined : (
          <AvatarGroup
            isExternalUrl={isExternalUrl}
            people={contributors}
            maxFaces={props.maxFaces}
            size="xs"
            className="py-2"
            hidePopover={true}
          />
        )
      }
    />
  );
};

const ActivityMentionCard_propTypes = {
  activity: PropTypes.object,
  setActivity: PropTypes.func,
  isLocked: PropTypes.bool,
  openCreateActivityDialog: PropTypes.func,
  inDropdown: PropTypes.bool,
  isValid: PropTypes.bool,
  query: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  bodyOnly: PropTypes.bool,
  className: PropTypes.string,
  focalPerson: PropTypes.object,
  focalSkill: PropTypes.object,
  focalCredential: PropTypes.object,
  focalContributions: PropTypes.arrayOf(PropTypes.object),
  showEmptyContributionsWithoutComments: PropTypes.bool,
  maxFaces: PropTypes.number,
  isResumeView: PropTypes.bool,
  isCompressedView: PropTypes.bool,
  hideIcon: PropTypes.bool,
  hidePeople: PropTypes.bool,
  hideDate: PropTypes.bool,
  isEditablePerfResumeMode: PropTypes.bool,
  isUneditablePerfResumeMode: PropTypes.bool,
  onClickAcceptCredit: PropTypes.func,
  showFeedback: PropTypes.bool,
  currentProxyPerson: PropTypes.object,
  visibilityToggleCallback: PropTypes.func,
  showGiveOrRequestFeedbackButton: PropTypes.bool,
  role: PropTypes.string,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  inline: PropTypes.bool,
  showDescription: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof ActivityMentionCard_propTypes>;

export default React.memo(ActivityMentionCard);
