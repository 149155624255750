import * as consts from '../../../consts/consts';

import React, { FC } from 'react';

import ObjectCard from './ObjectCard';
import PropTypes from 'prop-types';
import { SKILL_TYPE_BEHAVIOR } from '../../../utils/models/Skill';
import { titleCase } from 'title-case';
import { useIntl } from 'react-intl';

const SkillCard: FC<Props> = ({ size = 'sm', ...props }) => {
  const { formatMessage } = useIntl();
  // @ts-expect-error
  const name = titleCase(props.skill.name);
  const description =
    // @ts-expect-error
    typeof props.skill?.total_user_and_person_count !== 'undefined'
      ? formatMessage(
          {
            id: 'app.views.skills.skill_card.description',
            defaultMessage: '{count} with experience',
          },
          // @ts-expect-error
          { count: props.skill.total_user_and_person_count }
        )
      : // @ts-expect-error
      props.skill.type === SKILL_TYPE_BEHAVIOR.id
      ? formatMessage({
          id: 'app.views.skills.skill_card.behavior',
          defaultMessage: 'Behavior',
        })
      : formatMessage({
          id: 'app.views.skills.skill_card.skill',
          defaultMessage: 'Skill',
        });
  // @ts-expect-error
  const url = consts.SKILLS().path + '/' + props.skill.id;

  return (
    <ObjectCard
      icon={consts.ICONS.SKILL}
      className={props.className}
      name={name}
      description={description}
      url={props.linked ? url : null}
      query={props.query}
      size={size}
      // @ts-expect-error
      role={props.role}
      style={props.style}
      bodyOnly={props.bodyOnly}
      inline={props.inline}
      inDropdown={props.inDropdown}
      closeButtonText={props.closeButtonText}
      onClose={props.onClose}
      // @ts-expect-error
      skill={props.skill}
      showEnrichedProfile={props.showEnrichedProfile}
      isExternalUrl={props.isExternalUrl}
      aside={props.aside}
      isValid={props.isValid}
    >
      {props.children}
    </ObjectCard>
  );
};
const SkillCard_propTypes = {
  className: PropTypes.string,
  skill: PropTypes.object.isRequired,
  closeButtonText: PropTypes.string,
  onClose: PropTypes.func,
  bodyOnly: PropTypes.bool,
  inline: PropTypes.bool,
  inDropdown: PropTypes.bool,
  size: PropTypes.string,
  query: PropTypes.string,
  linked: PropTypes.bool,
  showEnrichedProfile: PropTypes.bool,
  style: PropTypes.object,
  isExternalUrl: PropTypes.bool,
  aside: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isValid: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof SkillCard_propTypes>;

export default React.memo(SkillCard);
