import { Button, Col, List, Row, Table } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { QuestionSummary, ThemeSummary } from './types';
import React, { FC, useMemo, useState } from 'react';

import ExportButton from '../../components/ExportButton/ExportButton';
import QuestionDetailsModal from './QuestionDetailsModal';
import SimpleCard from '../Widgets/Cards/SimpleCard';
import { zip } from 'lodash';

interface EngagementSurveySummaryCardProps {
  campaignName: string;
  summary: QuestionSummary[];
  bodyOnly?: boolean;
}

const QuestionCell: FC<{ question: string; numResponses: number }> = ({
  question,
  numResponses,
}) => (
  <div className="d-inline-flex flex-column align-items-baseline">
    <h3 className="fw-bold mb-2">{question}</h3>
    <p className="text-secondary">
      <FormattedMessage
        id="app.views.engagement_survey.engagement_survey_summary_card.num_responses"
        defaultMessage="{num} responses"
        values={{ num: numResponses }}
      >
        {(text) => <span className="text-muted">{text}</span>}
      </FormattedMessage>
    </p>
  </div>
);

const TopThemesCell: FC<{ themes: ThemeSummary[] }> = ({ themes }) => (
  <List tag="ol" className="ps-0">
    {themes.map((t: ThemeSummary) => (
      <li className="mb-1 text-body" key={t.name}>
        <p className="fw-bold d-inline me-3">{t.name}</p>
        <p className="text-muted d-inline">
          <FormattedMessage
            id="app.views.engagement_survey.engagement_survey_summary_card.num_responses_with_parens"
            defaultMessage="({num} responses)"
            values={{ num: t.related_answers.length }}
          >
            {(text) => <span className="text-muted">{text}</span>}
          </FormattedMessage>
        </p>
      </li>
    ))}
  </List>
);

const TableRow: FC<{
  question: QuestionSummary;
  onClick: (id: string) => void;
}> = ({ question, onClick }) => (
  <tr className="align-baseline">
    <td className="align-baseline">
      <QuestionCell
        question={question.label}
        numResponses={question.answers.length}
      />
    </td>
    <td className="align-baseline">
      <TopThemesCell themes={question.themes} />
    </td>
    <td className="align-baseline text-end">
      <Button size="sm" color="primary" onClick={() => onClick(question.name)}>
        <FormattedMessage
          id="app.views.engagement_survey.engagement_survey_summary_card.view_details"
          defaultMessage="View details"
        />
      </Button>
    </td>
  </tr>
);

const TableHeader: FC<{
  leftheader: JSX.Element;
  midheader: JSX.Element;
  csvFilename: string;
  csvData: string[][];
}> = ({ leftheader, midheader }) => {
  return (
    <tr>
      <th>{leftheader}</th>
      <th>{midheader}</th>
      <th />
    </tr>
  );
};

const EngagementSurveySummaryCard: FC<EngagementSurveySummaryCardProps> = ({
  campaignName,
  bodyOnly,
  summary,
}) => {
  const intl = useIntl();

  const [selectedQuestion, setSelectedQuestion] = useState<string | undefined>(
    undefined
  );

  const selectedQuestionSummary: QuestionSummary = useMemo(
    () =>
      summary.find((q) => q.name === selectedQuestion) ?? {
        name: '',
        label: '',
        last_updated: '',
        themes: [],
        answers: [],
        overall_summary: '',
      },
    [selectedQuestion, summary]
  );

  const leftheader = (
    <FormattedMessage
      id="app.views.engagement_survey.engagement_survey_summary_card.text_question"
      defaultMessage="Text question"
    />
  );

  const midheader = (
    <FormattedMessage
      id="app.views.engagement_survey.engagement_survey_summary_card.top_themes"
      defaultMessage="Top themes (powered by <link>GPT-4</link>)"
      values={{
        link: (text) => <span className="text-primary">{text}</span>,
      }}
    />
  );

  const csvFilename = `${campaignName} Engagement Survey Summary.csv`;

  const csvHeaderQuestion: string = intl.formatMessage({
    id: 'app.views.engagement_survey.engagement_survey_summary_card.question',
    defaultMessage: 'Question',
  });

  const csvHeaderOverall: string = intl.formatMessage({
    id: 'app.views.engagement_survey.engagement_survey_summary_card.overall_summary',
    defaultMessage: 'Overall Summary',
  });

  const csvHeaderTheme: string = intl.formatMessage({
    id: 'app.views.engagement_survey.engagement_survey_summary_card.theme',
    defaultMessage: 'Theme',
  });

  const csvHeaderSummary: string = intl.formatMessage({
    id: 'app.views.engagement_survey.engagement_survey_summary_card.summary',
    defaultMessage: 'Summary',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const csvHeaderAnswers: string = intl.formatMessage({
    id: 'app.views.engagement_survey.engagement_survey_summary_card.answers',
    defaultMessage: 'Answers',
  });

  const csvData: string[][] = useMemo(
    () =>
      summary
        ? (zip(
            [
              csvHeaderQuestion,
              csvHeaderOverall,
              csvHeaderTheme,
              csvHeaderSummary,
              csvHeaderAnswers,
            ],
            ...summary
              .map((questionSummary) => [
                [questionSummary.label, questionSummary.overall_summary],
                ...questionSummary.themes.map((t) => [
                  '',
                  '',
                  t.name,
                  t.summary,
                  ...t.related_answers.map(
                    (index) => questionSummary.answers[index]
                  ),
                ]),
              ])
              .flat()
          ) as string[][])
        : ([] as string[][]),
    [
      csvHeaderAnswers,
      csvHeaderOverall,
      csvHeaderQuestion,
      csvHeaderSummary,
      csvHeaderTheme,
      summary,
    ]
  );

  const cardBody = (
    <>
      <Row>
        <Col>
          <Table>
            <thead>
              <TableHeader
                leftheader={leftheader}
                midheader={midheader}
                csvFilename={csvFilename}
                csvData={csvData ?? []}
              />
            </thead>
            <tbody>
              {summary.map((q: QuestionSummary) => (
                <TableRow
                  question={q}
                  onClick={setSelectedQuestion}
                  key={q.name}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <QuestionDetailsModal
            campaignName={campaignName}
            question={selectedQuestionSummary}
            modal={selectedQuestion != undefined}
            // says "toggle" but we only use it to close the modal
            toggle={() => setSelectedQuestion(undefined)}
          />
        </Col>
      </Row>
    </>
  );

  return (
    <SimpleCard
      title={
        <div className="d-flex justify-content-between align-items-baseline">
          <FormattedMessage
            id="app.views.engagement_survey.engagement_survey_summary_card.title"
            defaultMessage="Engagement Survey Summary"
          />
          <ExportButton filename={csvFilename} data={csvData ?? []} />
        </div>
      }
      bodyOnly={bodyOnly}
    >
      {cardBody}
    </SimpleCard>
  );
};

export default EngagementSurveySummaryCard;
