import * as consts from '../../consts/consts';

import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { CAMPAIGN_STATUSES } from '../../utils/models/Campaign';
import CampaignBasics from './CampaignBasics';
import CampaignFlow from './CampaignFlow';
import CampaignNotifications from './CampaignNotifications';
import CampaignParticipants from './CampaignParticipants';
import CampaignReleaseReports from './CampaignReleaseReports';
import CampaignSecretLinks from './CampaignSecretLinks';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import Loading from '../Widgets/Loading';
import ModalEditorButton from '../Widgets/Modals/ModalEditorButton';
import Page from '../Layout/Pages/Page';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadOrRender } from '../../utils/util/formatter';
import { toast } from 'react-toastify';
import { toastErrorOrCallback } from '../../utils/util/util';
import { useAuth0 } from '@auth0/auth0-react';

const CampaignEditor: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [shouldGoToNextTab, setShouldGoToNextTab] = useState(false);
  const [shouldGoToPreviousTab, setShouldGoToPreviousTab] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const { user } = useAuth0();
  const userSub = user?.sub;

  const location = useLocation();
  const history = useHistory();

  const params = useParams();
  // @ts-expect-error
  const campaignId = params.id;

  const [campaign, setCampaign] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const onCampaignSave = useCallback((updatedCampaign) => {
    setCampaign(updatedCampaign);
  }, []);

  const onCampaignSaveAndContinue = useCallback(
    (updatedCampaign) => {
      onCampaignSave(updatedCampaign);
      setShouldGoToNextTab(true);
    },
    [onCampaignSave]
  );

  useEffect(() => {
    // fetch campaign from server
    if (
      userSub &&
      // @ts-expect-error
      props.currentOrganization?.id &&
      typeof campaign === 'undefined' &&
      campaignId
    ) {
      ConfirmAPI.getObject(
        userSub,
        // @ts-expect-error
        props.currentProxyPerson,
        ConfirmAPI.OBJECT_TYPES.CAMPAIGNS,
        campaignId,
        (data) => {
          setCampaign(data);
        },
        (message) => {
          setErrorMessage(message);
        },
        {
          // @ts-expect-error
          organization: props.currentOrganization?.id,
          // @ts-expect-error
          override_locale: props.settings.internationalization.enabled
            ? // @ts-expect-error
              props.settings.internationalization.default_locale
            : undefined,
          ...(props.currentProxyPerson
            ? // @ts-expect-error
              { proxy: props.currentProxyPerson.email }
            : {}),
          include_campaign_question_locks: 1,
        }
      );
    }
  }, [
    userSub,
    props.currentProxyPerson,
    campaign,
    campaignId,
    // @ts-expect-error
    props.currentOrganization?.id,
    // @ts-expect-error
    props.settings.internationalization.enabled,
    // @ts-expect-error
    props.settings.internationalization.default_locale,
  ]);

  const breadcrumbs = useMemo(() => {
    return [
      consts.ADMINISTRATION_CAMPAIGNS(formatMessage),
      {
        // @ts-expect-error
        name: campaign?.name,
      },
    ];
    // @ts-expect-error
  }, [campaign?.name, formatMessage]);

  const cycleIsPublished = useMemo(
    // @ts-expect-error
    () => campaign?.status === CAMPAIGN_STATUSES.ACTIVE,
    // @ts-expect-error
    [campaign?.status]
  );

  const onChangePublishState = useMemo(
    () =>
      toastErrorOrCallback((response) => {
        if (response.status === CAMPAIGN_STATUSES.ACTIVE) {
          toast.success(
            formatMessage({
              id: 'app.views.administration.campaign_editor.publish.success',
              defaultMessage: 'Cycle published successfully.',
            })
          );
        } else {
          toast.success(
            formatMessage({
              id: 'app.views.administration.campaign_editor.unpublish.success',
              defaultMessage: 'Cycle unpublished successfully.',
            })
          );
        }

        setCampaign(response);
      }),
    [formatMessage]
  );

  const publishButtonTextWithIcon = useMemo(() => {
    return cycleIsPublished ? (
      <>
        <i className="fe fe-eye-off me-2" />{' '}
        <FormattedMessage
          id="app.views.administration.campaign_editor.publish.button.unpublish"
          defaultMessage="Unpublish cycle"
        />
      </>
    ) : (
      <>
        <i className="fe fe-upload-cloud me-2" />{' '}
        <FormattedMessage
          id="app.views.administration.campaign_editor.publish.button.publish"
          defaultMessage="Publish cycle"
        />
      </>
    );
  }, [cycleIsPublished]);

  const actionButtons = useMemo(() => {
    return (
      <>
        <Link
          // @ts-expect-error
          tag="Button"
          className="ms-3 me-4 btn btn-light"
          target="_blank"
          rel="noopener noreferrer"
          to={
            consts.PREVIEW_CAMPAIGN().path +
            '/' +
            // @ts-expect-error
            campaign?.id +
            consts.PERFORMANCE().path
          }
        >
          <i className="fe fe-external-link me-2" />{' '}
          <FormattedMessage
            id="app.views.administration.campaign_editor.action.button.preview]"
            defaultMessage="Preview cycle"
          />
        </Link>
        <ModalEditorButton
          disabled={hasUnsavedChanges}
          disabledHoverText={
            hasUnsavedChanges
              ? cycleIsPublished
                ? formatMessage({
                    id: 'app.views.administration.campaign_editor.action.button.unpublish.disabled',
                    defaultMessage:
                      'Please save your changes before unpublishing.',
                  })
                : formatMessage({
                    id: 'app.views.administration.campaign_editor.action.button.publish.disabled',
                    defaultMessage:
                      'Please save your changes before publishing.',
                  })
              : undefined
          }
          title={
            cycleIsPublished
              ? formatMessage({
                  id: 'app.views.administration.campaign_editor.action.button.unpublish',
                  defaultMessage: 'Unpublish cycle',
                })
              : formatMessage({
                  id: 'app.views.administration.campaign_editor.action.button.publish',
                  defaultMessage: 'Publish cycle',
                })
          }
          color={cycleIsPublished ? 'light' : 'primary'}
          buttonText={publishButtonTextWithIcon}
          method={'PATCH'}
          url="/campaigns"
          object={{
            // @ts-expect-error
            ...campaign,
            status: cycleIsPublished
              ? CAMPAIGN_STATUSES.INACTIVE
              : CAMPAIGN_STATUSES.ACTIVE,
          }}
          inputs={[]}
          renderInputs={(inputs) => (
            <>
              {inputs}
              {!cycleIsPublished && (
                <>
                  <FormattedMessage
                    id="app.views.administration.campaign_editor.action.render_input.cycle_published.access_when_start"
                    defaultMessage="
                  Participants will be able to access it when it starts. Are you
                  sure?
                "
                  />
                </>
              )}
              {cycleIsPublished && (
                <>
                  <FormattedMessage
                    id="app.views.administration.campaign_editor.render_input.cycle_published.remove_access"
                    defaultMessage="
                  Participants will no longer be able to access the cycle. Are
                  you sure?
                "
                  />
                </>
              )}
            </>
          )}
          callback={onChangePublishState}
          // only use save and continue, not save, given how simple the page is
          // so won't require reviewing further after saving
          // @ts-expect-error
          onSaveAndContinue={props.onSaveAndContinue}
        />
      </>
    );
  }, [
    campaign,
    cycleIsPublished,
    hasUnsavedChanges,
    onChangePublishState,
    // @ts-expect-error
    props.onSaveAndContinue,
    publishButtonTextWithIcon,
    formatMessage,
  ]);

  const onGoToPreviousTab = useCallback(() => {
    setShouldGoToPreviousTab(true);
  }, []);

  const tabs = useMemo(() => {
    const urlPrefix =
      consts.ADMINISTRATION_CAMPAIGNS(formatMessage).path + '/' + campaignId;

    return [
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_editor.action.render_input.name.basics',
          defaultMessage: 'Basics',
        }),
        path: '',
        content: (
          <CampaignBasics
            setHasUnsavedChanges={setHasUnsavedChanges}
            // @ts-expect-error
            campaign={campaign}
            onSave={onCampaignSave}
            onSaveAndContinue={onCampaignSaveAndContinue}
          />
        ),
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_editor.action.render_input.name.flow_and_timing',
          defaultMessage: 'Flow & Timing',
        }),
        path: '/flow',
        content: (
          <CampaignFlow
            setHasUnsavedChanges={setHasUnsavedChanges}
            // @ts-expect-error
            campaign={campaign}
            onGoBack={onGoToPreviousTab}
            onSave={onCampaignSave}
            onSaveAndContinue={onCampaignSaveAndContinue}
            enableEmployeeNPS={true} // temporary for internal testing
          />
        ),
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_editor.action.render_input.name.notifications',
          defaultMessage: 'Notifications',
        }),
        path: '/notifications',
        content: (
          <CampaignNotifications
            setHasUnsavedChanges={setHasUnsavedChanges}
            // @ts-expect-error
            campaign={campaign}
            onGoBack={onGoToPreviousTab}
            onSave={onCampaignSave}
            onSaveAndContinue={onCampaignSaveAndContinue}
          />
        ),
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_editor.action.render_input.name.participants',
          defaultMessage: 'Participants',
        }),
        path: '/participants',
        content: (
          <CampaignParticipants
            setHasUnsavedChanges={setHasUnsavedChanges}
            // @ts-expect-error
            campaign={campaign}
            onGoBack={onGoToPreviousTab}
            onSave={onCampaignSave}
            onSaveAndContinue={onCampaignSaveAndContinue}
          />
        ),
      },
      {
        name: formatMessage({
          id: 'app.views.administration.campaign_editor.action.render_input.name.faqs',
          defaultMessage: 'FAQs',
        }),
        path: '/faqs',
        content: (
          <>
            {!!campaign && (
              <CampaignSecretLinks
                campaign={campaign}
                // @ts-expect-error
                setHasUnsavedChanges={setHasUnsavedChanges}
                onGoBack={onGoToPreviousTab}
                onSave={onCampaignSave}
                onSaveAndContinue={onCampaignSaveAndContinue}
              />
            )}
            {!!campaign && <CampaignReleaseReports campaign={campaign} />}
          </>
        ),
      },
    ].map((tab) => ({
      ...tab,
      path: urlPrefix + tab.path,
      action: actionButtons,
    }));
  }, [
    actionButtons,
    campaign,
    campaignId,
    onGoToPreviousTab,
    onCampaignSave,
    onCampaignSaveAndContinue,
    formatMessage,
  ]);

  const goToPreviousTab = useCallback(() => {
    const currentTabIndex = tabs.findIndex(
      (tab) => location.pathname === tab.path
    );

    if (currentTabIndex > 0) {
      history.push(tabs[currentTabIndex - 1].path);
    }
  }, [history, location.pathname, tabs]);

  useEffect(() => {
    if (shouldGoToPreviousTab) {
      goToPreviousTab();
      setShouldGoToPreviousTab(false);
    }
  }, [goToPreviousTab, shouldGoToPreviousTab]);

  const goToNextTab = useCallback(() => {
    const currentTabIndex = tabs.findIndex(
      (tab) => location.pathname === tab.path
    );

    if (currentTabIndex < tabs.length - 1) {
      history.push(tabs[currentTabIndex + 1].path);
    }
  }, [history, location.pathname, tabs]);

  useEffect(() => {
    if (shouldGoToNextTab) {
      goToNextTab();
      setShouldGoToNextTab(false);
    }
  }, [goToNextTab, shouldGoToNextTab]);

  const loadOrRenderOutput = loadOrRender(campaign, errorMessage);
  if (loadOrRenderOutput) {
    return loadOrRenderOutput;
  }

  if (!campaign) {
    return <Loading />;
  }

  // @ts-expect-error
  return <Page title={campaign.name} breadcrumbs={breadcrumbs} tabs={tabs} />;
};

const CampaignEditor_propTypes = {
  currentOrganization: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  features: PropTypes.object.isRequired,
};

type Props = PropTypes.InferProps<typeof CampaignEditor_propTypes>;

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features, settings } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
    settings,
  };
};

export default connect(mapStateToProps)(React.memo(CampaignEditor));
