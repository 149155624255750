import { format, parse } from 'date-fns';

interface InputType {
  position_effective_date?: string;
  position_start_date?: string;
  latest_hire_date?: string;
  original_hire_date?: string;
}

export const getDateForTimeInRoleComputation = (input: InputType) => {
  return (
    input?.position_effective_date ||
    input?.position_start_date ||
    input?.latest_hire_date ||
    input?.original_hire_date
  );
};

export const DATE_FORMATS = {
  std: {
    pikr: 'Y-m-d',
    unicode: 'yyyy-MM-dd',
    printf: '%Y-%m-%d',
    placeholder: 'YYYY-MM-DD',
  },
  'mm/dd/yy': {
    pikr: 'm/d/y',
    unicode: 'MM/dd/yy',
    printf: '%m/%d/%y',
    placeholder: 'MM/DD/YY',
  },
  'mm/dd/yyyy': {
    pikr: 'm/d/Y',
    unicode: 'MM/dd/yyyy',
    printf: '%m/%d/%Y',
    placeholder: 'MM/DD/YYYY',
  },
  'dd/mm/yyyy': {
    pikr: 'd/m/Y',
    unicode: 'dd/MM/yyyy',
    printf: '%d/%m/%Y',
    placeholder: 'DD/MM/YYYY',
  },
  'dd/mm/yy': {
    pikr: 'd/m/y',
    unicode: 'dd/MM/yy',
    printf: '%d/%m/%y',
    placeholder: 'DD/MM/YY',
  },
  //... other formats (what is 'YmdHi' ?)
};

interface DateFormat {
  pikr: string;
  unicode: string;
  printf: string;
  placeholder: string;
}

export const getDateFormatForDatasetTransform = (input: string): DateFormat => {
  if (input in DATE_FORMATS) {
    return DATE_FORMATS[input];
  } else {
    return DATE_FORMATS.std;
  }
};

export const formatDateWithUnicode = (
  date: Date,
  unicodeFormat: string
): string => {
  return format(date, unicodeFormat);
};

export const parseDateFromUnicode = (
  dateStr: string,
  unicodeFormat: string,
  referenceDate?: Date
): Date => {
  if (!referenceDate) {
    referenceDate = new Date();
  }
  return parse(dateStr, unicodeFormat, referenceDate);
};

export const isDateBetween = (
  date: string | Date,
  start: string | Date,
  end: string | Date
): boolean => {
  const d = typeof date === 'string' ? new Date(date) : date;
  const s = typeof start === 'string' ? new Date(start) : start;
  const e = typeof end === 'string' ? new Date(end) : end;
  return d >= s && d <= e;
};

export const getDatePart = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const monthStr = month > 9 ? `${month}` : `0${month}`;
  const dateStr = day > 9 ? `${day}` : `0${day}`;
  return `${year}-${monthStr}-${dateStr}`;
};
