import * as consts from 'consts/consts';

import { CARD_TYPES } from '../../Dashboards/CardRowDashboard';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { getEngagementSurveyComponents } from 'views/EngagementSurvey/EngagementSurvey';

export const renderImpactRows = ({
  formatMessage,
  organizationName,
  ratingRequired,
  shouldShowInfluence,
  colorConfig,
  getPowerLawLink,
}) => {
  return [
    [
      {
        id: 'gold-stars-header',
        type: CARD_TYPES.HEADER,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.gold_stars_header.title',
          defaultMessage: 'Gold stars',
        }),
        datakey: 'gold_stars_header',
      },
    ],
    [
      {
        type: CARD_TYPES.POWER_LAW_CARD,
        title: (
          <span>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.talent.title"
              defaultMessage="At {organizationName}, recognition for outstanding impact follows a <powerlawlink></powerlawlink>."
              values={{
                organizationName,
                powerlawlink: () => getPowerLawLink(0),
              }}
            />
          </span>
        ),
        color: colorConfig.gold_star,
        fieldSingular: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.talent.field_singular',
          defaultMessage: 'gold star',
        }),
        fieldPlural: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.talent.field_plural',
          defaultMessage: 'gold stars',
        }),
        datakey: 'talent',
      },
    ],
    [
      {
        contentId: 'top-performers',
        type: CARD_TYPES.LEADERBOARD_CARD,
        title: formatMessage(
          {
            id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.top_performers.title',
            defaultMessage: "{organizationName}'s top recognized performers",
          },
          { organizationName }
        ),
        datakey: 'top_performers',
        showRating: ratingRequired,
        shouldShowInfluence,
      },
    ],
    [
      {
        type: CARD_TYPES.SCATTER_PLOT,
        title: `vs tenure`,
        datakey: 'breakdowns',
        field: 'individual',
        metric: consts.PERFORMANCE_METRICS[2],
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.individual_breakdowns.before_content"
              defaultMessage="
          While gold stars tend to go up with tenure, managers receive fewer
          gold stars than individual contributors.
        "
            />
          </div>
        ),
      },
    ],
    [
      {
        type: CARD_TYPES.CANDLESTICK_CHART,
        datakey: 'breakdowns',
        color: colorConfig.gold_star,
        metric: consts.PERFORMANCE_METRICS[2],
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.breakdowns.before_content"
              defaultMessage="Hover on a candlestick to see its distribution details."
            />
          </div>
        ),
      },
    ],
    [
      {
        type: CARD_TYPES.BREAKDOWN_CHART,
        breakdownType: 'gold_stars',
        color: colorConfig.gold_star,
        tenureColor: colorConfig.tenure,
        title: formatMessage(
          {
            id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.gold_stars_average.title',
            defaultMessage: 'Average gold stars and tenure by{space}',
          },
          { space: ' ' }
        ),
        datakey: 'breakdowns',
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.breakdowns_gold_stars.before_content"
              defaultMessage="
          Keep in mind these averages when assessing specific individuals.
        "
            />
          </div>
        ),
      },
    ],
  ];
};

export const renderInfluenceRows = ({
  formatMessage,
  organizationName,
  getPowerLawLink,
  colorConfig,
  //   I think we can remove this
  shouldShowInfluence,
}) => {
  return [
    [
      {
        id: 'influence-header',
        type: CARD_TYPES.HEADER,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.influence.title',
          defaultMessage: 'Influence',
        }),
        datakey: 'influence_header',
      },
    ],
    [
      {
        type: CARD_TYPES.POWER_LAW_CARD,
        title: (
          <span>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.influence.title"
              defaultMessage="At {organizationName}, influence also follows a <powerlawlink></powerlawlink>."
              values={{
                organizationName,
                powerlawlink: () => getPowerLawLink(2),
              }}
            />
          </span>
        ),
        color: colorConfig.influence,
        fieldSingular: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.influence.field_singular',
          defaultMessage: 'influence',
        }),
        fieldPlural: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.influence.field_plural',
          defaultMessage: 'influence',
        }),
        datakey: 'influence',
      },
    ],
    [
      {
        type: CARD_TYPES.TRIPLE_LEADERBOARD_CARD,
        contentId: 'top-influencers',
        title: formatMessage(
          {
            id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.callouts.title',
            defaultMessage: "{organizationName}'s influencer callouts",
          },
          { organizationName }
        ),
        datakey: 'top_influencers',
        shouldShowInfluence,
      },
    ],
    [
      {
        type: CARD_TYPES.SCATTER_PLOT,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.vs_tenure.title',
          defaultMessage: 'vs tenure',
        }),
        datakey: 'breakdowns',
        field: 'individual',
        metric: consts.PERFORMANCE_METRICS[0],
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.individual_breakdown.before_content"
              defaultMessage="
                Influence tends to go up with tenure, and managers tend to
                have more influence than individual contributors.
              "
            />
          </div>
        ),
      },
    ],
    [
      {
        type: CARD_TYPES.CANDLESTICK_CHART,
        datakey: 'breakdowns',
        color: colorConfig.influence,
        metric: consts.PERFORMANCE_METRICS[0],
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.breakdowns_influence.before_content"
              defaultMessage="
                Hover on a candlestick to see its distribution details.
              "
            />
          </div>
        ),
      },
    ],
    [
      {
        type: CARD_TYPES.BREAKDOWN_CHART,
        breakdownType: 'influence',
        color: colorConfig.influence,
        tenureColor: colorConfig.tenure,
        title: formatMessage(
          {
            id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.influence_breakdown.title',
            defaultMessage: 'Average influence and tenure by{space}',
          },
          { space: ' ' }
        ),
        datakey: 'breakdowns',
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.influence.before_content"
              defaultMessage="
                Keep in mind these averages when assessing specific
                individuals.
              "
            />
          </div>
        ),
      },
    ],
    [
      {
        id: 'gold-stars-and-influence-header',
        type: CARD_TYPES.HEADER,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.positive_vs_tenure.title',
          defaultMessage: 'Positive recognition vs tenure',
        }),
        datakey: 'gold_stars_and_influence_header',
      },
    ],
    [
      {
        type: CARD_TYPES.SCATTER_PLOT,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.vs_tenure.title',
          defaultMessage: 'vs tenure',
        }),
        datakey: 'breakdowns',
        field: 'individual',
        metric: 'influence_gold_stars',
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.influence_gold_stars.before_content"
              defaultMessage="
                By adding influence and gold stars together, we see how the
                network recognizes positive recognition in all forms.
              "
            />
          </div>
        ),
      },
    ],
  ];
};

export const renderENpsRows = ({
  formatMessage,
  shouldShowEmployeeNPS,
  shouldShowEngagementSurveyPlaceholder,
  campaignHasENPSEnabled,
}) => {
  return shouldShowEmployeeNPS
    ? [
        [
          {
            id: 'employee-nps-header',
            type: CARD_TYPES.HEADER,
            title: null,
            datakey: 'employee_nps_header',
          },
        ],
        [
          {
            id: 'employee-nps-card',
            type: CARD_TYPES.EMPLOYEE_NPS,
            title: '',
            datakey: 'employee_nps',
          },
        ],
      ]
    : [
        ...(shouldShowEngagementSurveyPlaceholder && campaignHasENPSEnabled
          ? [
              [
                {
                  id: 'employee-nps-header',
                  type: CARD_TYPES.HEADER,
                  title: formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.enps.title',
                    defaultMessage: 'Employee NPS',
                  }),
                  datakey: 'employee_nps_header',
                },
              ],
              [
                {
                  type: CARD_TYPES.SIMPLE_CARD,
                  title: formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.enps.title',
                    defaultMessage: 'Employee NPS',
                  }),
                  datakey: 'enps_placeholder',
                },
              ],
            ]
          : []),
      ];
};

export const renderEngagementSurveyRows = ({
  campaignHasEngagementSurvey,
  formatMessage,
  campaign,
  shouldShowEngagementSurveySummary,
  shouldShowEngagementSurveyPlaceholder,
  campaignHasEngagementSurveyEnabled,
  engagementSurveyData,
  engagementSurveySummaryData,
}) => {
  return campaignHasEngagementSurvey
    ? [
        [
          {
            id: 'engagement-survey-header',
            type: CARD_TYPES.HEADER,
            title: formatMessage({
              id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.engagement_survey.title',
              defaultMessage: 'Engagement survey',
            }),
            datakey: 'engagement_survey_header',
          },
        ],
        // TODO: let it out...
        // @ts-expect-error
        getEngagementSurveyComponents(
          formatMessage,
          campaign,
          engagementSurveyData
        ),
        shouldShowEngagementSurveySummary
          ? [
              {
                type: CARD_TYPES.ENGAGEMENT_SURVEY_SUMMARY,
                summary: engagementSurveySummaryData,
                campaignName: campaign?.name,
              },
            ]
          : [],
      ]
    : [
        ...(shouldShowEngagementSurveyPlaceholder &&
        campaignHasEngagementSurveyEnabled
          ? [
              [
                {
                  id: 'engagement-survey-header',
                  type: CARD_TYPES.HEADER,
                  title: formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.engagement_survey.title',
                    defaultMessage: 'Engagement survey',
                  }),
                  datakey: 'engagement_survey_header',
                },
              ],
              [
                {
                  type: CARD_TYPES.SIMPLE_CARD,
                  title: formatMessage({
                    id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.engagement_survey.title',
                    defaultMessage: 'Engagement survey',
                  }),
                  datakey: 'engagegement_survey_placeholder',
                },
              ],
            ]
          : []),
      ];
};

export const renderTalentMatrixRows = () => {
  return [
    [
      {
        id: 'talent-matrix-header',
        type: CARD_TYPES.HEADER,
        datakey: 'talent_matrix_header',
      },
    ],
  ];
};

export const renderCompetenciesRows = ({
  skillsFromSelfSkillOwners,
  skillsFromManagerSkillOwners,
  skillsFromOthersSkillOwners,
  formatMessage,
}) => {
  return [
    [
      {
        id: 'skills-and-behaviors-header',
        type: CARD_TYPES.HEADER,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.skills_and_behaviors.title',
          defaultMessage: 'Skills and behaviors',
        }),
        datakey: 'skills_and_behaviors_header',
      },
    ],
    [
      {
        type: CARD_TYPES.SKILL_CLOUDS_CARD,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.self_reflections.title',
          defaultMessage: 'Self reflections',
        }),
        beforeContent: (
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.self_reflection.before_content"
              defaultMessage="<bold>These were mentioned in self reflections.</bold> When considering trainings, courses, and mentorship programs, consider these areas to focus on."
              values={{
                bold: (text) => <span className="fw-bold">{text}</span>,
              }}
            />
          </p>
        ),
        datakey: 'self_skills_and_behaviors',
        skillOwners: skillsFromSelfSkillOwners,
      },
    ],
    [
      {
        type: CARD_TYPES.SKILL_CLOUDS_CARD,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.manager_reviews.title',
          defaultMessage: 'Manager reviews',
        }),
        beforeContent: (
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.manager_reviews.before_content"
              defaultMessage="<bold>These were mentioned by managers in their reviews.</bold> When considering training and other programming for people
        managers, these are areas for which guidance and support would be
        helpful."
              values={{
                bold: (text) => <span className="fw-bold">{text}</span>,
              }}
            />
          </p>
        ),
        datakey: 'manager_review_skills_and_behaviors',
        skillOwners: skillsFromManagerSkillOwners,
      },
    ],
    [
      {
        type: CARD_TYPES.SKILL_CLOUDS_CARD,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.gold_stars_heads_up.title',
          defaultMessage: 'Gold stars and heads ups',
        }),
        beforeContent: (
          <p>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.gold_stars_and_heads_ups.before_content"
              defaultMessage="<bold>These were tagged when giving gold stars and heads ups.</bold> They represent collective strengths of your top talent and
          collective areas of improvement for those that need additional
          support."
              values={{
                bold: (text) => <span className="fw-bold">{text}</span>,
              }}
            />
          </p>
        ),
        datakey: 'gold_star_heads_up_skills_and_behaviors',
        skillOwners: skillsFromOthersSkillOwners,
      },
    ],
  ];
};

export const renderConcernsRows = ({
  formatMessage,
  organizationName,
  ratingRequired,
  shouldShowInfluence,
  colorConfig,
}) => {
  return [
    [
      {
        id: 'concerns-header',
        type: CARD_TYPES.HEADER,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.concerns.title',
          defaultMessage: 'Concerns',
        }),
        datakey: 'concerns_header',
      },
    ],
    [
      {
        contentId: 'top-concerns',
        type: CARD_TYPES.LEADERBOARD_CARD,
        title: formatMessage(
          {
            id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.people_who_may_need_attention.title',
            defaultMessage:
              "{organizationName}'s employees who may need attention",
          },
          { organizationName }
        ),
        datakey: 'concerns',
        showRating: ratingRequired,
        shouldShowInfluence,
      },
    ],
    [
      {
        type: CARD_TYPES.SCATTER_PLOT,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.vs_ternure.title',
          defaultMessage: 'vs tenure',
        }),

        datakey: 'breakdowns',
        field: 'individual',
        metric: consts.PERFORMANCE_METRICS[1],
        reverse: true,
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.individual.before_content"
              defaultMessage="
      There is no significant correlation between heads ups and tenure.
    "
            />
          </div>
        ),
      },
    ],
    [
      {
        type: CARD_TYPES.CANDLESTICK_CHART,
        datakey: 'breakdowns',
        field: 'function',
        color: colorConfig.heads_up,
        metric: consts.PERFORMANCE_METRICS[1],
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.function.before_content"
              defaultMessage="Hover on a candlestick to see its distribution details."
            />
          </div>
        ),
      },
    ],
    [
      {
        type: CARD_TYPES.BREAKDOWN_CHART,
        breakdownType: 'heads_ups',
        color: colorConfig.heads_up,
        tenureColor: colorConfig.tenure,
        title: formatMessage(
          {
            id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.average_heads_up_tenure.title',
            defaultMessage: 'Average heads ups and tenure by{space}',
          },
          { space: ' ' }
        ),
        datakey: 'breakdowns',
        beforeContent: (
          <div>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.heads_up.before_content"
              defaultMessage="
      Keep in mind these averages when assessing specific individuals.
    "
            />
          </div>
        ),
      },
    ],
  ];
};

export const renderReccommendationsRows = ({ formatMessage }) => {
  return [
    [
      {
        id: 'whats-next-header',
        type: CARD_TYPES.HEADER,
        title: formatMessage({
          id: 'app.views.widgets.dashboards.takeaways_dashboard.rows.whats_next.title',
          defaultMessage: "What's next?",
        }),
        datakey: 'whats_next_header',
      },
    ],
    [
      {
        type: CARD_TYPES.SIMPLE_CARD,
        title: (
          <span>
            <FormattedMessage
              id="app.views.widgets.dashboards.takeaways_dashboard.rows.whats_next.title"
              defaultMessage="What's next?"
            />
          </span>
        ),
        datakey: 'whats_next',
      },
    ],
  ];
};
