import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { FC, useMemo } from 'react';

import CardHeaderTitle from './CardHeaderTitle';
import CompletionDoughnutChart from '../Charts/CompletionDoughnutChart';
import PropTypes from 'prop-types';

const ProgressChartCard: FC<Props> = ({
  data = [],
  className = '',
  ...props
}) => {
  const cardBody = useMemo(() => {
    // @ts-expect-error
    const numCharts = data.length;
    const colSize = `col-${12 / numCharts}`;
    return (
      <Row>
        {/* @ts-expect-error */}
        {data.map((datum, index) => {
          return (
            <Col key={index} className={colSize}>
              <CompletionDoughnutChart
                // @ts-expect-error
                count={datum[0]}
                // @ts-expect-error
                total={datum[1]}
                // @ts-expect-error
                emptyStateText={props.emptyStateText}
                // @ts-expect-error
                unit={datum[2]}
              />
            </Col>
          );
        })}
      </Row>
    );
  }, [data, props.emptyStateText]);

  if (!data?.length) {
    return <></>;
  }

  return (
    // @ts-expect-error
    <Card className={className} role={props.role} style={props.style}>
      <CardHeader>
        <CardHeaderTitle>{props.title}</CardHeaderTitle>
      </CardHeader>
      <CardBody className="position-relative">{cardBody}</CardBody>
    </Card>
  );
};

const ProgressChartCard_propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
  ),
  className: PropTypes.string,
  role: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  emptyStateText: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof ProgressChartCard_propTypes>;

export default React.memo(ProgressChartCard);
