import { Button, Col, ModalBody, ModalHeader, Row } from 'reactstrap';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import Modal from '../../../components/SafeModal';
import { renderValidationError } from '../../../utils/util/formatter';

interface Props {
  isOpen: boolean;
  onClosed: () => void;
  onSubmitted?: () => void;
  toggle: () => void;
  validationErrors?: string | Array<string>;
  zIndex?: number;
  title: string;
  description: ReactNode;
  actions: PropActions[];
  className: string;
}

interface PropActions {
  text: string;
  color: string;
  onClick: () => void;
  className: string;
  outline: boolean;
  skipClose?: boolean;
}

const ConfirmationDialogModalMultiAction: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClosed: onClosedProps,
  toggle,
  onSubmitted,
  validationErrors = '',
  actions,
  zIndex,
  title,
  description,
  className,
  children,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClosed = useCallback(() => {
    // enable reuse of this dialog by resetting it
    setIsSubmitting(false);
    onClosedProps();
  }, [onClosedProps]);

  const handleOnClick = useCallback(
    (action: PropActions) => (_event) => {
      setIsSubmitting(true);
      onSubmitted?.();
      action.onClick();
      if (action?.skipClose) {
        return;
      }
      onClosed();
    },
    [onClosed, onSubmitted]
  );

  const isReadyForInput = validationErrors || !isSubmitting;

  return (
    <Modal
      // prevent Esc from closing editor (to avoid issues e.g.
      // when someone escapes file dialog and presses twice)
      keyboard={false}
      // prevent hiding when clicking outside
      backdrop="static"
      isOpen={isOpen}
      onClosed={onClosed}
      toggle={toggle}
      zIndex={zIndex}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {description}
        {children}
        <Row className="mt-4">
          {actions.map((action, idx) => (
            <Col key={idx} className={className}>
              <Button
                outline={action.outline}
                disabled={!isReadyForInput}
                color={action.color}
                className="btn-block w-100"
                onClick={handleOnClick(action)}
              >
                {isReadyForInput && action.text}
                {!isReadyForInput && (
                  <div
                    className="spinner-border"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                )}
              </Button>
            </Col>
          ))}
        </Row>
        {validationErrors && (
          <div
            className={`text-center pt-2 message ${
              validationErrors && 'error'
            }`}
          >
            <span className="text-danger">
              {renderValidationError(validationErrors)}
            </span>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationDialogModalMultiAction;
