// Make sure prefix fields start with 'https://www.'
export const CONTACT_METHOD_OPTIONS = [
  {
    name: 'Slack',
    id: 'slack',
    format: 'slack',
    icon: 'fe fe-slack',
  },
  {
    name: 'Teams',
    id: 'msteams',
    format: 'msteams',
    icon: 'fe fe-users',
  },
  {
    name: 'Phone',
    id: 'phone',
    format: 'phone',
    icon: 'fe fe-phone',
  },
  {
    name: 'Email',
    id: 'email',
    format: 'email',
    icon: 'fe fe-mail',
  },
  {
    name: 'LinkedIn',
    id: 'linkedin',
    format: 'linkedin',
    icon: 'fe fe-linkedin',
    prefix: 'https://www.linkedin.com/in/',
  },
  {
    name: 'GitHub',
    id: 'github',
    format: 'github',
    icon: 'fe fe-github',
    prefix: 'https://www.github.com/',
  },
  {
    name: 'Twitter',
    id: 'twitter',
    format: 'twitter',
    icon: 'fe fe-twitter',
    prefix: 'https://www.twitter.com/',
  },
].map((o) => ({
  ...o,
  toString: () => o.name,
}));

export const getContactMethodName = (method) => {
  const name = CONTACT_METHOD_OPTIONS.find((o) => o.id === method.id)?.name;
  return name ? name : '';
};

export const getContactMethodIcon = (method) => {
  const icon = CONTACT_METHOD_OPTIONS.find((o) => o.id === method.id)?.icon;
  return icon ? icon : '';
};

const getLink = (match, value) => {
  const prefix = match?.prefix;
  if (!prefix) {
    console.error('Missing prefix for Option:', match);
    return value;
  }

  if (!value) {
    console.error('Missing contact method match/value:', match, '/', value);
    return '';
  }

  // Assumes URL base is prefix minus the protocol
  const PROTOCOL = 'https://www.';
  const base = prefix.slice(PROTOCOL.length);

  if (value.includes(base)) {
    const slug = value.split(base)[1];
    return prefix + slug;
  }

  return prefix + value;
};

export const getContactMethodLink = (medium) => {
  const match = CONTACT_METHOD_OPTIONS.find((o) => o.id === medium.id);
  const format = match?.format;

  switch (format) {
    case 'slack':
      return 'slack://open?id=' + encodeURIComponent(medium.value);
    case 'phone':
      return 'tel:' + encodeURIComponent(medium.value);
    case 'email':
      return 'mailto:' + encodeURIComponent(medium.value);
    case 'github':
    case 'linkedin':
    case 'twitter':
      return getLink(match, medium.value);
    default:
      // do not encode as we want to use https://, not https:%3A... etc.
      return medium.value;
  }
};
