import React, { FC, useCallback, useEffect, useState } from 'react';

import { ICONS } from '../../../consts/consts';
import PropTypes from 'prop-types';
import { UncontrolledPopover } from 'reactstrap';
import _uniqueId from 'lodash/uniqueId';

const SwitchInput: FC<Props> = ({
  value = false,
  checkedValue = true,
  uncheckedValue = false,
  switchLabelClassName = '',
  ...props
}) => {
  const [switchValue, setSwitchValue] = useState(value === checkedValue);

  useEffect(() => {
    setSwitchValue(value === checkedValue);
  }, [checkedValue, value]);

  const propsOnChange = props.onChange;

  const onChange = useCallback(
    (e) => {
      if (e.target.checked) {
        setSwitchValue(true);
      } else {
        setSwitchValue(false);
      }

      if (propsOnChange) {
        if (e.target.checked) {
          propsOnChange(checkedValue);
        } else {
          propsOnChange(uncheckedValue);
        }
      }
    },
    [checkedValue, uncheckedValue, propsOnChange]
  );

  // we need a unique id, either provided or generated,
  // for toggling to work properly
  const id = props.id
    ? props.id
    : props.name
    ? 'switch-' + props.name
    : _uniqueId('switch-');

  return (
    <div
      className={
        'form-check form-switch linebreak' +
        (props.switchLabel ? '' : ' me-n2') +
        (props.centered ? ' text-center' : '')
      }
    >
      <input
        aria-label={props.ariaLabel}
        id={id}
        type="checkbox"
        // @ts-expect-error
        required={props.required}
        // @ts-expect-error
        role={props.role}
        // @ts-expect-error
        style={props.style}
        // @ts-expect-error
        name={props.name}
        checked={switchValue}
        onChange={onChange}
        className={
          'form-check-input' + (props.className ? ' ' + props.className : '')
        }
        // @ts-expect-error
        disabled={props.disabled}
      />
      <label
        className={`form-check-label ${switchLabelClassName}`}
        htmlFor={id}
        role="button"
        style={{
          lineHeight: '1.5rem',
        }}
      >
        {/* @ts-expect-error */}
        {props.switchLabel}
      </label>
      {props.enableHelp && (
        <>
          <i
            id={'helper-' + props.name}
            className={'ms-2 ' + ICONS.NOT_ACKNOWLEDGED}
            style={{ position: 'relative', top: 1 }}
          />
          <UncontrolledPopover
            placement="top"
            trigger="hover"
            target={'helper-' + props.name}
            popperClassName={'linebreak'}
          >
            {props.hoverHelperText}
          </UncontrolledPopover>
        </>
      )}
    </div>
  );
};

const SwitchInput_propTypes = {
  id: PropTypes.string,
  switchLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  centered: PropTypes.bool,
  style: PropTypes.string,
  ariaLabel: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  checkedValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  uncheckedValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableHelp: PropTypes.bool,
  hoverHelperText: PropTypes.string,
  switchLabelClassName: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof SwitchInput_propTypes>;

export default React.memo(SwitchInput);
