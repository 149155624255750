import React, { FC } from 'react';

import { Progress } from 'reactstrap';

export type CompletionBar = {
  progress: number;
  total: number;
  width?: number;
  extraText?: string;
  maxPercent?: number;
};

const ObjectivesOverviewCSVGenerationBar: FC<CompletionBar> = ({
  progress,
  total,
  width = 100,
  extraText,
  maxPercent = 100,
}) => {
  return (
    <>
      <div
        className="btn btn-sm btn-light"
        style={{ height: 28, width, opacity: 0.65 }}
      >
        <Progress multi className="mt-1">
          <Progress
            bar
            striped={true}
            animated={true}
            color="primary"
            value={Math.min(maxPercent, Math.round((progress / total) * 100))}
          />
          <Progress
            bar
            color="dark"
            style={{ opacity: 0.3 }}
            value={Math.max(0, Math.round(((total - progress) / total) * 100))}
          />
        </Progress>
      </div>
      <div
        className="text-center"
        style={{
          zIndex: 1000,
          color: 'black',
          marginTop: '-25px',
          width,
          position: 'absolute',
        }}
      >
        <span className="small">
          {Math.min(maxPercent, Math.round((progress / total) * 100)) + '%'}
        </span>
      </div>
      {extraText && (
        <div className="text-muted small text-center float-end ms-2 mt-2">
          {extraText}
        </div>
      )}
    </>
  );
};

export default ObjectivesOverviewCSVGenerationBar;
