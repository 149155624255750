import * as consts from '../../consts/consts';

import { Card, CardBody, CardHeader } from 'reactstrap';
import { Features, Organization, Person } from '../../types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { capitalize, renderValidationError } from '../../utils/util/formatter';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { INPUT_TYPES } from '../Widgets/Inputs/ValidatedInputTypes';
import Loading from '../Widgets/Loading';
import ModalPersonProfileEditButton from '../Widgets/Modals/ModalPersonProfileEditButton';
import { SKILL_TYPE_BEHAVIOR } from '../../utils/models/Skill';
import WordCloud from '../Widgets/WordCloud';
import { connect } from 'react-redux';
import { getQueryWithType } from '../Widgets/Inputs/ValidatedInput';
import { insertFeedbackSkillsIntoWordCloud } from '../../utils/models/Feedback';
import { isSkillsTalentInventoryEnabled } from 'utils/util/features';

type Props = {
  contributions: object[];
  currentOrganization: Organization;
  currentProxyPerson: Person;
  features: Features;
  feedbackList?: object[];
  isMe: boolean;
  isOpen: boolean;
  onClosed: () => void;
  person: Person & {
    // This likely coming from serializers.py ExtendedPersonSerializer
    declared_skills: object[];
  };
  prepTagsForSubmit?: (
    tags: object[],
    organizationId?: number,
    autocreate?: boolean
  ) => object[];
  skillsFromContributions: object[];
  successCallback: () => void;
  type: {
    icon: string;
    id: string;
    name: string;
    pluralName: string;
    profileHeading: any;
  };
};

const PersonProfileSkillsSummary: FC<Props> = (props: Props) => {
  const { formatMessage } = useIntl();
  const [talentInventorySkills, setTalentInventorySkills] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  const declaredSkillsFilteredByType = props.person.declared_skills.filter(
    // @ts-expect-error
    (s) => s.type === props.type.id
  );

  const otherDeclaredSkills = props.person.declared_skills.filter(
    // @ts-expect-error
    (s) => s.type !== props.type.id
  );

  const declaredSkills = declaredSkillsFilteredByType.map((skill) => ({
    wordObject: skill,
    declaredPerson: props.person,
  }));

  useEffect(() => {
    if (!isSkillsTalentInventoryEnabled(props.features)) {
      return;
    }

    if (props.person.id && props.currentOrganization) {
      // fetch contributions from RDS
      ConfirmAPI.sendRequestToConfirm(
        'GET',
        'get-skills-for-person/' + props.person.id,
        {
          type: props.type.id,
          proxy: props.currentProxyPerson?.email,
          organization_id: props.currentOrganization.id,
        },
        (data) => {
          setTalentInventorySkills(data);
          setErrorMessage(null);
        },
        // @ts-expect-error
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [
    props.currentOrganization,
    props.currentProxyPerson,
    props.features,
    props.person.id,
    props.type.id,
    setTalentInventorySkills,
  ]);

  const convertTalentInventorySkillsToWordCloud = (talentInventorySkills) => {
    if (!talentInventorySkills) {
      return [];
    }

    const wordCloud = [];
    Object.entries(talentInventorySkills).forEach(([skillId, skillData]) => {
      // @ts-expect-error
      wordCloud.push({
        wordObject: {
          id: skillId,
          // @ts-expect-error
          name: skillData.name,
          // @ts-expect-error
          type: skillData.type,
          // These following 2 are the same count here, in WordCloud .count
          // will be augmented with skills coming from other objects.
          // @ts-expect-error
          count: skillData.count,
          // @ts-expect-error
          talentInventoryCount: skillData.count,
          // @ts-expect-error
          talentInventoryPeopleCount: skillData.people_count,
        },
      });
    });

    return wordCloud;
  };

  const skills = useMemo(() => {
    return insertFeedbackSkillsIntoWordCloud(
      declaredSkills
        // @ts-expect-error
        .concat(props.skillsFromContributions)
        .concat(convertTalentInventorySkillsToWordCloud(talentInventorySkills)),
      // @ts-expect-error
      props.feedbackList,
      props.type.id
    );
  }, [
    declaredSkills,
    talentInventorySkills,
    props.feedbackList,
    props.skillsFromContributions,
    props.type.id,
  ]);

  const emptyText = useMemo(
    () =>
      formatMessage(
        {
          id: 'app.views.person.person_profile.label.no_skills',
          defaultMessage:
            'No {subject, select, provided {subject} other {skills}} added yet',
        },
        {
          subject: props.type ? props.type.pluralName : undefined,
        }
      ),
    [props.type, formatMessage]
  );

  const headingName = useMemo(
    () =>
      formatMessage(
        {
          id: 'app.views.person.person_profile.heading.skills',
          defaultMessage:
            '{subject, select, provided {subject} other {skills}}',
        },
        {
          subject: props.type ? props.type.name : undefined,
        }
      ),
    [props.type, formatMessage]
  );

  if (
    typeof talentInventorySkills === 'undefined' &&
    isSkillsTalentInventoryEnabled(props.features)
  ) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle icon={props.type.icon}>
          {props.type.profileHeading}
        </CardHeaderTitle>
        {props.isMe && (
          <ModalPersonProfileEditButton
            title={formatMessage(
              {
                id: 'app.views.person.person_profile_skills_summary.edit',
                defaultMessage: 'Edit {headingName}',
              },
              {
                headingName: headingName,
              }
            )}
            isOpen={props.isOpen}
            onClosed={props.onClosed}
            object={{
              declared_skills: declaredSkillsFilteredByType,
            }}
            anchorTrigger="edit-skills"
            transformObjectBeforeSubmit={(object) => {
              return {
                // @ts-expect-error
                declared_skills: props.prepTagsForSubmit([
                  ...otherDeclaredSkills,
                  ...object.declared_skills,
                ]),
              };
            }}
            inputs={[
              {
                name: 'declared_skills',
                type: INPUT_TYPES.TAGS_INPUT,
                renderCreateOption: (x, setIndex, setType) => {
                  setIndex('skills');
                  setType(props.type.id);
                  return {
                    name: x,
                    object: {
                      name: (
                        <span>
                          <FormattedMessage
                            id="app.views.person.person_profile_skills_summary.render_option"
                            defaultMessage='<span>Create {type} "</span>{value}<span>"</span>'
                            values={{
                              span: (chunks) => (
                                <span className="fw-normal">{chunks}</span>
                              ),
                              type: props.type.name,
                              value: x,
                            }}
                          />
                        </span>
                      ),
                    },
                  };
                },
                label: formatMessage(
                  {
                    id: 'app.views.person.person_profile_skills_summary.declared',
                    defaultMessage: 'Declared {headingName}',
                  },
                  {
                    headingName,
                  }
                ),
                helperText:
                  props.type === SKILL_TYPE_BEHAVIOR
                    ? formatMessage({
                        id: 'app.views.person.person_profile_skills_summary.helper_text.behavior',
                        defaultMessage:
                          'For example, "Thoughtfulness" or "creativity"',
                      })
                    : formatMessage({
                        id: 'app.views.person.person_profile_skills_summary.helper_text.skill',
                        defaultMessage: 'For example, "Python" or "writing"',
                      }),
                elasticsearchOptions: {
                  url: 'get-skills-by-name',
                  getQuery: getQueryWithType,
                },
              },
            ]}
            renderInputs={(inputs) => {
              return (
                <>
                  <div className="mb-3">
                    <h4 className="mb-4">
                      <FormattedMessage
                        id="app.views.person.person_profile_skills_summary.mentioned_in_contributions"
                        defaultMessage="{heading} mentioned in your activity contributions"
                        values={{
                          heading: capitalize(headingName),
                        }}
                      />
                    </h4>
                    <WordCloud
                      className="text-center mb-4"
                      emptyText={emptyText}
                      objects={props.skillsFromContributions}
                      pathPrefix={consts.SKILLS().path}
                    />
                    {inputs}
                  </div>
                </>
              );
            }}
            successCallback={props.successCallback}
          />
        )}
      </CardHeader>
      <CardBody>
        <WordCloud
          className="text-center"
          emptyText={emptyText}
          objects={skills}
          pathPrefix={consts.SKILLS().path}
        />
        {errorMessage &&
          renderValidationError(
            formatMessage({
              id: 'app.components.error.safe_modal.generic.error.toast.text',
              defaultMessage:
                'An error occurred. Please try again later or contact customer support.',
            })
          )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(PersonProfileSkillsSummary));
