import React, { FC } from 'react';

import { renderNumber } from '../TalentMatrix';

interface Props {
  className?: string;
  boundaries: [string, string];
  metric_description: string;
  is_reversed: boolean;
}

const BucketDescriptor: FC<Props> = ({
  className = '',
  boundaries,
  metric_description,
  is_reversed,
}) => {
  const comparisonOperator = is_reversed ? '≥' : '≤';

  return (
    <div className={className}>
      <small>{`${renderNumber(
        boundaries[0]
      )} ${comparisonOperator} ${metric_description} ${comparisonOperator} ${renderNumber(
        boundaries[1]
      )}`}</small>
    </div>
  );
};

export default BucketDescriptor;
