import { Col, Container, Row } from 'reactstrap';
import React, { ReactNode, useMemo } from 'react';

import scaleIllustration from '../../assets/img/illustrations/no-description.png';

interface EmptyStateProps {
  isMini?: boolean;
  inSidebar?: boolean;
  title?: string;
  subtitle?: string | ReactNode;
  illustration?: string;
  children?: ReactNode | ReactNode[];
  enlargeTitle?: boolean;
  smallImage?: boolean;
  showTitle?: boolean;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  inSidebar,
  smallImage,
  title,
  subtitle,
  isMini,
  illustration = scaleIllustration,
  showTitle = true,
  enlargeTitle = false,
  children,
}) => {
  const maxWidth = useMemo(
    () => (inSidebar ? '100%' : smallImage ? 150 : 300),
    [inSidebar, smallImage]
  );

  // if no subtitle, just show centered text
  if (title && !subtitle) {
    return (
      <p className={'text-center text-muted my-3 ' + (isMini ? '' : 'px-5')}>
        {title}
      </p>
    );
  }

  return (
    <Container className="text-center pt-6">
      <Row className="justify-content-center">
        <Col className="col-12 col-md-10 col-xl-8">
          <img
            src={illustration}
            alt={title}
            className="img-fluid mt-n5 mb-4"
            style={{
              maxWidth: maxWidth,
            }}
          />
          {showTitle &&
            (enlargeTitle ? (
              <h1 className={children ? '' : 'mb-2'}>{title}</h1>
            ) : (
              <h2 className={children ? '' : 'mb-2'}>{title}</h2>
            ))}
          <p className={'text-muted' + (children ? '' : ' mb-4')}>{subtitle}</p>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(EmptyState);
