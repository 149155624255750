import { ReduxState, TimeFrame, TimeFrameType } from '../../../types';
import {
  TimeFrameDirection,
  calculatePeriodForTimeFrameType,
  calculateTimeFrame,
  formatTimeFrame,
  rewriteUrlForBookmark,
} from './utils';
import { useCallback, useState } from 'react';

import { getDatePart } from '../../../utils/util/time';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export type UseObjectivesTimeFrameSelectorProps = {
  defaultTimeFrame?: TimeFrame;
  onChangeTimeFrame?: (timeFrame: TimeFrame) => void;
  enableUrlForBookmark?: boolean;
};

export const useObjectivesSettings = () => {
  const objectivesTimeFrameType: TimeFrameType = useSelector<
    ReduxState,
    TimeFrameType
  >((state) => state.settings.objectives_timeframe);
  const objectivesTimeframeDefaultStartDateMMDD: string = useSelector<
    ReduxState,
    string
  >((state) => state.settings.objectives_timeframe_default_start_date);
  return { objectivesTimeFrameType, objectivesTimeframeDefaultStartDateMMDD };
};

export const useObjectivesTimeFrameSelector = ({
  defaultTimeFrame,
  onChangeTimeFrame = () => {
    // do nothing
  },
  enableUrlForBookmark = true,
}: UseObjectivesTimeFrameSelectorProps) => {
  const { locale } = useIntl();
  const { objectivesTimeFrameType, objectivesTimeframeDefaultStartDateMMDD } =
    useObjectivesSettings();

  // Check if the default time frame is passed by the user
  // otherwise, check if the url contains a date
  // otherwise, use the default time frame from the settings
  const [currentTimeFrame, setCurrentTimeFrame] = useState<TimeFrame>(() => {
    if (defaultTimeFrame) {
      return defaultTimeFrame;
    }
    const urlSelectedDate = new URLSearchParams(location.search).get('date')
      ? new Date(`${new URLSearchParams(location.search).get('date')}T00:00:00`)
      : null;
    return calculatePeriodForTimeFrameType({
      date: urlSelectedDate ?? new Date(),
      type: objectivesTimeFrameType,
      startPeriodMMDD: objectivesTimeframeDefaultStartDateMMDD,
    });
  });

  // Update the element if the default changes
  useDeepCompareEffectNoCheck(() => {
    if (defaultTimeFrame) {
      setCurrentTimeFrame(defaultTimeFrame);
    }
  }, [defaultTimeFrame]);

  const moveTimeFrame = useCallback(
    (timeFrame: TimeFrame, direction: TimeFrameDirection) => {
      const newTimeFrame = calculateTimeFrame(timeFrame, direction);
      setCurrentTimeFrame(newTimeFrame);
      onChangeTimeFrame(newTimeFrame);
      if (enableUrlForBookmark) {
        rewriteUrlForBookmark(newTimeFrame);
      }
    },
    [onChangeTimeFrame, enableUrlForBookmark]
  );

  const viewPreviousTimeframe = useCallback(() => {
    moveTimeFrame(currentTimeFrame, 'PREVIOUS');
  }, [currentTimeFrame, moveTimeFrame]);

  const viewNextTimeframe = useCallback(() => {
    moveTimeFrame(currentTimeFrame, 'NEXT');
  }, [currentTimeFrame, moveTimeFrame]);

  return {
    currentFirstDayOfTimeFrame: currentTimeFrame.start,
    currentTimeframeText: formatTimeFrame(
      {
        type: currentTimeFrame.type,
        start: currentTimeFrame.start,
        end: currentTimeFrame.end,
      },
      locale
    ),
    viewPreviousTimeframe,
    viewNextTimeframe,
    firstDay: getDatePart(currentTimeFrame.start),
    lastDay: getDatePart(currentTimeFrame.end),
  };
};
