import React, { FC } from 'react';

import { Alert } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import config from '../../utils/util/config';

const EnvironmentAlert: FC = () => {
  // @ts-expect-error
  return config.isProduction() || config.isUat() ? null : (
    <Alert
      className="mb-0 rounded-0 text-center environment-alert"
      color="warning"
    >
      <FormattedMessage
        id="app.views.layout.environment_alert.text"
        defaultMessage="You are currently in the <strong>{environment}</strong> environment."
        description="Used to warn users if they are not in the prod environment"
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
          // @ts-expect-error
          environment: config.getEnvironmentName(),
        }}
      />
    </Alert>
  );
};

export default EnvironmentAlert;
