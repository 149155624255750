import {
  AGENDA_ITEM_VISIBILITY_ALL_PARTICIPANTS,
  AGENDA_ITEM_VISIBILITY_OWNER_ONLY,
} from '../../../utils/models/AgendaItem';
import { Col, Input, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import {
  DRAG_AND_DROP_ICON,
  DraggableItemTypes,
  useDragAndDrop,
} from '../../../utils/util/util';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useMemo, useRef } from 'react';

import Avatar from '../People/Avatar';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import RichTextEditor from './RichTextEditor';
import { connect } from 'react-redux';
import { peopleIdsAreEqual } from '../../../utils/models/Person';

const AgendaItemInputStatus = ({ agendaItem }) => {
  const popOverRef = useRef();
  return (
    <>
      {agendaItem.status === 'UPDATING' && (
        <>
          <i
            // @ts-expect-error
            ref={popOverRef}
            className="spinner-border"
            style={{
              width: '0.8rem',
              height: '0.8rem',
              position: 'relative',
              top: '-2px',
              left: '-6px',
            }}
          ></i>
          <UncontrolledPopover
            delay={50}
            trigger="hover"
            placement="top"
            // @ts-expect-error
            target={popOverRef}
          >
            <PopoverBody>
              <span className="text-dark">
                <FormattedMessage
                  id="app.views.widgets.inputs.agenda_item_input.saving"
                  defaultMessage="Saving..."
                />
              </span>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      )}
    </>
  );
};

const AgendaItemInput: FC<Props> = ({ disabled = false, ...props }) => {
  const { formatMessage } = useIntl();

  const lockRef = useRef();

  const propsCallback = props.callback;
  const isEditable = !disabled && propsCallback;

  const [dragAndDropRef, handlerId, drop, isDragging, drag] = useDragAndDrop(
    DraggableItemTypes.AGENDA_ITEM,
    // @ts-expect-error
    props.agendaItem?.key,
    props.hoverIndex,
    props.moveAgendaItem,
    !disabled && props.allowReordering
  );

  const isMyAgendaItem = useMemo(() => {
    // @ts-expect-error
    return peopleIdsAreEqual(props.me?.id, props.agendaItem?.owner_person?.id);
    // @ts-expect-error
  }, [props.agendaItem, props.me]);

  const toggleAgendaItemCompleted = useCallback(() => {
    // @ts-expect-error
    propsCallback({
      ...props.agendaItem,
      // @ts-expect-error
      completed_at: props.agendaItem.completed_at
        ? null
        : new Date().toISOString(),
    });
  }, [propsCallback, props.agendaItem]);

  const agendaItemIsLocked = useMemo(() => {
    return (
      // @ts-expect-error
      props.agendaItem?.visibility === AGENDA_ITEM_VISIBILITY_OWNER_ONLY.id
    );
    // @ts-expect-error
  }, [props.agendaItem?.visibility]);

  const toggleAgendaItemLock = useCallback(() => {
    // @ts-expect-error
    propsCallback({
      ...props.agendaItem,
      visibility: agendaItemIsLocked
        ? AGENDA_ITEM_VISIBILITY_ALL_PARTICIPANTS.id
        : AGENDA_ITEM_VISIBILITY_OWNER_ONLY.id,
    });
  }, [propsCallback, props.agendaItem, agendaItemIsLocked]);

  const onAgendaItemDescriptionChanged = useCallback(
    (description) => {
      // @ts-expect-error
      propsCallback({
        ...props.agendaItem,
        description: description,
      });
    },
    [propsCallback, props.agendaItem]
  );

  const propsDeleteCallback = props.deleteCallback;
  const onKeyDown = useCallback(
    (e) => {
      // if backspace pressed and agendaItem description is empty, delete agendaItem
      if (e.which === 8 && !(e.currentTarget.innerText?.trim()?.length > 0)) {
        // @ts-expect-error
        propsDeleteCallback(props.agendaItem);
      }
    },
    [props.agendaItem, propsDeleteCallback]
  );

  const propsInputCallback = props.inputCallback;
  const propsAgendaItem = props.agendaItem;

  // is called on every input change, is immediate unlike onChange
  const onInput = useCallback(
    (text) => {
      // invoke callback with entire item not just description
      // @ts-expect-error
      propsInputCallback({
        ...propsAgendaItem,
        description: text,
      });
    },
    [propsInputCallback, propsAgendaItem]
  );

  const toggleOwnerPerson = useCallback(() => {
    // if owner is me, toggle to other person, or vice-versa
    // @ts-expect-error
    propsCallback({
      ...props.agendaItem,
      // @ts-expect-error
      owner_person: props.agendaItem.participant_person,
      // @ts-expect-error
      participant_person: props.agendaItem.owner_person,
    });
  }, [props.agendaItem, propsCallback]);

  const agendaItem = props.agendaItem;

  // @ts-expect-error
  drag(drop(dragAndDropRef));

  return (
    <div className={props.allowReordering ? 'hover-parent' : ''}>
      {props.allowReordering && (
        <div
          // @ts-expect-error
          ref={dragAndDropRef}
          data-handler-id={handlerId}
          role="button"
          className="hover-child position-absolute text-muted pt-1"
          disabled={disabled}
          style={{ left: '-3.3rem' }}
        >
          {!disabled && !isDragging && DRAG_AND_DROP_ICON}
        </div>
      )}
      <Row
        className="align-items-center hover-parent"
        data-testid="agenda-item"
      >
        <Col
          className="col-auto pe-0 checklist align-self-start"
          // align with first line of text (if multiline, keep at top)
          style={{ position: 'relative', top: '2px' }}
        >
          <Input
            aria-label={formatMessage({
              id: 'app.views.widgets.inputs.agenda_item_input.aria_label.mark_task_as_completed',
              defaultMessage: 'Mark task as completed',
            })}
            className="form-check-input"
            type="checkbox"
            disabled={!isEditable}
            role={isEditable ? 'button' : undefined}
            // @ts-expect-error
            checked={!!agendaItem.completed_at}
            onChange={toggleAgendaItemCompleted}
          />
        </Col>
        <Col data-testid="agenda-item-description">
          <RichTextEditor
            className="hide-border"
            // unsaved changes are handled by the parent component
            disableUnsavedChangesPrompt={true}
            placeholder={formatMessage({
              id: 'app.views.widgets.inputs.agenda_item_input.placeholder.write_an_item',
              defaultMessage: 'Write an item',
            })}
            inForm={true}
            disabled={!isEditable}
            showToolbar={true}
            //toolbarBottom={true}
            toolbarInline={true}
            //toolbarVisibleWithoutSelection={true}
            // @ts-expect-error
            expanded={true}
            // only autofocus if no id, indicating not yet saved to the server
            // AND item is incomplete (as, on completion, we don't want to persist focus)
            // @ts-expect-error
            autoFocus={!agendaItem?.id && !agendaItem?.completed_at}
            // use unique key for name
            // @ts-expect-error
            name={'agenda_item_' + agendaItem?.key}
            value={
              // @ts-expect-error
              agendaItem?.description?.length > 0 ? agendaItem.description : ''
            }
            onChange={(desc) => onAgendaItemDescriptionChanged(desc)}
            // we need to create own instance of handler to ensure description is updated
            onKeyDown={(e) => onKeyDown(e)}
            onInput={onInput}
          />
        </Col>
        <Col className="col-auto ps-0">
          <AgendaItemInputStatus agendaItem={agendaItem} />
        </Col>
        {isMyAgendaItem && (
          <Col
            className={
              'col-auto ps-0' + (agendaItemIsLocked ? '' : ' hover-child')
            }
          >
            <i
              role={isEditable ? 'button' : undefined}
              // @ts-expect-error
              onClick={isEditable && toggleAgendaItemLock}
              // @ts-expect-error
              ref={lockRef}
              className={agendaItemIsLocked ? 'fe fe-lock' : 'fe fe-unlock'}
            />
            <UncontrolledPopover
              delay={50}
              trigger="hover"
              placement="top"
              // @ts-expect-error
              target={lockRef}
            >
              <PopoverBody>
                <span className="text-dark">
                  {agendaItemIsLocked
                    ? formatMessage({
                        id: 'app.views.widgets.inputs.agenda_item_input.visible_to_you',
                        defaultMessage: 'Only visible to you',
                      })
                    : formatMessage({
                        id: 'app.views.widgets.inputs.agenda_item_input.visible_to_both',
                        defaultMessage: 'Visible to both of you',
                      })}
                </span>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        )}
        <Col className="col-auto ps-0">
          <Avatar
            size="xs"
            // @ts-expect-error
            person={agendaItem.owner_person}
            onClick={toggleOwnerPerson}
          />
        </Col>
      </Row>
    </div>
  );
};

const AgendaItemInput_propTypes = {
  agendaItem: PropTypes.object.isRequired,
  allowReordering: PropTypes.bool,
  callback: PropTypes.func,
  deleteCallback: PropTypes.func,
  inputCallback: PropTypes.func,
  hoverIndex: PropTypes.number,
  moveAgendaItem: PropTypes.func,
  disabled: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof AgendaItemInput_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { me, currentOrganization, currentProxyPerson, features } = state;

  return {
    me: me,
    currentOrganization: currentOrganization,
    currentProxyPerson: currentProxyPerson,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(AgendaItemInput));
