import { Col, Row } from 'reactstrap';
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback, useState } from 'react';

import ActivityFeed from './ActivityFeed';
import DashboardAlerts from './DashboardAlerts';
import Page from '../Layout/Pages/Page';
import RightSidebar from './RightSidebar';
import TasksList from '../Tasks/TasksList';
import { connect } from 'react-redux';

const Dashboard: React.FC<Props> = (props: Props) => {
  const [showCreateActivityDialog, setShowCreateActivityDialog] =
    useState(false);

  const onCreateActivityDialogClosed = useCallback(() => {
    setShowCreateActivityDialog(false);
  }, []);

  return (
    <Page>
      <Row className="mt-4 mt-md-5  justify-content-center">
        <Col className="col-12 col-xl-8">
          <DashboardAlerts className="mb-5" />
          {
            // @ts-expect-error
            (props.tasks?.length > 0 ||
              // @ts-expect-error
              !props.features?.activities?.enabled) && (
              <div className="mb-5">
                <TasksList isFullPage={false} />
              </div>
            )
          }
          {
            // @ts-expect-error
            props.features?.activities?.enabled &&
              // @ts-expect-error
              props.features?.activities?.show_in_dashboard && (
                <div>
                  <ActivityFeed
                    showCreateActivityDialog={showCreateActivityDialog}
                    openCreateActivityDialog={() =>
                      setShowCreateActivityDialog(true)
                    }
                    onCreateActivityDialogClosed={onCreateActivityDialogClosed}
                  />
                </div>
              )
          }
        </Col>
        <RightSidebar />
      </Row>
    </Page>
  );
};

type Props = InferProps<typeof Dashboard_propTypes>;

const Dashboard_propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  fyis: PropTypes.arrayOf(PropTypes.object),
  features: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { tasks, features } = state;

  return {
    tasks,
    features,
  };
};

export default connect(mapStateToProps)(React.memo(Dashboard));
