import { Col, Row } from 'reactstrap';
import React, { Children, FC, PropsWithChildren } from 'react';

import { PageSectionTitle } from './PageSectionTitle';

interface Props {
  titleIcon: string;
  titleIconColor: string;
  title: string;
  widths?: Array<{ xs: number }>;
  rightWidget?: React.ReactNode;
  id: string;
}

export const PageSection: FC<PropsWithChildren<Props>> = ({
  titleIcon,
  titleIconColor,
  title,
  children,
  widths,
  rightWidget,
  id,
}) => {
  return (
    <>
      <Row className="pb-4 pt-5" id={id}>
        <Col>
          <PageSectionTitle
            icon={titleIcon}
            iconColor={titleIconColor}
            rightWidget={rightWidget}
          >
            {title}
          </PageSectionTitle>
        </Col>
      </Row>
      <Row>
        {Children.toArray(children).map((child, i) => {
          return (
            // @ts-expect-error
            <Col {...(widths ? widths[i] : {})} key={child.key}>
              {child}
            </Col>
          );
        })}
      </Row>
    </>
  );
};
