import { FormGroup, Input, Label, UncontrolledPopover } from 'reactstrap';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import RichTextViewer from './RichTextViewer';
import TextareaAutosize from 'react-textarea-autosize';
import { useIntl } from 'react-intl';

const MultipleChoiceInput = (props) => {
  const { formatMessage } = useIntl();
  const [validationMessage, setValidationMessage] = useState(null);
  const optionsAsObject =
    props.options?.length > 0 && typeof props.options[0] === 'string'
      ? props.options.map((s) => ({
          id: s,
          name: s,
        }))
      : props.options;

  const [customInputIsFocused, setCustomInputIsFocused] = useState(false);
  const [customOptionValue, setCustomOptionValue] = useState(
    props.allowCustom &&
      props.value?.length > 0 &&
      optionsAsObject &&
      optionsAsObject.findIndex((o) => o.id === props.value) === -1
      ? props.value
      : ''
  );
  const ref = useRef();
  const propsOnChange = props.onChange;
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;
  const incomingValue = props.value;

  useEffect(() => {
    if (propsRequired) {
      const isValid =
        typeof incomingValue !== 'undefined' && incomingValue !== null;
      setValidationMessage(
        isValid
          ? null
          : formatMessage({
              id: 'app.widgets.inputs.multiple_choice_input.please_select_an_answer',
              defaultMessage: 'Please select an answer.',
            })
      );
    } else {
      setValidationMessage(null);
    }
  }, [incomingValue, propsRequired, formatMessage]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  useEffect(() => {
    if (
      props.allowCustom &&
      props.value?.length > 0 &&
      optionsAsObject &&
      optionsAsObject.findIndex((o) => o.id === props.value) === -1
    ) {
      setCustomOptionValue(props.value);
    }
  }, [props.allowCustom, optionsAsObject, props.value]);

  useEffect(() => {
    if (customInputIsFocused && ref?.current) {
      ref.current.focus();
    }
  }, [customInputIsFocused]);

  const onFocusCustomValue = useCallback(() => {
    // populate value to ensure radio button is selected
    propsOnChange(customOptionValue);
  }, [customOptionValue, propsOnChange]);

  const onBlurCustomValue = useCallback(() => {
    // populate value to ensure radio button is selected
    setCustomInputIsFocused(false);
  }, []);

  const onChange = useCallback(
    (e) => {
      propsOnChange(e.target.value);
    },
    [propsOnChange]
  );

  const onChangeCustomValue = useCallback(
    (e) => {
      setCustomOptionValue(e.target.value);
      propsOnChange(e.target.value);
    },
    [propsOnChange]
  );

  const onCustomValueRadioClicked = useCallback(() => {
    setCustomInputIsFocused(true);
    onChange({
      target: {
        value: customOptionValue,
      },
    });
  }, [onChange, customOptionValue]);

  const customItemIsSelected =
    props.value === customOptionValue || customInputIsFocused;

  // NOTE: "RichTextViewer" is used for customOptionLabel below
  // so we can render links from org settings (e.g. promo packet
  // custom urls for promo packet templates)
  return (
    <>
      {props.title && <Label className="mb-2 fw-bold">{props.title}</Label>}
      <FormGroup tag="fieldset" name={props.name} required={props.required}>
        {optionsAsObject?.map((option, optionIndex) => {
          // having an ID with a period is invalid
          const popoverId = ('mc-' + props.name + '-' + option.id).replaceAll(
            '.',
            '_'
          );

          return (
            <Fragment key={optionIndex}>
              <FormGroup check className="mb-2">
                <Label check id={popoverId} role="button">
                  <Input
                    required={props.required}
                    role="button"
                    type="radio"
                    name={props.name}
                    value={option.id}
                    checked={props.value?.toString() === option.id?.toString()}
                    onChange={onChange}
                    disabled={props.disabled}
                  />{' '}
                  {option.name}{' '}
                  {!option.popoverContent && option.description && (
                    <i
                      style={{
                        cursor: 'auto',
                      }}
                      id={popoverId + '-question'}
                      className={
                        'fe fe-help-circle text-primary small pl-3 pr-0'
                      }
                    />
                  )}
                </Label>
              </FormGroup>
              {option.popoverContent && (
                <UncontrolledPopover trigger="hover" target={popoverId}>
                  {option.popoverContent}
                </UncontrolledPopover>
              )}
              {!option.popoverContent && option.description && (
                <UncontrolledPopover trigger="hover" target={popoverId}>
                  {option.description}
                </UncontrolledPopover>
              )}
            </Fragment>
          );
        })}
        {props.allowCustom && (
          <FormGroup check>
            <Label check role="button" className="w-100">
              <Input
                role="button"
                type="radio"
                name={props.name}
                value={customOptionValue}
                checked={customItemIsSelected}
                onChange={onCustomValueRadioClicked}
                disabled={props.disabled}
              />{' '}
              {props.customOptionText ??
                formatMessage({
                  id: 'app.widgets.inputs.multiple_choice_input.custom',
                  defaultMessage: 'Custom',
                })}
              {customItemIsSelected && (
                <div className="mt-2">
                  <RichTextViewer
                    model={props.customOptionLabel}
                    expanded={true}
                  />
                </div>
              )}
              <TextareaAutosize
                ref={ref}
                type="text"
                className={
                  'mt-2 form-control w-100' +
                  (customItemIsSelected ? '' : ' d-none')
                }
                placeholder={
                  props.customOptionPlaceholder ??
                  formatMessage({
                    id: 'app.widgets.inputs.multiple_choice_input.enter_a_custom_answer',
                    defaultMessage: 'Enter a custom answer',
                  })
                }
                onFocus={onFocusCustomValue}
                onBlur={onBlurCustomValue}
                onChange={onChangeCustomValue}
                value={customOptionValue}
                disabled={props.disabled}
              />
            </Label>
          </FormGroup>
        )}
      </FormGroup>
    </>
  );
};

MultipleChoiceInput.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  allowCustom: PropTypes.bool,
  customOptionText: PropTypes.string,
  customOptionLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  customOptionPlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(MultipleChoiceInput);
