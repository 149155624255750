import * as consts from '../../../consts/consts';

import {
  CampaignFlowContextType,
  CollapsiblePhaseConfig,
  Features,
  OrganizationSettings,
  ValidatedInputConfig,
} from '../../../types';
import {
  DEFAULT_MINIMUM_SURVEY_RESPONDENTS,
  DEFAULT_PERFORMANCE_CALLOUTS_DESCRIPTION,
  DEFAULT_PERFORMANCE_NETWORK_DESCRIPTION,
  DEFAULT_PERFORMANCE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT,
  DEFAULT_PERFORMANCE_PROMOTION_NOMINATION_BY_MANAGER_LABEL,
  DEFAULT_PERFORMANCE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT,
  DEFAULT_PERFORMANCE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL,
  DEFAULT_SELF_REFLECTION_INTRO_MESSAGE_HTML,
  PERFORMANCE_ACCOMPLISHMENTS_DEFAULT_VISIBILITY_PRIVATE,
  PERFORMANCE_ACCOMPLISHMENTS_INSTRUCTIONS,
  PERFORMANCE_ACCOMPLISHMENTS_MAXIMUM,
  PERFORMANCE_ACCOMPLISHMENTS_MINIMUM,
  PERFORMANCE_CALLOUTS_DESCRIPTION,
  PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK,
  PERFORMANCE_FEATURE_ASSESS_MANAGER,
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION,
  PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP,
  PERFORMANCE_FEATURE_COMPLETE_RESUME_STEP,
  PERFORMANCE_FEATURE_DISABLE_CALLOUTS,
  PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS,
  PERFORMANCE_FEATURE_DISABLE_NETWORK,
  PERFORMANCE_FEATURE_DISPLAY_HEADS_UPS_TO_RECIPIENT,
  PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED,
  PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_OPTIONAL,
  PERFORMANCE_FEATURE_ENGAGEMENT_SURVEY_SUMMARIES,
  PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_EVALUATION_PHASE_OPEN_RESPONSES_HELPER_TEXT,
  PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT,
  PERFORMANCE_FEATURE_GOLD_STAR_COMMENTS_REQUIRED,
  PERFORMANCE_FEATURE_HEADS_UP_ANONYMOUS_TOGGLE,
  PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED,
  PERFORMANCE_FEATURE_HIDE_ASSESS_MANAGER_FEEDBACK_FROM_SUBJECT,
  PERFORMANCE_FEATURE_HIDE_EVALUATION_NEGATIVE_TAG_RECOMMENDATIONS,
  PERFORMANCE_FEATURE_HIDE_EVALUATION_POSITIVE_TAG_RECOMMENDATIONS,
  PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
  PERFORMANCE_FEATURE_HIDE_PEER_FEEDBACK_FROM_SUBJECT,
  PERFORMANCE_FEATURE_HIDE_PULSE_ANONYMOUS_DESCRIPTION,
  PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT,
  PERFORMANCE_FEATURE_HIDE_TIMELINE_ACTIVITIES,
  PERFORMANCE_FEATURE_HIGH_PRIORITY_PEERS,
  PERFORMANCE_FEATURE_MANAGER_UNEDITABLE,
  PERFORMANCE_FEATURE_MAXIMUM_MANDATORY_PEERS,
  PERFORMANCE_FEATURE_MINIMUM_MANDATORY_PEERS,
  PERFORMANCE_FEATURE_MINIMUM_SURVEY_RESPONDENTS,
  PERFORMANCE_FEATURE_NETWORK_STAKEHOLDERS,
  PERFORMANCE_FEATURE_OBJECTIVES_HEADER,
  PERFORMANCE_FEATURE_OBJECTIVES_MESSAGE_HTML,
  PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_PEER_FEEDBACK,
  PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME,
  PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_CALLOUTS,
  PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_COMMENTS_REQUIRED,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT,
  PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_LABEL,
  PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED,
  PERFORMANCE_FEATURE_RATING_SCALE,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT,
  PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL,
  PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_SELF_REFLECTION_INTRO_MESSAGE_HTML,
  PERFORMANCE_FEATURE_SHOW_CRITICAL_FEEDBACK_TO_MANAGERS,
  PERFORMANCE_FEATURE_SHOW_OBJECTIVES_TO_MANAGERS,
  PERFORMANCE_FEATURE_SHOW_PERFORMANCE_PERIOD_WITH_SPECIFIC_DATES,
  PERFORMANCE_FEATURE_SHOW_RECOGNITION_TO_MANAGERS,
  PERFORMANCE_FEATURE_SHOW_TALENT_MATRICES,
  PERFORMANCE_FEATURE_TALENT_MATRICES,
  PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
  PERFORMANCE_FEATURE_WELCOME_HEADER,
  PERFORMANCE_FEATURE_WELCOME_MEDIA,
  PERFORMANCE_FEATURE_WELCOME_MESSAGE_HTML,
  PERFORMANCE_NETWORK_DESCRIPTION,
  PERFORMANCE_NETWORK_STEP_PEOPLE_MINIMUM,
  PHASE_TYPE_CALIBRATION,
  PHASE_TYPE_EVALUATION,
  PHASE_TYPE_INTRODUCTION_UI_ONLY,
  PHASE_TYPE_OTHERS,
  PHASE_TYPE_REPORTING,
  PHASE_TYPE_SELF,
  WIZARD_TYPE_ENGAGEMENT_ONLY,
  WIZARD_TYPE_ENPS_ONLY,
  WIZARD_TYPE_ONA_ONLY,
} from '../../../utils/models/Performance';
import { FormattedMessage, type IntlShape } from 'react-intl';
import {
  formatToFloat,
  isIncompleteDecimalNumber,
} from '../../Widgets/Inputs/ValidatedInput';
import { INPUT_TYPES } from '../../Widgets/Inputs/ValidatedInputTypes';
import { parseDateFromUnicode } from '../../../utils/util/time';
import {
  campaignEditorStartDateChangeObjectivesTimeframeSideEffects,
  campaignEditorStartDateChangePeerFeedbackAssigmentTimeSideEffects,
} from '../../../utils/models/Campaign';

import { DEFAULT_ACCOMPLISHMENTS_MINIMUM, FLAGS } from '../../../consts/consts';
import { Link } from 'react-router-dom';
import { MM_DD_YYYY_WITH_SLASHES_FORMAT } from '../../Widgets/Inputs/DatePicker';
import React from 'react';
import _uniqueId from 'lodash/uniqueId';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import {
  findNearestTimeFrame,
  toTimeFrameDateFormat,
} from '../../../utils/models/TimeFrame';
import { getQuarterDate } from '../../../utils/models/Activity';
import { PropsListOptions } from './common';
import { isEnabled } from 'utils/util/util';
import { TalentMatrixCampaignEditorTableItem } from 'views/TalentMatrix';
import { isTalentMatricesEnabled } from 'utils/util/features';
import { v4 as uuidv4 } from 'uuid';

// For posterity, we capture here any performance features that are deprecated, i.e.
// we won't offer them for clients in the future:
// - PERFORMANCE_FEATURE_CONTRIBUTION_SELF_REFLECTIONS (done in our very first campaign, very time consuming, not supported anymore)
// - PERFORMANCE_FEATURE_MANAGER_CONTRIBUTION_FEEDBACK (same as above)
// - PERFORMANCE_FEATURE_PEER_CONTRIBUTION_FEEDBACK (same as above)
// - PERFORMANCE_FEATURE_HIDE_INFLUENCE_FROM_MANAGERS (when influence was experimental, we only let "influence_admins" see it)
// - PERFORMANCE_FEATURE_ALLOW_FEEDBACK_REQUESTS_WHEN_CLAIMING_CONTRIBUTIONS (we used to let people invite others to their activities during perf and the volume got too high in terms of people's inbound contribution requests, so this flag disabled the step asking who else worked with them on it which invited these other contributors)
// - PERFORMANCE_FEATURE_HIDE_TIME_AT_ORGANIZATION (this was a flag to disable this column because the client's data wasn't vetted)
// - PERFORMANCE_FEATURE_HIDE_TIME_IN_ROLE (this was a flag to disable this column because the client's data wasn't vetted)
// - PERFORMANCE_FEATURE_RESUME_OKR_REMINDER (this showed a reminder to complete OKRs at the top of the resume step, but this should be controlled via custom text instead).

const ENABLE_OR_DISABLE_INPUT_ATTRIBUTES = {
  type: INPUT_TYPES.SWITCH,
  defaultValue: false,
};

const DROPDOWN_INPUT_ATTRIBUTES = {
  type: INPUT_TYPES.DROPDOWN,
};

// some flags are described in the negative, i.e. hide this thing
// instead of show this thing, so for a less confusing UX,
// we flip the default value for these flags so we can display
// all settings in the positive (instead of using double-negatives).
const REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES = {
  type: INPUT_TYPES.SWITCH,
  checkedValue: false,
  uncheckedValue: true,
  // note that this actually checks the box as checkedValue and uncheckedValue are flipped
  defaultValue: false,
};

const SECTION_HEADER_ATTRIBUTES = {
  type: INPUT_TYPES.SECTION,
  formGroupClassName: 'pt-5 mt-5',
  // show a top thin line
  formGroupStyle: {
    borderTop: '1px solid #e9ecef',
  },
  // hack to avoid warnings about missing name prop to ValidatedInput
  name: _uniqueId('section-header'),
};

const FIRST_SECTION_HEADER_ATTRIBUTES = {
  ...SECTION_HEADER_ATTRIBUTES,
  formGroupClassName: 'mt-0',
  formGroupStyle: {},
};

const TIMING_HEADER = {
  ...SECTION_HEADER_ATTRIBUTES,
  label: (
    <h2 className="mb-0">
      <FormattedMessage
        id="app.views.administration.campaign_flow.phase_timing"
        defaultMessage="Phase timing"
      />
    </h2>
  ),
  helperText: (
    <FormattedMessage
      id="app.views.administration.campaign_flow.helper_text.phase_timing"
      defaultMessage="Set when you want this phase to start and end. Phases can run concurrently, but be careful letting managers complete reviews without being informed by learnings from the network or the direct reports being assessed."
    />
  ),
};

const START_AND_END_DATE_OPTIONS = [
  TIMING_HEADER,
  {
    name: 'start_date',
    label: (
      <FormattedMessage
        id="app.views.administration.campaign_flow.label.phase_start_date"
        defaultMessage="Phase start date"
      />
    ),
    helperText: (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.phase_start_date"
        defaultMessage="When participants can start completing this phase (starting at 12:01am their local time)."
      />
    ),
    type: INPUT_TYPES.DATE_PICKER,
    format: MM_DD_YYYY_WITH_SLASHES_FORMAT,
    // prevent weird issue where calendar pops up on page load
    autoFocus: false,
    onChangeSideEffects: (
      currentObject,
      newValue,
      intl: IntlShape,
      organizationSettings: OrganizationSettings
    ) => {
      const objectiveTimeFrameSideEffect =
        campaignEditorStartDateChangeObjectivesTimeframeSideEffects(
          currentObject,
          newValue,
          organizationSettings,
          (current, next) => {
            if (current?.enabled && !isEqual(current, next)) {
              toast.warn(
                intl.formatMessage({
                  id: 'app.views.administration.campaign_flow.toast.objectives_timeframe_updated',
                  defaultMessage:
                    'Objectives timeframe for this cycle has been updated to match the phase start date you just entered. Please review the objectives step to ensure it is configured as you expect.',
                })
              );
            }
          }
        );

      const peerFeedback =
        campaignEditorStartDateChangePeerFeedbackAssigmentTimeSideEffects(
          currentObject,
          newValue,
          organizationSettings,
          (current, next) => {
            if (!isEqual(current, next)) {
              toast.warn(
                intl.formatMessage({
                  id: 'app.views.administration.campaign_flow.toast.peer_feedback_assignment_time_updated',
                  defaultMessage:
                    'The custom peer assignment time that was set for this cycle has been reset to match the phase start date you just entered. Please review the peer feedback step to ensure it is configured as you expect.',
                })
              );
            }
          }
        );

      return {
        ...objectiveTimeFrameSideEffect,
        ...peerFeedback,
      };
    },
  },
  {
    name: 'end_date',
    label: (
      <FormattedMessage
        id="app.views.administration.campaign_flow.label.end_date"
        defaultMessage="Phase end date"
      />
    ),
    helperText: (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.end_date"
        defaultMessage="The last day that participants can start completing this phase (ending at 11:59pm their local time)."
      />
    ),
    type: INPUT_TYPES.DATE_PICKER,
    format: MM_DD_YYYY_WITH_SLASHES_FORMAT,
    // prevent weird issue where calendar pops up on page load
    autoFocus: false,
  },
];

const getSelfPhaseOptions = (
  formatMessage,
  options
): ValidatedInputConfig[] => {
  return [
    {
      ...FIRST_SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.how_this_phase_works"
            defaultMessage="How this phase works"
          />
        </h2>
      ),
      helperText: (
        <>
          <p>
            <FormattedMessage
              id="app.views.administration.campaign_flow.how_this_phase_works_helper_text_1"
              defaultMessage="
          During the first phase, participants answer basic questions about
          themselves and the network of people they work with. It can be
          completed in 5 minutes if no self reflection or pulse survey is added.
          With very little time, your leaders can get massive insight into their
          team members through ONA (organizational network analysis).
        "
            />
          </p>
          <p>
            <FormattedMessage
              id="app.views.administration.campaign_flow..how_this_phase_works_helper_text_2"
              defaultMessage="
          Each step is designed to get slightly harder than the step prior. This
          design is intentional to ensure high completion rates through creating
          Flow (a concept from positive psychology). It is the reason why some
          participants say completing perf in Confirm is fun.
        "
            />
          </p>
        </>
      ),
    },
    ...START_AND_END_DATE_OPTIONS,
    {
      ...SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.network"
            defaultMessage="Network"
          />
        </h2>
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.network"
          defaultMessage="1-2 minutes to complete. We capture influence and, optionally, previous direct report comments. For benchmarking purposes, we do not allow changing the text of the network questions beyond the options below."
        />
      ),
    },
    {
      name: PERFORMANCE_FEATURE_DISABLE_NETWORK,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.enable_network"
          defaultMessage="Enable influence questions"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.enable_network"
          defaultMessage="Strongly recommended. Identify influencers, i.e. who people go to for help, advice, energy, and motivation."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      // NOTE: DEFAULT_PERFORMANCE_NETWORK_DESCRIPTION is default
      name: PERFORMANCE_NETWORK_DESCRIPTION,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.network_section_header"
          defaultMessage="Network section second page header"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.network_section_header"
          defaultMessage="Description at the top of the second page of the network section where skills and behaviors are tagged to helpers and advisers."
        />
      ),
      placeholder: DEFAULT_PERFORMANCE_NETWORK_DESCRIPTION(formatMessage),
      type: INPUT_TYPES.TEXT,
    },
    {
      name: PERFORMANCE_NETWORK_STEP_PEOPLE_MINIMUM,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.network_step_minimum"
          defaultMessage="Network step minimum number of people"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.network_step_minimum"
          defaultMessage="3 is recommended to capture signal and ensure all parts of the organization are recognized. Minimum number of people that must be mentioned for going to for help, advice, or energy."
        />
      ),
      type: INPUT_TYPES.TEXT,
    },
    {
      name: PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_COMMENTS_REQUIRED,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.require_previous_direct_report_comments"
          defaultMessage="Require previous manager comments"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.require_previous_direct_report_comments"
          defaultMessage="Require that previous managers write brief comments about their previous direct reports. These will be shown to the current manager when they write their review."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      ...SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.callouts"
            defaultMessage="Callouts"
          />
        </h2>
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.callouts"
          defaultMessage="2-5 minutes to complete. We capture network recognition and concerns via up to 3 gold stars (restricted to make them valuable) and heads ups (0 or more). For benchmarking purposes, we do not allow changing the text of the callout questions beyond the options below."
        />
      ),
    },
    {
      name: PERFORMANCE_FEATURE_DISABLE_CALLOUTS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.enable_callouts"
          defaultMessage="Enable callout questions"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.enable_callouts"
          defaultMessage="Strongly recommended. Allow people to recognize outstanding contributors and people of concern."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_CALLOUTS_DESCRIPTION,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.callouts_section_text_description"
          defaultMessage="Callouts section text description"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.callouts_section_text_description"
          defaultMessage="Description at the top of the callouts section."
        />
      ),
      placeholder: DEFAULT_PERFORMANCE_CALLOUTS_DESCRIPTION(formatMessage),
      type: INPUT_TYPES.RICH_TEXT_EDITOR,
    },
    {
      name: PERFORMANCE_FEATURE_GOLD_STAR_COMMENTS_REQUIRED,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.require_gold_star_comments"
          defaultMessage="Require gold star comments"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.require_gold_star_comments"
          defaultMessage="If you enable this, gold stars cannot be given unless comments are provided. Note that some people may choose not to give gold stars at all."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_GOLD_STARS_AND_INFLUENCE_VISIBLE_TO_RECIPIENT,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.let_people_see_that_they_were_recognized"
          defaultMessage="Let people see that they were recognized by others in their final report."
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.let_people_see_that_they_were_recognized"
          defaultMessage="Strongly recommended. Allow individuals to confidentially see the positive recognition, i.e. gold stars and influence recognition, received by their network in a final report released by their managers after the cycle is over. Don't worry; if a person receives none, they will not see this section at all or be told it exists."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_HEADS_UP_COMMENTS_REQUIRED,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.require_heads_up_comments"
          defaultMessage="Require heads up comments"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.require_heads_up_comments"
          defaultMessage="If you enable this, heads ups cannot be given unless comments are provided. Note that some people may choose not to give the heads up at all."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_HEADS_UP_ANONYMOUS_TOGGLE,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.allow_individuals_to_make_their_heads_ups_confidential"
          defaultMessage="Allow individuals to make their heads ups confidential to everyone, even managers and HR/People admins."
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.allow_individuals_to_make_their_heads_ups_confidential"
          defaultMessage="Not recommended. While this might seem good for psychological safety, it makes these much less actionable, and in the rare situations where it has been used, we see much more unconstructive feedback given which can harm company culture."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    ...(options.isSuperAdmin
      ? [
          {
            name: PERFORMANCE_FEATURE_DISPLAY_HEADS_UPS_TO_RECIPIENT,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.display_heads_ups"
                defaultMessage="Display received heads ups to recipients in Performance Profile"
              />
            ),
            helperText: (
              <>
                <FormattedMessage
                  id="app.views.administration.campaign_flow.helper_text.display_heads_ups"
                  defaultMessage="This setting determines whether employees see the heads ups they receive for a performance cycle in their Performance Profile."
                />
                <ul>
                  <li>
                    <FormattedMessage
                      id="app.views.administration.campaign_flow.helper_text.display_heads_ups_hidden"
                      defaultMessage="“Hidden” means only an employee's manager and above can see their Heads Ups."
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="app.views.administration.campaign_flow.helper_text.display_heads_ups_count"
                      defaultMessage="“Count only” means employees can see a count of the heads ups they receive, but not the attached comments."
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="app.views.administration.campaign_flow.helper_text.display_heads_ups_comments"
                      defaultMessage="“Include Comments” means employees can see the heads ups they received with the attached comments."
                    />
                  </li>
                </ul>
              </>
            ),
            ...DROPDOWN_INPUT_ATTRIBUTES,
            defaultValue: 'hidden',
            objects: [
              {
                id: 'hidden',
                name: formatMessage({
                  id: 'app.views.administration.campaign_flow.display_heads_ups.dropdown.hidden',
                  defaultMessage: 'Hidden (Default)',
                }),
              },
              {
                id: 'count',
                name: formatMessage({
                  id: 'app.views.administration.campaign_flow.display_heads_ups.dropdown.count',
                  defaultMessage: 'Count only',
                }),
              },
              {
                id: 'full',
                name: formatMessage({
                  id: 'app.views.administration.campaign_flow.display_heads_ups.dropdown.full',
                  defaultMessage: 'Include comments',
                }),
              },
            ],
          },
        ]
      : []),
    ...(options.show360Features
      ? [
          {
            ...SECTION_HEADER_ATTRIBUTES,
            label: (
              <h2 className="mb-0">
                <FormattedMessage
                  id="app.views.administration.campaign_flow.request_peers"
                  defaultMessage="Request peers"
                />
              </h2>
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.request_peers"
                defaultMessage="3-10 minutes. Only relevant if you have the peer feedback phase enabled in the next section below."
              />
            ),
          },
          {
            name: PERFORMANCE_FEATURE_NETWORK_STAKEHOLDERS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.let_individuals_select_peers"
                defaultMessage="Let individuals select peers to give them peer feedback"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.let_individuals_select_peers"
                defaultMessage="Only relevant if Peer Feedback is enabled in the next phase. Peers can be chosen by managers instead (see below)."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_HIGH_PRIORITY_PEERS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.manager_selected_peers"
                defaultMessage="Manager-selected peers for Peer Feedback"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.manager_selected_peers"
                defaultMessage="Allow managers to select people to do peer reviews of their direct reports. Their selections, if provided, will be prioritized over all other selection criteria."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
        ]
      : []),
    ...(options.show360Features
      ? [
          {
            name: PERFORMANCE_FEATURE_MINIMUM_MANDATORY_PEERS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.minimum_mandatory_peers"
                defaultMessage="Minimum Peer Feedback peers that must be chosen by managers"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.minimum_mandatory_peers"
                defaultMessage="Minimum Peer Feedback peers that must be chosen by managers. If blank, no mimimum is enforced."
              />
            ),
            type: INPUT_TYPES.TEXT,
          },
          {
            name: PERFORMANCE_FEATURE_MAXIMUM_MANDATORY_PEERS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.maximum_mandatory_peers"
                defaultMessage="Maximum  Peer Feedback peers that must be chosen by managers"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.maximum_mandatory_peers"
                defaultMessage="Maximum  Peer Feedback peers that must be chosen by managers. If blank, no maximum is enforced."
              />
            ),
            type: INPUT_TYPES.TEXT,
          },
        ]
      : []),
    ...(!options.disableSelfReflection
      ? [
          {
            ...SECTION_HEADER_ATTRIBUTES,
            label: (
              <h2 className="mb-0">
                <FormattedMessage
                  id="app.views.administration.campaign_flow.self_reflection"
                  defaultMessage="Self-reflection"
                />
              </h2>
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.self_reflection"
                defaultMessage="3-20 minutes or more, depending on how many questions you ask. Managers can see their answers unless a custom question is set to confidential."
              />
            ),
          },
          {
            name: PERFORMANCE_FEATURE_SELF_REFLECTION_INTRO_MESSAGE_HTML,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.self_reflection_intro_message"
                defaultMessage="Self-reflection introduction"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.self_reflection_intro_message"
                defaultMessage="A quick message to let the person shift gears from their network to self-reflection questions."
              />
            ),
            type: INPUT_TYPES.RICH_TEXT_EDITOR,
            defaultValue:
              DEFAULT_SELF_REFLECTION_INTRO_MESSAGE_HTML(formatMessage),
          },
          {
            name: PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.require_individuals_define_their_objectives"
                defaultMessage="Require individuals define their objectives (OKRs)"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.require_individuals_define_their_objectives"
                defaultMessage="Individuals can complete OKRs outside of perf, but this will require them to do so as part of the flow."
              />
            ),
            quarterHelperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.quarter_helper_text.require_individuals_define_their_objectives"
                defaultMessage="Create objectives for"
              />
            ),
            type: INPUT_TYPES.SWITCH_WITH_TIMEFRAME_SELECTOR,
            defaultValue: (context: CampaignFlowContextType) => {
              try {
                return {
                  enabled: context.phaseSelfSetObjectivesLegacy ?? false,
                  quarter: toTimeFrameDateFormat(
                    findNearestTimeFrame(
                      context.phaseSelfStartDate
                        ? parseDateFromUnicode(
                            context.phaseSelfStartDate,
                            'yyyy-MM-dd'
                          )
                        : new Date(),
                      context.objectivesOrganizationTimeFrameType,
                      context.objectivesOrganizationTimeFrameDefaultStartDateMMDD
                    )
                  ),
                };
              } catch (e) {
                // Remove this when we understand why this is happening
                console.error(
                  "Couldn't parse default value for PERFORMANCE_FEATURE_COMPLETE_OBJECTIVES_FOR_QUARTER_STEP. Falling back to a default quarter: check the reason why.",
                  JSON.stringify(e),
                  'Context:',
                  JSON.stringify(context)
                );
                return {
                  enabled: false,
                  quarter: toTimeFrameDateFormat({
                    start: getQuarterDate(new Date()),
                    end: getQuarterDate(new Date(), true),
                    type: 'QUARTER',
                  }),
                };
              }
            },
          },
          {
            name: PERFORMANCE_FEATURE_OBJECTIVES_HEADER,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.objectives_step_header"
                defaultMessage="Objectives step header"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.objectives_step_header"
                defaultMessage="What to display at the top of the Objectives step."
              />
            ),
            type: INPUT_TYPES.TEXT,
          },
          {
            name: PERFORMANCE_FEATURE_OBJECTIVES_MESSAGE_HTML,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.objective_introduction"
                defaultMessage="Objectives introduction"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.objective_introduction"
                defaultMessage="A quick message explaining how users should approach the Objectives section."
              />
            ),
            type: INPUT_TYPES.RICH_TEXT_EDITOR,
          },
          {
            name: PERFORMANCE_FEATURE_COMPLETE_RESUME_STEP,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.require_individuals_to_list_their_accomplishments"
                defaultMessage="Require individuals to list their accomplishments"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.require_individuals_to_list_their_accomplishments"
                defaultMessage="Require that individuals define their accomplishments for the performance period."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_ACCOMPLISHMENTS_INSTRUCTIONS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.accomplishments_introduction"
                defaultMessage="Accomplishments introduction"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.accomplishments_introduction"
                defaultMessage="A quick message asking people to list their top accomplishments"
              />
            ),
            type: INPUT_TYPES.RICH_TEXT_EDITOR,
          },
          {
            name: PERFORMANCE_ACCOMPLISHMENTS_MINIMUM,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.minimum_accomplishments"
                defaultMessage="Minimum accomplishments that must be provided"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.minimum_accomplishments"
                defaultMessage="Minimum accomplishments that must be provided by individuals. If blank, defaults to {defaultMinimum}."
                values={{
                  defaultMinimum: DEFAULT_ACCOMPLISHMENTS_MINIMUM,
                }}
              />
            ),
            type: INPUT_TYPES.TEXT,
          },
          {
            name: PERFORMANCE_ACCOMPLISHMENTS_MAXIMUM,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.maximum_accomplishments"
                defaultMessage="Maximum accomplishments that must be provided"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.maximum_accomplishments"
                defaultMessage="Maximum accomplishments that must be provided by individuals. Leave blank for no maximum."
              />
            ),
            type: INPUT_TYPES.TEXT,
          },
          {
            name: PERFORMANCE_ACCOMPLISHMENTS_DEFAULT_VISIBILITY_PRIVATE,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.default_accomplishments_visibility"
                defaultMessage="Default visibility of accomplishments to private"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.default_accomplishments_visibility"
                defaultMessage="Discouraged for cultures that value transparency. If this is enabled, people will not get as much value out of the profiles feature of Confirm where profiles display accomplishments."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_SELF_OPEN_RESPONSE_QUESTIONS,
            label: formatMessage({
              id: 'app.views.administration.campaign_flow.label.self_open_response_questions',
              defaultMessage:
                "Ask individuals custom questions for their manager's benefit",
            }),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.self_open_response_questions"
                defaultMessage="If you want to define a custom set of questions to ask individuals so their managers can see, e.g. for self-reflection."
              />
            ),
            type: INPUT_TYPES.QUESTIONS_EDITOR,
          },
        ]
      : []),
  ];
};

const getIntroductionPhaseOptions = (): ValidatedInputConfig[] => {
  return [
    {
      ...FIRST_SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.welcome"
            defaultMessage="Welcome"
          />
        </h2>
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.welcome"
          defaultMessage="1 minute to complete if your instructions are brief."
        />
      ),
      jsonRoot: '$.configs',
    },
    {
      name: PERFORMANCE_FEATURE_WELCOME_HEADER,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.welcome_header"
          defaultMessage="Welcome header"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.welcome_header"
          defaultMessage="What to display to all participants at the beginning."
        />
      ),
      type: INPUT_TYPES.TEXT,
      jsonRoot: '$.configs',
    },
    {
      name: PERFORMANCE_FEATURE_WELCOME_MESSAGE_HTML,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.introduction"
          defaultMessage="Introduction"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.introduction"
          defaultMessage="A quick summary of what participants should keep in mind before starting. 1-2 sentences is ideal to save time."
        />
      ),
      type: INPUT_TYPES.RICH_TEXT_EDITOR,
      jsonRoot: '$.configs',
    },
    {
      name: PERFORMANCE_FEATURE_SHOW_PERFORMANCE_PERIOD_WITH_SPECIFIC_DATES,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.show_performance_period_with_specific_dates"
          defaultMessage="In all sections below, show the words “evaluation period” instead of “[x] months” in questions asked where “'{{duration}}'” is used."
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.show_performance_period_with_specific_dates"
          defaultMessage='We will use exact months, e.g. "6 months" for a 6 month long evaluation period, but if you have specific dates and want your questions to be more generic and say "evaluation period" instead of the number of months, enable this.'
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
      jsonRoot: '$.configs',
    },
    {
      ...SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.basics"
            defaultMessage="Basics"
          />
        </h2>
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.basics"
          defaultMessage="1 minute to complete. This is a quick warm-up that people breeze through. We capture essential information as structured data that powers the rest of the experience, so these questions cannot be modified."
        />
      ),
      jsonRoot: '$.configs',
    },
    {
      name: PERFORMANCE_FEATURE_MANAGER_UNEDITABLE,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.allow_individuals"
          defaultMessage="Allow individuals to report if their manager is incorrect"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.allow_individuals"
          defaultMessage="Recommended. If an individual believes their manager is different, we will highlight these differences in the Performance Dashboard with a red (!) icon for your review. No managers will be changed automatically. You can choose to ignore these alerts and nothing will happen."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
      jsonRoot: '$.configs',
    },
    {
      name: PERFORMANCE_FEATURE_PREVIOUS_DIRECT_REPORT_CALLOUTS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.capture_past_direct"
          defaultMessage="Capture past direct reports with option to provide comments to current manager"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.capture_past_direct"
          defaultMessage="Let individuals mention previous direct reports for display in reporting as context for calibrations, etc."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
      jsonRoot: '$.configs',
    },
    {
      name: PERFORMANCE_FEATURE_WELCOME_MEDIA,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.welcome_media"
          defaultMessage="Welcome media"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.welcome_media"
          defaultMessage="An optional video or image to display to all participants at the beginning."
        />
      ),
      type: INPUT_TYPES.MEDIA_UPLOADER,
      jsonRoot: '$.configs',
    },
  ];
};

const getOthersPhaseOptions = (
  formatMessage,
  options
): ValidatedInputConfig[] => {
  return [
    {
      ...FIRST_SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.how_this_phase_works_others"
            defaultMessage="How this phase works"
          />
        </h2>
      ),
      helperText: options.show360Features ? (
        <p>
          <FormattedMessage
            id="app.views.administration.campaign_flow.how_this_phase_works_others_helper_text"
            defaultMessage="
            This phase is optional. It is a chance for direct reports to give
            feedback to their managers and take a brief pulse engagement survey
            which can have responses be confidential. It is also a chance to
            complete peer feedback, however peer feedback is prone to selection
            bias, recency bias, and deenergizing top performers who often are
            requested by many peers to write about them. We discourage using
            peer feedback to evaluate performance, but for specific individuals
            who may not get feedback through continuous feedback or
            organizational network analysis, this is an alternative method to
            get that feedback."
          />
        </p>
      ) : (
        <p>
          <FormattedMessage
            id="app.views.administration.campaign_flow.how_this_phase_works_others_helper_text_no_360"
            defaultMessage="
          This phase is optional. For those who want to do it, it is a chance
          for direct reports to give feedback to their managers and take a
          brief pulse engagement survey which can have responses be confidential.
        "
          />
        </p>
      ),
    },
    ...START_AND_END_DATE_OPTIONS,
    ...(options.disableUpwardFeedback
      ? []
      : [
          {
            ...SECTION_HEADER_ATTRIBUTES,
            label: (
              <h2 className="mb-0">
                <FormattedMessage
                  id="app.views.administration.campaign_flow.upward_feedback"
                  defaultMessage="Upward feedback"
                />
              </h2>
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.upward_feedback"
                defaultMessage="From direct reports to their managers. 1-20 minutes or more, depending on how many questions you ask."
              />
            ),
          },
          {
            name: PERFORMANCE_FEATURE_ASSESS_MANAGER,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.enable_upward_feedback"
                defaultMessage="Enable upward feedback"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.enable_upward_feedback"
                defaultMessage="Ask individuals to provide feedback about their managers."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_UPWARD_MANAGER_OPEN_RESPONSE_QUESTIONS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.custom_open_response_questions_for_upward_manager_feedback"
                defaultMessage="Custom open response questions for upward manager feedback"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.custom_open_response_questions_for_upward_manager_feedback"
                defaultMessage="Ask custom questions to individuals about their managers."
              />
            ),
            type: INPUT_TYPES.QUESTIONS_EDITOR,
          },
          {
            name: PERFORMANCE_FEATURE_HIDE_ASSESS_MANAGER_FEEDBACK_FROM_SUBJECT,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.allow_managers_to_see_the_feedback_from_their_direct_reports"
                defaultMessage="Allow managers to see the feedback from their direct reports"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.allow_managers_to_see_the_feedback_from_their_direct_reports"
                defaultMessage="Recommended. It will be unattributed, i.e. you can see the set of people that wrote, but you don't know who said what. If this is disabled, only those above them in the chain of command and their HR/People admins will be able to see the feedback."
              />
            ),
            ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
        ]),
    ...(options.show360Features
      ? [
          {
            ...SECTION_HEADER_ATTRIBUTES,
            label: (
              <h2 className="mb-0">
                <FormattedMessage
                  id="app.views.administration.campaign_flow.peer_360"
                  defaultMessage="Peer Feedback"
                />
              </h2>
            ),
            helperText: (
              <>
                <FormattedMessage
                  id="app.views.administration.campaign_flow.peer_360_helper_text"
                  defaultMessage="
                    <p>5-120 minutes or more, based on how many peers you are assigned. Let peers and managers select peers for detailed reviews.</p>
                    <p>Note that peer feedback is prone to selection bias, recency bias, and deenergizing top performers who are often requested by many peers to write about them. We discourage using peer feedback to evaluate performance, but for specific individuals who may not get feedback through continuous feedback or organizational network analysis, this is an alternative method to get that feedback.</p>
                  "
                  values={{
                    p: (chunks) => <p>{chunks}</p>,
                  }}
                />
              </>
            ),
          },
        ]
      : []),
    ...(options.show360Features
      ? [
          {
            name: PERFORMANCE_FEATURE_PEER_FEEDBACK,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.enable_peer_360_reviews"
                defaultMessage="Enable peer feedback reviews"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.enable_peer_360_reviews"
                defaultMessage="Ask individuals to provide feedback about their peers."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
        ]
      : []),
    ...(options.show360Features
      ? [
          {
            name: PERFORMANCE_FEATURE_ADD_OPTIONAL_PEER_FEEDBACK,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.allow_for_unsolicited_peer_feedback"
                defaultMessage="Allow for unsolicited peer feedback"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.allow_for_unsolicited_peer_feedback"
                defaultMessage="Allow individuals to provide a peer review to up to two people, even if it was not requested."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
        ]
      : []),
    ...(options.show360Features
      ? [
          {
            name: PERFORMANCE_FEATURE_HIDE_PEER_FEEDBACK_FROM_SUBJECT,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.allow_individuals_see_their_peer_360_review_feedback"
                defaultMessage="Allow individuals see their peers' feedback"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.allow_individuals_see_their_peer_360_review_feedback"
                defaultMessage="Recommended. It will be unattributed, i.e. you can see the set of people that wrote, but you you don't know who said what. If this is disabled, only those above them in the chain of command and their HR/People admins will be able to see the feedback."
              />
            ),
            ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_PEER_FEEDBACK_ASSIGNMENT_TIME,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.peer_feedback_assignment_time"
                defaultMessage="Customize the peer assignment time"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.peer_feedback_assignment_time"
                defaultMessage="If not customised, the peer feedback peers will be assigned based on the suggestions given by individuals and managers at midnight UTC on the start date of the phase."
              />
            ),
            type: INPUT_TYPES.DATE_PICKER,
            enableTime: true,
          },
          {
            name: PERFORMANCE_FEATURE_OTHERS_OPEN_RESPONSE_QUESTIONS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.custom_open_response_questions_for_peer_360s"
                defaultMessage="Custom open response questions for peer feedback"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.custom_open_response_questions_for_peer_360s"
                defaultMessage="Ask custom questions to peers."
              />
            ),
            type: INPUT_TYPES.QUESTIONS_EDITOR,
          },
        ]
      : []),
    ...(options.disableEnps
      ? []
      : [
          {
            ...SECTION_HEADER_ATTRIBUTES,
            name: 'employee_nps_section_header',
            label: (
              <h2 className="mb-0">
                <FormattedMessage
                  id="app.views.administration.campaign_flow.employee_nps_header.label"
                  defaultMessage="eNPS"
                />
              </h2>
            ),
            helperText: (
              <>
                <FormattedMessage
                  id="app.views.administration.campaign_flow.employee_nps_header.helpertext"
                  defaultMessage='Shows as "Feedback for us" at the end of this phase. 1 question, takes under 1 minute to complete.'
                />
                <br />
                <br />
                <FormattedMessage
                  id="app.views.widgets.dashboards.takeaways_dashboard.employee_nps_header.header.tooltip.description_part1"
                  defaultMessage="eNPS is a measure of employee engagement and loyalty. It is calculated by subtracting the
            percentage of detractors, who respond with a score of 0-6, from the percentage of promoters, who respond
            with a score of 9-10. Passives, who respond with a score of 7-8, are ignored.
            The overall score can range from -100 to 100."
                />
              </>
            ),
          },
          {
            name: PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_ENABLED,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.employee_nps_question_enabled.label"
                defaultMessage="Ask individuals to complete an eNPS question"
              />
            ),

            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.employee_nps_question_enabled.helpertext.generic"
                defaultMessage="Collect employee NPS data for your organization"
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_EMPLOYEE_NPS_QUESTION_OPTIONAL,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.employee_nps_question_required.label"
                defaultMessage="Allow users to skip eNPS"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.employee_nps_question_required.helpertext"
                defaultMessage="For accuracy, we recommend collecting an eNPS response from all employees."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: 'employee_nps_question_placeholder',
            type: INPUT_TYPES.EMPLOYEE_NPS_PREVIEW,
          },
          {
            name: 'employee_nps_comment_placeholder',
            type: INPUT_TYPES.TEXTAREA,
            disableOnChange: true, // TODO (tford) find a better way to disable autosave
            maxLength: 1000,
            minRows: 3,
            maxRows: 15,
            placeholder: formatMessage({
              id: 'app.views.administration.campaign_flow.employee_nps_comment_placeholder.placeholder',
              defaultMessage: 'Add a confidential comment here (optional)',
            }),
          },
        ]),
    ...(options.disableEngagementSurvey
      ? []
      : [
          {
            ...SECTION_HEADER_ATTRIBUTES,
            label: (
              <h2 className="mb-0">
                <FormattedMessage
                  id="app.views.administration.campaign_flow.quick_pulse_engagement_survey"
                  defaultMessage="Quick pulse engagement survey"
                />
              </h2>
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.quick_pulse_engagement_survey"
                defaultMessage='Shows as "Feedback for us" at the end of this phase. 1-3 minutes or more, depending on how many questions you ask. Likert scale questions can be done quickly here.'
              />
            ),
          },
          {
            name: PERFORMANCE_FEATURE_ASSESS_ORGANIZATION,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.ask_individuals_to_complete_a_brief_pulse_check_engagement_survey"
                defaultMessage="Ask individuals to complete a brief pulse check engagement survey"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.ask_individuals_to_complete_a_brief_pulse_check_engagement_survey"
                defaultMessage="While they are here, ask a few very simple questions about their engagement."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_ASSESS_ORGANIZATION_OPEN_RESPONSE_QUESTIONS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.custom_questions_for_brief_pulse_check_engagement_survey"
                defaultMessage="Custom questions for brief pulse check engagement survey"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.custom_questions_for_brief_pulse_check_engagement_survey"
                defaultMessage="Provide a custom set of questions to ask individuals to assess the organization."
              />
            ),
            type: INPUT_TYPES.QUESTIONS_EDITOR,
            canBeAnonymous: true,
          },
          {
            name: PERFORMANCE_FEATURE_HIDE_PULSE_ANONYMOUS_DESCRIPTION,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.make_pulse_check_engagement_survey_results_confidential"
                defaultMessage="Make pulse check engagement survey results confidential"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.make_pulse_check_engagement_survey_results_confidential"
                defaultMessage="Do not see who said what in final reporting. Warning: while this may increase psychological safety, it will decrease actionability."
              />
            ),
            ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_MINIMUM_SURVEY_RESPONDENTS,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.minimum_survey_respondents"
                defaultMessage="Minimum group size for visibility"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.minimum_survey_respondents_helper"
                defaultMessage="Results from groups smaller than this number will be moved to an 'Others' category to ensure data anonymity."
              />
            ),
            type: INPUT_TYPES.TEXT,
            placeholder: DEFAULT_MINIMUM_SURVEY_RESPONDENTS,
          },
          {
            name: PERFORMANCE_FEATURE_ENGAGEMENT_SURVEY_SUMMARIES,
            disabled: !options.enableEngagementSurveySummaries,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.show_chatgpt_summaries_of_engagement_survey_results"
                defaultMessage="Show ChatGPT summaries of engagement survey results"
              />
            ),
            helperText: options.enableEngagementSurveySummaries ? (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.enable.show_chatgpt_summaries_of_engagement_survey_results"
                defaultMessage="Allow Confirm to employ ChatGPT to summarize engagement survey results."
              />
            ) : (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.not_enabled.show_chatgpt_summaries_of_engagement_survey_results"
                defaultMessage="This feature is not enabled for your organization."
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
        ]),
  ];
};

const getEvaluationPhaseOptions = (
  features: Features
): ValidatedInputConfig[] => {
  return [
    {
      ...FIRST_SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.evaluation"
            defaultMessage="How this phase works"
          />
        </h2>
      ),
      helperText: (
        <>
          <p>
            <FormattedMessage
              id="app.views.administration.campaign_flow.evaluation_helper_text_1"
              defaultMessage="
          During this phase, managers review each of their direct reports and,
          optionally, provide a rating for each of their direct reports. The
          manager will be presented with whatever information you choose to
          provide them below, including any responses from previous phases.
        "
            />
          </p>
          <p>
            <FormattedMessage
              id="app.views.administration.campaign_flow.evaluation_helper_text_2"
              defaultMessage="
          We recommend that you provide the manager with all possible
          information below to ensure they make informed evaluations about their
          people.
        "
            />
          </p>
          <p>
            <FormattedMessage
              id="app.views.administration.campaign_flow.evaluation_helper_text_3"
              defaultMessage="
          Note that this phase is also optional, meaning you could do a perf
          cycle with only the first phase (or first two phases) and have a very
          quick cycle without manager ratings or reviews.
        "
            />
          </p>
        </>
      ),
    },
    ...START_AND_END_DATE_OPTIONS,
    {
      ...SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.review_direct_reports"
            defaultMessage="Review of direct reports"
          />
        </h2>
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.review_direct_reports"
          defaultMessage="From managers to their direct reports. 5-60 minutes or more, depending on how many questions you ask and how many direct reports a person has."
        />
      ),
    },
    {
      name: PERFORMANCE_FEATURE_EVALUATION_PHASE_OPEN_RESPONSES_HELPER_TEXT,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.open_responses_header"
          defaultMessage="Open responses header"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.open_responses_header"
          defaultMessage="What to display to managers before open responses"
        />
      ),
      type: INPUT_TYPES.RICH_TEXT_EDITOR,
    },
    {
      name: PERFORMANCE_FEATURE_SHOW_CRITICAL_FEEDBACK_TO_MANAGERS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.show_critical_feedback_to_managers"
          defaultMessage="Show continuous feedback from the performance period"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.show_critical_feedback_to_managers"
          defaultMessage="When a manager is reviewing, show any continuous feedback an employee received to help inform the review."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_SHOW_RECOGNITION_TO_MANAGERS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.show_recognition_to_managers"
          defaultMessage="Show continuous recognition from the performance period"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.show_recognition_to_managers"
          defaultMessage="When a manager is reviewing, show any continuous recognition an employee received to help inform the review."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_SHOW_OBJECTIVES_TO_MANAGERS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.show_objectives_to_managers"
          defaultMessage="Show objectives (OKRs) from the performance period"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.show_objectives_to_managers"
          defaultMessage="When a manager is reviewing, show the person's objectives to help inform the review."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_HIDE_TIMELINE_ACTIVITIES,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.hide_timeline_activities"
          defaultMessage="Show list of accomplishments"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.hide_timeline_activities"
          defaultMessage="Recommended. Let managers see the accomplishments an employee mentions when reviewing their performance."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_EVALUATION_OPEN_RESPONSE_QUESTIONS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.evaluation_open_response_questions"
          defaultMessage="Evaluation open response questions"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.evaluation_open_response_questions"
          defaultMessage="Ask managers custom questions about their direct reports. Use '{{name}}' to put the direct report's name into the question. Use '{{duration}}' to put the performance period's duration into the question."
        />
      ),
      type: INPUT_TYPES.QUESTIONS_EDITOR,
      canBePrivate: true,
    },
    {
      name: PERFORMANCE_FEATURE_HIDE_MANAGER_RATING,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.allow_manager_rating_to_direct_reports"
          defaultMessage="Allow managers to provide ratings to their direct reports"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.allow_manager_rating_to_direct_reports"
          defaultMessage="Recommended. If this is disabled, managers will only provide written feedback but not a quantitative rating score."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_HIDE_RATING_FROM_DIRECT_REPORT,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.allow_direct_reports_to_see_rating"
          defaultMessage="Allow direct reports to see the rating they received"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.allow_direct_reports_to_see_rating"
          defaultMessage="Strongly recommended for transparency. If this is disabled, only a person's manager and above, along with HR administrators, will see the person's rating."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_RATING_SCALE,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.manager_rating_scale"
          defaultMessage="Manager ratings for direct reports"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.manager_rating_scale"
          defaultMessage="Ratings are useful as a high level summary of manager opinion based on an individual's current level."
        />
      ),

      type: INPUT_TYPES.TABLE_EDITOR,
      addText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.add_text.manager_rating_scale"
          defaultMessage="Add rating"
        />
      ),
      columns: [
        {
          name: (
            <FormattedMessage
              id="app.views.administration.campaign_flow.columns.manager_rating_scale.name.name"
              defaultMessage="Rating name"
            />
          ),
          field: 'name',
          sortable: false,
          popoverContent: (
            <FormattedMessage
              id="app.views.administration.campaign_flow.columns.manager_rating_scale.name.popover_content"
              defaultMessage='The name of the rating, e.g. "Exceeds Expectations"'
            />
          ),
          type: INPUT_TYPES.TEXTAREA,
          generateDefaultValue: (length) => `Rating ${length + 1}`,
        },
        {
          name: (
            <FormattedMessage
              id="app.views.administration.campaign_flow.columns.manager_rating_scale.value.name"
              defaultMessage="Value"
            />
          ),
          field: 'value',
          sortable: false,
          popoverContent: (
            <FormattedMessage
              id="app.views.administration.campaign_flow.columns.manager_rating_scale.value.popover_content"
              defaultMessage="The numeric value of the rating, e.g. 4"
            />
          ),
          type: INPUT_TYPES.TEXT,
          formatValueOnSubmit: formatToFloat,
          suspendFormatValueOnSubmit: isIncompleteDecimalNumber,
          generateDefaultValue: (length, value: { value: number }[] | any) =>
            value?.reduce((acc, item) => {
              if (item.value > acc) {
                return item.value;
              }
              return acc;
            }, 0) + 1,
        },
        {
          name: (
            <FormattedMessage
              id="app.views.administration.campaign_flow.columns.manager_rating_scale.description.name"
              defaultMessage="Description"
            />
          ),
          field: 'description',
          sortable: false,
          popoverContent: (
            <FormattedMessage
              id="app.views.administration.campaign_flow.columns.manager_rating_scale.description.popover_content"
              defaultMessage="A few sentences describing the criteria for receiving this rating."
            />
          ),
          type: INPUT_TYPES.TEXTAREA,
        },
      ],
    },
    {
      name: PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.promotion_nominations_by_manager"
          defaultMessage="Promotion nominations by manager"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.promotion_nominations_by_manager"
          defaultMessage="Allow managers to nominate their direct reports for promotion. A simple yes/no checkbox will show"
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_LABEL,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.promotion_nomination_question"
          defaultMessage="Promotion nomination question"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.promotion_nomination_question"
          defaultMessage="Customize the “Do you wish to nominate '{{name}}' for promotion?” question with your own version."
        />
      ),
      defaultValue: DEFAULT_PERFORMANCE_PROMOTION_NOMINATION_BY_MANAGER_LABEL,
      type: INPUT_TYPES.RICH_TEXT_EDITOR,
    },
    {
      name: PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.promotion_nomination_helper_text"
          defaultMessage="Promotion nomination helper text"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.promotion_nomination_helper_text"
          defaultMessage="Text showing below promotion nomination question to provide more context for the manager."
        />
      ),
      defaultValue:
        DEFAULT_PERFORMANCE_PROMOTION_NOMINATION_BY_MANAGER_HELPER_TEXT,
      type: INPUT_TYPES.RICH_TEXT_EDITOR,
    },
    {
      name: PERFORMANCE_FEATURE_PROMOTION_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.simple_promotion_nomination_comments"
          defaultMessage="Simple promotion nomination comments"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.simple_promotion_nomination_comments"
          defaultMessage="Allow managers to provide brief comments about why they are nominating someone for promotion."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_PROMOTION_PACKETS_REQUIRED,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.promotion_packets"
          defaultMessage="Promotion packets"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.promotion_packets"
          defaultMessage="Require a promotion packet to nominate for promotion. These can be inputted into Confirm and approvals managed through here."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.salary_increase_nomination_by_manager"
          defaultMessage="Salary increase nominations by manager"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.salary_increase_nomination_by_manager"
          defaultMessage="Allow managers to nominate their direct reports for a salary increase. A simple yes/no checkbox will show"
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.salary_increase_nomination_question"
          defaultMessage="Salary increase nomination question"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.salary_increase_nomination_question"
          defaultMessage="Customize the “Do you wish to nominate '{{name}}' for a salary increase?”	 question with your own version."
        />
      ),
      defaultValue:
        DEFAULT_PERFORMANCE_SALARY_INCREASE_NOMINATION_BY_MANAGER_LABEL,
      type: INPUT_TYPES.RICH_TEXT_EDITOR,
    },
    {
      name: PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.salary_increase_nomination_helper_text"
          defaultMessage="Salary increase nomination helper text"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.salary_increase_nomination_helper_text"
          defaultMessage="Text showing below salary increase nomination question to provide more context for the manager."
        />
      ),
      defaultValue:
        DEFAULT_PERFORMANCE_SALARY_INCREASE_NOMINATION_BY_MANAGER_HELPER_TEXT,
      type: INPUT_TYPES.RICH_TEXT_EDITOR,
    },
    {
      name: PERFORMANCE_FEATURE_SALARY_INCREASE_NOMINATION_BY_MANAGER_ALLOW_COMMENTS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.simple_salary_increase_nomination_comments"
          defaultMessage="Simple salary increase nomination comments"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.simple_salary_increase_nomination_comments"
          defaultMessage="Allow managers to provide brief comments about why they are nominating someone for a salary increase."
        />
      ),
      ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_HIDE_EVALUATION_POSITIVE_TAG_RECOMMENDATIONS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.show_positive_skill_and_behavior_recommendations"
          defaultMessage="Show positive skill and behavior recommendations"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.show_positive_skill_and_behavior_recommendations"
          defaultMessage="Recommended to save managers time. We suggest tags based on what peers and direct reports said about the person. You can hide these and require managers have to start from scratch."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    {
      name: PERFORMANCE_FEATURE_HIDE_EVALUATION_NEGATIVE_TAG_RECOMMENDATIONS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.show_negative_skill_and_behavior_recommendations"
          defaultMessage="Show negative skill and behavior recommendations"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.show_negative_skill_and_behavior_recommendations"
          defaultMessage="Recommended to save managers time. Similar to positive skill and behavior recommendations, this can be disabled as well."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    ...(isTalentMatricesEnabled(features)
      ? [
          {
            name: PERFORMANCE_FEATURE_SHOW_TALENT_MATRICES,
            label: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.label.talent_matrices_for_direct_reports"
                defaultMessage="Talent matrices for direct reports"
              />
            ),
            helperText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.helper_text.talent_matrices_for_direct_reports"
                defaultMessage='Conduct 9-box and other n-box assessments that produce "bucketing" visualization reports.'
              />
            ),
            ...ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
          },
          {
            name: PERFORMANCE_FEATURE_TALENT_MATRICES,
            type: INPUT_TYPES.TABLE_EDITOR,
            label: '',
            helperText: '',
            addText: (
              <FormattedMessage
                id="app.views.administration.campaign_flow.add_text.talent_matrices"
                defaultMessage="Add talent matrix"
              />
            ),
            showActions: false,
            columns: [
              {
                field: 'id',
                name: 'ID',
                sortable: false,
                type: INPUT_TYPES.TEXT,
                popoverContent: '',
                filterOnly: true,
                generateDefaultValue: () => uuidv4(),
              },
              {
                name: (
                  <FormattedMessage
                    id="app.views.administration.campaign_flow.talent_matrices.table.column.configuration.name"
                    defaultMessage="Matrix configuration"
                  />
                ),
                field: 'configuration',
                sortable: false,
                columnClassName: 'col-12',
                popoverContent: (
                  <FormattedMessage
                    id="app.views.administration.campaign_flow.talent_matrices.table.column.configuration.popover_content"
                    defaultMessage="Talent Matrix configuration description."
                  />
                ),
                type: INPUT_TYPES.CUSTOM_COMPONENT,
                component: TalentMatrixCampaignEditorTableItem,
              },
            ],
          },
        ]
      : []),
  ];
};

const getCalibrationPhaseOptions = (formatMessage) => {
  return [
    {
      ...FIRST_SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.calibration"
            defaultMessage="How this phase works"
          />
        </h2>
      ),
      helperText: (
        <p>
          {formatMessage(
            {
              id: 'app.views.administration.campaign_flow.calibration_helper_text_1',
              defaultMessage:
                'During calibration, HR/People admins can access the <link>Performance Dashboard</link> and change manager reviews with comments indicating why it was changed. For calibration meetings, HR/People admins can use the "Tier" selector on this page to go from the bottom of the org hierarchy and review with their managers, up to the top of the org hierarchy.',
            },
            {
              link: (chunks) => (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={consts.TEAM_PERFORMANCE_DASHBOARD().path}
                >
                  {chunks}
                </Link>
              ),
            }
          )}
        </p>
      ),
    },
    ...START_AND_END_DATE_OPTIONS,
  ];
};

const getReportingPhaseOptions = (formatMessage) => {
  return [
    {
      ...FIRST_SECTION_HEADER_ATTRIBUTES,
      label: (
        <h2 className="mb-0">
          <FormattedMessage
            id="app.views.administration.campaign_flow.reporting"
            defaultMessage="How this phase works"
          />
        </h2>
      ),
      helperText: (
        <>
          <p>
            <FormattedMessage
              id="app.views.administration.campaign_flow.reporting_helper_text_1"
              defaultMessage="
          During the reporting phase, managers can release reports to
          individuals showing any information they have visibility to based on
          the settings in the above phases.
        "
            />
          </p>
          <p>
            {formatMessage(
              {
                id: 'app.views.administration.campaign_flow.reporting_helper_text_2',
                defaultMessage:
                  "Managers must manually release reports from the <link1>Performance Dashboard</link1> for each person, ideally at the time they have a verbal conversation with a given direct report. After, direct reports will be asked to acknowledge the conversation and will have the opportunity to rate how valuable the feedback was and optionally provide comments which they can choose to share with their manager or keep them private so only their manager's manager and above and their HR/People admins can see it. These conversation feedback ratings and comments are available in the <link2>Performance Dashboard</link2>.",
              },
              {
                link1: (chunks) => (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={consts.TEAM_PERFORMANCE_DASHBOARD().path}
                  >
                    {chunks}
                  </Link>
                ),
                link2: (chunks) => (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={consts.TEAM_PERFORMANCE_DASHBOARD().path}
                  >
                    {chunks}
                  </Link>
                ),
              }
            )}
          </p>
          <p>
            <FormattedMessage
              id="app.views.administration.campaign_flow.reporting_helper_text_3"
              defaultMessage="At the end of this phase, any managers who have not yet released their reports will have them auto-released."
            />
          </p>
        </>
      ),
    },
    {
      name: PERFORMANCE_FEATURE_DISABLE_CONVERSATION_ACKNOWLEDGEMENTS,
      label: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.label.ask_employees_to_acknowledge_conversations_with_their_manager"
          defaultMessage="Ask employees to acknowledge conversations with their manager"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.ask_employees_to_acknowledge_conversations_with_their_manager"
          defaultMessage="Recommended. After managers release the performance report, employees will be asked to acknowledge and, optionally, provide a rating with comments regarding how helpful the feedback was."
        />
      ),
      ...REVERSE_ENABLE_OR_DISABLE_INPUT_ATTRIBUTES,
    },
    ...START_AND_END_DATE_OPTIONS,
  ];
};

export const getCampaignPropsListOptions = (
  wizardType: string,
  isSuperAdmin: boolean,
  features: Features,
  enableEmployeeNPS: boolean = true
): PropsListOptions => {
  const options: PropsListOptions = {
    disableSelfReflection: wizardType === WIZARD_TYPE_ONA_ONLY,
    disableUpwardFeedback:
      wizardType === WIZARD_TYPE_ENPS_ONLY ||
      wizardType === WIZARD_TYPE_ENGAGEMENT_ONLY,
    disableEngagementSurvey: wizardType === WIZARD_TYPE_ENPS_ONLY,
    disableEnps: false,
    show360Features:
      !!features?.cycle_admin?.peer_360_options_enabled &&
      wizardType !== WIZARD_TYPE_ONA_ONLY &&
      wizardType !== WIZARD_TYPE_ENPS_ONLY &&
      wizardType !== WIZARD_TYPE_ENGAGEMENT_ONLY,
    isSuperAdmin: isSuperAdmin,
    enableEmployeeNPS,
    enableEngagementSurveySummaries: isEnabled(
      features,
      FLAGS.ENGAGEMENT_SURVEY_SUMMARIES
    ),
  };
  return options;
};

export const getPropsList = (
  formatMessage,
  options: PropsListOptions,
  wizardType: string = 'P',
  features: Features
): CollapsiblePhaseConfig[] => {
  const selfTitle =
    wizardType === WIZARD_TYPE_ONA_ONLY ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.title.self.ona_only"
        defaultMessage="Your network"
      />
    ) : (
      <FormattedMessage
        id="app.views.administration.campaign_flow.title.self"
        defaultMessage="You & your network"
      />
    );

  const selfSubtitle =
    wizardType === WIZARD_TYPE_ONA_ONLY ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.self.ona_only"
        defaultMessage="Network (ONA) questions"
      />
    ) : (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.self"
        defaultMessage="Network (ONA) questions and self assessment"
      />
    );

  const othersTitle =
    wizardType === WIZARD_TYPE_ENPS_ONLY ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.title.others.enps_only"
        defaultMessage="Employee NPS Survey"
      />
    ) : wizardType === WIZARD_TYPE_ENGAGEMENT_ONLY ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.title.others.engagement_only"
        defaultMessage="Quick pulse engagement survey"
      />
    ) : options.show360Features ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.title.others.upward_feedback_peer_360s_pulse_survey"
        defaultMessage="Upward feedback, peer feedback, quick pulse engagement survey"
      />
    ) : (
      <FormattedMessage
        id="app.views.administration.campaign_flow.title.others"
        defaultMessage="Upward feedback and quick pulse engagement survey"
      />
    );

  const othersSubtitle =
    wizardType === WIZARD_TYPE_ENPS_ONLY ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.others.enps_only"
        defaultMessage="Net Promoter Score Survey for Employees"
      />
    ) : wizardType === WIZARD_TYPE_ENGAGEMENT_ONLY ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.others.engagement_only"
        defaultMessage="Engagement Survey"
      />
    ) : options.show360Features ? (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.others.upward_feedback_peer_360s_pulse_survey"
        defaultMessage="Upward feedback, peer feedback, pulse survey"
      />
    ) : (
      <FormattedMessage
        id="app.views.administration.campaign_flow.helper_text.others"
        defaultMessage="Upward feedback & pulse survey"
      />
    );

  return [
    {
      type: PHASE_TYPE_INTRODUCTION_UI_ONLY,
      title: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.phase.introduction.title"
          defaultMessage="Introduction"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.phase.introduction.helper_text"
          defaultMessage="Welcome message, basic information"
        />
      ),
      inputs: getIntroductionPhaseOptions(),
      // introduction phase is always required
      disableToggle: true,
    },
    {
      type: PHASE_TYPE_SELF,
      title: selfTitle,
      helperText: selfSubtitle,
      inputs: getSelfPhaseOptions(formatMessage, options),
      disableToggle: wizardType === WIZARD_TYPE_ONA_ONLY,
      excludeSpecialQuestions: ['rating'],
    },
    {
      type: PHASE_TYPE_OTHERS,
      disableToggle:
        wizardType === WIZARD_TYPE_ENGAGEMENT_ONLY ||
        wizardType === WIZARD_TYPE_ENPS_ONLY,
      title: othersTitle,
      helperText: othersSubtitle,
      inputs: getOthersPhaseOptions(formatMessage, options),
      questionDefaults: {
        negative_skills: {
          minLength: 0,
          required: false,
        },
        negative_comments: {
          required: false,
        },
      },
    },
    {
      type: PHASE_TYPE_EVALUATION,
      title: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.title.manager_reviews"
          defaultMessage="Manager reviews"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.manager_reviews"
          defaultMessage="Managers give feedback about each of their direct reports"
        />
      ),
      inputs: getEvaluationPhaseOptions(features),
      excludeSpecialQuestions: ['rating'],
    },
    {
      type: PHASE_TYPE_CALIBRATION,
      title: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.title.calibration"
          defaultMessage="Calibration"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.calibration"
          defaultMessage="For larger organizations, allow HR/People admins to change manager ratings"
        />
      ),
      inputs: getCalibrationPhaseOptions(formatMessage),
    },
    {
      type: PHASE_TYPE_REPORTING,
      title: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.title.conversations"
          defaultMessage="Conversations"
        />
      ),
      helperText: (
        <FormattedMessage
          id="app.views.administration.campaign_flow.helper_text.conversations"
          defaultMessage="Access deliverables, track, and acknowledge feedback conversations"
        />
      ),
      inputs: getReportingPhaseOptions(formatMessage),
    },
  ];
};
