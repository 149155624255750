import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import Page from '../Page';

const Page403: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Page
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page403.title.permission_denied',
        defaultMessage: 'Permission Denied',
      })}
    >
      <p>
        <FormattedMessage
          id="app.views.layout.pages.errors.page403.not_expected"
          defaultMessage="Not what you expected? Please contact Customer Support.
          Otherwise, feel free to go back to <link>your dashboard</link>."
          values={{
            link: (chunks) => <Link to="/">{chunks}</Link>,
          }}
        />
      </p>
    </Page>
  );
};

export default React.memo(Page403);
