import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Organization, Person, PulseCheck } from '../../types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import Modal from '../../components/SafeModal';
import PulseCheckCard from './PulseCheckCard';
import PulseCheckInput from './PulseCheckInput';
import { connect } from 'react-redux';
import { isSameWeek } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';

interface PulseCheckSummaryProps {
  hideTitle?: boolean;
  isMe: boolean;
  bodyOnly?: boolean;
  person: Person;
  currentOrganization: Organization;
  currentProxyPerson?: Person;
  setPulseChecks?: (pulseChecks: Array<PulseCheck> | null | undefined) => void;
}

const PulseCheckSummary: React.FC<PulseCheckSummaryProps> = (props) => {
  const { formatMessage } = useIntl();
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [, setIsDeleting] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);

  const [pulseChecks, setPulseChecks] = useState<
    PulseCheck[] | null | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [viewPastPulseChecks, setViewPastPulseChecks] = useState(false);

  const { user } = useAuth0();

  const userSub = user?.sub;
  const currentOrgId = props.currentOrganization?.id;
  const personId = props.person.id;

  const toggleViewPastPulseChecks = useCallback(() => {
    setViewPastPulseChecks(!viewPastPulseChecks);
  }, [viewPastPulseChecks]);

  // when pulse checks are updated, call callback (necessary to
  // see the pulse checks in the parent widget, e.g. when looking
  // at another person's on one-on-ones
  const propsSetPulseChecks = props.setPulseChecks;
  const updatePulseChecks = useCallback(
    (pcs) => {
      setPulseChecks(pcs);
      if (propsSetPulseChecks) {
        propsSetPulseChecks(pcs);
      }
    },
    [propsSetPulseChecks]
  );

  // fetch activities associated with this person
  useEffect(() => {
    if (userSub && currentOrgId && personId) {
      // @ts-expect-error
      ElasticsearchAPI.getPulseChecksForPerson(
        userSub,
        props.currentProxyPerson,
        currentOrgId,
        personId,
        (pulseChecks) => {
          updatePulseChecks(pulseChecks);
        },
        (message) => {
          // set this to null so parent widget
          // can show nothing if there is an error
          updatePulseChecks(null);
          setErrorMessage(message);
        }
      );
    }
    // reload page when new activities fetched
  }, [
    currentOrgId,
    personId,
    props.currentProxyPerson,
    updatePulseChecks,
    userSub,
  ]);

  const onDeletePulseCheck = useCallback(
    (p) => {
      ConfirmAPI.sendRequestToConfirm(
        'DELETE',
        '/pulse-checks/' + p?.id,
        {},
        (response, error, hardErrorMessage = null) => {
          setIsDeleting(false);
          if (error) {
            // failure; keep modal open
            if (hardErrorMessage) {
              // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
              // leave this message blank as those errors will get surfaced below
              setDeleteValidationErrors(hardErrorMessage);
            } else {
              setDeleteValidationErrors(error);
            }
          } else {
            setDeleteModalIsOpen(false);

            // remove this pulse check from the full list
            updatePulseChecks(pulseChecks?.filter((pc) => pc.id !== p?.id));
          }
        },
        null
      );
    },
    [pulseChecks, updatePulseChecks]
  );

  const hasPulseChecks = !!pulseChecks?.length;

  const latestPulseCheckWasThisWeek = useMemo(() => {
    const latestCheck = hasPulseChecks ? pulseChecks[0] : undefined;

    return (
      latestCheck && isSameWeek(new Date(latestCheck.created_at), new Date())
    );
  }, [hasPulseChecks, pulseChecks]);

  const toggleIsEditing = useCallback(() => {
    setIsEditing(!isEditing);
    setIsAdding(false);
  }, [isEditing]);

  const onAddNewPulseCheck = useCallback(() => {
    setIsEditing(false);
    setIsAdding(true);
  }, []);

  if (
    typeof pulseChecks === 'undefined' ||
    errorMessage ||
    // show nothing if there are no pulse checks and it is someone else
    (!pulseChecks?.length && !props.isMe)
  ) {
    return <></>;
  }

  const body = (
    <>
      {props.isMe && (isEditing || isAdding || !latestPulseCheckWasThisWeek) && (
        <PulseCheckInput
          // if adding, set to undefined so that the form will be blank
          value={
            isEditing && latestPulseCheckWasThisWeek
              ? pulseChecks?.[0]
              : undefined
          }
          callback={(p) => {
            // ValidatedForm will return null if there is an error
            // if there is an error, do nothing so the user can
            // press submit again
            if (p === null) {
              return;
            }

            if (isEditing) {
              // replace the pulse check with the matching id
              updatePulseChecks(
                pulseChecks?.map((pc) => {
                  if (pc.id === p.id) {
                    return p;
                  }
                  return pc;
                })
              );
            } else {
              updatePulseChecks([p, ...(pulseChecks || [])]);
            }
            setIsAdding(false);
            setIsEditing(false);
          }}
          cancelCallback={() => {
            setIsAdding(false);
            setIsEditing(false);
          }}
        />
      )}
      {props.isMe &&
        !isEditing &&
        !isAdding &&
        latestPulseCheckWasThisWeek &&
        pulseChecks?.[0] && (
          <>
            <ConfirmationDialogModal
              isOpen={deleteModalIsOpen}
              onClosed={() => {
                setIsDeleting(false);
                setDeleteValidationErrors(null);
              }}
              toggle={toggleDeleteModal}
              confirmCallback={() => onDeletePulseCheck(pulseChecks[0])}
              title={formatMessage({
                id: 'app.views.person.person_profile_pulse_check_summary.no_previous.delete_check_in_question',
                defaultMessage: 'Delete check-in?',
              })}
              description={formatMessage({
                id: 'app.views.person.person_profile_pulse_check_summary.no_previous.delete_check_in_description',
                defaultMessage:
                  'Are you sure that you want to delete this check-in?',
              })}
              confirmText={formatMessage({
                id: 'app.views.person.person_profile_pulse_check_summary.no_previous.delete_check_in',
                defaultMessage: 'Delete check-in',
              })}
              validationErrors={deleteValidationErrors}
            />
            <PulseCheckCard
              bodyOnly={true}
              toggleIsEditing={toggleIsEditing}
              onDelete={toggleDeleteModal}
              onAdd={onAddNewPulseCheck}
              person={props.person}
              pulseCheck={pulseChecks[0]}
            />
          </>
        )}
      {!props.isMe && pulseChecks?.[0] && (
        <div className={props.isMe ? 'mt-4' : ''}>
          <PulseCheckCard
            bodyOnly={true}
            person={props.person}
            pulseCheck={pulseChecks[0]}
          />
        </div>
      )}
      {!props.isMe && !pulseChecks?.[0] && (
        <div className="fst-italic">
          <FormattedMessage
            id="app.views.person.person_profile_pulse_check_summary.no_previous"
            defaultMessage="No previous check-ins"
          />
        </div>
      )}
      {hasPulseChecks && pulseChecks?.length > 1 && (
        <>
          <Modal
            isOpen={viewPastPulseChecks}
            toggle={toggleViewPastPulseChecks}
          >
            <ModalHeader toggle={toggleViewPastPulseChecks}>
              <FormattedMessage
                id="app.views.person.person_profile_pulse_check_summary.header.check_ins"
                defaultMessage="Check-ins"
              />
            </ModalHeader>
            <ModalBody>
              {pulseChecks.slice(1).map((pulseCheck, i) => (
                <div className={i === 0 ? '' : 'mt-4'} key={pulseCheck.id}>
                  <PulseCheckCard
                    bodyOnly={true}
                    person={props.person}
                    pulseCheck={pulseCheck}
                  />
                </div>
              ))}
            </ModalBody>
          </Modal>
          {pulseChecks.length > 1 && (
            <>
              <Button
                color="link p-0 mt-4 btn-sm"
                onClick={toggleViewPastPulseChecks}
              >
                <FormattedMessage
                  id="app.views.person.person_profile_pulse_check_summary.button.past_check_ins"
                  defaultMessage="
            View past check-ins
          "
                />
              </Button>
            </>
          )}
        </>
      )}
    </>
  );

  if (props.bodyOnly) {
    return body;
  }

  return (
    <Card>
      {!props.hideTitle && (
        <CardHeader>
          <CardHeaderTitle icon="fe fe-activity me-2">
            <FormattedMessage
              id="app.views.person.person_profile_pulse_check_summary.title.check_ins"
              defaultMessage="Check-ins"
            />
          </CardHeaderTitle>
        </CardHeader>
      )}
      <CardBody>{body}</CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { currentOrganization, currentProxyPerson } = state;

  return { currentOrganization, currentProxyPerson };
};

export default connect(mapStateToProps)(React.memo(PulseCheckSummary));
