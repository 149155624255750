import React, { FC } from 'react';

import { DEVELOPMENT_PLAN_STATUSES } from 'utils/models/DevelopmentPlan';

interface Props {
  filterStatuses: string[];
  toggleFilterStatuses: (status: string) => void;
}

const DevelopmentPlansFilterBar: FC<Props> = ({
  filterStatuses,
  toggleFilterStatuses,
}) => {
  return (
    <ul className="pagination pagination-sm mb-0">
      {DEVELOPMENT_PLAN_STATUSES.map((type, index) => (
        <li
          key={index}
          className={
            'page-item' +
            (filterStatuses.indexOf(type.id) !== -1 ? ' active' : '')
          }
          role="button"
          onClick={() => toggleFilterStatuses(type.id)}
        >
          <span className="page-link">
            <i
              className={'me-2 ' + type.icon}
              style={{ position: 'relative', top: 1 }}
            />
            {type.heading}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default DevelopmentPlansFilterBar;
