import { Col, Row } from 'reactstrap';
import React, { FC } from 'react';

import { AvailableMetricConfig } from '../TalentMatrix';
import Legend from './Legend';

interface Props {
  xMetric: string;
  yMetric: string;
  availableMetrics: AvailableMetricConfig[];
}

const Legends: FC<Props> = ({ xMetric, yMetric, availableMetrics }) => {
  return (
    <div className="text-muted pt-5 ps-5 pb-2">
      <Row>
        <Col className="col-6" key="x_metric">
          <Legend metric={availableMetrics.find((it) => it.id == xMetric)!} />
        </Col>
        <Col className="col-6" key="y_metric">
          <Legend metric={availableMetrics.find((it) => it.id == yMetric)!} />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(Legends);
