import React, { FC } from 'react';

import { Button } from 'reactstrap';

interface Props {
  viewPreviousTimeframe: () => void;
  currentTimeframeText: string;
  viewNextTimeframe: () => void;
  disabled?: boolean;
  buttonClassName?: string;
}

const ObjectivesTimeFrameSelector: FC<Props> = ({
  viewPreviousTimeframe,
  currentTimeframeText,
  viewNextTimeframe,
  disabled = false,
  buttonClassName = '',
}) => {
  return (
    <>
      <div className="text-center mb-5 mb-md-2">
        <Button
          className={
            'btn-sm btn-rounded-circle btn-white d-inline-block me-3 ' +
            buttonClassName
          }
          onClick={viewPreviousTimeframe}
          disabled={disabled}
        >
          <i
            className="fe fe-chevron-left"
            style={{ position: 'relative', top: '1px' }}
          />
        </Button>
        <div
          className="d-inline-block text-center text-muted mb-3"
          style={{ position: 'relative', top: '1px' }}
        >
          {currentTimeframeText}
        </div>
        <Button
          className={
            'btn-sm btn-rounded-circle btn-white d-inline-block ms-3 ' +
            buttonClassName
          }
          onClick={viewNextTimeframe}
          disabled={disabled}
        >
          <i
            className="fe fe-chevron-right"
            style={{ position: 'relative', top: '1px' }}
          />
        </Button>
      </div>
    </>
  );
};

export default ObjectivesTimeFrameSelector;
