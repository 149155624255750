import { ColumnDescriptor, ToggleState } from './types';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { INPUT_TYPES } from '../../Inputs/ValidatedInputTypes';
import ModalEditor from '../../Modals/ModalEditor';
import SafeUncontrolledPopover from '../../../../components/SafeUncontrolledPopover';
import { difference } from 'lodash';
import { filterColumnsWithBothIdAndDescriptionFields } from './hooks';

interface Props {
  modalIsOpen: boolean;
  onToggle: () => void;
  onCallback: (selectedColumns: string[], unselectedColumns: string[]) => void;
  columnDescriptors: ColumnDescriptor[];
  selectedColumns: string[];
}

interface FormObject {
  visible_fields: string[];
  select_all: ToggleState;
  unselected_columns_stash: string[];
}

const areAllColumnsSelected = (
  columns: ColumnDescriptor[],
  selectedColumns: string[]
): ToggleState => {
  if (selectedColumns.length === 0) return 'N';
  return selectedColumns.length === columns.length ? 'Y' : '-';
};

const FilterablePeopleTableColumnSelectorModal: FC<Props> = ({
  modalIsOpen,
  onToggle,
  onCallback,
  columnDescriptors,
  selectedColumns,
}) => {
  const { formatMessage } = useIntl();

  const [object, setObject] = useState<FormObject>(() => {
    const defaultUnselectedColumns =
      filterColumnsWithBothIdAndDescriptionFields(columnDescriptors).map(
        (column) => column.field
      );
    return {
      visible_fields: selectedColumns,
      select_all: areAllColumnsSelected(
        columnDescriptors,
        difference(selectedColumns, defaultUnselectedColumns)
      ),
      unselected_columns_stash: defaultUnselectedColumns,
    };
  });

  useEffect(() => {
    setObject((object) => ({
      ...object,
      visible_fields: difference(
        selectedColumns,
        object.unselected_columns_stash
      ),
      select_all: areAllColumnsSelected(
        columnDescriptors,
        difference(selectedColumns, object.unselected_columns_stash)
      ),
    }));
  }, [selectedColumns, columnDescriptors]);

  const handleCallBack = useCallback(
    (obj: FormObject): void => {
      onCallback(obj.visible_fields, obj.unselected_columns_stash);
    },
    [onCallback]
  );

  const onSelectAllSideEffect = useCallback(
    (_current: FormObject, next: ToggleState): Partial<FormObject> => {
      return {
        visible_fields:
          next === 'Y' ? columnDescriptors.map((column) => column.field) : [],
        unselected_columns_stash:
          next === 'Y' ? [] : columnDescriptors.map((column) => column.field),
      };
    },
    [columnDescriptors]
  );

  const onVisibleFieldsSideEffect = useCallback(
    (current: FormObject, next: string[]): Partial<FormObject> => {
      const removedItems = difference(current.visible_fields, next);
      const addedItems = difference(next, current.visible_fields);
      return {
        select_all: areAllColumnsSelected(columnDescriptors, next),
        unselected_columns_stash: difference(
          [...current.unselected_columns_stash, ...removedItems],
          addedItems
        ),
      };
    },
    [columnDescriptors]
  );

  const handleValidate = useCallback(
    (obj) => {
      if (obj.visible_fields.length === 0) {
        return {
          visible_fields: formatMessage({
            id: 'app.views.widgets.people.filterable_people_table_column_selector_modal.validation_error.select_at_least_one_field',
            defaultMessage: 'Select at least one field',
          }),
        };
      }
    },
    [formatMessage]
  );

  return (
    <ModalEditor
      isOpen={modalIsOpen}
      toggle={onToggle}
      title={formatMessage({
        id: 'app.views.widgets.people.filterable_people_table_column_selector_modal.title.select_fields_to_display',
        defaultMessage: 'Select fields to display',
      })}
      object={object}
      onValidate={handleValidate}
      onChange={setObject}
      callback={handleCallBack}
      submitText={formatMessage({
        id: 'app.views.widgets.people.filterable_people_table_column_selector_modal.submit_text.apply',
        defaultMessage: 'Apply',
      })}
      inputs={[
        {
          type: INPUT_TYPES.CHECKBOX,
          name: 'select_all',
          helperText: formatMessage({
            id: 'app.views.widgets.people.filterable_people_table_column_selector_modal.input.helper_text',
            defaultMessage:
              'Select all fields available that you would like to display on the table.',
          }),
          checkLabel: (
            <span className="fw-bold">
              <FormattedMessage
                id="app.views.widgets.people.filterable_people_table_column_selector_modal.input.select_all"
                defaultMessage="Select all"
              />
            </span>
          ),
          checkedValue: 'Y',
          uncheckedValue: 'N',
          indeterminateValue: '-',
          withIndeterminate: true,
          formGroupClassName: 'pb-2 mb-0',
          onChangeSideEffects: onSelectAllSideEffect,
        },
        {
          type: INPUT_TYPES.CHECKBOX_MULTI,
          rowProps: { xs: 1, sm: 2, md: 3 },
          name: 'visible_fields',
          onChangeSideEffects: onVisibleFieldsSideEffect,
          options: columnDescriptors.map((column) => ({
            key: column.field,
            label: (
              <>
                <span id={column.field}>{column.name}</span>
                {!!column.popoverContent && (
                  <SafeUncontrolledPopover
                    placement="top"
                    trigger="hover"
                    target={column.field}
                  >
                    {column.popoverContent}
                  </SafeUncontrolledPopover>
                )}
              </>
            ),
          })),
        },
      ]}
      disableUnsavedChangesPrompt={true}
    />
  );
};

export default FilterablePeopleTableColumnSelectorModal;
