import { Card, CardBody, Col, Row, UncontrolledPopover } from 'reactstrap';
import React, { FC } from 'react';

import PropTypes from 'prop-types';

const ContentSection: FC<Props> = (props) => {
  const helperHeader = props.id + '-header';

  return (
    <Col className="col-12">
      <Card>
        <CardBody>
          <Row className="align-items-center">
            <Col className="col pe-0">
              {props.title && (
                <h6 className="text-uppercase text-muted mb-2">
                  {props.title}
                  {props.helperText && (
                    <>
                      <i
                        id={helperHeader}
                        className="ms-2 fe fe-help-circle text-primary"
                      />
                      <UncontrolledPopover
                        placement="top"
                        trigger="hover"
                        target={helperHeader}
                      >
                        {props.helperText}
                      </UncontrolledPopover>
                    </>
                  )}
                </h6>
              )}
              {props.body}
            </Col>
            {props.icon && (
              <Col className="col-auto">
                <span className={props.icon + ' h2 text-muted mb-0'}></span>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

const ContentSection_propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  helperText: PropTypes.string,
  icon: PropTypes.string,
  body: PropTypes.any,
};

type Props = PropTypes.InferProps<typeof ContentSection_propTypes>;

export default ContentSection;
