import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, ReactElement, useCallback, useState } from 'react';

import Avatar from '../Widgets/People/Avatar';
import ConfirmAPI from '../../utils/api/ConfirmAPI';
import ConfirmationDialogModal from '../Widgets/Modals/ConfirmationDialogModal';
import { ContributionComment } from '../../types';
import EmojiBar from '../Widgets/EmojiBar';
// @ts-expect-error
import { IntercomAPI } from '../../vendor/react-intercom';
import { Link } from 'react-router-dom';
import RichTextViewer from '../Widgets/Inputs/RichTextViewer';
import TagsList from '../Widgets/TagsList';

const CANNED_BLURRED_TEXT_ARRAY = [
  "Easter egg! You're very clever. Add your contribution details to see what was said.",
  'Easter egg! You seem to be an inquisitive person. Have you considered job opportunities at Confirm? To see the comment, add your contribution first.',
  "Easter egg! Good job getting to this message. Have you considered job opportunities at Confirm? There's a very simple way to see what was said about you. Just add your contribution first.",
];

const CANNED_BLURRED_SKILLS_ARRAY = [
  [{ name: 'Inspector' }, { name: 'Web Design' }],
  [
    { name: 'CSS' },
    { name: 'Frontend Engineering' },
    { name: 'HTML' },
    { name: 'Inspector' },
  ],
  [
    { name: 'HTML' },
    { name: 'CSS' },
    { name: 'Frontend Engineering' },
    { name: 'Inspector' },
    { name: 'Product Design' },
    { name: 'Product Development' },
  ],
];

const getRandomCannedBlurredText = (length) => {
  return CANNED_BLURRED_TEXT_ARRAY[length <= 50 ? 0 : length <= 100 ? 1 : 2];
};

const getRandomCannedBlurredSkills = (length) => {
  return CANNED_BLURRED_SKILLS_ARRAY[length <= 2 ? 0 : length <= 4 ? 1 : 2];
};

interface Props {
  authorRole: string;
  comment: ContributionComment;
  isExternalUrl: boolean;
  lockedMessage?: ReactElement;
  lockedMessageOnClick: () => void;
  onDelete?: (object: ContributionComment) => void;
  onEdit?: () => void;
  preventInteraction: boolean;
}

const ContributionCommentBody: FC<Props> = ({
  authorRole,
  comment,
  isExternalUrl,
  lockedMessage,
  lockedMessageOnClick,
  onDelete,
  onEdit,
  preventInteraction,
}: Props) => {
  const { formatMessage } = useIntl();

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setConfirmDeleteModal(!confirmDeleteModal);
  const [deleteValidationErrors, setDeleteValidationErrors] = useState(null);

  const propsOnEdit = onEdit;
  const propsOnDelete = onDelete;
  const author = comment.author_person;

  const confirmDelete = useCallback(() => {
    ConfirmAPI.sendRequestToConfirm(
      'DELETE',
      '/contribution-comments/' + comment.id,
      {},
      (response, error, hardErrorMessage = null) => {
        if (error) {
          // failure; keep modal open
          if (hardErrorMessage) {
            // for hard failures (e.g. 500 error); for soft failures (e.g. validation issues)
            // leave this message blank as those errors will get surfaced below
            setDeleteValidationErrors(hardErrorMessage);
          } else {
            setDeleteValidationErrors(error);
          }
        } else {
          setConfirmDeleteModal(false);
          if (propsOnDelete) {
            propsOnDelete(comment);
          }
        }
      },
      null
    );
  }, [comment, propsOnDelete]);

  if (!author.url) {
    console.error('No author url found for comment author.');
    return null;
  }

  return (
    <div className="comment mb-0">
      <Row>
        <Col className="col-auto">
          <Avatar
            person={author}
            size="xs"
            className="mt-2"
            linked
            isExternalUrl={isExternalUrl}
          />
        </Col>
        <Col style={{ marginLeft: '-1rem' }}>
          <Row>
            <Col>
              <div
                className={
                  'comment-body d-block px-3' + (lockedMessage ? ' mb-3' : '')
                }
                style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
              >
                <Row>
                  <Col>
                    <span className="fw-bold small">
                      <Link
                        style={{ color: 'inherit' }}
                        to={author.url}
                        target={isExternalUrl ? '_blank' : undefined}
                        rel="noopener noreferrer"
                      >
                        {author.full_name}
                      </Link>
                      {authorRole && (
                        <span className="fw-normal">
                          <FormattedMessage
                            id="util.list_comma"
                            defaultMessage=","
                            description="comma between item in lists"
                          />{' '}
                          {authorRole}
                        </span>
                      )}
                    </span>
                  </Col>
                  {!preventInteraction && !lockedMessage && (
                    <Col
                      className="col-auto ps-0 pe-2 mb-n1"
                      style={{ marginTop: 2 }}
                    >
                      {propsOnDelete && (
                        <ConfirmationDialogModal
                          isOpen={confirmDeleteModal}
                          onClosed={() => setDeleteValidationErrors(null)}
                          toggle={toggleConfirmDeleteModal}
                          confirmCallback={confirmDelete}
                          title={formatMessage({
                            id: 'app.views.activities.contribution_comment.title.delete_details',
                            defaultMessage: 'Delete details?',
                          })}
                          description={formatMessage({
                            id: 'app.views.activities.contribution_comment.description.delete_details',
                            defaultMessage:
                              'Are you sure that you want to delete the details you provided?',
                          })}
                          confirmText={formatMessage({
                            id: 'app.views.activities.contribution_comment.confirm_text.delete_details',
                            defaultMessage: 'Delete Details',
                          })}
                          validationErrors={deleteValidationErrors}
                        />
                      )}
                      <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle
                          tag="button"
                          className="more-dropdown-button btn btn-sm"
                          role="button"
                        >
                          <i className="fe fe-more-horizontal"></i>
                        </DropdownToggle>
                        <DropdownMenu end>
                          {propsOnEdit && (
                            <DropdownItem onClick={propsOnEdit}>
                              <FormattedMessage
                                id="app.views.activities.contribution_comment.dropdown_edit.edit_details"
                                defaultMessage="
                              Edit details
                            "
                              />
                            </DropdownItem>
                          )}
                          {propsOnDelete && (
                            <DropdownItem onClick={toggleConfirmDeleteModal}>
                              <FormattedMessage
                                id="app.views.activities.contribution_comment.dropdown_edit.delete_details"
                                defaultMessage="
                              Delete details
                            "
                              />
                            </DropdownItem>
                          )}
                          <DropdownItem onClick={() => IntercomAPI('show')}>
                            <FormattedMessage
                              id="app.views.activities.contribution_comment.dropdown_edit.report_problem"
                              defaultMessage="
                            Report a problem
                          "
                            />
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  )}
                </Row>
                {lockedMessage && (
                  <div
                    className="blurred-content-overlay"
                    onClick={lockedMessageOnClick}
                    role="button"
                  >
                    <div>
                      <span className="fe fe-lock mb-1 d-block" />
                      {lockedMessage}
                    </div>
                  </div>
                )}
                <div
                  className={
                    'mb-0 py-1' + (lockedMessage ? ' blurred-content' : '')
                  }
                >
                  {lockedMessage &&
                    getRandomCannedBlurredText(comment.description?.length)}
                  {!lockedMessage && (
                    <RichTextViewer
                      model={comment.description}
                      expanded={true}
                    />
                  )}
                </div>
                {comment.skills?.length > 0 && (
                  <TagsList
                    className={lockedMessage ? 'blurred-content' : undefined}
                    skills={
                      lockedMessage
                        ? getRandomCannedBlurredSkills(comment.skills.length)
                        : comment.skills
                    }
                    isExternalUrl={isExternalUrl}
                    truncated={true}
                  />
                )}
              </div>
            </Col>
          </Row>
          {!lockedMessage && !isExternalUrl && (
            <Row>
              <Col>
                <EmojiBar
                  isSmall={true}
                  className="ms-2"
                  objectId={comment?.id?.toString()}
                  contentType="contributioncomment"
                  reactions={comment?.reactions}
                  showCommentModal={onEdit}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(ContributionCommentBody);
