import { EnpsSummaryAnswer, EnpsSummaryTheme } from './types';
import { List, Modal, ModalBody, ModalHeader } from 'reactstrap';
import React, { useMemo, useState } from 'react';

import ExportButton from '../../../../components/ExportButton/ExportButton';
import SimpleCard from '../../Cards/SimpleCard';
import { createCSVFileName } from '../../../../utils/util/util';
import { getIntlStrings } from './intl';
import { useIntl } from 'react-intl';

interface Props {
  theme: EnpsSummaryTheme | null;
  isOpen: boolean;
  toggle: () => void;
  comments: EnpsSummaryAnswer[];
}

const EnpsThemeModal: React.FC<Props> = ({
  theme,
  isOpen,
  toggle,
  comments,
}) => {
  const { formatMessage } = useIntl();
  const intl = getIntlStrings(formatMessage);
  const [showRelatedComments, setShowRelatedComments] =
    useState<boolean>(false);

  const localToggle = () => {
    setShowRelatedComments(false);
    toggle();
  };

  const relatedComments = useMemo(() => {
    if (!theme?.relatedComments || theme.relatedComments.length === 0) {
      return <></>;
    }

    if (!showRelatedComments) {
      // show a link that, upon clicking will display related comments
      return (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowRelatedComments(true);
          }}
        >
          {intl.ShowRelatedComments}
        </a>
      );
    }

    return (
      <SimpleCard title={intl.RelatedComments}>
        <List tag="ul">
          {theme?.relatedComments.map((index) => (
            <li key={index}>{comments?.[index]?.comment}</li>
          ))}
        </List>
      </SimpleCard>
    );
  }, [
    comments,
    showRelatedComments,
    theme?.relatedComments,
    intl.ShowRelatedComments,
    intl.RelatedComments,
  ]);

  const csvFilename = createCSVFileName('enps_theme_details');
  const csvData: string[][] = useMemo(() => {
    const rows: string[][] = [];

    rows.push([intl.Theme, theme?.title ?? '']);
    rows.push([intl.Summary, theme?.summary ?? '']);
    rows.push([intl.Description, theme?.observations ?? '']);
    theme?.examples.forEach((example) => {
      rows.push([intl.TopQuote, example.quote]);
    });
    theme?.relatedComments.forEach((index) => {
      rows.push([intl.RelatedComments, comments[index]?.comment ?? '']);
    });

    return rows;
  }, [
    comments,
    intl.Description,
    intl.TopQuote,
    intl.RelatedComments,
    intl.Summary,
    intl.Theme,
    theme?.examples,
    theme?.observations,
    theme?.relatedComments,
    theme?.summary,
    theme?.title,
  ]);

  if (!theme) {
    return null;
  }
  return (
    <Modal isOpen={isOpen} toggle={localToggle} size="lg">
      <ModalHeader toggle={localToggle}>
        <div className="align-items-center justify-content-between d-flex">
          {intl.ThemeDetails}
          <ExportButton filename={csvFilename} data={csvData} />
        </div>
      </ModalHeader>
      <ModalBody>
        <SimpleCard title={intl.Theme}>{theme.title}</SimpleCard>
        <SimpleCard title={intl.Summary}>{theme.summary}</SimpleCard>
        <SimpleCard title={intl.Description}>{theme.observations}</SimpleCard>
        <SimpleCard title={intl.TopQuotes}>
          <List tag="ul">
            {theme.examples.map((example) => (
              <li key={example.index}>{example.quote}</li>
            ))}
          </List>
        </SimpleCard>
        {relatedComments}
      </ModalBody>
    </Modal>
  );
};

export default React.memo(EnpsThemeModal);
