import React, { FC } from 'react';

import { Progress } from 'reactstrap';

type CompletionBar = {
  progress: number;
  total: number;
  width?: number;
  extraText?: string;
  maxPercent?: number;
};

const CompletionBar: FC<CompletionBar> = ({
  progress,
  total,
  width,
  extraText,
  maxPercent = 100,
}) => {
  const safeTotal = total || 100; // Avoid division by zero
  const currentProgress = Math.min(
    maxPercent,
    Math.round((progress / safeTotal) * 100)
  );
  const currentRemaining = Math.max(
    0,
    Math.round(((safeTotal - progress) / safeTotal) * 100)
  );
  return (
    <>
      <div style={{ height: 20, width }}>
        <Progress multi>
          <Progress
            bar
            striped={true}
            animated={true}
            color="primary"
            value={currentProgress}
          />
          <Progress
            bar
            color="secondary"
            style={{ opacity: 0.7 }}
            value={currentRemaining}
          />
        </Progress>
      </div>
      <div
        className="text-center"
        style={{
          zIndex: 1000,
          color: 'grey',
          marginTop: '-24px',
          width,
          position: 'absolute',
        }}
      >
        <span className="bold" style={{ color: 'white', fontSize: '16px' }}>
          {currentProgress + '%'}
        </span>
      </div>
      {extraText && (
        <div className="text-muted small text-center float-end ms-2 mt-2">
          {extraText}
        </div>
      )}
    </>
  );
};

export default CompletionBar;
