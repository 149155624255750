import * as consts from '../../consts/consts';

import React, { FC } from 'react';

import OpportunitiesDashboard from './OpportunitiesDashboard';
import OpportunityIdeas from './OpportunityIdeas';
import OpportunityMentorships from './OpportunityMentorships';
import OpportunityProblems from './OpportunityProblems';
import Page from '../Layout/Pages/Page';
import { useIntl } from 'react-intl';

const OpportunitiesPage: FC = () => {
  const { formatMessage } = useIntl();
  const tabs: object[] = [];

  tabs.push({
    ...consts.OPPORTUNITIES_DASHBOARD(formatMessage),
    content: <OpportunitiesDashboard />,
  });

  tabs.push({
    ...consts.OPPORTUNITY_PROBLEMS(formatMessage),
    content: <OpportunityProblems />,
  });

  tabs.push({
    ...consts.OPPORTUNITY_IDEAS(formatMessage),
    content: <OpportunityIdeas />,
  });

  tabs.push({
    ...consts.OPPORTUNITY_MENTORSHIPS(formatMessage),
    content: <OpportunityMentorships />,
  });

  return (
    <Page
      title={formatMessage({
        id: 'app.views.opportunities.opportunities_page.title.opportunities',
        defaultMessage: 'Opportunities',
      })}
      tabs={tabs}
    >
      <></>
    </Page>
  );
};

export default React.memo(OpportunitiesPage);
