import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import EmptyState from '../Widgets/EmptyState';

const OpportunityMentorships: FC = () => {
  const { formatMessage } = useIntl();

  const mentorshipOpportunities = [];

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.opportunities.opportunity_mentorships.mentorships"
            defaultMessage="Mentorships"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {mentorshipOpportunities?.length === 0 && (
          <EmptyState
            title={formatMessage({
              id: 'app.views.opportunities.opportunity_mentorships.epty_state.title',
              defaultMessage: 'No mentorships',
            })}
            subtitle={formatMessage({
              id: 'app.views.opportunities.opportunity_mentorships.epty_state.subtitle',
              defaultMessage:
                'There are no mentorship opportunities currently available.',
            })}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default React.memo(OpportunityMentorships);
