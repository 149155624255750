import { Input, UncontrolledPopover } from 'reactstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// convert LIKERT_SCALE to array of objects where each item has a name and value
// and name = text with value = key value
// NOTE: we need to define this function here instead of Performance.js because
// if we do that, it will be undefined when used in ValidatedInput.js due to
// circular dependencies
export const LIKERT_SCALE_AS_ARRAY = (formatMessage) => [
  {
    name: formatMessage({
      id: 'app.views.widgets.inputs.likert_scale.values.strongly_disagree',
      defaultMessage: 'Strongly disagree',
    }),
    value: 1,
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.inputs.likert_scale.values.disagree',
      defaultMessage: 'Disagree',
    }),
    value: 2,
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.inputs.likert_scale.values.neither_agree_nor_disagree',
      defaultMessage: 'Neither agree nor disagree',
    }),
    value: 3,
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.inputs.likert_scale.values.agree',
      defaultMessage: 'Agree',
    }),
    value: 4,
  },
  {
    name: formatMessage({
      id: 'app.views.widgets.inputs.likert_scale.values.strongly_agree',
      defaultMessage: 'Strongly agree',
    }),
    value: 5,
  },
];

const LikertScaleInput = (props) => {
  const [validationMessage, setValidationMessage] = useState(null);

  const { formatMessage } = useIntl();
  const propsOnChange = props.onChange;
  const propsOnValidChange = props.onValidChange;
  const propsRequired = props.required;
  const incomingValue = props.value;

  // if there are multiple values, we return a dict of values
  // but if there's exactly one object, we just use the integer
  // value (i.e. 1-5) on the Likert scale
  const hasMultipleValues = props.objects?.length > 1;

  useEffect(() => {
    if (propsRequired) {
      // for a likert value to be valid for a required question,
      // all values must be filled in; if not filled out,
      // incomingValue is {} even with length = 1, but when
      // filled out and length = 1, value is an integer
      const isValid =
        Object.values(incomingValue)?.length === props.objects?.length ||
        (props.objects?.length === 1 && Number.isInteger(incomingValue));

      setValidationMessage(isValid ? null : 'Please select an answer.');
    } else {
      setValidationMessage(null);
    }
  }, [hasMultipleValues, incomingValue, props.objects?.length, propsRequired]);

  useEffect(() => {
    if (propsOnValidChange) {
      propsOnValidChange(validationMessage);
    }
  }, [validationMessage, propsOnValidChange]);

  const onChange = useCallback(
    (e) => {
      if (propsOnChange) {
        // strip props.name + '_' from e.target.name
        const targetName = e.target.name.substring(props.name.length + 1);

        const updatedValue = hasMultipleValues
          ? {
              ...props.value,
              [targetName]: parseInt(e.target.value),
            }
          : parseInt(e.target.value);
        propsOnChange(updatedValue);
      }
    },
    [hasMultipleValues, props.name.length, props.value, propsOnChange]
  );

  const likertScale = useMemo(
    () => props.scale ?? LIKERT_SCALE_AS_ARRAY(formatMessage),
    [props.scale, formatMessage]
  );

  return (
    <div className={props.className} role={props.role} style={props.style}>
      <table className="table table-sm text-center mb-0">
        <thead>
          <tr>
            <th scope="col" style={{ width: '25%' }} className="px-0"></th>
            {likertScale.map((item, i) => (
              <th key={i} scope="col" style={{ width: '15%' }} className="px-0">
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.objects?.map((object, index) => (
            <tr key={index}>
              <th scope="row" className="ps-0 text-start">
                <div>
                  {object.value}
                  {object.helperText && (
                    <>
                      <i
                        id={'option-' + object.name + '-' + index}
                        className="ms-2 fe fe-help-circle text-primary"
                      />
                      <UncontrolledPopover
                        placement="top"
                        trigger="hover"
                        target={'option-' + object.name + '-' + index}
                      >
                        {object.helperText}
                      </UncontrolledPopover>
                    </>
                  )}
                </div>
              </th>
              {[1, 2, 3, 4, 5].map((num, numIndex) => (
                <td className="px-0" key={numIndex}>
                  <Input
                    className="m-0 position-relative"
                    role="button"
                    type="radio"
                    // need props.name in case multiple likerts appear on same page
                    // to avoid their button clicks competing with each other
                    name={props.name + '_' + object.name}
                    value={num}
                    checked={
                      props.value &&
                      ((hasMultipleValues &&
                        props.value[object.name] === num) ||
                        (!hasMultipleValues && props.value === num))
                    }
                    required={props.required}
                    onChange={onChange}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

LikertScaleInput.defaultProps = {
  checkedValue: true,
  uncheckedValue: false,
};

LikertScaleInput.propTypes = {
  scale: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  style: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default React.memo(LikertScaleInput);
