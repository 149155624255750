export const ORG_STATUSES = {
  IGNORE: 'I',
  DEMO: 'D',
  LEAD: 'L',
  PROSPECT: 'P',
  ONBOARDING: 'O',
  ACTIVE: 'A',
  PAUSED: 'U',
  SUSPENDED: 'S',
  CHURNED: 'C',
};

export const ORGANIZATION_SETTINGS_USER_LISTS = [
  'system_admins',
  'admins',
  'influence_admins',
  'rating_dashboard_admins',
  'engagement_dashboard_admins',
];

export const orgIsActiveOrDemo = (organization) => {
  return (
    organization.status === ORG_STATUSES.ACTIVE ||
    organization.status === ORG_STATUSES.DEMO
  );
};

export const orgIsChurned = (organization) => {
  return organization.status === ORG_STATUSES.CHURNED;
};

/**
 * CUSTOM SESSION TTL
 * This code deals with setup needed for Organizations
 * that desire a shorter session life for their employees.
 *
 * Currently we have API access tokens that last 24 hours in
 * Auth0, id tokens are set to 8 hours (that's a global setting).
 *
 * Auth0 JWT tokens cannot be revoked so the only option we have is
 * to force re-auth (this is Auth0 CS team's recommendation).
 * https://auth0.com/docs/authenticate/login/max-age-reauthentication
 *
 * To be able to logout a user, we need the token to carry an extra claim:
 * auth_time. This claim can be requested by passing `max_age` and `prompt`
 * params when initializing Auth0Provider (but note: only the first invocation
 * and the user going through the login flow will set the claims, not any
 * app in-session re-renders).
 *
 * To have the claim in the token we need to set max_age in the app
 * for ALL customers, since we can only differentiate users *after* they login
 * and we have their Auth0 user email available.
 * We need the claim to be present at app bootstrap because it's used in the logic
 * for axios API interceptors (which only run once, if the app is not initialized).
 *
 * Similarly we can't use Organization.email_domains as that data comes in
 * much later in the session.
 */

// This must match the same key in admin/api/models.py
const ORGANIZATION_SETTINGS_CUSTOM_SESSION_TTL = 'custom_session_ttl';
// This must match the same default in admin/api/serializers.py
// Exported for testing, shall not be used directly in the app.
export const DEFAULT_NO_VALUE_CUSTOM_SESSION_TTL = -1;

export const getCustomSessionTTL = (organization) => {
  if (!organization) {
    console.error('getCustomSessionTTL: organization is undefined');
    return DEFAULT_NO_VALUE_CUSTOM_SESSION_TTL;
  }

  if (!(ORGANIZATION_SETTINGS_CUSTOM_SESSION_TTL in organization)) {
    return DEFAULT_NO_VALUE_CUSTOM_SESSION_TTL;
  }
  return organization[ORGANIZATION_SETTINGS_CUSTOM_SESSION_TTL];
};

export const hasCustomSessionTTL = (organization) => {
  return (
    getCustomSessionTTL(organization) !== DEFAULT_NO_VALUE_CUSTOM_SESSION_TTL
  );
};

// END CUSTOM SESSION TTL
