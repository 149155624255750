import React, { PropsWithChildren } from 'react';

interface CardHeaderTitleProps {
  icon?: string;
  className?: string;
}

const CardHeaderTitle: React.FC<PropsWithChildren<CardHeaderTitleProps>> = ({
  icon,
  className,
  children,
}) => {
  return (
    <h4
      className={'card-header-title' + (className ? ' ' + className : '')}
      role="heading"
    >
      {icon && (
        <i
          className={icon + ' me-2'}
          style={{ position: 'relative', top: '1px' }}
        />
      )}
      {children}
    </h4>
  );
};

export default React.memo(CardHeaderTitle);
