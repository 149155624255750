import * as consts from 'consts/consts';

import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import { Button } from 'reactstrap';
// @ts-expect-error
import { IntercomAPI } from 'vendor/react-intercom';
import { ReduxState } from 'types';
import SimplePage from '../SimplePage';
import { connect } from 'react-redux';
import { logOutAndCleanUp } from 'utils/models/User';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  me: ReduxState['me'];
}

const PageNoOrganization: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const { logout } = useAuth0();

  return (
    <SimplePage
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page_no_organization.title.no_organization_found',
        defaultMessage: 'No organization found',
      })}
    >
      <p>
        <FormattedMessage
          id="app.views.layout.pages.errors.page_no_organization.email_not_associated"
          defaultMessage="The email <bold>{email}</bold> is not
           associated with an organization. If you meant to log in with a different
           email address, <logout>log out</logout> and log back in with the correct email address."
          values={{
            bold: (chunks) => <span className="fw-bold">{chunks}</span>,
            logout: (chunks) => (
              <span
                className="btn-link"
                role="button"
                onClick={() => logOutAndCleanUp(logout)}
              >
                {chunks}
              </span>
            ),
            email: props.me?.email,
          }}
        />
        <span className="fw-bold">{props.me?.email}</span>
      </p>
      <p>
        <FormattedMessage
          id="app.views.layout.pages.errors.page_no_organization.request_demo_description"
          defaultMessage="
        If you are not a current customer but would like a demo of our platform:
      "
        />
      </p>
      <div className="mb-4">
        <a href={consts.CONFIRM_DEMO_FULL_PATH}>
          <Button color="primary">
            <FormattedMessage
              id="app.views.layout.pages.errors.page_no_organization.request_demo_button"
              defaultMessage="Request a demo"
            />
          </Button>
        </a>
      </div>
      <p className="text-muted mb-4">
        <FormattedMessage
          id="app.views.layout.pages.errors.page_no_organization.contact_customer_support"
          defaultMessage="
        Not what you expected? <intercom>Contact Customer Support</intercom>."
          values={{
            intercom: (chunks) => (
              <span
                className="text-primary"
                role="button"
                onClick={() => IntercomAPI('show')}
              >
                {chunks}
              </span>
            ),
          }}
        />
      </p>
    </SimplePage>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { me } = state;

  return {
    me,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(PageNoOrganization));
