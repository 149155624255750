import React from 'react';
import { Button } from 'reactstrap';
import { QuestionType } from './QuestionEditorCommon';
import { FormattedMessage, useIntl } from 'react-intl';

interface QuestionEditorModalTitleProps {
  modalTitle: string;
  questionType?: QuestionType | null;
  hasQuestion: boolean;
  onBackClick: () => void;
}

const QuestionEditorModalTitle: React.FC<QuestionEditorModalTitleProps> = ({
  modalTitle,
  questionType,
  hasQuestion,
  onBackClick,
}) => {
  const intl = useIntl();

  return questionType && !hasQuestion ? (
    <>
      <Button
        className="btn-sm me-2 btn-rounded-circle bg-primary-soft text-primary border-0"
        color="light"
        style={{ width: '1.5rem', height: '1.5rem' }}
        onClick={onBackClick}
        role="button"
        aria-label={intl.formatMessage({
          id: 'app.views.activities.widgets.inputs.question_editor_modal_title.back',
          defaultMessage: 'Back',
        })}
      >
        <span className="fe fe-chevron-left" />
      </Button>{' '}
      {questionType.name === 'custom' ? (
        modalTitle
      ) : (
        <FormattedMessage
          id="app.views.activities.widgets.inputs.question_editor_modal_title.header"
          defaultMessage="Create {questionType} question"
          values={{ questionType: questionType.heading }}
        />
      )}
    </>
  ) : (
    <>{modalTitle}</>
  );
};

export default React.memo(QuestionEditorModalTitle);
