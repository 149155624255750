import { useEffect, useState } from 'react';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import { useHistory } from 'react-router-dom';

const VERSION_CHECK_INTERVAL = 1000 * 60 * 60 * 1; // one hour
const REFRESH_GRACE_PERIOD = 1000 * 60 * 30; // 30 minutes

const needsRefresh = (lastRefreshTime: Date | undefined) => {
  const now: Date = new Date();
  const difference = now.getTime() - (lastRefreshTime ?? now).getTime();
  return difference > REFRESH_GRACE_PERIOD;
};

const FRONTEND_VERSION = process.env.REACT_APP_APPLICATION_RELEASE ?? '';

export const ReloadAppOnNewVersion = () => {
  const history = useHistory();

  const [appVersion, setAppVersion] = useState<string>(FRONTEND_VERSION);
  const [lastRefreshTime] = useState<Date>(new Date());

  console.log('RELOADER:', appVersion, FRONTEND_VERSION, lastRefreshTime);

  useEffect(() => {
    const interval = setInterval(() => {
      ConfirmAPI.sendRequestToConfirm('GET', '/version', {}, (data, error) => {
        if (!error && data.version) {
          setAppVersion(data.version);
        }
      });
    }, VERSION_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [history]);

  useEffect(() => {
    if (appVersion != FRONTEND_VERSION) {
      return history.listen((location, action) => {
        if (action === 'PUSH' && needsRefresh(lastRefreshTime)) {
          window.location.reload();
        }
      });
    }
  }, [history, appVersion, lastRefreshTime]);

  return null;
};
