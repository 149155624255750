import { isEqual } from 'lodash';

export const GOAL_VISIBILITY_EVERYONE = (formatMessage) => ({
  id: 'E',
  name: formatMessage({
    id: 'app.utils.models.goal_visibility.everyone',
    defaultMessage: 'Visible to others',
  }),
  icon: 'fe fe-unlock',
});

export const GOAL_VISIBILITY_MANAGER = (formatMessage) => ({
  id: 'M',
  name: formatMessage({
    id: 'app.utils.models.goal_visibility.manager_and_above_only',
    defaultMessage: 'Just my manager and above',
  }),
  icon: 'fe fe-lock',
});

export const GOAL_VISIBILITIES = (formatMessage) => [
  GOAL_VISIBILITY_EVERYONE(formatMessage),
  GOAL_VISIBILITY_MANAGER(formatMessage),
];

// allow for up to 10 possible supporter suggestions
export const MAX_SUGGESTED_SUPPORTERS = 10;

export const getRecommendationSearchFilters = (goalOrAspiration) => {
  if (!goalOrAspiration) {
    return null;
  }

  // require at least one skill or credential
  if (
    !(goalOrAspiration.skills?.length > 0) &&
    !(goalOrAspiration.credentials?.length > 0)
  ) {
    return null;
  }

  return {
    skills: goalOrAspiration?.skills
      ? goalOrAspiration.skills.map((s) => s.id)
      : [],
    credentials: goalOrAspiration?.credentials
      ? goalOrAspiration.credentials.map((s) => s.id)
      : [],
  };
};

export const getRecommendationSearchTerms = (goalOrAspiration) => {
  if (!goalOrAspiration) {
    return null;
  }

  const skills = goalOrAspiration?.skills ? goalOrAspiration.skills : [];
  const credentials = goalOrAspiration?.credentials
    ? goalOrAspiration.credentials
    : [];

  // require at least one skill or credential
  if (!(skills.length > 0) && !(credentials.length > 0)) {
    return null;
  }

  // get list of terms a la "A" "B" "C" to pass in as a single string
  return [...skills, ...credentials]
    .map((s) => s.name.replace('"', ''))
    .join(' ');
};

export const aspirationsAreEqual = (a1, a2) => {
  if (a1.id !== a2.id) {
    return false;
  }
  if (a1.name !== a2.name) {
    return false;
  }
  if (a1.description !== a2.description) {
    return false;
  }
  if (!isEqual(a1.skills, a2.skills)) {
    return false;
  }
  if (!isEqual(a1.credentials, a2.credentials)) {
    return false;
  }
  if (!isEqual(a1.supporter_people, a2.supporter_people)) {
    return false;
  }
  if (!goalsAreEqual(a1.goals, a2.goals)) {
    return false;
  }

  return true;
};

export const goalsAreEqual = (g1, g2) => {
  if (!g1?.id && !g2?.id) {
    return true;
  }

  if (g1?.id !== g2?.id) {
    return false;
  }
  if (g1.name !== g2.name) {
    return false;
  }
  if (g1.description !== g2.description) {
    return false;
  }
  if (g1.targeted_at !== g2.targeted_at) {
    return false;
  }
  if (g1.completed_at !== g2.completed_at) {
    return false;
  }
  if (!isEqual(g1.aspirations, g2.aspirations)) {
    return false;
  }
  if (!isEqual(g1.skills, g2.skills)) {
    return false;
  }
  if (!isEqual(g1.credentials, g2.credentials)) {
    return false;
  }
  if (!isEqual(g1.supporter_people, g2.supporter_people)) {
    return false;
  }

  return true;
};
