import { ListGroupItem } from 'reactstrap';
import React from 'react';

const ObjectiveItemIcon = (props) => {
  const hover = props.hasChildren
    ? 'objective-list--chevron__hover text-body'
    : '';
  const indent = `objective-list--chevron__${props.indent}`;
  const icon = props.showChildren ? 'fe-chevron-down' : 'fe-chevron-right';

  return (
    <i
      className={`objective-list--chevron ${hover} ${indent} fe ${icon} fw-bold fs-3 me-2 position-relative`}
      style={{ top: '2px' }}
      onClick={props.onClick}
    />
  );
};

export const ObjectiveItemTitle = (props) => {
  return (
    <div className="d-flex align-items-baseline flex-grow-1 position-relative">
      <ObjectiveItemIcon
        showChildren={props.showChildren}
        onClick={props.onClick}
        indent={props.indent}
        hasChildren={props.hasChildren}
      />
      <h4 className="mb-0 w-100">{props.children}</h4>
    </div>
  );
};
export const ObjectiveItemControls = (props) => {
  return <div className="d-flex gap-3 mb-2">{props.children}</div>;
};
export const ObjectiveItemBody = (props) => {
  return (
    <div className="d-flex gap-5 align-items-baseline justify-content-between">
      {props.children}
    </div>
  );
};
export const ObjectiveItem = (props) => {
  const indentClassName = props.indent > 0 ? `ps-${4 + props.indent}` : '';

  return (
    <ListGroupItem
      hidden={props.hidden}
      color="white"
      className={`objective-list--item pt-0 pb-2 ${indentClassName}`}
      {...props}
    >
      {props.children}
    </ListGroupItem>
  );
};
export const ObjectiveItemHead = (props) => {
  return (
    <div className="mt-2 mb-1" style={{ maxHeight: '21px' }}>
      {props.children}
    </div>
  );
};
