import { Col, Input, Label, UncontrolledPopover } from 'reactstrap';
import React, { FC, useCallback } from 'react';

import { FormattedMessage } from 'react-intl';
import { ICONS } from 'consts/consts';

interface Props {
  value: boolean;
  onClick: (value: boolean) => void;
}

const TerminatedToggle: FC<Props> = ({ value, onClick }) => {
  const handleOnClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <>
      <Col className="col-auto d-flex align-items-center pe-0">
        <div
          id="show-terminated-people"
          className="form-check form-switch"
          onClick={handleOnClick}
        >
          <Input
            name="terminated-switch"
            type="checkbox"
            checked={value}
            className={'form-check-input'}
            readOnly
          />
          <Label
            className="form-check-label mb-0"
            htmlFor="terminated-switch"
            role="button"
          >
            <i className={ICONS.TERMINATED} />
          </Label>
        </div>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={'show-terminated-people'}
        >
          {value && (
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.click_to_hide_terminated"
              defaultMessage="Click to hide people that are terminated."
            />
          )}
          {!value && (
            <FormattedMessage
              id="app.views.widgets.dashboards.team_dashboard.click_to_show_terminated"
              defaultMessage="Click to show people that are terminated."
            />
          )}
        </UncontrolledPopover>
      </Col>
    </>
  );
};

export default TerminatedToggle;
