import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useCallback, useState } from 'react';

import BackGroundTaskModal from 'components/BackgroundTaskModal/BackGroundTaskModal';
import { Campaign } from '../../types';
import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import { isEngagementSurveyOnly } from '../../utils/models/Campaign';
import { toast } from 'react-toastify';

interface Props {
  campaign: Campaign;
}

const CampaignReleaseReports: FC<Props> = ({ campaign }) => {
  const { formatMessage } = useIntl();

  const [modalOpen, setModalOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalToggle = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleOnComplete = useCallback(
    (data) => {
      setModalOpen(false);
      if (data) {
        if (data?.length === 0) {
          toast.info(
            formatMessage({
              id: 'app.views.administration.campaign_release_reports.toast.info.all_reports_released',
              defaultMessage: 'All reports have already been released.',
            })
          );
        } else {
          toast.success(
            formatMessage(
              {
                id: 'app.views.administration.campaign_release_reports.toast.success.reports_released',
                defaultMessage: '{count} reports released successfully!',
              },
              { count: data?.length ?? 0 }
            )
          );
        }
      }
    },
    [formatMessage]
  );

  if (isEngagementSurveyOnly(campaign)) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle>
          <FormattedMessage
            id="app.views.administration.campaign_release_reports.card.header.remaining_reports"
            defaultMessage="Release remaining performance reports"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        <p>
          <FormattedMessage
            id="app.views.administration.campaign_release_reports.card.body.paragraph.realease_all"
            defaultMessage="
          For any managers who have not yet released their reports, you can
          choose to release all of the remaining unreleased reports.
        "
          />
        </p>
        <Button color="light" className="btn-sm" onClick={handleButtonClick}>
          {formatMessage({
            id: 'app.views.administration.campaign_release_reports.submit_text.release_remaining_performance_reports',
            defaultMessage: 'Release remaining performance reports',
          })}
        </Button>
        <BackGroundTaskModal
          title={'Release remaining performance reports'}
          isOpen={modalOpen}
          onToggle={handleModalToggle}
          onComplete={handleOnComplete}
          requestConfig={{
            method: 'POST',
            url: '/performance/release-remaining-reports-async',
            params: {
              campaign: campaign.id,
            },
          }}
          submitText={
            <FormattedMessage
              id="app.views.administration.campaign_release_report.release_reports_action_button"
              defaultMessage="Release all"
            />
          }
        >
          <>
            <p>
              <FormattedMessage
                id="app.views.administration.campaign_release_reports.title.release_remaining_performance_reports_warning"
                defaultMessage="You will not be able to edit your feedback after these reports are released."
              />
            </p>
            <p>
              <FormattedMessage
                id="app.views.administration.campaign_release_reports.paragraph.are_you_sure_release"
                defaultMessage="Are you sure you want to release all remaining reports?"
              />
            </p>
          </>
        </BackGroundTaskModal>
      </CardBody>
    </Card>
  );
};

export default React.memo(CampaignReleaseReports);
