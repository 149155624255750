import { FormattedMessage, useIntl } from 'react-intl';
import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import SimplePage from '../SimplePage';

const Page404: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <SimplePage
      title={formatMessage({
        id: 'app.views.layout.pages.errors.page404.title.not_found',
        defaultMessage: 'Not Found',
      })}
      titleClassName="text-center"
    >
      <div className="text-center">
        <p>
          <FormattedMessage
            id="app.views.layout.pages.errors.page404.go_back_link"
            defaultMessage="Feel free to go back to <link>your dashboard</link>."
            values={{
              link: (chunks) => <Link to="/">{chunks}</Link>,
            }}
          />
        </p>
        <p className="text-muted mb-4">
          <FormattedMessage
            id="app.views.layout.pages.errors.page404.contact_customer_support"
            defaultMessage="
          Not what you expected? Contact Customer Support.
        "
          />
        </p>
      </div>
    </SimplePage>
  );
};

export default React.memo(Page404);
