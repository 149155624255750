import * as React from 'react';

import { CampaignFlowContextType } from '../../types';
import { useObjectivesSettings } from '../Objectives/ObjectivesTimeFrameSelector/hooks';

const CampaignFlowContext = React.createContext<CampaignFlowContextType | null>(
  null
);

const CampaignFlowProvider = ({ initialValue, children }) => {
  const { objectivesTimeFrameType, objectivesTimeframeDefaultStartDateMMDD } =
    useObjectivesSettings();

  const [phaseSelfStartDate, setPhaseSelfStartDate] = React.useState<string>(
    initialValue.phaseSelfStartDate
  );

  const value: CampaignFlowContextType = React.useMemo(
    () => ({
      phaseSelfStartDate,
      setPhaseSelfStartDate,
      phaseSelfSetObjectivesLegacy: initialValue.phaseSelfSetObjectivesLegacy, // we don't need to store this since it's a legacy and won't change
      objectivesOrganizationTimeFrameType: objectivesTimeFrameType,
      objectivesOrganizationTimeFrameDefaultStartDateMMDD:
        objectivesTimeframeDefaultStartDateMMDD,
    }),
    [
      phaseSelfStartDate,
      initialValue.phaseSelfSetObjectivesLegacy,
      objectivesTimeFrameType,
      objectivesTimeframeDefaultStartDateMMDD,
    ]
  );

  return (
    <CampaignFlowContext.Provider value={value}>
      {children}
    </CampaignFlowContext.Provider>
  );
};

const useCampaignFlow = (): CampaignFlowContextType | null => {
  const context = React.useContext(CampaignFlowContext);
  if (context === undefined) {
    throw new Error(
      'useCampaignFlow must be used within a CampaignFlowProvider'
    );
  }
  return context;
};

export { CampaignFlowProvider, useCampaignFlow };
