import { ListGroup, ListGroupItem } from 'reactstrap';
import React, { FC, useCallback, useState } from 'react';

import ConfirmAPI from '../../utils/api/ConfirmAPI';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import SkillActionButtons from './SkillActionButtons';
import SkillCard from '../Widgets/Cards/SkillCard';
import { connect } from 'react-redux';
import { setMe } from '../../actions';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const SkillList: FC<Props> = ({
  inModal = false,
  hideDescriptions = false,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [skillsToAdd, setSkillsToAdd] = useState([]);
  const [skillsToRemove, setSkillsToRemove] = useState([]);
  const person = props.me;
  const setMe = props.setMe;

  const addSkill = useCallback(
    (skill) => {
      // @ts-expect-error
      setSkillsToAdd([...skillsToAdd, skill]);
      ConfirmAPI.sendRequestToConfirm(
        'PATCH',
        'me',
        {
          // @ts-expect-error
          declared_skills: [...person.declared_skills, skill].map((s) => {
            // @ts-expect-error
            return { organization: props.currentOrganizationId, ...s };
          }),
        },
        (me, error, hardErrorMessage = null) => {
          setSkillsToAdd([]);
          if (error) {
            if (hardErrorMessage) {
              toast.error(hardErrorMessage);
            } else {
              toast.error(error);
            }
          } else {
            toast.success(
              formatMessage({
                id: 'app.views.skills.skill_list.toast.skill_added',
                defaultMessage: 'Skill added!',
              })
            );
            if (me) {
              // update global user
              setMe(me);
            }
          }
        }
      );
    },
    [
      // @ts-expect-error
      person.declared_skills,
      // @ts-expect-error
      props.currentOrganizationId,
      setMe,
      skillsToAdd,
      formatMessage,
    ]
  );

  const removeSkill = useCallback(
    (skill) => {
      // @ts-expect-error
      setSkillsToRemove([...skillsToRemove, skill]);
      ConfirmAPI.sendRequestToConfirm(
        'PATCH',
        'me',
        {
          // @ts-expect-error
          declared_skills: person.declared_skills
            .filter((s) => s.name?.toString() !== skill?.name.toString())
            .map((s) => {
              // @ts-expect-error
              return { organization: props.currentOrganizationId, ...s };
            }),
        },
        (me, error, hardErrorMessage = null) => {
          setSkillsToRemove([]);
          if (error) {
            if (hardErrorMessage) {
              toast.error(hardErrorMessage);
            } else {
              toast.error(error);
            }
          } else {
            toast.success(
              formatMessage({
                id: 'app.views.skills.skill_list.toast.skill_removed',
                defaultMessage: 'Skill removed!',
              })
            );
            if (me) {
              // update global user
              setMe(me);
            }
          }
        }
      );
    },
    [
      // @ts-expect-error
      person.declared_skills,
      // @ts-expect-error
      props.currentOrganizationId,
      setMe,
      skillsToRemove,
      formatMessage,
    ]
  );

  return (
    <ListGroup className="list-group-flush my-n3">
      {/* @ts-expect-error */}

      {props.skills.map((skill, index) => (
        <ListGroupItem key={index}>
          <SkillCard
            buttonColor="light" // for lists, too many primary buttons is a bit too dramatic
            // @ts-expect-error
            skill={skill}
            description={
              props.descriptionMap ? props.descriptionMap(skill) : null
            }
            bodyOnly
            hideDescription={hideDescriptions}
            inModal={inModal}
            linked={true}
            aside={
              <SkillActionButtons
                currentUserHasDeclaredSkill={
                  // @ts-expect-error
                  person.declared_skills &&
                  // @ts-expect-error
                  person.declared_skills.findIndex(
                    // @ts-expect-error
                    (s) => s.name.toString() === skill.name.toString()
                  ) !== -1
                }
                // @ts-expect-error
                skill={skill}
                skillsToAdd={skillsToAdd}
                skillsToRemove={skillsToRemove}
                addSkill={() => addSkill(skill)}
                removeSkill={() => removeSkill(skill)}
              />
            }
          />
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

const SkillList_propTypes = {
  hideDescriptions: PropTypes.bool,
  skills: PropTypes.arrayOf(PropTypes.object),
  descriptionMap: PropTypes.func,
  inModal: PropTypes.bool,
  me: PropTypes.object.isRequired,
  setMe: PropTypes.func.isRequired,
};

type Props = PropTypes.InferProps<typeof SkillList_propTypes>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, me } = state;

  return {
    currentOrganizationId: currentOrganization?.id,
    me,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMe: (me) => dispatch(setMe(me)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SkillList));
