import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { FilePond, registerPlugin } from 'react-filepond';
import React, { FC, useEffect, useState } from 'react';

import { AUTH0_PARAMS } from '../../../utils/api/AuthProvider';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { redirectLoginOptionsGenerator } from '../../../utils/util/utiltsx';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const imageUploadUrl =
  process.env.REACT_APP_CONFIRM_API_URL + '/files/activity-image';

interface Props {
  onUpload: (data: any) => void;
  callToAction?: string;
  currentOrganizationId?: number;
}

const MediaUploader: FC<Props> = ({
  callToAction = 'Add screenshot or video',
  ...props
}) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);
  const [files, setFiles] = useState([]);
  const location = useLocation();

  const currentOrgId = props.currentOrganizationId;

  const isSignup = location.pathname === '/signup';

  // update access token for file/image uploading as close to actually sending as possible
  // to ensure that if someone leaves and comes back after the token expires,
  // the save will work (NOTE: we update this whenever the model changes to ensure
  // this is ALWAYS up to date)
  useEffect(() => {
    (async () => {
      let token = null;
      try {
        console.debug('[mediauploader][call] getAccessTokenSilently');
        // @ts-expect-error
        token = await getAccessTokenSilently(AUTH0_PARAMS);
        console.debug('[mediauploader][response] getAccessTokenSilently');
      } catch (e) {
        console.debug(e);
        // @ts-expect-error
        if (e.error === 'login_required' || e.error === 'consent_required') {
          console.debug('[mediauploader][call] loginWithRedirect');
          // @ts-expect-error
          loginWithRedirect(redirectLoginOptionsGenerator(location, isSignup));
          console.debug('[mediauploader][response] loginWithRedirect');
        }
        throw e;
      }

      setAccessToken(token);
    })();
  }, [getAccessTokenSilently, location, loginWithRedirect, isSignup]);

  return (
    <div className="pb-4">
      {/* @ts-expect-error */}
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        allowReorder={true}
        maxFiles={9}
        server={{
          url: imageUploadUrl,
          process: {
            method: 'PUT',
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
            onerror: (response) => {
              const errorData = JSON.parse(response);
              toast.error(errorData.message);
            },
            ondata: (formData) => {
              // @ts-expect-error
              formData.append('organization', currentOrgId);
              return formData;
            },
            onload: (response) => {
              props.onUpload(JSON.parse(response));
            },
          },
        }}
        name="file"
        labelIdle={
          '<div class="fe fe-image fs-lg mb-2"></div><div class="row"><div class="col"><span class="filepond--label-action">' +
          callToAction +
          '</span></div></div>'
        }
      />
    </div>
  );
};

// These props come from the application's
// state when it is started
const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization } = state;

  return {
    currentOrganizationId: currentOrganization?.id,
  };
};

export default connect(mapStateToProps)(React.memo(MediaUploader));
