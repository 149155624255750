import {
  PROMO_PACKET_INITIAL_SUBMIT_INPUTS,
  getCurrentJobFamilyLabel,
  getEnabledInputsForOrganization,
  isPromoPacketManagementRoleInferredFromOptions,
  mapPromoPacketsCustomLevelOption,
  onValidatePromotionPacket,
  promoPacketsTransformObjectBeforeSubmit,
} from '../../../utils/models/PromotionPackets';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { INPUT_TYPES } from '../Inputs/ValidatedInputTypes';
import ModalEditorButton from './ModalEditorButton';
import PropTypes from 'prop-types';
import { ReduxState } from 'types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { withRichTextViewer } from '../Inputs/ValidatedInput';

const ModalPromotionPacketEditorButton: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const [object, setObject] = useState(undefined);

  const title = useMemo(() => {
    return (
      props.title ||
      formatMessage({
        id: 'app.views.widgets.modals.modal_promotion_packet_editor_button.title',
        defaultMessage: 'Create promotion packet',
      })
    );
  }, [props.title, formatMessage]);

  const levelIdInputOverrides = useMemo(
    () =>
      props.customLevelOptions
        ? {
            type: INPUT_TYPES.DROPDOWN,
            objects: props.customLevelOptions.map(
              mapPromoPacketsCustomLevelOption
            ),
          }
        : {},
    [props.customLevelOptions]
  );

  const templateOverride = useMemo(
    () => props.urlInputAttributes || {},
    [props.urlInputAttributes]
  );

  const isManagementRoleInferred = useMemo(() => {
    return (
      props.customLevelOptions &&
      isPromoPacketManagementRoleInferredFromOptions(props.customLevelOptions)
    );
  }, [props.customLevelOptions]);

  const onPromoPacketChange = useCallback((o) => {
    // needed to autopopulate job family when selected in the question below
    setObject(o);
  }, []);

  const candidateCurrentJobFamily = useMemo(() => {
    // @ts-expect-error
    return object?.candidate_person?.job_family;
    // @ts-expect-error
  }, [object?.candidate_person?.job_family]);

  const formatEditPacketInputs = useCallback(
    (inputs, isDraft, isAdmin) => {
      const filteredInputs = getEnabledInputsForOrganization(
        inputs,
        isDraft,
        isAdmin,
        // @ts-expect-error
        props.promoPacketOrgSettings
      );

      return (
        isManagementRoleInferred
          ? filteredInputs.filter(
              (i) => i.name !== 'configs_is_management_role'
            )
          : filteredInputs
      ).map((i) =>
        i.name === 'configs_new_level_id'
          ? {
              ...i,
              ...levelIdInputOverrides,
            }
          : i.name === 'configs_packet_url'
          ? withRichTextViewer({
              ...i,
              ...templateOverride,
            })
          : i.name === 'configs_is_job_family_change' &&
            candidateCurrentJobFamily
          ? {
              ...i,
              label: getCurrentJobFamilyLabel(candidateCurrentJobFamily),
            }
          : i
      );
    },
    [
      candidateCurrentJobFamily,
      isManagementRoleInferred,
      levelIdInputOverrides,
      props.promoPacketOrgSettings,
      templateOverride,
    ]
  );

  const inputs = useMemo(() => {
    return formatEditPacketInputs(
      PROMO_PACKET_INITIAL_SUBMIT_INPUTS(formatMessage),
      // new packets are considered drafts and not done by an admin,
      // even if the creator is an admin
      true,
      false
    );
  }, [formatEditPacketInputs, formatMessage]);

  const onValidate = useCallback(
    (obj) => {
      const errors = onValidatePromotionPacket(
        formatMessage,
        obj,
        isManagementRoleInferred,
        // @ts-expect-error
        props.promoPacketOrgSettings
      );

      // TODO: make "required" flag work for select input instead of this custom check
      if (!obj.candidate_person) {
        errors['candidate_person'] = formatMessage({
          id: 'app.views.widgets.modals.modal_promotion_packet_editor_button.candidate_person_required',
          defaultMessage: "Enter the candidate's name.",
        });
      }

      return errors;
    },
    [isManagementRoleInferred, props.promoPacketOrgSettings, formatMessage]
  );

  const output = useMemo(
    () => (
      <>
        <ModalEditorButton
          block
          url="packets"
          title={title}
          submitText={title}
          object={object}
          onChange={onPromoPacketChange}
          // when creating another promo packet, don't show anything
          // you selected before
          onClosed={() => setObject(undefined)}
          onValidate={onValidate}
          inputs={inputs}
          transformObjectBeforeSubmit={(p) =>
            promoPacketsTransformObjectBeforeSubmit(p, props.customLevelOptions)
          }
          callback={props.callback}
        />
      </>
    ),
    [
      inputs,
      object,
      onPromoPacketChange,
      onValidate,
      props.callback,
      props.customLevelOptions,
      title,
    ]
  );

  return output;
};

const ModalPromotionPacketEditorButton_propTypes = {
  promoPacketOrgSettings: PropTypes.object.isRequired,
  title: PropTypes.string,
  callback: PropTypes.func.isRequired,
  currentOrganization: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  currentProxyPerson: PropTypes.object,
  features: PropTypes.object.isRequired,
  customLevelOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  urlInputAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  disabledHoverText: PropTypes.string,
};

type Props = PropTypes.InferProps<
  typeof ModalPromotionPacketEditorButton_propTypes
>;

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson, me, features } = state;

  return {
    currentOrganization,
    currentProxyPerson,
    me,
    features,
  };
};

export default connect(mapStateToProps)(
  React.memo(ModalPromotionPacketEditorButton)
);
