import { Col, Container, Row } from 'reactstrap';
import React, { FC, PropsWithChildren } from 'react';

import noOrganizationImg from '../../../assets/img/illustrations/no-organization.png';

type Props = {
  image?: string;
  alt?: string;
  title: string;
  titleClassName?: string;
  hideImage?: boolean;
};

const SimplePage: FC<PropsWithChildren<Props>> = ({
  image = noOrganizationImg,
  alt = 'Abstract visual of people smiling and sharing ideas',
  title,
  titleClassName = '',
  children,
  hideImage = false,
}) => {
  return (
    <Container>
      <Row className="row align-items-center mt-6">
        {!hideImage && (
          <Col className="col-12 col-md-6 offset-xl-1 order-md-2 mb-5 mb-md-0">
            <div className="text-center">
              <img src={image} alt={alt} className="img-fluid" />
            </div>
          </Col>
        )}
        <Col className="col-12 col-md-6 col-xl-5 order-md-1 my-5">
          <div>
            <h1 className={'display-4 mb-3 ' + titleClassName}>{title}</h1>
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(SimplePage);
