export const fetchTestData = (type) => {
  // Note: Cannot use variables (only string literals) for React imports:
  // https://stackoverflow.com/questions/56868349/react-dynamic-import-does-not-accept-string-variable
  if (type === 'HourlyApps') {
    return import('../../hourlyapps_clean_data.json');
  }
  if (type === 'MinuteApps') {
    return import('../../minuteapps_clean_data.json');
  }
  return import('../../dailyapps_data.json');
};
