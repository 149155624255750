import * as consts from '../../consts/consts';

import { Features, ReduxState, Tab } from '../../types';
import React, { ReactElement } from 'react';

import Page from '../Layout/Pages/Page';
import PeopleDirectory from './PeopleDirectory';
import PeopleOrgChart from './PeopleOrgChart';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

interface PeopleDirectoryPageProps {
  features: Features;
}

const getTabHeader = (name: string, icon: string): ReactElement => {
  return (
    <>
      <span className={'d-sm-none display-4 mx-1 ' + icon}></span>
      <span className="d-none d-sm-inline">{name}</span>
    </>
  );
};

const PeopleDirectoryPage: React.FC<PeopleDirectoryPageProps> = (props) => {
  const { formatMessage } = useIntl();

  const tabs: Tab[] = [];

  if (props.features?.people?.org_chart) {
    tabs.push({
      path: consts.PEOPLE_ORG_CHART(formatMessage).path,
      name: getTabHeader(
        consts.PEOPLE_ORG_CHART(formatMessage).name,
        consts.ICONS.ORG_CHART
      ),
      content: <PeopleOrgChart />,
    });
  }

  if (props.features?.people?.list_view) {
    tabs.push({
      path: consts.PEOPLE_DIRECTORY_LIST(formatMessage).path,
      name: getTabHeader(
        consts.PEOPLE_DIRECTORY_LIST(formatMessage).name,
        consts.ICONS.PEOPLE
      ),
      content: <PeopleDirectory />,
    });
  }

  return (
    <Page
      title={formatMessage({
        id: 'app.views.people.people_directory_page.title.all_people',
        defaultMessage: 'All people',
      })}
      tabs={tabs}
    />
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { features } = state;
  return {
    features,
  };
};

const connector = connect(mapStateToProps);

export default connector(React.memo(PeopleDirectoryPage));
