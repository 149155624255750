import * as consts from '../../consts/consts';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import React, { FC } from 'react';

import CardHeaderTitle from '../Widgets/Cards/CardHeaderTitle';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const now = new Date();

const getMonDD = (dateString) => {
  const date = new Date(dateString);

  // if this year, show MONTH_NAME DD
  if (date.getFullYear() === now.getFullYear()) {
    return date.toLocaleString('default', { month: 'short', day: 'numeric' });
  }

  // not this year, so show MONTH_NAME DD, YYYY
  return date.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const getValidTimingText = (awardIssuance) => {
  if (!awardIssuance || !awardIssuance.date_granted) {
    return '';
  }

  return getMonDD(awardIssuance.date_granted);
};

const PersonProfileAwardIssuanceRow: FC<PersonProfileAwardIssuanceRowProps> = (
  props
) => {
  const awardIssuance = props.awardIssuance;

  return (
    <Row>
      <Col className="col-2 pe-0">
        <img
          // @ts-expect-error
          src={awardIssuance.award.image}
          className="mw-100 mt-1"
          role="button"
        />
      </Col>
      <Col>
        {/* @ts-expect-error */}
        <h4 className="mb-2">{awardIssuance.award.name}</h4>
        <p className="text-muted mb-1 small">
          {/* @ts-expect-error */}
          {awardIssuance.authority_website && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              // @ts-expect-error
              href={awardIssuance.authority_website}
            >
              {/* @ts-expect-error */}
              {awardIssuance.authority_name}
            </a>
          )}
          {/* @ts-expect-error */}
          {!awardIssuance.authority_website && awardIssuance.authority_name}
        </p>
        <p className="text-muted small mb-0">
          {getValidTimingText(awardIssuance)}
        </p>
        <span className="small">
          {/* @ts-expect-error */}
          <p className="mt-2 mb-0">{awardIssuance.award.description}</p>
        </span>
      </Col>
    </Row>
  );
};

const PersonProfileAwardIssuanceRow_propTypes = {
  awardIssuance: PropTypes.object.isRequired,
  successCallback: PropTypes.func.isRequired,
};

type PersonProfileAwardIssuanceRowProps = PropTypes.InferProps<
  typeof PersonProfileAwardIssuanceRow_propTypes
>;

const PersonProfileAwardsSummary: FC<Props> = (props) => {
  const awardIssuances = props.awardIssuances;

  return (
    <Card>
      <CardHeader>
        <CardHeaderTitle icon={consts.ICONS.AWARD}>
          <FormattedMessage
            id="app.views.person.person_profile_awards_summary.awards"
            defaultMessage="Awards"
          />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody>
        {!(awardIssuances?.length === 0) && (
          <ListGroup className="list-group-flush my-n3">
            {awardIssuances.map((awardIssuance, index) => (
              <ListGroupItem key={index}>
                <PersonProfileAwardIssuanceRow
                  // @ts-expect-error
                  awardIssuance={awardIssuance}
                  awardIssuances={awardIssuances}
                  // @ts-expect-error
                  successCallback={props.successCallback}
                />
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        {(!awardIssuances || awardIssuances.length === 0) && (
          <p
            className={
              'text-center text-muted my-3 px-5' +
              (props.className ? ' ' + props.className : '')
            }
          >
            <FormattedMessage
              id="app.views.person.person_profile_awards_summary.no_awards"
              defaultMessage="
            No awards granted yet
          "
            />
          </p>
        )}
      </CardBody>
    </Card>
  );
};

const PersonProfileAwardsSummary_propTypes = {
  awardIssuances: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

type Props = PropTypes.InferProps<typeof PersonProfileAwardsSummary_propTypes>;

export default React.memo(PersonProfileAwardsSummary);
