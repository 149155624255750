import Cookies from 'js-cookie';

interface RipplingData {
  code: string;
  redirect_uri: string;
}

const RIPPLING_COOKIE_CODE = 'rippling_installation_flow_code';
const RIPPLING_COOKIE_REDIRECT_URI = 'rippling_installation_flow_redirect_uri';
// Rippling CODE expires after 5 minutes.
const RIPPLING_COOKIE_EXPIRATION_10_MINUTES = 1 / 24 / 6;

const ripplingDataFromQuerystring = (
  location_search: string | null | undefined
): RipplingData | null => {
  if (!location_search) {
    return null;
  }

  const searchParams = new URLSearchParams(location_search);
  const code = searchParams.get('code');
  const redirect_uri = searchParams.get('redirect_uri');
  if (code && redirect_uri) {
    return { code, redirect_uri };
  }
  return null;
};

const ripplingDataFromCookie = (): RipplingData | null => {
  const code = Cookies.get(RIPPLING_COOKIE_CODE);
  const redirect_uri = Cookies.get(RIPPLING_COOKIE_REDIRECT_URI);
  if (code && redirect_uri) {
    return { code, redirect_uri };
  }
  return null;
};

const ripplingSetCookie = (code: string, redirect_uri: string): void => {
  // Need to keep the cookies around for a bit in case the user
  // closes the browser in between signup steps.
  Cookies.set(RIPPLING_COOKIE_CODE, code, {
    expires: RIPPLING_COOKIE_EXPIRATION_10_MINUTES,
  });
  Cookies.set(RIPPLING_COOKIE_REDIRECT_URI, redirect_uri, {
    expires: RIPPLING_COOKIE_EXPIRATION_10_MINUTES,
  });
};

export const ripplingRemoveCookie = (redirectTo: string): void => {
  Cookies.remove(RIPPLING_COOKIE_CODE);
  Cookies.remove(RIPPLING_COOKIE_REDIRECT_URI);
  setTimeout(() => {
    window.location.href = redirectTo;
  }, 500);
};

export const processRipplingData = (
  location_search: string | null | undefined
): RipplingData | null => {
  const ripplingDataQS = ripplingDataFromQuerystring(location_search);
  if (ripplingDataQS) {
    // QS is supposedly fresher data, that's why it comes first.
    ripplingSetCookie(ripplingDataQS.code, ripplingDataQS.redirect_uri);
    return ripplingDataQS;
  }

  const ripplingDataCookie = ripplingDataFromCookie();
  if (ripplingDataCookie) {
    return ripplingDataCookie;
  }

  return null;
};
