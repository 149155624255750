import { Button, Popover, PopoverBody } from 'reactstrap';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { capitalize } from '../../../utils/util/formatter';

const ToggleInput: FC<Props> = (props) => {
  const numOptions = props.objects?.length;
  const propsOnChange = props.onChange;
  const ref = useRef();

  const [isMousedOver, setIsMousedOver] = useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const togglePopover = () => setPopoverIsOpen(!popoverIsOpen);

  // value is object id; always select value
  const [selectedIndex, setSelectedIndex] = useState(
    (() => {
      const foundIndex =
        // @ts-expect-error
        props.objects && props.objects.findIndex((o) => o.id === props.value);
      return foundIndex === -1 ? 0 : foundIndex;
    })()
  );

  // re-render when ref changes or moused over
  // (to fix issue with popover not showing initially for this component when on validated modal forms)
  useEffect(() => {
    if (isMousedOver) {
      setPopoverIsOpen(true);
    } else {
      setPopoverIsOpen(false);
    }
  }, [ref, isMousedOver]);

  const onClick = useCallback(() => {
    const newIndex = (selectedIndex + 1) % numOptions;
    setSelectedIndex(newIndex);
    if (propsOnChange) {
      propsOnChange({
        target: {
          name: props.name,
          // @ts-expect-error
          value: props.objects[newIndex].id,
        },
      });
    }
  }, [numOptions, props.name, props.objects, propsOnChange, selectedIndex]);

  const color = useMemo(
    () => (selectedIndex === 0 ? 'white' : 'primary'),
    [selectedIndex]
  );

  const selectedItem = useMemo(
    () => props.objects[selectedIndex],
    [props.objects, selectedIndex]
  );

  const selectedName = useMemo(() => {
    return capitalize(
      // @ts-expect-error
      typeof selectedItem.name !== 'undefined'
        ? // @ts-expect-error
          selectedItem.name
        : // @ts-expect-error
          selectedItem.toString()
    );
  }, [selectedItem]);

  return (
    <>
      {props.showName && (
        <span className="me-2" role="button" onClick={onClick}>
          {selectedName}
        </span>
      )}
      <Button
        onMouseOver={() => setIsMousedOver(true)}
        onMouseOut={() => setIsMousedOver(false)}
        // @ts-expect-error
        innerRef={ref}
        color={color}
        className={props.className ? props.className : 'btn-rounded-circle'}
        onClick={onClick}
      >
        {/* @ts-expect-error */}
        <i className={selectedItem.icon} />
      </Button>
      {!props.showName && ref?.current && (
        <Popover
          trigger="hover focus"
          placement="right"
          target={ref?.current}
          isOpen={popoverIsOpen}
          toggle={togglePopover}
        >
          <PopoverBody>{selectedName}</PopoverBody>
        </Popover>
      )}
    </>
  );
};

const ToggleInput_propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  alignRight: PropTypes.bool,
  objects: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired,
  renderItem: PropTypes.func,
  renderOption: PropTypes.func,
  onChange: PropTypes.func,
  // value is id of object (not entire object itself)
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showName: PropTypes.bool,
};

type Props = PropTypes.InferProps<typeof ToggleInput_propTypes>;

export default React.memo(ToggleInput);
