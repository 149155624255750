import {
  DEVELOPMENT_PLANS_BASE_URL_PATH,
  DEVELOPMENT_PLAN_INITIAL_SUBMIT_INPUTS,
  DevelopmentPlan,
  toApiSubmit,
} from 'utils/models/DevelopmentPlan';
import { Organization, OrganizationSettings, ReduxState } from 'types';
import React, { FC, useCallback, useMemo, useState } from 'react';

import ModalEditorButton from 'views/Widgets/Modals/ModalEditorButton';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

interface Props {
  title?: string;
  callback?: (o: DevelopmentPlan) => void;
  currentOrganization: Organization;
  settings: OrganizationSettings;
}

const ModalDevelopmentPlanEditorButton: FC<Props> = ({
  callback = () => {},
  currentOrganization,
  settings,
}) => {
  const { formatMessage } = useIntl();
  const [object, setObject] = useState(undefined);

  const handleDevelopmentPlanChange = useCallback((o) => {
    setObject(o);
  }, []);

  const inputs = useMemo(() => {
    return DEVELOPMENT_PLAN_INITIAL_SUBMIT_INPUTS(
      formatMessage,
      settings.development_plans_templates
    );
  }, [formatMessage, settings.development_plans_templates]);

  const onValidate = useCallback((_obj) => {
    return [];
  }, []);

  const output = useMemo(
    () => (
      <>
        <ModalEditorButton
          block
          url={DEVELOPMENT_PLANS_BASE_URL_PATH}
          title={formatMessage({
            id: 'app.views.development_plans.modal_development_plan_editor_button.title.create_development_plan',
            defaultMessage: 'Create development plan',
          })}
          submitText={formatMessage({
            id: 'app.views.development_plans.modal_development_plan_editor_button.submitText.create_development_plan',
            defaultMessage: 'Create development plan',
          })}
          object={object}
          onChange={handleDevelopmentPlanChange}
          onClosed={() => setObject(undefined)}
          onValidate={onValidate}
          inputs={inputs}
          transformObjectBeforeSubmit={(p) =>
            toApiSubmit(p, currentOrganization)
          }
          callback={callback}
        />
      </>
    ),
    [
      object,
      handleDevelopmentPlanChange,
      onValidate,
      inputs,
      callback,
      currentOrganization,
      formatMessage,
    ]
  );

  return output;
};

const mapStateToProps = (state: ReduxState) => {
  const { features, currentOrganization, settings } = state;

  return {
    features,
    currentOrganization,
    settings,
  };
};

export default connect(mapStateToProps)(
  React.memo(ModalDevelopmentPlanEditorButton)
);
