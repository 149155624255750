import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ValidatedForm from './ValidatedForm';
import { toast } from 'react-toastify';
import { toastErrorOrCallback } from '../../../utils/util/util';

interface Props {
  onSave?: (data: object) => void;
  onSaveAndContinue?: (data: object) => void;
  onGoBack?: () => void;
  setHasUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
  renderInputs?: (inputs: object) => object;
  preSubmit?: () => void;
  hasUnsavedChanges?: boolean;
  transformObjectBeforeSubmit: (data: object) => object;
}

const ValidatedWizardForm: FC<
  PropsWithChildren<Props & { [key: string]: unknown }>
> = (props) => {
  const { formatMessage } = useIntl();
  const [isSavingNormally, setIsSavingNormally] = useState(false);
  const [isSavingAndContinuing, setIsSavingAndContinuing] = useState(false);

  const {
    children,
    onSave,
    onSaveAndContinue,
    onGoBack,
    setHasUnsavedChanges,
    renderInputs,
    ...propsWithoutChildren
  } = props;

  const formRef = useRef<HTMLFormElement | null>();

  const callback = useMemo(
    () =>
      toastErrorOrCallback(
        (data) => {
          if (data) {
            if (isSavingAndContinuing) {
              // disable unless clicked again
              setIsSavingNormally(false);
              setIsSavingAndContinuing(false);
              if (onSaveAndContinue) {
                onSaveAndContinue(data);
              }
            } else {
              toast.success(
                formatMessage({
                  id: 'app.views.widgets.forms.validated_wizard_form.toast.cycle_updated',
                  defaultMessage: 'Cycle updated successfully.',
                })
              );
              // disable unless clicked again
              setIsSavingNormally(false);
              setIsSavingAndContinuing(false);
              if (onSave) {
                onSave(data);
              }
            }
          }
        },
        () => {
          setIsSavingNormally(false);
          setIsSavingAndContinuing(false);
        }
      ),
    [onSave, onSaveAndContinue, isSavingAndContinuing, formatMessage]
  );

  const onSaveAndContinueClicked = useCallback(() => {
    setIsSavingAndContinuing(true);
  }, []);

  const propsPreSubmit = props.preSubmit;
  const preSubmit = useCallback(() => {
    // set so we disable BOTH buttons when saving normally
    setIsSavingNormally(true);

    if (propsPreSubmit) {
      propsPreSubmit();
    }
  }, [propsPreSubmit]);

  useEffect(() => {
    if (isSavingAndContinuing) {
      formRef.current?.props.onSubmit();
    }
  }, [isSavingAndContinuing]);

  const isSaving = useMemo(
    () => isSavingNormally || isSavingAndContinuing,
    [isSavingAndContinuing, isSavingNormally]
  );

  const saveAndContinueButton = useMemo(() => {
    return onSaveAndContinue ? (
      <Button
        className="mt-4 w-100 btn-block"
        color="primary"
        disabled={isSaving}
        onClick={onSaveAndContinueClicked}
      >
        <>
          {!isSaving &&
            formatMessage({
              id: 'app.views.widgets.forms.validated_wizard_form.save_and_continue',
              defaultMessage: 'Save and continue',
            })}
          {isSaving && (
            <div
              className="spinner-border"
              style={{ width: '1rem', height: '1rem' }}
            />
          )}
        </>
      </Button>
    ) : undefined;
  }, [onSaveAndContinueClicked, onSaveAndContinue, isSaving, formatMessage]);

  const renderForm = useCallback(
    (inputs, submitButton) => {
      return (
        <>
          {inputs && (
            <Card>
              <CardBody>
                {renderInputs ? renderInputs(inputs) : inputs}
              </CardBody>
            </Card>
          )}
          <Row>
            <Col className="col-auto w-25 mt-4">
              {onGoBack && (
                <Button color="light" className="w-100" onClick={onGoBack}>
                  <FormattedMessage
                    id="app.views.widgets.forms.validated_wizard_form._go_back"
                    defaultMessage="Go back"
                  />
                </Button>
              )}
            </Col>
            <Col></Col>
            {onSave && <Col className="col-auto w-25">{submitButton}</Col>}
            {saveAndContinueButton && (
              <Col className="col-auto w-25">{saveAndContinueButton}</Col>
            )}
          </Row>
        </>
      );
    },
    [renderInputs, onGoBack, onSave, saveAndContinueButton]
  );

  return (
    <>
      {children}
      <ValidatedForm
        formRef={formRef}
        submitText={formatMessage({
          id: 'app.views.widgets.forms.validated_wizard_form.submitText.save',
          defaultMessage: 'Save',
        })}
        buttonColor="light"
        renderForm={renderForm}
        callback={callback}
        setHasUnsavedChanges={setHasUnsavedChanges}
        {...propsWithoutChildren}
        preSubmit={preSubmit}
        transformObjectBeforeSubmit={props.transformObjectBeforeSubmit}
      />
    </>
  );
};

export default React.memo(ValidatedWizardForm);
