import * as consts from '../../consts/consts';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import FeedbackDirectory from './FeedbackDirectory';
import FeedbackRequestTable from './FeedbackRequestTable';
import ModalFeedbackEditorButton from './ModalFeedbackEditorButton';
import Page from '../Layout/Pages/Page';
import { useIntl } from 'react-intl';

const FeedbackDirectoryPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const pathDict = useMemo(
    () => consts.TEAM_FEEDBACK_AND_RECOGNITION(formatMessage),
    [formatMessage]
  );

  const noTabProvidedInUrl = useMemo(
    () => pathDict && location?.pathname === pathDict.path,
    [location?.pathname, pathDict]
  );

  const addFeedbackOrNotesButtonText = useMemo(
    () =>
      formatMessage({
        id: 'app.views.feedback.feedback_directory_page.give_or_request_feedback_action',
        defaultMessage: 'Give or request feedback',
      }),
    [formatMessage]
  );

  const [newFeedbackRequests, setNewFeedbackRequests] = useState<unknown[]>([]);
  const newFeedbackOrRequestCreatedCallback = useCallback(
    (newObject: unknown) => {
      // if the new object is a feedback request, as indicated by
      // NOT having a "type" field, add it to the list of feedback requests
      // so it shows in the UI without reloading it
      if (
        typeof newObject === 'object' &&
        newObject !== null &&
        !('type' in newObject)
      ) {
        setNewFeedbackRequests([newObject, ...newFeedbackRequests]);
      }
    },
    [newFeedbackRequests]
  );

  const tabs = useMemo(
    () => [
      {
        path: pathDict.path + '/received',
        name: formatMessage({
          id: 'app.views.feedback.feedback_directory_page.received',
          defaultMessage: 'Received',
        }),
        content: <FeedbackDirectory />,
        action: (
          <ModalFeedbackEditorButton
            buttonText={addFeedbackOrNotesButtonText}
          />
        ),
      },
      {
        path: pathDict.path + '/sent',
        name: formatMessage({
          id: 'app.views.feedback.feedback_directory_page.sent_requests',
          defaultMessage: 'Sent requests',
        }),
        content: (
          <FeedbackRequestTable newFeedbackRequests={newFeedbackRequests} />
        ),
        action: (
          <ModalFeedbackEditorButton
            buttonText={addFeedbackOrNotesButtonText}
            callback={newFeedbackOrRequestCreatedCallback}
          />
        ),
      },
    ],
    [
      pathDict.path,
      formatMessage,
      addFeedbackOrNotesButtonText,
      newFeedbackRequests,
      newFeedbackOrRequestCreatedCallback,
    ]
  );

  // if no tab provided in url, and first tab is not resume tab (because it
  // is disabled, then redirect to the first tab's path)
  useEffect(() => {
    if (noTabProvidedInUrl && tabs?.[0]?.path !== pathDict?.path) {
      history.replace(tabs?.[0]?.path);
    }
  }, [history, noTabProvidedInUrl, pathDict?.path, tabs]);

  return (
    <Page
      title={consts.TEAM_FEEDBACK_AND_RECOGNITION(formatMessage).name}
      tabs={tabs}
    />
  );
};

export default React.memo(FeedbackDirectoryPage);
