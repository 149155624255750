import * as consts from '../../consts/consts';

import { Button, Card, CardBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Person, ReduxState } from 'types';
import React, { FC, useEffect, useState } from 'react';
import { claimInvitation, loadInvitation } from '../../actions';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Loading from '../Widgets/Loading';
import Page from '../Layout/Pages/Page';
import { connect } from 'react-redux';
import { peopleIdsAreEqual } from '../../utils/models/Person';

interface Props {
  meId?: number;
  me: Person;
  loadInvitation: (invitation: any, callback: any) => void;
  claimInvitation: (invitation: any, callback: any) => void;
}

const Invitation: FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const params = useParams();
  const [hardErrorMessage, setHardErrorMessage] = useState(null);
  const [invitation, setInvitation] = useState({
    // @ts-expect-error
    id: params.id,
  });
  const [wrongInvitationIndicated, setWrongInvitationIndicated] =
    useState(false);
  const [senderPerson, setSenderPerson] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  // get continue url (only path portion if a full url is given)
  let continueUrl = urlParams.get(consts.URL_QUERY_PARAMETERS.CONTINUE_URL);
  // @ts-expect-error
  if (continueUrl?.startsWith(process.env.REACT_APP_CONFIRM_APP_URL)) {
    continueUrl = continueUrl.slice(
      // @ts-expect-error
      process.env.REACT_APP_CONFIRM_APP_URL.length
    );
  }

  const loadInvitation = props.loadInvitation;

  useEffect(() => {
    // only use this once the invitation senderPerson is set
    // @ts-expect-error
    if (!invitation || !invitation.sender_person || !props.meId || !history) {
      return;
    }

    /* This page is the destination from invitation emails; by default it should log the person in and take the
       person to the relevant page in the app UNLESS the email on the invitation does not match the user, in
       which case, either:
       (a) The email address on the invitation already has an account, in which case we recommend logging in as
           that other account. If there's a desire to merge accounts, email customer support.
           or
       (b) The email address on the invitation does NOT already have an account, in which case we prompt
    */
    // @ts-expect-error
    if (peopleIdsAreEqual(invitation.recipient_person?.id, props.meId)) {
      // if there's an explicit redirect url provided, go there
      if (continueUrl) {
        history.replace(continueUrl);
      }
      // invitation is claimed by this user, so redirect to where this invitation intends to go
      // @ts-expect-error
      else if (invitation.contribution) {
        history.replace(
          consts.ACTIVITIES().path +
            '/' +
            // @ts-expect-error
            (invitation.contribution.activity?.id
              ? // @ts-expect-error
                invitation.contribution.activity.id
              : // @ts-expect-error
                invitation.contribution.activity)
        );
      } else {
        // go home by default
        console.error(
          'Unknown invitation type for invitation ' + invitation.id
        );
        history.replace('/');
      }
      return;
    }

    // invitation does not match senderPerson, so prompt user accordingly in the UI by setting the senderPerson
    // @ts-expect-error
    setSenderPerson(invitation.sender_person);
    // @ts-expect-error
  }, [invitation, history, continueUrl, props.meId, props.meIdme]);

  useEffect(() => {
    // @ts-expect-error
    if (invitation?.id && !invitation.sender_person && loadInvitation) {
      if (!token) {
        setHardErrorMessage(
          // @ts-expect-error
          'Invalid invitation. Please double-check the invitation link was entered correctly.'
        );
        return;
      }

      // load invitation from backend
      loadInvitation(
        {
          id: invitation.id,
          token: token,
        },
        (inv, error, errorMessage) => {
          if (errorMessage) {
            setHardErrorMessage(errorMessage);
          } else if (inv) {
            setInvitation(inv);
          } else {
            console.error(
              'Hit error receiving invitation: ' + JSON.stringify(error)
            );
            setHardErrorMessage(error);
          }
        }
      );
    }
  }, [invitation, token, loadInvitation]);

  const addRecipientAndClaimInvitation = () => {
    // claim invitation on backend
    props.claimInvitation(
      {
        id: invitation.id,
        token: token,
      },
      (inv, error, errorMessage) => {
        if (errorMessage) {
          setHardErrorMessage(errorMessage);
        } else if (inv) {
          setInvitation(inv);
        } else {
          console.error(
            'Hit error claiming invitation: ' + JSON.stringify(error)
          );
          setHardErrorMessage(error);
        }
      }
    );
  };

  const indicateWrongInvitation = () => {
    setWrongInvitationIndicated(true);
  };

  return (
    <>
      {!hardErrorMessage && !senderPerson && <Loading />}
      {hardErrorMessage && (
        <Card>
          <CardBody>
            <span>{hardErrorMessage}</span>
          </CardBody>
        </Card>
      )}
      {!hardErrorMessage && senderPerson && (
        <Page
          // @ts-expect-error
          avatar={senderPerson.avatar}
          // @ts-expect-error
          givenName={senderPerson.given_name}
          // @ts-expect-error
          familyName={senderPerson.family_name}
          pretitle={formatMessage({
            id: 'app.views.invitations.invitation.pretitle.invitation',
            defaultMessage: 'Invitation',
          })}
          title={formatMessage(
            {
              id: 'app.views.invitations.invitation.title.invitation_from_sender',
              defaultMessage: 'Invitation from {sender}',
            },
            // @ts-expect-error
            { sender: senderPerson.full_name }
          )}
        >
          <>
            {/* @ts-expect-error */}
            {invitation.accepted_on && (
              <div className="mb-4">
                <FormattedMessage
                  id="app.views.invitations.invitation.claimed"
                  defaultMessage="
                This invitation has already been claimed. If this was a mistake,
                please contact customer support.
              "
                />
              </div>
            )}
            {/* @ts-expect-error */}
            {!invitation.accepted_on && (
              <div>
                <div className="mb-4">
                  <FormattedMessage
                    id="app.views.invitations.invitation.not_accepted_on"
                    defaultMessage="
                  Are you <bold>{recipientEmail}</bold>? You're currently logged in as <bold>{meEmail}</bold>."
                    values={{
                      bold: (chunks) => (
                        <span className="fw-bold">{chunks}</span>
                      ),
                      // @ts-expect-error
                      recipientEmail: invitation.recipient_person.email,
                      meEmail: props.me.email,
                    }}
                  />
                </div>
                <div>
                  <Button
                    color="primary"
                    className="mb-4 me-4"
                    onClick={addRecipientAndClaimInvitation}
                  >
                    <FormattedMessage
                      id="app.views.invitations.invitation.button.attach"
                      defaultMessage="Yes, attach {recipientEmail} to my account"
                      values={{
                        // @ts-expect-error
                        recipientEmail: invitation.recipient_person.email,
                      }}
                    />
                  </Button>
                  <Button
                    color="secondary"
                    className="mb-4"
                    onClick={indicateWrongInvitation}
                  >
                    <FormattedMessage
                      id="app.views.invitations.invitation.button.reject"
                      defaultMessage="No, {recipientEmail} is someone else"
                      values={{
                        // @ts-expect-error
                        recipientEmail: invitation.recipient_person.email,
                      }}
                    />
                  </Button>
                </div>
              </div>
            )}
            {wrongInvitationIndicated && (
              <div className="mb-4">
                <FormattedMessage
                  id="app.views.invitations.invitation.wrong_recipient"
                  defaultMessage="
                Only the intended recipient of the invitation should accept it.
                {br}{br}
                If you believe this is not what you want, please contact
                customer to share what you are trying to do.
                "
                  values={{
                    br: <br />,
                  }}
                />
              </div>
            )}
          </>
        </Page>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { me } = state;

  return {
    me,
    meId: me?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadInvitation: (invitation, callback) =>
      dispatch(loadInvitation(invitation, callback)),
    claimInvitation: (invitation, callback) =>
      dispatch(claimInvitation(invitation, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Invitation));
