import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';
import { HttpMethod, useConfirmApi } from 'utils/api/ApiHooks';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import CompletionBar from './CompletionBar';
import { ENG_TEAM_NOTIFIED_OF_ERROR } from 'consts/consts';
import { useBackgroundTask } from './hooks';
import { useRefDimensions } from 'utils/util/hooks';

interface Props {
  title: string;
  isOpen: boolean;
  requestConfig: {
    url: string;
    method: HttpMethod;
    params: object;
  };
  onToggle?: () => void;
  onComplete: (data: unknown) => void;
  cancelText?: string | JSX.Element;
  submitText?: string | JSX.Element;
}

const BackgroundTaskModal: FC<PropsWithChildren<Props>> = ({
  title,
  isOpen,
  children,
  onToggle,
  onComplete,
  requestConfig,
  cancelText = (
    <FormattedMessage
      id="app.components.background_task_modal.back_ground_task_modal.cancel"
      defaultMessage="Cancel"
    />
  ),
  submitText = (
    <FormattedMessage
      id="app.components.background_task_modal.back_ground_task_modal.submit"
      defaultMessage="Submit"
    />
  ),
}) => {
  const { formatMessage } = useIntl();
  const modalRef = useRef<HTMLHeadingElement>(null);
  const { width } = useRefDimensions(modalRef, isOpen);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [latestError, setLatestError] = useState<{ status: number } | null>(
    null
  );

  const handleConfirmClick = useCallback(() => {
    setIsConfirmed(true);
  }, []);

  const handleOnTaskError = useCallback(() => {
    setIsConfirmed(false);
  }, []);

  const handleOnTaskComplete = useCallback(
    (data) => {
      setIsConfirmed(false);
      onComplete(data);
    },
    [onComplete]
  );

  const {
    data,
    error,
    status: apiStatus,
  } = useConfirmApi<{ status: string; task_key: string }>({
    ...requestConfig,
    disabled: !isConfirmed,
  });

  useEffect(() => {
    if (apiStatus === 'ERROR') {
      // @ts-expect-error
      setLatestError(error);
      setIsConfirmed(false);
    }
  }, [apiStatus, error]);

  const { completion } = useBackgroundTask({
    disabled: !isConfirmed || apiStatus !== 'SUCCESS',
    taskDescriptor: data,
    onTaskCompleted: handleOnTaskComplete,
    onError: handleOnTaskError,
  });

  return (
    <Modal keyboard={false} backdrop="static" isOpen={isOpen} toggle={onToggle}>
      <div ref={modalRef}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {children}
          {['SUCCESS', 'LOADING'].includes(apiStatus) && (
            <Row className="mt-4">
              <Col className="col-auto">
                <CompletionBar
                  progress={completion.progress}
                  total={completion.total}
                  width={width * 0.91}
                />
              </Col>
            </Row>
          )}
          {!!latestError && !isConfirmed && (
            <Row className="mt-4">
              <Col className="col-auto">
                <div className="text-danger">
                  {computeError(latestError, formatMessage)}
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col className="col-md-6">
              <Button
                disabled={isConfirmed}
                color="light"
                className="btn-block w-100"
                onClick={onToggle}
              >
                {cancelText}
              </Button>
            </Col>
            <Col className="col-md-6">
              <Button
                disabled={isConfirmed}
                color="primary"
                className="btn-block w-100"
                onClick={handleConfirmClick}
              >
                {!isConfirmed && submitText}
                {isConfirmed && (
                  <div
                    className="spinner-border"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                )}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  );
};

const computeError = (error, formatMessage: IntlShape['formatMessage']) => {
  if (error?.status === 403) {
    return formatMessage({
      id: 'app.components.background_task_modal.back_ground_task_modal.error_403',
      defaultMessage: 'You are not authorized to perform this action.',
    });
  }
  console.error(
    'Error releasing reports:',
    JSON.stringify(error, Object.getOwnPropertyNames(error))
  );
  return ENG_TEAM_NOTIFIED_OF_ERROR(formatMessage);
};

export default React.memo(BackgroundTaskModal);
