import React, { FC } from 'react';

import CredentialsDirectory from './CredentialsDirectory';
import Page from '../Layout/Pages/Page';
import { useIntl } from 'react-intl';

const CredentialsDirectoryPage: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Page
      title={formatMessage({
        id: 'app.views.credentials.credentials_directory_page.title.all_credentials',
        defaultMessage: 'All credentials',
      })}
    >
      <CredentialsDirectory />
    </Page>
  );
};

// all tracking in app will be passed through here
export default React.memo(CredentialsDirectoryPage);
