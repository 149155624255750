import { ListGroup, ListGroupItem } from 'reactstrap';
import React, { FC } from 'react';

import CredentialCard from '../Widgets/Cards/CredentialCard';

type Props = {
  credentials: object[];
};

const CredentialList: FC<Props> = ({ credentials }) => {
  return (
    <ListGroup className="list-group-flush my-n3">
      {credentials.map((credential, index) => (
        <ListGroupItem key={index}>
          <CredentialCard credential={credential} bodyOnly linked={true} />
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default CredentialList;
