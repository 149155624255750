import * as consts from './consts/consts';

import React, { ComponentType } from 'react';

// NOTE: we don't use React Lazy loading to improve navigation performance between pages
import Account from './views/Account/Account';
import ActivitiesDirectoryPage from './views/Activities/ActivitiesDirectoryPage';
import ActivityPage from './views/Activities/ActivityPage';
import AppIntegrations from './views/AppIntegrations/AppIntegrations';
import CampaignAdministrationDashboard from './views/Administration/CampaignAdministrationDashboard';
import CampaignEditor from './views/Administration/CampaignEditor';
import CredentialPage from './views/Credentials/CredentialPage';
import CredentialsDirectoryPage from './views/Credentials/CredentialsDirectoryPage';
import Dashboard from './views/Dashboard/Dashboard';
import DevelopmentPlanPage from 'views/DevelopmentPlans/DevelopmentPlanPage';
import DevelopmentPlansDashboard from './views/DevelopmentPlans/DevelopmentPlansDashboard';
import EngagementHubPage from './views/Engagement/EngagementHubPage';
import FeedbackDirectoryPage from './views/Feedback/FeedbackDirectoryPage';
import I18nTest from './views/Test/I18nTest';
import Invitation from './views/Invitations/Invitation';
import JobFamily from './views/Levels/JobFamily';
import LevelingFramework from './views/Levels/LevelingFramework';
import LocalStorageContentTest from './views/Test/LocalStorageContentTest';
import Logout from './views/Layout/Logout';
import MyProfile from './views/Person/MyProfile';
import ObjectivesHubPage from './views/Objectives/ObjectivesHubPage';
import OneOnOnesDirectoryPage from './views/OneOnOnes/OneOnOnesDirectoryPage';
import OpportunitiesPage from './views/Opportunities/OpportunitiesPage';
import OrganizationSettings from './views/Organization/OrganizationSettings';
import PageMaintenance from './views/Layout/Pages/Errors/PageMaintenance';
import PeopleAdministrationHubPage from './views/Administration/PeopleAdministrationHubPage';
import PeopleDirectoryPage from './views/People/PeopleDirectoryPage';
import PerformanceAdministrationPeerSelection from './views/Performance/PerformanceAdministrationPeerSelection';
// Leveling and performance pages
import PerformanceCurrentStep from './views/Performance/PerformanceCurrentStep';
import PerformanceHubPage from './views/Performance/PerformanceHubPage';
import PersonProfile from './views/Person/PersonProfile';
import PromotionPacketPage from './views/PromotionPackets/PromotionPacketPage';
import PromotionPacketsDashboard from './views/PromotionPackets/PromotionPacketsDashboard';
import { Redirect } from 'react-router-dom';
import RedirectToHome from './views/Layout/RedirectToHome';
import Referrals from './views/Referrals/Referrals';
import SkillPage from './views/Skills/SkillPage';
import SkillsDirectoryPage from './views/Skills/SkillsDirectoryPage';
import TasksPage from './views/Tasks/TasksPage';
// Team management pages
import TeamPage from './views/Team/TeamPage';
import WelcomeStep0 from './views/Welcome/WelcomeStep0';
import WelcomeStep1 from './views/Welcome/WelcomeStep1';
import NudgesList from 'views/Nudges/NudgesList';

export interface PermissionCallbackArgs {
  isHRBP: boolean;
  isOrgSystemAdmin: boolean;
  isSuperAdmin: boolean;
}

export interface PermissionCallbackResult {
  isEnabled: boolean;
}
export type PermissionCallback = (
  permissionArgs: PermissionCallbackArgs
) => PermissionCallbackResult;

export interface Route {
  path: string;
  name: string;
  exact: boolean;
  component: ComponentType;
  permissionsCallback?: PermissionCallback;
}

const adminRequired = ({ isOrgSystemAdmin, isSuperAdmin }) => ({
  isEnabled: isOrgSystemAdmin || isSuperAdmin,
});

interface RouteDescriptorItem {
  name: string;
  path: string;
}

interface RouteDescriptor {
  route: RouteDescriptorItem;
  component: ComponentType;
  subroute?: string;
  exact?: boolean;
  permissionsCallback?: PermissionCallback;
}

const generateRoutes = (features, formatMessage): Route[] => {
  // Routes included for everyone regardless of which features are enabled
  // NOTE: Also add any new route paths to core/admin/api/utils.py: APP_ROUTES
  let routes: RouteDescriptor[] = [
    { route: consts.MAINTENANCE(formatMessage), component: PageMaintenance },
    { route: consts.LOGOUT(formatMessage), component: Logout },
    { route: consts.WELCOME(formatMessage), component: WelcomeStep0 },
    { route: consts.WELCOME_STEP_1(formatMessage), component: WelcomeStep1 },
    { route: consts.LOGIN(formatMessage), component: RedirectToHome },
    { route: consts.SIGNUP(formatMessage), component: RedirectToHome },
    { route: consts.HOME(formatMessage), component: Dashboard },
    { route: consts.TASKS(formatMessage), component: TasksPage },
    { route: consts.I18N_TEST(formatMessage), component: I18nTest },
    {
      route: consts.LOCAL_STORAGE_TEST(formatMessage),
      component: LocalStorageContentTest,
    },
    {
      route: consts.ACCOUNT(formatMessage),
      exact: false,
      // @ts-expect-error
      component: Account,
    },
    { route: consts.MY_PROFILE(formatMessage), component: MyProfile },
    {
      route: consts.ADMINISTRATION_PEOPLE(formatMessage),
      exact: false,
      component: PeopleAdministrationHubPage,
      permissionsCallback: adminRequired,
    },
    {
      route: consts.ADMINISTRATION_CAMPAIGNS(formatMessage),
      component: CampaignAdministrationDashboard,
      permissionsCallback: adminRequired,
    },
    {
      route: consts.ADMINISTRATION_CAMPAIGNS(formatMessage),
      subroute: '/:id',
      exact: false,
      component: CampaignEditor,
      permissionsCallback: adminRequired,
    },
    {
      route: consts.ORGANIZATION_SETTINGS(formatMessage),
      exact: false,
      component: OrganizationSettings,
    },
    {
      route: consts.PEOPLE_ORG_CHART(formatMessage),
      component: PeopleDirectoryPage,
    },
    {
      route: consts.PEOPLE_DIRECTORY_LIST(formatMessage),
      component: PeopleDirectoryPage,
    },
    {
      route: consts.INVITATIONS(formatMessage),
      subroute: '/:id',
      component: Invitation,
    },
    {
      route: consts.PEOPLE_SUBROUTE(formatMessage),
      subroute: '/:personId',
      exact: false,
      component: PersonProfile,
    },
    {
      route: consts.ACTIVITIES_DIRECTORY(formatMessage),
      component: ActivitiesDirectoryPage,
    },
    {
      route: consts.MY_PROFILE(formatMessage),
      subroute: '/:subroute',
      component: MyProfile,
    },

    /* TEAM */
    {
      route: consts.TEAM_OBJECTIVES(formatMessage),
      component: ObjectivesHubPage,
    },
    {
      route: consts.TEAM_OBJECTIVES_TAB_PARTICIPATION(formatMessage),
      component: ObjectivesHubPage,
    },
    { route: consts.TEAM(formatMessage), component: TeamPage },
    {
      route: consts.ENGAGEMENT_HUB(formatMessage),
      component: EngagementHubPage,
    },

    /* PERFORMANCE DASHBOARDS PATHS (SHOULD COME BEFORE PERFORMANCE CYCLE STEPS)
       DUE TO EXACT MATCHING */
    {
      route: consts.PERFORMANCE_ADMINISTRATION_PEER_SELECTION(formatMessage),
      component: PerformanceAdministrationPeerSelection,
    },
    {
      route: consts.TEAM_PERFORMANCE_DASHBOARD(formatMessage),
      component: PerformanceHubPage,
    },
    {
      route: consts.VISUALIZATIONS_DASHBOARD_NEW(formatMessage),
      component: PerformanceHubPage,
    },
    {
      route: consts.PARTICIPATION_DASHBOARD(formatMessage),
      component: PerformanceHubPage,
    },
    {
      route: consts.DEVELOPMENT_PLANS(formatMessage),
      subroute: '/:id',
      exact: false,
      component: DevelopmentPlanPage,
    },
    {
      route: consts.DEVELOPMENT_PLANS(formatMessage),
      component: DevelopmentPlansDashboard,
    },
    {
      route: consts.PROMOTION_PACKETS(formatMessage),
      subroute: '/:id',
      exact: false,
      component: PromotionPacketPage,
    },
    {
      route: consts.PROMOTION_PACKETS(formatMessage),
      component: PromotionPacketsDashboard,
    },
    {
      route: consts.RATINGS_DASHBOARD(formatMessage),
      component: PerformanceHubPage,
    },
    {
      route: consts.TAKEAWAYS_DASHBOARD(formatMessage),
      component: PerformanceHubPage,
    },
    /* redirect old dashboard links to new ones */
    {
      route: {
        name: 'Participation dashboard',
        path: '/performance/participation-dashboard',
      },
      exact: true,
      component: () => <Redirect to="/performance/participation" />,
    },
    {
      route: {
        name: 'Rating dashboard',
        path: '/performance/rating-dashboard',
      },
      exact: true,
      component: () => <Redirect to="/performance/ratings" />,
    },

    /* PERFORMANCE CYCLE STEPS (MUST COME AFTER PERFORMANCE ADMINISTRATIONS) */
    // NOTE: if the below paths changes, please ensure to update Layout.js to reflect
    // these changes to ensure that the main left nav is hidden and top header shows
    // a simplified version to minimize distractions and escape paths.
    {
      // for campaign PREVIEWING (for admins to walk through the flow of a campaign)
      route: consts.PREVIEW_CAMPAIGN(formatMessage),
      // no step in url means go to the current step
      subroute: '/:campaignId/performance/:step?',
      exact: false,
      component: (props) => (
        <PerformanceCurrentStep isPreview={true} {...props} />
      ),
      permissionsCallback: adminRequired,
    },
    {
      route: consts.PERFORMANCE(formatMessage),
      // no step in url means go to the current step
      subroute: '/:step?',
      exact: false,
      component: PerformanceCurrentStep,
    },

    /* NON PERFORMANCE-RELATED PATHS */
    {
      route: consts.LEVELING_FRAMEWORK(formatMessage),
      component: LevelingFramework,
    },
    {
      route: consts.NUDGES_ADMINISTRATION(formatMessage),
      component: NudgesList,
    },
    // @ts-expect-error
    { route: consts.ACCOUNT(formatMessage), component: Account },
    {
      route: consts.APP_INTEGRATIONS(formatMessage),
      component: AppIntegrations,
      permissionsCallback: adminRequired,
    },
    {
      route: consts.LEVELING_FRAMEWORK(formatMessage),
      subroute: '/:slug',
      component: JobFamily,
    },
    {
      route: consts.INVITATIONS(formatMessage),
      subroute: '/:id',
      component: Invitation,
    },
  ];

  // experimental features

  if (features?.activities?.enabled) {
    routes = routes.concat([
      {
        route: consts.OPPORTUNITIES(formatMessage),
        exact: false,
        component: OpportunitiesPage,
      },
      {
        route: consts.CONTRIBUTIONS(formatMessage),
        subroute: '/:contributionId',
        exact: false,
        component: ActivityPage,
      },
      {
        route: consts.ACTIVITIES(formatMessage),
        subroute: '/:id',
        exact: false,
        component: ActivityPage,
      },
      {
        route: consts.SKILLS_DIRECTORY(formatMessage),
        component: SkillsDirectoryPage,
      },
      {
        route: consts.SKILLS(formatMessage),
        subroute: '/:id',
        exact: false,
        component: SkillPage,
      },
      {
        route: consts.CREDENTIALS_DIRECTORY(formatMessage),
        component: CredentialsDirectoryPage,
      },
      {
        route: consts.CREDENTIALS(formatMessage),
        subroute: '/:id',
        exact: false,
        component: CredentialPage,
      },
      {
        route: consts.TEAM_ONE_ON_ONES(formatMessage),
        component: OneOnOnesDirectoryPage,
      },
      {
        route: consts.TEAM_FEEDBACK_AND_RECOGNITION(formatMessage),
        exact: false,
        component: FeedbackDirectoryPage,
      },
    ]);

    if (features?.referrals?.enabled) {
      routes = routes.concat([
        {
          route: consts.REFERRALS(formatMessage),
          exact: true,
          component: Referrals,
        },
      ]);
    }
  }

  // Keep the vanity URL route at the end so that user generated slugs
  // don't conflict with any of the routes above
  routes = routes.concat([
    {
      route: consts.HOME(formatMessage),
      subroute: ':slug',
      exact: false,
      component: PersonProfile,
    },
  ]);

  return routes.map((r) => ({
    path: r.subroute ? r.route.path + r.subroute : r.route.path,
    name: r.route.name,
    exact: typeof r.exact !== 'undefined' ? r.exact : true,
    component: r.component,
    permissionsCallback: r.permissionsCallback,
  }));

  // NOTE: when needing to authorize for additional Google API access, enable the below
  /*.map(r => ({
    ...r,
    authParams: {
      access_type: 'offline',
      approval_prompt: 'force',
      // NOTE: this needs to match what is in google.py on the backend, so when updating
      // this, update there as well
      // TODO: unify these into one place
      // NOTE: THIS LIST HERE IS COMMA-SEPARATED, BUT ON THE BACKEND IT'S AN ARRAY
      connection_scope: 'https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.group.readonly,https://www.googleapis.com/auth/admin.directory.group.member.readonly,https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
    },
  }));*/
};

export default generateRoutes;
