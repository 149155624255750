import { Button, Card, CardBody, Collapse } from 'reactstrap';
import React, { useState } from 'react';

interface Props {
  className?: string;
  row: React.ReactNode;
  content: React.ReactNode;
}

const AccordionTableRow: React.FC<Props> = ({ className, row, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <Button onClick={() => setIsOpen(!isOpen)}>{row}</Button>
      <Collapse isOpen={isOpen}>
        <Card className="mt-4">
          <CardBody>{content}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default AccordionTableRow;
