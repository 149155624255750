import * as consts from '../../consts/consts';
import React, { useMemo } from 'react';
import Page from '../Layout/Pages/Page';
import TeamOverview from './TeamOverview';
import { useIntl } from 'react-intl';

const getTabHeader = (name: string): JSX.Element => {
  return (
    <>
      <span className="d-none d-sm-inline">{name}</span>
    </>
  );
};

const TeamPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const tabs = useMemo(() => {
    return [
      {
        path: consts.TEAM(formatMessage).path,
        name: getTabHeader(consts.TEAM(formatMessage).name),
        content: <TeamOverview />,
      },
    ];
  }, [formatMessage]);

  return (
    <Page
      title={formatMessage({
        id: 'app.views.team.team_page.title',
        defaultMessage: 'Team alignment',
      })}
      tabs={tabs}
    />
  );
};

export default TeamPage;
