import React, { FC, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import SelectInput from './SelectInput';
import { useIntl } from 'react-intl';

// Based on https://github.com/akofman/react-select-places/blob/master/src/index.js
const PlacesInput: FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  let selectPlacesNode;

  const [value, setValue] = useState(undefined);

  const autocompletionRequest = {
    types: ['(cities)'],
  };

  const getSelectInputValueFromProvidedValue = (val) => {
    return {
      ...val, // has label which is required for SelectInput and place id
      value: val, // required for SelectInput
      // required for autocomplete render function
      name: val.label,
      id: val.place_id,
      object: val,
      icon: 'fe fe-map-pin',
    };
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    // @ts-expect-error
    if (props.value?.place_id) {
      // @ts-expect-error
      if (props.value?.label) {
        setValue(getSelectInputValueFromProvidedValue(props.value));
      } else {
        // @ts-expect-error
        new window.google.maps.places.PlacesService(
          selectPlacesNode
        ).getDetails(
          // @ts-expect-error
          { placeId: props.value?.place_id },
          (placeInfo, requestStatus) => {
            if (isMounted) {
              if (requestStatus === 'OK') {
                setValue(
                  getSelectInputValueFromProvidedValue({
                    label: placeInfo.formatted_address,
                    // @ts-expect-error
                    place_id: props.value?.place_id,
                  })
                );
                // @ts-expect-error
              } else if (props.value.label) {
                console.error(
                  `Google Maps Places API RequestStatus is: ${requestStatus}, label property is used`
                );
                setValue(
                  getSelectInputValueFromProvidedValue({
                    // @ts-expect-error
                    label: props.value.label,
                    // @ts-expect-error
                    place_id: props.value.place_id,
                  })
                );
              } else {
                console.error(
                  `Google Maps Places API RequestStatus is: ${requestStatus} and label property is missing`
                );
              }
            }
          }
        );
      }
    }
  }, [isMounted, props.value, selectPlacesNode]);

  const loadOptions = (input, callback) => {
    if (input) {
      // @ts-expect-error
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { ...autocompletionRequest, input },
        (predictions) => {
          let options = [];
          if (predictions) {
            options = predictions.map((prediction) =>
              getSelectInputValueFromProvidedValue({
                label: prediction.description,
                place_id: prediction.place_id,
              })
            );
          }
          callback(options);
        }
      );
    } else {
      // no input / match
      callback([]);
    }
  };

  const onChange = (newValue) => {
    setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  return (
    <>
      <SelectInput
        required={props.required}
        disabled={props.disabled}
        className={'places-input'}
        innerRef={props.innerRef}
        name={props.name}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        defaultOptions={false}
        placeholder={formatMessage({
          id: 'app.views.widgets.inputs.places_input.placeholder.type_a_city',
          defaultMessage: 'Type a city...',
        })}
        hideDropdownIndicator={true}
      />
      <div ref={(node) => (selectPlacesNode = node)} />
    </>
  );
};

const PlacesInput_propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  // Value object fields expected: label (string), place_id (Google Place Id)
  value: PropTypes.object,
  innerRef: PropTypes.object,
  onChange: PropTypes.func,
};

type Props = PropTypes.InferProps<typeof PlacesInput_propTypes>;

export default React.memo(PlacesInput);
