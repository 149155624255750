import {
  Button,
  Card,
  CardBody,
  List,
  ListGroup,
  ListGroupItem,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { QuestionSummary, ThemeSummary } from './types';
import React, { FC, useMemo, useState } from 'react';

import ExportButton from '../../components/ExportButton/ExportButton';
import { FormattedMessage } from 'react-intl';
import Modal from '../../components/SafeModal';
import PercentageCircleChart from '../Widgets/Charts/PercentageCircleChart';
import { zip } from 'lodash';

const INITIAL_RESPONSES_TO_SHOW = 3;
const INITIAL_THEMES_TO_SHOW = 2;

const ThemeResponsesGroup: FC<{
  responses: string[];
}> = ({ responses }) => {
  const [viewMore, setViewMore] = useState<boolean>(false);
  const responsesToShow = useMemo(
    () =>
      viewMore ? responses : responses.slice(0, INITIAL_RESPONSES_TO_SHOW),
    [responses, viewMore]
  );

  return (
    <ListGroupItem className="ps-6">
      <List className="ps-0 mb-3">
        {responsesToShow.map((response: string, index: number) => (
          <li className="mb-2" key={`theme-${index}`}>
            <FormattedMessage
              id="app.views.engagement_survey.question_details_modal.quote"
              defaultMessage='"'
            />
            {response}
            <FormattedMessage
              id="app.views.engagement_survey.question_details_modal.quote"
              defaultMessage='"'
            />
          </li>
        ))}
      </List>
      {responses.length > INITIAL_RESPONSES_TO_SHOW && !viewMore && (
        <Button
          className="ms-n3"
          color="link"
          onClick={() => setViewMore(true)}
        >
          <FormattedMessage
            id="app.views.engagement_survey.question_details_modal.view_more"
            defaultMessage="View more"
          />
        </Button>
      )}
    </ListGroupItem>
  );
};

const ThemeHeaderGroup: FC<{
  themeName: string;
  numResponses: number;
  totalResponses: number;
  isOpen: boolean;
  onClick: () => void;
}> = ({ themeName, numResponses, totalResponses, isOpen, onClick }) => (
  <ListGroupItem className="summary-details--row" onClick={onClick}>
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex gap-4 align-items-center">
        <PercentageCircleChart
          percentage={(100 * numResponses) / totalResponses}
          onColor="#3498db"
          offColor="#ecf0f1"
        />
        <p className="fw-bold mb-0">{themeName}</p>
      </div>
      {isOpen ? (
        <i className="fe fe-chevron-up" />
      ) : (
        <i className="fe fe-chevron-down" />
      )}
    </div>
  </ListGroupItem>
);

const TopThemesGroup: FC<{ themes: ThemeSummary[]; answers: string[] }> = ({
  themes,
  answers,
}) => {
  const [openListItems, setOpenListItems] = useState<object>({});
  const [viewMore, setViewMore] = useState<boolean>(false);
  const themesToShow = useMemo(
    () => (viewMore ? themes : themes.slice(0, INITIAL_THEMES_TO_SHOW)),
    [themes, viewMore]
  );

  return (
    <ListGroup className="mt-4">
      <ListGroupItem>
        <h4 className="card-header-title d-flex justify-content-between">
          <FormattedMessage
            id="app.views.engagement_survey.question_details_modal.top_themes"
            defaultMessage="Top themes"
          />{' '}
        </h4>
      </ListGroupItem>
      {themesToShow.map((t: ThemeSummary, index: number) => (
        <React.Fragment key={`theme-fragment-${index}`}>
          <ThemeHeaderGroup
            themeName={t.name ?? ''}
            numResponses={t.related_answers.length}
            totalResponses={answers.length}
            onClick={() =>
              setOpenListItems({
                ...openListItems,
                [index]: !openListItems[index],
              })
            }
            isOpen={openListItems[index]}
          />
          {openListItems[index] && (
            <ThemeResponsesGroup
              responses={t.related_answers.map(
                (index: number) => answers[index]
              )}
            />
          )}
        </React.Fragment>
      ))}
      {themes.length > INITIAL_THEMES_TO_SHOW && !viewMore && (
        <ListGroupItem>
          <Button color="link" onClick={() => setViewMore(true)}>
            <FormattedMessage
              id="app.views.engagement_survey.question_details_modal.view_more"
              defaultMessage="View more"
            />
          </Button>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

const SummaryGroup: FC<{ overallSummary: string }> = ({ overallSummary }) => (
  <ListGroup>
    <ListGroupItem>
      <FormattedMessage
        id="app.views.engagement_survey.question_details_modal.summary"
        defaultMessage="Summary"
      >
        {(text) => (
          <h4 className="card-header-title d-flex justify-content-between">
            {text}
          </h4>
        )}
      </FormattedMessage>
    </ListGroupItem>
    <ListGroupItem>{overallSummary}</ListGroupItem>
  </ListGroup>
);

const Header: FC<{
  campaignName: string;
  questionSummary: QuestionSummary | null;
}> = ({ campaignName, questionSummary }) => {
  const csvFilename = useMemo(
    () =>
      questionSummary
        ? `${campaignName} - ${questionSummary.label} - Summary.csv`
        : '',
    [campaignName, questionSummary]
  );

  const csvData = useMemo(
    () =>
      questionSummary
        ? (zip(
            ['Question', 'Overall Summary', 'Theme', 'Summary', 'Answers'],
            [questionSummary.label, questionSummary.overall_summary],
            ...questionSummary.themes.map((t) => [
              '',
              '',
              t.name,
              t.summary,
              ...t.related_answers.map(
                (index) => questionSummary.answers[index]
              ),
            ])
          ) as string[][])
        : ([] as string[][]),
    [questionSummary]
  );

  return questionSummary ? (
    <div className="align-items-center justify-content-between d-flex">
      <FormattedMessage
        id="app.views.engagement_survey.question_details_modal.question_details"
        defaultMessage="Text question summary"
      />
      <ExportButton filename={csvFilename} data={csvData} />
    </div>
  ) : (
    <></>
  );
};

const QuestionDisplayText = ({ question }) => (
  <Card>
    <CardBody>
      <h6 className="text-muted text-uppercase">
        <FormattedMessage
          id="app.views.engagement_survey.question_details_modal.question"
          defaultMessage="Question"
        />
      </h6>
      <h4 className="mb-0">{question}</h4>
    </CardBody>
  </Card>
);

const QuestionDetailsModal: FC<{
  campaignName: string;
  question: QuestionSummary;
  modal: boolean;
  toggle: () => void;
}> = ({ campaignName, question, modal, toggle }) => {
  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        <Header campaignName={campaignName} questionSummary={question} />
      </ModalHeader>
      <ModalBody>
        <QuestionDisplayText question={question.label} />
        <SummaryGroup overallSummary={question.overall_summary} />
        <TopThemesGroup themes={question.themes} answers={question.answers} />
      </ModalBody>
    </Modal>
  );
};

export default QuestionDetailsModal;
