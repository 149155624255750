import { InputGroup, InputGroupText } from 'reactstrap';
import React, { FC } from 'react';

import PropTypes from 'prop-types';

// @ts-expect-error
const PrependedInput: FC<Props> = (props) => {
  // no iconClassName and no prependedText should be treated like a normal input
  if (!props.iconClassName && !props.prependedText) {
    return props.children;
  }
  return (
    <InputGroup
      className={
        'input-group-rounded input-group-merge input-group-reverse ' +
        (props.className ? ' ' + props.className : '')
      }
    >
      {props.children}
      <InputGroupText
        // @ts-expect-error
        className={props.inputGroupTextClassName}
      >
        <span
          // @ts-expect-error
          className={props.iconClassName}
        >
          {props.prependedText}
        </span>
      </InputGroupText>
    </InputGroup>
  );
};

const PrependedInput_propTypes = {
  prependedText: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  inputGroupTextClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

type Props = PropTypes.InferProps<typeof PrependedInput_propTypes>;

export default React.memo(PrependedInput);
