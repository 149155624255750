import { Card, CardBody, Col, Row } from 'reactstrap';
import { Organization, Person, ReduxState } from 'types';
import React, { FC, useEffect, useState } from 'react';

import ElasticsearchAPI from '../../utils/api/ElasticsearchAPI';
import FilterInput from '../Widgets/Search/FilterInput';
import { FormattedMessage } from 'react-intl';
import Loading from '../Widgets/Loading';
import PageError from '../Layout/Pages/Errors/PageError';
import SkillList from './SkillList';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  currentOrganization: Organization;
  currentProxyPerson: Person;
  inModal?: boolean;
}

const SkillsDirectory: FC<Props> = ({ inModal = false, ...props }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const [skills, setSkills] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filterName, setFilterName] = useState('');

  const { user } = useAuth0();
  const userSub = user?.sub;
  const currentOrgId = props.currentOrganization?.id;

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const MAX_SKILLS_TO_SHOW = 9999;

    if (userSub && currentOrgId) {
      const queryParams = {
        size: MAX_SKILLS_TO_SHOW,
        sort: [
          {
            'name.raw': 'asc',
          },
        ],
        source_includes: ['id', 'name', 'type'],
      };

      // @ts-expect-error
      ElasticsearchAPI.search(
        // only cache if no query provided (so we don't take up too much
        // memory story search results as searches are done)
        filterName ? undefined : userSub,
        props.currentProxyPerson,
        currentOrgId,
        'get-skills-by-name',
        filterName
          ? {
              ...queryParams,
              name: filterName,
            }
          : queryParams,
        (hits) => {
          const newSkills = hits.map((h) => h._source);
          if (isMounted) {
            setSkills(newSkills);
          }
        },
        (message) => {
          setErrorMessage(message);
        }
      );
    }
  }, [isMounted, userSub, filterName, currentOrgId, props.currentProxyPerson]);

  if (errorMessage) {
    console.error('Could not load all skills: ' + errorMessage);
    return <PageError message={errorMessage} />;
  }

  if (typeof skills === 'undefined') {
    return <Loading />;
  }

  const onChangeFilter = (e) => {
    setFilterName(e.target.value);
  };

  const skillsContent = (
    <>
      {/* @ts-expect-error */}
      {skills.length === 0 && (
        <div className="text-center mb-4">
          <span className="text-muted">
            <FormattedMessage
              id="app.views.skills.skills_directory.no_results"
              defaultMessage="No results found"
            />
          </span>
        </div>
      )}
      <SkillList skills={skills} inModal={inModal} />
    </>
  );

  return (
    <Row className="justify-content-md-center">
      {/*<Col md={4} className="order-1 order-md-2">
        {!inModal && (
          <SkillListCard
            title="Recommended skills"
            loaderFunction={(p, c, e) =>
              ElasticsearchAPI.getTopSkills(userSub, currentOrgId, p, c, e)
            }
            hideDescriptions={true}
            emptyState="You have no recommended teams."
          />
        )}
      </Col>*/}
      <Col md="12" className="order-2 order-md-1">
        <FilterInput
          value={filterName}
          onChange={onChangeFilter}
          autoFocus={inModal}
        />
        {inModal && <div className="mt-4">{skillsContent}</div>}
        {!inModal && (
          <Card className="mt-4 mb-0">
            <CardBody>{skillsContent}</CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { currentOrganization, currentProxyPerson } = state;

  return {
    currentOrganization,
    currentProxyPerson,
  };
};

// all tracking in app will be passed through here
export default connect(mapStateToProps)(React.memo(SkillsDirectory));
