import { Col, Container, Row } from 'reactstrap';
import React, { FC } from 'react';

import TasksList from './TasksList';

const TasksPage: FC = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col className="order-2 order-md-1 col-md-8">
          <TasksList isFullPage={true} />
        </Col>
      </Row>
    </Container>
  );
};

// all tracking in app will be passed through here
export default React.memo(TasksPage);
